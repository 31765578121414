import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';
import moment from 'moment';

Vue.use(Vuex);

const competitorsSearchParamsTemplate = {
  groupNameOrUrl: null,
  differencePercentFrom: null,
  differencePercentTo: null,
  groupCategoriesId: null,
  ourPriceGrater: null,
};

export default {
  namespaced: true,
  state: {
    competitorsGroups: [],
    selectedSocial: 'telegram',
    loadParams: {
      'page-size': 50,
      page: 1,
    },
    sortDir: 'desc',
    sortBy: 'differencePercent',
    totalGroups: null,
    totalPageCounts: null,
    competitorsSearchParams: { ...competitorsSearchParamsTemplate },
    competitorsSearchParamsTemplate: { ...competitorsSearchParamsTemplate },
  },
  getters: {
    getCompetitorsGroups: (state) => state.competitorsGroups,
    getSelectedSocial: (state) => state.selectedSocial,
    getTotalPageCounts: (state) => state.totalPageCounts,
    getTotalGroups: (state) => state.totalGroups,
    getSortBy: (state) => state.sortBy,
    getSortDir: (state) => state.sortDir,
    getCurrentPage: (state) => state.loadParams.page,
    getIsLastPage: (state) => +state.loadParams.page === +state.totalPageCounts + 1,
    getCompetitorsSearchParams: (state) => state.competitorsSearchParams,
    getCompetitorsSearchParamsTemplate: (state) => state.competitorsSearchParamsTemplate,
  },
  mutations: {
    setCompetitorsGroups: (state, value) => { state.competitorsGroups = value; },
    setSelectedSocial: (state, value) => { state.selectedSocial = value; },
    setLoadPage: (state, value) => { state.loadParams.page = value; },
    setSortBy: (state, value) => { state.sortBy = value; },
    setSortDir: (state, value) => { state.sortDir = value; },
    setTotalPageCounts: (state, value) => { state.totalPageCounts = value; },
    setTotalGroups: (state, value) => { state.totalGroups = value; },
    setCompetitorsSearchParams: (state, value) => { state.competitorsSearchParams = { ...value }; },
    resetCompetitorsSearchParams: (state) => { state.competitorsSearchParams = { ...competitorsSearchParamsTemplate }; },
  },
  actions: {
    // Moderation
    getCompetitorsGroups({ commit, state }, params) {
      if (params.reset) {
        commit('setCompetitorsGroups', []);
        commit('setLoadPage', 1);
        commit('setTotalPageCounts', null);
      }
      return api.get('/price-catalog/aggregate', {
        params: {
          serviceName: 'telega-in',
          groupSocial: state.selectedSocial,
          sort: state.sortBy,
          sortDirection: state.sortDir,
          ...state.loadParams,
          ...state.competitorsSearchParams,
        },
      })
        .then((response) => {
          const { data } = response.data;
          data.forEach((item) => {
            Vue.set(item.externalPrice, 'checked', false);
          });

          if (params.reset) {
            commit('setCompetitorsGroups', data);
          } else {
            commit('setCompetitorsGroups', [...state.competitorsGroups, ...data]);
          }

          commit('setTotalPageCounts', response.headers['x-pagination-page-count']);
          commit('setLoadPage', state.loadParams.page + 1);
          commit('setTotalGroups', response.headers['X-Pagination-Total-Count']);

          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- getCompetitorsGroups; Error -', error);
          return Promise.reject(error);
        });
    },

    // Удаление/скрытие элемента
    hideElements({ state, commit }, priceIds) {
      const params = { priceIds };
      return api.patch('/price-catalog/hide', params)
        .then(() => {
          const newCompetitorsGroups = state.competitorsGroups.filter((item) => !priceIds.includes(item.externalPrice.id));
          commit('setCompetitorsGroups', newCompetitorsGroups);
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- competitors/deleteElement --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Экспортировать из каталога сравнения цен
    exportPriceCatalog({ commit, dispatch }, params) {
      commit('app/setAppIsLoading', true, { root: true });

      return api.post('/export-groups/export-price-catalog-and-hide', params, { responseType: 'arraybuffer' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          const date = moment(new Date()).format('L');
          const hours = moment(new Date()).format('HH-MM');
          link.setAttribute('download', `Экспорт каталога цен - ${date}__${hours}.xlsx`);
          document.body.appendChild(link);
          link.click();
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'exportPriceCatalog' }, { root: true });
          console.warn('Error in action -- exportPriceCatalog --; Error -', error);
          return Promise.reject();
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },
  },
};
