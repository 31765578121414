<template>
  <div
  ref="other-filters"
  class="other-filters"
  @click="openMenu">
    <div class="other-filters__title">
      <span class="other-filters__text other-filters__text_second">
        {{ $t('other_filters') }}
      </span>
      <transition name="fade">
        <div
        v-if="isNotEmptyValues"
        class="other-filters__title-active" />
      </transition>
    </div>
    <span
    class="other-filters__text"
    :class="isNotEmptyValues && 'other-filters__text_blue'">
      {{ getLabel }}
    </span>
    <transition name="fade">
      <div
      v-if="isOpen"
      v-click-outside="onClickOutside"
      :style="{
        top: `${top}px`,
        left: `${left}px`,
      }"
      class="other-filters__menu">
        <!-- Рекламная метка -->
        <div class="other-filters__container">
          <span class="other-filters__text">
            {{ $t('advertising_label') }}
          </span>
          <div class="other-filters__switch">
            <div
            v-for="param in simpleParams"
            :key="param.value"
            class="other-filters__switch-item"
            :class="advertisingLabelValue == param.value && 'other-filters__switch-item_active'"
            @click="changeSwitcherValue('advertisingLabel', 'advertisingLabelValue', param.value)">
              <span>
                {{ $t(param.name) }}
              </span>
            </div>
          </div>
        </div>

        <div class="other-filters__container">
          <span class="other-filters__text">
            {{ $t('advertising_options') }}
          </span>
          <div class="other-filters__advertising">
            <div
            v-for="param in placementParams"
            :key="param.value"
            class="other-filters__radio">
              <label class="other-filters__radio">
                <input
                v-model="placementValue"
                type="radio"
                :value="param"
                @change="changePlacement(param)">
                <span class="other-filters__text other-filters__text_second">
                  {{ $t(param.name) }}
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="other-filters__container">
          <div class="other-filters__robot-text-block">
            <span class="other-filters__text">
              {{ $t('robot') }}
            </span>
            <span class="other-filters__text">
              {{ getRobotValue }}
            </span>
          </div>
          <DataRangeSlider
          :value="robotValues"
          :from-max="0"
          :to-max="100"
          :disabled="disabled"
          @change="changeRobotValues" />
        </div>
        <span
        class="other-filters__text other-filters__text_second other-filters__text_reset"
        @click="resetAll">
          {{ $t('reset_all') }}
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'OtherFilters',
  props: {
    advertisingLabel: {
      type: [Boolean, String],
      default: null,
    },
    placementInTop: {
      type: [Number, String],
      default: null,
    },
    placementInFeed: {
      type: [Number, String],
      default: null,
    },
    roboticityMin: {
      type: [Number, String],
      default: null,
    },
    roboticityMax: {
      type: [Number, String],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOpen: false,
    simpleParams: [
      { value: null, name: 'all' },
      { value: true, name: 'yes' },
      { value: false, name: 'no' },
    ],
    placementParams: [
      { placeTop: null, placeFeed: null, name: 'all' },
      { placeTop: '1', placeFeed: '24', name: '1/24' },
      { placeTop: '2', placeFeed: '48', name: '2/48' },
      { placeTop: '3', placeFeed: '78', name: '3/78' },
      { placeTop: 0, placeFeed: 0, name: 'without_delete' },
    ],
    placementValue: null,
    advertisingLabelValue: null,
    robotValues: null,
    top: 0,
    left: 0,
  }),
  created() {
    // advertisingLabel
    this.advertisingLabelValue = this.advertisingLabel ? this.advertisingLabel === 'true' : null;

    // robot
    const minRobotValue = this.roboticityMin ? this.roboticityMin : 0;
    const maxRobotValue = this.roboticityMax ? this.roboticityMax : 100;
    this.robotValues = [minRobotValue, maxRobotValue];

    // placement
    if (this.placementInTop === null && this.placementInFeed === null) {
      this.placementValue = { placeTop: null, placeFeed: null, name: 'all' };
      return;
    }
    // eslint-disable-next-line
    this.placementValue = this.placementParams.find((item) => item.placeTop == this.placementInTop);
  },
  methods: {
    resetAll() {
      this.advertisingLabelValue = null;
      this.placementValue = { placeTop: null, placeFeed: null, name: 'all' };
      this.robotValues = [0, 100];
      const values = ['placementInTop', 'placementInFeed', 'advertisingLabel', 'roboticityMin', 'roboticityMax'];
      values.forEach((item) => {
        this.$emit(`update:${item}`, null);
      });
    },
    openMenu() {
      this.top = this.$refs['other-filters'].getBoundingClientRect().top + 50;
      this.left = this.$refs['other-filters'].getBoundingClientRect().left;
      this.isOpen = true;
    },
    onClickOutside() {
      this.isOpen = false;
    },
    changeSwitcherValue(key, name, value) {
      if (this[name] === value) {
        return;
      }
      this[name] = value;
      this.$emit(`update:${key}`, value);
    },
    changePlacement(param) {
      this.placementValue = param;
      this.$emit('update:placementInTop', param.placeTop);
      this.$emit('update:placementInFeed', param.placeFeed);
    },
    changeRobotValues(values) {
      this.robotValues = [...values];
      this.$emit('update:roboticityMin', this.robotValues[0] === 0 ? null : this.robotValues[0]);
      this.$emit('update:roboticityMax', this.robotValues[1] === 100 ? null : this.robotValues[1]);
    },
  },
  computed: {
    isNotEmptyValues() {
      const emptyRobot = this.roboticityMin === null && this.roboticityMax === null ? null : false;
      return [this.placementValue.placeTop, this.advertisingLabelValue, emptyRobot]
        .filter((item) => item !== null).length;
    },
    getLabel() {
      return this.isNotEmptyValues ? `${this.$t('selected')} (${this.isNotEmptyValues})` : this.$t('not_selected');
    },
    getRobotValue() {
      // NEED TRANSLATE
      return `от ${this.robotValues[0]} до ${this.robotValues[1]}%`;
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
  },
  watch: {
    reset(newValue) {
      if (newValue === false) {
        this.resetAll();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.other-filters {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 60px;
}

.other-filters__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5px;
}

.other-filters__title-active {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: $primary-blue;
  margin-left: 10px;
  margin-top: 1px;
}

.other-filters__text {
  color: #565656;
  font-size: 14px;
  cursor: pointer;
  &_second {
    font-weight: 400;
    color: #7a7a7a;
    font-size: 13px;
  }
  &_blue {
    color: $primary-blue;
  }
}

.other-filters__menu {
  position: absolute;
  z-index: 10;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  width: 320px;
  @include between-elements {
    margin-bottom: 15px;
  }
  @include last-child2 {
    margin-bottom: 25px;
  }
}

.other-filters__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.other-filters__hidden {
  display: flex;
  @include between-elements {
    margin-right: 10px;
  }
}

.other-filters__advertising {
  @include between-elements {
    margin-bottom: 5px;
  }
}

.other-filters__switch {
  height: 25px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  outline: 1px solid $border-color-primary;
  cursor: pointer;
  @include between-elements {
    border-right: 1px solid $border-color-primary;
  }
}

.other-filters__switch-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  color: $primary-black;
  &_active {
    color: $primary-white;
    background: $primary-blue;
  };
}

.other-filters__robot-text-block {
  display: flex;
  justify-content: space-between;
}

</style>
