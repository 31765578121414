<template>
  <div class="mave-devices">
    <!-- title -->
    <span class="text text_title">Откуда слушают</span>

    <!-- tabs -->
    <div class="mave-devices__tabs">
      <span
      v-for="tab in tabs"
      :key="tab.name"
      class="mave-devices__tab"
      :class="selectedTab.name === tab.name && 'mave-devices__tab_selected'"
      @click="changeSelectedTab(tab)">
        {{ tab.name }}
      </span>
    </div>

    <!-- График -->
    <div
    v-show="showGraph"
    class="mave-devices__graph-content">
      <div
      ref="deviceChart"
      class="mave-devices__graph" />
    </div>

    <!-- Данных нет -->
    <div
    v-if="!showGraph"
    class="mave-devices__empty">
      {{ emptyText }}
    </div>
  </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import am5localeRUS from '@amcharts/amcharts5/locales/ru_RU';

export default {
  name: 'MaveDevices',
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    selectedTab: null,
    tabs: [
      { name: 'Система', value: 'system' },
      { name: 'Тип устройств', value: 'device' },
    ],
    deviceSystems: [],
    deviceTypes: [],
    root: null,
    showGraph: true,
  }),
  created() {
    // eslint-disable-next-line prefer-destructuring
    this.selectedTab = this.tabs[0];
  },
  mounted() {
    this.initData();
    this.initChart();
  },
  methods: {
    initData() {
      this.deviceSystems = this.group.deviceSystems || [];
      this.deviceTypes = this.group.devices || [];
    },
    destroyGraph() {
      if (this.root) this.root.dispose();
    },
    initChart() {
      const data = this.selectedTab.value === 'system' ? this.deviceSystems : this.deviceTypes;

      if (!data.length) {
        this.showGraph = false;
        return;
      }

      this.showGraph = true;

      this.root = am5.Root.new(this.$refs.deviceChart);
      this.locale = am5localeRUS;
      this.root.setThemes([am5themesAnimated.new(this.root)]);

      const chart = this.root.container.children.push(am5percent.PieChart.new(this.root, {
        innerRadius: am5.percent(50),
        layout: this.root.horizontalLayout,
      }));

      const categoryField = this.selectedTab.value === 'system' ? 'system' : 'device';

      const series = chart.series.push(am5percent.PieSeries.new(this.root, {
        valueField: 'value',
        categoryField,
        alignLabels: false,
        // dx: -200, смещение влево
      }));

      // series.get('colors').set('colors', [
      //   am5.color('#6AB16F'),
      //   am5.color('#ec4e4e'),
      //   am5.color('#A1A1A1'),
      //   am5.color('#FFC125'),
      //   am5.color('#CCEFFA'),
      // ]);

      series.labels.template.setAll({
        text: '',
      });

      const legend = chart.children.push(am5.Legend.new(this.root, {
        width: am5.percent(100),
        y: am5.percent(50),
        // x: 400,
        centerY: am5.percent(50),
        layout: this.root.verticalLayout,
      }));

      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
      });

      legend.markers.template.setAll({
        width: 14,
        height: 14,
      });

      series.data.setAll(data);

      legend.data.setAll(series.dataItems);

      series.appear(1000, 100);
    },
    changeSelectedTab(tab) {
      this.selectedTab = tab;
      this.destroyGraph();
      this.initChart();
    },
  },
  computed: {
    emptyText() {
      return this.selectedTab.value === 'system'
        ? 'Нет данных по системам'
        : 'Нет данных по типу устройств';
    },
  },
};
</script>

<style lang="scss" scoped>
.mave-devices {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  padding: 32px 32px 45px;
  border-radius: 20px;
  box-shadow: 0px 4px 23px rgba(0, 174, 232, 0.07);
  min-height: 550px;

  .text {
    font-size: 14px;
    &_title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .mave-devices__tabs {
    margin: 20px 0px;
    display: flex;
    justify-content: flex-start;
    @include between-elements {
      margin-right: 20px;
    }
  }

  .mave-devices__tab {
    cursor: pointer;
    padding: 0px 5px 10px;
    &_selected {
      border-bottom: 3px solid $primary-blue;
    }
  }

  .mave-devices__graph-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }

  .mave-devices__graph {
    width: 100%;
    height: 100%;
    position: relative;
    &::after {
      position: absolute;
      content: '';
      height: 20px;
      background: white;
      pointer-events: none;
      bottom: 1px;
      left: 4px;
      width: 55px;
      z-index: 999990;
      opacity: 1;
      border-radius: 4px;
    }
  }

  .mave-devices__empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-gray;
  }
}
</style>
