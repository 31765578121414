<template>
  <div class="mave-platforms">
    <span class="mave-platforms__text mave-platforms__text_title">Где слушают</span>

    <!-- Платформы -->
    <div
    v-if="platforms.length"
    class="mave-platforms__content">
      <!-- Индикаторы -->
      <div class="mave-platforms__indicators">
        <div
        v-for="platform in platforms"
        :key="platform.platform"
        v-tooltip="platform.fullLabel"
        :style="{
          width: `${platform.value}%`,
          'background-color': `${platform.color}`
        }"
        class="mave-platforms__indicator" />
      </div>

      <!-- Список -->
      <div class="mave-platforms__elements">
        <div
        v-for="platform in platforms"
        :key="platform.platform"
        class="mave-platforms__element">
          <div class="mave-platforms__element-name">
            <div
            class="mave-platforms__element-indicator"
            :style="{'background-color': platform.color}" />

            <span class="mave-platforms__text mave-platforms__text_platform">{{ platform.platform }}</span>
          </div>
          <span class="mave-platforms__text mave-platforms__text_blue">{{ platform.label }}</span>
        </div>
      </div>
    </div>

    <!-- Данных нет -->
    <div
    v-else
    class="mave-platforms__empty">
      Данные отсутствуют
    </div>
  </div>
</template>

<script>
import colors from '@/assets/js/colors';

export default {
  name: 'MavePlatforms',
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    platforms: [],
  }),
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      const platforms = this.group.platforms
        ? this.group.platforms.map((item) => {
          const rand = 1 + Math.random() * (colors.length + 1 - 1);
          const color = colors[Math.floor(rand)];
          return {
            ...item,
            label: `${item.value} %`,
            fullLabel: `${item.platform}: ${item.value} %`,
            color,
          };
        })
        : [];
      this.platforms = platforms;
    },
  },
};
</script>

<style lang="scss" scoped>
.mave-platforms {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  padding: 32px 32px 45px;
  border-radius: 20px;
  box-shadow: 0px 4px 23px rgba(0, 174, 232, 0.07);
  min-height: 550px;
  max-height: 550px;

  .mave-platforms__text {
    font-size: 14px;
    &_platform {
      color: $primary-gray;
    }
    &_title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    &_blue {
      color: $primary-blue;
      font-weight: bold;
    }
  }

  .mave-platforms__indicators {
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 26px;
    background: rgba($primary-group-border, 0.4);
    margin-bottom: 40px;
    padding: 0px 20px;
    @include between-elements {
      margin-right: 3px;
    }
  }

  .mave-platforms__indicator {
    height: 5px;
    display: flex;
    border-radius: 40px;
  }

  .mave-platforms__element-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .mave-platforms__element-indicator {
    margin-right: 10px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }

  .mave-platforms__content {
    width: 100%;
    overflow: hidden;
  }

  .mave-platforms__elements {
    height: 100%;
    overflow: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-right: 10px;
    @include between-elements {
      margin-bottom: 20px;
    }
  }

  .mave-platforms__element {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid $primary-group-border;
  }

  .mave-devices__empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-gray;
  }
}
</style>
