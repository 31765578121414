<template>
  <div
  class="edit-compilation-bar"
  :class="show && 'edit-compilation-bar_show'">
    <div class="edit-compilation-bar__container">
      <span class="edit-compilation-bar__text">{{ getTitle }}</span>
      <div class="edit-compilation-bar__to-compilation">
        <span
        class="edit-compilation-bar__text edit-compilation-bar__text_button"
        @click="goToCurrentCompilation">Перейти в подборку</span>
      </div>
    </div>
    <div
    v-tooltip="'Завершить редактирование'"
    class="edit-compilation-bar__close-compilation"
    @click="closeEdit">
      <DataIcon
      :size="16"
      name="close" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditCompilationBar',
  data: () => ({
    show: true,
  }),
  methods: {
    closeEdit() {
      this.show = !this.show;
      setTimeout(() => {
        this.$store.commit('groups/setAddToCompilationMode', false);
      }, 300);
    },
    goToCurrentCompilation() {
      this.$router.push({ name: 'compilation', query: { id: this.currentCompilation.id } });
    },
  },
  computed: {
    currentCompilation() {
      return this.$store.getters['groups/getAddToCompilationMode'];
    },
    getTitle() {
      let compilationName = this.currentCompilation.name;
      if (compilationName && compilationName === 'noname') {
        compilationName = this.currentCompilation.id ? `ID - ${this.currentCompilation.id}` : 'Без названия';
      }
      return `Каталог (Подборка: ${compilationName})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-compilation-bar {
  width: 100%;
  height: 0px;
  min-height: 0px;
  padding: 0px 20px;
  background: $primary-blue;
  border-radius: 12px 12px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  opacity: 0;
  @include between-elements {
    margin-right: 30px;
  }
  transition: height 0.1s, opacity 0.1s;
  &_show {
    overflow: visible;
    height: 50px;
    min-height: 50px;
    opacity: 1;
  }
}

.edit-compilation-bar__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 30px;
  }
}

.edit-compilation-bar__text {
  font-size: 14px;
  color: white;
  &_button {
    color: $primary-blue;
    font-size: 12px;
  }
}

.edit-compilation-bar__to-compilation {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 20px;
  padding: 0px 15px;
  cursor: pointer;
}

.edit-compilation-bar__close-compilation {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  cursor: pointer;
}
</style>
