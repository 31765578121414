export const groupsSearchParamsTemplate = {
  nameOrUrl: null,
  byUrl: null,
  contact: null,
  categoriesId: null,
  withoutCategory: null,
  withoutCountry: null,
  subscriberCountries: null,
  description: null,
  containsPostPriceSell: null,

  subscribersMin: null,
  subscribersMax: null,

  viewsMin: null,
  viewsMax: null,
  viewsType: 'last24h',

  priceMin: null,
  priceMax: null,
  priceType: 'post',

  cpmMin: null,
  cpmMax: null,
  cpсMin: null,
  cpсMax: null,

  erMin: null,
  erMax: null,

  countInCompilationsMin: null,
  countInCompilationsMax: null,
  campaignLauncherCountMin: null,
  campaignLauncherCountMax: null,

  fromPublisherBot: null,

  postsMin: null,
  postsMax: null,
  postsType: 'last24h',

  growMin: null,
  growMax: null,
  growType: 'month',
  interestsId: null,

  roboticityMin: null,
  roboticityMax: null,

  malePercentMin: null,
  malePercentMax: null,
  femalePercentMin: null,
  femalePercentMax: null,
  ageMin: null,
  ageMax: null,

  advertisingLabel: null,
  placementInFeed: null,
  placementInTop: null,
  // admin/
  botName: null,
  inSocialjet: null,
  isBlogger: null,
  inBlackList: null,
  dataUpdatedAtFrom: null,
  dataUpdatedAtTo: null,
  createdAtFrom: null,
  createdAtTo: null,
  ourTradeOfferUpdatedAtFrom: null,
  ourTradeOfferUpdatedAtTo: null,
  paymentTypesId: null,
  onlyBadGroups: null,
  containsContact: null,
  isExclusive: null,
  inspectReportWarningCodes: null,
  postSaleBiggerThanBuy: null,
  withoutAd: null,
};

export const groupsSearchParamsTemplateMave = {
  nameOrUrl: null,
  byUrl: null,
  contact: null,
  categoriesId: null,
  withoutCategory: null,
  withoutCountry: null,
  subscriberCountries: null,
  description: null,
  containsPostPriceSell: null,

  subscribersMin: null,
  subscribersMax: null,

  listeningMin: null,
  listeningMax: null,
  viewsType: 'last24h',

  priceMin: null,
  priceMax: null,
  priceType: 'post',

  cpmMin: null,
  cpmMax: null,
  cpсMin: null,
  cpсMax: null,

  erMin: null,
  erMax: null,

  countInCompilationsMin: null,
  countInCompilationsMax: null,
  campaignLauncherCountMin: null,
  campaignLauncherCountMax: null,

  fromPublisherBot: null,

  postsMin: null,
  postsMax: null,
  postsType: 'last24h',

  growMin: null,
  growMax: null,
  growType: 'month',
  interestsId: null,

  roboticityMin: null,
  roboticityMax: null,

  malePercentMin: null,
  malePercentMax: null,
  femalePercentMin: null,
  femalePercentMax: null,
  ageMin: null,
  ageMax: null,

  advertisingLabel: null,
  placementInFeed: null,
  placementInTop: null,
  // admin/
  botName: null,
  inSocialjet: null,
  isBlogger: null,
  inBlackList: null,
  dataUpdatedAtFrom: null,
  dataUpdatedAtTo: null,
  createdAtFrom: null,
  createdAtTo: null,
  ourTradeOfferUpdatedAtFrom: null,
  ourTradeOfferUpdatedAtTo: null,
  paymentTypesId: null,
  onlyBadGroups: null,
  containsContact: null,
  isExclusive: null,
  inspectReportWarningCodes: null,
  postSaleBiggerThanBuy: null,
  withoutAd: null,
};
