var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-button"},[_c('div',{staticClass:"data-button__button",class:{
    'data-button__button_outlined': _vm.type === 'outlined',
    'data-button__button_danger': _vm.type === 'danger',
    'data-button__button_text': _vm.type === 'text',
    'data-button__button_disabled': _vm.disabled,
  },style:({
    'width': _vm.width === 'full' ? '100%' : _vm.width + 'px',
    'height': _vm.height ? _vm.height + 'px' : '40px',
    'fontSize': _vm.fontSize + 'px',
    'borderRadius': _vm.borderRadius + 'px'
  }),attrs:{"disabled":_vm.disabled},on:{"click":_vm.click}},[(_vm.text && !_vm.icon)?_c('span',[_vm._v(_vm._s(_vm.text))]):_vm._e(),(_vm.icon)?_c('DataIcon',{attrs:{"name":_vm.icon}}):_vm._e(),(_vm.showRipple)?_c('span',{staticClass:"data-button__ripple"}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }