<template>
  <div class="post-search-filters">
    <!-- Фильтр соц сети -->
    <SocialFilter
    ref="socialFilter" />

    <!-- Фильтр по ключевым словам -->
    <WordsFilter
    ref="keyWordsFilter"
    type="keywords" />

    <!-- Фильтр по датам постов -->
    <PostDates
    ref="postDatesFilter"
    :reset="isReset" />

    <!-- Фильтр  по словам исключениям -->
    <WordsFilter
    ref="stopWordsFilter"
    :open="false"
    type="stopwords" />

    <PostContent
    ref="tonalityFilter"
    type="tonality"
    title="Направленность поста" />

    <PostContent
    ref="contentFilter"
    type="content"
    title="Содержимое постов" />

    <DataButton
    width="full"
    :border-radius="10"
    :height="50"
    text="Найти посты"
    @handler="goSearch" />

    <div
    class="post-search-filters__reset"
    :class="showResetButton && 'post-search-filters__reset_show'">
      <span
      class="post-search-filters__reset-text"
      @click="resetFilters">Сбросить фильтры</span>
    </div>
  </div>
</template>

<script>
import SocialFilter from './components/SocialFilter.vue';
import WordsFilter from './components/WordsFilter.vue';
import PostDates from './components/PostDates.vue';
import PostContent from './components/PostFocus.vue';

export default {
  name: 'PostSearchFilters',
  components: {
    SocialFilter, WordsFilter, PostDates, PostContent,
  },
  data: () => ({
    showResetButton: false,
    avaibleFilters: ['keyword', 'social', 'nameOrUrl', 'fromDate', 'toDate', 'stopWords', 'tonality', 'withAttachments', 'withLinks'],
    isReset: false,
  }),
  methods: {
    goSearch() {
      this.$store.commit('searchPosts/setLoadPage', 1);
      this.$emit('goSearch');
    },
    resetFilters() {
      this.$router.push({ name: 'post-search', query: {} });
      [this.$refs.socialFilter,
        this.$refs.keyWordsFilter,
        this.$refs.stopWordsFilter,
        this.$refs.postDatesFilter,
        this.$refs.tonalityFilter,
        this.$refs.contentFilter].forEach((filter) => { filter.resetFilters(); });
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newVal) {
        const routeKeys = Object.keys(newVal);
        this.showResetButton = this.avaibleFilters.some((filter) => routeKeys.includes(filter));
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.post-search-filters {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-right: 20px;
  max-width: 365px;
  @include between-elements {
    margin-bottom: 15px;
  }

  .post-search-filters__reset {
    width: 100%;
    text-align: center;
    transition: 0.3s;
    opacity: 0;
    pointer-events: none;
    &_show {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .post-search-filters__reset-text {
    color: $primary-gray;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }
}
</style>
