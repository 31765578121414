<template>
  <div
  class="campaign-element">
    <div class="campaign-element__column campaign-element__column_name pointer">
      <div
      class="campaign-element__campaign-id"
      @click="copyId">
        {{ campaign.id }}
      </div>
      <div
      class="campaign-element__name-text"
      @click="openBrief">
        <span
        v-tooltip="campaign.name"
        class="campaign-element__text campaign-element__text_name">
          {{ campaign.name }}
        </span>
        <span class="campaign-element__text campaign-element__text_agency-name">
          {{ campaignCreator }}
        </span>
      </div>
    </div>

    <!-- Подборка -->
    <div
    class="campaign-element__column"
    style="width: 150px">
      <a
      v-if="requestLink"
      :href="requestLink"
      target="_blank"
      class="campaign-element__text campaign-element__text_blue">
        Открыть
      </a>
      <span
      v-else
      class="campaign-element__text">
        —
      </span>
    </div>

    <!-- Бюджет -->
    <div
    class="campaign-element__column"
    style="width: 200px">
      <span class="campaign-element__text">
        {{ budget }}
      </span>
    </div>

    <!-- Ответственный в SJ -->
    <div
    class="campaign-element__column"
    style="width: 250px">
      <span class="campaign-element__text">
        {{ getResponsibleName }}
      </span>
    </div>

    <!-- Статус -->
    <div
    class="campaign-element__column"
    style="width: 150px">
      <span
      class="campaign-element__text"
      :class="!status && 'campaign-element__text_light'">
        {{ status ? status : 'Нет данных' }}
      </span>
    </div>

    <!-- Отчёт -->
    <div
    class="campaign-element__column"
    style="width: 150px">
      <a
      v-if="reportLink"
      :href="reportLink"
      target="_blank"
      class="campaign-element__text campaign-element__text_blue">
        Открыть
      </a>
      <span
      v-else
      class="campaign-element__text">
        —
      </span>
    </div>

    <!-- Дата старта -->
    <div
    class="campaign-element__column"
    style="width: 140px">
      <span class="campaign-element__text campaign-element__text_light">
        {{ startDate }}
      </span>
    </div>

    <!-- Дата окончания -->
    <div
    class="campaign-element__column"
    style="width: 140px">
      <span class="campaign-element__text campaign-element__text_light">
        {{ endDate }}
      </span>
    </div>

    <!-- Меню -->
    <div
    class="campaign-element__column"
    style="width: 100px">
      <img
      src="@/assets/svg/compilation/DotsThree.png"
      class="campaign-element__menu-icon"
      @click="showMenu = true">
      <div
      v-if="showMenu"
      v-click-outside="() => showMenu = false"
      class="campaign-element__menu"
      :class="lastElement && 'campaign-element__menu_last'">
        <span
        class="campaign-element__menu-element"
        @click="deleteCampaign">Архивировать</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CampaignElement',
  props: {
    // Объект с клиентом/запросом
    campaign: {
      type: Object,
      default: () => {},
    },
    lastElement: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    statuses: [
      { id: null, name: 'Все' },
      { id: 1, name: 'Новый' },
      { id: 2, name: 'В работе' },
      { id: 3, name: 'Готов' },
    ],
    status: null,
    brief: null,
    showBrief: false,
    requestLink: null,
    budget: null,
    reportLink: null,
    showMenu: false,
    campaignCreator: null,
  }),
  created() {
    this.status = this.getStatus();
    this.brief = this.campaign.requestPlacement?.brief || false;
    this.requestLink = this.campaign.requestPlacement?.linkToCompilation || false;
    this.budget = this.campaign.amount?.amount ? this.setNumberSpaces(+this.campaign.amount?.amount) : '—';
    this.reportLink = this.campaign.linkToReport || false;
    this.startDate = this.getStartDate();
    this.endDate = this.getEndDate();
    this.campaignCreator = this.getCampaignCreator();
  },
  methods: {
    copyId() {
      this.copyToBuffer(this.campaign.id);
      this.$store.dispatch('app/setSnackBar', { text: 'Скопировано' });
    },
    getStartDate() {
      const date = this.campaign.startDateTime?.date;
      return date ? this.moment(date).format('ll') : '—';
    },
    getEndDate() {
      const date = this.campaign.endDateTime?.date;
      return date ? this.moment(date).format('ll') : '—';
    },
    getStatus() {
      const { status } = this.campaign;
      return status ? this.statuses.find((item) => item.id === status).name : false;
    },
    openBrief() {
      if (!this.campaign.requestPlacement) return;

      const params = {
        selectedGroups: this.getSelectedGroups,
        viewMode: true,
        currentPlacement: this.campaign.requestPlacement,
      };
      this.$store.commit('placements/setPlacementParams', params);
    },
    deleteCampaign() {
      this.$store.dispatch('campaigns/deleteCampaign', this.campaign.id);
      this.showMenu = false;
    },
    getCampaignCreator() {
      return this.campaign?.agencyOwner?.name || null;
    },
  },
  computed: {
    getResponsibleName() {
      return this.campaign.requestPlacement?.responsibleUser?.name
      || this.campaign.requestPlacement?.responsibleUser?.email
      || this.campaign.responsibleName
      || 'Нет данных';
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign-element {
  display: flex;
  align-items: center;
  min-width: 100%;
  background: white;
  padding: 15px 0px;
  border-bottom: 1px solid $primary-group-border;
  &:hover {
    background: #f4fbfe;
    .campaign-element__column_name {
      background: #f4fbfe;
    }
  }
}

.campaign-element__column {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  height: 40px;
  &_name {
    flex-direction: row;
    justify-content: flex-start;
    position: sticky;
    left: 0px;
    z-index: 1;
    background: white;
    padding-left: 10px;
    width: 300px;
    padding-right: 20px;
    @include tablet-screen {
      width: 420px;
    }
  }
}

.campaign-element__name-text {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.campaign-element__text {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: black;
  &_name {
    font-size: 16px;
    color: $primary-blue;
  }
  &_light {
    color: $primary-lightgray;
  }
  &_agency-name {
    color: $primary-lightgray;
    font-size: 12px;
  }
  &_green {
    color: $primary-green;
  }
  &_red {
    font-size: 16px;
    color: $primary-red;
  }
  &_blue {
    color: $primary-blue;
  }
}

.campaign-element__actions {
  display: flex;
  justify-content: center;
  @include between-elements {
    margin-right: 20px;
  }
}

.campaign-element__menu-icon {
  position: absolute;
  right: 10px;
  padding: 10px;
  cursor: pointer;
}

.campaign-element__menu {
  min-width: 210px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40px;
  right: 25px;
  z-index: 2;
  &_last {
    top: initial;
    bottom: 50px;
  }
}

.campaign-element__menu-element {
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: rgba($primary-lightgray, 0.05);
  }
}

.campaign-element__campaign-id {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgba($primary-blue, 0.1);
  color: $primary-gray;
  font-size: 13px;
  padding: 5px 10px;
  border-radius: 6px;
  margin-right: 10px;
}

</style>
