<template>
  <div class="clients-screen">
    <div class="clients-screen__header">
      <div class="clients-screen__tabs">
        <!-- Табы заголовков -->
        <div class="clients-screen__title-tabs">
          <span
          class="clients-screen__title-tab"
          :class="{ 'clients-screen__title-tab_selected' : isAdmin && 'clients' === selectedTitleTab }"
          @click="changeTitleTab('clients')">Клиенты</span>
          <span
          v-if="isAdmin"
          class="clients-screen__title-tab"
          :class="{ 'clients-screen__title-tab_selected' : isAdmin && 'clientsStatistic' === selectedTitleTab }"
          @click="changeTitleTab('clientsStatistic')">Статистика</span>
          <span
          v-if="isAdmin"
          class="clients-screen__title-tab"
          :class="{ 'clients-screen__title-tab_selected' : isAdmin && 'clientsAccess' === selectedTitleTab }"
          @click="changeTitleTab('clientsAccess')">Заявки на доступ</span>
        </div>
        <DataButton
        text="Добавить"
        :width="184"
        :height="30"
        @handler="openAddModal" />
      </div>

      <!-- Табы клиентов -->
      <div
      v-if="isAdmin && selectedTitleTab === 'clients'"
      class="clients-screen__clients-tabs">
        <span
        v-for="tab in clientTabs"
        :key="tab.value"
        :class="{ 'clients-screen__clients-tab_selected' : tab.value === selectedClientTab }"
        class="clients-screen__clients-tab"
        @click="changeSelectedClientTab(tab.value)">
          {{ tab.name }}
        </span>
      </div>
    </div>

    <ClientsAccess
    v-if="selectedTitleTab === 'clientsAccess'" />

    <ClientsStatistic
    v-if="selectedTitleTab === 'clientsStatistic'" />

    <Clients
    v-if="selectedTitleTab === 'clients'"
    ref="clients"
    :sales-managers="salesManagers"
    :account-managers="accountManagers"
    :selected-client-tab="selectedClientTab" />

    <DataModal
    :show-modal="showAddModal"
    @close="showAddModal = false">
      <template #modal-content>
        <AddClientModal
        :is-admin="isAdmin"
        :is-agency="isAgency"
        :sales-managers="salesManagers"
        :account-managers="accountManagers"
        modal-type="addClient"
        @close="showAddModal = false" />
      </template>
    </DataModal>
  </div>
</template>

<script>
import Clients from './components/Clients/Clients.vue';
import AddClientModal from './components/Modals/AddClientModal.vue';
import ClientsAccess from './components/ClientsAccess/ClientsAccess.vue';
import ClientsStatistic from './components/ClientsStatistic/ClientsStatistic.vue';

export default {
  name: 'ClientsScreen',
  components: {
    Clients, AddClientModal, ClientsAccess, ClientsStatistic,
  },
  data: () => ({
    clientTabs: [
      { name: 'Все клиенты', value: 'all' },
      { name: 'Агентства', value: 'agency' },
      { name: 'Прямые рекламодатели', value: 'rekls' },
      { name: 'Пользователи', value: 'users' },
    ],
    selectedClientTab: 'all',
    selectedTitleTab: 'clients',
    showAddModal: false,
    salesManagers: null,
    accountManagers: null,
  }),
  created() {
    if (this.isAdmin) {
      this.getSalesList();
      this.initClientsList();
    }
  },
  methods: {
    changeTitleTab(value) {
      if (this.selectedTitleTab === value) return;
      this.selectedTitleTab = value;
      this.$store.dispatch('clients/resetSearchParams');

      if (this.selectedTitleTab === 'clients') {
        this.$nextTick(() => {
          this.$refs.clients.loadClientsData();
        });
      }
    },
    changeSelectedClientTab(value) {
      if (this.selectedClientTab === value) return;
      this.selectedClientTab = value;
      this.$nextTick(() => {
        this.$refs.clients.loadClientsData();
      });
    },
    async getSalesList() {
      const sales = await this.$store.dispatch('user/getUserList', 9);
      const managers = await this.$store.dispatch('user/getUserList', 6);
      this.salesManagers = sales.map((item) => ({ name: item.name || item.email, value: item.id })); //
      this.accountManagers = managers.map((item) => ({ name: item.name || item.email, value: item.id })); //
    },
    initClientsList() {
      this.$store.dispatch('clients/getFullClients');
    },
    openAddModal() {
      if (this.getIsDemoCatalog) {
        this.$router.push({ name: 'tariffs' });
        return;
      }
      this.showAddModal = true;
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
    getIsDemoCatalog() {
      return this.$store.getters['user/getIsDemoCatalog'];
    },
  },
};
</script>

<style lang="scss" scoped>
.clients-screen {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
}

.clients-screen__title-tab {
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  &_selected {
    color: $primary-blue;
  }
}

.clients-screen__header {
  margin: 0px 20px;
}

.clients-screen__title-tabs {
  display: flex;
  gap: 20px;
}

.clients-screen__tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clients-screen__clients-tabs {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.clients-screen__clients-tab {
  color: rgba($primary-gray, 0.5);
  font-size: 13px;
  transition: 0.2s;
  cursor: pointer;
  position: relative;
  &_selected {
    color: $primary-blue;
    &:after {
      position: absolute;
      top: 20px;
      left: 0px;
      content: '';
      width: 100%;
      height: 1px;
      background: $primary-blue;
    }
  }
}
</style>
