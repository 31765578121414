<template>
  <div
  :class="isEmptyData && 'group-contentanalysis_empty'"
  class="group-contentanalysis">
    <div>
      <div class="group-contentanalysis__overal">
        <div
        class="group-contentanalysis__circle"
        :class="getCirleClass && ['group-contentanalysis__circle_' + getCirleClass]" />
        <span
        class="group-contentanalysis__text"
        :class="!data.cleanContentScore && 'group-contentanalysis__text_light'">{{ getContentScore }}</span>
        <span class="group-contentanalysis__text_light">{{ $t('rating') + ' SJI' }}</span>
      </div>
      <div
      v-if="!hideDetails && isAdmin"
      class="group-contentanalysis__elements">
        <span
        class="group-contentanalysis__text group-contentanalysis__text_light">
          {{ getErotic }}
        </span>
        <span
        class="group-contentanalysis__text group-contentanalysis__text_light">
          {{ getViolence }}
        </span>
        <span
        class="group-contentanalysis__text group-contentanalysis__text_light">
          {{ getSwearing }}
        </span>
        <span
        class="group-contentanalysis__text group-contentanalysis__text_light">
          {{ processedPostsCountTitle }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupContentAnalysis',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
    processedPostsCount: {
      type: [String, Number],
      default: null,
    },
    disableEditor: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    processedPostsCountTitle: null,
  }),
  created() {
    this.processedPostsCountTitle = `Обработано: ${this.processedPostsCount && this.processedPostsCount !== 0
      ? this.processedPostsCount
      : '—'}`;
  },
  computed: {
    getContentScore() {
      return this.data.cleanContentScore !== null ? parseFloat(this.data.cleanContentScore).toFixed(1) : '—';
    },
    getCirleClass() {
      if (!this.data.cleanContentScore) return false;
      if (this.data.cleanContentScore <= 4) {
        return 'red';
      }
      if (this.data.cleanContentScore <= 7) {
        return 'yellow';
      }
      return 'green';
    },
    getErotic() {
      return `${this.$t('Erotic')}: ${this.data.erotic ? this.data.erotic : '—'}`;
    },
    getViolence() {
      return `${this.$t('Violence')}: ${this.data.violence ? this.data.violence : '—'}`;
    },
    getSwearing() {
      return `${this.$t('Swearing')}: ${this.data.swearing ? this.data.swearing : '—'}`;
    },
    isEmptyData() {
      return this.data === null || this.data === undefined
      || ((this.data?.cleanContentScore && this.data?.cleanContentScore === null)
      && this.data.erotic === null
      && this.data.swearing === null
      && this.data.violence === null
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.group-contentanalysis {
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 30px;
  height: 50px;
  &_empty {
    padding-left: 0px;
    align-items: center;
  }
}

.group-contentanalysis__elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.group-contentanalysis__overal {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include between-elements {
    margin-right: 10px;
  }
}

.group-contentanalysis__circle {
  width: 8px;
  height: 8px;
  background: $primary-lightgray;
  border-radius: 100%;
  padding-right: 5px;
  &_red {
    background: $primary-red;
  }
  &_yellow {
    background: $primary-yellow;
  }
  &_green {
    background: $primary-green;
  }
}

.group-contentanalysis__text {
  font-size: 13px;
  color: $primary-black;
  line-height: 15px;
  &_small {
    font-size: 12px;
    color: $primary-black;
    padding-right: 0px;
  }
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
    line-height: 12px;
    padding-right: 0px;
    overflow-wrap: break-word;
  }
}
</style>
