import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    selectedList: [],
    compilationsList: [],
    compilationsFolders: [],
    simpleCompilationsList: [], // массив с поборками в простом формате (без агрегации)
    //
    currentCompilationGroups: [],
    currentCompilationInfo: [],
    currentCompilationSummary: null,
    folderCompilationSummary: null,
    //
    totalPageCount: null,
    sortDir: null,
    sortBy: null,
    loadParams: {
      'page-size': 50,
      page: 1,
    },
    compilationToken: null,
    loadingCompilation: false,
    enableDragMode: false,
    selectedDragElement: null,
    selectedCompilationsFolder: null,
    selectedFolder: null,
    draggableFolder: null,
    triggerForSelectedAllGroups: false,
    selectedPriceType: 'postPrice',
    selectedPriceVariant: { name: 'per24', value: '24' },
  },
  getters: {
    getTotalPageCounts: (state) => state.totalPageCount,
    getCurrentPage: (state) => state.loadParams.page,
    //
    getCompilationsList: (state) => state.compilationsList,
    getSimpleCompilationsList: (state) => state.simpleCompilationsList,
    getCompilationsSelectedList: (state) => state.selectedList,
    getCurrentCompilationGroups: (state) => state.currentCompilationGroups,
    getCurrentCompilationInfo: (state) => state.currentCompilationInfo,
    getCurrentCompilationSummary: (state) => state.currentCompilationSummary,
    getFolderCompilationSummary: (state) => state.folderCompilationSummary,
    getGroupsSortBy: (state) => state.sortBy,
    getGroupsSortDir: (state) => state.sortDir,
    getCompilationToken: (state) => state.compilationToken,
    getIsLoadingCompilation: (state) => state.loadingCompilation,
    getEnableDragMode: (state) => state.enableDragMode,
    getSelectedFolder: (state) => state.selectedFolder,
    getSelectedCompilationsFolder: (state) => state.selectedCompilationsFolder,
    getSelectedDragElement: (state) => state.selectedDragElement,
    getDraggableFolder: (state) => state.draggableFolder,
    getTriggerForSelectedAllGroups: (state) => state.triggerForSelectedAllGroups,
    getSelectedPriceType: (state) => state.selectedPriceType,
    getSelectedPriceVariant: (state) => state.selectedPriceVariant,
    getCompilationsFolders: (state) => state.compilationsFolders,
  },
  mutations: {
    setSelectedCompilationList: (state, value) => { state.selectedList = value; },
    setCurrentCompilationGroups: (state, value) => { state.currentCompilationGroups = value; },
    setCurrentCompilationInfo: (state, value) => { state.currentCompilationInfo = value; },
    setCurrentCompilationSummary: (state, value) => { state.currentCompilationSummary = value; },
    setFolderCompilationSummary: (state, value) => { state.folderCompilationSummary = value; },
    setCompilationsList: (state, value) => { state.compilationsList = value; },
    setSimpleCompilationsList: (state, value) => { state.simpleCompilationsList = value; },
    setCompilationToken: (state, value) => { state.compilationToken = value; },
    //
    setTotalPageCount: (state, value) => { state.totalPageCount = value; },
    setLoadPage: (state, value) => { state.loadParams.page = value; },
    //
    setSortBy: (state, value) => { state.sortBy = value; },
    setSortDir: (state, value) => { state.sortDir = value; },
    //
    setIsLoadingCompilation: (state, value) => { state.loadingCompilation = value; },
    setEnableDragMode: (state, value) => { state.enableDragMode = value; },
    setSelectedFolder: (state, value) => { state.selectedFolder = value; },
    setSelectedCompilationsFolder: (state, value) => { state.selectedCompilationsFolder = value; },
    setSelectedDragElement: (state, value) => { state.selectedDragElement = value; },
    setDraggableFolder: (state, value) => { state.draggableFolder = value; },
    setTriggerForSelectedAllGroups: (state, value) => { state.triggerForSelectedAllGroups = value; },
    // price switcher
    setSelectedPriceType: (state, value) => { state.selectedPriceType = value; },
    setSelectedPriceVariant: (state, value) => { state.selectedPriceVariant = value; },
    // compilations folders
    setCompilationsFolders: (state, value) => { state.compilationsFolders = value; },
  },
  actions: {
    // Удаление каналов из подборки
    removeGroupsFromCompilation({ state, commit, dispatch }, { id, groups }) {
      const groupsToDelete = {
        groupIdentities: groups.map((item) => ({
          id: item.id,
          social: item.social,
        })),
        shareToken: state.compilationToken || null,
      };
      return api.post(`/compilations/${id}/delete-groups`, groupsToDelete)
        .then(() => {
          const newGroups = state.currentCompilationGroups.filter((group) => !groups.some((item) => item.id === group.id && item.name === group.name));
          commit('setCurrentCompilationGroups', newGroups);
          commit('setSelectedCompilationList', []);
          dispatch('getCurrentCompilationSummary', { id });
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- removeGroupsFromCompilation --; Error -', error);
          return Promise.reject();
        });
    },

    // Добавление каналов в подборку
    addGroupsToCompilation({ commit }, { id, groups }) {
      const groupsToAdd = {
        groupIdentities: groups.map((item) => ({
          id: item.id,
          social: item.social,
        })),
      };
      return api.post(`/compilations/${id}/add-groups`, groupsToAdd)
        .then(() => Promise.resolve())
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- addGroupsToCompilation --; Error -', error);
          return Promise.reject();
        });
    },

    // Создание новой подборки
    createNewCompilation({ state, commit }, { name, groups }) {
      commit('app/setAppIsLoading', true, { root: true });
      const groupIdentities = groups.map((group) => ({ id: group.id, social: group.social }));
      const compilation = { name, groupIdentities };

      return api.post('/compilations', compilation)
        .then(({ data: { data } }) => {
          commit('setSimpleCompilationsList', [data, ...state.simpleCompilationsList]);
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- createNewCompilation --; Error -', error);
          return Promise.reject();
        })
        .finally(() => {
          commit('app/setAppIsLoading', false, { root: true });
        });
    },

    // Отправка уведомления о размещении
    sendShareNotification({ dispatch }, params) {
      return api.post('/compilation/send-notification-to-publisher', params)
        .then(() => Promise.resolve())
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'sendShareNotification' }, { root: true });
          console.warn('Error in action -- sendShareNotification --; Error -', error);
          return Promise.reject();
        });
    },

    // Сохранение подборки к себе
    saveCompilationForYourSelf({ commit, dispatch }, params) {
      commit('app/setAppIsLoading', true, { root: true });
      const payload = { name: params.name, compilationId: params.compilationId };

      return api.post('/compilations', payload)
        .then(() => Promise.resolve())
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'deleteFolderForCompilation' }, { root: true });
          console.warn('Error in action -- saveCompilationForYourSelf --; Error -', error);
          return Promise.reject();
        })
        .finally(() => {
          commit('app/setAppIsLoading', false, { root: true });
        });
    },

    // Отправка расшаренной подборки
    createAndShareCompilation({ commit }, collection) {
      commit('app/setAppIsLoading', true, { root: true });
      return api.post('/compilations/create-and-share', collection)
        .then(({ data: { data } }) => {
          commit('app/setAppIsLoading', false, { root: true });
          return Promise.resolve(data);
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action createAndShareCompilation; Error -', error);
          return Promise.reject(error);
        });
    },

    // Удаление подборки
    deleteCompilation({ state, commit }, id) {
      return api.delete(`/compilations/${id}`)
        .then(() => {
          const newCompilationList = state.compilationsList.filter((compilation) => {
            if (!compilation.compilation) {
              return false;
            }
            return compilation.compilation.id !== id;
          });
          commit('setCompilationsList', newCompilationList);
          commit('app/setAppIsLoading', false, { root: true });
          return Promise.resolve();
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- deleteCompilation --; Error -', error);
          return Promise.reject();
        });
    },

    // Переименовать подборку
    renameCompilation({ state, commit }, { id, name }) {
      commit('app/setAppIsLoading', true, { root: true });
      const compilation = {
        name,
      };
      return api.put(`/compilations/${id}`, compilation)
        .then(() => {
          const newCompilationList = state.compilationsList.map((item) => {
            if (item.id === compilation.id) {
              item.name = compilation.name;
              return item;
            }
            return item;
          });
          commit('setCompilationsList', newCompilationList);
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- createNewCompilation --; Error -', error);
          return Promise.reject();
        })
        .finally(() => {
          commit('app/setAppIsLoading', false, { root: true });
        });
    },

    // Изменение наценки подборки
    changeCollectionPrice({ state, commit }, compilationData) {
      commit('app/setAppIsLoading', true, { root: true });
      return api.post('/compilations/trade-offer', compilationData)
        .then(({ data: { data } }) => {
          const newGroups = state.currentCompilationGroups.map((group) => {
            const newGroup = data.find((item) => item.id === group.id && item.social === group.social);
            return newGroup || group;
          });
          commit('setCurrentCompilationGroups', newGroups);
          commit('app/setAppIsLoading', false, { root: true });
          return Promise.resolve(data);
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action changeCollectionPrice; Error -', error);
          return Promise.reject(error);
        });
    },

    // Расшарить подборку
    shareCompilation({ commit }, id) {
      return api.post('/compilations/share', { id })
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action shareCompilation; Error -', error);
          return Promise.reject(error);
        });
    },

    // Получение списка подборок пользователя
    getUserCompilationsList({ commit }, params) {
      return api.get('/compilations-aggregate', { params })
        .then(({ data: { data } }) => {
          commit('setCompilationsList', data.reverse());
          return Promise.resolve(data);
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- getUserCompilationsList --; Error -', error);
          return Promise.reject();
        });
    },

    // Получение списка подборок пользователя (Старый)
    getUserCompilationsListOld({ commit }, userId) {
      return api.get('/compilations', {
        params: {
          ownerUserId: userId,
        },
      })
        .then(({ data: { data } }) => {
          commit('setSimpleCompilationsList', data.reverse());
          return Promise.resolve(data);
        })
        .catch((error) => {
          console.warn('Error in action -- getUserCompilationsList --; Error -', error);
          return Promise.reject();
        });
    },

    // Получение каналов из подборки
    getCurrentCompilationGroups({ state, commit }, params) {
      commit('app/setAppIsLoading', true, { root: true });

      if (params.reset) {
        commit('setCurrentCompilationGroups', []);
        commit('setLoadPage', 1);
        commit('setTotalPageCount', 0);
      }

      return api.get(`/compilations/${params.id}/positions`, {
        params: {
          ...state.loadParams,
          shareToken: state.compilationToken || params.shareToken || null,
          sortBy: state.sortBy,
          sortDirection: state.sortDir,
          folderId: state.selectedFolder?.id,
          responsibleUserIds: params.responsibleUserIds,
          withoutResponsibleUser: params.withoutResponsibleUser,
        },
      })
        .then((response) => {
          const groups = response.data.data.map((group) => {
            group.group.positionId = group.id;
            group.group.responsibleUser = group.responsibleUser;
            group.group.folder = group.folder;
            group.group.note = group.comment;
            group.group.positionId = group.id;
            return group.group;
          });
          commit('setCurrentCompilationGroups', [...state.currentCompilationGroups, ...groups]);
          if (state.triggerForSelectedAllGroups) {
            const groupsToSelected = [...state.selectedList, ...groups];
            commit('setSelectedCompilationList', groupsToSelected);
          }
          commit('setLoadPage', state.loadParams.page + 1);
          commit('setTotalPageCount', response.headers['x-pagination-page-count']);
          commit('app/setAppIsLoading', false, { root: true });
          return Promise.resolve(response);
        })

        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action getCurrentCompilationGroups; Error -', error);
          return Promise.reject(error);
        });
    },

    // Получение информации по подборке
    getCurrentCompilationInfo({ commit }, params) {
      return api.get(`/compilations/${params.id}`, { params: { shareToken: params.shareToken } })
        .then(({ data: { data } }) => {
          commit('setCurrentCompilationInfo', data[0]);
          return Promise.resolve(data[0]);
        })
        .catch((error) => {
          console.warn('Error in action getCurrentCompilationInfo; Error -', error);
          return Promise.reject(error);
        });
    },

    // Получение сводки по подборке
    getCurrentCompilationSummary({ commit }, payload) {
      const params = {
        compilationId: payload.id,
        folderId: payload.folderId,
        summaryTypes: [
          'countSelectedGroups',
          'sumPostPriceSell',
          'sumPostPriceSale',
          'sumPostPriceBuy',
          'sumSubscribersCount',
          'sumViewsLast24hCount',
          'sumViewsStatisticCount',
          'sumTradeOffer',
          'averageCpm',
        ],
      };
      return api.post('/summary/calculate', params, { params: { shareToken: payload.shareToken } })
        .then(({ data: { data } }) => {
          if (payload.isFolder) {
            commit('setFolderCompilationSummary', data);
          } else {
            commit('setCurrentCompilationSummary', data);
          }
          return Promise.resolve(data);
        })
        .catch((error) => {
          console.warn('Error in action getCurrentCompilationSummary; Error -', error);
          return Promise.reject(error);
        });
    },

    // ПАПКИ В СПИСКЕ ПОДБОРОК

    // Получение списка папок пользователя
    getCompilationsFolders({ commit }, id) {
      return api.get(`/compilation/${id}/aggregate-compilation-folders`)
        .then(({ data: { data } }) => {
          const folders = data.map((item) => ({
            ...item,
            name: item.name === 'root' ? 'Без папки' : item.name,
          }));
          commit('setCompilationsFolders', folders);
          return Promise.resolve(data);
        })
        .catch((error) => {
          console.warn('Error in action -- getCompilationsFolders --; Error -', error);
          return Promise.reject();
        });
    },

    // Получение подборок из конкретной папки
    getCompilationsInFolder({ commit }, id) {
      return api.get('/compilation/aggregate-compilation-position', { params: { folderId: id } })
        .then(({ data: { data } }) => {
          const compilations = data.map((item) => (
            {
              ...item.compilationAggregate,
              compilationPositionId: item.id,
              folder: item.compilationAggregateFolder,
            }));
          commit('setCompilationsList', compilations);
          return Promise.resolve(data);
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- getCompilationsInFolder --; Error -', error);
          return Promise.reject();
        });
    },

    // Создание папки для подборок
    createFolderForCompilations({ state, commit, dispatch }, params) {
      return api.post('/compilations/create-aggregate-compilation-folder', { folderName: params.folderName, userId: params.userId })
        .then(({ data: { data } }) => {
          state.compilationsFolders.unshift(data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          dispatch('app/createApiErrorMessage', { error, action: 'deleteFolderForCompilation' }, { root: true });
          console.warn('Error in action -- createFolderForCompilations --; Error -', error);
          return Promise.reject();
        });
    },

    // Удаление папки для подборки
    deleteFolderForCompilation({ state, commit, dispatch }, id) {
      return api.delete('/compilations/delete-aggregate-compilation-folder', { params: { folderId: id } })
        .then(() => {
          state.compilationsFolders = state.compilationsFolders.filter((folder) => folder.id !== id);
          return Promise.resolve();
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          dispatch('app/createApiErrorMessage', { error, action: 'deleteFolderForCompilation' }, { root: true });
          console.warn('Error in action -- deleteFolderForCompilation --; Error -', error);
          return Promise.reject();
        });
    },

    // Переименовать папку для подборок
    renameFolderForCompilation({ state, dispatch }, params) {
      return api.patch('/compilations/rename-aggregate-compilation-folder', params)
        .then(() => {
          state.compilationsFolders = state.compilationsFolders.map((item) => {
            if (item.id === params.folderId) {
              item.name = params.folderName;
            }
            return item;
          });
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'renameFolderForCompilation' }, { root: true });
          console.warn('Error in action -- renameFolderForCompilation --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Добавить подборку в папку
    addCompilationToFolder({ state, commit }, params) {
      return api.post('/compilation/add-aggregate-compilation-to-folder', {
        folderId: params.folderId,
        aggregateCompilationId: params.compilationId,
      })
        .then(({ data: { data } }) => {
          state.compilationsList = state.compilationsList.filter((item) => item.id !== params.compilationId);
          return Promise.resolve(data);
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- addCompilationToFolder --; Error -', error);
          return Promise.reject();
        });
    },

    // Изменить позицию папки внутри списка папок
    moveFolderInFolderList({ commit }, params) {
      return api.patch(`/compilation/${params.userId}/move-aggregate-compilation-folder`, params.payload)
        .then(() => Promise.resolve())
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- moveCompilationPositionInFolder --; Error -', error);
          return Promise.reject();
        });
    },

    // Изменить позици подборки внутри списка подборок
    moveCompilationPositionInFolder({ commit }, params) {
      return api.patch(`/compilation/${params.userId}/move-aggregate-compilation-position`, params.payload)
        .then(() => Promise.resolve())
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- moveCompilationPositionInFolder --; Error -', error);
          return Promise.reject();
        });
    },

    // ПОДБОРКА

    // Создание папки в подборке
    createFolderInCompilation({ state, commit }, params) {
      const payload = {
        shareToken: params.shareToken,
        folderName: params.folderName,
      };

      return api.post(`/compilations/${params.compilationId}/create-folder`, payload)
        .then(({ data: { data } }) => {
          state.currentCompilationInfo.folders.unshift(data);
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- createFolderInCompilation --; Error -', error);
          return Promise.reject();
        });
    },

    // Удаление папки из подборки
    deleteFolderInCompilation({ state, commit, dispatch }, params) {
      const payload = {
        shareToken: params.shareToken,
        folderId: params.folderId,
      };

      return api.post(`/compilations/${params.compilationId}/delete-folder`, payload)
        .then(({ data: { data } }) => {
          state.currentCompilationInfo.folders = state.currentCompilationInfo.folders.filter((item) => item.id !== params.folderId);
          if (state.selectedFolder && params.folderId === state.selectedFolder.id) {
            commit('setSelectedFolder', null);
            dispatch('getCurrentCompilationGroups', { id: params.compilationId, reset: true });
          }
          return Promise.resolve(data);
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- deleteFolderInCompilation --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Переименовать папку в подборке
    renameFolderInCompilation({ state, commit }, params) {
      const payload = {
        shareToken: params.shareToken,
        folderName: params.folderName,
        folderId: params.folderId,
      };

      return api.post(`/compilations/${params.compilationId}/rename-folder`, payload)
        .then(() => {
          state.currentCompilationInfo.folders.forEach((item) => {
            if (item.id === params.folderId) {
              item.name = params.folderName;
            }
          });
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- renameFolderInCompilation --; Error -', error);
          return Promise.reject();
        });
    },

    // Изменить позиции канала
    movePositionInCompilation({ state, commit }, params) {
      const payload = {
        shareToken: params.shareToken,
        positionsIds: params.positionsIds, // id канала и его позиции
        folderId: params.folderId, // id папки
        destinationPositionId: params.destinationPositionId, // канал перед которым надо вставить (передается Place after или before)
        place: params.place, // after or before
      };

      return api.post(`/compilations/${params.compilationId}/move-position`, payload)
        .then(() => {
          try {
            if (params.inOneFolder) return;
            state.currentCompilationGroups = state.currentCompilationGroups.filter((item) => !params.positionsIds.includes(item.positionId));
            commit('setSelectedCompilationList', []);
          } catch (err) {
            console.warn(err);
          }
        })
        .catch((error) => {
          commit('setSelectedCompilationList', []);
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- movePositionInCompilation --; Error -', error);
          return Promise.reject();
        });
    },

    // Смена ответственного
    changeResponsibleInPosition({ state, dispatch }, {
      positionsIds, responsibleUserId, compilationId, responsibleUserObject,
    }) {
      const params = {
        positionsIds,
        responsibleUserId,
      };
      return api.post(`/compilations/${compilationId}/set-responsible-user`, params)
        .then(() => {
          state.currentCompilationGroups.forEach((item) => {
            if (positionsIds.includes(item.positionId)) {
              item.responsibleUser = responsibleUserObject;
            }
          });
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'changeResponsibleInPosition' }, { root: true });
          console.warn('Error in action -- changeResponsibleInPosition --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Удаление ответственного
    deleteResponsibleInPosition({ state, dispatch }, { positionsIds, compilationId }) {
      const params = { positionsIds };
      return api.post(`/compilations/${compilationId}/remove-responsible-user`, params)
        .then(() => {
          state.currentCompilationGroups.forEach((item) => {
            if (positionsIds.includes(item.positionId)) {
              item.responsibleUser = null;
            }
          });
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'deleteResponsibleInPosition' }, { root: true });
          console.warn('Error in action -- deleteResponsibleInPosition --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Добавление коммента (примечание) к каналу в подборке
    addCommentToGroupInCompilation({ state, dispatch }, params) {
      return api.post('/compilations/add-comment', params)
        .then(() => {
          state.currentCompilationGroups.forEach((item) => {
            if (item.id === params.positionsId) {
              item.comment = params.comment;
              item.group.comment = params.comment;
            }
          });
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'addCommentToGroupInCompilation' }, { root: true });
          console.warn('Error in action -- addCommentToGroupInCompilation --; Error -', error);
          return Promise.reject(error);
        });
    },
  },
};
