<template>
  <div class="campaigns">
    <div
    v-if="getCampaignsData.length"
    class="campaigns-wrapper">
      <div
      ref="campaignsContent"
      class="campaigns__content"
      @scroll="onScroll"
      @mousedown.left.stop="mouseDown"
      @mouseup.left="mouseUp"
      @mouseleave="mouseLeave"
      @mousemove="mouseMove">
        <CampaignsList :campaigns-data="getCampaignsData" />
      </div>
    </div>
    <span
    v-else-if="!isLoading"
    class="campaigns__text campaigns__text_light">
      Список кампаний пуст
    </span>
  </div>
</template>

<script>
import CampaignsList from './CampaignsList.vue';

export default {
  name: 'Campaigns',
  components: { CampaignsList },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isScroll: false,
    startX: null,
    scrollLeft: null,
  }),
  methods: {
    onScroll() {
      if (this.isLastPage || this.isLoading) {
        return;
      }

      const position = this.$refs.campaignsContent.scrollTop;
      const { clientHeight } = this.$refs.campaignsContent;

      const endScroll = Math.ceil(position + clientHeight) >= Math.ceil(this.$refs.campaignsContent.scrollHeight);
      if (endScroll) {
        this.$emit('loadCampaignsData', { reset: false });
      }
    },
    mouseDown(e) {
      this.isScroll = true;
      this.startX = e.pageX - this.$refs.campaignsContent.offsetLeft;
      this.scrollLeft = this.$refs.campaignsContent.scrollLeft;
    },
    mouseLeave() {
      this.isScroll = false;
    },
    mouseUp() {
      this.isScroll = false;
    },
    mouseMove(e) {
      const contentWidth = this.$refs.campaignsContent.clientWidth;
      if (!this.isScroll || this.showGroupMenu || contentWidth > 1530) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.campaignsContent.offsetLeft;
      const walk = (x - this.startX) * 1; // scroll-fast
      this.$refs.campaignsContent.scrollLeft = this.scrollLeft - walk;
    },
  },
  computed: {
    getCampaignsData() {
      return this.$store.getters['campaigns/getCampaignsData'];
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
    isLastPage() {
      return this.$store.getters['campaigns/getIsLastPage'];
    },
  },
};
</script>

<style lang="scss" scoped>
.campaigns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: auto;
  background: white;
  border-radius: 12px;
  margin: 20px 0px;
}

.campaigns-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
  background: white;
}

.campaigns__content {
  overflow: auto;
  height: 100%;
}

.campaigns__elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.campaigns__text {
  font-size: 14px;
  color: #575757;
  &_light {
    color: $primary-lightgray;
  }
}
</style>
