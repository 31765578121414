<template>
  <div
  v-click-outside="() => { $emit('close') }"
  class="change-margin">
    <div class="change-margin__new-value-container">
      <span class="change-margin__value-text">
        Новая наценка:
      </span>
      <DataInput
      v-model="newMargin"
      class="change-margin__new-value-input" />
      <DataButton
      icon="done"
      width="40"
      height="40"
      @handler="setMargin" />
    </div>
    <span
    v-if="newValueError && isInvalidNewValue"
    class="change-margin__value-text change-margin__value-text_invalid">Укажите наценку от 1 до 100</span>
  </div>
</template>

<script>
export default {
  name: 'ChangeMargin',
  props: {
    selectedGroups: {
      type: Array,
      default: null,
    },
    compilation: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    newMargin: '',
    newValueError: false,
  }),
  methods: {
    setMargin() {
      if (this.isInvalidNewValue) {
        this.newValueError = true;
        return;
      }
      this.newValueError = false;

      const params = {
        compilationId: String(this.compilation.id),
        type: 'margin',
        positions: [],
      };

      this.selectedGroups.forEach((item) => {
        params.positions.push({
          groupIdentity: {
            id: item.id,
            social: item.social,
          },
          value: this.newMargin,
          type: 'margin',
        });
      });

      this.$store.dispatch('compilations/changeCollectionPrice', params)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Наценка изменена' });
        });
      this.$emit('close');
    },
  },
  computed: {
    isInvalidNewValue() {
      return !this.newMargin || Number(this.newMargin) > 100 || Number(this.newMargin) < 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.change-margin {
  background-color: white;
  width: 290px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  border-radius: 12px;
  padding: 10px 20px;
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  .change-margin__value-text {
    color: $primary-black;
    font-size: 14px;
    white-space: nowrap;
    &_invalid {
      color: $primary-red;
      margin-top: 5px;
    }
  }

  .change-margin__new-value-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include between-elements {
      margin-right: 10px;
    }
  }

  .change-margin__new-value-input {
    width: 75px;
  }
}

</style>
