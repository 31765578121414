<template>
  <div class="selected-groups">
    <div
    v-if="isLoading"
    class="selected-groups__loading">
      <DataPreloader />
      <span class="selected-groups__button-text">Получаем информацию о каналах...</span>
    </div>
    <div
    v-else
    class="selected-groups__content">
      <div class="selected-group__totals">
        <div class="selected-group__total-column">
          <span class="selected-group__total-title">Выбрано каналов:</span>
          <span class="selected-group__total-count">
            {{ selectedData.length }}
          </span>
          <div class="selected-group__total-data">
            <div class="selected-group__total-item">
              <img src="../assets/Eye.png">
              <span class="selected-group__total-text">{{ getTotalSelectedViews }}</span>
            </div>
            <div class="selected-group__total-item">
              <img src="../assets/UserCircle.png">
              <span class="selected-group__total-text">{{ getTotalSelectedSubscribers }}</span>
            </div>
          </div>
        </div>
        <div class="selected-group__total-column">
          <span class="selected-group__total-title">Цена</span>
          <span class="selected-group__total-count">
            {{ setNumberSpaces(getTotalSelectedPrice) + ' ₽' }}
          </span>
          <div class="selected-group__total-item">
            <span class="selected-group__total-text">{{ getTotalPriceWithNds }}</span>
          </div>
        </div>
      </div>

      <div
      v-if="selectedData.length"
      class="selected-groups__groups">
        <div
        v-for="group in selectedData"
        :key="group.id"
        class="selected-groups__group">
          <!-- Название канала, категория -->
          <div
          class="selected-groups__group-name-block"
          @click="routeToCurrentGroupLink(group)">
            <img
            class="selected-groups__group-avatar"
            :src="group.avatarSrc ? group.avatarSrc : require('@/assets/svg/empty-avatar.svg')">
            <img
            class="selected-groups__group-social-icon"
            :src="require(`@/assets/svg/groups-icons/${group.social}-icon.svg`)">
            <div class="selected-groups__group-name">
              <span class="selected-groups__group-name-text">
                {{ group.name }}
              </span>
              <span
              v-if="group.categories"
              :title="getCategories(group)"
              class="selected-groups__group-name-text selected-groups__group-name-text_category">
                {{ getCategories(group) }}
              </span>
            </div>
          </div>

          <!-- Иконка удаления канала -->
          <!-- Иконка удаления канала из выбранных каналов доступна только при создании, в режиме просмотра подборки удаление невозможно -->
          <img
          v-if="!viewMode"
          class="selected-groups__trash-icon"
          src="../assets/Trash.png"
          @click="deleteGroup(group)">
          <div class="selected-groups__price-block">
            <span class="selected-groups__price">
              {{ basePrice(group) }}
            </span>
            <span class="selected-groups__group-name-text_category">
              {{ group.social === 'mave' ? 'Размещение': 'Пост 24ч ' }}
            </span>
          </div>
        </div>
      </div>

      <div
      class="selected-groups__buttons">
        <DataButton
        type="outlined"
        :width="230"
        :text="viewMode ? 'Посмотреть подробности' : 'Запрос на доп подборку'"
        @handler="openAdditional" />
        <span
        v-if="!viewMode"
        class="selected-groups__button-text">
          Поможем расширить медиа-план, подобрав дополнительные паблики
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreatePlacementSelectedGroups',
  props: {
    selectedGroups: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedData: null,
  }),
  created() {
    this.initGroups();
  },
  methods: {
    initGroups() {
      this.selectedData = this.selectedGroups.map((group) => {
        const avatarSrc = group.social === 'mave' ? group.coverImage : group.avatar;
        return {
          ...group,
          avatarSrc: avatarSrc || null,
        };
      });
    },
    getCategories(group) {
      return group.categories.map((category) => this.$t(category.name)).join(', ');
    },
    openAdditional() {
      this.$emit('openAdditional');
    },
    deleteGroup(group) {
      this.selectedData = this.selectedData.filter((item) => !(item.id === group.id && item.name === group.name));
      this.$emit('setSelectedData', this.selectedData);
    },
    basePrice(group) {
      try {
        const currentProducts = group.products.filter((item) => item.type === 1); // находим объекты с нужным продуктом
        if (!currentProducts.length) {
          return '-';
        }
        const currentProduct = currentProducts[0];
        const resultPrice = currentProduct?.tradeOffersChain?.currentTradeOffer?.resultPrice || null;

        if (!resultPrice && +resultPrice !== 0) {
          return this.$t('no_data_available');
        }
        return `${this.setNumberSpaces(Math.round(resultPrice))} ${this.getCurrencySymbol}`;
      } catch {
        return '?';
      }
    },
    routeToCurrentGroupLink(group) {
      if (!group.url.match(/^https?:\/\//i)) {
        window.open(`https://${group.url}`);
      } else {
        window.open(group.url);
      }
    },
  },
  computed: {
    getTotalSelectedPrice() {
      try {
        const totalSell = Math.round(this.selectedData.reduce((prev, cur) => {
          const currentOffer = cur.products.find((item) => item.type === 1);
          const currentPrice = currentOffer
            ? currentOffer.tradeOffersChain?.currentTradeOffer?.resultPrice || 0
            : 0;
          return prev + currentPrice;
        }, 0));

        return totalSell;
      } catch {
        return '?';
      }
    },
    getTotalPriceWithNds() {
      try {
        const totalWithNds = Math.round(this.getTotalSelectedPrice * (1 + 0.20));
        return `(${this.setNumberSpaces(totalWithNds)} ${this.$t('nds')})`;
      } catch {
        return '?';
      }
    },
    getCurrencySymbol() {
      return this.$store.getters['app/getCurrencySymbol'];
    },
    getTotalSelectedSubscribers() {
      return this.setNumberSpaces(this.selectedData.reduce((prev, cur) => prev + +(cur.subscribersStatistic?.count || 0), 0));
    },
    getTotalSelectedViews() {
      return this.setNumberSpaces(this.selectedData.reduce((prev, cur) => {
        if (cur.social === 'mave') {
          return prev + +(cur.listeningStatistic?.averagePerMonth || 0);
        }
        return prev + +(cur.viewsStatistic?.countLast24h || 0);
      }, 0));
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-groups {
  background: #EDFBFF;
  width: 100%;
}

.selected-groups__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 40px;
  max-height: 600px;
  min-height: 100%;
}

.selected-groups__loading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #EDFBFF;
  padding: 40px;
  min-height: 100%;
  max-height: 600px;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.selected-group__totals {
  width: 100%;
  background: white;
  border: 1px solid #cceffa;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  @include between-elements {
    margin-right: 30px;
  }
}

.selected-group__total-column {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.selected-groups__group-name-block {
  display: flex;
  cursor: pointer;
}

.selected-group__total-title {
  font-size: 16px;
  color: #7B7B7B;
}

.selected-group__total-text {
  color: #7B7B7B;
  font-size: 14px;
}

.selected-group__total-count {
  font-size: 36px;
  color: $primary-blue;
  line-height: 36px;
  font-weight: bold;
}

.selected-group__total-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include between-elements {
    margin-right: 10px;
  }
}

.selected-group__total-item {
  display: flex;
  height: 20px;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 5px;
  }
}

.selected-groups__groups {
  border-radius: 16px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 10px;
  border: 1px solid #cceffa;
  background: white;
  min-height: 100%;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.selected-groups__group {
  display: flex;
  border-bottom: 1px solid #cceffa;
  padding-bottom: 10px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.selected-groups__group-avatar {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 10px;
}

.selected-groups__group-name {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 7px;
  }
}

.selected-groups__group-name-text {
  color: $primary-blue;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 120px;
  @include desktop-screen {
    width: 240px;
  }
  &_category {
    color: $primary-lightgray;
    font-size: 12px;
  }
}

.selected-groups__trash-icon {
  width: 24px;
  height: 24px;
  padding: 5px;
  box-sizing: content-box;
  cursor: pointer;
  margin: 0px 10px 0px 30px;
}

.selected-groups__buttons {
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  margin-top: 20px;
  @include between-elements {
    margin-right: 20px;
  }
}

.selected-groups__button-text {
  color: $primary-lightgray;
  font-size: 14px;
}

.selected-groups__price-block {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  white-space: nowrap;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.selected-groups__price {
  color: $primary-blue;
  font-size: 16px;
}

.selected-groups__group-social-icon {
  position: absolute;
  top: 0px;
  left: 35px;
  width: 18px;
  height: 18px;
  border-radius: 100%;
}
</style>
