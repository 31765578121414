<template>
  <div class="competitors-screen">
    <div class="competitors-screen__wrapper">
      <div class="competitors-screen__title">
        <span class="competitors-screen__text">
          Сравнение цен
        </span>
      </div>
      <CompetitorsFilters
      v-show="showFilters"
      ref="competitorsFilters"
      class="competitors-screen__filters"
      :is-active-filters="isActiveFilters"
      :current-search-params="getCurrentSearchParams"
      @resetFilters="resetFilters"
      @updateFilters="updateFilters" />
      <div
      v-if="isMobile"
      class="competitors-screen__filters-toggle"
      @click="showFilters = !showFilters">
        <span class="competitors-screen__title competitors-screen__title_filters_toggle">{{ showFilters ? 'Скрыть фильтры' : 'Показать фильтры' }}</span>
        <DataIcon
        name="expand_more"
        :arrow-open="showFilters"
        class="competitors-screen__title competitors-screen__title_filters_toggle"
        :size="16"
        pointer />
      </div>
      <div class="competitors-screen__tabs-actions">
        <div class="competitors-screen__tabs">
          <span
          v-for="tab in tabs"
          :key="tab.name"
          :class="tab.value === getSelectedSocial && 'competitors-screen__tab_selected'"
          class="competitors-screen__tab"
          @click="changeTab(tab)">
            {{ tab.name }}
          </span>
        </div>
        <div
        v-if="!isLoading"
        class="competitors-list__actions">
          <div
          class="competitors-screen__button"
          :class="{ 'competitors-screen__button_disable': !elementsSelected }"
          @click="hideSelectedElements">
            Скрыть
          </div>
          <div
          v-tooltip.left="getExportTooltip"
          class="competitors-screen__button"
          :class="{ 'competitors-screen__button_disable': !elementsSelected }"
          @click="openExport">
            Экспортировать
          </div>
        </div>
      </div>

      <CompetitorsList
      :is-loading="isLoading"
      :groups="getCompetitorsGroups"
      @loadCompetitorsGroups="loadCompetitorsGroups" />

      <DataModal
      :show-modal="showExportModal"
      @close="showExportModal = false">
        <template #modal-content>
          <CompetitorsExportModal
          :groups="getCompetitorsGroups"
          @close="showExportModal = false" />
        </template>
      </DataModal>
    </div>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import CompetitorsList from './components/CompetitorsList.vue';
import CompetitorsFilters from './components/CompetitorsFilters.vue';
import CompetitorsExportModal from './components/CompetitorsExportModal.vue';

export default {
  name: 'GroupsModerationScreen',
  components: {
    CompetitorsList, CompetitorsFilters, CompetitorsExportModal,
  },
  data: () => ({
    isLoading: false,
    tabs: [
      { name: 'Телеграмм', value: 'telegram' },
      { name: 'ВК', value: 'vk' },
      { name: 'Инстаграмм', value: 'instagram' },
      { name: 'Одноклассники', value: 'odnoklassniki' },
      { name: 'Facebook', value: 'facebook' },
      { name: 'Youtube', value: 'youtube' },
      { name: 'TikTok', value: 'tiktok' },
    ],
    showFilters: false,
    showExportModal: false,
  }),
  beforeDestroy() {
    this.$store.commit('competitors/setCompetitorsGroups', []);
  },
  created() {
    this.loadCompetitorsGroups({ reset: true });
    this.showFilters = !this.isMobile;
  },
  methods: {
    loadCompetitorsGroups(params) {
      if (this.isLoading) return;

      this.isLoading = true;
      this.$store.dispatch('competitors/getCompetitorsGroups', { reset: params.reset })
        .finally(() => {
          this.isLoading = false;
        });
    },
    changeTab(tab) {
      this.$store.commit('competitors/setSelectedSocial', tab.value);
      this.loadCompetitorsGroups({ reset: true });
    },
    resetFilters() {
      this.$store.commit('competitors/resetCompetitorsSearchParams');
      this.loadCompetitorsGroups({ reset: true });
    },
    updateFilters(filters) {
      this.$store.commit('competitors/setCompetitorsSearchParams', filters);
      this.loadCompetitorsGroups({ reset: true });
    },
    openExport() {
      if (!this.elementsSelected) return;
      this.showExportModal = true;
    },
    hideSelectedElements() {
      const ids = this.getCompetitorsGroups.filter((item) => item.externalPrice.checked === true).map((item) => item.externalPrice.id);
      this.$store.dispatch('competitors/hideElements', ids)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: ids.length > 1 ? 'Элементы скрыты' : 'Элемент скрыт' });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: 'Произошла ошибка. Попробуйте позже',
              error: true,
            });
        });
    },
  },
  computed: {
    getCompetitorsGroups() {
      return this.$store.getters['competitors/getCompetitorsGroups'];
    },
    getSelectedSocial() {
      return this.$store.getters['competitors/getSelectedSocial'];
    },
    isMobile() {
      return window.innerWidth < 768;
    },
    isActiveFilters() {
      return !isEqual(this.getCurrentSearchParams, this.getCurrentSearchParamsTemplate);
    },
    getCurrentSearchParams() {
      return this.$store.getters['competitors/getCompetitorsSearchParams'];
    },
    getCurrentSearchParamsTemplate() {
      return this.$store.getters['competitors/getCompetitorsSearchParamsTemplate'];
    },
    elementsSelected() {
      return this.getCompetitorsGroups.some((item) => item.externalPrice.checked === true);
    },
    getExportTooltip() {
      return this.elementsSelected ? null : 'Необходимо выбрать каналы для экспорта';
    },
  },
};
</script>

<style lang="scss" scoped>
.competitors-screen {
  display: flex;
  flex-direction: column;
}

.competitors-screen__wrapper {
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
}

.competitors-screen__title {
  @include between-elements {
    margin-right: 20px;
  }
}

.competitors-screen__text {
  font-size: 18px;
  font-weight: 600;
  color: #575757;
}

.competitors-screen__tabs-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include tablet-screen {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }
}

.competitors-screen__tabs {
  @include between-elements {
    margin-right: 15px;
  }
}

.competitors-screen__tab {
  color: rgba($primary-gray, 0.5);
  font-size: 13px;
  transition: 0.2s;
  cursor: pointer;
  position: relative;
  height: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &_selected {
    color: $primary-blue;
    &:after {
      position: absolute;
      top: 23px;
      left: 0px;
      content: '';
      width: 100%;
      height: 1px;
      background: $primary-blue;
    }
  }
}

.competitors-screen__filters {
  margin: 20px 0px;
}

.competitors-screen__filters-toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0px;
  cursor: pointer;
  @include between-elements {
    margin-right: 5px;
  }
}

.competitors-screen__title {
  font-size: 18px;
  font-weight: 600;
  &_filters_toggle {
    font-weight: 500;
    font-size: 16px;
    color: $primary-blue;
  }
}

.competitors-screen__button {
  margin-top: 10px;
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 6px;
  text-align: center;
  max-width: 200px;
  transition: 0.2s;
  cursor: pointer;
  color: $primary-white;
  background: $primary-blue;
  &_disable {
    cursor: pointer;
    background: $primary-blue;
    outline: 1px solid rgb(190, 190, 190);
    cursor: default;
    color: rgb(190, 190, 190);
    background: rgb(235, 235, 235);
    outline: 1px solid rgb(190, 190, 190);
  }
}

.competitors-list__actions {
  display: flex;
  @include between-elements {
    margin-right: 10px;
  }
}
</style>
