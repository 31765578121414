import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    groupsFavoriteData: [],
    isShowFavorite: false,
  },
  getters: {
    //
    getFavoriteGroupsData: (state) => state.groupsFavoriteData,
    getShowFavoriteGroups: (state) => state.isShowFavorite,
    //
  },
  mutations: {
    setGroupsFavoriteData: (state, value) => { state.groupsFavoriteData = value; },
    setShowFavoriteGroups: (state, value) => { state.isShowFavorite = value; },
  },
  actions: {
    // Получение списка избранных
    getUserFavoritesList({ commit }) {
      return api.get('/favourites')
        .then(({ data: { data } }) => {
          commit('setGroupsFavoriteData', data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          console.warn('Error in action -- getUserFavoritesList --; Error -', error);
          return Promise.reject();
        });
    },

    // Добавление канала в избранное
    addGroupsToFavorites({ state, commit }, groups) {
      const newFavorites = groups.filter((group) => !state.groupsFavoriteData.some((item) => item.id === group.id && item.name === group.name));

      if (!newFavorites.length) {
        return Promise.resolve();
      }

      const groupsToAdd = {
        groupIdentities: newFavorites.map((item) => ({
          id: item.id,
          social: item.social,
        })),
      };

      return api.post('/favourites', groupsToAdd)
        .then(() => {
          commit('setGroupsFavoriteData', [...newFavorites, ...state.groupsFavoriteData]);
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- addGroupsToFavorite --; Error -', error);
          return Promise.reject();
        });
    },

    // Удаление канала из избранного
    removeGroupsFromFavorites({ state, commit }, groups) {
      const groupsToRemove = {
        groupIdentities: groups.map((item) => ({
          id: item.id,
          social: item.social,
        })),
      };
      return api.post('/favourites/delete-groups', groupsToRemove)
        .then(() => {
          const newFavorites = state.groupsFavoriteData.filter((group) => !groups.some((item) => item.id === group.id && item.name === group.name));
          commit('setGroupsFavoriteData', newFavorites);
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- removeGroupsFromFavorites --; Error -', error);
          return Promise.reject();
        });
    },
  },

};
