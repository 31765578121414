<template>
  <div class="clients-access">
    <div
    v-if="!isLoading && getAccessClientsData.length"
    class="clients-access-wrapper">
      <div class="clients-access__actions">
        <span
        v-if="clietsAccessType === 'wait'"
        class="clients-access__text pointer"
        @click="changeRequestsType('ready')">Показать обработанные заявки</span>
        <span
        v-else
        class="clients-access__text pointer"
        @click="changeRequestsType('wait')">Показать все заявки</span>
        <DataIcon
        name="settings"
        class="clients-access__text" />
      </div>
      <div
      ref="clientsContent"
      class="clients-access__content"
      @mousedown.left.stop="mouseDown"
      @mouseup.left="mouseUp"
      @mouseleave="mouseLeave"
      @mousemove="mouseMove">
        <ClientsAccessList
        :cliets-access-type="clietsAccessType"
        :is-loading="isLoading"
        :access-clients-data="getAccessClientsData" />
      </div>
    </div>
    <span
    v-else
    class="clients-access__text clients-access__text_light">
      {{ getEmptyLabel }}
    </span>
  </div>
</template>

<script>
import ClientsAccessList from './components/ClientsAccessList.vue';

export default {
  name: 'ClientsAccess',
  components: { ClientsAccessList },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    clietsAccessType: 'wait',
    isScroll: false,
    startX: null,
    scrollLeft: null,
  }),
  created() {
    this.init();
  },
  methods: {
    changeRequestsType(type) {
      this.clietsAccessType = type;
    },
    init() {
      this.$store.dispatch('tariffs/getTariffRequestsData');
    },
    mouseDown(e) {
      this.isScroll = true;
      this.startX = e.pageX - this.$refs.clientsContent.offsetLeft;
      this.scrollLeft = this.$refs.clientsContent.scrollLeft;
    },
    mouseLeave() {
      this.isScroll = false;
    },
    mouseUp() {
      this.isScroll = false;
    },
    mouseMove(e) {
      if (!this.isScroll || this.showGroupMenu) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.clientsContent.offsetLeft;
      const walk = (x - this.startX) * 1; // scroll-fast
      this.$refs.clientsContent.scrollLeft = this.scrollLeft - walk;
    },
  },
  computed: {
    getAccessClientsData() {
      return this.$store.getters['tariffs/getTariffsRequestsData'];
    },
    getEmptyLabel() {
      if (this.isLoading) return 'Загрузка списка заявок';
      return 'Заявок не найдено';
    },
  },

};
</script>

<style lang="scss" scoped>
.clients-access {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: auto;
  background: white;
  border-radius: 12px;
  margin: 20px;
}

.clients-access-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
  background: white;
}

.clients-access__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

.clients-access__content {
  overflow: auto;
  height: 100%;
}

.clients-access__elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.clients-access__text {
  font-size: 14px;
  color: #575757;
  &_light {
    color: $primary-lightgray;
  }
}
</style>
