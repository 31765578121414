<template>
  <div class="sji-headers">
    <div
    v-for="header in getHeaders"
    :key="header.value"
    :style="`width: ${header.width}px`"
    :class="{
      'sji-headers__element_name': header.value === 'name',
    }"
    class="sji-headers__element">
      <span class="sji-headers__text">
        {{ header.text }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SjiHeaders',
  props: {
    selectedTab: {
      type: String,
      default: 'wait',
    },
  },
  data: () => ({}),
  computed: {
    getHeaders() {
      const headers = [
        {
          name: 'name2', value: 'name', text: 'Название', width: 400, show: true,
        },
        {
          name: 'subscribers', value: 'subscribers', text: 'Подписчики', width: 150, show: true,
        },
        {
          name: 'views', value: 'views', text: 'Охват', width: 150, show: true,
        },
        {
          name: 'index', value: 'index', text: 'Индекс SJI', width: 200, show: true,
        },
        {
          name: 'actions', value: 'actions', text: 'Действия', width: 250, show: this.selectedTab === 'wait',
        },
        {
          name: 'intop', value: 'intop', text: 'Добавить в топ', width: 150, show: this.selectedTab === 'wait',
        },
        {
          name: 'status', value: 'status', text: 'Обработано', width: 150, show: true,
        },
      ];
      return headers.filter((column) => column.show);
    },
  },
};
</script>

<style lang="scss" scoped>
.sji-headers {
  display: flex;
  align-items: center;
  min-width: 100%;
  z-index: 1;
  position: sticky;
  background: white;
  top: 0px;
  left: 0px;
  height: 52px;
  border-bottom: 1px solid $primary-group-border;
}

.sji-headers__element {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &_name {
    position: sticky;
    left: 0px;
    padding-left: 20px;
    background: white;
    justify-content: flex-start;
    align-items: flex-start;
    @include tablet-screen {
      padding-left: 30px;
    }
  }
}

.sji-headers__text {
  font-size: 14px;
  color: $primary-gray;
  &_name {
    color: $primary-blue;
    width: 300px;
  }
}
</style>
