<template>
  <div class="catalog-screen">
    <TariffsRate
    v-if="showTariffsBurnRate"
    :user-info="getUserInfo" />
    <GroupsFilter />
    <MainGroups :groups-data="getGroupsData" />
  </div>
</template>

<script>
import MainGroups from '@/components/MainGroups/MainGroups.vue';
import GroupsFilter from './components/GroupsFilter/GroupsFilter.vue';
import TariffsRate from './components/TariffsRate/TariffsRate.vue';

export default {
  name: 'CatalogScreen',
  components: {
    MainGroups,
    GroupsFilter,
    TariffsRate,
  },
  computed: {
    getGroupsData() {
      if (this.isShowSelectedGroups) {
        return this.$store.getters['groups/getSelectedGroups'];
      }
      if (this.isShowFavoriteGroups) {
        return this.$store.getters['favorites/getFavoriteGroupsData'];
      }
      return this.$store.getters['groups/getGroupsData'];
    },
    isShowSelectedGroups() {
      return this.$store.getters['collections/getShowSelectedGroups'];
    },
    isShowFavoriteGroups() {
      return this.$store.getters['favorites/getShowFavoriteGroups'];
    },
    showTarifRate() {
      return this.$store.getters['app/getShowFullTariffRate'];
    },
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
    showTariffsBurnRate() {
      return !this.isClientAgency && !this.isAdmin;
    },
    isClientAgency() {
      return this.$store.getters['user/getUserInfo']?.asClientAgency;
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
  },
};
</script>

<style lang="scss">
.catalog-screen {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
}
</style>
