<template>
  <div id="app">
    <AppLoader
    v-if="isLoading"
    :is-catalog-loader="isInitialCatalogLoading" />
    <AppModals />
    <DataOverlay />
    <DataSnackbar />

    <!-- main-layout -->
    <div>
      <div v-if="isLogin">
        <router-view
        :key="getLocale" />
      </div>
      <div v-else>
        <MainHeader :mode="!isAuthUser ? 'noauth' : ''" />
        <div class="app__content">
          <Sidebar v-if="isAuthUser" />
          <router-view
          :key="getLocale"
          :class="{ 'app__pages_nosidebar' : !isAuthUser }"
          class="app__pages" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/axios';
import Sidebar from './components/Sidebar/Sidebar.vue';
import AppModals from './components/AppModals/AppModals.vue';

export default {
  components: { Sidebar, AppModals },
  name: 'App',
  created() {
    const locale = this.getLocale === 'ru' ? 'ru' : 'en';
    this.moment.locale(locale);
    this.initApp();
  },
  methods: {
    initApp() {
      this.loadCategories();
    },
    loadCategories() {
      // Загрузка категорий
      // FUTURE сделать реализацию какой-то общей загрузки данных для приложения, в котором подгружаются категории и другие необходимые данные
      api.get('/categories')
        .then(({ data: { data } }) => {
          this.$store.commit('groups/setCategories', data);
        })
        .catch((error) => {
          console.warn('Ошибка загрузки категорий', error);
        });
    },
  },
  computed: {
    getLocale() {
      return this.$i18n.locale;
    },
    isLoading() {
      return this.$store.getters['app/getAppIsLoading'] || !this.appIsLoaded;
    },
    isLogin() {
      return this.$route.name === 'login';
    },
    isAuthUser() {
      const auth = this.$store.getters['user/getUserInfo'];
      return !!auth && !this.$store.getters['user/getUserInfoIsLoaded'];
    },
    appIsLoaded() {
      return this.$store.getters['app/getAppIsLoaded'];
    },
    isInitialCatalogLoading() {
      return this.$store.getters['app/getIsInitialCatalogLoading'];
    },
  },
  watch: {
    getLocale(newValue) {
      const locale = newValue === 'ru' ? 'ru' : 'en';
      this.moment.locale(locale);
    },
  },
};
</script>

<style lang="scss">
.app {
  min-height: 100vh;
  height: 100%;
}

.app__content {
  display: flex;
  background: $main-background;
  min-height: calc(100vh - 70px);
  min-width: 100vw;
}

.app__pages {
  width: 100vw;
  margin: 0px auto;
  transition: 0.2s;
  padding-top: 20px;
  @include desktop-screen {
    width: calc(100vw - 80px);
    margin-left: 70px;
  }
  &_nosidebar {
    margin-left: 0px;
    margin: 0px;
    width: 100vw;
  }
}
</style>
