<template>
  <div class="competitors-list">
    <div
    v-if="!isEmptyGroups"
    ref="groupsContent"
    class="competitors-list__content"
    @scroll="onScroll">
      <div
      class="competitors-list__elements"
      :class="isLoading && 'competitors-list__elements_loading'">
        <CompetitorsHeaders
        :selected-tab="selectedTab"
        :groups="groups"
        :is-all-selected="isAllSelected"
        @loadCompetitorsGroups="loadCompetitorsGroups" />
        <CompetitorElement
        v-for="(group, index) in groups"
        :key="group.id"
        :index="index"
        :difference-percent="group.differencePercent"
        :group="group.externalPrice"
        :selected-tab="selectedTab" />
      </div>
    </div>
    <div
    v-if="isEmptyGroups || isLoading"
    class="competitors-list__elements-empty">
      <img
      v-if="isLoading"
      class="competitors-list__loader"
      src="@/assets/svg/spinner-loader.svg">
      <span
      v-else
      class="competitors-list__text_light">
        Нет каналов
      </span>
    </div>
  </div>
</template>

<script>
import CompetitorsHeaders from './CompetitorsHeaders.vue';
import CompetitorElement from './CompetitorElement.vue';

export default {
  name: 'CompetitorsList',
  components: { CompetitorsHeaders, CompetitorElement },
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    selectedTab: {
      type: String,
      default: 'wait',
    },
  },
  data: () => ({
  }),
  methods: {
    onScroll() {
      if (this.isLastPage || this.isLoading) {
        return;
      }

      const end = Math.ceil(this.$refs.groupsContent.scrollHeight - this.$refs.groupsContent.offsetHeight - this.$refs.groupsContent.scrollTop) <= 1;

      if (end) this.$emit('loadCompetitorsGroups', { reset: false });
    },
    loadCompetitorsGroups(params) {
      this.$emit('loadCompetitorsGroups', params);
    },
  },
  computed: {
    isEmptyGroups() {
      return this.groups.length === 0;
    },
    isLastPage() {
      return this.$store.getters['competitors/getIsLastPage'];
    },
    isAllSelected() {
      return this.groups.every((item) => item.externalPrice.checked === true);
    },
  },
};
</script>

<style lang="scss" scoped>
.competitors-list {
  background: $primary-white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  position: relative;
  margin: 20px 0px;
}

.competitors-list__content {
  background: white;
  z-index: 1;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.competitors-list__elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
  transition: 0.2s;
  &_loading {
    opacity: 0.4;
    transition: 0.3s;
  }
}

.competitors-list__elements-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 0px;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  top: 50%;
  right: 0;
  text-align: center;
}

.competitors-list__text {
  color: $primary-black;
  font-size: 14px;
  &_light {
    color: $primary-lightgray;
  }
}

.competitors-list__loader {
  height: 150px;
  animation: pulse 1.2s ease-in-out infinite;
  z-index: 10;
}

@keyframes pulse {
  from {
    transform: scale(1);
    opacity: 0.3;
  }
  50% {
    transform: scale(.98);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0.3;
  }
}

</style>
