<template>
  <div class="group-price-update">
    <span class="group-price-update__text">{{ updateDate }}</span>
    <span
    v-if="updateUser"
    class="group-price-update__text">{{ updateUser }}</span>
    <span
    v-if="updatePriceType"
    class="group-price-update__text">{{ updatePriceType }}</span>
  </div>
</template>

<script>
export default {
  name: 'GroupPriceUpdate',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    disableEditor: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    updateDate: null,
    updateUser: null,
    updatePriceType: null,
  }),
  created() {
    this.getPriceUpdateDate();
  },
  methods: {
    getPriceUpdateDate() {
      const productsId = {
        1: 'postPrice',
        2: 'storyPrice',
        3: 'reelPrice',
        4: 'streamPrice',
        5: 'integrationPrice',
        6: 'circlePrice',
        7: 'nonStandartPrice',
      };
      try {
        const products = this.group.products.map((item) => {
          const tradeoffer = item.tradeOffersChain?.tradeOffers[0];
          if (tradeoffer) {
            return {
              type: item.type,
              date: tradeoffer.updatedAt?.date,
              timestamp: new Date(tradeoffer.updatedAt?.date)?.getTime(),
              user: tradeoffer.updaterUser?.name || tradeoffer.updaterUser?.email,
            };
          }
          return null;
        });
        const currentProduct = products.sort((a, b) => b.timestamp - a.timestamp)?.[0] || false;
        if (currentProduct) {
          this.updateDate = currentProduct.date ? this.moment(currentProduct.date).format('LLL') : null;
          this.updateUser = currentProduct.user ? currentProduct.user : null;
          this.updatePriceType = currentProduct.type ? `Обновлено: ${this.$t(productsId[currentProduct.type])}` : null;
        } else {
          this.updateDate = 'Нет данных';
        }
      } catch {
        this.updateDate = '?';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.group-price-update {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 180px;
  min-height: 50px;
  position: relative;
  @include between-elements {
    margin-bottom: 3px;
  }
}

.group-price-update__text {
  font-size: 12px;
  color: $primary-lightgray;
  text-align: center;
  word-break: break-all;
}

</style>
