<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="group-description">
    <div class="group-description__main">
      <div
      :class="isAdmin && 'pointer'"
      class="group-description__text group-description__text_light"
      @click="openEditor"
      v-html="getDescription" />
    </div>

    <transition name="fade">
      <div
      v-if="showEditor"
      v-click-outside="onClickOutside"
      class="group-description__editor">
        <div class="group-description__editor-element">
          <DataTextarea
          ref="textarea"
          v-model="newDescription"
          type="text"
          :disabled="!isEdit"
          :font-size="12"
          :height="200"
          :placeholder="getPlaceholder" />
        </div>
        <DataButton
        v-if="isAdmin && getSelectedDescription === 'description'"
        :text="isEdit ? $t('save') : 'Редактировать'"
        type="text"
        @handler="saveChanges" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'GroupDescription',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    disableEditor: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showEditor: false,
    newDescription: null,
    isEdit: false,
  }),
  methods: {
    openAiDescription() {
      if (this.getSelectedDescription === 'ai') {
        this.getSelectedDescription = 'description';
      } else {
        this.getSelectedDescription = 'ai';
      }
    },
    openEditor() {
      if (this.disableEditor) return;
      if (this.getSelectedDescription === 'ai') {
        this.newDescription = this.group.descriptionFromParser || '';
      } else {
        this.newDescription = this.group.description || '';
      }
      this.isEdit = false;
      this.showEditor = true;
    },
    onClickOutside() {
      this.showEditor = false;
    },
    saveChanges() {
      if (!this.isEdit) {
        this.isEdit = true;
        return;
      }
      const newData = {
        description: this.newDescription === '' ? null : this.newDescription,
      };

      this.showEditor = false;

      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData })
        .then(() => {
          this.group.description = this.newDescription === '' ? null : this.newDescription;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
        });
    },
  },
  computed: {
    getPlaceholder() {
      if (this.isAdmin) return 'Укажите описание';
      return 'Описание отстутствует';
    },
    getDescription() {
      if (this.getSelectedDescription === 'ai') {
        return this.group.descriptionFromParser ? this.sanitizeHtml(this.group.descriptionFromParser) : '—';
      }

      return this.group.description ? this.sanitizeHtml(this.group.description) : '—';
    },
    getSelectedDescription() {
      return this.$store.getters['catalog/getSelectedDescription'];
    },
  },
};
</script>

<style lang="scss" scoped>
.group-description {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 260px;
  position: relative;
  max-height: 200px;
  height: 100%;

  .group-description__main {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    max-width: 100%;
  }
}

.group-description__text {
  font-size: 14px;
  padding: 0px 5px;
  color: $primary-gray;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

.group-description__editor {
  position: absolute;
  top: 0px;
  left: 30px;
  width: 500px;
  border-radius: 12px;
  padding: 20px 20px 0px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 240px;
}

.group-description__editor-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

</style>
