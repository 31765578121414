<template>
  <div class="search-post">
    <div
    class="search-post__header"
    @click="openPost">
      <img
      :src="post.channel?.avatar ? post.channel?.avatar : require('@/assets/svg/empty-avatar.svg')"
      class="search-post__avatar">
      <span class="search-post__title">{{ post.channel?.title || post.url || '' }}</span>
    </div>
    <div class="search-post__content">
      <!-- MAIN TEXT -->
      <span class="search-post__text">
        {{ post.text }}
      </span>

      <!-- FOOTER -->
      <div class="search-post__footer">
        <div class="search-post__footer-container">
          <!-- SOCIAL -->
          <div
          v-if="false"
          class="search-post__social-container">
            <DataCheckbox v-model="groupChecked" />
          </div>
          <div class="search-post__social-container">
            <img
            class="search-post__icon"
            :src="require(`@/assets/svg/groups-icons/${socialIcon}-icon.svg`)">
            <span class="search-post__social-name">{{ socialName }}</span>
          </div>
          <!-- Tonality -->
          <div
          v-if="tonality"
          class="search-post__social-container"
          :style="{ background: tonality.color }">
            <span
            class="search-post__social-name"
            :style="{ color: tonality.textColor }">{{ tonality.name }}</span>
          </div>
        </div>
        <div class="search-post__footer-container">
          <div
          v-if="dateTime"
          class="search-post__date-container">
            <img
            class="search-post__icon"
            src="../../assets/calendar-blank.svg">
            <span class="search-post__date-text">{{ dateTime }}</span>
          </div>
          <div
          v-if="totalViews"
          class="search-post__date-container">
            <img
            class="search-post__icon"
            src="../../assets/eye.svg">
            <span class="search-post__date-text">{{ totalViews }}</span>
          </div>
          <DataButton
          text="Открыть пост"
          class="search-post__open-button"
          :height="30"
          :width="140"
          @handler="openPost" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchPost',
  props: {
    post: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    dateTime: null,
    totalViews: null,
    socialName: null,
    tonalityOptions: [
      {
        id: 1, value: 'negative', name: 'Отрицательный', color: '#fae3e5', textColor: '#a46b6d',
      },
      {
        id: 2, value: 'positive', name: 'Положительный', color: '#edfeee', textColor: '#7da480',
      },
      {
        id: 3, value: 'neutral', name: 'Нейтральный', color: '#e8e8e8', textColor: '#7f7b7b',
      },
    ],
    tonality: null,
    socialIcon: null,
    socials: [
      { value: 'telegram', icon: 'telegram', name: 'Телеграм' },
      { value: 'vk', icon: 'vk', name: 'Вконтакте' },
      { value: 'instagram', icon: 'instagram', name: 'Инстаграм' },
      { value: 'odnoklassniki', icon: 'odnoklassniki', name: 'Одноклассники' },
      { value: 'youtube', icon: 'youtube', name: 'Youtube' },
      { value: 'dzen', icon: 'yandex-zen', name: 'Я.Дзен' },
      { value: 'mave', icon: 'mave', name: 'Mave' },
    ],
  }),
  created() {
    this.initData();
  },
  methods: {
    openPost() {
      if (!this.post.url) return;
      window.open(this.post.url, '_blank');
    },
    initData() {
      this.dateTime = this.post.created_at ? this.moment(this.post.created_at).format('LLL') : null;
      this.totalViews = this.post.views_24 ? this.post.views_24 : null;
      this.socialName = this.socials.find((item) => item.value === this.post.social)?.name || '?';
      this.tonality = this.tonalityOptions.find((item) => item.value === this.post.tonality) || null;
      this.socialIcon = this.socials.find((item) => item.value === this.post.social)?.icon || null;
    },
  },
  computed: {
    groupChecked: {
      get() {
        return this.groupIsChecked;
      },
      set(value) {
        this.$emit('clickCheckbox', value);
      },
    },
    groupIsChecked(group) {
      return this.getSelectedGroups.length > 0
        ? this.getSelectedGroups.some((item) => (item.name === group.name && item.id === group.id))
        : false;
    },
    getSelectedGroups() {
      return this.$store.getters['groups/getSelectedGroups'];
    },
  },
};
</script>

<style lang="scss" scoped>
.search-post {
  background: white;
  border-radius: 20px;
  box-shadow: 0px 4px 23px 0px rgba(0, 174, 232, 0.12);
  height: 390px;
  padding: 24px;
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .search-post__header {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    cursor: pointer;
    @include between-elements {
      margin-right: 20px;
    }
  }

  .search-post__avatar {
    width: 70px;
    height: 70px;
    border-radius: 100%;
  }

  .search-post__title {
    font-size: 18px;
    color: $primary-gray;
    font-weight: 600;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-right: 80px;
  }

  .search-post__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  .search-post__text {
    color: #232323;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    max-height: 190px;
    overflow: hidden;
    padding-right: 80px;
  }

  .search-post__date-text {
    color: #A7A7A7;
    font-size: 14px;
    padding-right: 0px;
    font-weight: 400;
  }

  .search-post__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 20px;
  }

  .search-post__social-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: rgba(0, 174, 232, 0.10);
    border-radius: 20px;
    padding: 0px 10px;
    height: 36px;
  }

  .search-post__icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .search-post__social-name {
    color: $primary-gray;
    font-size: 15px;
  }

  .search-post__footer-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    @include between-elements {
      margin-right: 10px;
    }
  }

  .search-post__date-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include between-elements {
      margin-right: 10px;
    }
  }

  .search-post__open-button {
    margin-left: 10px;
    position: absolute;
    top: -40px;
    right: 6px;
  }
}
</style>
