<template>
  <div class="clients-access-list">
    <ClientsAccessHeaders :cliets-access-type="clietsAccessType" />
    <ClientsAccessElement
    v-for="client in accessClientsData"
    :key="client.id"
    :cliets-access-type="clietsAccessType"
    :client="client" />
  </div>
</template>

<script>
import ClientsAccessElement from './ClientsAccessElement.vue';
import ClientsAccessHeaders from './ClientsAccessHeaders.vue';

// Копонент обёртка для списка Запросов на получение доступа
export default {
  name: 'ClientsAccessList',
  components: { ClientsAccessElement, ClientsAccessHeaders },
  props: {
    accessClientsData: {
      type: Array,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    clietsAccessType: {
      type: String,
      default: 'wait',
    },
  },
  data: () => ({
    currentElement: null,
  }),
};
</script>

<style lang="scss" scoped>
.clients-access-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.clients-access-list__confirm-modal {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  @include between-elements {
    margin-bottom: 40px;
  }
}

.clients-access-list__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include between-elements {
    margin-right: 20px;
  }
}
</style>
