<template>
  <div
  class="group-actions"
  :class="[
    show && 'group-actions_show',
    mode === 'noauth' && 'group-actions_show_no-buttons'
  ]">
    <div
    v-if="mode !== 'noauth'"
    class="group-actions__button-block">
      <ActionButton
      v-if="!isEditCompilationMode"
      :is-compilation="isCompilation" />
      <button
      v-if="!isEditCompilationMode"
      class="group-actions__button"
      @click="openCreatePlacement">
        {{ $t('send_a_request') }}
      </button>
      <AddGroupToCompilation
      v-if="isEditCompilationMode"
      :compilation-info="getCompilationInfo"
      :selected-groups="selectedGroups" />
    </div>
    <GroupStats
    :mode="mode"
    :is-compilation="isCompilation"
    :selected-groups="selectedGroups" />
    <transition name="fade">
      <div
      v-if="isAllSelectedForCompilation"
      class="group-actions__all-selected"
      :class="mode === 'noauth' && 'group-actions__all-selected_noauth'">
        <span class="group-actions__text group-actions__text_all-selected">{{ getAllSelectedTitle }}</span>
        <div
        class="group-actions__all-selected-btn"
        :class="getTriggerForSelectedAllGroups && 'group-actions__all-selected-btn_active'">
          <span
          class="group-actions__text"
          :class="getTriggerForSelectedAllGroups && 'group-actions__text_all-selected'"
          @click="toggleAllGroupsToSelected">{{ getTitleForSelectAllButton }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ActionButton from './components/ActionButton/ActionButton.vue';
import GroupStats from './components/GroupsStats.vue';
import AddGroupToCompilation from './components/AddGroupsToCompilation.vue';

export default {
  name: 'GroupActions',
  components: { ActionButton, GroupStats, AddGroupToCompilation },
  props: {
    selectedGroups: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: null,
    },
    groupsData: {
      type: Array,
      default: () => [],
    },
    isCompilation: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showAddToCompilationModal: false,
  }),
  methods: {
    toggleAllGroupsToSelected() {
      const selected = this.getTriggerForSelectedAllGroups;
      this.$store.commit('compilations/setTriggerForSelectedAllGroups', !selected);
    },
    openCreatePlacement() {
      const params = {
        selectedGroups: this.selectedGroups,
        viewMode: false,
        isNewRevision: false,
        currentPlacement: null,
      };
      this.$store.commit('placements/setPlacementParams', params);
    },
    addGroupsToCompilationFromCatalog() {
      this.$store.dispatch('compilations/addGroupsToCompilation', { id: this.getCompilationInfo.id, groups: this.selectedGroups })
        .then(() => {
          this.$store.commit('groups/setSelectedGroups', []);
          this.$store.dispatch('app/setSnackBar', { text: 'Успешно. Выбранные каналы добавлены в подборку' });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: 'Произошла ошибка при добавлении каналов. Попробуйте позже',
              error: true,
            });
        });
    },
  },
  computed: {
    isAllSelectedForCompilation() {
      // Логика показа кнопки выбора всех каналов
      if (!this.isCompilation) {
        return false;
      }

      if (!this.selectedGroups.length) return false;

      if (this.getTriggerForSelectedAllGroups) return true;

      // Количество каналов в подборке
      const channelsInCompilation = this.getCurrentCollectionStats?.countSelectedGroups || null;

      // Если количество каналов в подборке меньше или равно количеству первой пачки загружаемых каналов (30 по умолчанию), не показывать кнопку
      if (channelsInCompilation && channelsInCompilation <= 30) return false;

      // Если количество каналов в подборке равно количеству выбранных, не показывать кнопку
      if (channelsInCompilation && this.selectedGroups.length === channelsInCompilation) {
        return false;
      }

      // В остальных случаях показывать если количество выбранных равно количеству загруженных
      return this.selectedGroups.length === this.groupsData.length;
    },
    isLastPage() {
      if (this.isCompilation) {
        return this.$store.getters['collections/getCurrentPage'] === (+this.$store.getters['collections/getTotalPageCounts'] + 1);
      }
      return this.$store.getters['groups/getCurrentPage'] === (+this.$store.getters['groups/getTotalPageCounts'] + 1);
    },
    getAllSelectedTitle() {
      // NEED TRANSLATE
      if (this.getTriggerForSelectedAllGroups && this.getCurrentCollectionStats?.countSelectedGroups) {
        const channels = this.getPluralWord(this.getCurrentCollectionStats?.countSelectedGroups, ['канал', 'канала', 'каналов']);
        return `Выбраны ${this.getCurrentCollectionStats?.countSelectedGroups} ${channels}`;
      }
      return `Выбрано ${this.selectedGroups.length} каналов`;
    },
    getTitleForSelectAllButton() {
      // NEED TRANSLATE
      return this.getTriggerForSelectedAllGroups ? 'Выбраны все' : 'Выбрать все';
    },
    getCurrentCollectionStats() {
      return this.$store.getters['compilations/getCurrentCompilationSummary'];
    },
    getTriggerForSelectedAllGroups() {
      return this.$store.getters['compilations/getTriggerForSelectedAllGroups'];
    },
    isEditCompilationMode() {
      return this.$store.getters['groups/getAddToCompilationMode'];
    },
    getCompilationInfo() {
      return this.$store.getters['compilations/getCurrentCompilationInfo'];
    },
  },
};
</script>

<style lang="scss" scoped>
.group-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  bottom: 0px;
  background: $primary-blue;
  padding: 0px 10px;
  height: 0px;
  transition: height .2s;
  overflow: hidden;
  z-index: 4;
  &_show {
    height: 110px;
    margin-top: 5px;
    overflow: visible;
    &_no-buttons {
      height: 148px;
      @include tablet-screen {
        height: 44px;
      }
    }
  }
  @include desktop-screen {
    padding: 0px 40px;
    justify-content: space-between;
  }

  .group-actions__content {
    display: flex;
    width: 100%;
    padding: 0px 30px;
    align-items: center;
    justify-content: space-between;
  }

  .group-actions__button-block {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    @include between-elements {
      margin-bottom: 15px;
    }
    @include tablet-screen {
      flex-direction: row;
      margin-right: 20px;
      @include between-elements {
        margin-right: 15px;
        margin-bottom: 0px;
      }
    }
  }

  .group-actions__button {
    cursor: pointer;
    width: 140px;
    height: 40px;
    outline: none;
    border: none;
    background: $primary-white;
    border-radius: 40px;
    @include tablet-screen {
      width: 180px;
    }
  }

  .group-actions__white-bar {
    height: 10px;
    background: white;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .group-actions__text {
    color: $primary-black;
    font-size: 14px;
    &_all-selected {
      color: white;
    }
  }

  .group-actions__all-selected {
    display: flex;
    max-width: 350px;
    justify-content: center;
    align-items: center;
    height: 0px;
    background: $primary-blue;
    border-radius: 12px;
    padding: 20px 20px;
    font-size: 12px;
    cursor: pointer;
    z-index: 5;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    bottom: 140px;
    right: 0;
    text-align: center;
    @include tablet-screen {
      bottom: 100px;
    }
    &_noauth {
      bottom: 63px;
    }
  }

  .group-actions__all-selected-btn {
    margin-left: 10px;
    padding: 3px 10px;
    width: 140px;
    background: white;
    border-radius: 6px;
    &_active {
      background: $primary-darkblue;
      color: white;
    }
  }

  .compilation__drag-bottom {
    background: rgba($primary-blue, 0.2);
    width: 100%;
    height: 50px;
    position: absolute;
    top: -50px;
    left: 0px;
  }

  .group-actions__add-to-compilation {
    position: relative;
  }
}
</style>
