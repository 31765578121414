<template>
  <div class="group-screen">
    <div
    v-if="getCurrentGroup && !isLoading"
    class="group">
      <GroupHeader :group="getCurrentGroup" />
      <GroupStatistic
      v-if="getCurrentGroup.social !== 'mave' "
      :group="getCurrentGroup" />
      <GroupAnalytics
      v-if="getCurrentGroup.social !== 'mave' "
      :group="getCurrentGroup" />
      <MaveAnalytics
      v-if="getCurrentGroup.social === 'mave' "
      :group="getCurrentGroup" />
    </div>
    <div
    v-else
    class="group-screen__not-found">
      <span class="group-screen__text">{{ getTitle }}</span>
      <DataButton
      v-if="!isLoading"
      :text="isAuthUser ? 'Перейти в каталог' : 'Перейти на главную'"
      :width="200"
      @handler="goToMain" />
    </div>
  </div>
</template>

<script>
import GroupHeader from './components/GroupHeader/GroupHeader.vue';
import GroupStatistic from './components/GroupStatistic.vue';
import GroupAnalytics from './components/GroupAnalytics/GroupAnalytics.vue';
import MaveAnalytics from './components/MaveAnalytics/MaveAnalytics.vue';

export default {
  name: 'Group',
  components: {
    GroupHeader, GroupStatistic, GroupAnalytics, MaveAnalytics,
  },
  data: () => ({
    isLoading: false,
    loadError: false,
  }),
  beforeDestroy() {
    this.$store.commit('group/setCurrentGroup', null);
  },
  async created() {
    // выкидываем, если не авторизован и нет shareToken
    const shareToken = this.$route.query.shareToken || null;
    if (!this.getCurrentGroup) {
      this.isLoading = true;
      const params = { social: this.$route.params.social, id: this.$route.params.id, shareToken };

      await this.$store.dispatch('group/getCurrentGroup', params)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.loadError = true;
          this.isLoading = false;
          setTimeout(() => {
            this.$store.dispatch('app/setSnackBar', {
              text: 'Не удалось загрузить информацию о группе',
              error: true,
            }, 1000);
          });
        });
    }
  },
  methods: {
    goToMain() {
      this.$router.push({ name: 'catalog' });
    },
  },
  computed: {
    getCurrentGroup() {
      return this.$store.getters['group/getCurrentGroup'];
    },
    groupNotFound() {
      return !this.isLoading && !this.getCurrentGroup;
    },
    isAuthUser() {
      const auth = this.$store.getters['user/getUserInfo'];
      return !!auth;
    },
    getTitle() {
      if (this.loadError) {
        return 'При загрузке группы произошла ошибка. Проверьте правильность ссылки или попробуйте позже';
      }
      if (!this.getCurrentGroup && !this.isLoading) {
        return 'Нет данных о группе';
      }
      return 'Загрузка данных...';
    },
  },
};
</script>

<style lang="scss" scoped>
.group {
  margin: 0px 20px;
}

.group-screen__not-found {
  height: 80vh;
  width: 100%;
  max-width: 500px;
  margin: 0px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include between-elements {
    margin-bottom: 20px;
  }
}

.group-screen__text {
  color: $primary-gray;
  font-size: 14px;
}
</style>
