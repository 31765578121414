<template>
  <div class="compilations-element">
    <!-- Название -->
    <div
    class="compilations-element__name-column">
      <div class="compilations-element__name">
        <img src="@/assets/svg/compilation/FileText.png">
        <span
        ref="rkname"
        class="compilations-element__text compilations-element__text_name pointer"
        @click="openCurrentCompilation">
          {{ compilationName }}
        </span>
        <DataIcon
        name="edit"
        class="compilations-element__edit-icon"
        :size="14"
        pointer
        @click.native="renameCompilation" />
      </div>
      <span
      v-tooltip="categories ? categories : ''"
      class="compilations-element__text compilations-element__text_categories">
        {{ categories || 'Без категорий' }}
      </span>
      <span class="compilations-element__text compilations-element__text_light">
        {{ createdAt }}
      </span>
    </div>

    <div class="compilations-element__content">
      <!-- Показатели -->
      <div
      class="compilations-element__data-column"
      style="width: 500px">
        <!-- 1 -->
        <div class="compilations-element__data-item">
          <div class="compilations-element__data-element compilations-element__data-element_fix">
            <div
            v-if="socials"
            class="compilations-element__socials">
              <img
              v-for="(social, index) in socials"
              :key="social"
              class="compilations-element__social"
              :style="`margin-left: -${index + 7}px`"
              :src="require(`@/assets/svg/groups-icons/${social}-icon.svg`)">
            </div>
            <span
            v-if="compilation.groupsCount"
            class="compilations-element__text">
              {{ compilation.groupsCount }}
            </span>
            <span class="compilations-element__text compilations-element__text_light">
              {{ compilation.groupsCount ? groupsCountText : 'Каналы не выбраны' }}
            </span>
          </div>
          <div class="compilations-element__data-line" />
          <div class="compilations-element__data-element">
            <img src="@/assets/svg/compilation/UsersThree.png">
            <span class="compilations-element__text">
              {{ subscribersCount }}
            </span>
            <span class="compilations-element__text compilations-element__text_light">
              {{ 'Подписчики' }}
            </span>
          </div>
        </div>
        <!-- 2 -->
        <div class="compilations-element__data-item">
          <div class="compilations-element__data-element compilations-element__data-element_fix">
            <img src="@/assets/svg/compilation/Eye.png">
            <span
            class="compilations-element__text">
              {{ viewsCount }}
            </span>
            <span class="compilations-element__text compilations-element__text_light">
              {{ viewsCount ? getPluralWord(+viewsCount, ['Просмотр', 'Просмотра', 'Просмотров']) : 'Просмотры' }}
            </span>
          </div>
          <div class="compilations-element__data-line" />
          <div class="compilations-element__data-element">
            <img src="@/assets/svg/compilation/Pulse.png">
            <span
            class="compilations-element__text">
              {{ sellTotal }}
            </span>
            <span class="compilations-element__text compilations-element__text_light">
              {{ 'Бюджет' }}
            </span>
          </div>
        </div>
      </div>

      <!-- Действия -->
      <div class="compilations-element__actions">
        <span
        class="compilations-element__text compilations-element__text_action"
        @click="openCurrentCompilation">Открыть</span>
        <img
        src="@/assets/svg/compilation/DotsThree.png"
        class="compilations-element__menu-icon"
        @click="showMenu = true">
        <div
        v-if="showMenu"
        v-click-outside="() => showMenu = false"
        class="compilations-element__menu"
        :class="lastElement && 'compilations-element__menu_last'">
          <span
          class="compilations-element__menu-element"
          @click="openExportModal">Экспортировать подборку</span>
          <span
          class="compilations-element__menu-element"
          @click="renameCompilation">Переименовать подборку</span>
          <span
          class="compilations-element__menu-element"
          @click="deleteCompilation">Удалить подборку</span>
          <span
          class="compilations-element__menu-element"
          @click="openNewFolderModal">Переместить в папку</span>
        </div>
      </div>

      <DataModal
      :show-modal="showAddToFolderModal"
      @close="showAddToFolderModal = false">
        <template #modal-content>
          <div class="compilations-element__add-to-folder">
            <span>Выберите новую папку</span>
            <DataSelect
            v-model="selectedNewFolder"
            :options="getFoldersList"
            placeholder="Укажите папку" />

            <DataButton
            text="Переместить"
            @handler="addToFolder" />
          </div>
        </template>
      </DataModal>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompilationElement',
  components: {},
  props: {
    compilation: {
      type: Object,
      default: null,
    },
    lastElement: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showMenu: false,
    createdAt: null,
    compilationName: null,
    sellTotal: null,
    groupsCount: null,
    viewsCount: null,
    socials: null,
    groupsCountText: null,
    showAddToFolderModal: false,
    selectedNewFolder: null,
  }),
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getCompilationName();
      this.sellTotal = this.compilation.sellTotal ? this.setNumberSpaces(Math.round(+this.compilation.sellTotal)) : 0;
      this.subscribersCount = this.compilation.subscribersCount ? this.setNumberSpaces(Math.round(+this.compilation.subscribersCount)) : 0;
      this.createdAt = this.compilation.compilation.createdAt?.date
        ? this.moment(this.compilation.compilation.createdAt.date).format('LLL')
        : 'Дата не указана';
      this.groupsCount = this.compilation.groupsCount || null;
      this.viewsCount = this.compilation.viewsCount ? this.setNumberSpaces(Math.round(+this.compilation.viewsCount)) : 0;
      this.initCategories();
      this.socials = this.compilation.socials && this.compilation.socials.length ? this.compilation.socials : false;
      this.groupsCountText = this.getGroupsCountText();
    },
    getCompilationName() {
      if (this.compilation.compilation.name === 'noname') {
        this.compilationName = `Подборка №${this.compilation.compilation.id}`;
        return;
      }
      this.compilationName = this.compilation.compilation.name.charAt(0).toUpperCase() + this.compilation.compilation.name.slice(1);
    },
    openCurrentCompilation() {
      this.$router.push({ name: 'compilation', query: { id: this.compilation.compilation.id, name: this.compilation.compilation.name } });
    },
    deleteCompilation() {
      const compilation = { name: this.compilation.compilation.name, id: this.compilation.compilation.id };
      const modalParams = { name: 'EditCollectionModal', props: { compilation, action: 'delete' } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    renameCompilation() {
      const compilation = { name: this.compilation.compilation.name, id: this.compilation.compilation.id };
      const modalParams = { name: 'EditCollectionModal', props: { compilation, action: 'rename' } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    openExportModal() {
      const compilation = { name: this.compilation.compilation.name, id: this.compilation.compilation.id };
      const modalParams = { name: 'ExportGroupsModal', props: { compilation } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    getCompilationInfo() {
      return this.$store.getters['compilations/getCurrentCompilationInfo'];
    },
    initCategories() {
      try {
        const categories = this.compilation.categoriesIds && this.compilation.categoriesIds.length
          ? this.compilation.categoriesIds.map((item) => this.$t(this.getCategories.find((category) => +category.id === +item).name))
          : null;
        this.categories = categories.join(', ');
      } catch {
        this.categories = null;
      }
    },
    getGroupsCountText() {
      try {
        return this.getPluralWord(+this.compilation.groupsCount, ['канал', 'канала', 'каналов']);
      } catch {
        return '';
      }
    },
    openNewFolderModal() {
      if (!this.getCompilationsFolders || this.getCompilationsFolders.length < 2) {
        this.$store.dispatch('app/setSnackBar', { text: 'Создайте папку' });
        this.showMenu = false;
        return;
      }
      this.showAddToFolderModal = true;
    },
    addToFolder() {
      if (!this.selectedNewFolder) return;
      const isThisFolder = this.compilation.folder.id === this.selectedNewFolder.value;
      if (isThisFolder) {
        this.$store.dispatch('app/setSnackBar', { text: 'Подборка уже находится в данной папке. Выберите другую папку' });
        return;
      }
      const params = { folderId: this.selectedNewFolder.value, compilationId: this.compilation.id };
      this.$store.dispatch('compilations/addCompilationToFolder', params)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Подборка перенесена' });
        });
    },
  },
  computed: {
    getCategories() {
      return this.$store.getters['groups/getCategories'];
    },
    getCompilationsFolders() {
      return this.$store.getters['compilations/getCompilationsFolders'];
    },
    getFoldersList() {
      return this.getCompilationsFolders.map((folder) => ({
        name: folder.name,
        value: folder.id,
      }));
    },
    getSelectedFolder() {
      return this.$store.getters['compilations/getSelectedFolder'];
    },
  },
};
</script>

<style lang="scss" scoped>
.compilations-element {
  min-width: 100%;
  display: flex;
  align-items: center;
  background: white;
  border-radius: 12px;
  border: 1px solid #D8F2FA;
  padding: 10px 0px;
  box-sizing: border-box;
}

.compilations-element__name-column {
  width: 370px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  left: 0px;
  z-index: 1;
  background: white;
  padding-left: 20px;
  border-right: 1px solid #D8F2FA;
  position: relative;
  @include between-elements {
    margin-bottom: 5px;
  }
  &:hover {
    .compilations-element__edit-icon {
      opacity: 1;
    }
  }
}

.compilations-element__name {
  display: flex;
  justify-content: flex-start;
  padding-right: 30px;
  @include between-elements {
    margin-right: 5px;
  }
  margin-bottom: 5px;
}

.compilations-element__content {
  display: flex;
  background: white;
  height: 60px;
}

.compilations-element__data-column {
  width: 500px;
  border-right: 1px solid #D8F2FA;
  height: 100%;
  padding: 0px 20px 0px 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.compilations-element__data-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.compilations-element__data-element {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 5px;
  }
  &_fix {
    width: 200px;
  }
}

.compilations-element__data-line {
  width: 1px;
  height: 20px;
  margin-right: 20px;
  background: #D8F2FA;
}

.compilations-element__socials {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  align-items: center;
}

.compilations-element__social {
  width: 23px;
  height: 23px;
  top: 0;
  left: 0;
  box-sizing: content-box;
  border: 2px solid white;
  border-radius: 100%;
  position: relative;
  background: white;
}

.compilations-element__text {
  font-size: 14px;
  max-width: 100%;
  color: #4F4F4F;
  &_name {
    font-size: 16px;
    color: $primary-blue;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &_categories {
    font-size: 14px;
    color: $primary-gray;
    overflow: hidden;
    max-width: 90%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  &_light {
    color: $primary-lightgray;
    font-size: 14px;
    font-weight: 400;
  }
  &_action {
    font-size: 16px;
    color: $primary-blue;
    cursor: pointer;
  }
}

.compilations-element__menu-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  padding: 10px;
  cursor: pointer;
}

.compilations-element__menu {
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  right: 0px;
  z-index: 2;
  &_last {
    top: initial;
    bottom: 50px;
  }
}

.compilations-element__menu-element {
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: rgba($primary-lightgray, 0.05);
  }
}

.compilations-element__actions {
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.compilations-element__edit-icon {
  display: none;
  @include desktop-screen {
    display: block;
    margin-left: 10px;
    color: $primary-lightgray;
    transition: 0.2s;
    opacity: 0;
  }
}

.compilations-element__social {
  width: 23px;
  height: 23px;
  top: 0;
  left: 0;
  box-sizing: content-box;
  border: 2px solid white;
  border-radius: 100%;
  position: relative;
  background: white;
}

.compilations-element__socials {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  align-items: center;
}

.compilations-element__add-to-folder {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include between-elements {
    margin-bottom: 30px;
  }
}
</style>
