<template>
  <div class="request-headers">
    <div
    v-for="header in getHeaders"
    :key="header.name"
    :style="`width: ${header.width}px`"
    :class="{'request-headers__element_name': header.value === 'rkName' }"
    class="request-headers__element">
      <span class="request-headers__text">
        {{ header.name }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RequestHeaders',
  props: {
  },
  methods: {},
  computed: {
    getHeaders() {
      const headers = [
        {
          name: 'Название РК', value: 'rkName', width: 470, show: true,
        },
        {
          name: 'Менеджер', value: 'manager', width: 200, show: true,
        },
        {
          name: 'Показатели', value: 'data', width: 450, show: true,
        },
        {
          name: 'Подборка', value: 'request', width: 200, show: true,
        },
        {
          name: 'Коммент', value: 'comment', width: 200, show: this.isAdmin,
        },
        {
          name: '', value: 'actions', width: 60, show: this.isAdmin,
        },
      ];
      return headers.filter((item) => item.show);
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
  },
};
</script>

<style lang="scss" scoped>
.request-headers {
  display: flex;
  align-items: center;
  min-width: 100%;
  position: sticky;
  z-index: 3;
  background: #F0F9FC;
  border: 1px solid #F0F9FC;
}

.request-headers__element {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F0F9FC;
  height: 40px;
  &_name {
    // position: sticky;
    left: 0px;
    align-items: flex-start;
  }
}

.request-headers__text {
  font-size: 14px;
  color: #7B7B7B;
}

</style>
