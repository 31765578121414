<template>
  <div class="compilations">
    <CompilationsHeader
    :selected-tab="selectedTab" />

    <div
    v-if="isAgency"
    class="compilations__tabs">
      <span
      v-for="tab in tabs"
      :key="tab.value"
      class="compilations__tab"
      :class="tab.value === selectedTab && 'compilations__tab_selected'"
      @click="changeTab(tab)">
        {{ tab.name }}
      </span>
    </div>

    <div class="compilations__content">
      <CompilationsFolders
      v-if="selectedTab === 'my' && getCompilationsFolders.length > 1"
      :folders="getCompilationsFolders" />

      <CompilationsList
      :compilations="getCompilationsList" />
    </div>
  </div>
</template>

<script>
import CompilationsList from './components/CompilationsList/CompilationsList.vue';
import CompilationsHeader from './components/CompilationsHeader/CompilationsHeader.vue';
import CompilationsFolders from './components/CompilationsFolders/CompilationsFolders.vue';

export default {
  name: 'CompilationsScreen',
  components: { CompilationsList, CompilationsHeader, CompilationsFolders },
  data: () => ({
    tabs: [
      { name: 'Мои подборки', value: 'my' },
      { name: 'Все подборки', value: 'all' },
    ],
    selectedTab: 'my',
  }),
  created() {
    this.initCompilations();
  },
  methods: {
    initCompilations() {
      if (this.getCompilationsFolders.length) {
        this.loadCompilations();
        return;
      }
      const userId = this.$store.getters['user/getUserInfo']?.id;
      this.$store.dispatch('compilations/getCompilationsFolders', userId)
        .then(() => {
          this.loadCompilations();
        });
    },
    loadCompilations() {
      let folder = null;
      if (this.getSelectedCompilationsFolder) {
        folder = this.getSelectedCompilationsFolder;
      } else {
        folder = this.getCompilationsFolders.find((item) => item.isRoot) || this.getCompilationsFolders.find((item) => item);
      }
      this.$store.commit('compilations/setSelectedCompilationsFolder', folder);
      this.$store.dispatch('compilations/getCompilationsInFolder', folder.id);
    },
    loadAllCompilations() {
      const userId = this.$store.getters['user/getUserInfo']?.id;
      this.$store.dispatch('compilations/getUserCompilationsList', { ownerUserId: userId });
    },
    changeTab(tab) {
      if (this.selectedTab === tab.value) return;
      this.selectedTab = tab.value;

      const params = {};
      if (this.selectedTab === 'all') {
        const agencyId = this.$store.getters['user/getUserInfo']?.asEmployerAgency?.id;
        params.ownerAgencyId = agencyId;
        this.$store.dispatch('compilations/getUserCompilationsList', params);
        return;
      }

      this.loadCompilations();
    },
  },
  computed: {
    getCompilationsList() {
      return this.$store.getters['compilations/getCompilationsList'];
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
    getCompilationsFolders() {
      return this.$store.getters['compilations/getCompilationsFolders'];
    },
    getSelectedCompilationsFolder() {
      return this.$store.getters['compilations/getSelectedCompilationsFolder'];
    },
  },
};
</script>

<style lang="scss">
.compilations {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
}

.compilations__tabs {
  padding-left: 20px;
  margin: 10px 0px;
  @include between-elements {
    margin-right: 15px;
  }
}

.compilations__content {
  display: flex;
  margin: 10px 20px;
  height: 100%;
  overflow: hidden;
}

.compilations__tab {
  color: rgba($primary-gray, 0.5);
  font-size: 13px;
  transition: 0.2s;
  cursor: pointer;
  position: relative;
  &_selected {
    color: $primary-blue;
    &:after {
      position: absolute;
      top: 20px;
      left: 0px;
      content: '';
      width: 100%;
      height: 1px;
      background: $primary-blue;
    }
  }
}

</style>
