<template>
  <div class="clients-headers">
    <div
    v-for="header in getHeaders"
    :key="header.value"
    :style="`width: ${header.width}px`"
    :class="{ 'clients-headers__element_name': header.value === 'name' }"
    class="clients-headers__element">
      <span class="clients-headers__text_light">
        {{ header.name }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ClientHeaders',
  data: () => ({
  }),
  computed: {
    getHeaders() {
      const headers = [
        { name: 'Название', value: 'name', show: true },
        {
          name: 'Запросы', value: 'requests', width: 120, show: true,
        },
        {
          name: 'Клиенты', value: 'user', width: 120, show: this.showClientHeader,
        },
        {
          name: 'Кампании', value: 'source', width: 120, show: true,
        },
        {
          name: 'Потрачено за мес/всего', value: 'status', width: 200, show: true,
        },
        {
          name: 'Комиссия', value: 'total', width: 100, show: true,
        },
        {
          name: 'Депозит', value: 'balance', width: 150, show: true,
        },
        {
          name: 'Аккаунт менеджер', value: 'account', width: 300, show: this.isAdmin,
        },
        {
          name: 'Sales менеджер', value: 'sales', width: 300, show: this.isAdmin,
        },
        {
          name: '', value: 'actions', width: 50, show: true,
        },
      ];
      return headers.filter((item) => item.show);
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    showClientHeader() {
      return this.isAdmin;
    },
  },
};
</script>

<style lang="scss" scoped>
.clients-headers {
  display: flex;
  align-items: center;
  min-width: 100%;
  position: sticky;
  background: white;
  z-index: 2;
  top: 0px;
  left: 0px;
  border-top: 1px solid $primary-group-border;
  border-bottom: 1px solid $primary-group-border;
}

.clients-headers__element {
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 5px;
  }
  &_name {
    padding: 12px 0px;
    position: sticky;
    left: 0px;
    padding-left: 20px;
    background: white;
    justify-content: flex-start;
    align-items: flex-start;
    @include tablet-screen {
      width: 400px;
    }
  }
}

.clients-headers__text {
  font-size: 14px;
  color: $primary-gray;
  &_name {
    color: $primary-blue;
  }
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

</style>
