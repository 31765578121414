var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-checkbox",class:[
  _vm.getChecked && 'data-checkbox_checked',
  _vm.disabled && 'disabled',
  !_vm.nochange && 'pointer',
  !_vm.nochange && 'hover'
],style:({
  'width': _vm.size + 'px',
  'height': _vm.size + 'px',
}),on:{"click":_vm.toggleCheck}},[_c('transition',[_c('DataIcon',{staticClass:"data-checkbox__icon",class:_vm.getChecked && 'data-checkbox__icon_show',attrs:{"name":_vm.getIconName ? _vm.getIconName : '',"size":_vm.size - 2}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }