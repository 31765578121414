<template>
  <div class="groups-filter">
    <SocialSwitcher
    :selected-type="getSelectedTypeOfGroups"
    :disable="isLoading" />
    <div
    class="group-filter__main-container">
      <div class="group-filter__main-filters">
        <div
        class="group-filter__filters"
        :class="showAllFilters && 'group-filter__filters_show-all'">
          <div
          class="group-filter__filters-container"
          :class="showAllFilters && 'group-filter__filters-container_show-all'">
            <!-- Название и тд -->
            <AccountFilter
            :name.sync="searchParams.nameOrUrl"
            :url.sync="searchParams.byUrl"
            :contact.sync="searchParams.contact"
            :description.sync="searchParams.description"
            :disabled="isLoading"
            :reset="paramsIsEnabled"
            @resetAllFilters="resetAllFilters" />

            <!-- Категории -->
            <CategoriesFilter
            :selected-categories.sync="searchParams.categoriesId"
            :categories-count="getGroupsAggregateValues.totalCountByCategories"
            :without-category.sync="searchParams.withoutCategory"
            :reset="paramsIsEnabled" />

            <!-- Подписчики -->
            <NumericFilter
            :title="$t('subscribers')"
            :min.sync="searchParams.subscribersMin"
            :max.sync="searchParams.subscribersMax"
            :disabled="isLoading"
            :to-max="getGroupsAggregateValues.subscribersMax"
            :reset="paramsIsEnabled"
            with-interval />

            <!-- Просмотры -->
            <NumericFilter
            :title="$t('views')"
            :search-type.sync="searchParams.viewsType"
            :min.sync="searchParams.viewsMin"
            :max.sync="searchParams.viewsMax"
            :to-max="getAggregateForViews"
            :reset="paramsIsEnabled"
            :disabled="isLoading"
            with-interval
            type="views" />

            <!-- География -->
            <GeographyFilter
            :countries.sync="searchParams.subscriberCountries"
            :without-country.sync="searchParams.withoutCountry"
            :disabled="isLoading"
            :reset="paramsIsEnabled" />

            <!-- Цены -->
            <NumericFilter
            :title="$t('prices')"
            :search-type.sync="searchParams.priceType"
            :min.sync="searchParams.priceMin"
            :max.sync="searchParams.priceMax"
            :placeholder="$t('any_price')"
            with-interval
            type="price"
            :to-max="getGroupsAggregateValues.postPriceSellMax"
            :disabled="isLoading"
            :reset="paramsIsEnabled" />

            <!--Аудитория -->
            <AuditoryFilter
            :male-min.sync="searchParams.malePercentMin"
            :male-max.sync="searchParams.malePercentMax"
            :female-min.sync="searchParams.femalePercentMin"
            :female-max.sync="searchParams.femalePercentMax"
            :interests-id.sync="searchParams.interestsId"
            :age-min.sync="searchParams.ageMin"
            :age-max.sync="searchParams.ageMax"
            :disabled="isLoading"
            :reset="paramsIsEnabled" />

            <!-- Количество постов -->
            <NumericFilter
            :title="$t('posts_count')"
            :search-type.sync="searchParams.postsType"
            :min.sync="searchParams.postsMin"
            :max.sync="searchParams.postsMax"
            :to-max="getAggregateForPosts"
            :reset="paramsIsEnabled"
            :disabled="isLoading"
            type="posts" />

            <!-- Рост -->
            <NumericFilter
            :title="$t('growth')"
            :search-type.sync="searchParams.growType"
            :min.sync="searchParams.growMin"
            :max.sync="searchParams.growMax"
            :to-max="getAggregateForGrow"
            :reset="paramsIsEnabled"
            :disabled="isLoading"
            type="grow" />

            <!-- CPM -->
            <NumericFilter
            title="CPM"
            :min.sync="searchParams.cpmMin"
            :max.sync="searchParams.cpmMax"
            :to-max="getGroupsAggregateValues.cpmMax"
            :disabled="isLoading"
            :placeholder="$t('any_cpm-er')"
            :reset="paramsIsEnabled"
            :menu-width="220" />

            <!-- CPC -->
            <NumericFilter
            title="CPС"
            :min.sync="searchParams.cpсMin"
            :max.sync="searchParams.cpсMax"
            :to-max="50000"
            :disabled="isLoading"
            :placeholder="$t('any_cpm-er')"
            :reset="paramsIsEnabled"
            :menu-width="220" />

            <!-- ER -->
            <NumericFilter
            title="ER"
            :min.sync="searchParams.erMin"
            :max.sync="searchParams.erMax"
            :to-max="getGroupsAggregateValues.erMax"
            :disabled="isLoading"
            :placeholder="$t('any_cpm-er')"
            :reset="paramsIsEnabled"
            :menu-width="220" />

            <!-- Даты -->
            <DatesFilter
            v-if="isAdmin"
            :updated-at-min.sync="searchParams.dataUpdatedAtFrom"
            :updated-at-max.sync="searchParams.dataUpdatedAtTo"
            :created-at-min.sync="searchParams.createdAtFrom"
            :created-at-max.sync="searchParams.createdAtTo"
            :our-trade-offer-updated-at-from.sync="searchParams.ourTradeOfferUpdatedAtFrom"
            :our-trade-offer-updated-at-to.sync="searchParams.ourTradeOfferUpdatedAtTo"
            :disabled="isLoading"
            :reset="paramsIsEnabled" />

            <!-- Админские фильтры -->
            <AdminFilters
            v-if="isAdmin"
            :disabled="isLoading"
            :in-social-jet.sync="searchParams.inSocialjet"
            :in-black-list.sync="searchParams.inBlackList"
            :bot-name.sync="searchParams.botName"
            :payment-types-id.sync="searchParams.paymentTypesId"
            :post-sale-bigger-than-buy.sync="searchParams.postSaleBiggerThanBuy"
            :warnings.sync="searchParams.inspectReportWarningCodes"
            :count-in-compilations-min.sync="searchParams.countInCompilationsMin"
            :count-in-compilations-max.sync="searchParams.countInCompilationsMax"
            :campaign-launcher-count-min.sync="searchParams.campaignLauncherCountMin"
            :campaign-launcher-count-max.sync="searchParams.campaignLauncherCountMax"
            :reset="paramsIsEnabled" />

            <!-- Другие фильтры -->
            <OtherFilters
            :advertising-label.sync="searchParams.advertisingLabel"
            :placement-in-feed.sync="searchParams.placementInFeed"
            :placement-in-top.sync="searchParams.placementInTop"
            :roboticity-min.sync="searchParams.roboticityMin"
            :roboticity-max.sync="searchParams.roboticityMax"
            :disabled="isLoading"
            :reset="paramsIsEnabled" />
          </div>
        </div>
        <div class="group-filter__fixed-filters">
          <PriceSwitcher />
          <div class="group-filter__search-button">
            <DataButton
            text="Найти"
            :width="90"
            @handler="searchGroups" />
          </div>
        </div>
      </div>
      <div class="group-filter__checkboxes-filters">
        <div class="group-filter__checkboxes">
          <div class="group-filter__checkboxes-item">
            <DataCheckbox
            :value="searchParams.isExclusive"
            @input="setCheckboxFilter($event, 'isExclusive')" />
            <span class="groups-filter__text">
              Эксклюзивные каналы
            </span>
          </div>
          <div
          v-if="isAdmin || isAgency"
          class="group-filter__checkboxes-item">
            <DataCheckbox
            :value="searchParams.isBlogger"
            @input="setCheckboxFilter($event, 'isBlogger')" />
            <span class="groups-filter__text">
              {{ $t('blogger') }}
            </span>
          </div>
          <div class="group-filter__checkboxes-item">
            <DataCheckbox
            :value="searchParams.withoutAd"
            @input="setCheckboxFilter($event, 'withoutAd')" />
            <span class="groups-filter__text">
              Каналы без рекламы
            </span>
          </div>
          <div
          v-if="isAdmin || isAgency"
          class="group-filter__checkboxes-item">
            <DataCheckbox
            :value="searchParams.onlyBadGroups"
            @input="setCheckboxFilter($event, 'onlyBadGroups')" />
            <span class="groups-filter__text">
              Скрытые каналы
            </span>
          </div>
          <div
          v-if="isAdmin"
          class="group-filter__checkboxes-item">
            <DataCheckbox
            :value="searchParams.containsContact === false ? true : false"
            @input="setCheckboxFilter($event, 'containsContact')" />
            <span class="groups-filter__text">
              Без контактов
            </span>
          </div>
          <div
          v-if="isAdmin"
          class="group-filter__checkboxes-item">
            <DataCheckbox
            :value="searchParams.fromPublisherBot"
            @input="setCheckboxFilter($event, 'fromPublisherBot')" />
            <span class="groups-filter__text">
              Каналы из бота
            </span>
          </div>
        </div>
        <FilterResults
        :price-stats="getGroupsAggregateValues"
        :disabled="isLoading"
        :with-price.sync="searchParams.containsPostPriceSell"
        :reset="paramsIsEnabled" />
      </div>
    </div>
    <FilterControls
    :show-all-filters="showAllFilters"
    :params-is-enabled="paramsIsEnabled"
    @resetAllFilters="resetAllFilters"
    @searchGroups="searchGroups"
    @toggleAllFilters="showAllFilters = !showAllFilters" />
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import groupsData from '@/assets/js/groupsData';

import SocialSwitcher from './components/SocialSwitcher.vue';
import FilterControls from './components/FilterControls.vue';
import FilterResults from './components/FilterResults.vue';
import AccountFilter from './components/AccountFilter/AccountFilter.vue';
import CategoriesFilter from './components/CategoriesFilter.vue';
import NumericFilter from './components/NumericFilter.vue';
import AuditoryFilter from './components/AuditoryFilter.vue';
import DatesFilter from './components/DatesFilter.vue';
import AdminFilters from './components/AdminFilters.vue';
import GeographyFilter from './components/GeographyFilter.vue';
import OtherFilters from './components/OtherFilters.vue';
import PriceSwitcher from './components/PriceSwitcher.vue';

export default {
  name: 'GroupsFilter',
  components: {
    SocialSwitcher,
    FilterControls,
    AccountFilter,
    FilterResults,
    CategoriesFilter,
    NumericFilter,
    AuditoryFilter,
    DatesFilter,
    AdminFilters,
    GeographyFilter,
    OtherFilters,
    PriceSwitcher,
  },
  data: () => ({
    showAllFilters: false,
    searchParams: null,
    prevParams: null,
  }),
  created() {
    this.searchParams = { ...this.getSearchParams };
    this.filtersData = groupsData;
  },
  methods: {
    resetAllFilters() {
      this.$store.commit('groups/setInitial', true);
      this.$store.commit('groups/resetGroupsSearchParams');
      this.searchParams = { ...this.getSearchParams };
      this.$router.push({ path: this.$router.currentPath, query: {} });
    },
    searchGroups() {
      const newParams = pickBy(this.searchParams, (param) => param !== null);
      if (this.isLoading || this.getIsDemoCatalog) {
        return;
      }
      this.$store.commit('groups/setLoadPage', 1);
      const query = { group: this.getSelectedTypeOfGroups, ...newParams };
      this.$router.push({ path: this.$router.currentPath, query });
      this.$store.commit('collections/setShowSelectedGroups', false);
      this.$store.commit('favorites/setShowFavoriteGroups', false);
      this.$store.commit('groups/setGroupsSearchParams', this.searchParams);
      this.$store.commit('groups/setGroupsData', []);
      this.$store.dispatch('groups/loadGroupsData', { aggregate: true });
    },
    setCheckboxFilter(value, field) {
      if (field === 'containsContact') {
        this.searchParams[field] = value === true ? false : null;
      } else {
        this.searchParams[field] = value || null;
      }
      this.searchGroups();
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters['app/getAppIsLoading'];
    },
    getGroupsAggregateValues() {
      return this.$store.getters['groups/getGroupsAggregateValues'];
    },
    getSearchParams() {
      return this.$store.getters['groups/getGroupsSearchParams'];
    },
    getSearchNameOrUrl() {
      return this.getSearchParams.nameOrUrl;
    },
    paramsIsEnabled() {
      return !isEqual(this.searchParams, this.$store.getters['groups/getGroupsSearchParamsTemplate']);
    },
    isResetParams() {
      return isEqual(this.getSearchParams, this.$store.getters['groups/getGroupsSearchParamsTemplate']);
    },
    getSelectedTypeOfGroups() {
      return this.$store.getters['groups/getSelectedTypeOfGroups'];
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
    getAggregateForViews() {
      if (this.searchParams.viewsType === 'last48h') {
        return this.getGroupsAggregateValues.viewsLast48hMax;
      }
      if (this.searchParams.viewsType === 'last72h') {
        return this.getGroupsAggregateValues.viewsLast72hMax;
      }
      return this.getGroupsAggregateValues.viewsLast24hMax;
    },
    getAggregateForPosts() {
      if (this.searchParams.postsType === 'lastMonth') {
        return this.getGroupsAggregateValues.postsLastMonthMax;
      }
      if (this.searchParams.postsType === 'lastWeek') {
        return this.getGroupsAggregateValues.postsLastWeekMax;
      }
      return this.getGroupsAggregateValues.postsLast24hMax;
    },
    getAggregateForGrow() {
      if (this.searchParams.growType === 'month') {
        return this.getGroupsAggregateValues.monthGrowMax;
      }
      return this.getGroupsAggregateValues.dayGrowMax;
    },
    getIsDemoCatalog() {
      return this.$store.getters['user/getIsDemoCatalog'];
    },
  },
  watch: {
    isResetParams(newValue, prevValue) {
      if (!prevValue && newValue) {
        this.resetAllFilters();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.groups-filter {
  width: 100%;
  max-width: 1350px;
  padding: 0px 20px;
  margin: 60px auto 36px;
  border-radius: 12px;
  box-sizing: border-box;
}

.groups-filter__nav {
  width: 100%;
  height: 30px;
}

.group-filter__main-container {
  width: 100%;
  border-radius: 12px;
  padding: 10px 20px 13px;
  background: $primary-white;
  margin-bottom: 13px;
  justify-content: center;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
}

.group-filter__fixed-filters {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 20px;
  }
}

.group-filter__filters {
  height: 60px;
  display: flex;
  &_show-all {
    height: 100%;
  }
}

.group-filter__filters-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  @include between-elements {
    margin-right: 10px;
  }
}

.group-filter__main-filters {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  padding-bottom: 10px;
}

.groups-filter__text {
  font-size: 14px;
  color: $primary-gray;
  line-height: 16px;
  white-space: nowrap;
}

.groups-filter__show-all-button {
  position: absolute;
  left: 40px;
  bottom: -30px;
  cursor: pointer;
}

.group-filter__checkboxes-filters {
  width: 100%;
  border-top: 1px solid #d8f2fa;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  @include between-elements {
    margin-right: 30px;
  }
}

.group-filter__checkboxes {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  flex-wrap: wrap;
  @include between-elements {
    margin-right: 20px;
  }
}

.group-filter__checkboxes-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 25px;
  @include between-elements {
    margin-right: 10px;
  }
}

.group-filter__search-button {
  border-left: 2px solid $primary-blue;
  height: 40px;
  padding-left: 20px;
}
</style>
