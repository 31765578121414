<template>
  <div class="filter-controls">
    <div class="filter-controls__container">
      <div
      class="filter-controls__element"
      @click="$emit('toggleAllFilters')">
        <span
        class="filter-controls__text">{{ $t('more_filters') }}</span>
        <DataIcon
        name="expand_more"
        :size="20"
        class="filter-controls__text"
        :arrow-open="showAllFilters" />
      </div>
      <div
      v-if="paramsIsEnabled"
      class="filter-controls__element"
      @click="resetFilters">
        <span
        class="filter-controls__text">{{ $t('reset_filters') }}</span>
        <DataIcon
        name="close"
        :size="16"
        class="filter-controls__text" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterControls',
  props: {
    showAllFilters: {
      type: Boolean,
      default: false,
    },
    paramsIsEnabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    resetFilters() {
      this.$emit('resetAllFilters');
      this.$emit('searchGroups');
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include tablet-screen {
    margin-right: 20px;
    padding: 0px 20px;
  }
}

.filter-controls__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-controls__element {
  cursor: pointer;
  margin-right: 0px;
  display: flex;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  @include between-elements {
    margin-right: 5px;
  }
  @include tablet-screen {
    margin-right: 20px;
  }
}

.filter-controls__text {
  color: $primary-gray;
  font-size: 14px;
}
</style>
