<template>
  <div class="group-analytics-el">
    <span class="group-analytics-el__text group-analytics-el__text_title">Кол-во просмотров</span>
    <div class="group-analytics-el__info-block">
      <div
      v-for="item in items"
      :key="item.label"
      class="group-analytics-el__info-elem">
        <span class="group-analytics-el__text">{{ item.label }}</span>
        <div class="group-analytics-el__info-values">
          <span class="group-analytics-el__text group-analytics-el__text_bold">
            {{ getViews(item.value) ? getViews(item.value) : '—' }}
          </span>
          <span
          v-if="item.er && getEr"
          class="group-analytics-el__text group-analytics-el__text_blue">
            {{ getEr }}
          </span>
        </div>
      </div>
    </div>
    <GroupPriceChart
    v-if="chartData.length"
    smoothed
    class="group-analytics-el__chart"
    :with-brush="isDesktop"
    :chart-data="chartData" />
    <div
    v-else
    class="group-analytics-el__empty">
      <img
      v-if="isLoading"
      class="group-analytics-el__loader"
      src="@/assets/images/rocket.gif">
      <p
      class="group-analytics-el__text group-analytics-el__text_small"
      :class="isLoading && 'group-analytics-el__text_light'">
        {{ isLoading ? 'Загрузка истории количества просмотров...' : 'История изменений количества просмотров недоступна' }}
      </p>
    </div>
  </div>
</template>

<script>
import GroupPriceChart from '@/components/MainGroups/components/Group/components/GroupPriceChart.vue';

export default {
  name: 'GroupAnViews',
  components: { GroupPriceChart },
  props: {
    group: {
      type: Object,
      default: null,
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [
      { label: '24 часа', value: 'countLast24h', er: true },
      { label: '48 часов', value: 'countLast48h' },
      { label: '72 часа', value: 'countLast72h' },
    ],
  }),
  methods: {
    getViews(type) {
      if (!type || !this.group.viewsStatistic || !this.group.viewsStatistic[type]) return false;

      const views = this.group.viewsStatistic[type] || null;
      if (views === null || views === undefined) {
        return false;
      }
      return this.setNumberSpaces(views);
    },
  },
  computed: {
    isDesktop() {
      return window.innerWidth > 1080;
    },
    getEr() {
      return this.group.er ? `(ER ${this.group.er}%)` : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-analytics-el {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  padding: 32px 32px 45px;
  border-radius: 20px;
  box-shadow: 0px 4px 23px rgba(0, 174, 232, 0.12);
  min-height: 550px;
}

.group-analytics-el__text {
  font-size: 14px;
  &_title {
    font-size: 20px;
    font-weight: 700;
  }
  &_light {
    font-size: 14px;
    color: $primary-lightgray;
  }
  &_bold {
    font-size: 24px;
    font-weight: 700;
    color: $primary-blue;
  }
  &_blue {
    color: $primary-blue;
    font-size: 16px;
  }
}

.group-analytics-el__info-block {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  margin-top: 20px;
  @include tablet-screen {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.group-analytics-el__info-elem {
  background: #F0F9FC;
  border-radius: 20px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 5px;
  }
  @include desktop-screen {
    @include between-elements {
      margin-bottom: 20px;
    }
  }
}

.group-analytics-el__chart {
  margin-top: 20px;
  width: 100%;
  height: 320px;
}

.group-analytics-el__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 88px;
  border-radius: 20px;
  margin-top: 20px;
  background: white;
  border: 1px solid #D8F2FA;
  padding: 0px 20px;
  text-align: center;
  @include desktop-screen {
    height: 100%;
  }
  @include between-elements {
    margin-bottom: 20px;
  }
}

.group-analytics-el__loader {
  display: none;
  @include desktop-screen {
    display: block;
    width: 50px;
    height: 50px;
  }
}
</style>
