<template>
  <div class="sji-moderation">
    <div
    v-if="!isEmptyGroups"
    ref="groupsContent"
    class="sji-moderation__content"
    @scroll="onScroll">
      <div
      class="sji-moderation__elements"
      :class="isLoading && 'sji-moderation__elements_loading'">
        <SjiHeaders :selected-tab="selectedTab" />
        <SjiElement
        v-for="(group, index) in groups"
        :key="group.id"
        :index="index"
        :group="group"
        :selected-tab="selectedTab" />
      </div>
    </div>
    <div
    v-if="isEmptyGroups || isLoading"
    class="sji-moderation__elements-empty">
      <span class="sji-moderation__text_light">
        {{ isLoading ? 'Загрузка каналов...' : 'Нет каналов для модерации' }}
      </span>
    </div>
  </div>
</template>

<script>
import SjiHeaders from './SjiHeaders.vue';
import SjiElement from './SjiElement.vue';

export default {
  name: 'GroupsModeration',
  components: { SjiHeaders, SjiElement },
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    selectedTab: {
      type: String,
      default: 'wait',
    },
  },
  methods: {
    onScroll() {
      if (this.isLastPage || this.isLoading) {
        return;
      }

      const position = this.$refs.groupsContent.scrollTop;
      const { clientHeight } = this.$refs.groupsContent;

      const endScroll = Math.ceil(position + clientHeight) >= Math.ceil(this.$refs.groupsContent.scrollHeight);
      if (endScroll) {
        this.$emit('getSjiModerateGroups', { reset: false });
      }
    },
  },
  computed: {
    isEmptyGroups() {
      return this.groups.length === 0;
    },
    isLastPage() {
      return this.$store.getters['moderate/getIsLastPage'];
    },
  },
};
</script>

<style lang="scss" scoped>
.sji-moderation {
  background: $primary-white;
  border-radius: 12px;
  display: flex;
  flex: 1;
  overflow: auto;
  position: relative;
  margin: 20px;
}

.sji-moderation__content {
  background: white;
  z-index: 1;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.sji-moderation__elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
  transition: 0.2s;
  &_loading {
    opacity: 0.4;
    transition: 0.3s;
  }
}

.sji-moderation__elements-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  top: 50%;
  right: 0;
  text-align: center;
}

.sji-moderation__text {
  color: $primary-black;
  font-size: 14px;
  &_light {
    color: $primary-lightgray;
  }
}

</style>
