import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import moment from 'moment';
import { VTooltip } from 'floating-vue';
import VuePortal from '@linusborg/vue-simple-portal';
import money from 'v-money';
import VueYandexMetrika from 'vue-yandex-metrika';
// eslint-disable-next-line import/no-extraneous-dependencies
import sanitizeHtml from 'sanitize-html';
import App from './App.vue';
import router from './router';
import store from './store';
import appComponents from './plugins/app-components';
import phoneMask from './plugins/phoneMask';
import appHelpers from './plugins/app-helpers';
import i18n from './plugins/i18n';
import 'floating-vue/dist/style.css';

Vue.use(money);
Vue.directive('tooltip', VTooltip);
Vue.directive('phone', phoneMask);
Vue.prototype.moment = moment;
Vue.prototype.sanitizeHtml = sanitizeHtml;
Vue.config.productionTip = false;
Vue.use(vClickOutside);
Vue.use(appComponents);
Vue.use(appHelpers);
Vue.use(VuePortal, {
  name: 'portal',
});

Vue.use(VueYandexMetrika, {
  id: 93683000,
  env: process.env.NODE_ENV,
  router,
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  webvisor: true,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
