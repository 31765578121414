<template>
  <div class="campaigns-filters">
    <div class="campaigns-filters__filters-container">
      <div
      v-if="isManager || isAdmin"
      style="width: 300px"
      class="campaigns-filters__filter">
        <span class="campaigns-filters__filter-title">Клиент</span>
        <DataSelect
        v-model="selectedAgency"
        :options="agencyList"
        placeholder="Введите название"
        with-search
        no-option-label="Загрузка списка клиентов..."
        theme="white"
        @input="setClientFilter" />
      </div>
      <div class="campaigns-filters__filter">
        <span class="campaigns-filters__filter-title">Статус</span>
        <DataSelect
        v-model="selectedStatus"
        :options="statuses"
        placeholder="Статус"
        theme="white"
        @input="setStatusFilter" />
      </div>
      <div
      v-if="isAdmin"
      style="width: 250px"
      class="campaigns-filters__filter">
        <span class="campaigns-filters__filter-title">Менеджер</span>
        <DataSelect
        v-model="selectedManager"
        :options="managersList"
        placeholder="Менеджер"
        with-search
        no-option-label="Загрузка списка менеджеров..."
        theme="white"
        @input="setManager" />
      </div>
      <div class="campaigns-filters__filter">
        <span class="campaigns-filters__filter-title">Дата создания от</span>
        <DataDatePicker
        v-model="startDate"
        theme="white"
        @change="setStartDate" />
      </div>
      <div class="campaigns-filters__filter">
        <span class="campaigns-filters__filter-title">Дата создания до</span>
        <DataDatePicker
        v-model="endDate"
        theme="white"
        @change="setEndDate" />
      </div>
    </div>

    <span
    v-if="isActiveFilters"
    class="campaigns-filters__reset"
    @click="resetFilters">Сбросить все фильтры</span>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import api from '@/axios';

export default {
  name: 'RequestFilters',
  data: () => ({
    statuses: [
      { id: 1, name: 'Новый' },
      { id: 2, name: 'В работе' },
      { id: 3, name: 'Готов' },
    ],
    selectedStatus: null,
    managersList: [],
    selectedManager: null,
    agencyList: [],
    selectedAgency: null,
    startDate: null,
    endDate: null,
  }),
  async created() {
    if (this.isAdmin) {
      const managers = await this.$store.dispatch('placements/getResponsibleNames', {});
      this.managersList = managers
        .map((item, index) => ({ id: index, name: item.name || item.email, value: item.id }));
    }
    if (this.isAdmin || this.isManager) {
      this.getClients();
    }
  },
  methods: {
    resetFilters() {
      this.reset();
      this.$store.commit('campaigns/resetCampaignsSearchParams');
      this.$emit('loadCampaignsData', { reset: true });
    },
    getClients() {
      // api.get('/agency', { params: { isAgency: true } })
      api.get('/agency')
        .then(({ data: { data } }) => {
          this.agencyList = data
            .map((item) => ({ name: item.name || item.email, value: item.id }));
        })
        .catch((error) => {
          console.warn('Не удалось загрузить список клиентов', error);
          return Promise.reject(error);
        });
    },
    reset() {
      this.selectedStatus = null;
      this.selectedManager = null;
      this.selectedAgency = null;
      this.startDate = null;
      this.endDate = null;
    },
    setStatusFilter(status) {
      const params = { ...this.getCurrentSearchParams, status: status ? status.id : null };
      this.$store.commit('campaigns/setCampaignsSearchParams', params);
      this.$emit('loadCampaignsData', { reset: true });
    },
    setManager(manager) {
      const params = { ...this.getCurrentSearchParams, requestPlacementResponsibleUserId: manager ? manager.value : null };
      this.$store.commit('campaigns/setCampaignsSearchParams', params);
      this.$emit('loadCampaignsData', { reset: true });
    },
    setStartDate(date) {
      const fromDate = date ? `${date} 00:00` : null;
      const params = { ...this.getCurrentSearchParams, startDateTimeFrom: fromDate };
      this.$store.commit('campaigns/setCampaignsSearchParams', params);
      this.$emit('loadCampaignsData', { reset: true });
    },
    setEndDate(date) {
      const toDate = date ? `${date} 23:59` : null;
      const params = { ...this.getCurrentSearchParams, startDateTimeTo: toDate };
      this.$store.commit('campaigns/setCampaignsSearchParams', params);
      this.$emit('loadCampaignsData', { reset: true });
    },
    setClientFilter() {
      const params = { ...this.getCurrentSearchParams, ownerAgencyId: this.selectedAgency?.value ? this.selectedAgency.value : null };
      this.$store.commit('campaigns/setCampaignsSearchParams', params);
      this.$emit('loadCampaignsData', { reset: true });
    },
  },
  computed: {
    getCurrentSearchParams() {
      return this.$store.getters['campaigns/getCampaignsSearchParams'];
    },
    getCurrentSearchParamsTemplate() {
      return this.$store.getters['campaigns/getCampaignsSearchParamsTemplate'];
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
    isManager() {
      return this.$store.getters['user/getIsManager'];
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    isActiveFilters() {
      return !isEqual(this.getCurrentSearchParams, this.getCurrentSearchParamsTemplate);
    },
  },
};
</script>

<style lang="scss" scoped>
.campaigns-filters__filters-container {
  // display: grid;
  // grid-template-columns: 300px 200px 250px 200px 200px;
  // justify-items: stretch;
  // align-items: stretch;
  // gap: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.campaigns-filters__filter {
  display: flex;
  flex-direction: column;
  width: 200px;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.campaigns-filters__filter-title {
  font-size: 14px;
}

.campaigns-filters__reset {
  display: block;
  padding: 10px 5px 5px 5px;
  cursor: pointer;
  font-size: 14px;
}
</style>
