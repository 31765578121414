<template>
  <div class="rules-modal">
    <div class="rules-modal__rules">
      <a
      v-for="rule in rules"
      :key="rule.id"
      :href="rule.link"
      target="_blank"
      class="rules-modal__element">{{ `${rule.id}. ${rule.name}` }}</a>
    </div>
    <DataButton
    text="Продолжить"
    @handler="$emit('close')" />
  </div>
</template>

<script>
export default {
  name: 'RulesModal',
  data: () => ({
    rules: [
      {
        id: 1,
        name: 'Агентский договор',
        link: 'https://socialjet.pro/a_agreement',
      },
      {
        id: 2,
        name: 'Политика обработки персональных данных',
        link: 'https://socialjet.pro/privacy',
      },
      {
        id: 3,
        name: 'Пользовательское соглашение',
        link: 'https://socialjet.pro/u_agreement',
      },
      {
        id: 4,
        name: 'Правила оказания услуг',
        link: 'https://socialjet.pro/terms',
      },
      {
        id: 5,
        name: 'Согласие на рассылку',
        link: 'https://socialjet.pro/ad_accept',
      },
      {
        id: 6,
        name: 'Согласие на обработку персональных данных',
        link: 'https://socialjet.pro/privacy_accept',
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.rules-modal {
  min-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include between-elements {
    margin-bottom: 50px;
  }
}

.rules-modal__rules {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 15px;
  }
}
.rules-modal__element {
  text-decoration: none;
  font-size: 16px;
  color: $primary-gray;
}
</style>
