<template>
  <div>
    <!-- Элемент модерации цен -->
    <div
    v-if="sourceType === 'import'"
    class="group-moderation-element">
      <div class="group-moderation-element__column group-moderation-element__column_name">
        <img
        class="group-moderation-element__avatar"
        :src="group.entity.avatar ? group.entity.avatar : require('@/assets/svg/empty-avatar.svg')"
        @click="routeToCurrentGroupLink">
        <div class="group-moderation-element__name-text">
          <span
          v-tooltip="group.entity.name"
          class="group-moderation-element__text group-moderation-element__text_name">
            {{ group.entity.name }}
          </span>
          <span
          v-if="categories"
          class="group-moderation-element__text group-moderation-element__text_category">
            {{ categories }}
          </span>
        </div>
      </div>

      <!-- Subscribers -->
      <div class="group-moderation-element__column">
        <span class="group-moderation-element__text">
          {{ subscribers }}
        </span>
      </div>

      <!-- Views -->
      <div class="group-moderation-element__column">
        <span class="group-moderation-element__text">
          {{ views }}
        </span>
      </div>

      <!-- Sale -->
      <div class="group-moderation-element__column">
        <div class="group-moderation-element__prices">
          <span class="group-moderation-element__text group-moderation-element__text_green">
            {{ newSale }}
          </span>
          <span class="group-moderation-element__text">
            /
          </span>
          <span class="group-moderation-element__text group-moderation-element__text_red">
            {{ oldSale }}
          </span>
        </div>
        <span class="group-moderation-element__text group-moderation-element__text_light">
          {{ priceType }}
        </span>
      </div>

      <!-- Price -->
      <div class="group-moderation-element__column">
        <div class="group-moderation-element__prices">
          <span class="group-moderation-element__text group-moderation-element__text_green">
            {{ newPrice }}
          </span>
          <span class="group-moderation-element__text">
            /
          </span>
          <span class="group-moderation-element__text group-moderation-element__text_red">
            {{ oldPrice }}
          </span>
        </div>
        <span class="group-moderation-element__text group-moderation-element__text_light">
          {{ priceType }}
        </span>
      </div>

      <!-- Manager -->
      <div class="group-moderation-element__column group-moderation-element__column_wide">
        <span class="group-moderation-element__text group-moderation-element__text_light">
          {{ manager }}
        </span>
        <span class="group-moderation-element__text group-moderation-element__text_light">
          {{ date }}
        </span>
      </div>

      <div
      v-if="status === 'wait'"
      class="group-moderation-element__buttons">
        <span
        class="group-moderation-element__text group-moderation-element__text_blue pointer"
        @click="approveGroup(true)">
          Принять
        </span>
        <span
        class="group-moderation-element__text group-moderation-element__text_red pointer"
        @click="approveGroup(false)">
          Отклонить
        </span>
      </div>
      <div
      v-else
      class="group-moderation-element__statuses">
        <DataIcon
        :name="status === 'applied' ? 'done' : 'close'"
        class="group-moderation-element__text"
        :class="status === 'applied' ? 'group-moderation-element__text_green' : 'group-moderation-element__text_red'"
        :size="20"
        pointer />
        <span class="group-moderation-element__text group-moderation-element__text_light">
          {{ status === 'applied' ? 'Подтверждено' : 'Отклонено' }}
        </span>
      </div>
    </div>

    <!-- Элемент модерации канала -->
    <div
    v-if="sourceType === 'publisherBot'"
    class="group-moderation-element">
      <div
      :style="`height: ${newPrices?.length * 20}px`"
      class="group-moderation-element__column group-moderation-element__column_name"
      @click="openNewChannelLink">
        <img
        class="group-moderation-element__avatar"
        :src="require('@/assets/svg/empty-avatar.svg')">
        <div class="group-moderation-element__name-text pointer">
          <span class="group-moderation-element__text group-moderation-element__text_name">
            {{ name }}
          </span>
        </div>
      </div>

      <!-- Контакт админа -->
      <div
      class="group-moderation-element__column group-moderation-element__column_admin pointer"
      style="width: 220px"
      @click="openAdminLink">
        <img
        class="group-moderation-element__social-icon"
        :src="require(`@/assets/svg/groups-icons/${social}-icon.svg`)">
        <span class="group-moderation-element__text group-moderation-element__text_admin">
          {{ adminContact }}
        </span>
      </div>

      <!-- Подписчики -->
      <div
      class="group-moderation-element__column"
      style="width: 130px">
        <span class="group-moderation-element__text">
          {{ subscribers }}
        </span>
      </div>

      <!-- Просмотры -->
      <div
      class="group-moderation-element__column"
      style="width: 130px">
        <span class="group-moderation-element__text">
          {{ views }}
        </span>
      </div>

      <!-- Стоимость -->
      <div
      class="group-moderation-element__column group-moderation-element__column_price"
      style="width: 220px">
        <div
        v-for="price in newPrices"
        :key="price.text">
          <span
          class="group-moderation-element__text group-moderation-element__text_category">
            {{ price.text + ':' }}
          </span>
          <span
          class="group-moderation-element__text">
            {{ price.value + ' ' + getCurrencySymbol }}
          </span>
        </div>
      </div>

      <!-- Контакты -->
      <div
      class="group-moderation-element__column"
      style="width: 180px">
        <span class="group-moderation-element__text group-moderation-element__text_category">
          {{ contact }}
        </span>
      </div>

      <!-- Тип оплаты -->
      <div
      class="group-moderation-element__column"
      style="width: 130px">
        <span class="group-moderation-element__text">
          {{ paymentType }}
        </span>
      </div>

      <!-- Статус -->
      <div
      class="group-moderation-element__column"
      style="width: 130px"
      @click="setInWork">
        <span
        class="group-moderation-element__text pointer"
        :class="group.status === 'in work' && 'group-moderation-element__text_green'">
          {{ getChannelStatus }}
        </span>
      </div>

      <!-- Действия -->
      <div
      v-if="group.status === 'wait' || group.status === 'in work'"
      class="group-moderation-element__buttons">
        <span
        class="group-moderation-element__text group-moderation-element__text_blue pointer"
        @click="approveGroup(true)">
          Принять
        </span>
        <span
        class="group-moderation-element__text group-moderation-element__text_red pointer"
        @click="approveGroup(false)">
          Отклонить
        </span>
      </div>
      <div
      v-else
      v-tooltip.left="rejectReason ? rejectReason : ''"
      class="group-moderation-element__statuses">
        <DataIcon
        :name="status === 'applied' ? 'done' : 'close'"
        class="group-moderation-element__text"
        :class="status === 'applied' ? 'group-moderation-element__text_green' : 'group-moderation-element__text_red'"
        :size="20"
        pointer />
        <span class="group-moderation-element__text group-moderation-element__text_light">
          {{ status === 'applied' ? 'Подтверждено' : 'Отклонено' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupElement',
  props: {
    group: {
      type: Object,
      default: () => {},
    },
    sourceType: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    social: null,
    isNewGroup: false,
    status: null,
    url: null,
    adminContact: null,
    contact: null,
    name: null,
    categories: null,
    subscribers: null,
    views: null,
    manager: null,
    date: null,
    oldSale: null,
    newSale: null,
    oldPrice: null,
    newPrice: null,
    priceType: null,
    rejectReason: null,
    productsId: {
      1: 'postPrice',
      2: 'storyPrice',
      3: 'reelPrice',
      4: 'streamPrice',
      5: 'integrationPrice',
      6: 'circlePrice',
      7: 'nonStandartPrice',
    },
    paymentOptions: [
      { id: 1, name: 'ИП' },
      { id: 2, name: 'СЗ' },
      { id: 3, name: 'КЭШ' },
      { id: 4, name: 'Другое' },
      { id: 5, name: 'OOO' },
      { id: 6, name: 'Sollar' },
    ],
    paymentType: null,
    newPrices: null,
    pricesTypes: [
      { name: 'postPriceWithoutDelete', value: 'Без удаления' },
      { name: 'postPrice72h', value: 'Пост 72ч' },
      { name: 'postPrice48h', value: 'Пост 48ч' },
      { name: 'postPrice24h', value: 'Пост 24ч' },
      { name: 'storyPrice', value: 'Сторис' },
      { name: 'fixedInTopPrice', value: 'Закреп' },
      { name: 'integrationPrice', value: 'Интеграция' },
      { name: 'streamPrice', value: 'Стрим' },
      { name: 'circlePrice15s', value: 'Кружочки 15 сек' },
      { name: 'circlePrice30s', value: 'Кружочки 30 сек' },
      { name: 'circlePrice60s', value: 'Кружочки 60 сек' },
      { name: 'nonstandardPrice', value: 'Нестандарт' },
    ],
    statuses: [
      {
        name: 'Не взят в работу', value: 'wait', counter: 'wait', color: 'orange',
      },
      {
        name: 'В работе', value: 'in-work', counter: 'in-work', color: 'orange',
      },
      {
        name: 'Подтверждённые', value: 'applied', counter: 'applied', color: 'green',
      },
      {
        name: 'Отклонённые', value: 'rejected', counter: 'rejected', color: 'red',
      },
    ],
  }),
  created() {
    this.isNewGroup = !this.group.entity;
    if (this.sourceType === 'import') {
      this.initDataForPrice();
      return;
    }
    this.initDataForNewChannel();
  },
  methods: {
    initDataForPrice() {
      this.name = this.group.entity.name;
      this.status = this.group.status;
      this.categories = this.getCategories();
      this.subscribers = this.getSubscribers();
      this.views = this.getViews();
      this.manager = this.group.userChanger.email;
      this.date = this.moment(this.group.createdAt.date).format('LLL');
      // sale
      this.oldSale = this.getOldPrice('sale');
      this.newSale = this.getNewPrice('sale');
      // price
      this.oldPrice = this.getOldPrice('price');
      this.newPrice = this.getNewPrice('price');
    },
    initDataForNewChannel() {
      this.status = this.group.status;
      this.social = this.isNewGroup ? this.group.changes.find((item) => item.field === 'social')?.value || '??' : this.group.entity.social;
      this.name = this.isNewGroup ? this.group.changes.find((item) => item.field === 'name')?.value || '??' : this.group.entity.name;
      this.adminContact = `https://t.me/${this.group.userChanger?.telegram}`;
      this.contact = this.group.changes.find((item) => item.field === 'contact')?.value || '??';
      this.subscribers = this.isNewGroup ? '-' : this.getSubscribers();
      this.url = this.group.changes.find((item) => item.field === 'url')?.value || this.group?.entity?.url || '-';
      this.views = this.isNewGroup ? '-' : this.getViews();
      this.rejectReason = this.status === 'rejected' ? this.group.rejectReason || 'Причина не указана' : null;
      try {
        this.paymentType = this.group.changes
          .find((item) => item.field === 'paymentTypesId')?.value
          .map((item) => this.paymentOptions.find((option) => option.id === item).name).join(',');
      } catch {
        this.paymentType = '?';
      }
      const prices = this.group.changes.find((item) => item.field === 'prices')?.value;
      this.newPrices = prices.map((price) => {
        const priceText = this.pricesTypes.find((item) => item.name === price.priceType).value;
        return { text: priceText, value: price.value };
      });
    },
    getCategories() {
      const categories = this.group.entity.categories?.map((category) => this.$t(category.name));
      return categories.length ? categories.join(', ') : false;
    },
    getSubscribers() {
      return this.group.entity?.subscribersStatistic?.count
        ? this.setNumberSpaces(this.group.entity.subscribersStatistic.count)
        : 'Нет данных';
    },
    getViews() {
      return this.group.entity?.viewsStatistic?.countLast24h
        ? `${this.setNumberSpaces(this.group.entity.viewsStatistic.countLast24h)} / день`
        : 'Нет данных';
    },
    routeToCurrentGroupLink() {
      if (!this.group.entity?.url.match(/^https?:\/\//i)) {
        window.open(`https://${this.group.entity.url}`);
      } else {
        window.open(this.group.entity.url);
      }
    },
    openNewChannelLink() {
      window.open(this.url, '_blank');
    },
    openAdminLink() {
      window.open(this.adminContact, '_blank');
    },
    approveGroup(value) {
      const params = {
        sourceType: this.sourceType, value, id: this.group.id, group: this.group.entity,
      };
      this.$emit('approveGroup', params);
    },
    getOldPrice(type) {
      try {
        const change = this.group.changes[0];
        const oldPrice = this.group.entity.products.find((item) => item.id === change.value.id);
        if (!oldPrice) return 'n/a';

        const priceType = this.productsId[oldPrice.type] || '';

        let priceVariant = '';
        if (oldPrice.durationInHours) {
          priceVariant = oldPrice.durationInHours === 'withoutDelete' ? 'месяц без удаления' : oldPrice.durationInHours;
        }
        if (oldPrice.isFixedInTop) {
          priceVariant = 'закреп';
        }
        if (oldPrice.circleDurationInSeconds) {
          priceVariant = oldPrice.circleDurationInSeconds;
        }

        this.priceType = `${this.$t(priceType)} ${priceVariant}`;

        if (type === 'sale') {
          const currentPrice = oldPrice.tradeOffersChain?.currentTradeOffer?.sale;
          return currentPrice || '-';
        }

        if (type === 'price') {
          const currentPrice = oldPrice.tradeOffersChain?.currentTradeOffer?.resultPrice;
          return currentPrice || '-';
        }

        return 'zz';
      } catch {
        return '?';
      }
    },
    getNewPrice(type) {
      try {
        if (type === 'sale') {
          const change = this.group.changes[0].value?.sale;
          return change || '-';
        }
        if (type === 'price') {
          const change = this.group.changes[0].value?.resultPrice;
          return change || '-';
        }
        return '-';
      } catch {
        return '?';
      }
    },
    setInWork() {
      if (this.group.status !== 'wait') return;
      this.$store.dispatch('moderate/setInWorkModerateChannel', this.group.id);
    },
  },
  computed: {
    getCurrencySymbol() {
      return this.$store.getters['app/getCurrencySymbol'];
    },
    getChannelStatus() {
      if (this.group.status === 'wait') {
        return 'Взять в работу';
      }
      if (this.group.status === 'in work') {
        return 'В работе';
      }
      return this.statuses.find((item) => item.value === this.group.status).name;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-moderation-element {
  display: flex;
  align-items: center;
  min-width: 100%;
  position: sticky;
  background: white;
  top: 0px;
  left: 0px;
  min-height: 58px;
  border-bottom: 1px solid $primary-group-border;
  position: relative;
}

.group-moderation-element__column {
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid $primary-group-border;
  &_name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: sticky;
    left: 0px;
    padding-left: 20px;
    background: white;
    @include tablet-screen {
      min-width: 400px;
      padding-left: 40px;
    }
  }
  &_wide {
    width: 240px;
  }
  &_row {
    flex-direction: row;
    @include between-elements {
      margin-right: 5px;
    }
  }
  &_admin {
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-right: 15px;
    @include between-elements {
      margin-right: 5px;
    }
  }
  &_price {
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px 0px 5px 40px;
    @include between-elements {
      margin-bottom: 2px;
    }
  }
}

.group-moderation-element__text {
  font-size: 14px;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &_name {
    font-size: 16px;
    color: $primary-blue;
  }
  &_category {
    font-size: 12px;
    color: $primary-lightgray;
  }
  &_light {
    color: $primary-lightgray;
    font-size: 12px;
    text-align: center;
  }
  &_red {
    color: $primary-red;
  }
  &_green {
    color: $primary-green;
  }
  &_blue {
    color: $primary-blue;
  }
  &_admin {
    font-size: 14px;
    color: $primary-blue;
  }
}

.group-moderation-element__avatar {
  display: none;
  @include tablet-screen {
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-right: 20px;
    box-sizing: border-box;
    border-radius: 50%;
  }
}

.group-moderation-element__prices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include between-elements {
    margin-right: 10px;
  }
  margin-bottom: 5px;
}

.group-moderation-element__name-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  @include between-elements {
    margin-bottom: 5px;
  }
  &_green {
    color: $primary-green;
  }
}

.group-moderation-element__buttons {
  display: flex;
  width: 240px;
  padding: 0px 20px 0px 40px;
  @include between-elements {
    margin-right: 30px;
  }
}

.group-moderation-element__statuses {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 240px;
  height: 40px;
  padding-left: 30px;
  @include between-elements {
    margin-right: 10px;
  }
}

.group-moderation-element__social-icon {
  width: 20px;
  height: 20px;
}
</style>
