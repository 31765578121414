import Vue from 'vue';
import Vuex from 'vuex';

// Vuex modules
import app from './modules/app';
import user from './modules/user';
import groups from './modules/groups';
import group from './modules/group';
import collections from './modules/collections';
import compilations from './modules/compilations';
import imports from './modules/imports';
import moderate from './modules/moderate';
import clients from './modules/clients';
import placements from './modules/placements';
import tariffs from './modules/tariffs';
import campaigns from './modules/campaigns';
import competitors from './modules/competitors';
import myGroups from './modules/my-groups';
import favorites from './modules/favorites';
import searchPosts from './modules/search-posts';
import catalog from './modules/catalog';
import notifications from './modules/notifications';

Vue.use(Vuex);
export default new Vuex.Store({
  namespaced: true,
  modules: {
    app,
    user,
    group,
    groups,
    collections,
    compilations,
    imports,
    moderate,
    clients,
    placements,
    tariffs,
    campaigns,
    competitors,
    myGroups,
    favorites,
    searchPosts,
    catalog,
    notifications,
  },
});
