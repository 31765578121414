<template>
  <div class="compilation-send-notify">
    <!-- Загрузка -->
    <div
    v-if="isLoading"
    class="compilation-send-notify__container">
      <span class="compilation-send-notify__text">
        Загрузка доступных для рассылки каналов...
      </span>
    </div>

    <!-- Контент -->
    <div v-else>
      <div
      v-if="!isLoading && !awaibleGroupsToSend.length"
      class="compilation-send-notify__container">
        <span class="compilation-send-notify__text">Нет доступных каналов для рассылки</span>
        <DataButton
        text="Закрыть"
        @handler="$emit('close')" />
      </div>
      <div
      v-else
      class="compilation-send-notify__container">
        <div class="compilation-send-notify__groups-container">
          <div
          v-for="group in awaibleGroupsToSend"
          :key="group.group.id"
          class="compilation-send-notify__group-container">
            <DataCheckbox v-model="group.checked" />
            <div class="compilation-send-notify__group">
              <img
              class="compilation-send-notify__group-avatar"
              :src="group.group.avatar ? group.group.avatar : require('@/assets/svg/empty-avatar.svg')">
              <span class="compilation-send-notify__text">{{ group.group.name }}</span>
            </div>
          </div>
        </div>
        <span class="compilation-send-notify__text compilation-send-notify__text_date">Укажите планируемую дату размещения</span>
        <div class="compilation-send-notify__content">
          <div class="compilation-send-notify__dates-container">
            <DataDatePicker
            v-model="periodStart"
            :placeholder="isPeriod ? 'Начало' : 'дд.мм.гггг'"
            :error="periodStartError" />
            <DataDatePicker
            v-if="isPeriod"
            v-model="periodEnd"
            placeholder="Конец"
            :error="periodEndError" />
          </div>
          <div class="compilation-send-notify__checkbox-container">
            <DataCheckbox v-model="isPeriod" />
            <span class="compilation-send-notify__text">Указать период</span>
          </div>
        </div>
        <DataButton
        class="compilation-send-notify__button"
        :text="isSending ? 'Отправка...' : 'Отправить'"
        @handler="sendShareNotification" />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/axios';

export default {
  name: 'CompilationSendNotify',
  data: () => ({
    isPeriod: false,
    periodStart: '',
    periodEnd: '',
    periodStartError: false,
    periodEndError: false,
    isSending: false,
    isLoading: true,
    awaibleGroupsToSend: [],
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.awaibleGroupsToSend = [];
      api.get(`/compilations/${this.getCompilationInfo.id}/positions`, { params: { fromPublisherBot: true } })
        .then(({ data: { data } }) => {
          this.awaibleGroupsToSend = data.map((item) => ({
            ...item,
            checked: true,
          }));
        })
        .catch(() => {
          this.awaibleGroupsToSend = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async sendShareNotification() {
      if (this.isSending) return;

      // Ошибки
      if (this.isPeriod && (!this.periodStart || !this.periodEnd)) {
        this.periodEndError = !this.periodEnd;
        this.periodStartError = !this.periodStart;
        return;
      }
      if (!this.periodStart) {
        this.periodStartError = true;
        return;
      }

      // Payload
      this.periodStartError = false;
      this.periodEndError = false;
      // this.isSending = true;
      const payload = {
        compilationId: this.getCompilationInfo.id,
        positionsIds: this.awaibleGroupsToSend.filter((item) => item.checked).map((item) => item.id),
        periodStart: this.periodStart,
        periodEnd: this.periodEnd,
      };

      this.$store.dispatch('compilations/sendShareNotification', payload)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Увдомления отправлены' });
        })
        .finally(() => {
          this.$emit('close');
        });
    },
  },
  computed: {
    getCompilationInfo() {
      return this.$store.getters['compilations/getCurrentCompilationInfo'];
    },
  },
};
</script>

<style lang="scss" scoped>
.compilation-send-notify {
  .compilation-send-notify__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    min-width: 550px;
  }

  .compilation-send-notify__text {
    color: $primary-gray;
    &_date {
      border-top: 1px solid $primary-group-border;
      width: 100%;
      text-align: center;
      padding-top: 20px;
      font-size: 14px;
    }
  }

  .compilation-send-notify__dates-container {
    display: flex;
    justify-content: center;
    align-items: center;
    @include between-elements {
      margin-right: 20px;
    }
  }

  .compilation-send-notify__button {
    margin-top: 20px;
  }

  .compilation-send-notify__checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    @include between-elements {
      margin-right: 10px;
    }
  }

  .compilation-send-notify__groups-container {
    max-height: 240px;
    overflow: auto;
    padding-right: 20px;
    padding-bottom: 10px;
    width: 100%;
    min-width: 550px;
    @include between-elements {
      margin-bottom: 10px;
    }
  }

  .compilation-send-notify__group-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include between-elements {
      margin-right: 10px;
    }
  }

  .compilation-send-notify__content {
    width: 100%;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include between-elements {
      margin-right: 20px;
    }
  }

  .compilation-send-notify__group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include between-elements {
      margin-right: 10px;
    }
  }

  .compilation-send-notify__group-avatar {
    width: 20px;
    height: 20px;
    border-radius: 100%;
  }
}
</style>
