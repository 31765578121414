<template>
  <div class="competitors-headers">
    <div class="competitors-headers__checkbox">
      <DataCheckbox
      :value="isAllSelected"
      @input="setAllSelected" />
    </div>

    <div
    v-for="header in getHeaders"
    :key="header.value"
    :style="`width: ${header.width}px`"
    :class="{
      'competitors-headers__element_name': header.value === 'name',
    }"
    class="competitors-headers__element"
    @click="changeSort(header.sort)">
      <span
      class="competitors-headers__text"
      :class="{
        'competitors-headers__text_selected': header.sort && header.sort === getSortBy,
        'pointer' : header.sort,
      }">
        {{ header.text }}
      </span>
      <DataIcon
      v-if="header.sort"
      :size="12"
      name="south"
      :arrow-open="getSortDir === 'asc' && header.sort === getSortBy"
      class="groups-header__icon"
      :class="[
        header.sort === getSortBy && 'groups-header__icon_selected',
        header.sort && 'pointer'
      ]" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompetitorsHeaders',
  props: {
    selectedTab: {
      type: String,
      default: 'wait',
    },
    groups: {
      type: Array,
      default: null,
    },
    isAllSelected: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  methods: {
    setAllSelected() {
      this.groups.forEach((item) => { item.externalPrice.checked = !this.isAllSelected; });
    },
    changeSort(value) {
      if (!value) {
        return;
      }
      if (this.getSortBy === value) {
        const newDir = this.getSortDir === 'asc' ? 'desc' : 'asc';
        this.$store.commit('competitors/setSortDir', newDir);
      } else {
        this.$store.commit('competitors/setSortDir', 'desc');
      }

      this.$store.commit('competitors/setSortBy', value);
      this.$emit('loadCompetitorsGroups', { reset: true });
    },
  },
  computed: {
    getHeaders() {
      const headers = [
        {
          name: 'name2', value: 'name', text: 'Название', show: true,
        },
        {
          name: 'subscribers', value: 'subscribers', text: 'Подписчики', width: 150, show: true,
        },
        {
          name: 'views', value: 'views', text: 'Охват', width: 150, show: true,
        },
        {
          name: 'SocialData', value: 'SocialData', text: 'SocialData Прайс', width: 200, show: true,
        },
        {
          name: 'SocialData', value: 'SocialData2', text: 'SocialData Себес', width: 200, show: true,
        },
        {
          name: 'Телега.ин', value: 'telega-in', text: 'Телега.ин', width: 200, show: true,
        },
        {
          name: 'difference', value: 'difference', sort: 'differencePercent', text: 'Разница в цене', width: 200, show: true,
        },
        {
          name: '', value: '', text: '', width: 150, show: true,
        },
      ];
      return headers.filter((column) => column.show);
    },
    getSortDir() {
      return this.$store.getters['competitors/getSortDir'];
    },
    getSortBy() {
      return this.$store.getters['competitors/getSortBy'];
    },
  },
};
</script>

<style lang="scss" scoped>
.competitors-headers {
  display: flex;
  align-items: center;
  min-width: 100%;
  z-index: 1;
  position: sticky;
  background: white;
  top: 0px;
  left: 20px;
  height: 45px;
  border-bottom: 1px solid $primary-group-border;
  z-index: 2;
}

.competitors-headers__checkbox {
  position: sticky;
  background: white;
  top: 0px;
  left: 0px;
  padding-left: 20px;
  z-index: 2;
  height: 20px;
  width: 40px;
}

.competitors-headers__element {
  display: flex;
  justify-content: center;
  align-items: center;
  &_name {
    position: sticky;
    left: 40px;
    top: 0px;
    padding-left: 20px;
    background: white;
    justify-content: flex-start;
    align-items: flex-start;
    width: 200px;
    @include tablet-screen {
      padding-left: 20px;
      width: 360px;
    }
  }
  @include between-elements {
    margin-right: 10px;
  }
}

.competitors-headers__text {
  font-size: 13px;
  color: $primary-gray;
  &_name {
    font-size: 14px;
    color: $primary-blue;
    width: 300px;
  }
  &_selected {
    font-weight: 600;
  }
}
</style>
