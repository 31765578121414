<template>
  <div
  class="data-burger-button"
  @click="toggleButton">
    <div
    class="data-burger-button__line"
    :class="isOpen && 'data-burger-button__line_top_active'" />
    <div
    class="data-burger-button__line"
    :class="isOpen && 'data-burger-button__line_middle_active'" />
    <div
    class="data-burger-button__line"
    :class="isOpen && 'data-burger-button__line_bottom_active'" />
  </div>
</template>

<script>
export default {
  name: 'DataBurgerButton',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleButton() {
      this.$emit('handler', !this.isOpen);
    },
  },
};
</script>

<style lang="scss" scoped>
.data-burger-button {
  cursor: pointer;
  margin-bottom: 3px;
}

.data-burger-button__line {
  position: relative;
  width: 20px;
  height: 2px;
  border-radius: 3px;
  background-color: $primary-gray;
  margin-top: 4px;
  transition: all 0.1s ease-in-out;
  &_top_active {
    transform: rotate(-45deg);
    margin-top: 12px;
  }
  &_middle_active {
    transform: rotate(45deg);
    margin-top: -2px;
  }
  &_bottom_active {
    opacity: 0;
    transform: rotate(45deg);
  }
}
</style>
