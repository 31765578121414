<template>
  <div>
    <div
    v-click-outside="closeMenu"
    class="action-button">
      <button
      class="action-button__button"
      @click="toggleMenu">
        {{ $t('actions') }}
      </button>
      <DataIcon
      name="more_vert"
      :size="20"
      :menu-open="showMenu"
      class="action-button__icon"
      @click.native="toggleMenu" />
      <transition name="fade">
        <div
        v-if="showMenu"
        class="action-button__menu">
          <div v-if="isCompilation">
            <div class="action-button__change-margin">
              <span
              v-if="(isAdmin || isManager) && getSelectedGroups && getSelectedGroups.length"
              class="action-button__action"
              @click="showMarginEditor = true">Изменить наценку</span>
              <transition name="fade">
                <ChangeMargin
                v-if="showMarginEditor"
                :selected-groups="getSelectedGroups"
                :compilation="getCompilationInfo"
                class="action-button__change-margin-editor"
                @close="closeMarginEditor" />
              </transition>
            </div>
            <span
            v-if="(isAdmin || isManager) && getSelectedGroups && getSelectedGroups.length"
            class="action-button__action"
            @click="openResponsibleModal">Назначить ответственного</span>
            <span
            v-if="getFolders && getSelectedGroups && getSelectedGroups.length"
            class="action-button__action"
            @click="openMoveModal">Переместить в папку</span>
            <span
            class="action-button__action"
            @click="openExportModal">{{ $t('export_compilation') }}</span>
            <span
            v-if="getSelectedGroups.length > 0"
            class="action-button__action"
            @click="openSaveCollectionModal">{{ $t('Save to another compilation') }}</span>
            <span
            class="action-button__action"
            @click="openDeleteCollectionModal">{{ $t('delete_compilation') }}</span>
            <span
            class="action-button__action"
            @click="openRenameCollectionModal">Переименовать подборку</span>
            <span
            v-if="getSelectedGroups.length > 0"
            class="action-button__action"
            @click="removeFromCompilation">{{ labelForRemoveFromCompilation }}</span>
          </div>
          <div v-else>
            <span
            v-if="isAdmin"
            class="action-button__action"
            @click="openExportModal">{{ $t('export') }}</span>
            <span
            class="action-button__action"
            @click="openSaveCollectionModal">{{ $t('Save to a compilation') }}</span>
            <span
            class="action-button__action"
            @click="toggleInFavorite(true)">{{ $t('add_to_favorites') }}</span>
            <span
            class="action-button__action"
            @click="toggleInFavorite(false)">{{ $t('remove_from_favorites') }}</span>
            <span
            class="action-button__action"
            @click="openAddBlackListModal">{{ 'Отправить в ЧС' }}</span>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import ChangeMargin from './components/ChangeMargin.vue';

export default {
  name: 'ActionButton',
  components: { ChangeMargin },
  data: () => ({
    showMenu: false,
    showMarginEditor: false,
  }),
  props: {
    isCompilation: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeMenu() {
      this.showMenu = false;
      this.showMarginEditor = false;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    closeMarginEditor() {
      this.showMenu = false;
      this.showMarginEditor = false;
    },
    toggleInFavorite(value) {
      if (value) {
        this.$store.dispatch('compilations/addGroupsToFavorites', this.getSelectedGroups)
          .then(() => {
            this.$store.dispatch('app/setSnackBar', {
              text: this.getSelectedGroups.length > 1 ? this.$t('channels_added_to_favorites') : this.$t('channel_added_to_favorites'),
            });
          })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar', {
              text: this.$t('an_error_occurred_when_changing_the_favorite_groups_reload_the_page_and_try_again'), error: true,
            });
          });
      } else {
        this.$store.dispatch('favorites/removeGroupsFromFavorites', this.getSelectedGroups)
          .then(() => {
            this.$store.dispatch('app/setSnackBar', {
              text: this.getSelectedGroups.length > 1 ? this.$t('channels_removed_from_favorites') : this.$t('channel_removed_from_favorites'),
            });
          })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar', {
              text: this.$t('an_error_occurred_when_changing_the_favorite_groups_reload_the_page_and_try_again'), error: true,
            });
          });
      }
    },
    removeFromCompilation() {
      const groupsLength = this.getSelectedGroups.length;
      this.$store.dispatch('compilations/removeGroupsFromCompilation', { groups: this.getSelectedGroups, id: this.getCompilationInfo.id })
        .then(() => {
          if (groupsLength > 1) {
            this.$store.dispatch('app/setSnackBar', { text: this.$t('groups_have_been_removed_from_the_collection') });
          } else {
            this.$store.dispatch('app/setSnackBar', { text: this.$t('group_have_been_removed_from_the_collection') });
          }
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('an_error_occurred_when_changing_the_selection_reload_the_page_and_try_again'),
              error: true,
            });
        });
    },
    openExportModal() {
      const compilation = this.isCompilation ? { name: this.getCompilationInfo.name, id: this.getCompilationInfo.id } : null;
      const modalParams = { name: 'ExportGroupsModal', props: { compilation } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    openSaveCollectionModal() {
      const modalParams = { name: 'SaveCollectionModal', props: { isCompilation: this.isCompilation } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    openDeleteCollectionModal() {
      const compilation = this.isCompilation ? { name: this.getCompilationInfo.name, id: this.getCompilationInfo.id } : null;
      const modalParams = { name: 'EditCollectionModal', props: { compilation, action: 'delete' } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    openRenameCollectionModal() {
      const compilation = this.isCompilation ? { name: this.getCompilationInfo.name, id: this.getCompilationInfo.id } : null;
      const modalParams = { name: 'EditCollectionModal', props: { compilation, action: 'rename' } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    openAddBlackListModal() {
      const modalParams = { name: 'GroupApproveModal', props: { groups: this.getSelectedGroups, action: 'addToBlackList' } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    openMoveModal() {
      const props = {
        type: 'move', compilationInfo: this.getCompilationInfo, selectedGroups: this.getSelectedGroups, folders: this.getFolders,
      };
      const modalParams = { name: 'CompilationFolderModal', props };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    openResponsibleModal() {
      const props = {
        compilationInfo: this.getCompilationInfo, selectedGroups: this.getSelectedGroups, managers: this.getRealisationsManagers,
      };
      const modalParams = { name: 'SetResponsibleModal', props };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
  },
  computed: {
    getSelectedGroups() {
      if (this.isCompilation) {
        return this.$store.getters['compilations/getCompilationsSelectedList'];
      }
      return this.$store.getters['groups/getSelectedGroups'];
    },
    labelForRemoveFromCompilation() {
      return this.getSelectedGroups.length > 1 ? this.$t('remove_channels_from_collection') : this.$t('remove_channel_from_collection');
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    getCompilationInfo() {
      return this.$store.getters['compilations/getCurrentCompilationInfo'];
    },
    getFavoriteGroups() {
      return this.$store.getters['favorites/getFavoriteGroupsData'];
    },
    getFolders() {
      if (!this.compilationInfo?.folders && this.compilationInfo?.folders.length === 1) return false;
      const folders = this.getCompilationInfo?.folders
        .map((folder) => ({ ...folder, groups: [], name: folder.name === 'root' ? 'Каналы без папки' : folder.name }));
      return folders ? folders.reverse() : false;
    },
    getRealisationsManagers() {
      return this.$store.getters['user/getRealisationsManagers'];
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
    isManager() {
      return this.$store.getters['user/getIsManager'];
    },
  },
};
</script>

<style lang="scss" scoped>
.action-button {
  position: relative;
  cursor: pointer;
}

.action-button__button {
  cursor: pointer;
  width: 140px;
  height: 40px;
  outline: none;
  border: none;
  background: $primary-white;
  border-radius: 20px;
  @include tablet-screen {
    width: 180px;
  }
}

.action-button__menu {
  position: absolute;
  background-color: white;
  left: -8px;
  bottom: 50px;
  z-index: 1;
  width: 200px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
}

.action-button__icon {
  position: absolute;
  right: 15px;
  top: 11px;
}

.action-button__action {
  color: $primary-black;
  padding: 10px 20px;
  display: block;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  transition: .2s;
  &:hover {
    transition: .2s;
    background: rgba($primary-gray, 0.1);
  }
}

.action-button__change-margin {
  position: relative;
  .action-button__change-margin-editor {
    position: absolute;
    top: 0px;
    left: 210px;
  }
}
</style>
