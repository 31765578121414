import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    userToken: null,
    userInfo: null,
    isDemoCatalog: true,
    userTariff: null,
    userInfoIsLoaded: false,
    realisationsManagers: null,
  },
  getters: {
    getUserToken: () => localStorage.getItem('userToken'),
    getUserInfo: (state) => state.userInfo,
    getIsAdmin: (state) => ([2, 4, 6, 7, 8, 9].includes(state.userInfo?.role)), // admin or superuser or manager or realisator
    getIsManager: (state) => state.userInfo?.role === 6,
    getIsRealisator: (state) => state.userInfo?.role === 7,
    getIsRealisatorHead: (state) => state.userInfo?.role === 8,
    getIsPublisher: (state) => state.userInfo?.role === 3,
    getIsEmployerAgency: (state) => state.userInfo?.asEmployerAgency !== null && state.userInfo?.asEmployerAgency !== undefined,
    getIsAgency: (state) => state.userInfo?.asEmployerAgency?.isAgency,
    getIsAdvertiser: (state) => state.userInfo?.asEmployerAgency?.isAgency === false,
    getIsSuperAdmin: (state) => state.userInfo?.role === 4,
    getIsDemoCatalog: (state) => state.isDemoCatalog,
    getUserInfoIsLoaded: (state) => state.userInfoIsLoaded,
    getRealisationsManagers: (state) => state.realisationsManagers,
    getIsSalesManager: (state) => state.userInfo?.role === 9,
  },
  mutations: {
    setUserToken: (state, value) => {
      state.userToken = value;
      localStorage.setItem('userToken', value);
    },
    setUserInfo: (state, value) => { state.userInfo = value; },
    setUserInfoIsLoaded: (state, value) => { state.userInfoIsLoaded = value; },
    setIsDemoCatalog: (state, value) => { state.isDemoCatalog = value; },
    setRealisationsManagers: (state, value) => { state.realisationsManagers = value; },
  },
  actions: {
    // Получение токена через passthrough-token
    getToken({ commit }, token) {
      localStorage.clear();
      return api.post('/auth', { token })
        .then(({ data: { data } }) => {
          commit('setUserToken', data['x-api-token']);
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- getToken --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Получение токена через авторизацию
    login({ commit }, loginData) {
      return api.post('/auth/login',
        {
          login: loginData.login,
          password: loginData.password,
        })
        .then(({ data: { data } }) => {
          commit('setUserToken', data['x-api-token']);
          return Promise.resolve();
        }).catch((error) => {
          console.warn('Error in action -- login --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Получение данных пользователя
    loadUserInfo({ commit, dispatch }) {
      commit('setUserInfoIsLoaded', true);
      return api.get('/user/me')
        .then(({ data: { data } }) => {
          commit('setUserInfo', data);
          // проверка наличия доступа либо как клиента агенства, либо как прямого рекла
          const isClientAccess = (data?.asClientAgency?.currentTariff?.tariff?.isAccessToCatalog && data.isAccessToAgency) || false;
          const isAgencyAccess = data?.asEmployerAgency?.currentTariff?.tariff?.isAccessToCatalog || false;
          const accessRoles = [2, 4, 6, 7, 8, 9];
          const accessToCatalog = isClientAccess || isAgencyAccess || accessRoles.includes(data.role);
          commit('setIsDemoCatalog', !accessToCatalog);
          return Promise.resolve(data);
        })
        .catch((error) => {
          dispatch('logout');
          console.warn('Error in action -- loadUserInfo --; Error -', error);
          return Promise.reject(error);
        })
        .finally(() => { commit('setUserInfoIsLoaded', false); });
    },

    // Разлогинивание
    logout({ commit }) {
      commit('setUserToken', null);
      commit('setUserInfo', null);
      localStorage.removeItem('userToken');
      localStorage.removeItem('userInfo');
      localStorage.clear();
    },

    // Обновление данных пользователя
    updateUserInfo({ commit }, userInfo) {
      commit('app/setAppIsLoading', true, { root: true });
      return api.post('/user/edit', userInfo)
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          console.warn('Error in action -- updateUserInfo --; Error -', error);
          return Promise.reject(error);
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },

    // Регистрация пользователя
    registrationUser({ commit }, registerData) {
      commit('app/setAppIsLoading', true, { root: true });
      return api.post('/user/registration', registerData)
        .then(() => Promise.resolve())
        .catch((error) => {
          console.warn('Error in action -- registrationUser --; Error -', error);
          return Promise.reject(error);
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },

    // Получение любого списока пользователей по роли
    getUserList({ commit }, roleId) {
      return api.get('/user', { params: { role: roleId } })
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- getUserList --; Error -', error);
          return Promise.reject(error);
        });
    },
  },

};
