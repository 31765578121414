<template>
  <div class="requests">
    <div
    v-if="requestsData.length"
    class="requests-wrapper">
      <div
      ref="requestsContent"
      class="requests__content"
      @mousedown.left="mouseDown"
      @mouseup.left="mouseUp"
      @mouseleave="mouseLeave"
      @mousemove="mouseMove"
      @scroll="onScroll">
        <RequestHeaders />
        <div class="requests__elements">
          <RequestElement
          v-for="(request, index) in requestsData"
          :key="request.id"
          :request="request"
          :last-element="requestsData.length > 1 && index === requestsData.length - 1"
          @deleteRequest="deleteRequest" />
        </div>
      </div>
    </div>
    <span
    v-else-if="!isLoading"
    class="requests__text requests__text_light">
      {{ isActiveFilters ? 'По данным параметрам ничего не найдено' : 'Список пуст' }}
    </span>
  </div>
</template>

<script>
import RequestHeaders from './components/RequestHeaders.vue';
import RequestElement from './components/RequestElement/RequestElement.vue';

export default {
  name: 'Requests',
  components: { RequestHeaders, RequestElement },
  props: {
    requestsData: {
      type: Array,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isActiveFilters: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isScroll: false,
    startX: null,
    scrollLeft: null,
    placementParams: null,
  }),
  methods: {
    onScroll() {
      if (this.isLastPage || this.isLoading) {
        return;
      }

      const position = this.$refs.requestsContent.scrollTop;
      const { clientHeight } = this.$refs.requestsContent;

      const endScroll = Math.ceil(position + clientHeight) >= Math.ceil(this.$refs.requestsContent.scrollHeight);
      if (endScroll) {
        this.$emit('loadRequests', { reset: false });
      }
    },
    mouseDown(e) {
      this.isScroll = true;
      this.startX = e.pageX - this.$refs.requestsContent.offsetLeft;
      this.scrollLeft = this.$refs.requestsContent.scrollLeft;
    },
    mouseLeave() {
      this.isScroll = false;
    },
    mouseUp() {
      this.isScroll = false;
    },
    mouseMove(e) {
      const contentWidth = this.$refs.requestsContent.clientWidth;
      if (!this.isScroll || this.showGroupMenu || contentWidth > 1650) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.requestsContent.offsetLeft;
      const walk = (x - this.startX) * 1; // scroll-fast
      this.$refs.requestsContent.scrollLeft = this.scrollLeft - walk;
    },
    deleteRequest(id) {
      this.$store.dispatch('placements/deletePlacement', id)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Запрос удален' });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'При отправке запроса произошла ошибка. Попробуйте позже', error: true });
        });
    },
  },
  computed: {
    isLastPage() {
      return this.$store.getters['placements/getIsLastPage'];
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
  },
};
</script>

<style lang="scss" scoped>
.requests {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: auto;
  margin: 20px;
}

.requests-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: auto;
}

.requests__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  flex: 1;
  &::-webkit-scrollbar { width: 0; }
}

.requests__elements {
  min-width: 100%;
  @include between-elements {
    margin-bottom: 15px;
  }
}

.requests__text {
  font-size: 14px;
  color: #575757;
  &_light {
    color: $primary-lightgray;
  }
}
</style>
