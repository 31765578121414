<template>
  <div class="login-page">
    <div class="login-page__header">
      <img
      src="@/assets/logos/social-data-logo.svg"
      class="login-page__logo">
      <transition
      name="fade"
      mode="out-in">
        <div
        v-if="selectedStep !== null"
        class="login-page__back"
        @click="selectedStep = null">
          <DataIcon
          class="login-page__back-text"
          :size="22"
          name="chevron_left" />
          <span class="login-page__back-text">Вернуться назад</span>
        </div>
      </transition>
    </div>
    <transition
    name="fade"
    mode="out-in">
      <div
      v-if="!selectedStep"
      :key="selectedStep"
      class="login-page__select-block">
        <span class="login-page__title">Личный кабинет</span>
        <div class="login-page__buttons">
          <DataButton
          text="Войти"
          :width="320"
          :height="50"
          :font-size="16"
          @handler="selectedStep = 'login'" />
          <DataButton
          text="Регистрация"
          type="outlined"
          :width="320"
          :height="50"
          :font-size="16"
          @handler="selectedStep = 'registration'" />
        </div>
      </div>
      <LoginForm
      v-if="selectedStep === 'login'"
      :key="selectedStep"
      @setSelectedStep="setSelectedStep" />
      <RegisterForm
      v-if="selectedStep === 'registration'"
      :key="selectedStep"
      @setSelectedStep="setSelectedStep" />
    </transition>
    <LoginFooter />
  </div>
</template>

<script>
import LoginFooter from './components/LoginFooter.vue';
import LoginForm from './components/LoginForm.vue';
import RegisterForm from './components/RegisterForm.vue';

export default {
  name: 'Login',
  components: { LoginFooter, LoginForm, RegisterForm },
  data: () => ({
    selectedStep: null,
    prevStep: null,
  }),
  methods: {
    setSelectedStep(value) {
      this.prevStep = this.selectedStep;
      this.selectedStep = value;
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters['app/getAppIsLoading'] || this.$store.getters['app/getAppIsLoading'];
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.login-page__back-text {
  font-size: 16px;
  color: $primary-gray;
  line-height: 22px;
}

.login-page__header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-page__back {
  margin-top: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @include between-elements {
    margin-right: 5px;
  }
  @include desktop-screen {
    margin-top: 0px;
    position: absolute;
    top: 95px;
    left: -300px;
  }
}

.login-page__logo {
  width: 250px;
  padding-top: 40px;
}

.login-page__title {
  font-size: 26px;
  font-weight: 600;
  color: #0E092A;
}

.login-page__select-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  width: 300px;
  @include between-elements {
    margin-bottom: 30px;
  }
}

.login-page__buttons {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 15px;
  }
}

</style>
