<template>
  <div class="account-balance">
    <!-- MAIN -->
    <transition
    :key="isLoadingAccount"
    name="fade">
      <div
      v-if="!isLoadingAccount"
      class="account-balance__main"
      @click="openHistory">
        <img
        :src="require('../assets/wallet.svg')"
        class="account-balance__icon">
        <span
        v-if="getBalance"
        class="account-balance__balance">{{ getBalance }}</span>
      </div>
    </transition>

    <!-- History -->
    <transition
    :key="showHistory"
    name="fade">
      <div
      v-if="showHistory"
      v-click-outside="closeHistory"
      class="account-balance__history">
        <!--  Заголовок -->
        <div class="account-balance__history-title">
          <span class="account-balance__history-title-text">История операций</span>
          <DataIcon
          name="close"
          :size="22"
          class="account-balance__close"
          @click.native="closeHistory" />
        </div>

        <!-- Фильтры -->
        <div class="account-balance__history-filters">
          <div class="account-balance__history-filter">
            <span class="account-balance__history-filter-text">Тип операции</span>
            <DataSelect
            v-model="selectedType"
            placeholder="Выберите тип"
            :options="types"
            @input="loadHistory" />
          </div>
          <div class="account-balance__history-filter">
            <span class="account-balance__history-filter-text">Период</span>
            <div class="account-balance__date-inputs">
              <DataDatePicker
              v-model="dateAt"
              @input="loadHistory" />
              <DataDatePicker
              v-model="dateTo"
              @input="loadHistory" />
            </div>
          </div>
          <transition
          :key="isActiveFilters"
          name="fade">
            <DataIcon
            v-if="isActiveFilters"
            name="close"
            class="account-balance__remove-filters"
            :size="22"
            @click.native="clearFilters" />
          </transition>
        </div>

        <!-- История -->
        <div
        v-if="history.length"
        class="account-balance__history-table">
          <!-- Headers -->
          <div class="account-balance__history-headers">
            <div
            v-for="header in historyHeaders"
            :key="header.name"
            :style="{ width: `${header.width}px` }"
            class="account-balance__history-header">
              {{ header.name }}
            </div>
          </div>
          <!-- Elements -->
          <div class="account-balance__history-elements">
            <div
            v-for="item in history"
            :key="item.id"
            class="account-balance__history-element">
              <!-- Дата -->
              <div
              class="account-balance__history-date"
              style="width: 180px">
                {{ item.date }}
              </div>
              <!-- Тип (списание/пополнение) -->
              <div style="width: 180px">
                <div
                class="account-balance__history-type"
                :class="item.type === 'expense' && 'account-balance__history-type_expense'">
                  {{ item.textType }}
                </div>
              </div>
              <!-- Сумма и описание -->
              <div
              class="account-balance__history-amount"
              style="width: 200px">
                {{ item.amount }}
              </div>
            </div>
          </div>
        </div>

        <!-- Empty -->
        <div
        v-if="!history.length"
        class="account-balance__empty">
          {{ loadingHistory ? 'Загрузка...' : 'Операции не найдены' }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AccountBalance',
  data: () => ({
    isLoadingAccount: false,
    balanceInfo: null,
    showHistory: false,
    agencyId: null,
    dateAt: null,
    dateTo: null,
    historyHeaders: [
      { name: 'Дата', value: 'date', width: 180 },
      { name: 'Тип транзакции', value: 'type', width: 180 },
      { name: 'Сумма', value: 'value', width: 200 },
    ],
    loadingHistory: false,
    history: [],
    types: [{ name: 'Пополнение', value: 2 }, { name: 'Списание', value: 1 }],
    selectedType: null,
  }),
  created() {
    this.loadAccountInfo();
  },
  methods: {
    loadAccountInfo() {
      this.isLoadingAccount = true;
      this.agencyId = this.getUserInfo?.asEmployerAgency?.id;
      if (!this.agencyId) return;
      this.$store.dispatch('clients/getCurrentAgency', this.agencyId)
        .then((data) => {
          this.balanceInfo = data.account;
        })
        .finally(() => {
          this.isLoadingAccount = false;
        });
    },
    closeHistory() {
      this.showHistory = false;
      this.history = [];
      this.selectedType = null;
      this.dateAt = null;
      this.dateTo = null;
    },
    openHistory() {
      this.showHistory = true;
      this.loadHistory();
    },
    loadHistory() {
      this.loadingHistory = true;
      const payload = {
        agencyId: this.agencyId,
        createdAtFrom: this.dateAt ? `${this.dateAt} 00:00` : null,
        createdAtTo: this.dateTo ? `${this.dateTo} 23:59` : null,
        type: this.selectedType ? this.selectedType.value : null,
      };
      this.$store.dispatch('clients/getBalanceHistory', payload)
        .then((data) => {
          this.history = this.mapHistoryData(data);
        })
        .finally(() => {
          this.loadingHistory = false;
        });
    },
    clearFilters() {
      this.selectedType = null;
      this.dateAt = null;
      this.dateTo = null;
      this.loadHistory();
    },
    mapHistoryData(data) {
      return data.map((item) => {
        const type = item.type === 1 || String(item.amount).includes('-') ? 'expense' : 'income';
        const textType = type === 'expense' ? 'Списание' : 'Пополнение';
        const description = item.description || '';
        const amount = type === 'expense' ? String(item.amount).replace(/-/g, '') : String(item.amount);
        return {
          ...item,
          amount: `${this.setNumberSpaces(amount)} ${this.getCurrencySymbol} ${description}`,
          date: this.moment(item.createdAt.date).format('lll'),
          type,
          textType,
        };
      });
    },
  },
  computed: {
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
    getBalance() {
      if (this.isLoadingAccount || !this.balanceInfo) return null;
      return this.balanceInfo.balance === null || this.balanceInfo.balance === undefined
        ? null
        : `${this.setNumberSpaces(this.balanceInfo.balance)} ${this.getCurrencySymbol}`;
    },
    getCurrencySymbol() {
      return this.$store.getters['app/getCurrencySymbol'];
    },
    isActiveFilters() {
      return !!this.selectedType || !!this.dateAt || !!this.dateTo;
    },
  },
};
</script>

<style lang="scss" scoped>
.account-balance {
  position: relative;

  .account-balance__main {
    display: flex;
    align-items: center;
    padding-right: 20px;
    @include between-elements {
      margin-right: 5px;
    }
  }

  .account-balance__icon {
    min-width: 20px;
    width: 20px;
  }

  .account-balance__balance {
    font-size: 14px;
    color: $primary-blue;
    font-weight: 600;
    cursor: pointer;
  }

  .account-balance__history {
    width: 630px;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0px 0px 20px 0px rgb(34 60 80 / 20%);
    background: white;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    top: 40px;
    left: -140px;
  }

  .account-balance__history-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .account-balance__close {
    color: $primary-gray;
    cursor: pointer;
  }

  .account-balance__history-title-text {
    font-size: 16px;
    color: $primary-gray;
    font-weight: 600;
  }

  .account-balance__history-table {
    width: 100%;
    margin-top: 30px;
  }

  .account-balance__history-headers {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #d8f2fa;
    @include between-elements {
      margin-right: 10px;
    }
  }

  .account-balance__history-header {
    font-size: 13px;
    color: $primary-lightgray;
  }

  .account-balance__history-date {
    font-size: 14px;
    color: $primary-lightgray;
  }

  .account-balance__history-amount {
    font-size: 14px;
    color: $primary-gray;
    flex-wrap: wrap;
  }

  .account-balance__history-elements {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 424px;
    overflow: auto;
  }

  .account-balance__history-element {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid #d8f2fa;
    @include between-elements {
      margin-right: 10px;
    }
  }

  .account-balance__history-type {
    background: $primary-green;
    border-radius: 8px;
    padding: 7px 10px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    &_expense {
      background: rgb(70, 198, 248);
    }
  }

  .account-balance__history-filters {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
    @include between-elements {
      margin-right: 10px;
    }
  }

  .account-balance__history-filter {
    display: flex;
    min-width: 200px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @include between-elements {
      margin-bottom: 10px;
    }
  }

  .account-balance__history-filter-text {
    color: $primary-gray;
    font-size: 13px;
  }

  .account-balance__date-inputs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include between-elements {
      margin-right: 10px;
    }
  }

  .account-balance__remove-filters {
    color: $primary-gray;
    margin-left: 10px;
    cursor: pointer;
    margin-top: 22px;
  }

  .account-balance__empty {
    width: 100%;
    padding: 50px 0px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-lightgray;
    font-size: 13px;
  }
}

</style>
