<template>
  <textarea
  class="data-textarea"
  :class="{
    'data-textarea_disabled': disabled,
    'data-textarea_error': error,
    'data-textarea_no-edit': noEdit,
    'data-textarea_theme-white': theme === 'white'
  }"
  :style="{'height': `${height}px`, 'font-size': `${fontSize}px`}"
  :value="value"
  :disabled="disabled || noEdit"
  :placeholder="placeholder"
  @input="$emit('input', $event.target.value)" />
</template>

<script>
export default {
  name: 'DataTextarea',
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 100,
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
    },
    fontSize: {
      type: Number,
      default: 16,
    },
  },
};
</script>

<style lang="scss" scoped>
.data-textarea {
  width: 100%;
  background: #fafeff;
  border-radius: 8px;
  border: 1px solid #d8f2fa;
  height: 48px;
  outline: none;
  resize: none;
  font-size: 16px;
  padding: 15px 20px;
  &::placeholder {
    color: #7b7b7b;
    font-weight: 400;
  }
    &_theme-white {
    background: white;
  }
  &_error {
    border: 1px solid $primary-red;
  }
  &_disabled {
    background: #f5f5f5;
    border: 1px solid #dbdbdb;
    &::placeholder {
      color: #c0c0c0;
      font-weight: 400;
    }
  }
  &_no-edit {
    background: #f7f7f7;
    border: 1px solid rgba(black, 0.03);
  }
}
</style>
