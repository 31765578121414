<template>
  <div class="my-groups-screen">
    <div class="my-groups-screen__wrapper">
      <span class="my-groups-screen__title">Мои каналы</span>
      <MyGroupsTabs @loadMyGroups="loadMyGroups" />
      <MyGroups :groups="getMyGroups" />
    </div>
  </div>
</template>

<script>
import MyGroups from './components/MyGroups.vue';
import MyGroupsTabs from './components/MyGroupsTabs.vue';

export default {
  name: 'MyGroupsScreen',
  components: { MyGroups, MyGroupsTabs },
  created() {
    this.loadMyGroups({ reset: true });
  },
  methods: {
    loadMyGroups(params) {
      const userId = this.$store.getters['user/getUserInfo']?.id;
      this.$store.dispatch('myGroups/loadMyGroups', { reset: params.reset, userId });
    },
  },
  computed: {
    getMyGroups() {
      return this.$store.getters['myGroups/getMyGroupsData'];
    },
    isLoadingGroups() {
      return this.$store.getters['myGroups/getLoadingGroups'];
    },
  },

};
</script>

<style lang="scss" scoped>
.my-groups-screen__title {
  font-size: 18px;
  font-weight: 600;
  margin: 0px 20px;
}

</style>
