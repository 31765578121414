<template>
  <div class="profile">
    <span class="profile__text profile__text_title">Настройки профиля</span>
    <div class="profile__content">
      <div class="profile__avatar-block">
        <img
        src="./assets/UserCircle.svg"
        class="profile__avatar">
      </div>
      <div class="profile__settings-block">
        <div class="profile__settings-inputs">
          <div
          v-for="item in profileBase"
          :key="item.name"
          class="profile__input-container">
            <span class="profile__text profile__text_subtitle">{{ item.name }}</span>
            <DataInput
            v-model="item.value"
            :placeholder="item.placeholder"
            :disabled="!item.editable" />
          </div>
        </div>
        <div class="profile__buttons-block">
          <DataButton
          text="Редактировать"
          @handler="saveChanges" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'ProfileScreen',
  data: () => ({
    profileBase: [
      {
        name: 'Имя', placeholder: 'Введите имя', field: 'name', value: '', editable: true,
      },
      {
        name: 'Название компании', placeholder: 'Название компании', field: 'companyName', value: '', editable: false,
      },
      {
        name: 'Телеграм', placeholder: 'Телеграм', field: 'telegram', value: '', editable: true,
      },
      {
        name: 'Почта', placeholder: 'Почта', field: 'email', value: '', editable: false,
      },
      {
        name: 'Телефон', placeholder: 'Телефон', field: 'phoneNumber', value: '', editable: true,
      },
    ],
    profileMap: [],
  }),
  created() {
    if (this.getUserInfo) this.initProfileData();
  },
  methods: {
    initProfileData() {
      this.profileBase = this.profileBase.map((item) => {
        if (item.field === 'companyName') {
          const agencyName = this.getUserInfo.asEmployerAgency?.name || null;
          item.value = agencyName || this.getUserInfo[item.field] || '';
          return item;
        }
        item.value = this.getUserInfo[item.field] || '';
        return item;
      });
      const agencyName = this.getUserInfo.asEmployerAgency?.name || null;
      this.profileBase.companyName = agencyName;
      this.profileMap = cloneDeep(this.profileBase);
    },
    saveChanges() {
      const userData = {};
      this.profileBase.forEach((item) => {
        userData[item.field] = item.value;
      });
      userData.id = this.getUserInfo.id;
      this.$store.dispatch('user/updateUserInfo', userData)
        .then(() => {
          this.profileMap = cloneDeep(this.profileBase);
          this.$store.dispatch('app/setSnackBar', { text: 'Успешно. Данные обновлены' });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка при обновлении данных', error: true });
        });
    },
  },
  computed: {
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
  },
  watch: {
    getUserInfo(newValue) {
      if (newValue) {
        this.initProfileData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  width: 100%;
  height: 100%;
}

.profile__text {
  font-size: 16px;
  &_title {
    font-size: 36px;
    font-weight: 700;
    margin: 0px 20px;
  }
  &_subtitle {
    color: $primary-lightgray;
    font-size: 14px;
  }
}

.profile__content {
  margin: 40px 20px;
  @include between-elements {
    margin-bottom: 30px;
  }
  @include tablet-screen {
    display: grid;
    grid-template-columns: 2fr 4fr;
    grid-gap: 30px;
    @include between-elements {
      margin-bottom: 0px;
    }
  }
}

.profile__avatar-block {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 20px;
  @include between-elements {
    margin-bottom: 40px;
  }
}

.profile__settings-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  border-radius: 20px;
  background: white;
  @include between-elements {
    margin-bottom: 32px;
  }
}

.profile__settings-inputs {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(266px, 1fr));
  grid-gap: 30px;
}

.profile__input-container {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.profile__input {
  min-width: 266px;
  height: 50px;
  background: #F8F8F8;
  border-radius: 8px;
  border: none;
  padding: 0px 20px;
  font-size: 18px;
  transition: 0.2s;
  &:focus {
    border: none;
    outline: none;
    box-shadow: 0px 2px 6px -3px rgba(0, 174, 232, 0.35);
  }
}

.profile__buttons-block {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  @include between-elements {
    margin-bottom: 20px;
  }
  @include desktop-screen {
    flex-direction: row;
    @include between-elements {
      margin-bottom: 0px;
      margin-right: 20px;
    }
  }
}

.profile__button {
  height: 50px;
  border: 2px solid $primary-blue;
  border-radius: 33px;
  background: white;
  padding: 0px 20px;
  color: $primary-blue;
  @include tablet-screen {
    min-width: 266px;
    max-width: 266px;
  }
  cursor: pointer;
  &_secondary {
    border: none;
    min-width: 180px;
  }
}
</style>
