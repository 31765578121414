<template>
  <div class="clients-access-element">
    <div class="clients-access-element__column clients-access-element__column_name">
      <div class="clients-access-element__name-text">
        <span
        v-tooltip.bottom="clientData.companyName"
        class="clients-access-element__text clients-access-element__text_name">
          {{ clientData.companyName }}
        </span>
        <span class="clients-access-element__text clients-access-element__text_light">
          {{ clientData.email }}
        </span>
      </div>
    </div>

    <!-- ФИО -->
    <div
    class="clients-access-element__column"
    style="width: 200px">
      <span class="clients-access-element__text">
        {{ clientData.fio }}
      </span>
    </div>

    <!-- Контакты -->
    <div
    v-tooltip="'Нажмите на контакт, чтобы скопировать'"
    class="clients-access-element__contacts"
    style="width: 200px">
      <span
      v-for="(contact, index) in getContactLabel"
      :key="index"
      class="clients-access-element__text clients-access-element__text_light pointer"
      @click="copyContact(contact);">
        {{ contact }}
      </span>
    </div>

    <!-- Агентство -->
    <div
    class="clients-access-element__column"
    style="width: 100px">
      <span class="clients-access-element__text">
        {{ getIsAgency() }}
      </span>
    </div>

    <!-- Что продвигают -->
    <div
    class="clients-access-element__column clients-access-element__column_brief"
    style="width: 300px">
      <span class="clients-access-element__text clients-access-element__text_light clients-access-element__text_brief">
        {{ clientData.brief }}
      </span>
    </div>

    <!-- Дата заявки -->
    <div
    class="clients-access-element__column"
    style="width: 130px">
      <span class="clients-access-element__text clients-access-element__text_light clients-access-element__text_brief">
        {{ date() }}
      </span>
    </div>

    <!-- Доступ -->
    <div
    v-if="clietsAccessType === 'wait'"
    class="clients-access-element__column">
      <div class="clients-access-element__actions">
        <span
        class="clients-access-element__text clients-access-element__text_blue pointer"
        @click="accessClient(true)">
          Открыть
        </span>
        <span
        class="clients-access-element__text clients-access-element__text_red pointer"
        @click="accessClient(false)">
          Отклонить
        </span>
      </div>
    </div>
    <div
    v-else
    class="clients-access-element__column">
      <span
      class="clients-access-element__text">
        —
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ClientsAccessElement',
  props: {
    // Объект с клиентом/запросом
    client: {
      type: Object,
      default: () => {},
    },
    // Тип запроса (обработанный, активный)
    clietsAccessType: {
      type: String,
      default: 'wait',
    },
  },
  data: () => ({
    showMenu: false,
    clientData: {},
  }),
  created() {
    this.initData();
  },
  methods: {
    accessClient(value) {
      if (value) {
        const expiredDate = this.moment(new Date()).add(30, 'd').format('YYYY-MM-DD');
        const params = {
          requestId: this.client.id,
        };
        this.$store.dispatch('tariffs/approveDemoTariffRequest', params)
          .then(() => {
            this.$store.dispatch('app/setSnackBar', { text: `Тестовый доступ для клиента ${this.clientData.companyName} будет подключен до ${expiredDate}` });
          })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка. Попробуйте повторить позже', error: true });
          });
      } else {
        this.$store.dispatch('tariffs/rejectDemoTariffRequest', this.client.id)
          .then(() => {
            this.$store.dispatch('app/setSnackBar', { text: 'Заявка отклонена' });
          })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка. Попробуйте повторить позже', error: true });
          });
      }
    },
    initData() {
      this.clientData.companyName = this.client.companyName || 'Компания';
      this.clientData.email = this.client.user?.email || '—';
      this.clientData.fio = this.client.name || this.client.user?.name || '—';
      this.clientData.contact = {
        main: this.client.contacts ? this.client.contacts : '—',
        telegram: this.client?.user?.telegram ? this.client?.user?.telegram : '—',
        phone: this.client?.user?.phoneNumber ? this.client?.user?.phoneNumber : '—',
        email: this.client?.user?.email ? this.client?.user?.email : '—',
      };
      this.clientData.brief = this.client.description || '—';
    },
    copyContact(value) {
      const isCopy = this.copyToBuffer(value);
      if (isCopy) {
        this.$store.dispatch('app/setSnackBar', { text: 'Контакт скопирован' });
      }
    },
    getIsAgency() {
      const isAgency = this.client.user.asEmployerAgency;
      if (isAgency) {
        return isAgency.isAgency ? 'Да' : 'Нет';
      }
      return 'Нет';
    },
    date() {
      return this.client.createdAt?.date ? this.moment(this.client.createdAt?.date).format('LL') : '—';
    },
  },
  computed: {
    getContactLabel() {
      return Object.values(this.clientData.contact).filter((item) => item !== '—');
    },
  },
};
</script>

<style lang="scss" scoped>
.clients-access-element {
  display: flex;
  align-items: center;
  min-width: 100%;
  background: white;
  padding: 15px 0px;
  border-bottom: 1px solid $primary-group-border;
  &:hover {
    background: #f4fbfe;
    .clients-access-element__column_name {
      background: #f4fbfe;
    }
  }
}

.clients-access-element__column {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  height: 40px;
  &_name {
    flex-direction: row;
    justify-content: flex-start;
    position: sticky;
    left: 0px;
    z-index: 1;
    background: white;
    padding-left: 20px;
  }
  &_brief {
    justify-content: flex-start;
    padding: 0px 20px 0px 40px;
  };
}

.clients-access-element__contacts {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.clients-access-element__name-text {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.clients-access-element__text {
  font-size: 14px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  &_name {
    font-size: 16px;
    color: $primary-blue;
  }
  &_light {
    color: $primary-lightgray;
    font-size: 12px;
  }
  &_green {
    color: $primary-green;
  }
  &_blue {
    color: $primary-blue;
  }
  &_red {
    color: $primary-red;
  }
  &_brief {
    white-space: normal;
    text-align: center;
    text-align: left;
    -webkit-line-clamp: 4;
  }
}

.clients-access-element__actions {
  display: flex;
  justify-content: center;
  @include between-elements {
    margin-right: 20px;
  }
}
</style>
