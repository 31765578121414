var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeSidebar),expression:"closeSidebar"}],staticClass:"sidebar",class:_vm.getOpenSidebar && 'sidebar_open'},[_c('div',{staticClass:"sidebar__menu"},[_c('div',{staticClass:"sidebar__pages"},[_c('div',{staticClass:"sidebar__page sidebar__page_toggler",class:[_vm.getOpenSidebar && 'sidebar__page_open', _vm.getOpenSidebar && 'sidebar__page_active'],on:{"click":_vm.toggleSidebar}},[_c('div',{staticClass:"sidebar__mini-page sidebar__mini-page_toggler"},[_c('img',{staticClass:"sidebar__icon",attrs:{"src":require(`./assets/${_vm.getOpenSidebar? `arrow-left-active` : 'menu'}.svg`)}})]),(_vm.getOpenSidebar)?_c('span',{staticClass:"sidebar__page-title"},[_vm._v(" Свернуть ")]):_vm._e()]),_vm._l((_vm.getLinksData),function(page){return _c('a',{key:page.id,staticClass:"sidebar__page-wrap",attrs:{"href":page.href}},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(!page.link && 'Скоро'),expression:"!page.link && 'Скоро'",modifiers:{"right":true}}],staticClass:"sidebar__page",class:{'sidebar__page_disabled': !page.link, 'sidebar__page_open': _vm.getOpenSidebar },on:{"click":function($event){$event.preventDefault();return _vm.goTo(page)}}},[_c('div',{staticClass:"sidebar__mini-page"},[_c('img',{staticClass:"sidebar__icon",class:!page.link && 'sidebar__icon_disabled',attrs:{"src":require(`./assets/${page.link.includes(_vm.currentPage) ? `${page.icon}-active` : page.icon}.svg`)}}),_c('span',{staticClass:"sidebar__page-minititle",class:{
              'sidebar__page-minititle_active': page.link.includes(_vm.currentPage),
              'sidebar__page-minititle_hide': _vm.getOpenSidebar,
            }},[_vm._v(" "+_vm._s(page.name)+" ")])]),(_vm.getOpenSidebar)?_c('span',{staticClass:"sidebar__page-title",class:{
            'sidebar__page-title_active': page.link.includes(_vm.currentPage),
            'sidebar__page-title_disabled': !page.link,
          }},[_vm._v(" "+_vm._s(page.name)+" ")]):_vm._e()])])})],2)]),_c('div',{staticClass:"sidebar__helps"},_vm._l((_vm.getHelpsData),function(page){return _c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(!page.link && 'Скоро'),expression:"!page.link && 'Скоро'"}],key:page.id,staticClass:"sidebar__page",class:{'sidebar__page_disabled': !page.link, 'sidebar__page_open': _vm.getOpenSidebar },on:{"click":function($event){$event.preventDefault();return _vm.goTo(page)}}},[_c('div',{staticClass:"sidebar__mini-page"},[_c('img',{staticClass:"sidebar__icon",class:!page.link && 'sidebar__icon_disabled',attrs:{"src":require(`./assets/${page.link.includes(_vm.currentPage) ? `${page.icon}-active` : page.icon}.svg`)}}),_c('span',{staticClass:"sidebar__page-minititle",class:{
          'sidebar__page-minititle_active': page.link.includes(_vm.currentPage),
          'sidebar__page-minititle_hide': _vm.getOpenSidebar,
        }},[_vm._v(" "+_vm._s(page.name)+" ")])]),(_vm.getOpenSidebar)?_c('span',{staticClass:"sidebar__page-title",class:{
        'sidebar__page-title_active': page.link.includes(_vm.currentPage),
        'sidebar__page-title_disabled': !page.link,
      }},[_vm._v(" "+_vm._s(page.name)+" ")]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }