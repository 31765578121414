import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    searchPosts: [],
    loadParams: {
      pageSize: 5,
      page: 1,
    },
    totalCounts: 0,
    isInitial: true,
  },
  getters: {
    getSearchPosts: (state) => state.searchPosts,
    getTotalCounts: (state) => state.totalCounts,
    getCurrentPage: (state) => state.loadParams.page,
    getIsLastPage: (state) => state.loadParams.page === +state.totalPageCounts + 1,
    getIsInitial: (state) => state.isInitial,
  },
  mutations: {
    setSearchPosts: (state, value) => { state.searchPosts = value; },
    setTotalCounts: (state, value) => { state.totalCounts = value; },
    setLoadPage: (state, value) => { state.loadParams.page = value; },
    setIsInitial: (state, value) => { state.isInitial = value; },
  },
  actions: {
    // Получение списка постов
    loadSearchPosts({ state, commit, dispatch }, payload) {
      return api.get('/keyword-post', { params: Object.assign(payload.params, state.loadParams) })
        .then(({ data: { data } }) => {
          const posts = data.items ? data.items.map((item) => ({
            ...item,
            social: payload.params.social,
          })) : [];
          const totalCounts = data.total || 0;
          commit('setSearchPosts', posts);
          commit('setTotalCounts', totalCounts);
          return Promise.resolve(data);
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'loadSearchPosts' }, { root: true });
          console.warn('Error in action -- loadSearchPosts --; Error -', error);
          return Promise.reject(error);
        });
    },
  },
};
