<template>
  <div class="profile">
    <div class="profile__content">
      <!-- Avatar -->
      <div
      class="profile__avatar flex-center"
      @click="goToProfile">
        <span
        v-if="getInitialsForAvatar"
        class="profile__avatar-text">{{
          getInitialsForAvatar
        }}</span>
      </div>
      <img
      src="@/assets/svg/icons/logout.svg"
      class="profile__logout"
      @click="showLogoutModal = true">
    </div>

    <DataModal
    :show-modal="showLogoutModal"
    @close="showLogoutModal = false">
      <template #modal-content>
        <div class="profile__confirm-modal">
          <span>Вы точно хотите выйти?</span>
          <div class="profile__buttons">
            <DataButton
            text="Выйти"
            @handler="logout" />
            <DataButton
            text="Назад"
            type="outlined"
            @handler="showLogoutModal = false" />
          </div>
        </div>
      </template>
    </DataModal>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  data: () => ({
    showLogoutModal: false,
  }),
  methods: {
    logout() {
      // Админа при разлогинивании отправлять на login, остальных на лендос
      this.$store.dispatch('user/logout');
      this.$router.push({ name: 'login' });
    },
    goToProfile() {
      if (this.$route.name === 'profile') return;
      this.$router.push({ name: 'profile' });
    },
  },
  computed: {
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
    getInitialsForAvatar() {
      return this.getUserInfo?.avatar
        ? null
        : this.getUserInfo?.email.substr(0, 2).toUpperCase();
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  align-items: center;
}

.profile__content {
  display: flex;
  align-items: center;
  @include between-elements {
    margin-right: 15px;
  }
}

.profile__avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: $primary-blue;
  cursor: pointer;
}

.profile__avatar-text {
  color: $primary-white;
  font-size: 10px;
}

.profile__user {
  display: none;
  @include desktop-screen {
    display: block;
    cursor: default;
  }
}

.profile__name {
  font-size: 13px;
  color: $primary-gray;
  line-height: 24px;
  width: 100%;
}

.profile__logout {
  width: 20px;
  cursor: pointer;
}

.profile__confirm-modal {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  @include between-elements {
    margin-bottom: 40px;
  }
}

.profile__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include between-elements {
    margin-right: 10px;
  }
}
</style>
