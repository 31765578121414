<template>
  <div
  ref="content"
  class="clients-statistic-list"
  @scroll="onScroll">
    <div
    v-if="list.length || initLoading"
    class="clients-statistic-list__content"
    @scroll="onScroll"
    @mousedown.left="mouseDown"
    @mouseup.left="mouseUp"
    @mouseleave="mouseLeave"
    @mousemove="mouseMove">
      <div class="clients-statistic-list__list">
        <ClientsStatisticHeaders />
        <ClientsStatisticElement
        v-for="item in list"
        :key="item.id"
        :client="item"
        @selectCurrentClient="currentClientStatistic = item" />
        <div
        v-if="isLoading"
        class="clients-statistic-list__content">
          <ClientsStatisticElement
          v-for="item in initLoading ? 100 : 5"
          :key="item"
          is-skeleton />
        </div>
      </div>
    </div>
    <div
    v-else-if="!isLoading"
    class="clients-statistic-list__empty">
      <span>Клиенты не найдены</span>
    </div>

    <DataModal
    :show-modal="!!currentClientStatistic"
    @close="currentClientStatistic = null">
      <template #modal-content>
        <CurrentClientStatisticModal
        :client="currentClientStatistic"
        :filters="filters"
        @close="currentClientStatistic = null" />
      </template>
    </DataModal>
  </div>
</template>

<script>
import ClientsStatisticHeaders from './ClientsStatisticHeaders.vue';
import ClientsStatisticElement from './ClientsStatisticElement.vue';
import CurrentClientStatisticModal from './CurrentClientStatisticModal.vue';

export default {
  name: 'ClientsStatisticList',
  components: { ClientsStatisticHeaders, ClientsStatisticElement, CurrentClientStatisticModal },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    initLoading: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isScroll: false,
    startX: null,
    scrollLeft: null,
    currentClientStatistic: null,
  }),
  methods: {
    mouseDown(e) {
      this.isScroll = true;
      this.startX = e.pageX - this.$refs.content.offsetLeft;
      this.scrollLeft = this.$refs.content.scrollLeft;
    },
    mouseLeave() {
      this.isScroll = false;
    },
    mouseUp() {
      this.isScroll = false;
    },
    mouseMove(e) {
      if (!this.isScroll) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.content.offsetLeft;
      const walk = (x - this.startX) * 1; // scroll-fast
      this.$refs.content.scrollLeft = this.scrollLeft - walk;
    },
    onScroll() {
      const position = this.$refs.content.scrollTop;
      const { clientHeight } = this.$refs.content;
      const endScroll = Math.ceil(position + clientHeight) >= Math.ceil(this.$refs.content.scrollHeight);
      if (endScroll && !this.isLoading) {
        this.$emit('getStatisticData');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.clients-statistic-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  justify-content: flex-start;
  background: white;
  position: relative;

  .clients-statistic-list__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 100%;
  }

  .clients-statistic-list__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .clients-statistic-list__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    flex: 1;
    border-radius: 12px;
  }

  .clients-statistic-list__loading {
    position: absolute;
    top: 0px;
    left: 0px;
    min-height: 100%;
    width: 100%;
    flex: 1;
    background: red;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
