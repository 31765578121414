<template>
  <div class="placement-result">
    <div class="create-order__title">
      Запрос успешно отправлен!
    </div>
    <span class="placement-result__text">
      Передаем в работу нашей команде. Он будет готов в течение 8 рабочих часов.
    </span>
    <div class="placement-result__image" />
    <span
    class="placement-result__text"
    style="max-width: 340px">
      А пока можете заварить чашечку вкусного кофе)
    </span>
    <DataButton
    text="Вернуться к каталогу"
    width="full"
    height="50"
    class="placement-result__button"
    @handler="$emit('close')" />
  </div>
</template>

<script>
export default {
  name: 'PlacementResult',
};
</script>

<style lang="scss" scoped>
.placement-result {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  max-width: 500px;
}

.placement-result__image {
  width: 353px;
  height: 222px;
  background-size: contain;
  background-image: url('~@/assets/images/coffepic.png');
  margin: 60px 0px 30px;
}

.placement-result__text {
  font-size: 16px;
  color: #4F4F4F;
  font-weight: 400;
}

.placement-result__button {
  width: 100%;
  margin-top: 20px;
}

</style>
