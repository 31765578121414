<template>
  <div class="compilation-screen">
    <div class="compilation-screen__wrapper">
      <div
      v-if="prevRoute?.name && !isNoauthMode"
      class="compilation-screen__back"
      @click="returnBack">
        <DataIcon
        name="keyboard_backspace"
        class="compilation-screen__prev"
        :size="16"
        pointer />
        <span class="compilation-screen__prev">{{ label }}</span>
      </div>

      <CompilationInfo
      :is-noauth-mode="isNoauthMode"
      :get-is-loading-compilation="getIsLoadingCompilation"
      :compilation-name="compilationName"
      :is-request="isRequest"
      :realisations-managers="getRealisationsManagers"
      @addGroupsToCompilation="addGroupsToCompilation"
      @loadWithResponsibleUsers="loadWithResponsibleUsers" />

      <CompilationFolders
      v-if="showCompilationFolders && !getIsLoadingCompilation && !loading"
      :is-noauth-mode="isNoauthMode"
      :compilation-info="getCompilationInfo" />

      <Compilation
      v-if="!getIsLoadingCompilation && !loading"
      :compilation-groups="getCompilationGroups"
      :mode="isNoauthMode ? 'noauth' : ''"
      :custom-columns="getCompilationColumns"
      :groups-is-loaded="!getIsLoadingCompilation"
      :is-last-page="isLastPage"
      :realisations-managers="getRealisationsManagers"
      @loadNewGroups="loadNewGroups" />
    </div>
  </div>
</template>

<script>
import Compilation from './components/Compilation.vue';
import CompilationInfo from './components/CompilationInfo.vue';
import CompilationFolders from './components/CompilationFolders.vue';

export default {
  name: 'CollectionsScreen',
  components: {
    Compilation, CompilationInfo, CompilationFolders,
  },
  data: () => ({
    isRequest: false,
    compilationName: null,
    compilationId: null,
    prevRoute: null,
    shareToken: null,
    loading: false,
  }),
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  created() {
    this.$store.commit('compilations/setEnableDragMode', false);
    this.initData();
    if ((this.isAdmin || this.isManager) && !this.getRealisationsManagers) {
      const realisations = this.$store.dispatch('user/getUserList', 7);
      const realisationsManagers = this.$store.dispatch('user/getUserList', 8);
      Promise.all([realisations, realisationsManagers])
        .then((data) => {
          const allRealisationManagers = data.flat(1);
          this.$store.commit('user/setRealisationsManagers', allRealisationManagers);
        });
    }
  },
  methods: {
    loadNewGroups() {
      this.$store.dispatch('compilations/getCurrentCompilationGroups', { id: this.getCompilationInfo.id });
    },
    initData() {
      const {
        id, shareToken, name, rk,
      } = this.$route.query;

      // проверка на переход из запроса
      if (rk) this.isRequest = true;
      this.compilationName = rk || name;
      this.compilationId = id;

      this.$store.commit('compilations/setSelectedFolder', null); // Сброс выбранной папки
      this.$store.commit('compilations/setIsLoadingCompilation', true);
      this.$store.commit('compilations/setCompilationToken', shareToken);
      this.$store.dispatch('compilations/getCurrentCompilationInfo', { id, shareToken })
        .then((data) => {
          const rootFolder = data.folders.find((item) => item.name === 'root');
          this.$store.commit('compilations/setSelectedFolder', rootFolder);
          this.$store.dispatch('compilations/getCurrentCompilationGroups', { id, shareToken, reset: true })
            .finally(() => { this.$store.commit('compilations/setIsLoadingCompilation', false); });
        })
        .catch(() => {
          this.$router.push({ name: 'compilations' });
          this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка при загрузке подборки', error: true });
        });
      this.$store.dispatch('compilations/getCurrentCompilationSummary', { id, shareToken });
    },
    addGroupsToCompilation() {
      this.$store.commit('groups/setAddToCompilationMode', { ...this.getCompilationInfo });
      this.$store.commit('compilations/setSelectedCompilationList', []);
      this.$router.push({ name: 'catalog' });
    },
    returnBack() {
      if (this.prevRoute.name === 'catalog') {
        this.addGroupsToCompilation();
        return;
      }
      this.$router.go(-1);
    },
    loadWithResponsibleUsers(params) {
      this.loading = true;
      this.$store.dispatch('compilations/getCurrentCompilationGroups', params)
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    getIsLoadingCompilation() {
      return this.$store.getters['compilations/getIsLoadingCompilation'];
    },
    getCompilationInfo() {
      return this.$store.getters['compilations/getCurrentCompilationInfo'];
    },
    getCompilationGroups() {
      return this.$store.getters['compilations/getCurrentCompilationGroups'];
    },
    getCompilationColumns() {
      return this.getCompilationInfo?.sharedFields;
    },
    isNoauthMode() {
      return !localStorage.getItem('userToken');
    },
    isLastPage() {
      return this.$store.getters['compilations/getCurrentPage'] === (+this.$store.getters['compilations/getTotalPageCounts'] + 1);
    },
    label() {
      if (this.prevRoute.name === 'catalog') {
        return 'Вернуться в каталог';
      }
      return 'Вернуться к списку подборок';
    },
    showCompilationFolders() {
      return this.getCompilationInfo.folders && this.getCompilationInfo.folders.length > 1;
    },
    isManager() {
      return this.$store.getters['user/getIsManager'];
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    getRealisationsManagers() {
      return this.$store.getters['user/getRealisationsManagers'];
    },
  },
};
</script>

<style lang="scss" scoped>
.compilation-screen {
  display: flex;
  flex-direction: column;
}

.compilation-screen__wrapper {
  margin: 0px 20px;
}

.compilation-screen__back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  @include between-elements {
    margin-right: 10px;
  }
}

.compilation-screen__prev {
  font-size: 14px;
  color: $primary-gray;
}
</style>
