<template>
  <div
  v-click-outside="closeMenu"
  class="data-select"
  :class="{
    'data-select_disabled': disabled,
    'data-select_error': error,
    'data-select_no-edit': noEdit,
    'data-select_theme-white': theme === 'white'
  }">
    <!-- SINGLE SELECT -->
    <div
    v-if="selectType === 'single'"
    class="data-select__wrapper"
    @click="setOpenMenu">
      <span
      v-if="!selectedOption && !openSearchInput"
      class="data-select__placeholder">{{ placeholder }}</span>
      <span
      v-if="selectedOption && !openSearchInput"
      class="data-select__value">{{ selectedOption.name }}</span>
      <input
      v-if="withSearch && openSearchInput"
      ref="searchInput"
      v-model="inputField"
      class="data-select__search-input">
    </div>

    <!-- MULTI SELECT -->
    <div
    v-if="selectType === 'multi'"
    class="data-select__wrapper"
    @click="setOpenMenu">
      <span
      v-if="!selectedOptions.length && !openSearchInput"
      class="data-select__placeholder">{{ placeholder }}</span>
      <div
      v-if="!openSearchInput"
      ref="multilabel"
      class="data-select__multiselect-value">
        <span class="data-select__value">
          {{ labelForMultiSelect }}
        </span>
        <div
        v-if="multiSelectCounter"
        class="data-select__multiselect-counter">
          {{ `+ ${multiSelectCounter}` }}
        </div>
      </div>
      <input
      v-if="withSearch && openSearchInput"
      ref="searchInput"
      v-model="inputField"
      class="data-select__search-input">
    </div>

    <DataIcon
    v-if="withSearch"
    :name="openSearchInput ? 'close' : 'search'"
    :size="20"
    class="data-select__menu-arrow"
    @click.native="setOpenSearchInput" />

    <DataIcon
    v-else
    name="expand_more"
    :size="22"
    class="data-select__menu-arrow"
    :arrow-open="isOpenMenu"
    @click.native="isOpenMenu = !isOpenMenu" />

    <!-- MENU -->
    <transition name="fade">
      <div
      v-if="isOpenMenu"
      class="data-select__menu"
      :class="{
        'data-select__menu_no-edit': noEdit
      }"
      :style="`max-height: ${(maxElements * 48) + 2}px`">
        <!-- SINGLE SELECT -->
        <div v-if="selectType === 'single'">
          <div
          v-for="option in filteredOptions"
          :key="option.id"
          class="data-select__option"
          :class="{ 'data-select__option_selected': isSelectedOption(option) }"
          @click="selectSingleOption(option)">
            <span class="data-select__option-name">{{ option.name }}</span>
          </div>
          <div
          v-if="!options || !options.length"
          class="data-select__option data-select__option_nodata">
            {{ noOptionLabel }}
          </div>
          <div
          v-if="!filteredOptions.length && inputField.length"
          class="data-select__option">
            {{ inputField.length ? 'Ничего не найдено' : noOptionLabel }}
          </div>
        </div>
        <!-- MULTI SELECT -->
        <div v-if="selectType === 'multi'">
          <div
          v-for="option in filteredOptions"
          :key="option.value"
          class="data-select__option"
          :class="{
            'data-select__option_selected': isSelectedOption(option),
          }"
          @click="selectMultiOption(option)">
            <DataCheckbox
            :disabled="option.disabled"
            style="margin-right: 10px"
            :value="isSelectedOption(option)" />
            <span class="data-select__option-name">{{ option.name }}</span>
          </div>
          <div
          v-if="!filteredOptions.length && (inputField.length && openSearchInput)"
          class="data-select__option data-select__option_nodata">
            {{ inputField.length ? 'Ничего не найдено' : noOptionLabel }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DataSelect',
  props: {
    value: {
      type: [Boolean, String, Number, Array, Object],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    selectType: {
      type: String,
      default: 'single',
    },
    maxElements: {
      type: Number,
      default: 6,
    },
    error: {
      type: Boolean,
      default: false,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
    },
    // Label в случае если опций нет (например, не загрузились)
    noOptionLabel: {
      type: String,
      default: 'Нет данных',
    },
    // Отключает снятие выбора при клике на уже выбранный элемент (для single option)
    noClearSelect: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedOption: null,
    selectedOptions: [],
    isOpenMenu: false,
    multiSelectCounter: null,
    openSearchInput: false,
    inputField: '',
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.selectType === 'multi') {
        this.selectedOptions = this.value ? this.value : [];
        this.multiSelectCounter = this.selectedOptions.length ? this.selectedOptions.length - 1 : null;
      } else {
        this.selectedOption = this.value;
      }
    },
    setOpenMenu() {
      if (this.disabled) return;

      if (this.withSearch) {
        this.openSearchInput = true;
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
        this.isOpenMenu = true;
      } else {
        this.isOpenMenu = !this.isOpenMenu;
      }
    },
    closeMenu() {
      this.isOpenMenu = false;
      this.openSearchInput = false;
      this.inputField = '';
    },
    selectSingleOption(option) {
      if (this.noEdit && this.disabled) return;
      // Проверка на возможность снять выбор, кликнув повторно на выбранный элемент
      if (this.noClearSelect) {
        this.selectedOption = option;
      } else {
        this.selectedOption = JSON.stringify(option) === JSON.stringify(this.selectedOption) ? null : option;
      }
      this.$emit('input', this.selectedOption);
      this.closeMenu();
    },
    selectMultiOption(option) {
      if (this.noEdit || option.disabled || this.disabled) return;

      const isSelected = this.selectedOptions.some((item) => item.name === option.name);
      this.selectedOptions = isSelected ? this.selectedOptions.filter((item) => item.name !== option.name) : [...this.selectedOptions, option];

      this.isOpenMenu = true;

      if (this.withSearch && this.openSearchInput) {
        this.inputField = '';
        this.$refs.searchInput.focus();
      }
      this.multiSelectCounter = this.selectedOptions.length && this.selectedOptions.length > 1 ? this.selectedOptions.length - 1 : null;

      this.$emit('input', this.selectedOptions);
      this.$emit('selectOption', { option, selected: !isSelected });
    },
    isSelectedOption(option) {
      if (this.selectType === 'single') {
        return this.selectedOption === option;
      }
      if (this.selectType === 'multi') {
        // FUTURE добавить props label, который будет обозначать уникальный идентификатор или value, и по этому параметру понимать есть ли выбранный
        // элемент в списке
        return this.selectedOptions.some((item) => (JSON.stringify(item) === JSON.stringify(option)));
      }
      return false;
    },
    setOpenSearchInput() {
      if (this.openSearchInput) {
        this.openSearchInput = false;
        this.inputField = '';
        return;
      }
      this.isOpenMenu = true;
      this.openSearchInput = true;
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },
  },
  computed: {
    fullMultiselectLabel() {
      return this.selectedOptions.map((item) => item.name).join(', ');
    },
    labelForMultiSelect() {
      const string = this.selectedOptions.map((item) => item.name).join(', ');
      if (this.multiSelectCounter) {
        return this.selectedOptions[0].name;
      }
      return string;
    },
    filteredOptions() {
      return this.options
        ? this.options.filter((item) => item.name.toUpperCase().includes(this.inputField.toUpperCase()))
        : [];
    },
  },
  watch: {
    value(newValue) {
      if (newValue === null) {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.data-select {
  width: 100%;
  background: #fafeff;
  border-radius: 8px;
  border: 1px solid #d8f2fa;
  height: 48px;
  outline: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  &_theme-white {
    background: white;
    .data-select__menu {
      background: white;
    }
    .data-select__option {
      &:hover {
        background: rgb(248, 248, 248);
      }
      &_selected {
        background: rgb(248, 248, 248);
      }
    }
  }
  &_error {
    border: 1px solid $primary-red;
  }
  &_no-edit {
    background: #f7f7f7;
    border: 1px solid rgba(black, 0.03);
  }
  &_disabled {
    background: rgba(black, 0.03);
    // border: none;
    p, span {
      color: rgba(black, 0.3);
    }
  }
}

.data-select__menu-arrow {
  position: absolute;
  top: 15px;
  right: 10px;
  color: #7b7b7b;
}

.data-select__wrapper {
  padding: 0px 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.data-select__placeholder {
  color: #7b7b7b;
  font-weight: 400;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.data-select__menu {
  position: absolute;
  top: 52px;
  left: 0px;
  min-height: 48px;
  transition: 0.2s;
  overflow: auto;
  border-radius: 8px;
  width: 100%;
  background: #fafeff;
  border: 1px solid #d8f2fa;
  z-index: 10;
  &_no-edit {
    background: #f7f7f7;
  }
}

.data-select__option {
  height: 48px;
  padding: 0px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:hover {
    background: #edf5f7;
  }
  &_selected {
    background: #edf5f7;
  }
  &_nodata {
    color: $primary-lightgray;
    font-size: 14px;
  }
}

.data-select__value {
  font-size: 16px;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data-select__multiselect-value {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 20px;
  width: 100%;
  flex: 1;
}

.data-select__option-name {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
}

.data-select__multiselect-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($primary-blue, 0.2);
  margin-left: 15px;
  padding: 5px 10px;
  font-weight: 600;
  border-radius: 4px;
  white-space: nowrap;
  cursor: pointer;
}

.data-select__search-input {
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
}
</style>
