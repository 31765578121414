<template>
  <div
  class="tariff-full-burn"
  :class="(showFull && !hideBurn) && 'tariff-full-burn_show'">
    <div
    class="tariff-full-burn__container"
    :class="showFull && 'tariff-full-burn__container_show'">
      <div class="tariff-full-burn__info">
        <div class="tariff-full-burn__label-container">
          <span class="tariff-full-burn__text">Ваш бюджет за 365 дней</span>
          <span
          class="tariff-full-burn__text tariff-full-burn__text_label">{{ tariffLabel }}</span>
        </div>
        <div
        v-if="accessToCatalog"
        class="tariff-full-burn__label-container pointer"
        style="align-items: center"
        @click="goToTariffs">
          <img
          src="../assets/ok-icon.svg"
          class="tariff-full-burn__ok-icon">
          <span class="tariff-full-burn__text">{{ expiredLabel }}</span>
        </div>
        <div
        v-else
        @click="getAccessToCatalog">
          <span class="tariff-full-burn__text pointer">Запросить доступ</span>
        </div>
      </div>

      <div class="tariff-full-burn__progress">
        <div class="tariff-full-burn__progress_top">
          <div
          class="tariff-full-burn__label-container"
          style="align-items: center">
            <span class="tariff-full-burn__text tariff-full-burn__text">Burn Rate</span>
            <img
            src="../assets/burn-icon.svg"
            class="tariff-full-burn__ok-icon">
          </div>
          <span class="tariff-full-burn__text tariff-full-burn__text_small">{{ saleLabel }}</span>
        </div>
        <div
        v-if="points"
        ref="progress"
        class="tariff-full-burn__container-progress">
          <div
          ref="progress"
          class="tariff-full-burn__progress-active-points">
            <div
            v-for="(point, index) in points"
            :key="point.value"
            :style="{
              left: `${point.left}% `,
              opacity: (index === 0 || index === points.length - 1) ? 0 : 1,
              justifyContent: index === 0 ? 'flex-start' : index === points.length - 1 ? 'flex-end' : 'center',
            }"
            class="tariff-full-burn__progress-active-point-container">
              <div class="tariff-full-burn__progress-active-point" />
            </div>
          </div>
          <div
          class="tariff-full-burn__progress-active-line"
          :style="{width: `${activeLineWidth}%`}" />
          <div class="tariff-full-burn__progress-text-points">
            <span
            v-for="point in points"
            :key="point.value"
            :style="{
              marginLeft: `${point.margin}px`,
              left: `${point.left}%`,
            }"
            class="tariff-full-burn__text tariff-full-burn__text_text-point">
              {{ point.name }}
            </span>
          </div>
        </div>
      </div>

      <div
      class="tariff-full-burn__close"
      @click="changeTariffBar">
        <div class="tariff-full-burn__close-button">
          <DataIcon
          name="expand_more"
          :arrow-open="true"
          class="tariff-full-burn__menu-arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TariffFullBurn',
  props: {
    showFull: {
      type: Boolean,
      default: false,
    },
    userBurnRate: {
      type: [Number, String],
      default: null,
    },
    expiredDate: {
      type: String,
      default: null,
    },
    comission: {
      type: [String, Number],
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    accessToCatalog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    tariffValue: 0,
    tariffLabel: '',
    activeLineWidth: 0,
    nextMargin: null,
    nextDifference: null,
    hideBurn: false,
  }),
  mounted() {
    this.tariffValue = this.userBurnRate ? this.userBurnRate : 0;
    this.getTariffLabelProgress();
    setTimeout(() => {
      this.activeLineWidth = this.getActiveLineWidth();
      this.getTariffLabelProgress();
    }, 100);
  },
  methods: {
    goToTariffs() {
      this.$router.push({ name: 'tariffs' });
    },
    getAccessToCatalog() {
      const modalParams = { name: 'DemoBarRequestModal' };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    changeTariffBar() {
      this.hideBurn = true;
      setTimeout(() => {
        this.$store.commit('app/setShowFullTariffRate', false);
        this.hideBurn = false;
      }, 150);
    },
    getActiveLineWidth() {
      try {
        const checkpoints = this.getUserInfo.asEmployerAgency?.burnMarketRateAccount?.checkpoints;
        if (!checkpoints && !checkpoints.length) {
          this.nextMargin = '0?';
          this.nextDifference = '0?';
        }

        if (this.tariffValue === 0) {
          this.nextMargin = checkpoints[0].resultCommission;
          this.nextDifference = checkpoints[0].value;
          return 0;
        }

        if (this.tariffValue > checkpoints[2].value) {
          return 100;
        }

        let fromElement;
        const toElement = this.points.find((item, index) => {
          if (item.value >= this.tariffValue) {
            fromElement = this.points[index - 1];
          }
          return item.value > this.tariffValue;
        });

        this.nextMargin = toElement.nextMargin;
        this.nextDifference = toElement.value - this.tariffValue;

        const pointsDifferent = (toElement.value - fromElement.value); // разница значения между поинтами, внутри которого искомое значение
        const fromToDif = this.tariffValue - fromElement.value; // разница между заданной ценой и ближайшей максимальной точкой
        const percent = 33 / (pointsDifferent / fromToDif); // процент, который нужно добавить между поинтами
        const width = fromElement.left + percent + 0.5; // 0.5 чтобы полоса захватывала точку
        return width;
      } catch {
        return 0;
      }
    },
    getTariffLabelProgress() {
      const time = 1000;
      const step = Math.round(this.tariffValue / 100);
      let n = 0;
      const t = Math.round(time / (this.tariffValue / step));
      const interval = setInterval(() => {
        n += step;
        this.tariffLabel = this.setNumberSpaces(+n);
        if (n >= this.tariffValue) {
          this.tariffLabel = this.getTariffProgressLabel();
          clearInterval(interval);
        }
      }, t);
    },
    getTariffProgressLabel() {
      return `${this.setNumberSpaces(+this.tariffValue) || 0} ₽`;
    },
    getTotalCount(count) {
      count = Math.abs(count);

      if (count > 999 && count < 100000) {
        return `${parseFloat((count / 1000).toFixed(1))}k`;
      }
      if (count >= 100000 && count < 1000000) {
        return `${(count / 1000).toFixed(0)}k`;
      }
      if (count >= 1000000) {
        return `${parseFloat((count / 1000000).toFixed(1))}m`;
      }
      return count;
    },
  },
  computed: {
    saleLabel() {
      try {
        const checkpoints = this.getUserInfo.asEmployerAgency?.burnMarketRateAccount?.checkpoints;
        if (this.tariffValue >= checkpoints[2].value) {
          return 'Достигнута максимальная скидка';
        }
        return `До следущего порога скидки (${this.nextMargin}) не хватает ${this.setNumberSpaces(this.nextDifference)} ₽`;
      } catch {
        return '';
      }
    },
    expiredLabel() {
      if (this.isAdmin) {
        return 'Доступ администратора';
      }
      if (!this.expiredDate) {
        return '-';
      }
      return `Доступ до ${this.expiredDate}`;
    },
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
    points() {
      try {
        const checkpoints = this.getUserInfo.asEmployerAgency?.burnMarketRateAccount?.checkpoints;
        const baseComission = this.getUserInfo?.asEmployerAgency?.baseCommissionInPercent;
        return [
          {
            name: `0к - ${baseComission}%`,
            value: 0,
            nextMargin: checkpoints[0].resultCommission,
            margin: 0,
            left: 0,
          },
          {
            name: `${this.getTotalCount(checkpoints[0].value)} - ${checkpoints[0].resultCommission}%`,
            value: checkpoints[0].value,
            nextMargin: checkpoints[0].resultCommission,
            margin: -28,
            left: 33,
          },
          {
            name: `${this.getTotalCount(checkpoints[1].value)} - ${checkpoints[1].resultCommission}%`,
            value: checkpoints[1].value,
            nextMargin: checkpoints[1].resultCommission,
            margin: -24,
            left: 66,
          },
          {
            name: `${this.getTotalCount(checkpoints[2].value)} - ${checkpoints[2].resultCommission}%`,
            value: checkpoints[2].value,
            nextMargin: checkpoints[2].resultCommission,
            margin: -33,
            left: 100,
          },
        ];
      } catch {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tariff-full-burn {
  width: 100%;
  background: white;
  display: flex;
  height: 0px;
  opacity: 0;
  justify-content: center;
  align-items: center;
  background: transparent;
  transition: height 0.2s, opacity 0.4s;
  overflow: hidden;
  &_show {
    opacity: 1;
    height: 105px;
    overflow: visible;
  }
}

.tariff-full-burn__container {
  width: 100%;
  max-width: 1600px;
  background: white;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-sizing: border-box;
  border: 1px solid #e0f1f7;
  border-top: none;
  display: flex;
  transition: height 0.2s, opacity 0.2s;
  height: 105px;
  padding: 20px 20px 20px;
}

.tariff-full-burn__info {
  height: 100%;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tariff-full-burn__label-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  @include between-elements {
    margin-right: 10px;
  }
}

.tariff-full-burn__text {
  font-size: 14px;
  &_label {
    font-size: 24px;
    font-weight: 600;
    line-height: 18px;
  }
  &_small {
    font-size: 12px;
  }
  &_text-point {
    font-size: 12px;
    text-align: left;
    position: absolute;
    top: 17px;
    width: 80px;
  }
}

.tariff-full-burn__ok-icon {
  width: 20px;
  height: 20px;
}

.tariff-full-burn__progress {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
.tariff-full-burn__progress_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tariff-full-burn__container-progress {
  width: 100%;
  background: #D8F2FA;
  height: 10px;
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.tariff-full-burn__progress-active-line {
  position: absolute;
  left: -1px;
  top: 0;
  width: 0px;
  transition: width 1.1s ease-out;
  height: 10px;
  background: linear-gradient(90deg,#7ace80, #82DA88);
  border-radius: 10px;
}

.tariff-full-burn__progress-text-points {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
}

.tariff-full-burn__progress-active-points {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
}

.tariff-full-burn__progress-active-point-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
}

.tariff-full-burn__progress-active-point {
  width: 4px;
  height: 4px;
  background: white;
  border-radius: 100%;
  z-index: 1;
}

.tariff-full-burn__close {
  padding: 0px 10px 0px 30px;
  height: 100%;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tariff-full-burn__close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #D8F2FA;
  border-radius: 100%;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }
}

.tariff-full-burn__menu-arrow {
  color: $primary-darkblue;
}
</style>
