<template>
  <div
  class="demo-bar"
  :class="hideGroupDetails && 'demo-bar_hide'">
    <div class="demo-bar__container">
      <span class="demo-bar__text demo-bar__text_title">Оформите доступ к SocialData</span>
      <span class="demo-bar__text">Получите доступ к нашему продукту на месяц и оцените его по достоинству</span>
      <div
      class="demo-bar__button"
      @click="getAccessToCatalog">
        <img src="@/assets/svg/icons/shop-icon.svg">
        <span class="demo-bar__text demo-bar__text_button">Оформить тестовый период</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DemoBar',
  props: {
    hideGroupDetails: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    getAccessToCatalog() {
      const modalParams = { name: 'DemoBarRequestModal' };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
  },
};
</script>

<style lang="scss" scoped>
.demo-bar {
  width: 100%;
  position: absolute;
  bottom: 0px;
  top: 352px;
  left: 0px;
  flex: 1;
  z-index: 4;
  margin-bottom: 10px;
  backdrop-filter: blur(2.6px);
  background-color: rgba(white, 0.1);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  user-select: none;
  &_hide {
    top: 242px;
  }
}

.demo-bar__container {
  min-width: 40%;
  max-width: 70%;
  left: 0px;
  bottom: -20px;
  background: white;
  border-radius: 20px;
  border: 1px solid rgba($primary-blue, 0.2);
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  transform: translateY(-40px);
  user-select: auto;
  margin-top: -20px;
  @include between-elements {
    margin-bottom: 24px;
  }
}

.demo-bar__text {
  font-size: 16px;
  max-width: 510px;
  text-align: center;
  line-height: 28px;
  &_title {
    max-width: 650px;
    font-size: 28px;
    color: $primary-blue;
    font-weight: 600;
  }
  &_button {
    color: white;
  }
}

.demo-bar__button {
  background: $primary-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 40px;
  border-radius: 33px;
  transition: 0.2s;
  cursor: pointer;
  @include between-elements {
    margin-right: 15px;
  }
  &:hover {
    opacity: 0.8;
  }
}
</style>
