<template>
  <div class="group-popular">
    <div
    class="group-popular__content pointer">
      <div class="group-popular__element">
        <span
        class="group-popular__text">
          Подборки:
        </span>
        <span class="group-popular__text group-popular__text_light">
          {{ getCountInCompilations }}
        </span>
      </div>
      <div class="group-popular__element">
        <span
        class="group-popular__text">
          РК:
        </span>
        <span class="group-popular__text group-popular__text_light">
          {{ getCountInRk }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GroupPopular',
  props: {
    group: {
      type: Object,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    disableEditor: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
  }),
  computed: {
    getCountInCompilations() {
      if (!this.group.countInCompilations && this.group.countInCompilations !== 0) {
        return '—';
      }
      return this.group.countInCompilations;
    },
    getCountInRk() {
      if (!this.group.campaignLauncherCount && this.group.campaignLauncherCount !== 0) {
        return '—';
      }
      return this.group.campaignLauncherCount;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-popular {
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 50px;
  padding-left: 35px;
  position: relative;
}

.group-popular__content {
  @include between-elements {
    margin-bottom: 3px;
  }
}

.group-popular__text {
  font-size: 12px;
  margin-right: 2px;
  color: $primary-black;
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

</style>
