<template>
  <div class="words-filter">
    <!-- Заголовок -->
    <div
    class="words-filter__header"
    @click="isOpen = !isOpen">
      <div class="words-filter__header-name">
        <span class="words-filter__title">{{ title }}</span>
        <transition name="fastfade">
          <div
          v-if="isActiveFilter"
          v-tooltip="getAddedWordsTooltip"
          class="words-filter__active-highlight" />
        </transition>
      </div>
      <DataIcon
      name="expand_more"
      class="words-filter__title pointer"
      :arrow-open="isOpen" />
    </div>

    <!-- Контент -->
    <div
    class="words-filter__content"
    :class="isOpen && 'words-filter__content_show'">
      <DataInput
      v-model.trim="word"
      placeholder="Введите слово"
      @input="inputWord">
        <template #icon>
          <transition name="fade">
            <div
            v-if="word.length"
            class="words-filter__add-button"
            @click="addWord">
              + Добавить
            </div>
          </transition>
        </template>
      </DataInput>

      <div
      class="words-filter__added-words">
        <div
        v-for="(word, index) in addedWords"
        :key="word + index"
        class="words-filter__added-word"
        :class="{ 'words-filter__added-word_stopwords': type === 'stopwords' }">
          <span class="words-filter__added-word-text">
            {{ word }}
          </span>
          <DataIcon
          name="close"
          pointer
          :size="16"
          class="words-filter__added-word-icon"
          @click.native="removeWord(word, index)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WordsFilter',
  props: {
    type: {
      type: String,
      default: 'keywords',
    },
    open: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isOpen: true,
    title: '',
    word: '',
    addedWords: [],
  }),
  created() {
    this.title = this.type === 'keywords' ? 'Ключевые слова' : 'Минус слова';
    this.isOpen = this.open;
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      // Если массив
      if (this.type === 'keywords' && this.$route.query.keywords) {
        this.addedWords = Array.isArray(this.$route.query.keywords)
          ? this.$route.query.keywords
          : [this.$route.query.keywords];

        this.$route.query.keywords = this.addedWords;
      }
      if (this.type === 'stopwords' && this.$route.query.stopWords) {
        this.addedWords = Array.isArray(this.$route.query.stopWords)
          ? this.$route.query.stopWords
          : [this.$route.query.stopWords];
        this.$route.query.stopWords = this.addedWords;
      }

      if (this.addedWords.length) this.isOpen = true;
    },
    inputWord() {
      this.setFilters();
    },
    addWord() {
      this.addedWords.unshift(this.word);
      this.word = '';
      this.setFilters();
    },
    removeWord(word, wordIndex) {
      this.addedWords = this.addedWords.filter((item, index) => !(item === word && index === wordIndex));
      this.setFilters();
    },
    setFilters() {
      const query = { ...this.$route.query };
      if (this.type === 'keywords') {
        if (this.addedWords.length || this.word.length) {
          query.keywords = this.word.length ? this.addedWords.concat(this.word) : this.addedWords;
        } else {
          delete query.keywords;
        }
      }

      if (this.type === 'stopwords') {
        if (this.addedWords.length || this.word.length) {
          query.stopWords = this.word.length ? this.addedWords.concat(this.word) : this.addedWords;
        } else {
          delete query.stopWords;
        }
      }

      this.$router.push({ name: 'post-search', query, params: { savedPosition: true } });
    },
    resetFilters() {
      this.addedWords = [];
      this.word = '';
    },
  },
  computed: {
    isActiveFilter() {
      return this.addedWords.length || this.word.length;
    },
    getAddedWordsTooltip() {
      return this.addedWords;
    },
  },
};
</script>

<style lang="scss" scoped>
.words-filter {
  background: white;
  padding: 0px 15px;
  border-radius: 10px;

  .words-filter__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 15px 0px;
  }

  .words-filter__header-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .words-filter__title {
    font-size: 14px;
    color: $primary-gray;
    font-weight: 500;
    margin-right: 10px;
  }

  .words-filter__active-highlight {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: $primary-blue;
  }

  .words-filter__content {
    max-height: 0px;
    opacity: 0;
    transition: 0.2s;
    pointer-events: none;
    overflow: hidden;
    &_show {
      pointer-events: auto;
      opacity: 1;
      max-height: 230px;
    }
  }

  .words-filter__added-words {
    margin-top: 10px;
    gap: 5px;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    height: auto;
    max-height: 160px;
    margin-bottom: 15px;
  }

  .words-filter__added-word {
    background: $primary-blue;
    color: white;
    padding: 6px 5px 6px 10px;
    border-radius: 5px;
    display: inline-flex;
    max-width: 100%;
    overflow: hidden;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    position: relative;
    min-width: 40px;
    &_stopwords {
      background: rgba($primary-gray, 0.7);
    }
  }

  .words-filter__added-word-text {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-width: 40px;
    margin-right: 10px;
  }

  .words-filter__added-word-icon {
    color: rgba(white, 0.7);
  }

  .words-filter__add-button {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $primary-blue;
    color: white;
    font-size: 13px;
    transition: 0.1s;
    border-radius: 6px;
    position: absolute;
    top: 8px;
    right: 10px;
    padding: 0px 8px;
    cursor: pointer;
  }
}
</style>
