<template>
  <div class="clients-access-headers">
    <div
    v-for="header in getHeaders"
    :key="header.value"
    :style="`width: ${header.width}px`"
    :class="{ 'clients-access-headers__element_name': header.value === 'name' }"
    class="clients-access-headers__element">
      <span class="clients-access-headers__text_light">
        {{ header.name }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ClientsAccessHeaders',
  props: {
    clietsAccessType: {
      type: String,
      default: 'wait',
    },
  },
  data: () => ({}),
  computed: {
    getHeaders() {
      return [
        { name: 'Клиент', value: 'name' },
        { name: 'ФИО', value: 'fio', width: 200 },
        { name: 'Контакты', value: 'contact', width: 200 },
        { name: 'Агентство', value: 'agency', width: 100 },
        { name: 'Что продвигают', value: 'about', width: 300 },
        { name: 'Дата заявки', value: 'date', width: 130 },
        { name: this.clietsAccessType === 'wait' ? 'Доступ' : 'Статус', value: 'access' },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.clients-access-headers {
  display: flex;
  align-items: center;
  min-width: 100%;
  position: sticky;
  background: white;
  z-index: 2;
  top: 0px;
  left: 0px;
  border-top: 1px solid $primary-group-border;
  border-bottom: 1px solid $primary-group-border;
}

.clients-access-headers__element {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 5px;
  }
  &_name {
    padding: 12px 0px;
    position: sticky;
    left: 0px;
    padding-left: 20px;
    background: white;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.clients-access-headers__text {
  font-size: 14px;
  color: $primary-gray;
  &_name {
    color: $primary-blue;
  }
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

</style>
