<template>
  <div class="clients">
    <div class="clients__actions">
      <div class="clients__filters">
        <div class="clients__campaign-filter">
          <DataInput
          v-model.trim="clientName"
          clear-icon
          class="clients__search-input"
          placeholder="Введите название"
          @input="searchClient" />
        </div>
        <span
        class="clients__access-text"
        @click="setAccessToCatalog">{{ accessLabel }}</span>
        <div
        v-if="isAdmin"
        class="clients__campaign-filter">
          <DataCheckbox
          v-model="withCampaign"
          with-null
          @input="changeWithCampaign" />
          <span class="clients__text pointer">Есть РК</span>
        </div>
      </div>
      <DataIcon
      name="settings"
      class="clients__text" />
    </div>
    <div
    v-if="getClientsData.length"
    class="clients-wrapper">
      <div
      ref="clientsContent"
      class="clients__content"
      @scroll="onScroll">
        <UsersList
        v-if="selectedClientTab === 'users'"
        :users-data="getClientsData" />
        <ClientsList
        v-else
        :sales-managers="salesManagers"
        :account-managers="accountManagers"
        :clients-data="getClientsData" />
      </div>
    </div>
    <div
    v-if="!getClientsData.length && !isLoading"
    class="clients__empty">
      <span
      class="clients__text clients__text_light">
        Список пуст
      </span>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import ClientsList from './components/Clients/ClientsList.vue';
import UsersList from './components/Users/UsersList.vue';

export default {
  name: 'Clients',
  components: { ClientsList, UsersList },
  props: {
    selectedClientTab: {
      type: String,
      default: null,
    },
    accountManagers: {
      type: Array,
      default: null,
    },
    salesManagers: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    accessToCatalog: true,
    withCampaign: null,
    clientName: '',
  }),
  created() {
    this.loadClientsData();
  },
  methods: {
    loadClientsData(reset = true) {
      if (this.isAdmin) {
        this.$store.commit('app/setAppIsLoading', true);
        if (this.selectedClientTab === 'all') {
          this.$store.dispatch('clients/getClientsData', { reset })
            .finally(() => { (this.$store.commit('app/setAppIsLoading', false)); });
        }
        if (this.selectedClientTab === 'agency') {
          this.$store.dispatch('clients/getClientsData', { reset, isAgency: true })
            .finally(() => { (this.$store.commit('app/setAppIsLoading', false)); });
        }
        if (this.selectedClientTab === 'rekls') {
          this.$store.dispatch('clients/getClientsData', { reset, isAgency: false })
            .finally(() => { (this.$store.commit('app/setAppIsLoading', false)); });
        }
        if (this.selectedClientTab === 'users') {
          this.$store.dispatch('clients/getUsersData', { reset })
            .finally(() => { (this.$store.commit('app/setAppIsLoading', false)); });
        }
        return;
      }
      if (this.isAgency) {
        this.$store.commit('app/setAppIsLoading', true);
        const agencyId = this.$store.getters['user/getUserInfo']?.asEmployerAgency?.id;
        this.$store.dispatch('clients/getAgencyClients', { reset: true, id: agencyId })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar',
              {
                text: 'Произошла ошибка при загрузке клиентов. Попробуйте позже',
                error: true,
              });
          })
          .finally(() => { (this.$store.commit('app/setAppIsLoading', false)); });
      }
    },
    // eslint-disable-next-line func-names
    searchClient: debounce(function () {
      this.$store.commit('clients/setClientName', this.clientName);
      this.loadClientsData();
    }, 600),
    onScroll() {
      if (this.isLastPage || this.isLoading) {
        return;
      }
      const position = this.$refs.clientsContent.scrollTop;
      const { clientHeight } = this.$refs.clientsContent;

      const endScroll = Math.ceil(position + clientHeight) >= Math.ceil(this.$refs.clientsContent.scrollHeight);
      if (endScroll) {
        this.loadClientsData(false);
      }
    },
    setAccessToCatalog() {
      this.accessToCatalog = !this.accessToCatalog;
      this.$store.commit('clients/setAccessToCatalog', this.accessToCatalog);
      this.loadClientsData();
    },
    changeWithCampaign() {
      this.$store.commit('clients/setWithCampaign', this.withCampaign);
      this.loadClientsData();
    },
  },
  computed: {
    accessLabel() {
      if (this.isAdmin || this.isAgency) {
        return this.accessToCatalog ? 'Показать клиентов без доступа' : 'Показать клиентов с доступом';
      }
      return '';
    },
    isLastPage() {
      return this.$store.getters['clients/getIsLastPage'];
    },
    getClientsData() {
      if (this.selectedClientTab === 'users') {
        return this.$store.getters['clients/getUsersData'];
      }
      return this.$store.getters['clients/getClientsData'];
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    isLoading() {
      return this.$store.getters['app/getAppIsLoading'];
    },
  },
};
</script>

<style lang="scss">
.clients {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow: auto;
  background: white;
  border-radius: 12px;
  margin: 20px;
  justify-content: flex-start;

  .clients-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    justify-content: flex-start;
    background: white;
  }

  .clients__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    width: 100%;
  }

  .clients__empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .clients__filters {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .clients__campaign-filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include between-elements {
      margin-right: 10px;
    }
  }

  .clients__content {
    overflow: auto;
    height: 100%;
  }

  .clients__elements {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .clients__text {
    font-size: 14px;
    color: #575757;
    &_light {
      color: $primary-lightgray;
    }
  }

  .clients__access-text {
    margin-right: 20px;
    font-size: 14px;
    color: #575757;
    cursor: pointer;
  }

  .clients__search-input {
    margin-right: 20px;
    .data-input {
      height: 35px !important;
    }
    .data-input__icon-block {
      top: 6px;
    }
  }
}
</style>
