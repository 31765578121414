import axios from 'axios';

export const API_URL = process.env.VUE_APP_API_BASE_URL ?? 'https://data-api.socialjet.pro/api-dev/v1/';

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('userToken');
  if (token) {
    config.headers['X-API-KEY'] = token;
  }
  const currency = localStorage.getItem('appCurrency') || 'rub';
  if (currency !== 'rub') {
    config.headers.currency = currency;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (err) => Promise.reject(err)
  ,
);

export default api;
