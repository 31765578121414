<template>
  <div class="add-groups-to-compilation">
    <button
    class="add-groups-to-compilation__button"
    @click="openAddGroupsModal">
      Добавить каналы
    </button>
    <DataModal
    :show-modal="showAddToCompilationModal"
    @close="showAddToCompilationModal = false">
      <template #modal-content>
        <div class="add-groups-to-compilation__main-block">
          <div class="add-groups-to-compilation__selected-groups">
            <span>Всего каналов: </span><span
            class="add-groups-to-compilation__selected-groups-text">{{ selectedGroupsList.length }}</span>
            <div class="add-groups-to-compilation__selected-groups-container">
              <div class="add-groups-to-compilation__selected-groups-list">
                <div
                v-for="group in selectedGroupsList"
                :key="group.id + group.social"
                class="add-groups-to-compilation__selected-groups-list-item">
                  <span>{{ group.name }}</span>
                  <DataIcon
                  name="close"
                  pointer
                  @click.native="deleteGroupFromSelected(group)" />
                </div>
              </div>
            </div>
          </div>
          <DataButton
          text="Добавить данные каналы в подборку"
          :width="340"
          @handler="addGroupsToCompilationFromCatalog" />
          <DataButton
          text="Отменить"
          :width="340"
          type="outlined"
          @handler="showAddToCompilationModal = false" />
        </div>
      </template>
    </DataModal>
  </div>
</template>

<script>
export default {
  name: 'AddGroupsToCompilation',
  props: {
    selectedGroups: {
      type: Array,
      default: null,
    },
    compilationInfo: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    selectedGroupsList: null,
    showAddToCompilationModal: false,
  }),
  methods: {
    openAddGroupsModal() {
      this.selectedGroupsList = [...this.selectedGroups];
      this.showAddToCompilationModal = true;
    },
    addGroupsToCompilationFromCatalog() {
      this.$store.dispatch('compilations/addGroupsToCompilation', { id: this.compilationInfo.id, groups: this.selectedGroupsList })
        .then(() => {
          this.$store.commit('groups/setSelectedGroups', []);
          this.showAddToCompilationModal = false;
          this.$store.dispatch('app/setSnackBar', { text: 'Успешно. Выбранные каналы добавлены в подборку' });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: 'Произошла ошибка при добавлении каналов. Попробуйте позже',
              error: true,
            });
        });
    },
    deleteGroupFromSelected(group) {
      this.selectedGroupsList = this.selectedGroupsList.filter((item) => item.id !== group.id);
      if (this.selectedGroupsList.length === 0) {
        this.showAddToCompilationModal = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-groups-to-compilation {
}

.add-groups-to-compilation__button {
  cursor: pointer;
  width: 140px;
  height: 40px;
  outline: none;
  border: none;
  background: $primary-white;
  border-radius: 40px;
  @include tablet-screen {
    width: 180px;
  }
}

.add-groups-to-compilation__main-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.add-groups-to-compilation__selected-groups {
  padding-bottom: 20px;
  position: relative;
}

.add-groups-to-compilation__selected-groups-container {
  width: 340px;
  background: white;
  overflow: hidden;
  z-index: 3;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 20px 0px 0px 0px;
}

.add-groups-to-compilation__selected-groups-list {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-height: 240px;
  overflow: auto;
}

.add-groups-to-compilation__selected-groups-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid rgba($primary-blue, 0.2);
}

</style>
