var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"data-skeleton",class:[
  'animation-' + _vm.animationType,
  _vm.circle,
],style:({
  width: _vm.width ? `${_vm.width}px` : '100%',
  height: `${_vm.height}px`,
})})
}
var staticRenderFns = []

export { render, staticRenderFns }