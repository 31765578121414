export default {
  grow: [
    {
      value: 'month',
      text: 'per_month',
    },
    {
      value: 'day',
      text: 'per_day',
      hideFor: ['youtube'],
    },
  ],
  views: [
    {
      value: 'last24h',
      text: 'per24',
    },
    {
      value: 'last48h',
      text: 'per48',
    },
    {
      value: 'last72h',
      text: 'per72',
    },
  ],
  posts: [
    {
      value: 'last24h',
      text: 'per24',
    },
    {
      value: 'lastWeek',
      text: 'per7days',
    },
    {
      value: 'lastMonth',
      text: 'per_month',
    },
  ],
  price: [
    {
      value: 'post',
      text: 'per_post',
    },
    {
      value: 'story',
      text: 'per_stories',
      showFor: ['instagram', 'vk'],
    },
    {
      value: 'reels',
      text: 'per_reels',
      showFor: ['instagram'],
    },
    {
      value: 'integration',
      text: 'per_integration',
    },
  ],
  gender: [
    {
      value: null,
      text: 'all',
    },
    {
      value: 'male',
      text: 'male',
    },
    {
      value: 'female',
      text: 'female',
    },
  ],
  countries: [
    {
      value: null,
      name: 'all',
      en: null,
    },
    {
      value: 'Россия',
      name: 'Россия',
      en: 'russia',
    },
    {
      value: 'Украина',
      name: 'Украина',
      en: 'ukrain',
    },
    {
      value: 'Беларусь',
      name: 'Беларусь',
      en: 'belarus',
    },
    {
      value: 'Узбекистан',
      name: 'Узбекистан',
      en: 'uzbekistan',
    },
    {
      value: 'Казахстан',
      name: 'Казахстан',
      en: 'kazakhstan',
    },
    {
      value: 'Иран',
      name: 'Иран',
      en: 'iran',
    },
    {
      value: 'Киргизия',
      name: 'Киргизия',
      en: 'kyrgyzstan',
    },
    {
      value: 'withoutCountry',
      name: 'Без страны',
      en: 'empty-avatar',
    },
  ],
  botNames: [
    {
      value: null,
      name: 'Все',
    },
    {
      value: 'tgstat',
      name: 'Tgstat',
    },
    {
      value: 'telegram-api',
      name: 'Telegram-api',
    },
    {
      value: 'telegram-html',
      name: 'Telegram-html',
    },
    {
      value: 'vk-api',
      name: 'Vk-api',
    },
    {
      value: 'vk-html',
      name: 'Vk-html',
    },
    {
      value: 'dzen',
      name: 'Dzen',
    },
    {
      value: 'youtube',
      name: 'Youtube',
    },
    {
      value: 'ok',
      name: 'OK',
    },
    {
      value: 'isnt',
      name: 'Inst',
    },
  ],
  boolean: [
    { value: null, name: 'all' },
    { value: true, name: 'yes' },
    { value: false, name: 'no' },
  ],
  groupTemplate: {
    name: null,
    url: null,
    description: null,
    categoriesId: [],
    contact: null,
    inSocialjet: false,
    postsStatistic: {
      averagePerDay: null,
      averagePerMonth: null,
      countLast24h: null,
      countLast48h: null,
      countLast72h: null,
      countLastMonth: null,
      total: null,
    },
    commentsStatistic: {
      averagePerDay: null,
      averagePerMonth: null,
      countLast24h: null,
      countLast48h: null,
      countLast72h: null,
      countLastMonth: null,
      total: null,
    },
    subscribersStatistic: {
      malePercent: null,
      femalePercent: null,
      count: null,
      dayGrow: null,
      monthGrow: null,
      cities: null,
      countries: null,
    },
    postPrice: {
      buy: null,
      sale: null,
      sell: null,
    },
  },
  socials: [
    { value: 'telegram', name: 'telegram' },
    { value: 'vk', name: 'vk' },
    { value: 'instagram', name: 'instagram' },
    { value: 'odnoklassniki', name: 'odnoklassniki' },
    { value: 'youtube', name: 'youtube' },
    { value: 'yandex-zen', name: 'yandex-zen' },
  ],
  columns: [
    {
      name: 'name',
      value: 'name',
      show: true,
      sort: 'name',
      id: 1,
    },
    {
      name: 'bot-notify',
      value: 'bot-notify',
      show: true,
      admin: true,
      id: 'bot-notify',
      width: 200,
    },
    {
      name: 'responsible',
      value: 'responsible',
      show: true,
      admin: true,
      id: 122,
      width: 260,
    },
    {
      name: 'description',
      value: 'description',
      show: true,
      id: 'description',
      width: 260,
    },
    {
      name: 'subscribers',
      value: 'subscribers',
      valueMave: 'audience',
      show: true,
      id: 'subscribers',
      sort: 'subscribersCount',
    },
    {
      name: 'views-er',
      value: ['views', 'er'],
      value2: 'views-er',
      valueMave: 'Прослушивания',
      show: true,
      sort: ['viewsLast24h', 'er'],
      sortMave: ['averagePerDay', 'er'],
      id: 3,
    },
    {
      name: 'geography',
      value: 'geo',
      show: true,
      id: 4,
    },
    {
      name: 'gender',
      value: 'gender',
      show: true,
      id: 5,
    },
    {
      name: 'interests',
      value: 'interests',
      show: true,
      id: 6,
    },
    {
      name: 'posts',
      value: 'posts_count',
      show: true,
      sort: 'postsLast24h',
      id: 7,
    },
    {
      name: 'rate',
      value: 'margin',
      show: true,
      admin: true,
      id: 8,
    },
    {
      name: 'sell',
      value: 'price_sell',
      show: true,
      sort: 'postPriceSell',
      id: 9,
      width: 200,
    },
    {
      name: 'buy',
      value: 'price_buy',
      admin: true,
      agency: true,
      show: true,
      sort: 'postPriceBuy',
      id: 10,
      width: 200,
    },
    {
      name: 'sale',
      value: 'price_sale',
      admin: true,
      show: true,
      sort: 'postPriceSale',
      id: 11,
      width: 200,
    },
    {
      name: 'discount',
      value: 'discount',
      admin: true,
      show: true,
      sort: 'discount',
      id: 12,
    },
    {
      name: 'cpm',
      value: 'cpm',
      show: true,
      sort: 'cpm',
      id: 13,
    },
    {
      name: 'cpc',
      value: 'cpc',
      show: true,
      id: 14,
    },
    {
      name: 'sji',
      value: 'rating_sji',
      show: true,
      sort: 'cleanContentScore',
      id: 15,
      info: 'rating_sji_info',
    },
    {
      name: 'popular',
      value: 'popular',
      admin: true,
      show: true,
      sort: 'countInCompilations',
      id: 16,
    },
    {
      name: 'roboticity',
      value: 'robot',
      show: true,
      id: 17,
    },
    {
      name: 'note',
      value: 'note',
      show: true,
      id: 17.1,
    },
    {
      name: 'other',
      value: 'Other',
      admin: true,
      show: true,
      id: 18,
    },
    {
      name: 'contacts',
      value: 'contacts',
      admin: true,
      show: true,
      sort: 'contact',
      id: 19,
    },
    {
      name: 'comment',
      value: 'comment',
      admin: true,
      show: true,
      id: 20,
    },
    {
      name: 'editor',
      value: 'redactor',
      admin: true,
      show: true,
      id: 21,
    },
    {
      name: 'createdAt',
      value: 'create_date',
      admin: true,
      show: true,
      sort: 'createdAt',
      id: 22,
    },
    {
      name: 'updatedAt',
      value: 'update',
      show: true,
      sort: 'dataUpdatedAt',
      id: 23,
    },
    {
      name: 'priceUpdatedAt',
      value: 'update_price',
      admin: true,
      show: true,
      sort: 'postPriceManualUpdatedAt',
      id: 24,
    },
    {
      name: 'banReason',
      value: 'bad_reason',
      admin: true,
      show: true,
      id: 25,
    },
  ],
  intervalsSubscribers: [
    {
      name: 'nano',
      text: '1k-10k',
      min: 1000,
      max: 10000,
    },
    {
      name: 'micro',
      text: '10k-50k',
      min: 10000,
      max: 50000,
    },
    {
      name: 'middle',
      text: '50k-500k',
      min: 50000,
      max: 500000,
    },
    {
      name: 'macro',
      text: '500k-1 млн',
      min: 500000,
      max: 1000000,
    },
    {
      name: 'mega',
      text: '1 млн +',
      min: 1000000,
      max: 0,
    },
  ],
  intervalsPrice: [
    {
      text: '0-5k',
      min: 0,
      max: 5000,
    },
    {
      text: '5k-10k',
      min: 5000,
      max: 10000,
    },
    {
      text: '10k-20k',
      min: 10000,
      max: 20000,
    },
    {
      text: '20k-50k',
      min: 20000,
      max: 50000,
    },
    {
      text: '50k-100k',
      min: 50000,
      max: 100000,
    },
    {
      text: '100k +',
      min: 100000,
      max: 0,
    },
  ],
  intervalsViews: [
    {
      text: '0-1k',
      min: 0,
      max: 1000,
    },
    {
      text: '1k-2k',
      min: 1000,
      max: 2000,
    },
    {
      text: '2k-3k',
      min: 2000,
      max: 3000,
    },
    {
      text: '3k-5k',
      min: 3000,
      max: 5000,
    },
    {
      text: '5k-10k',
      min: 5000,
      max: 10000,
    },
    {
      text: '10k-20k',
      min: 10000,
      max: 20000,
    },
    {
      text: '20k-50k',
      min: 20000,
      max: 50000,
    },
    {
      text: '50k +',
      min: 50000,
      max: 0,
    },
  ],
};
