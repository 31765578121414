import { render, staticRenderFns } from "./MainGroups.vue?vue&type=template&id=288723ec&scoped=true"
import script from "./MainGroups.vue?vue&type=script&lang=js"
export * from "./MainGroups.vue?vue&type=script&lang=js"
import style0 from "./MainGroups.vue?vue&type=style&index=0&id=288723ec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "288723ec",
  null
  
)

export default component.exports