<template>
  <div class="tariffs-screen">
    <div class="tariffs-screen__header">
      <span
      class="tariffs-screen__title"
      @click="openPaymentModal">
        Доступ
      </span>
    </div>
    <TariffsBanner
    v-if="showTariffBanner"
    class="tariffs-screen__tariff-banner"
    @closeTariffBanner="closeTariffBanner" />

    <div
    v-if="tariffId"
    class="tariffs-screen__tariffs">
      <div class="tariffs-screen__tariff-element">
        <TariffInfo
        :tariff-id="tariffId"
        :tariff-value="burnRate"
        :expired-date="expiredDate"
        @openPayment="openPaymentModal" />
        <TariffProgress
        :tariff-id="tariffId"
        :tariff-value="burnRate" />
      </div>
    </div>

    <TariffRequestBar v-else />

    <DataModal
    :show-modal="showPaymentModal"
    @close="showPaymentModal = false">
      <template #modal-content>
        <div class="tariffs-screen__payment-modal">
          <span class="tariffs-screen__payment-title">
            Оплата тарифа
          </span>
          <span class="tariffs-screen__payment-description">
            Получите полный доступ ко всем функциям на месяц.
            Если в течение платного периода Вы запустите через нас рекламные кампании на сумму 100 000р. и более, следующий месяц доступа будет бесплатным
          </span>
          <span class="tariffs-screen__payment-price">
            25 000 ₽
          </span>
          <DataButton
          text="Оплатить"
          @handler="initPayment" />
        </div>
      </template>
    </DataModal>
  </div>
</template>

<script>
import getBaseUrl from '@/assets/js/helpers';
import TariffsBanner from './components/TariffsBanner.vue';
import TariffProgress from './components/TariffProgress.vue';
import TariffInfo from './components/TariffInfo.vue';
import TariffRequestBar from './components/TariffRequestBar.vue';

export default {
  components: {
    TariffsBanner, TariffProgress, TariffInfo, TariffRequestBar,
  },
  data: () => ({
    showTariffBanner: true,
    showPaymentModal: false,
    paymentWidget: null,
    tariffId: null,
    burnRate: null,
    expiredDate: null,
  }),
  created() {
    if (localStorage.getItem('hideTariffBlueBanner')) {
      this.showTariffBanner = false;
    }
    this.tariffId = this.getUserInfo?.asEmployerAgency?.currentTariff?.tariff?.id || null;
    this.burnRate = this.getUserInfo?.asEmployerAgency?.burnMarketRateAccount?.burnMarketRate || null;
    this.expiredDate = this.getUserInfo?.asEmployerAgency?.currentTariff?.expiredDateTime?.date || null;
  },
  methods: {
    closeTariffBanner() {
      localStorage.setItem('hideTariffBlueBanner', true);
      this.showTariffBanner = false;
    },
    initPayment() {
      this.showPaymentModal = false;
      const { cp } = window;
      if (!cp) {
        this.$store.dispatch('app/setSnackBar', { text: 'Оплата временно недоступна. Попробуйте позже', error: true });
        return;
      }
      this.paymentWidget = new cp.CloudPayments();
      const paymentParams = { currency: 'rub', amount: 25000 };
      this.$store.dispatch('tariffs/createPayment', paymentParams)
        .then((data) => {
          this.paymentWidget.pay('auth',
            {
              publicId: 'pk_9e9e8fd355f3cda57f67963894ec1',
              description: 'Оплата доступа к SocialData',
              amount: 25000,
              currency: 'RUB',
              accountId: this.getUserInfo.email,
              invoiceId: data.id,
              skin: 'mini',
            },
            {
              onSuccess: () => {
                this.$store.dispatch('app/setSnackBar', { text: 'Оплата успешно произведена' });
                setTimeout(() => {
                  window.open(getBaseUrl());
                }, 2000);
              },
            });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Оплата временно недоступна. Попробуйте позже', error: true });
        });
    },
    openPaymentModal() {
      this.showPaymentModal = true;
    },
  },
  computed: {
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
  },
};
</script>

<style lang="scss" scoped>
.tariffs-screen {
  padding: 20px;
  display: flex;
  flex-direction: column;
  @include desktop-screen {
    padding: 30px 30px;
  }
}

.tariffs-screen__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 50px;
  @include between-elements {
    margin-right: 20px;
  }
}

.tariffs-screen__title {
  color: #575757;
  font-size: 14px;
  font-size: 28px;
  font-weight: 600;
}

.tariffs-screen__tariffs {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 30px;
  }
}

.tariffs-screen__tariff-element {
  display: block;
  width: 100%;
  @include between-elements {
    margin-bottom: 10px;
  }
  @include max-screen {
    @include between-elements {
      margin-bottom: 0px;
    }
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
  }
}

.tariffs-screen__payment-modal {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  width: 680px;
  min-height: 350px;
}

.tariffs-screen__payment-title {
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 600;
  color: #4F4F4F;
}

.tariffs-screen__payment-description {
  font-size: 16px;
  margin-bottom: 80px;
  line-height: 22px;
  color: #4F4F4F;
  max-width: 500px;
}

.tariffs-screen__payment-price {
  font-size: 28px;
  color: $primary-blue;
  font-weight: 600;
  margin-bottom: 20px;
}
</style>
