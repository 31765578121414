import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    clientsData: [],
    fullClientsData: [],
    usersData: [],
    clientsLoading: false,
    withAccessToCatalog: true,
    withCampaign: null,
    clientName: '',
    loadParams: {
      'page-size': 50,
      page: 1,
    },
    totalPageCounts: null,
    adminsList: null,
  },
  getters: {
    getClientsData: (state) => state.clientsData,
    getTotalPageCounts: (state) => state.totalPageCounts,
    getIsLastPage: (state) => state.loadParams.page === +state.totalPageCounts + 1,
    getUsersData: (state) => state.usersData,
    getFullClientsData: (state) => state.fullClientsData,
  },
  mutations: {
    setClientsData: (state, value) => { state.clientsData = value; },
    setUsersData: (state, value) => { state.usersData = value; },
    setClientsLoading: (state, value) => { state.clientsLoading = value; },
    setTotalPageCounts: (state, value) => { state.totalPageCounts = value; },
    setLoadPage: (state, value) => { state.loadParams.page = value; },
    setAccessToCatalog: (state, value) => { state.withAccessToCatalog = value; },
    setWithCampaign: (state, value) => { state.withCampaign = value; },
    setFullClientsData: (state, value) => { state.fullClientsData = value; },
    setClientName: (state, value) => { state.clientName = value; },
  },
  actions: {
    // Сброс параметров поиска
    resetSearchParams({ commit }) {
      commit('setWithCampaign', null);
      commit('setClientName', null);
      commit('setAccessToCatalog', null);
    },

    // Получение списка обычных пользователей (физиков)
    getUsersData({ state, commit, dispatch }, params = { reset: false }) {
      if (params.reset) {
        commit('setUsersData', []);
        commit('setLoadPage', 1);
        commit('setTotalPageCounts', null);
      }
      return api.get('/user', { params: { ...state.loadParams } })
        .then((response) => {
          const usersData = params.reset ? response.data.data : [...state.usersData, ...response.data.data];
          commit('setUsersData', usersData);
          commit('setTotalPageCounts', response.headers['x-pagination-page-count']);
          commit('setLoadPage', state.loadParams.page + 1);
          return Promise.resolve(usersData);
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'getUsersData' }, { root: true });
          console.warn('Error in action -- getUsersData --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Получение списка агенств и клиентов в админке
    getClientsData({ state, commit, dispatch }, params = { reset: false }) {
      if (params.reset) {
        commit('setClientsData', []);
        commit('setLoadPage', 1);
        commit('setTotalPageCounts', null);
      }
      return api.get('/agency',
        {
          params:
          {
            isAgency: params.isAgency,
            withAccessToCatalog: state.withAccessToCatalog,
            withCampaign: state.withCampaign,
            name: state.clientName || null,
            ...state.loadParams,
          },
        })
        .then((response) => {
          const clientsData = params.reset ? response.data.data : [...state.clientsData, ...response.data.data];
          commit('setClientsData', clientsData);
          commit('setTotalPageCounts', response.headers['x-pagination-page-count']);
          commit('setLoadPage', state.loadParams.page + 1);
          return Promise.resolve(clientsData);
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'getClientsData' }, { root: true });
          console.warn('Error in action -- getClientsData --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Получение всех клиентов даты
    getFullClients({ commit, dispatch }) {
      return api.get('/agency')
        .then(({ data: { data } }) => {
          commit('setFullClientsData', data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'getFullClients' }, { root: true });
          console.warn('Error in action -- getFullClients --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Создание агенства из админки
    createAgency({ state, commit, dispatch }, client) {
      return api.post('/agency', client)
        .then(({ data: { data } }) => {
          commit('setClientsData', [...state.clientsData, data]);
          return Promise.resolve(data);
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'createAgency' }, { root: true });
          console.warn('Error in action -- createAgency--; Error -', error);
          return Promise.reject();
        });
    },

    // Самостоятельная регистрация агентства/клиента
    registrateAgency({ commit, dispatch }, client) {
      commit('app/setAppIsLoading', true, { root: true });
      return api.post('/agency/registration', client)
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'registrateAgency' }, { root: true });
          console.warn('Error in action -- registrateAgency--; Error -', error);
          return Promise.reject();
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },

    // Обновление клиента/агентства из админки
    updateAgency({ state, commit, dispatch }, params) {
      return api.patch(`/agency/${params.id}`, params.payload)
        .then(({ data: { data } }) => {
          const newData = state.clientsData.map((item) => {
            if (item.id === params.id) {
              return data;
            }
            return item;
          });
          commit('setClientsData', newData);
          Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'updateAgency' }, { root: true });
          console.warn('Error in action -- updateAgency --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Удаление агентства/клиента из админки
    deleteClientFromAdmin({ state, commit, dispatch }, clientId) {
      return api.patch('/agency/archive', { agencyId: clientId })
        .then(() => {
          const newData = state.clientsData.filter((item) => item.id !== clientId);
          commit('setClientsData', newData);
          Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'deleteClientFromAdmin' }, { root: true });
          console.warn('Error in action -- deleteClientFromAdmin --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Удаление агентства/клиента из админки
    deleteClientFromAgency({ state, commit, dispatch }, params) {
      return api.patch('/agency/delete-client', params)
        .then(() => {
          const newData = state.clientsData.filter((item) => item.id !== params.userId);
          commit('setClientsData', newData);
          Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'deleteClientFromAdmin' }, { root: true });
          console.warn('Error in action -- deleteClientFromAgency --; Error -', error);
          return Promise.reject(error);
        });
    },

    // создание сотрудника и добавление его в агентство
    createUserAndAddToAgency({ state, commit, dispatch }, userData) {
      return api.post('/agency/create-user-and-add', userData)
        .then(({ data: { data } }) => {
          const newData = state.clientsData.map((item) => {
            if (item.id === userData.agencyId) {
              item.employees.push(data);
            }
            return item;
          });
          commit('setClientsData', newData);
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'createUserAndAddToAgency' }, { root: true });
          console.warn('Error in action -- createUserAndAddToAgency --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Удалить сотрудника из агентства
    deleteEmployeeFromAgency({ state, commit, dispatch }, params) {
      return api.patch('/agency/delete-employee', { agencyId: params.agencyId, userId: params.userId })
        .then(() => {
          const newClients = state.clientsData.map((client) => {
            if (client.id === params.agencyId) {
              const newUsers = client.employees.filter((user) => user.id !== params.userId);
              client.employees = newUsers;
            }
            return client;
          });
          commit('setClientsData', newClients);
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'deleteUserFromAgency' }, { root: true });
          console.warn('Error in action -- deleteUserFromAgency --; Error -', error);
          return Promise.reject(error);
        });
    },

    // получить список клиентов агенства
    getAgencyClients({ state, commit, dispatch }, params = { reset: false }) {
      if (params.reset) {
        commit('setClientsData', []);
        commit('setLoadPage', 1);
        commit('setTotalPageCounts', null);
      }
      return api.get(`/agency/${params.id}/clients`, { params: { ...state.loadParams, withAccessToCatalog: state.withAccessToCatalog } })
        .then((response) => {
          const clientsData = params.reset ? response.data.data : [...state.clientsData, ...response.data.data];
          commit('setClientsData', clientsData);
          commit('setTotalPageCounts', response.headers['x-pagination-page-count']);
          commit('setLoadPage', state.loadParams.page + 1);
          return Promise.resolve(clientsData);
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'getAgencyClients' }, { root: true });
          console.warn('Error in action -- getAgencyClients --; Error -', error);
          return Promise.reject(error);
        });
    },

    // создать клиента в агенстве (из под агенства)
    createClientAndAddToAgency({ state, commit, dispatch }, clientData) {
      return api.post('/agency/create-client-and-add', clientData)
        .then(({ data: { data } }) => {
          commit('setClientsData', [data, ...state.clientsData]);
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'createClientAndAddToAgency' }, { root: true });
          console.warn('Error in action -- createClientAndAddToAgency --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Редактирование агенством доступа своего клиента
    setAccessClientAgency({ state, commit, dispatch }, params) {
      return api.post(`/user/${params.id}/set-access`, { isAccess: params.isAccess })
        .then(({ data: { data } }) => {
          const newData = state.clientsData.map((item) => {
            if (item.id === params.id) {
              return data;
            }
            return item;
          });
          commit('setClientsData', newData);
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'setAccessClientAgency' }, { root: true });
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- setAccessClientAgency --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Редактирование сотрудника агентства
    updateEmployeeFromAgency({ state, commit, dispatch }, userInfo) {
      commit('app/setAppIsLoading', true, { root: true });
      return api.post('/user/edit', userInfo)
        .then(({ data: { data } }) => {
          const agencyId = data.asEmployerAgency?.id || null;
          state.clientsData.forEach((item) => {
            if (item.id === agencyId) {
              item.employees = item.employees.map((employee) => {
                if (employee.id === data.id) {
                  return data;
                }
                return employee;
              });
            }
          });
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'updateEmployeeFromAgency' }, { root: true });
          console.warn('Error in action -- updateEmployeeFromAgency --; Error -', error);
          return Promise.reject(error);
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },

    // Редактирование клиента агенства (агенством)
    updateClientAgency({ state, commit, dispatch }, userInfo) {
      commit('app/setAppIsLoading', true, { root: true });
      return api.post('/user/edit', userInfo)
        .then(({ data: { data } }) => {
          state.clientsData.map((item) => {
            if (item.id === userInfo.id) {
              return data;
            }
            return item;
          });
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'updateClientAgency' }, { root: true });
          console.warn('Error in action -- updateClientAgency --; Error -', error);
          return Promise.reject(error);
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },

    // Обновление списка аккаунт менеджеров в клиенте
    updateAccountManagers({ dispatch }, params) {
      return api.post('/agency/update-responsible-users', params)
        .then(() => Promise.resolve())
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'updateResponsibleUsers' }, { root: true });
          console.warn('Error in action -- updateResponsibleUsers --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Обновление списка sales менеджеров
    updateSalesManagers({ dispatch }, params) {
      return api.post('/agency/update-responsible-sales-manager-users', params)
        .then(() => Promise.resolve())
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'updateResponsibleUsers' }, { root: true });
          console.warn('Error in action -- updateResponsibleUsers --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Изменение баланса клиента
    setIncomeClientBalance({ dispatch }, params) {
      return api.post('/account/income-balance', params)
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'setIncomeClientBalance' }, { root: true });
          console.warn('Error in action -- setIncomeClientBalance --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Получение конкретого агенства (клиента)
    getCurrentAgency({ dispatch }, id) {
      return api.get(`/agency/${id}`)
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'getCurrentAgency' }, { root: true });
          console.warn('Error in action -- getCurrentAgency --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Получение истории транзакций
    getBalanceHistory({ dispatch }, payload) {
      return api.get('/account/transactions-history', { params: { ...payload } })
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'getBalanceHistory' }, { root: true });
          console.warn('Error in action -- getBalanceHistory --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Вкл/выкл депозитной системы
    updateHasBalance({ state, commit }, params) {
      return api.post('/account/update-has-balance', params)
        .then(({ data: { data } }) => {
          state.clientsData.forEach((item) => {
            if (item.id === params.agencyId) {
              item.hasBalance = params.hasBalance;
            }
          });
          return Promise.resolve(data);
        })
        .catch((error) => {
          // dispatch('app/createApiErrorMessage', { error, action: 'updateHasBalance' }, { root: true });
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- updateHasBalance --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Получение истории транзакций
    downloadStatisticReport({ dispatch }, params) {
      return api.get('/agency/get-statistic-export', { responseType: 'arraybuffer', params: { ...params } })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Статистика ${params.name}.xlsx`);
          document.body.appendChild(link);
          link.click();
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'downloadStatisticReport' }, { root: true });
          console.warn('Error in action -- downloadStatisticReport --; Error -', error);
          return Promise.reject(error);
        });
    },
  },
};
