const eventTypes = {
  // Кампании
  campaignCreated: {
    name: 'Создана новая рекламная кампания: (request)',
    description: 'Нажмите, чтобы перейти в список кампаний',
    type: 'campaign',
    icon: 'PlusCircle-blue',
    color: '#eaf9ff',
  },
  campaignCompleted: {
    name: 'Рекламная кампания (request) завершена',
    description: 'Нажмите, чтобы перейти в список кампаний',
    type: 'campaign',
    icon: 'CheckCircle-blue',
    color: '#eaf9ff',
  },
  // Запрос
  requestPlacementCreated: {
    name: 'Создан новый запрос: (request)',
    description: 'Нажмите, чтобы посмотреть детали запроса',
    type: 'request',
    icon: 'PlusCircle',
    color: '#e8ffe9',
  },
  requestPlacementUpdated: {
    name: 'Запрос (request) обновлен',
    description: 'Нажмите, чтобы посмотреть детали запроса',
    type: 'request',
    icon: 'PencilSimpleLine',
    color: '#e8ffe9',
  },
  requestPlacementRework: {
    name: 'В запросе (request) есть изменения',
    description: 'Нажмите, чтобы посмотреть детали запроса',
    type: 'request',
    icon: 'PencilSimpleLine',
    color: '#e8ffe9',
  },
  requestPlacementCommented: {
    name: 'Новый комментарий в запросе (request)',
    description: 'Нажмите, чтобы посмотреть детали запроса',
    type: 'request',
    icon: 'ChatCircleDots',
    color: '#e8ffe9',
  },
  requestPlacementCompleted: {
    name: 'Запрос (request) готов',
    description: 'Нажмите, чтобы посмотреть детали запроса',
    type: 'request',
    icon: 'CheckCircle',
    color: '#e8ffe9',
  },
  requestPlacementSetInProgress: {
    name: 'Запрос (request) взят в работу',
    description: 'Нажмите, чтобы посмотреть детали запроса',
    type: 'request',
    icon: 'Spinner',
    color: '#e8ffe9',
  },
};

export default eventTypes;
