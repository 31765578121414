<template>
  <div class="current-client-statistic-modal">
    <span class="title">{{ client.name }}</span>
    <div
    v-if="isLoading"
    class="loader">
      <img src="@/assets/svg/spinner-loader.svg">
    </div>
    <div
    v-else
    class="content">
      <!-- Табы -->
      <div class="tabs">
        <span
        class="tab"
        :class="selectedTab === 'placements' && 'tab_active'"
        @click="selectedTab = 'placements'">{{ `Запросы (${placements.data.length})` }}</span>
        <span
        class="tab"
        :class="selectedTab === 'campaigns' && 'tab_active'"
        @click="selectedTab = 'campaigns'">{{ `Кампании (${campaigns.data.length})` }}</span>
      </div>

      <!-- Запросы -->
      <div
      v-if="selectedTab === 'placements'"
      class="content">
        <div
        v-if="placements.data.length"
        class="placements">
          <div class="placements__headers">
            <span
            v-for="header in placements.headers"
            :key="header.name"
            :style="`width: ${header.width}px`"
            class="placements__header">
              {{ header.name }}
            </span>
          </div>
          <div class="placements__content">
            <div
            v-for="(placement, index) in placements.data"
            :key="placement.id"
            :class="index % 2 === 0 && 'placements__item_filled'"
            class="placements__item">
              <span class="placement__name">
                {{ placement.name }}
              </span>
              <span class="placement__column">
                {{ placement.createdDate }}
              </span>
              <span class="placement__column">
                {{ placement.updatedDate }}
              </span>
              <span class="placement__column">
                {{ placement.budget }}
              </span>
            </div>
          </div>
        </div>
        <div
        v-else
        class="empty-content">
          <span>Список пуст</span>
        </div>
      </div>

      <!-- Кампании -->
      <div
      v-if="selectedTab === 'campaigns'"
      class="content">
        <div
        v-if="campaigns.data.length"
        class="placements">
          <div class="placements__headers">
            <span
            v-for="header in campaigns.headers"
            :key="header.name"
            :style="`width: ${header.width}px`"
            class="placements__header">
              {{ header.name }}
            </span>
          </div>
          <div class="placements__content">
            <div
            v-for="(campaign, index) in campaigns.data"
            :key="campaign.id"
            :class="index % 2 === 0 && 'placements__item_filled'"
            class="placements__item">
              <span class="placement__name">
                {{ campaign.name }}
              </span>
              <span class="placement__column">
                {{ campaign.createdDate }}
              </span>
              <span class="placement__column">
                {{ campaign.updatedDate }}
              </span>
              <span class="placement__column">
                {{ campaign.amount }}
              </span>
            </div>
          </div>
        </div>
        <div
        v-else
        class="empty-content">
          <span>Список пуст</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getClientPlacements, getClientCampaigns } from '../api';

export default {
  name: 'CurrentClientStatisticModal',
  props: {
    client: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    placements: {
      data: [],
      error: false,
      headers: [
        { name: 'Название запроса', value: 'name', width: 380 },
        { name: 'Дата создания', value: 'create', width: 250 },
        { name: 'Дата обновления', value: 'update', width: 250 },
        { name: 'Бюджет', value: 'budget', width: 250 },
      ],
    },
    campaigns: {
      data: [],
      error: false,
      headers: [
        { name: 'Название кампании', value: 'name', width: 380 },
        { name: 'Дата создания', value: 'create', width: 250 },
        { name: 'Дата обновления', value: 'update', width: 250 },
        { name: 'Сумма', value: 'amount', width: 250 },
      ],
    },
    selectedTab: 'placements',
  }),
  created() {
    this.isLoading = true;
    this.getData();
  },
  methods: {
    getClientPlacements,
    getClientCampaigns,
    getData() {
      const params = { ...this.filters, agencyName: this.client.name };
      // Запросы
      getClientPlacements(params)
        .then(({ data: { data } }) => {
          this.placements.data = this.mapPlacementsData(data);
        })
        .catch(() => {
          this.placements.error = true;
        })
        .finally(() => {
          this.isLoading = false;
        });

      // Кампании
      getClientCampaigns(params)
        .then(({ data: { data } }) => {
          this.campaigns.data = this.mapCampaignsData(data);
        })
        .catch(() => {
          this.campaigns.error = true;
        });
    },
    mapPlacementsData(data) {
      return data.map((item) => {
        const createdDate = item.createdAt?.date ? this.moment(item.createdAt.date).format('LLL') : 'Дата не указана';
        const updatedDate = item.updatedAt?.date ? this.moment(item.updatedAt.date).format('LLL') : 'Дата не указана';
        const budget = item.budget ? `${item.budget} ₽` : 'Бюджет не указан';
        return {
          name: item.name || 'Без названия',
          createdDate,
          updatedDate,
          budget,
        };
      });
    },
    mapCampaignsData(data) {
      return data.map((item) => {
        const createdDate = item.createdAt?.date ? this.moment(item.createdAt.date).format('LLL') : 'Дата не указана';
        const updatedDate = item.updatedAt?.date ? this.moment(item.updatedAt.date).format('LLL') : 'Дата не указана';
        const amount = item.amount?.amount ? `${item.amount?.amount} ₽` : 'Не указано';
        return {
          name: item.name || 'Без названия',
          createdDate,
          updatedDate,
          amount,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.current-client-statistic-modal {
  width: 80vw;
  height: 70vh;
  max-width: 1400px;
  max-height: 2000px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;

  .title {
    color: $primary-gray;
    font-size: 20px;
    margin-bottom: 20px;
    display: block;
  }

  .loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    @include between-elements {
      margin-right: 20px;
    }
  }

  .tab {
    cursor: pointer;
    padding: 10px 20px;
    background: rgba(#D8F2FA, 0.6);
    border-radius: 6px;
    &_active {
      background: $primary-blue;
      color: white;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .placements__headers {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    box-sizing: border-box;
    width: 100%;
    padding-left: 20px;
  }

  .placements__header {
    display: block;
    color: $primary-lightgray;
    font-size: 14px;
  }

  .placements {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .placements__content {
    height: 100%;
    overflow: auto;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .placements__item {
    min-height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    &_filled {
      background: rgba(#D8F2FA, 0.6);
      border-radius: 12px;
    }
  }

  .placement__name {
    display: block;
    width: 400px;
    padding-left: 20px;
    padding-right: 30px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .placement__column {
    width: 250px;
    font-size: 14px;
    color: $primary-gray;
  }

  .empty-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: 1;
    min-height: 100%;
    width: 100%;
    padding-bottom: 50px;
    color: $primary-lightgray;
    font-size: 16px;
  }
}
</style>
