<template>
  <div class="responsible-modal">
    <span class="responsible-modal__title">Выберите ответственного</span>
    <DataSelect
    v-model="selectedManager"
    :options="realisationsManagersList"
    :error="error && !selectedManager"
    placeholder="Выбрать ответственного" />
    <div class="responsible-modal__buttons">
      <DataButton
      text="Создать"
      @handler="setResponsibleUsers" />
      <DataButton
      text="Отменить"
      type="outlined"
      @handler="$emit('close')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SetResponsibleModal',
  props: {
    // Объект подборки
    compilationInfo: {
      type: Object,
      default: null,
    },
    // Выбранные каналы для работы с ними (перемещение, удаление из папок и тд)
    selectedGroups: {
      type: Array,
      default: null,
    },
    // Список менеджеров
    managers: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    selectedManager: null,
    error: false,
  }),
  methods: {
    setResponsibleUsers() {
      if (!this.selectedManager) {
        this.error = true;
        return;
      }
      if (this.selectedManager.value === 'novalue') {
        this.selectedRealisationManager = null;
        const params = {
          compilationId: this.compilationInfo.id,
          positionsIds: this.selectedGroups.map((item) => item.positionId),
        };
        this.$store.dispatch('compilations/deleteResponsibleInPosition', params)
          .then(() => {
            this.$store.dispatch('app/setSnackBar', { text: 'Ответственный удален' });
          })
          .finally(() => {
            this.$emit('close');
          });
        return;
      }
      const params = {
        compilationId: this.compilationInfo.id,
        positionsIds: this.selectedGroups.map((item) => item.positionId),
        responsibleUserId: this.selectedManager.value,
        responsibleUserObject: this.managers.find((item) => item.id === this.selectedManager.value),
      };
      this.$store.dispatch('compilations/changeResponsibleInPosition', params)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Ответственный установлен' });
        })
        .finally(() => {
          this.$emit('close');
        });
    },
  },
  computed: {
    realisationsManagersList() {
      const managers = this.managers || [];
      const novariant = { name: 'Не указан', value: 'novalue' };
      const list = managers.filter((item) => item.name && item.id).map((item) => ({ name: item.name, value: item.id }));
      return list.concat(novariant);
    },
  },

};
</script>

<style lang="scss" scoped>
.responsible-modal {
  max-width: 450px;
  width: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.responsible-modal__title {
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.responsible-modal__buttons {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  @include between-elements {
    margin-bottom: 20px;
  }
  @include tablet-screen {
    display: flex;
    justify-content: space-between;
    @include between-elements {
      margin-bottom: 0px;
    }
  }
}
</style>
