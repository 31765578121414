<template>
  <div class="add-client">
    <!-- ФОРМА ДОБАВЛЕНИЯ/РЕДАКТИРОВАНИЯ КЛИЕНТА -->
    <div
    v-if="selectedModalType === 'addClient'"
    class="add-client__container">
      <span class="add-client__text add-client__text_title">Добавление пользователя</span>
      <div
      v-if="isAdmin"
      class="add-client__input-element add-client__input-element_select">
        <div class="add-client__company-block">
          <span
          v-if="isAdmin"
          class="add-client__text add-client__text_light"
          :class="{'add-client__text_error': !selectedCompany && clientForm.companyError }">
            {{ !selectedCompany && clientForm.companyError ? 'Выберите или создайте компанию' : 'Выберите компанию' }}
          </span>
          <span
          v-else
          class="add-client__text add-client__text_light">
            Название компании
          </span>
          <span
          class="add-client__text add-client__text_blue"
          @click="selectedModalType = 'addCompany'">Создать компанию</span>
        </div>
        <DataSelect
        v-model="selectedCompany"
        :options="getClientsData"
        with-search
        placeholder="Не выбрано" />
      </div>
      <span class="add-client__text add-client__text_subtitle">Данные пользователя</span>
      <div class="add-client__client-inputs">
        <!-- Название компании (для агенства) -->
        <div
        v-if="isAgency && !isAdmin"
        class="add-client__client-agency-inputs"
        style="margin-bottom: 0px">
          <div class="add-client__input-element">
            <span
            class="add-client__text add-client__text_light"
            :class="{'add-client__text_error': clientForm.companyNameError }">
              {{ clientForm.companyNameError ? 'Введите название компании' : 'Название компании' }}
            </span>
            <input
            v-model.trim="clientForm.companyName"
            placeholder="Название компании"
            class="add-client__input"
            :class="clientForm.companyNameError && 'add-client__input_error'">
          </div>
          <div
          class="add-client__input-element"
          style="width: 120px">
            <span class="add-client__text add-client__text_light">Комиссия</span>
            <DataInput
            v-model.trim="clientForm.baseCommissionInPercent"
            type="text"
            :error="clientForm.baseCommissionInPercentError" />
            <span class="add-client__input-percent">%</span>
          </div>
        </div>

        <!-- ФИО -->
        <div
        class="add-client__input-element">
          <span
          class="add-client__text add-client__text_light"
          :class="{'add-client__text_error': clientForm.fioError }">
            {{ clientForm.fioError ? 'Введите корректные данные ФИО' : 'ФИО' }}
          </span>
          <input
          v-model.trim="clientForm.fio"
          placeholder="ФИО"
          class="add-client__input"
          :class="clientForm.fioError && 'add-client__input_error'">
        </div>

        <!-- Email -->
        <div
        class="add-client__input-element">
          <span
          class="add-client__text add-client__text_light"
          :class="{'add-client__text_error': clientForm.emailError }">
            {{ clientForm.emailError ? 'Введите корректный email' : 'Почта' }}
          </span>
          <input
          v-model.trim="clientForm.email"
          placeholder="mail@gmail.com"
          class="add-client__input"
          :class="clientForm.emailError && 'add-client__input_error'">
        </div>

        <!-- Пароль -->
        <div
        class="add-client__input-element">
          <span
          class="add-client__text add-client__text_light"
          :class="{'add-client__text_error': clientForm.passwordError }">
            {{ clientForm.passwordError ? 'Введите пароль не менее 6 символов' : 'Пароль' }}
          </span>
          <input
          ref="clientPassword"
          v-model.trim="clientForm.password"
          type="password"
          placeholder="Задайте пароль"
          class="add-client__input"
          :class="clientForm.passwordError && 'add-client__input_error'">
          <DataIcon
          name="visibility"
          class="add-client__pass-icon"
          :size="20"
          pointer
          @mouseup.native="$refs.clientPassword.type= 'password'"
          @mousedown.native="$refs.clientPassword.type = 'text'" />
        </div>
      </div>
      <div class="add-client__buttons">
        <DataButton
        text="Добавить"
        width="full"
        :height="48"
        @handler="addClient" />
        <DataButton
        text="Отмена"
        width="full"
        :height="48"
        type="outlined"
        @handler="$emit('close')" />
      </div>
    </div>

    <!-- ФОРМА ДОБАВЛЕНИЯ/РЕДАКТИРОВАНИЯ КОМПАНИИ -->
    <div
    v-if="selectedModalType === 'addCompany'"
    class="add-client__container">
      <span class="add-client__text add-client__text_title">{{ isEditCompany ? 'Редактирование клиента' : 'Добавление пользователя' }}</span>
      <span
      v-if="!isEditCompany"
      class="add-client__text add-client__text_subtitle">{{ labelForCompany }}</span>
      <div class="add-client__client-agency-inputs">
        <div class="add-client__input-element">
          <span
          class="add-client__text add-client__text_light"
          :class="{ 'add-client__text_error': companyForm.nameError }">Название</span>
          <DataInput
          v-model.trim="companyForm.name"
          placeholder="Название компании"
          type="text"
          :error="companyForm.nameError" />
        </div>
        <div
        class="add-client__input-element"
        style="width: 120px">
          <span class="add-client__text add-client__text_light">Комиссия</span>
          <DataInput
          v-model.trim="companyForm.baseCommissionInPercent"
          type="text"
          :error="companyForm.baseCommissionInPercentError" />
          <span class="add-client__input-percent">%</span>
        </div>
      </div>
      <div
      v-if="isAdmin"
      class="add-client__toggles-block">
        <div
        class="add-client__input-percent__toggle">
          <DataToggle v-model="companyForm.isAgency" />
          <span class="add-client__text">Агентство</span>
        </div>
        <div class="add-client__input-percent__toggle">
          <DataToggle v-model="companyForm.allowAccess" />
          <span class="add-client__text">{{ labelForAccess }}</span>
        </div>
        <div
        v-if="companyForm.isAgency"
        class="add-client__input-percent__toggle">
          <DataToggle v-model="companyForm.hideTradeOfferWithOurBuyerType" />
          <span class="add-client__text">
            Скрыть прайс
          </span>
        </div>
      </div>
      <div
      v-if="isAdmin"
      class="add-client__agency-toggles">
        <div class="add-client__input-percent__toggle">
          <DataToggle v-model="companyForm.isSendRequestPlacementsToCrm" />
          <span class="add-client__text">
            {{ companyForm.isSendRequestPlacementsToCrm? 'Запросы клиентов приходят к нам' : 'Запросы клиентов приходят агентству' }}
          </span>
        </div>
        <div class="add-client__input-percent__toggle">
          <DataToggle v-model="companyForm.depositeSystem" />
          <span class="add-client__text">
            Депозитная система
          </span>
        </div>
      </div>
      <div
      v-if="companyForm.allowAccess"
      class="add-client__edit-date">
        <div class="add-client__input-element">
          <span class="add-client__text add-client__text_light">Кол-во дней</span>
          <DataInput
          :value="companyForm.accessDays"
          type="number"
          max="365"
          @input="changeAccessDate($event, 'days')" />
        </div>
        <div class="add-client__input-element">
          <span class="add-client__text add-client__text_light">Доступ до</span>
          <DataDatePicker
          :value="companyForm.accessDaysDate"
          input-type="date"
          @change="changeAccessDate($event, 'picker')" />
        </div>
      </div>
      <div
      v-if="isAdmin"
      style="margin-bottom: 20px"
      class="add-client__input-element">
        <span
        class="add-client__text add-client__text_light"
        :class="{ 'add-client__text_error': companyForm.selectedManagersError }">Аккаунт менеджер</span>
        <DataSelect
        ref="accountSelect"
        v-model="companyForm.selectedManagers"
        :options="managersList"
        :max-elements="getMaxSelectElements"
        :error="companyForm.selectedManagersError"
        select-type="multi"
        placeholder="Выберите менеджеров"
        @selectOption="selectAccount" />
      </div>
      <div
      v-if="isAdmin"
      class="add-client__input-element">
        <span
        class="add-client__text add-client__text_light"
        :class="{ 'add-client__text_error': companyForm.selectedSalesError }">Выбрать сейлза</span>
        <DataSelect
        ref="salesSelect"
        v-model="companyForm.selectedSales"
        :options="salesManagers"
        :max-elements="getMaxSelectElements"
        :error="companyForm.selectedSalesError"
        select-type="multi"
        placeholder="Выберите сейлза" />
      </div>

      <!-- Buttons -->
      <div class="add-client__buttons">
        <DataButton
        :text="isEditCompany ? 'Обновить': 'Создать'"
        width="full"
        :height="48"
        @handler="companyHandler" />
        <DataButton
        text="Закрыть"
        width="full"
        :height="48"
        type="outlined"
        @handler="isEditCompany ? $emit('close') : selectedModalType = 'addClient'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddClientModal',
  props: {
    // Передача конкретного клиента для редактирования
    currentClient: {
      type: Object,
      default: null,
    },
    // Режим редактирования клиента
    isEditCompany: {
      type: Boolean,
      default: null,
    },
    // Пользователь с правами админа
    isAdmin: {
      type: Boolean,
      default: false,
    },
    // Пользователь сотрудник агентства
    isAgency: {
      type: Boolean,
      default: false,
    },
    // Тип компонента - addCompany / addClient
    modalType: {
      type: String,
      default: 'addClient',
    },
    accountManagers: {
      type: Array,
      default: null,
    },
    salesManagers: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    clientForm: null,
    companyForm: null,
    isLoading: false,
    selectedModalType: 'addClient',
    selectedCompany: null,
    managersList: [],
  }),
  created() {
    this.initForm();
    if (this.isAdmin) {
      this.initManagersList();
    }
  },
  methods: {
    initForm() {
      this.clientForm = {
        companyName: '',
        companyNameError: false,
        fio: '',
        fioError: false,
        email: '',
        emailError: false,
        password: '',
        passwordError: false,
        baseCommissionInPercent: '25',
        baseCommissionInPercentError: false,
        accessDays: '30',
        companyError: false,
      };
      this.companyForm = {
        name: this.isEditCompany && this.currentClient?.name ? this.currentClient.name : '',
        nameError: false,
        baseCommissionInPercent: this.isEditCompany && this.currentClient?.baseCommissionInPercent ? this.currentClient.baseCommissionInPercent : '25',
        baseCommissionInPercentError: false,
        allowAccess: this.isEditCompany ? this.currentClient?.currentTariff?.tariff?.isAccessToCatalog : true,
        accessDays: '30',
        accessDaysDate: null,
        accessDaysError: false,
        isAgency: this.isEditCompany ? this.currentClient.isAgency : false,
        isSendRequestPlacementsToCrm: this.isEditCompany ? this.currentClient.isSendRequestPlacementsToCrm : true,
        hideTradeOfferWithOurBuyerType: this.isEditCompany ? this.currentClient.hideTradeOfferWithOurBuyerType : false,
        selectedManagers: [],
        selectedManagersError: false,
        selectedSales: [],
        selectedSalesError: false,
        depositeSystem: this.isEditCompany && this.currentClient.hasBalance,
      };

      if (this.isEditCompany) {
        this.companyForm.selectedManagers = this.currentClient.responsibleUsers
          ? this.currentClient.responsibleUsers.map((item) => ({ name: item.name || item.email || 'Без имени', value: item.id }))
          : [{ name: 'Доступен всем менеджерам', value: 'all' }];
        this.companyForm.selectedSales = this.currentClient.responsibleSalesManagerUsers
          ? this.currentClient.responsibleSalesManagerUsers.map((item) => ({ name: item.name || item.email, value: item.id }))
          : [];
      }

      this.companyForm.accessDaysDate = this.moment(new Date()).add(+this.companyForm.accessDays, 'd').format('YYYY-MM-DD');

      this.isLoading = false;
      this.selectedCompany = null;
      this.selectedModalType = this.modalType || 'addClient';
      if (this.isEditCompany) {
        let expiredDate;
        if (this.isAgency || this.isAdmin) {
          expiredDate = this.currentClient?.currentTariff?.expiredDateTime?.date || false;
        } else {
          expiredDate = this.getUserInfo?.currentTariff?.expiredDateTime || false;
        }
        if (expiredDate) {
          const date = this.moment(expiredDate).format('YYYY-MM-DD');
          this.changeAccessDate(date, 'picker');
          this.companyForm.accessDaysDate = date;
        }
      }
    },
    addClient() {
      if (!this.checkClientForm()) return;
      if (this.isAdmin) {
        this.createEmployeeAndAddToAgency();
        return;
      }
      if (this.isAgency) {
        this.addClientFromAgency();
      }
    },
    // Создание агенством своего клиента
    addClientFromAgency() {
      const agencyId = this.$store.getters['user/getUserInfo']?.asEmployerAgency?.id;
      const userData = {
        companyName: this.clientForm.companyName,
        login: this.clientForm.email,
        password: this.clientForm.password,
        agencyId,
        name: this.clientForm.fio,
        baseCommissionInPercent: this.clientForm.baseCommissionInPercent,
      };

      this.$store.dispatch('clients/createClientAndAddToAgency', userData)
        .then(() => {
          this.$emit('close');
          setTimeout(() => {
            this.$store.dispatch('app/setSnackBar', { text: 'Клиент создан' });
          }, 500);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // Создание админом сотрудника какого-то агенства/клиента
    createEmployeeAndAddToAgency() {
      this.isLoading = true;
      const userData = {
        login: this.clientForm.email, password: this.clientForm.password, agencyId: this.selectedCompany.value, name: this.clientForm.fio,
      };
      this.$store.dispatch('clients/createUserAndAddToAgency', userData)
        .then(() => {
          this.$emit('close');
          setTimeout(() => {
            this.$store.dispatch('app/setSnackBar', { text: 'Клиент создан' });
          }, 500);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    checkClientForm() {
      if (this.isAdmin) {
        this.clientForm.companyError = !this.selectedCompany;
      }
      if (this.isAgency && !this.isAdmin) {
        this.clientForm.companyNameError = this.clientForm.companyName.length < 3;
      }
      this.clientForm.fioError = this.clientForm.fio.length < 3;
      // eslint-disable-next-line max-len
      this.clientForm.emailError = !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(this.clientForm.email.toLowerCase());
      this.clientForm.passwordError = this.clientForm.password.length < 6;
      this.clientForm.baseCommissionInPercentError = !this.clientForm.baseCommissionInPercent.length;
      if (this.clientForm.fioError
      || this.clientForm.companyNameError
      || this.clientForm.emailError
      || this.clientForm.passwordError
      || this.clientForm.baseCommissionInPercentError
      || this.clientForm.companyError) {
        return false;
      }
      return true;
    },
    checkCompanyForm() {
      this.companyForm.nameError = !this.companyForm.name.length;
      this.companyForm.selectedManagersError = !this.companyForm.selectedManagers.length;
      this.companyForm.selectedSalesError = !this.companyForm.selectedSales.length;
      this.companyForm.baseCommissionInPercentError = !this.companyForm.baseCommissionInPercent;
      if (this.companyForm.allowAccess) {
        this.companyForm.accessDaysError = !this.companyForm.accessDays.length || +this.companyForm.accessDays > 365;
        if (+this.companyForm.accessDays > 365) {
          this.$store.dispatch('app/setSnackBar', { text: 'Максимальное количество дней доступа - 1 год (365 дней)', error: true });
        }
      }
      return ![
        this.companyForm.nameError,
        this.companyForm.baseCommissionInPercentError,
        this.companyForm.accessDaysError,
        this.companyForm.selectedManagersError,
        this.companyForm.selectedSalesError].some((item) => item === true);
    },
    companyHandler() {
      if (!this.checkCompanyForm() || this.isLoading) return;
      if (this.isEditCompany) {
        this.updateCompany();
        return;
      }
      this.createCompany();
    },
    createCompany() {
      // this.isLoading = true;
      // если выбрано количество дней доступа - передаем это значение, если нет, устанавливаем базово 30 дней
      const client = {
        name: this.companyForm.name,
        baseCommissionInPercent: this.companyForm.baseCommissionInPercent,
        isAgency: this.companyForm.isAgency,
        responsibleUsersIds: this.companyForm.selectedManagers.filter((item) => item.value !== 'all').map((item) => item.value),
        responsibleSalesManagerUsersIds: this.companyForm.selectedSales.map((item) => item.value),
      };

      if (this.companyForm.allowAccess) {
        const expiredDate = this.moment(this.companyForm.accessDaysDate).format('YYYY-MM-DD');
        //
        client.openAccess = true;
        client.accessExpiredDate = expiredDate;
      }
      this.$store.dispatch('clients/createAgency', client)
        .then((data) => {
          this.initForm();
          this.selectedCompany = { name: data.name, value: data.id };
          this.$store.dispatch('app/setSnackBar', { text: 'Компания создана' });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateCompany() {
      if (this.isLoading) return;
      this.isLoading = true;

      const params = {
        id: this.currentClient.id,
        payload: {
          name: this.companyForm.name,
          baseCommissionInPercent: this.companyForm.baseCommissionInPercent,
          openAccess: this.companyForm.allowAccess,
          isAgency: this.companyForm.isAgency,
        },
      };

      if (this.companyForm.allowAccess) {
        const expiredDate = this.moment(this.companyForm.accessDaysDate).format('YYYY-MM-DD');
        params.payload.accessExpiredDate = expiredDate;
      }

      if (this.companyForm.isAgency) {
        params.payload.isSendRequestPlacementsToCrm = this.companyForm.isSendRequestPlacementsToCrm;
        params.payload.hideTradeOfferWithOurBuyerType = this.companyForm.hideTradeOfferWithOurBuyerType;
      }

      const allManagers = this.companyForm.selectedManagers.some((item) => item.value === 'all');

      if (!allManagers && this.companyForm.selectedManagers.length) {
        params.payload.responsibleUsersIds = this.companyForm.selectedManagers.map((item) => item.value);
        params.payload.responsibleSalesManagerUsersIds = this.companyForm.selectedSales.map((item) => item.value);
      }

      // Обновление данных клиента
      this.$store.commit('app/setAppIsLoading', true);
      this.$store.dispatch('clients/updateAgency', params)
        .then(() => {
          // Обновление депозитной системы
          if (this.companyForm.depositeSystem !== this.currentClient.hasBalance) {
            const depositeParams = { agencyId: this.currentClient.id, hasBalance: this.companyForm.depositeSystem };
            this.$store.dispatch('clients/updateHasBalance', depositeParams)
              .then(() => {
                this.$store.dispatch('app/setSnackBar', { text: 'Компания обновлена' });
                this.isLoading = false;
                this.$store.commit('app/setAppIsLoading', false);
                this.$emit('close');
              });
          } else {
            this.$store.commit('app/setAppIsLoading', false);
            this.$store.dispatch('app/setSnackBar', { text: 'Компания обновлена' });
            this.isLoading = false;
            this.$emit('close');
          }
        })
        .catch(() => {
          this.$store.commit('app/setAppIsLoading', false);
          this.isLoading = false;
          this.$emit('close');
        });
    },
    changeAccessDate(value, type) {
      if (type === 'days') {
        this.companyForm.accessDays = value;
        this.companyForm.accessDaysDate = +this.companyForm.accessDays > 365
          ? this.moment(new Date()).add(365, 'd').format('YYYY-MM-DD')
          : this.moment(new Date()).add(+this.companyForm.accessDays, 'd').format('YYYY-MM-DD');
        return;
      }
      if (type === 'picker') {
        if (!value || !value.length) {
          this.companyForm.accessDays = '30';
          this.companyForm.accessDaysDate = this.moment(new Date()).add(365, 'd').format('YYYY-MM-DD');
          return;
        }

        // проверка что выбранная дата старее текущей
        const isOldDate = this.moment(new Date()).isAfter(value);
        if (isOldDate) {
          this.changeAccessDate('30', 'days');
          return;
        }

        const newDays = this.moment(value).diff(new Date(), 'days');
        this.companyForm.accessDaysDate = value;
        this.companyForm.accessDays = newDays < 1 ? '1' : String(this.moment(value).diff(new Date(), 'days'));
      }
    },
    initManagersList() {
      this.managersList = [{ name: 'Доступен всем менеджерам', value: 'all' }].concat(this.accountManagers);
      if (this.isEditCompany) return;
      this.companyForm.selectedManagers = [...this.managersList];
    },
    selectAccount(params) {
      if (params.option.value === 'all' && params.selected) {
        this.companyForm.selectedManagers = [...this.managersList];
      }
      if (params.option.value === 'all' && !params.selected) {
        this.companyForm.selectedManagers = [];
      }
      if (params.option.value !== 'all' && !params.selected) {
        this.companyForm.selectedManagers = this.companyForm.selectedManagers.filter((item) => item.value !== params.option.value && item.value !== 'all');
      }
      if (params.option.value !== 'all' && params.selected) {
        this.companyForm.selectedManagers = this.companyForm.selectedManagers.filter((item) => item.value !== 'all');
        // this.companyForm.selectedManagers.push(params.option);
        if (this.companyForm.selectedManagers.length === this.managersList.length - 1) {
          this.companyForm.selectedManagers.push(this.managersList[0]);
        }
      }
      this.$nextTick(() => {
        this.$refs.accountSelect.init();
      });
    },
    selectSales(params) {
      if (params.option.value === 'all' && params.selected) {
        this.companyForm.selectedManagers = [...this.managersList];
      }
      if (params.option.value === 'all' && !params.selected) {
        this.companyForm.selectedManagers = [];
      }
      if (params.option.value !== 'all' && !params.selected) {
        this.companyForm.selectedManagers = this.companyForm.selectedManagers.filter((item) => item.value !== params.option.value && item.value !== 'all');
      }
      if (params.option.value !== 'all' && params.selected) {
        this.companyForm.selectedManagers = this.companyForm.selectedManagers.filter((item) => item.value !== 'all');
        this.companyForm.selectedManagers.push(params.option);
        if (this.companyForm.selectedManagers.length === this.managersList.length - 1) {
          this.companyForm.selectedManagers.push(this.managersList[0]);
        }
      }
      this.$nextTick(() => {
        this.$refs.accountSelect.init();
      });
    },
  },
  computed: {
    getClientsData() {
      return this.$store.getters['clients/getFullClientsData'].map((item) => ({ name: item.name, value: item.id }));
    },
    labelForCompany() {
      return this.isEditCompany ? 'Редактирование компании' : 'Создание компании';
    },
    labelForAccess() {
      if (this.isEditCompany) {
        return this.companyForm.allowAccess ? 'Доступ открыт' : 'Доступ закрыт';
      }
      return 'Открыть доступ';
    },
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
    getMaxSelectElements() {
      const windowHeight = window.screen.height;
      return windowHeight > 1000 ? 6 : 3;
    },
    getClientBalance() {
      const balance = this.currentClient?.account?.balance || 0;
      return `Баланс ${this.setNumberSpaces(balance)} ${this.getCurrencySymbol}`;
    },
    getCurrencySymbol() {
      return this.$store.getters['app/getCurrencySymbol'];
    },
    isSalesManager() {
      return this.$store.getters['user/getIsSalesManager'];
    },
    isAccountManager() {
      return this.$store.getters['user/getIsManager'];
    },
  },
};
</script>

<style lang="scss" scoped>
.add-client {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @include tablet-screen {
    min-width: 500px;
  }
}

.add-client__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.add-client__company-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-client__text {
  font-size: 14px;
  color: #232323;
  &_title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    @include tablet-screen {
      font-size: 28px;
    }
  }
  &_subtitle {
    font-size: 14px;
    font-weight: 600;
    @include tablet-screen {
      font-size: 18px;
    }
  }
  &_light {
    color: #4F4F4F;
    font-weight: 400;
    margin-bottom: 5px;
  }
  &_blue {
    color: $primary-blue;
    margin-bottom: 10px;
    font-weight: 500;
    cursor: pointer;
  }
  &_select {
    color: rgba(#4F4F4F, 0.8);
    font-size: 16px;
  }
  &_error {
    color: $primary-red;
  }
}

.add-client__client-inputs {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin: 20px 0px;
}

.add-client__client-agency-inputs {
  display: flex;
  gap: 20px;
  margin: 20px 0px;
}

.add-client__input-element {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  &_select {
    margin-bottom: 20px;
  }
}

.add-client__input {
  background: #FAFEFF;
  border-radius: 8px;
  border: 1px solid #D8F2FA;
  height: 48px;
  outline: none;
  font-size: 16px;
  padding: 0px 16px;
  &::placeholder {
    color: rgba(#4F4F4F, 0.8);
  }
  &_rate {
    padding: 0px 25px;
  }
  &_error {
    border: 1px solid $primary-red;
  }
}

.add-client__select {
  background: #FAFEFF;
  border-radius: 8px;
  border: 1px solid #D8F2FA;
  height: 48px;
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  &_disabled {
    background: rgba(black, 0.05);
    opacity: 0.6;
    border: none;
  }
  &_error {
    border: 1px solid $primary-red;
  }
}

.add-client__agency-toggles {
  margin-bottom: 20px;
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}

.add-client__select-menu {
  border-radius: 8px;
  border: 1px solid #D8F2FA;
  max-height: 330px;
  background: #FFFFFF;
  position: absolute;
  left: 0;
  top: 50px;
  z-index: 1;
  overflow: auto;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  width: 100%;
}

.add-client__select-menu-item {
  padding: 0px 16px;
  height: 40px;
  display: flex;
  align-items: center;
  &:hover {
    background: #f1fcff;
  }
}

.add-client__buttons {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 30px;
  @include tablet-screen {
  grid-template-columns: 1fr 1fr;
  }
}

.add-client__toggles-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  @include between-elements {
    margin-right: 30px;
  }
}

.add-client__input-percent__toggle {
  display: flex;
  align-items: center;
  @include between-elements {
    margin-right: 10px;
  }
}

.add-client__pass-icon {
  position: absolute;
  right: 15px;
  top: 40px;
  color: $primary-lightgray;
}

.add-client__input-percent {
  position: absolute;
  right: 15px;
  top: 37px;
  font-size: 17px;
}

.add-client__edit-date {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  @include between-elements {
    margin-right: 20px;
  }
}

// balance
.add-client__balance {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.add-client__balance-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-client__balance-toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 5px;
  }
}

.add-client__balance-toggle-text {
  color: $primary-blue;
  cursor: pointer;
}

.add-client__balance-toggle-icon {
  color: $primary-blue;
  cursor: pointer;
  transition: 0.2s;
  &_open {
    transform: rotate(90deg) !important;
  }
}

.add-client__balance-add {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include between-elements {
    margin-right: 20px;
  }
}
</style>
