<template>
  <div
  class="compilations"
  :class="[ isOpen && 'compilations_open']"
  @click="openMenu">
    <span class="compilations__text">
      {{ getLabel }}
    </span>
    <div class="compilations__actions">
      <span
      class="compilations__text compilations__text_light">
        {{ getCompilations.length }}
      </span>
      <DataIcon
      name="expand_more"
      :size="20"
      class="compilations__text compilations__text_light"
      :arrow-open="isOpen" />
    </div>
    <div
    v-if="isOpen"
    v-click-outside="onClickOutside"
    class="compilations__menu"
    :class="getCompilations.length === 0 && 'flex-center'">
      <span
      v-if="getCompilations.length === 0"
      class="compilations__text compilations__text_small compilations__text_light">{{ $t('The collections are empty') }}
      </span>
      <span
      v-for="compilation in getCompilations"
      :key="compilation.id"
      class="compilations__item"
      @click="setShowCurrentCollection(compilation)">
        {{ compilation.name }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Compilations',
  data: () => ({
    isOpen: false,
  }),
  async created() {
    this.$store.dispatch('compilations/getUserCompilationsListOld', this.getUserInfo.id);
  },
  methods: {
    onClickOutside() {
      this.isOpen = false;
    },
    openMenu() {
      this.isOpen = !this.isOpen;
    },
    setShowCurrentCollection(compilation) {
      this.$router.push({ name: 'compilation', query: { id: compilation.id, name: compilation.name } });
    },
  },
  computed: {
    getLabel() {
      return this.$t('my_compilations');
    },
    getCompilations() {
      return this.$store.getters['compilations/getSimpleCompilationsList'];
    },
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
  },
};
</script>

<style lang="scss" scoped>
.compilations {
  width: 180px;
  height: 30px;
  border: 1px solid $border-color-primary;
  box-sizing: border-box;
  border-radius: 6px;
  background: $primary-white;
  padding: 0px 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &_open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid transparent;
  }
  &_show-collection {
    background: $primary-blue;
  }
}

.compilations__actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.compilations__text {
  font-size: 13px;
  color: $primary-gray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &_light {
    color: $primary-lightgray;
  }
  &_small {
    font-size: 12px;
  }
  &_show-collection {
    color: $primary-white;
  }
}

.compilations__arrow {
  pointer-events: none;
  color: $primary-gray;
  &_show-collection {
    color: $primary-white;
  }
  &_close {
    pointer-events: fill;
  }
}

.compilations__menu {
  position: absolute;
  border-radius: 6px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  background-color: $primary-white;
  width: 180px;
  left: -1px;
  top: 28px;
  z-index: 5;
  border: 1px solid $border-color-primary;
  border-top: none;
  max-height: 300px;
  min-height: 100px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.compilations__item {
  color: black;
  font-size: 13px;
  padding: 3px 10px;
  display: block;
  text-align: left;
  &:hover {
    transition: .2s;
    background: #ebebeb;
  }
}
</style>
