<template>
  <div class="compilations-drag">
    <div
    v-if="showNewFolderButton && !isNoauthMode"
    class="compilations-drag__item"
    @click="openCreateFolderModal">
      <img
      class="compilations-drag__icon"
      src="@/assets/svg/compilation/drag-folder.svg">
      <span class="compilations-drag__text">Добавить папку</span>
    </div>
    <div
    class="compilations-drag__item"
    :class="{ 'compilations-drag__item_selected' : enableDragMode }"
    @click="setEnableDragMode">
      <img
      class="compilations-drag__icon"
      src="@/assets/svg/compilation/drag-arrows.svg">
      <span class="compilations-drag__text">Переместить</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompilationsDrag',
  methods: {
    setEnableDragMode() {
      this.$store.commit('compilations/setEnableDragMode', !this.enableDragMode);
    },
    openCreateFolderModal() {
      const props = { type: 'create', compilationInfo: this.getCompilationInfo };
      const modalParams = { name: 'CompilationFolderModal', props };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
  },
  computed: {
    enableDragMode() {
      return this.$store.getters['compilations/getEnableDragMode'];
    },
    showNewFolderButton() {
      return this.getCompilationInfo.folders && this.getCompilationInfo.folders.length === 1;
    },
    getCompilationInfo() {
      return this.$store.getters['compilations/getCurrentCompilationInfo'];
    },
    isNoauthMode() {
      return !localStorage.getItem('userToken');
    },
  },
};

</script>

<style lang="scss">
.compilations-drag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 10px;
  }
}

.compilations-drag__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 10px;
  cursor: pointer;
  box-sizing: border-box;
  &_selected {
    outline: 1px solid $primary-lightgray;
    border-radius: 8px;
    background: rgb(250, 250, 250);
  }
  @include between-elements {
    margin-right: 10px;
  }
}

.compilations-drag__text {
  font-size: 13px;
  color: $primary-gray;
}

.compilations-drag__icon {
  width: 25px;
}
</style>
