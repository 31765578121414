<template>
  <div class="mass-search">
    <span class="mass-search__title">Массовый поиск</span>
    <div class="mass-search__search">
      <DataTextarea
      :value="links"
      :height="getTextAreaHeight"
      :error="emptyLinksError && !linksArray.length"
      placeholder="Вставьте ссылки на каналы"
      class="mass-search__textarea"
      :font-size="16"
      @input="setLinksArray($event)" />
      <DataButton
      :text="searchButtonText"
      :height="50"
      :width="200"
      @handler="search" />
    </div>
    <div
    v-if="searchEnd"
    key="content"
    class="mass-search__results">
      <div class="mass-search__results-container">
        <div class="mass-search__results-items">
          <div
          class="mass-search__results-item"
          @click="foundType = 'found'">
            <span
            class="mass-search__result-text"
            :class="foundType === 'found' && 'mass-search__result-text_active'">Найдено</span>
            <span class="mass-search__result-text mass-search__result-text_second">{{ `(${foundLinks.length}) ` }}</span>
          </div>
          <div
          class="mass-search__results-item"
          @click="foundType = 'notfound'">
            <span :class="foundType === 'notfound' && 'mass-search__result-text_active'">Не найдено</span>
            <span class="mass-search__result-text mass-search__result-text_second">{{ `(${notFoundLinks.length}) ` }}</span>
          </div>
        </div>
        <DataIcon
        v-if="notFoundLinks.length"
        v-tooltip="'Скопировать ненайденные ссылки'"
        class="mass-search__copy-link pointer"
        name="content_copy"
        :size="20"
        @click.native="copyNotFoundLinks" />
      </div>

      <div
      v-if="showFoundGroups"
      class="mass-search__not-found">
        <div
        v-for="(link, index) in getFindLinks"
        :key="link + index"
        class="mass-search__not-found-item">
          {{ link }}
        </div>
      </div>

      <DataButton
      text="Показать результаты"
      width="full"
      height="50"
      :font-size="16"
      :disabled="!searchEnd"
      @handler="showResult" />
    </div>
  </div>
</template>

<script>
import uniqBy from 'lodash/uniqBy';

export default {
  name: 'MassSearch',
  data: () => ({
    links: '',
    linksArray: [],
    finalLinks: [],
    emptyLinksError: false,
    groups: null,
    foundLinks: [],
    notFoundLinks: [],
    searchEnd: false,
    showFoundGroups: false,
    foundType: null,
  }),
  methods: {
    setLinksArray(text) {
      this.links = text;
      this.linksArray = this.links.replace(/\n/g, '--').replace(/\s/g, '--').split('--');
      this.finalLinks = uniqBy(this.linksArray.filter((item) => item));
      this.finalLinks = this.finalLinks.map((item) => {
        if (item.includes('https://')) return item;
        return `https://${item}`;
      });
    },
    search() {
      if (!this.finalLinks.length) {
        this.emptyLinksError = true;
        return;
      }

      this.emptyLinksError = false;
      this.searchEnd = false;
      this.foundLinks = [];
      this.notFoundLinks = [];
      this.groups = [];

      this.$emit('resetAllFilters');
      this.$store.commit('groups/setGroupsData', []);
      this.$store.commit('groups/setLoadPage', 1);
      this.$emit('updateField', 'update:url', this.finalLinks);

      const searchParams = { ...this.$store.getters['groups/getGroupsSearchParamsTemplate'] };
      searchParams.byUrl = this.finalLinks;
      this.$store.commit('groups/setGroupsSearchParams', searchParams);

      this.$store.dispatch('groups/loadGroupsData', { aggregate: true })
        .then((data) => {
          this.groups = [...data];
          this.setFoundLinks();
          this.showFoundGroups = true;
          if (this.foundLinks.length) {
            this.foundType = 'found';
          } else {
            this.foundType = 'notfound';
          }
          this.searchEnd = true;
        });
    },
    setFoundLinks() {
      this.foundLinks = this.groups.map((item) => item.url);
      this.finalLinks.forEach((item) => {
        const includes = this.groups.some((group) => group.url.toUpperCase() === item.toUpperCase());
        if (!includes) {
          this.notFoundLinks.push(item);
        }
      });
    },
    showResult() {
      this.$emit('close');
    },
    copyNotFoundLinks() {
      const copyLinks = this.copyToBuffer(this.notFoundLinks.join('\n'));
      if (copyLinks) {
        this.$store.dispatch('app/setSnackBar', { text: 'Ссылки скопированы' });
      }
    },
  },
  computed: {
    getTextAreaHeight() {
      if (this.linksArray.length === 1 || !this.linksArray.length) return 51;
      const height = (this.linksArray.length * 20) + 30;
      return height > 200 ? 200 : height;
    },
    getSelectedTypeOfGroups() {
      return this.$store.getters['groups/getSelectedTypeOfGroups'];
    },
    getFindLinks() {
      return this.foundType === 'found' ? this.foundLinks : this.notFoundLinks;
    },
    searchButtonText() {
      if (!this.finalLinks.length) return 'Найти';
      const channels = this.getPluralWord(this.finalLinks.length, ['канал', 'канала', 'каналов']);
      return `Найти ${this.finalLinks.length} ${channels}`;
    },
    getSearchParams() {
      return this.$store.getters['groups/getGroupsSearchParams'];
    },
  },
};
</script>

<style lang="scss" scoped>
.mass-search {
  max-width: 600px;
  width: 80vw;

  .mass-search__title {
    font-size: 22px;
    font-weight: 600;
  }

  .mass-search__search {
    display: flex;
    margin-top: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    @include between-elements {
      margin-right: 20px;
    }
  }

  .mass-search__textarea {
    width: 100%;
    height: auto;
    color: $primary-gray;
  }

  .mass-search__results {
    width: 100%;
    min-height: 150px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .mass-search__results-items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include between-elements {
      margin-right: 20px;
    }
  }

  .mass-search__results-container {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding-top: 40px;
  }

  .mass-search__results-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    @include between-elements {
      margin-right: 5px;
    }
  }

  .mass-search__not-found {
    margin-bottom: 20px;
    padding: 10px 0px;
    max-height: 200px;
    overflow: auto;
  }

  .mass-search__not-found-item {
    padding: 12px;
    border-bottom: 1px solid rgba($primary-blue, 0.2);
    color: $primary-gray;
  }

  .mass-search__result-text {
    font-size: 16px;
    font-weight: 600;
    color: $primary-black;
    &_second {
      color: $primary-gray
    }
    &_active {
      color: $primary-blue;;
    }
  }

  .mass-search__copy-link {
    color: rgb(139, 139, 139);
    margin-right: 20px;
  }
}
</style>
