<template>
  <div
  class="data-input-wrapper">
    <input
    ref="datainput"
    :placeholder="placeholder"
    class="data-input"
    :type="inputType"
    :class="{
      'data-input_disabled': disabled,
      'data-input_error': error,
      'data-input_no-edit': noEdit,
      'data-input_theme-white': theme === 'white'
    }"
    :style="hasIcon && 'padding-right: 40px'"
    :value="value"
    :disabled="disabled || noEdit"
    @change="change"
    @input="input"
    @keyup.enter="submitValue">
    <slot name="icon" />
    <transition
    mode="out-in">
      <div
      v-if="clearIcon && value && value.length && !disabled && !submitIcon"
      key="clear"
      class="data-input__icon-block pointer">
        <DataIcon
        name="close"
        :size="22"
        @click.native="clear" />
      </div>
      <div
      v-else-if="submitIcon && value && value.length"
      key="search"
      class="data-input__icon-block pointer">
        <DataIcon
        :name="submitIcon"
        :size="22"
        @click.native="submitValue" />
      </div>
      <div
      v-else-if="searchIcon"
      key="search"
      class="data-input__icon-block">
        <DataIcon
        name="search"
        :size="22" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DataInput',
  props: {
    value: {
      type: [String, Number, Array, Object],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    // Иконка очистки введенного текста
    clearIcon: {
      type: Boolean,
      default: false,
    },
    // Иконка поиска
    searchIcon: {
      type: Boolean,
      default: false,
    },
    // Иконка подтверждения введенного текста (вместе с событием submitValue)
    submitIcon: {
      type: String,
      default: null,
    },
    inputType: {
      type: String,
      default: 'text',
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
  methods: {
    input(event) {
      this.$emit('input', event.target.value);
    },
    change(event) {
      this.$emit('change', event.target.value);
    },
    clear() {
      if (this.noEdit) return;
      this.$emit('input', '');
    },
    submitValue() {
      if (!this.submitValue) return;
      this.$emit('submitValue');
    },
    focus() {
      this.$refs.datainput.focus();
    },
  },
  computed: {
    hasIcon() {
      return this.searchIcon || this.submitIcon || this.clearIcon;
    },
  },
};
</script>

<style lang="scss" scoped>
.data-input-wrapper {
  width: 100%;
  position: relative;
}

.data-input {
  width: 100%;
  background: #fafeff;
  border-radius: 8px;
  border: 1px solid #d8f2fa;
  height: 48px;
  outline: none;
  font-size: 16px;
  padding: 0px 20px 0px 20px;
  &::placeholder {
    color: #7b7b7b;
    font-weight: 400;
  }
  &_error {
    border: 1px solid $primary-red;
  }
  &_theme-white {
    background: white;
  }
  &_disabled {
    background: #f5f5f5;
    border: 1px solid #dbdbdb;
    &::placeholder {
      color: #c0c0c0;
      font-weight: 400;
    }
  }
  &_no-edit {
    background: #f7f7f7;
    border: 1px solid rgba(black, 0.03);
  }
}

.data-input__icon-block {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 12px;
  right: 12px;
  color: #7b7b7b;
  width: 24px;
  height: 24px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.data-input__calendar-icon {
  position: absolute;
  top: 12px;
  right: 15px;
}

</style>
