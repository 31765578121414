<template>
  <div class="post-content">
    <div
    class="post-content__header"
    @click="isOpen = !isOpen">
      <div class="post-content__header-name">
        <span class="post-content__title">{{ title }}</span>
        <transition name="fastfade">
          <div
          v-if="isActiveFilter"
          v-tooltip="tooltipText"
          class="post-content__active-highlight" />
        </transition>
      </div>
      <DataIcon
      name="expand_more"
      class="post-content__title pointer"
      :arrow-open="isOpen" />
    </div>
    <div
    class="post-content__content"
    :class="{
      'post-content__content_show': isOpen,
      'post-content__content_content': isOpen && type === 'content'
    }">
      <div class="post-content__items">
        <div
        v-for="option in getOptions"
        :key="option.name"
        class="post-content__item"
        :class="{ 'post-content__item_margin': option.margin }">
          <DataCheckbox
          v-model="option.checked"
          @input="changeFilters(option)" />
          <span class="post-content__item-text">{{ option.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Posttonality',
  props: {
    type: {
      type: String,
      default: 'tonality',
    },
    title: {
      type: String,
      default: 'Направленность поста',
    },
  },
  data: () => ({
    isOpen: false,
    contentOptions: [
      {
        name: 'С вложениями', checked: false, value: 'true', field: 'withAttachments', group: 1,
      },
      {
        name: 'Без вложений', checked: false, value: 'false', field: 'withAttachments', margin: true, group: 1,
      },
      {
        name: 'С ссылками', checked: false, value: 'true', field: 'withLinks', group: 2,
      },
      {
        name: 'Без ссылок', checked: false, value: 'false', field: 'withLinks', group: 2,
      },
    ],
    tonalityOptions: [
      {
        name: 'Положительное', checked: false, value: 'positive', field: 'tonality', group: 1,
      },
      {
        name: 'Отрицательное', checked: false, value: 'negative', field: 'tonality', group: 1,
      },
      {
        name: 'Нейтральное', checked: false, value: 'neutral', field: 'tonality', group: 1,
      },
    ],
  }),
  created() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.type === 'tonality' && this.$route.query.tonality) {
        this.getOptions.forEach((item) => {
          if (item.value === this.$route.query.tonality) {
            item.checked = true;
          }
        });
        this.isOpen = this.isActiveFilter;
        return;
      }

      if (this.type === 'content' && (this.$route.query.withLinks || this.$route.query.withAttachments)) {
        this.getOptions.forEach((item) => {
          if (item.field === 'withLinks' && item.value === this.$route.query.withLinks) {
            item.checked = true;
          }
          if (item.field === 'withAttachments' && item.value === this.$route.query.withAttachments) {
            item.checked = true;
          }
        });
      }

      this.isOpen = this.isActiveFilter;
    },
    changeFilters(option) {
      const query = { ...this.$route.query };
      if (option.checked) {
        query[option.field] = option.value;
      } else {
        delete query[option.field];
      }

      this.getOptions.forEach((item) => {
        if (item.name !== option.name && option.group === item.group) {
          item.checked = false;
        }
      });

      this.$router.push({ name: 'post-search', query, params: { savedPosition: true } });
    },
    resetFilters() {
      this.getOptions.forEach((item) => { item.checked = false; });
    },
  },
  computed: {
    getOptions() {
      return this.type === 'tonality' ? this.tonalityOptions : this.contentOptions;
    },
    isActiveFilter() {
      return this.getOptions.some((item) => item.checked);
    },
    tooltipText() {
      return this.getOptions.filter((item) => item.checked).map((item) => item.name).join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
.post-content {
  background: white;
  border-radius: 10px;
  padding: 0px 15px;

  .post-content__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 15px 0px;
  }

  .post-content__header-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .post-content__active-highlight {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: $primary-blue;
  }

  .post-content__title {
    font-size: 14px;
    color: $primary-gray;
    font-weight: 500;
    margin-right: 10px;
  }

  .post-content__content {
    height: 0px;
    opacity: 0;
    transition: 0.2s;
    pointer-events: none;
    overflow: hidden;
    display: flex;
    width: 100%;
    @include between-elements {
      margin-right: 15px;
    }
    &_show {
      pointer-events: auto;
      opacity: 1;
      height: 95px;
    }
    &_content {
      height: 145px;
    }
  }

  .post-content__items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 15px;
    width: 100%;
    @include between-elements {
      margin-bottom: 10px;
    }
  }

  .post-content__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    @include between-elements {
      margin-right: 10px;
    }
    &_margin {
      padding-bottom: 15px;
      border-bottom: 1px solid #d8f2fa;
      margin-bottom: 15px !important;
    }
  }

  .post-content__item-text {
    color: $primary-gray;
    font-size: 15px;
  }
}
</style>
