import NotFound from '../screens/NotFound/NotFound.vue';
import CatalogScreen from '../screens/CatalogScreen/CatalogScreen.vue';
import GroupScreen from '../screens/GroupScreen/GroupScreen.vue';
import CompilationsScreen from '../screens/CompilationsScreen/CompilationsScreen.vue';
import CompilationScreen from '../screens/CompilationScreen/CompilationScreen.vue';
import Login from '../screens/LoginScreen/LoginScreen.vue';
import Publishers from '../screens/Publishers/Publishers.vue';
import ImportsScreen from '../screens/ImportsScreen/ImportsScreen.vue';
import ProfileScreen from '../screens/ProfileScreen/ProfileScreen.vue';
import ClientsScreen from '../screens/ClientsScreen/ClientsScreen.vue';
import TariffsScreen from '../screens/TariffsScreen/TariffsScreen.vue';
import RequestsScreen from '../screens/RequestsScreen/RequestsScreen.vue';
import CampaignsScreen from '../screens/CampaignsScreen/CampaignsScreen.vue';
import PostSearch from '../screens/PostSearchScreen/PostSearchScreen.vue';
// Publisher
import MyGroupsScreen from '../screens/MyGroupsScreen/MyGroupsScreen.vue';
// Hidden admin screens
import GroupsModerationScreen from '../screens/GroupsModerationScreen/GroupsModerationScreen.vue';
import SjiModerationScreen from '../screens/SjiModerationScreen/SjiModerationScreen.vue';
import CompetitorsScreen from '../screens/CompetitorsScreen/CompetitorsScreen.vue';
//
import store from '../store/index';

const routes = [

  // Страница основного лендинга
  {
    name: 'main',
    path: '/',
    beforeEnter: (from, to, next) => {
      if (localStorage.getItem('userToken')) {
        const query = { group: 'telegram' };
        next({ name: 'catalog', query });
      } else {
        window.open('https://socialjet.pro/data/', '_self');
      }
    },
  },

  // Страница каталога
  {
    name: 'catalog',
    path: '/catalog',
    component: CatalogScreen,
    beforeEnter: (from, to, next) => {
      const isPublisher = store.getters['user/getIsPublisher'];
      if (isPublisher) {
        next({ name: 'my-groups' });
        return;
      }

      if (!store.state.groups.initial) {
        next();
        return;
      }

      const { group, importId, ...searchParams } = from.query;

      if (searchParams) {
        const currentParams = Object.assign(store.getters['groups/getGroupsSearchParams'], searchParams);

        if (typeof currentParams.categoriesId === 'string') {
          currentParams.categoriesId = [currentParams.categoriesId];
        }
        if (typeof currentParams.paymentTypesId === 'string') {
          currentParams.paymentTypesId = [currentParams.paymentTypesId];
        }
        if (typeof currentParams.subscriberCountries === 'string') {
          currentParams.subscriberCountries = [currentParams.subscriberCountries];
        }
        if (typeof currentParams.inspectReportWarningCodes === 'string') {
          currentParams.inspectReportWarningCodes = [currentParams.inspectReportWarningCodes];
        }
        if (typeof currentParams.interestsId === 'string') {
          currentParams.interestsId = [currentParams.interestsId];
        }
        store.commit('groups/setGroupsSearchParams', currentParams);
      }
      store.commit('collections/setSelectedGroups', []);
      store.commit('groups/setSelectedTypeOfGroups', group || 'telegram');
      store.commit('app/setInitialCatalogLoading', true);

      // В случае загрузки каналов из импорта
      if (importId) {
        store.commit('groups/setIsImportedGroups', importId);
        store.dispatch('groups/loadImportGroups', { id: importId }).finally(() => {
          store.commit('app/setInitialCatalogLoading', false);
          next();
        });
        return;
      }

      store.dispatch('groups/loadGroupsData', { reset: true, aggregate: true }).finally(() => {
        store.commit('app/setInitialCatalogLoading', false);
        next();
      });
    },
  },

  // Страница группы
  {
    name: 'group',
    path: '/group/:social/:id',
    component: GroupScreen,
  },

  // Страница конкретной подборки
  {
    name: 'compilation',
    path: '/compilation',
    component: CompilationScreen,
    beforeEnter: (from, to, next) => {
      const isPublisher = store.getters['user/getIsPublisher'];
      if (isPublisher) {
        next({ name: 'my-groups' });
        return;
      }

      const { shareToken } = from.query;
      if (!localStorage.getItem('userToken') && !shareToken) {
        next({ name: 'login' });
      } else {
        next();
      }
    },
  },

  // Страница списка подборок
  {
    name: 'compilations',
    path: '/compilations',
    component: CompilationsScreen,
    beforeEnter: (from, to, next) => {
      const isPublisher = store.getters['user/getIsPublisher'];
      if (isPublisher) {
        next({ name: 'my-groups' });
        return;
      }

      const { shareToken } = from.query;
      if (!localStorage.getItem('userToken') && !shareToken) {
        next({ name: 'login' });
      } else {
        next();
      }
    },
  },

  // Страница авторизации
  {
    name: 'login',
    path: '/login',
    component: Login,
    beforeEnter: (from, to, next) => {
      if (localStorage.getItem('userToken')) {
        const query = { group: 'telegram' };
        next({ name: 'catalog', query });
      } else {
        next();
      }
    },
  },

  // Страница импортов
  {
    name: 'imports-screen',
    path: '/imports',
    component: ImportsScreen,
    beforeEnter: (from, to, next) => {
      const isPublisher = store.getters['user/getIsPublisher'];
      if (isPublisher) {
        next({ name: 'my-groups' });
        return;
      }

      // FUTURE вывести проверку на адмна в middlewares
      const isAdmin = store.getters['user/getIsAdmin'];
      if (!isAdmin) {
        next({ name: 'catalog' });
      } else {
        next();
      }
    },
  },

  // Страница NOT FOUND
  {
    name: 'not-found',
    path: '*',
    component: NotFound,
  },

  // Лендинг для паблишеров
  {
    name: 'publishers',
    path: '/publishers',
    component: Publishers,
  },

  // Страница модерации каналов
  {
    name: 'moderation',
    path: '/moderation',
    component: GroupsModerationScreen,
    beforeEnter: (from, to, next) => {
      const isPublisher = store.getters['user/getIsPublisher'];
      if (isPublisher) {
        next({ name: 'my-groups' });
        return;
      }

      const isAdmin = store.getters['user/getIsAdmin'];
      if (!isAdmin) {
        next({ name: 'catalog' });
      } else {
        next();
      }
    },
  },

  // Страница модерации индексов SJI
  {
    name: 'sji-moderation',
    path: '/sji-moderation',
    component: SjiModerationScreen,
    beforeEnter: (from, to, next) => {
      const isPublisher = store.getters['user/getIsPublisher'];
      if (isPublisher) {
        next({ name: 'my-groups' });
        return;
      }

      const isAdmin = store.getters['user/getIsAdmin'];
      if (!isAdmin) {
        next({ name: 'catalog' });
      } else {
        next();
      }
    },
  },

  // Страница сравнения цен с конкурентами
  {
    name: 'competitors',
    path: '/competitors',
    component: CompetitorsScreen,
    beforeEnter: (from, to, next) => {
      const isPublisher = store.getters['user/getIsPublisher'];
      if (isPublisher) {
        next({ name: 'my-groups' });
        return;
      }

      const isAdmin = store.getters['user/getIsAdmin'];
      if (!isAdmin) {
        next({ name: 'catalog' });
      } else {
        next();
      }
    },
  },

  // Профиль
  {
    name: 'profile',
    path: '/profile',
    component: ProfileScreen,
  },

  // Страница агентств и клиентов
  {
    name: 'clients',
    path: '/clients',
    component: ClientsScreen,
    beforeEnter: (from, to, next) => {
      const isPublisher = store.getters['user/getIsPublisher'];
      if (isPublisher) {
        next({ name: 'my-groups' });
        return;
      }

      // доступ к странице клиентов только у админов и сотрудников агенств (все админы, кроме реализаторов)
      const isAdmin = store.getters['user/getIsAdmin'];
      const isAgency = store.getters['user/getIsAgency'];
      const isRealisator = store.getters['user/getIsRealisator'];
      if ((!isAdmin && !isAgency) || isRealisator) {
        next({ name: 'catalog' });
      } else {
        next();
      }
    },
  },

  // Страница запросов
  {
    name: 'requests',
    path: '/requests',
    component: RequestsScreen,
    beforeEnter: (from, to, next) => {
      const isPublisher = store.getters['user/getIsPublisher'];
      const isRealisator = store.getters['user/getIsRealisator'];
      if (isRealisator) {
        next({ name: 'catalog' });
        return;
      }
      if (isPublisher) {
        next({ name: 'my-groups' });
      } else {
        next();
      }
    },
  },

  // Страница поиска постов
  {
    name: 'post-search',
    path: '/post-search',
    component: PostSearch,
    beforeEnter: (from, to, next) => {
      const isPublisher = store.getters['user/getIsPublisher'];
      if (isPublisher) {
        next({ name: 'my-groups' });
      } else {
        next();
      }
    },
  },

  // Страница Рекламных кампаний
  {
    name: 'campaigns',
    path: '/campaigns',
    component: CampaignsScreen,
    beforeEnter: (from, to, next) => {
      const isRealisator = store.getters['user/getIsRealisator'];
      const isPublisher = store.getters['user/getIsPublisher'];
      if (isRealisator) {
        next({ name: 'catalog' });
        return;
      }
      if (isPublisher) {
        next({ name: 'my-groups' });
      } else {
        next();
      }
    },
  },

  // Страница моих каналов (для паблишеров)
  {
    name: 'my-groups',
    path: '/my-groups',
    component: MyGroupsScreen,
  },

  // Страница тарифов
  {
    name: 'tariffs',
    path: '/tariffs',
    component: TariffsScreen,
    beforeEnter: (from, to, next) => {
      const isPublisher = store.getters['user/getIsPublisher'];
      if (isPublisher) {
        next({ name: 'my-groups' });
      }
      // доступ к странице тарифов у всех, кроме клиентов агенств
      const isClientAgency = store.getters['user/getUserInfo']?.asClientAgency;
      if (isClientAgency) {
        next({ name: 'catalog' });
      } else {
        next();
      }
    },
  },
];

export default routes;
