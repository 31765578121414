<template>
  <div class="compilations-headers">
    <div
    v-for="header in getHeaders"
    :key="header.name"
    :style="`width: ${header.width}px`"
    :class="{'compilations-headers__element_name': header.value === 'compilationName' }"
    class="compilations-headers__element">
      <span class="compilations-headers__text">
        {{ header.name }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CompilationsHeaders',
  props: {
  },
  methods: {},
  computed: {
    getHeaders() {
      const headers = [
        {
          name: 'Название', value: 'compilationName', width: 370, show: true,
        },
        {
          name: 'Показатели', value: 'data', width: 500, show: true,
        },
        {
          name: 'Действия', value: 'actions', width: 250, show: true,
        },
      ];
      return headers.filter((item) => item.show);
    },
  },
};
</script>

<style lang="scss" scoped>
.compilations-headers {
  display: flex;
  align-items: center;
  min-width: 100%;
  position: sticky;
  z-index: 2;
  top: 0px;
  left: 0px;
  background: #F0F9FC;
  border: 1px solid #F0F9FC;
}

.compilations-headers__element {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F0F9FC;
  height: 30px;
  &_name {
    left: 0px;
    align-items: flex-start;
  }
}

.compilations-headers__text {
  font-size: 14px;
  color: #7B7B7B;
}

</style>
