<template>
  <div class="login-form">
    <span class="login-form__title">Вход в личный кабинет</span>

    <!-- Форма -->
    <form
    class="login-form__form"
    @keyup.enter="loginHandler">
      <div class="login-form__inputs">
        <div class="login-form__input-block">
          <input
          v-model.trim="login"
          autocomplete="off"
          placeholder="Логин или email"
          class="login-form__input">
          <span
          v-if="!login && showError"
          class="login-form__error">{{ $t('this_is_a_required_field') }}</span>
        </div>
        <div class="login-form__input-block">
          <input
          ref="passwordInput"
          v-model.trim="password"
          autocomplete="off"
          type="password"
          :placeholder="$t('Password')"
          class="login-form__input">
          <span
          v-if="!password && showError"
          class="login-form__error">{{ $t('this_is_a_required_field') }}</span>
          <DataIcon
          name="visibility"
          class="login-form__pass-icon"
          :size="20"
          pointer
          @mouseup.native="$refs.passwordInput.type = 'password'"
          @mousedown.native="$refs.passwordInput.type = 'text'" />
        </div>
      </div>
      <DataButton
      text="Войти"
      type="button"
      width="full"
      :height="50"
      :font-size="16"
      @handler="loginHandler" />
    </form>

    <!-- Восстановление пароля -->
    <span
    class="login-form__forgot-text"
    @click="openForgorPopup">Забыли пароль?</span>
    <DataModal
    :show-modal="showForgotPopup"
    @close="showForgotPopup = false">
      <template #modal-content>
        <div class="login-form__forgot-password">
          <div class="login-form__forgot-password-container">
            <span>Для восстановления пароля обратитесь к Вашему менеджеру</span>
            <span>Или обратитесь в <a href="https://t.me/socialjet_bot">поддержку</a></span>
          </div>
          <DataButton
          text="Закрыть"
          @handler="showForgotPopup = false" />
        </div>
      </template>
    </DataModal>

    <!-- Нет аккаунта -->
    <div class="login-form__register">
      <span class="login-form__account-text">У меня нет аккаунта</span>
      <DataButton
      text="Регистрация"
      width="full"
      :height="50"
      type="outlined"
      :font-size="16"
      @handler="$emit('setSelectedStep', 'registration')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',
  data: () => ({
    login: null,
    password: null,
    showError: false,
    showForgotPopup: false,
  }),
  methods: {
    openForgorPopup() {
      this.showForgotPopup = true;
    },
    loginHandler() {
      if (!this.login || !this.password) {
        this.showError = true;
        return;
      }
      this.showError = false;
      const loginData = {
        login: this.login,
        password: this.password,
      };
      this.sendForm(loginData);
    },
    sendForm(loginData) {
      localStorage.clear();
      this.$store.commit('app/setAppIsLoading', true);
      this.$store.dispatch('user/login', loginData)
        .then(() => {
          this.$store.dispatch('user/loadUserInfo')
            .then(() => {
              this.$store.commit('app/setAppIsLoading', false);
              this.$router.push({ name: 'catalog', query: { group: 'telegram' } });
              this.$store.dispatch('app/setSnackBar',
                {
                  text: this.$t('Successful authorization'),
                  error: false,
                });
            })
            .catch((error) => {
              console.warn('Не удалось загрузить информацию о пользователе', error);
              this.$store.dispatch('app/setSnackBar',
                {
                  text: this.$t('Возникла внутренняя ошибка. Перезагрузите страницу и попробуйте ещё раз'),
                  error: true,
                });
            });
        })
        .catch((error) => {
          this.resetInputs();
          this.$store.commit('app/setAppIsLoading', false);
          console.warn('Ошибка при получении токена пользователя', error);
          if (error.response.status && error.response.status === 422) {
            this.$store.dispatch('app/setSnackBar',
              {
                text: this.$t('Incorrect login or password'),
                error: true,
              });
          } else {
            this.$store.dispatch('app/setSnackBar',
              {
                text: this.$t('Возникла внутренняя ошибка. Перезагрузите страницу и попробуйте ещё раз'),
                error: true,
              });
          }
        });
    },
    resetInputs() {
      this.login = null;
      this.password = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  text-align: center;
}

.login-form__form {
  width: 100%;
  max-width: 400px;
}

.login-form__title {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #0E092A;
}

.login-form__inputs {
  margin-bottom: 20px;
  @include between-elements {
    margin-bottom: 20px;
  }
}

.login-form__input {
  padding: 14px 40px 14px 16px;
  border: 1px solid $border-color-primary;
  border-radius: 8px;
  background: $primary-white;
  box-sizing: border-box;
  font-size: 16px;
  &:focus {
    outline: none;
  }
}

.login-form__pass-icon {
  position: absolute;
  top: 16px;
  right: 15px;
  color: $primary-lightgray;
}

.login-form__input-block {
  display: flex;
  flex-direction: column;
  position: relative;
}

.login-form__error {
  margin-top: 5px;
  font-size: 12px;
  color: $primary-red;
  text-align: left;
}

.login-form__forgot-text {
  font-size: 16px;
  color: $primary-blue;
  margin: 30px 0px;
  cursor: pointer;
}

.login-form__register {
  padding-top: 30px;
  border-top: 1px solid #CCEFFA;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form__account-text {
  margin-bottom: 15px;
  font-size: 14px;
  color: #232323;
}

.login-form__forgot-password {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include between-elements {
    margin-bottom: 30px;
  }
}

.login-form__forgot-password-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include between-elements {
    margin-bottom: 10px;
  }
}
</style>
