<template>
  <div>
    <!-- commit -->
    <div
    v-if="tariffId == 3"
    class="tariff-info tariff-info_full">
      <span class="tariff-info__text tariff-info__text_title">
        Доступ открыт
      </span>
      <div class="tariff-info__full-content">
        <div class="tariff-info__full-block">
          <img src="../assets/ok-circle.svg">
        </div>
        <div class="tariff-info__full-text-block">
          <span class="tariff-info__text tariff-info__text_fulltitle">
            100 000 ₽
          </span>
          <div class="tariff-info__container">
            <span class="tariff-info__text tariff-info__text_gray">
              Stay tuned commit
            </span>
            <img
            v-tooltip.top="'Минимальный порог оборота средств за месяц для получения бесплатного доступа на след. месяц'"
            src="@/assets/svg/icons/info.svg"
            class="tariff-info__info-icon pointer">
          </div>
        </div>
      </div>
      <span class="tariff-info__text">{{ expiredLabel }}</span>
    </div>

    <!-- Open tariff -->
    <div
    v-if="tariffId == 2"
    class="tariff-info">
      <span class="tariff-info__text tariff-info__text_title">
        Доступ открыт
      </span>
      <div class="tariff-info__open-content">
        <div class="tariff-info__container">
          <span class="tariff-info__text tariff-info__text_gray">
            Бюджет этого месяца
          </span>
          <img
          v-tooltip.top="'Минимальный порог оборота средств за месяц для получения бесплатного доступа на след. месяц'"
          src="@/assets/svg/icons/info.svg"
          class="tariff-info__info-icon pointer">
        </div>
        <div class="tariff-info__progress-block">
          <span class="tariff-info__text-progress-blue">{{ getBurnLabel }}</span>
          <span class="tariff-info__text-progress-darkblue"> / 100 000₽</span>
        </div>
      </div>
      <div class="tariff-info__footer-text">
        <span class="tariff-info__text-footer">Бесплатный доступ в след. месяце доступен при бюджете 100к+ за предыдущий</span>
        <span class="tariff-info__text-footer">
          В случае недостижения этой суммы можно
          <span
          class="tariff-info__text-footer-link"
          @click="openPayment">
            купить платный доступ
          </span>
          за 25 000 ₽
        </span>
      </div>
    </div>

    <!-- Trial tariff -->
    <div
    v-if="tariffId == 1"
    class="tariff-info">
      <div class="tariff-info__title-block">
        <span class="tariff-info__text tariff-info__text_title">
          Доступ открыт
        </span>
        <div class="tariff-info__container">
          <span class="tariff-info__text">
            Триал доступ
          </span>
          <img
          v-tooltip.top="'Минимальный порог оборота средств за месяц для получения бесплатного доступа на след. месяц'"
          src="@/assets/svg/icons/info.svg"
          class="tariff-info__info-icon pointer">
        </div>
      </div>
      <div class="tariff-info__open-content">
        <span class="tariff-info__text tariff-info__text_gray">
          Бюджет этого месяца
        </span>
        <div class="tariff-info__progress-block">
          <span class="tariff-info__text-progress-blue">{{ getBurnLabel }}</span>
          <span class="tariff-info__text-progress-darkblue"> / 100 000₽</span>
        </div>
      </div>
      <div class="tariff-info__footer-text">
        <span class="tariff-info__text-footer">Бесплатный доступ в след. месяце доступен при бюджете 100к+ за предыдущий</span>
        <span class="tariff-info__text-footer">
          В случае недостижения этой суммы можно
          <span
          class="tariff-info__text-footer-link"
          @click="openPayment">
            купить платный доступ
          </span>
          за 25 000 ₽
        </span>
      </div>
    </div>

    <!-- Close tariff -->
    <div
    v-if="tariffId == 4"
    class="tariff-info tariff-info_closed">
      <div class="tariff-info__title-block">
        <span class="tariff-info__text tariff-info__text_title tariff-info__text_red">
          Доступ закрыт
        </span>
        <span
        class="tariff-info__text"
        style="text-align: center; max-width: 320px">
          В прошлом месяце вы не потратили 100 000 ₽
        </span>
      </div>
      <div class="tariff-info__open-content">
        <span class="tariff-info__text tariff-info__text_gray">
          Стоимость доступа
        </span>
        <div class="tariff-info__progress-block">
          <span class="tariff-info__text-progress-darkblue">25 000 ₽</span>
        </div>
      </div>
      <div class="tariff-info__close-button">
        <img src="../assets/shop-icon.svg">
        <span
        class="tariff-info__close-button-text"
        @click="openPayment">
          Получить доступ на 30 дней
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TariffInfo',
  props: {
    tariffId: {
      type: [Number, String],
      default: null,
    },
    tariffValue: {
      type: Number,
      default: null,
    },
    expiredDate: {
      type: [String, Number],
      default: null,
    },
  },
  methods: {
    openPayment() {
      this.$emit('openPayment');
    },
  },
  computed: {
    getBurnLabel() {
      if (this.tariffValue === null) {
        return '0 ₽';
      }
      return `${this.tariffValue} ₽`;
    },
    expiredLabel() {
      return this.expiredDate ? `Доступ до ${this.moment(this.expiredDate).format('ll')}` : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.tariff-info {
  padding: 32px;
  background: white;
  border-radius: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @include between-elements {
    margin-bottom: 20px;
  }
  @include tablet-screen {
      min-width: 370px;
    flex-direction: row;
    @include between-elements {
      margin-bottom: 0px;
    }
  }
  @include max-screen {
    flex-direction: column;
  }
  &_full {
    @include max-screen {
      padding: 50px 32px 80px 32px;
    }
  }
  &_closed {
    @include max-screen {
      padding: 40px 32px 50px 32px;
    }
  }
}

.tariff-info__text {
  font-size: 16px;
  &_title {
    font-size: 30px;
    color: #6AB16F;
    font-weight: 700;
  }
  &_fulltitle {
    font-size: 30px;
    color: #6AB16F;
    font-weight: 600;
  }
  &_red {
    color: #CF5858;
  }
  &_gray {
    color: $primary-lightgray;
  }
}

.tariff-info__full-content {
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}
.tariff-info__full-block {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(106, 177, 111, 0.1);
  padding: 14px;
  border-radius: 8px;
}

.tariff-info__full-text-block {
  display: flex;
  flex-direction: column;
}

.tariff-info__text-progress-blue {
  font-size: 20px;
  color: #66CEF1;
  font-weight: 600;
  @include tablet-screen {
    font-size: 30px;
  }
}

.tariff-info__open-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @include between-elements {
    margin-bottom: 20px;
  }
}

.tariff-info__title-block {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
  @include between-elements {
    margin-bottom: 30px;
  }
}
.tariff-info__text-progress-darkblue {
  color: #00AEE8;
  font-size: 20px;
  font-weight: 600;
  @include tablet-screen {
    font-size: 30px;
  }
}

.tariff-info__footer-text {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  @include max-screen {
    max-width: 100%;
    padding-top: 20px;
    border-top: 1px solid #D8F2FA;
  }
}

.tariff-info__text-footer {
  color: #7B7B7B;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  margin-left: 15px;
  &:before {
    content: '';
    width: 4px;
    height: 4px;
    background: #7B7B7B;
    position: absolute;
    top: 10px;
    left: -15px;
    border-radius: 100%;
  }
}

.tariff-info__text-footer-link {
  color: #7B7B7B;
  font-size: 14px;
  line-height: 24px;
  color: $primary-blue;
  cursor: pointer;
}

.tariff-info__closed-text {
  text-align: center;
  max-width: 320px;
  line-height: 30px;
}

.tariff-info__close-button {
  max-width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $primary-blue;
  border-radius: 33px;
  height: 52px;
  padding: 0px 20px;
  cursor: pointer;
  transition: 0.2s;
  @include between-elements {
    margin-right: 10px;
  }
  &:hover {
    background: rgba($primary-blue, 0.8);
  }
}

.tariff-info__close-button-text {
  color: white;
  font-size: 16px;
  text-align: center;
}

.tariff-info__closed-text {
  color: white;
  font-size: 16px;
  text-align: center;
  max-width: 300px;
}

.tariff-info__info-icon {
  width: 20px;
  height: 20px;
}

.tariff-info__container {
  display: flex;
  justify-content: center;
  align-items: center;
  @include between-elements {
    margin-right: 5px;
  }
}
</style>
