<template>
  <div class="export-groups">
    <div class="export-groups__header-block">
      <span class="export-groups__text export-groups__text_title">Экспорт данных</span>
      <span class="export-groups__text export-groups__text_subtitle">{{ $t('select_the_required_columns_to_export') }}</span>
    </div>
    <div class="export-groups__items">
      <div
      v-for="column in columns"
      :key="column.value"
      class="export-groups__item"
      :class="{ 'export-groups__item_margin-bottom': column.margin }">
        <DataCheckbox
        v-model="column.set" />
        <span class="export-groups__text export-groups__text_subtitle">{{ $t(column.name) }}</span>
      </div>
    </div>
    <div class="export-groups__button-block">
      <DataButton
      :text="$t('export2')"
      @handler="exportGroups" />
      <DataButton
      :text="$t('cancel')"
      type="outlined"
      @handler="close" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompetitorsExportModal',
  props: {
    groups: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    columns: [
      {
        name: 'name2',
        value: 'name',
        set: true,
        show: true,
      },
      {
        name: 'link',
        value: 'url',
        set: true,
        show: true,
      },
      {
        name: 'description',
        value: 'description',
        set: true,
        show: true,
      },
      {
        name: 'categories',
        value: 'categories',
        set: true,
        show: true,
      },
      {
        name: 'subscribers_count',
        value: 'subscribersCount',
        set: true,
        show: true,
      },
      {
        name: 'views_24h',
        value: 'viewsCountLast24h',
        set: true,
        show: true,
      },
      {
        name: 'postPrice24hSell',
        value: 'postPrice24hSell',
        set: true,
        show: true,
      },
      {
        name: 'telegaInPrice',
        value: 'telegaInPrice',
        set: true,
        show: true,
      },
    ],
  }),
  methods: {
    close() {
      this.$emit('close');
    },
    exportGroups() {
      const fields = this.columns.filter((item) => item.set).map((item) => item.value);

      if (!fields.length) {
        return;
      }

      const groupIdentities = this.groups
        .filter((item) => item.externalPrice.checked)
        .map((item) => ({ id: item.externalPrice.id }));

      const params = {
        // limit: 0,
        fields,
        id: groupIdentities,
        serviceName: 'telega-in',
        // groupSocial: 'vk',
        // ourPriceGrater: true,
        // differencePercentFrom: 0,
        // differencePercentTo: 0,
        // groupNameOrUrl: 'string',
        // ourPriceFrom: 0,
        // ourPriceTo: 0,
        // externalPriceFrom: 0,
        // externalPriceTo: 0,
        // groupCategoriesId: [
        //   0,
        // ],
        // groupWithoutCategory: true,
      };

      this.$store.dispatch('competitors/exportPriceCatalog', params)
        .then(() => {
          const allGroups = this.$store.getters['competitors/getCompetitorsGroups'];
          const newCompetitorsGroups = allGroups
            .filter((item) => !groupIdentities.some((group) => group.id === item.externalPrice.id));
          this.$store.commit('competitors/setCompetitorsGroups', newCompetitorsGroups);
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Successfully. Open the downloaded file'), error: false });
          this.$emit('close');
        })
        .catch(() => {
          this.$emit('close');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.export-groups {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @include desktop-screen {
    width: 40vw;
    max-width: 1500px;
  }
}

.export-groups__header-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
}

.export-groups__text {
  color: $primary-black;
  font-size: 14px;
  &_title {
    font-size: 22px;
    margin-bottom: 10px;
  }
  &_small {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

.export-groups__items {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: scroll;
  padding-bottom: 10px;
  max-height: 450px;
  @include between-elements {
    margin-bottom: 5px;
  }
  @include desktop-screen {
    flex-direction: column;
      flex-wrap: wrap;
  }
}

.export-groups__item {
  display: flex;
  width: 270px;
  align-items: center;
  margin-right: 10px;
  @include between-elements {
    margin-right: 10px;
  }
  &_margin-bottom {
    padding-bottom: 40px
  }
}

.export-groups__button-block {
  padding-top: 20px;
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}

</style>
