<template>
  <div class="placement">
    <div class="placement__header">
      <div class="placement__header-container">
        <div class="placement__title">
          {{ getTitle }}
        </div>
        <DataIcon
        name="close"
        class="placement__close"
        pointer
        :size="50"
        @click.native="closePlacement" />
      </div>
    </div>

    <CreatePlacement
    :selected-groups="getPlacementParams.selectedGroups"
    :view-mode="getPlacementParams.viewMode"
    :current-placement="getPlacementParams.currentPlacement"
    @success="openSuccessModal" />
  </div>
</template>

<script>
import CreatePlacement from './components/CreatePlacement.vue';

export default {
  name: 'Placement',
  components: { CreatePlacement },
  data: () => ({}),
  created() {
    document.body.style.overflowY = 'hidden';
  },
  beforeDestroy() {
    document.body.style.overflowY = 'auto';
  },
  methods: {
    closePlacement() {
      this.$store.commit('placements/setPlacementParams', null);
    },
    openSuccessModal() {
      this.closePlacement();
      const modalParams = { name: 'PlacementResultModal' };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
  },
  computed: {
    getPlacementParams() {
      return this.$store.getters['placements/getPlacementParams'] || {};
    },
    getTitle() {
      if (this.getPlacementParams?.currentPlacement) {
        return this.getPlacementParams?.currentPlacement?.campaignsName || 'Редактирование заказа';
      }
      return 'Создание заказа';
    },
  },
};
</script>

<style lang="scss" scoped>
.placement {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: white;
  overflow: auto;
}

.placement__header {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  background: white;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(236, 236, 236);
  z-index: 1;
  padding: 0px 40px;
  margin: 0px auto;
  width: 100%;
}

.placement__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 2000px;
}

.placement__close {
  color: $primary-black;
}

.placement__title {
  font-size: 26px;
  color: $primary-black;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
