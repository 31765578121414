import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    initial: true,
    selectedGroups: [],
    groupsSelectedData: [],
    groupsFavoriteData: [],
    collectionsData: [],
    collectionsList: [],
    collectionSelected: [],
    //
    currentCollectionId: null,
    isShowSelected: false,
    isShowFavorite: false,
    isShowCollection: false,
    loadCompilation: [],
    loadParams: {
      'page-size': 50,
      page: 1,
    },
    totalPageCount: null,
    compilationInfo: null,

    sortDir: 'desc',
    sortBy: 'subscribersCount',
  },
  getters: {
    getTotalPageCounts: (state) => state.totalPageCount,
    getCurrentPage: (state) => state.loadParams.page,
    //
    getSelectedGroups: (state) => state.selectedGroups,
    getSelectedGroupsData: (state) => state.groupsSelectedData,
    getCollectionsData: (state) => state.collectionsData,
    getCollectionsList: (state) => state.collectionsList,
    //
    getCurrentCollectionId: (state) => state.currentCollectionId,
    getCurrentCollection: (state) => state.collectionsData.find((collection) => collection.id === state.currentCollectionId),
    getLoadCompilation: (state) => state.loadCompilation,
    //
    getShowSelectedGroups: (state) => state.isShowSelected,
    getShowCollectionGroups: (state) => state.isShowCollection,
    //
    getGroupsSortBy: (state) => state.sortBy,
    getGroupsSortDir: (state) => state.sortDir,
  },
  mutations: {
    setGroupsSelectedData: (state, value) => { state.groupsSelectedData = value; },
    setSelectedGroups: (state, value) => { state.selectedGroups = value; },
    setTotalPageCount: (state, value) => { state.totalPageCount = value; },
    setLoadPage: (state, value) => { state.loadParams.page = value; },
    //
    setCollectionsData: (state, value) => { state.collectionsData = value; },
    setCollectionsList: (state, value) => { state.collectionsList = value; },
    setCurrentCollectionId: (state, value) => { state.currentCollectionId = value; },
    setCompilationInfo: (state, value) => { state.compilationInfo = value; },
    setLoadCompilation: (state, value) => { state.loadCompilation = value; },
    //
    setSortBy: (state, value) => { state.sortBy = value; },
    setSortDir: (state, value) => { state.sortDir = value; },
    //
    setShowSelectedGroups: (state, value) => { state.isShowSelected = value; },
  },
  actions: {

  },
};
