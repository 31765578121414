<template>
  <div
  ref="compilation"
  class="compilation"
  :class="showCompilationFolders && 'compilation_with-folders'">
    <NavActions
    :groups-data="compilationGroups"
    :mode="mode"
    :is-admin="isAdmin"
    :selected-groups="getSelectedGroups"
    is-compilation
    :empty-data="isEmptyCompilation" />

    <div
    ref="compilationContent"
    class="compilation__content"
    :class="{
      'compilation__content_empty' : isEmptyCompilation,
      'compilation__content_hidden': getIsDemoCatalog && mode !== 'noauth'
    }"
    @scroll="onScroll"
    @mousedown.left="mouseDown"
    @mouseup.left="mouseUp"
    @mouseleave="mouseLeave"
    @mousemove="mouseMove">
      <div
      v-if="!isEmptyCompilation"
      ref="groupElements"
      class="compilation__elements">
        <GroupsHeaders
        :is-admin="isAdmin"
        :mode="mode"
        :selected-groups="getSelectedGroups"
        :columns="columns"
        :is-all-selected="isAllSelected"
        is-compilation
        @clickCheckbox="selectAllGroups"
        @scrollToTop="scrollToTop" />

        <Draggable
        :list="compilationGroups"
        v-bind="dragOptions"
        class="compilation__groups"
        @choose="chooseDragElement"
        @end="endDrag"
        @change="changeDragInList">
          <div
          v-for="group in compilationGroups"
          :key="group.name + group.id">
            <Group
            :group="group"
            :group-is-checked="groupIsChecked(group)"
            :selected-groups="getSelectedGroups"
            :selected-type-of-groups="getSelectedTypeOfGroups"
            :hide-group-details="getHideGroupDetails"
            :is-admin="isAdmin"
            :is-manager="isManager"
            :is-agency="isAgency"
            is-compilation
            :mode="mode"
            :realisations-managers="realisationsManagers"
            :drag-mode="enableDragMode"
            :columns="columns"
            @clickCheckbox="(value) => setGroupIsChecked(value, group)" />
          </div>
        </Draggable>
      </div>
    </div>

    <div
    v-if="isEmptyCompilation"
    class="compilation__groups-empty flex-center">
      <span class="compilation__text">{{ titleForEmptyCompilation }}</span>
    </div>

    <transition name="fade">
      <div
      v-show="scrollPosition > 1320"
      class="compilation__up-button"
      :class="mode === 'noauth' && 'compilation__up-button_noauth'"
      @click="scrollToTop">
        <span class="compilation__up-button-text">{{ $t('up') }}</span>
        <DataIcon
        name="north"
        class="compilation__up-button-icon"
        :size="16"
        pointer />
      </div>
    </transition>

    <GroupActions
    v-if="compilationGroups.length"
    :mode="mode"
    :groups-data="compilationGroups"
    :selected-groups="getSelectedGroups"
    is-compilation
    show />

    <DemoBar
    v-if="getIsDemoCatalog && mode !== 'noauth'"
    :hide-group-details="getHideGroupDetails" />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import groupsData from '@/assets/js/groupsData';

import NavActions from '@/components/MainGroups/components/NavActions/NavActions.vue';
import GroupsHeaders from '@/components/MainGroups/components/GroupsHeaders.vue';
import Group from '@/components/MainGroups/components/Group/Group.vue';
import GroupActions from '@/components/MainGroups/components/GroupActions/GroupActions.vue';
import DemoBar from '@/components/MainGroups/components/DemoBar.vue';
import Draggable from 'vuedraggable';

export default {
  components: {
    GroupsHeaders, NavActions, Group, GroupActions, DemoBar, Draggable,
  },
  props: {
    compilationGroups: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: null,
    },
    customColumns: {
      type: Array,
      default: null,
    },
    realisationsManagers: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    scrollPosition: 0,
    columns: null,
    isScroll: false,
    startX: null,
    scrollLeft: null,
  }),
  created() {
    this.initColumns();
  },
  beforeDestroy() {
    this.$store.commit('compilations/setSelectedCompilationList', []);
  },
  methods: {
    initColumns() {
      const savedGroupsColumns = JSON.parse(localStorage.getItem('savedGroupsColumns050224'));
      if (savedGroupsColumns) {
        savedGroupsColumns.unshift({
          name: 'name',
          value: 'name',
          show: true,
          sort: 'name',
        });
      }
      this.columns = savedGroupsColumns || cloneDeep(groupsData.columns);
      if (this.customColumns && this.customColumns.length) {
        this.columns = this.columns.filter((item) => item.name === 'name' || this.customColumns.includes(item.name));
      }

      // Скрытие прайса для агентств, у которых свойство hideTradeOfferWithOurBuyerType === true
      if (this.isAgency) {
        const hidePrice = this.getUserInfo?.asEmployerAgency?.hideTradeOfferWithOurBuyerType || false;
        if (hidePrice) {
          this.columns = this.columns.filter((item) => item.value !== 'price_buy');
        }
      }
      const isHideDetails = JSON.parse(localStorage.getItem('hideDetails'));
      this.$store.commit('groups/setHideGroupDetails', isHideDetails === null ? true : isHideDetails);
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.$refs.compilationContent.scrollTo({ top: 0, behavior: 'smooth' });
    },
    onScroll() {
      const position = this.$refs.compilationContent.scrollTop;
      const { clientHeight } = this.$refs.compilationContent;

      if (position > this.scrollPosition) {
        this.$refs.compilation.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
      this.scrollPosition = position;

      if (this.$refs.compilationContent.scrollHeight <= clientHeight
      || this.isLastPage
      || this.isLoading
      || this.enableDragMode) {
        return;
      }

      const endScroll = Math.ceil(position + clientHeight) >= Math.ceil(this.$refs.compilationContent.scrollHeight);

      if (endScroll) {
        this.$emit('loadNewGroups');
      }
    },
    mouseDown(e) {
      if (e.target.className === 'group-name__block'
      || e.target.className === 'group-name'
      || e.target.className.includes('input')
      || e.target.className.includes('editor')
      || this.enableDragMode) return;

      this.isScroll = true;
      this.startX = e.pageX - this.$refs.compilationContent.offsetLeft;
      this.scrollLeft = this.$refs.compilationContent.scrollLeft;
    },
    mouseLeave() {
      this.isScroll = false;
    },
    mouseUp() {
      this.isScroll = false;
    },
    mouseMove(e) {
      if (!this.isScroll || this.showGroupMenu) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.compilationContent.offsetLeft;
      const walk = (x - this.startX) * 1; // scroll-fast
      this.$refs.compilationContent.scrollLeft = this.scrollLeft - walk;
    },
    selectAllGroups(value) {
      if (!value) {
        this.$store.commit('compilations/setTriggerForSelectedAllGroups', false);
        const groupsToUnselected = this.getSelectedGroups.filter((item) => !this.compilationGroups.some((el) => el.id === item.id && el.name === item.name));
        this.$store.commit('compilations/setSelectedCompilationList', groupsToUnselected);
      } else {
        const groupsToSelected = this.compilationGroups.filter((item) => !this.getSelectedGroups.some((el) => el.id === item.id && el.name === item.name));
        this.$store.commit('compilations/setSelectedCompilationList', [...this.getSelectedGroups, ...groupsToSelected]);
      }
    },
    setHideGroupDetails() {
      localStorage.setItem('hideDetails', !this.getHideGroupDetails);
      this.$store.commit('groups/setHideGroupDetails', !this.getHideGroupDetails);
    },
    groupIsChecked(group) {
      return this.getSelectedGroups.length > 0
        ? this.getSelectedGroups.some((item) => (item.name === group.name && item.id === group.id))
        : false;
    },
    setGroupIsChecked(value, group) {
      if (value) {
        const newSelectedGroups = [...this.getSelectedGroups, group];
        this.$store.commit('compilations/setSelectedCompilationList', newSelectedGroups);
        return;
      }
      const newSelectedGroups = this.getSelectedGroups.filter((item) => !(item.id === group.id && item.name === group.name));
      this.$store.commit('compilations/setSelectedCompilationList', newSelectedGroups);
      if (!newSelectedGroups.length) {
        this.$store.commit('compilations/setTriggerForSelectedAllGroups', false);
      }
    },
    changeDragInList(event) {
      // Перенос внутри папки
      const { element } = event.moved;
      const { newIndex } = event.moved;

      const isFolder = !!this.$store.getters['compilations/getDraggableFolder'];
      if (isFolder) return;

      const prevElementIndex = this.compilationGroups[newIndex - 1]?.positionId;
      const destinationIndex = prevElementIndex || this.compilationGroups[newIndex + 1]?.positionId;
      const folderId = prevElementIndex ? this.compilationGroups[newIndex - 1]?.folder?.id : this.compilationGroups[newIndex + 1]?.folder?.id;
      const place = prevElementIndex ? 'after' : 'before';

      const params = {
        compilationId: this.getCompilationInfo.id,
        positionsIds: [element.positionId], // id канала
        folderId,
        place, // after or before
        destinationPositionId: destinationIndex || null, // канал перед которым надо вставить (передается Place after или before)
        inOneFolder: true,
      };

      this.$store.dispatch('compilations/movePositionInCompilation', params)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Перемещено' });
          this.$emit('close');
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Ошибка', error: true });
          this.$emit('close');
        });
    },
    chooseDragElement(event) {
      const element = this.compilationGroups[event.oldIndex];
      this.$store.commit('compilations/setSelectedDragElement', element);
    },
    endDrag() {
      // Перенос в папку
      const isFolder = !!this.$store.getters['compilations/getDraggableFolder'];
      if (!isFolder) return;

      const currentFolder = this.$store.getters['compilations/getDraggableFolder'];
      const currentElement = this.$store.getters['compilations/getSelectedDragElement'];

      this.$store.commit('compilations/setDraggableFolder', null);

      const params = {
        compilationId: this.getCompilationInfo.id,
        positionsIds: [currentElement.positionId], // id канала
        folderId: currentFolder.id, // id папки
        place: 'before', // в начало папки
        inOneFolder: false,
      };

      this.$store.dispatch('compilations/movePositionInCompilation', params)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Перемещено' });
          this.$emit('close');
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Ошибка', error: true });
          this.$emit('close');
        });
    },
  },
  computed: {
    isEmptyCompilation() {
      return this.compilationGroups.length === 0 && !this.isLoading;
    },
    getSelectedGroups() {
      return this.$store.getters['compilations/getCompilationsSelectedList'];
    },
    getSelectedTypeOfGroups() {
      return this.$store.getters['groups/getSelectedTypeOfGroups'];
    },
    isLoading() {
      return this.$store.getters['app/getAppIsLoading'];
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    isManager() {
      return this.$store.getters['user/getIsManager'];
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
    isLastPage() {
      return this.$store.getters['compilations/getCurrentPage'] === (+this.$store.getters['compilations/getTotalPageCounts'] + 1);
    },
    showGroupMenu() {
      return this.$store.getters['groups/getShowGroupMenu'];
    },
    getIsDemoCatalog() {
      return this.$store.getters['user/getIsDemoCatalog'];
    },
    selectedGroups() {
      return this.$store.getters['compilations/getCompilationsSelectedList'];
    },
    isAllSelected() {
      return this.compilationGroups.length === this.selectedGroups.length;
    },
    getHideGroupDetails() {
      return this.$store.getters['groups/getHideGroupDetails'];
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: !this.enableDragMode,
        ghostClass: 'compilation__drag-group',
      };
    },
    enableDragMode() {
      return this.$store.getters['compilations/getEnableDragMode'];
    },
    titleForEmptyCompilation() {
      const isEmptyFolder = this.$store.getters['compilations/getSelectedFolder'];
      return isEmptyFolder ? 'В данной папке нет каналов' : this.$t('Empty compilation');
    },
    getCompilationInfo() {
      return this.$store.getters['compilations/getCurrentCompilationInfo'];
    },
    showCompilationFolders() {
      return this.getCompilationInfo.folders && this.getCompilationInfo.folders.length > 1;
    },
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
  },
  watch: {
    compilationGroups() {
      if (this.compilationGroups.length < 15) {
        this.scrollPosition = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.compilation {
  background: $primary-white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px);
  position: relative;
  margin: 20px 0px 0px;
  flex: 1;
  @include tablet-screen {
    margin: 20px 0px;
  }
  &_with-folders {
    height: calc(100vh - 100px);
  }
}

.compilation__nav-actions {
  background: white;
  height: 50px;
  width: 100%;
  padding: 0px 20px;
  border-radius: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 20px;
  }
}

.compilation__content {
  background: white;
  z-index: 1;
  overflow: auto;
  overflow-x: scroll;
  width: 100%;
  height: 100vh;
  &_empty {
    height: 0px;
  }
  &_hidden {
    overflow-y: hidden;
  }
}

.compilation__groups {
  min-width: 100%;
}

.compilation__groups-empty {
  flex-direction: column;
  height: 100%;
}

.compilation__text {
  color: $primary-gray;
  font-size: 16px;
}

.compilation__elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.compilation__up-button {
  display: none;
  @include desktop-screen {
    position: absolute;
    padding: 5px 20px;
    bottom: 110px;
    right: 40px;
    color: $primary-white;
    font-size: 12px;
    transition: 0.2s;
    cursor: pointer;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    background: $primary-blue;
    align-items: center;
    &_noauth {
      bottom: 60px;
    }
  }
}

.compilation__drag-group {
  opacity: 0.1;
}

</style>
