import { render, staticRenderFns } from "./GroupElement.vue?vue&type=template&id=5a58a88f&scoped=true"
import script from "./GroupElement.vue?vue&type=script&lang=js"
export * from "./GroupElement.vue?vue&type=script&lang=js"
import style0 from "./GroupElement.vue?vue&type=style&index=0&id=5a58a88f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a58a88f",
  null
  
)

export default component.exports