<template>
  <div
  class="tariff-request-bar">
    <div class="tariff-request-bar__container">
      <span class="tariff-request-bar__text tariff-request-bar__text_title">Оформите доступ к SocialData</span>
      <span class="tariff-request-bar__text">Получите доступ к нашему продукту на месяц и оцените его по достоинству</span>
      <div
      class="tariff-request-bar__button"
      @click="getAccessToCatalog">
        <img src="@/assets/svg/icons/shop-icon.svg">
        <span class="tariff-request-bar__text tariff-request-bar__text_button">Оформить тестовый период</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TariffRequestBar',
  methods: {
    getAccessToCatalog() {
      const modalParams = { name: 'DemoBarRequestModal' };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
  },
};
</script>

<style lang="scss" scoped>
.tariff-request-bar {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.tariff-request-bar__container {
  background: white;
  border-radius: 20px;
  border: 1px solid rgba($primary-blue, 0.2);
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include between-elements {
    margin-bottom: 24px;
  }
}

.tariff-request-bar__text {
  font-size: 16px;
  text-align: center;
  line-height: 28px;
  &_title {
    max-width: 650px;
    font-size: 28px;
    color: $primary-blue;
    font-weight: 600;
  }
  &_button {
    color: white;
  }
}

.tariff-request-bar__button {
  background: $primary-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 40px;
  border-radius: 33px;
  transition: 0.2s;
  cursor: pointer;
  @include between-elements {
    margin-right: 15px;
  }
  &:hover {
    opacity: 0.8;
  }
}
</style>
