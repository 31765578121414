<template>
  <div class="campaigns-list">
    <CampaignHeaders />
    <CampaignElement
    v-for="(campaign, index) in campaignsData"
    :key="campaign.id"
    :last-element="campaignsData.length > 1 && index === campaignsData.length - 1"
    :campaign="campaign" />
  </div>
</template>

<script>
import CampaignHeaders from './CampaignHeaders.vue';
import CampaignElement from './CampaignElement.vue';

// Копонент обёртка для списка кампаний
export default {
  name: 'CampaignsList',
  components: { CampaignHeaders, CampaignElement },
  props: {
    campaignsData: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
  }),
  methods: {
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.campaigns-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.campaigns-list__text {
  font-size: 14px;
  &_bold {
    font-weight: bold;
  }
}
</style>
