<template>
  <div
  ref="price-switcher"
  class="price-switcher"
  :style="isCompilation && 'position: relative'">
    <div
    class="price-switcher__switcher"
    @click="openMenu">
      <span class="price-switcher__text price-switcher__text_second">Цена по умолчанию</span>
      <span class="price-switcher__text price-switcher__text_blue">{{ getLabel }}</span>
    </div>
    <div
    v-if="isOpen"
    v-click-outside="onClickOutside"
    class="price-switcher__menu"
    :style="{
      top: `${top}px`,
      left: `${left}px`,
    }">
      <div class="price-switcher__price-container">
        <div
        v-if="getSelectedTypeOfGroups !== 'mave'"
        class="price-switcher__price-radios">
          <div
          v-for="type in getPriceTypes"
          :key="type.name"
          class="price-switcher__price-radio"
          :class="!type.disabled && 'pointer'"
          @click="changePriceType(type)">
            <DataRadio :checked="getSelectedPriceType === type.name" />
            <span
            class="price-switcher__text">
              {{ $t(type.name) }}
            </span>
          </div>
        </div>

        <div class="price-switcher__prices">
          <div
          v-for="variant in getPriceVariants"
          :key="variant.name"
          class="price-switcher__price"
          @click="changePriceVariant(variant)">
            <DataRadio :checked="getSelectedPriceVariant.value === variant.value" />
            <span class="price-switcher__text">{{ $t(variant.name) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import socialsPriceTypes from '@/assets/js/socialsPriceTypes';

export default {
  name: 'PriceSwitcher',
  data: () => ({
    isOpen: false,
    top: 70,
    left: 0,
  }),
  props: {
    isCompilation: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openMenu() {
      // Для mave один тип размещения, расхлоп не открываем
      // if (this.getSelectedTypeOfGroups === 'mave') return;

      if (!this.isCompilation) {
        this.top = this.$refs['price-switcher'].getBoundingClientRect().top + 70;
        this.left = this.$refs['price-switcher'].getBoundingClientRect().left;
      }
      this.isOpen = true;
    },
    onClickOutside() {
      this.isOpen = false;
    },
    changePriceType(type) {
      if (!type.variants) {
        this.changePriceVariant(null);
      } else {
        this.changePriceVariant(type.variants[0]);
      }
      if (this.isCompilation) {
        this.$store.commit('compilations/setSelectedPriceType', type.name);
        return;
      }
      this.$store.commit('groups/setSelectedPriceType', type.name);
    },
    changePriceVariant(variant) {
      if (this.isCompilation) {
        this.$store.commit('compilations/setSelectedPriceVariant', variant);
        return;
      }
      this.$store.commit('groups/setSelectedPriceVariant', variant);
    },
  },
  computed: {
    getPriceTypes() {
      // список типов цены, доступных для конкретной соц сети
      return socialsPriceTypes.find((item) => item.social === (this.getSelectedTypeOfGroups || 'telegram'))?.priceTypes;
    },
    getPriceVariants() {
      const selectedPriceTypes = this.getPriceTypes.find((item) => item.name === this.getSelectedPriceType);
      return selectedPriceTypes ? selectedPriceTypes.variants : this.getPriceTypes.variants || [];
    },
    getSelectedTypeOfGroups() {
      return this.$store.getters['groups/getSelectedTypeOfGroups'];
    },
    getLabel() {
      if (this.getSelectedTypeOfGroups === 'mave') {
        return 'Размещение';
      }
      const selectedPriceVariant = this.getSelectedPriceVariant?.name || '';
      return `${this.$t(this.getSelectedPriceType)} ${this.$t(selectedPriceVariant).toLowerCase()}`;
    },
    getSelectedPriceType() {
      if (this.isCompilation) {
        return this.$store.getters['compilations/getSelectedPriceType'];
      }
      return this.$store.getters['groups/getSelectedPriceType'];
    },
    getSelectedPriceVariant() {
      if (this.isCompilation) {
        return this.$store.getters['compilations/getSelectedPriceVariant'];
      }
      return this.$store.getters['groups/getSelectedPriceVariant'];
    },
  },
  watch: {
    getSelectedTypeOfGroups() {
      if (!this.isCompilation) {
        this.$store.commit('groups/setSelectedPriceVariant', { name: 'per24', value: '24' });
        this.$store.commit('groups/setSelectedPriceType', 'postPrice');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.price-switcher__switcher {
  min-width: 150px;
  padding: 10px;
  background: #F0F9FC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
  box-sizing: content-box;
  @include between-elements {
    padding-bottom: 5px;
  }
}

.price-switcher__text {
  color: #565656;
  font-size: 14px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
  &_second {
    font-weight: 400;
    color: #7a7a7a;
    font-size: 13px;
  }
  &_blue {
    color: $primary-blue;
  }
}

.price-switcher__menu {
  position: absolute;
  z-index: 10;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  min-width: 320px;
  @include between-elements {
    margin-bottom: 15px;
  }
}

.price-switcher__price-container {
  display: flex;
  min-width: 350px;
}

.price-switcher__price-radios {
  display: flex;
  flex-direction: column;
  padding-right: 30px;
  border-right: 1px solid #CCEFFA;
}

.price-switcher__price-radio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  @include between-elements {
    margin-right: 10px;
  }
}

.price-switcher__prices {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.price-switcher__price {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  @include between-elements {
    margin-right: 10px;
  }
  &:hover {
    .price-switcher__icon {
      opacity: 1;
    }
  }
}
</style>
