<template>
  <span
  class="data-skeleton"
  :class="[
    'animation-' + animationType,
    circle,
  ]"
  :style="{
    width: width ? `${width}px` : '100%',
    height: `${height}px`,
  }" />
</template>

<script>
export default {
  name: 'DataSkeleton',
  props: {
    animationType: {
      type: String,
      default: 'fade',
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: 40,
    },
    circle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.data-skeleton {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 20px;
  background-color: #f2f2f2;
}

.circle {
  border-radius: 100%;
}

.animation-pulse {
  animation: pulseAnimation 1.5s infinite; /* Анимация пульсации */
}

.animation-wave {
  animation: waveAnimation 2s infinite; /* Анимация волны */
}

.animation-fade {
  animation: fadeAnimation 1.5s infinite; /* Анимация затухания */
}

@keyframes pulseAnimation {
  0% {
    background-color: #f2f2f2; /* Цвет фона загрузки */
  }
  50% {
    background-color: #e6e6e6; /* Цвет фона загрузки */
  }
  100% {
    background-color: #f2f2f2; /* Цвет фона загрузки */
  }
}

@keyframes waveAnimation {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.5);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes fadeAnimation {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
</style>
