<template>
  <div>
    <div class="users-list">
      <UserHeaders />
      <UserElements
      v-for="user in usersData"
      ref="clientElement"
      :key="user.id"
      :user="user"
      @openDeleteUser="openDeleteUser" />
    </div>

    <!-- Модалка удаления -->
    <DataModal
    :show-modal="showDeleteUserModal"
    @close="showDeleteUserModal = false">
      <template #modal-content>
        <div class="users-list__confirm-modal">
          <span>Вы точно хотите удалить пользователя?</span>
          <div class="users-list__buttons">
            <DataButton
            text="Удалить"
            @handler="confirmDeleteUser" />
            <DataButton
            text="Отменить"
            type="outlined"
            @handler="showDeleteUserModal = false" />
          </div>
        </div>
      </template>
    </DataModal>
  </div>
</template>

<script>
import UserElements from './UserElement.vue';
import UserHeaders from './UserHeaders.vue';

// Копонент обёртка для списка юзеров в клиентах
export default {
  name: 'UsersList',
  components: { UserElements, UserHeaders },
  props: {
    usersData: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    showDeleteUserModal: false,
    currentUser: null,
  }),
  methods: {
    openDeleteUser(user) {
      this.showDeleteUserModal = true;
      this.currentUser = user;
      console.log(`Удаление пользователя с id - ${this.currentUser.id}`);
    },
    confirmDeleteUser() {
      console.log(`Пользователь с id - ${this.currentUser.id} удален`);
      this.showDeleteUserModal = false;
      this.currentUser = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.users-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.users-list__confirm-modal {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  @include between-elements {
    margin-bottom: 40px;
  }
}

.users-list__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include between-elements {
    margin-right: 20px;
  }
}
</style>
