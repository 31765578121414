<template>
  <div
  class="tariff-mini-burn"
  :class="(!showFull && !hideBurn) && 'tariff-mini-burn_show'">
    <div class="tariff-mini-burn__content">
      <div
      v-if="accessToCatalog"
      class="tariff-mini-burn__container">
        <div class="tariff-mini-burn__ok-icon" />
        <span class="tariff-mini-burn__text">Commit</span>
      </div>
      <div
      v-if="accessToCatalog"
      class="tariff-mini-burn__container">
        <span class="tariff-mini-burn__text">{{ expiredLabel }}</span>
      </div>
      <div
      v-else
      @click="getAccessToCatalog">
        <span class="tariff-mini-burn__text pointer">Запросить доступ</span>
      </div>
      <div
      v-if="accessToCatalog"
      class="tariff-mini-burn__container tariff-mini-burn__container_noborder"
      style="text-align: flex-end">
        <span class="tariff-mini-burn__text">Комиссия</span>
        <span class="tariff-mini-burn__text tariff-mini-burn__text_percent">{{ getComission }}</span>
      </div>
    </div>
    <DataIcon
    name="chevron_right"
    class="tariff-mini-burn__icon"
    @click.native="changeTariffBar" />
  </div>
</template>

<script>
export default {
  name: 'TariffMiniBurn',
  props: {
    showFull: {
      type: Boolean,
      default: false,
    },
    userBurnRate: {
      type: [Number, String],
      default: null,
    },
    expiredDate: {
      type: String,
      default: null,
    },
    comission: {
      type: [String, Number],
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    accessToCatalog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    hideBurn: false,
  }),
  methods: {
    changeTariffBar() {
      this.hideBurn = true;
      setTimeout(() => {
        this.$store.commit('app/setShowFullTariffRate', true);
        this.hideBurn = false;
      }, 150);
    },
    getAccessToCatalog() {
      const modalParams = { name: 'DemoBarRequestModal' };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
  },
  computed: {
    expiredLabel() {
      if (this.isAdmin) {
        return 'Доступ администратора';
      }
      if (!this.expiredDate) {
        return '-';
      }
      return `Доступ до ${this.expiredDate}`;
    },
    getComission() {
      if (this.isAdmin) {
        return '0%';
      }
      return this.comission ? `${this.comission}%` : '-';
    },
  },
};
</script>

<style lang="scss" scoped>
.tariff-mini-burn {
  position: absolute;
  top: 0px;
  right: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  max-width: 445px;
  border-radius: 10px;
  border: 1px solid #D8F2FA;
  padding: 18px 10px 5px;
  transition: 0.15s;
  opacity: 0;
  &_show {
    opacity: 1;
    top: 56px;
  }
}

.tariff-mini-burn__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 8px;
  }
  &:after {
    content: '';
    height: 19px;
    margin: 0px 15px;
    width: 1px;
    background: #DBDBDB;
  }
  &_noborder {
    &:after {
      margin: 0px;
      width: 0px;
    }
  }
}

.tariff-mini-burn__text {
  font-size: 12px;
  color: #7B7B7B;
  &_percent {
    color: #6AB16F;
    font-size: 14px;
    font-weight: 600;
  }
}

.tariff-mini-burn__content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tariff-mini-burn__ok-icon {
  width: 20px;
  height: 20px;
  background: url('../assets/check-ok.svg');
}

.tariff-mini-burn__icon {
  color: #008FBF;
  cursor: pointer;
}
</style>
