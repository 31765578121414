const socialsPriceTypes = [
  // TELEGRAM
  {
    social: 'telegram',
    priceTypes: [
      {
        name: 'postPrice',
        variants: [
          {
            name: 'per24', value: '24', field: 'durationInHours',
          },
          {
            name: 'per48', value: '48', field: 'durationInHours',
          },
          {
            name: 'per72', value: '72', field: 'durationInHours',
          },
          {
            name: 'without_delete', value: 'withoutDelete', field: 'durationInHours',
          },
          {
            name: 'fixedInTop', value: 'isFixedInTop', field: 'isFixedInTop',
          },
        ],
      },
      {
        name: 'integrationPrice',
        variants: null,
      },
      {
        name: 'circlePrice',
        variants: [
          {
            name: '15s', value: '15', field: 'circleDurationInSeconds',
          },
          {
            name: '30s', value: '30', field: 'circleDurationInSeconds',
          },
          {
            name: '60s', value: '60', field: 'circleDurationInSeconds',
          },
        ],
      },
      {
        name: 'streamPrice',
        variants: null,
      },
      {
        name: 'storyPrice',
        variants: null,
      },
      {
        name: 'nonStandartPrice',
        variants: null,
      },
    ],
  },
  // VKONTAKTE
  {
    social: 'vk',
    priceTypes: [
      {
        name: 'postPrice',
        variants: [
          {
            name: 'per24', value: '24', field: 'durationInHours',
          },
          {
            name: 'per48', value: '48', field: 'durationInHours',
          },
          {
            name: 'per72', value: '72', field: 'durationInHours',
          },
          {
            name: 'without_delete', value: 'withoutDelete', field: 'durationInHours',
          },
          {
            name: 'fixedInTop', value: 'isFixedInTop', field: 'isFixedInTop',
          },
          {
            name: 'isVkMarket', value: 'isVkMarket', field: 'isVkMarket',
          },
        ],
      },
      {
        name: 'integrationPrice',
        variants: null,
      },
      {
        name: 'storyPrice',
        variants: null,
      },
      {
        name: 'streamPrice',
        variants: null,
      },
      {
        name: 'nonStandartPrice',
        variants: null,
      },
    ],
  },
  // INSTAGRAM
  {
    social: 'instagram',
    priceTypes: [
      {
        name: 'postPrice',
        variants: [
          {
            name: 'per24', value: '24', field: 'durationInHours',
          },
          {
            name: 'per48', value: '48', field: 'durationInHours',
          },
          {
            name: 'per72', value: '72', field: 'durationInHours',
          },
          {
            name: 'without_delete', value: 'withoutDelete', field: 'durationInHours',
          },
          {
            name: 'fixedInTop', value: 'isFixedInTop', field: 'isFixedInTop',
          },
        ],
      },
      {
        name: 'integrationPrice',
        variants: null,
      },
      {
        name: 'storyPrice',
        variants: null,
      },
      {
        name: 'reelPrice',
        variants: null,
      },
      {
        name: 'streamPrice',
        variants: null,
      },
      {
        name: 'nonStandartPrice',
        variants: null,
      },
    ],
  },
  // ODNOKLASSNIKI
  {
    social: 'odnoklassniki',
    priceTypes: [
      {
        name: 'postPrice',
        variants: [
          {
            name: 'per24', value: '24', field: 'durationInHours',
          },
          {
            name: 'per48', value: '48', field: 'durationInHours',
          },
          {
            name: 'per72', value: '72', field: 'durationInHours',
          },
          {
            name: 'without_delete', value: 'withoutDelete', field: 'durationInHours',
          },
          {
            name: 'fixedInTop', value: 'isFixedInTop', field: 'isFixedInTop',
          },
        ],
      },
      {
        name: 'integrationPrice',
        variants: null,
      },
      {
        name: 'nonStandartPrice',
        variants: null,
      },
    ],
  },
  // YOUTUBE
  {
    social: 'youtube',
    priceTypes: [
      {
        name: 'postPrice',
        variants: [
          {
            name: 'per24', value: '24', field: 'durationInHours',
          },
          {
            name: 'per48', value: '48', field: 'durationInHours',
          },
          {
            name: 'per72', value: '72', field: 'durationInHours',
          },
          {
            name: 'without_delete', value: 'withoutDelete', field: 'durationInHours',
          },
          {
            name: 'fixedInTop', value: 'isFixedInTop', field: 'isFixedInTop',
          },
        ],
      },
      {
        name: 'integrationPrice',
        variants: null,
      },
      {
        name: 'streamPrice',
        variants: null,
      },
      {
        name: 'nonStandartPrice',
        variants: null,
      },
    ],
  },
  // YANDEX
  {
    social: 'yandex-zen',
    priceTypes: [
      {
        name: 'postPrice',
        variants: [
          {
            name: 'per24', value: '24', field: 'durationInHours',
          },
          {
            name: 'per48', value: '48', field: 'durationInHours',
          },
          {
            name: 'per72', value: '72', field: 'durationInHours',
          },
          {
            name: 'without_delete', value: 'withoutDelete', field: 'durationInHours',
          },
          {
            name: 'fixedInTop', value: 'isFixedInTop', field: 'isFixedInTop',
          },
        ],
      },
      {
        name: 'integrationPrice',
        variants: null,
      },
      {
        name: 'nonStandartPrice',
        variants: null,
      },
    ],
  },
  // MAVE
  {
    social: 'mave',
    priceTypes: [
      {
        name: 'postPrice',
        variants: [
          {
            name: 'Размещение', value: '24', field: 'durationInHours',
          },
        ],
      },
    ],
  },
];

export default socialsPriceTypes;
