<template>
  <div class="groups-moderation">
    <div
    v-if="!isEmptyGroups"
    ref="groupsContent"
    class="groups-moderation__content"
    @scroll="onScroll">
      <div
      class="groups-moderation__elements"
      :class="isLoadingGroups && 'groups-moderation__elements_loading'">
        <GroupsHeaders :source-type="sourceType" />
        <GroupElement
        v-for="group in groups"
        :key="group.id"
        :group="group"
        :source-type="sourceType"
        @approveGroup="approveGroup" />
      </div>
    </div>
    <div
    v-if="isEmptyGroups || isLoadingGroups"
    class="groups-moderation__elements-empty">
      <span class="groups-moderation__text_light">
        {{ isLoadingGroups ? 'Загрузка каналов...' : 'Нет каналов для модерации' }}
      </span>
    </div>

    <!-- Модалка для редактирования добавленного после модерации канала -->
    <DataModal
    :show-modal="showEditModal"
    no-close
    @close="showEditModal = false">
      <template #modal-content>
        <EditGroupModal
        :edited-group="editedGroup"
        @close="showEditModal = false" />
      </template>
    </DataModal>

    <!-- Модалка с добавлением причины отклонения -->
    <DataModal
    :show-modal="showRejectReasonModal"
    no-close
    @close="showRejectReasonModal = false">
      <template #modal-content>
        <div class="groups-moderation__reject-reason">
          <span class="groups-moderation__reject-reason-title">Укажите причину отклонения</span>
          <DataTextarea
          v-model="rejectReason"
          placeholder="Введите текст"
          :height="150" />
          <DataButton
          text="Подтвердить"
          class="groups-moderation__reject-reason-button"
          @handler="sendReject" />
        </div>
      </template>
    </DataModal>
  </div>
</template>

<script>
import GroupsHeaders from './GroupsHeaders.vue';
import GroupElement from './GroupElement.vue';
import EditGroupModal from './EditGroupModal.vue';

export default {
  name: 'GroupsModeration',
  components: { GroupsHeaders, GroupElement, EditGroupModal },
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    isLoadingGroups: {
      type: Boolean,
      default: false,
    },
    sourceType: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    editedGroup: null,
    showEditModal: false,
    showRejectReasonModal: false,
    rejectReason: '',
    approveParams: null,
  }),
  methods: {
    onScroll() {
      if (this.isLastPage || this.isLoadingGroups) {
        return;
      }

      const position = this.$refs.groupsContent.scrollTop;
      const { clientHeight } = this.$refs.groupsContent;

      const endScroll = Math.ceil(position + clientHeight) >= Math.ceil(this.$refs.groupsContent.scrollHeight);
      if (endScroll) {
        this.$emit('getModerateGroups', { reset: false });
      }
    },
    sendReject() {
      this.showRejectReasonModal = false;
      this.approveGroup(this.approveParams);
    },
    approveGroup(params) {
      if (params.sourceType === 'publisherBot' && !params.value && !this.approveParams) {
        this.approveParams = params;
        this.showRejectReasonModal = true;
        return;
      }
      this.approveParams = null;
      const newGroups = this.groups.filter((item) => item.id !== params.id);
      this.$store.dispatch('moderate/setModerateGroupApprove', { value: params.value, id: params.id, rejectReason: this.rejectReason })
        .then(({ data: { data } }) => {
          this.$store.commit('moderate/setGroupsForModerate', newGroups);
          this.editedGroup = data;
          this.rejectReason = '';
          if (params.sourceType === 'publisherBot' && params.value) {
            this.showEditModal = true;
          } else {
            this.$store.dispatch('app/setSnackBar', { text: params.value ? 'Подтверждено' : 'Отклонено' });
          }
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Что-то пошло не так. Перезагрузите страницу и попробуйте снова', error: true });
        });
    },
    openEditModal(group) {
      this.editedGroup = group;
      this.showEditModal = true;
    },
  },
  computed: {
    isEmptyGroups() {
      return this.groups.length === 0;
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    isLastPage() {
      return this.$store.getters['moderate/getIsLastPage'];
    },
  },
};
</script>

<style lang="scss" scoped>
.groups-moderation {
  background: $primary-white;
  border-radius: 12px;
  display: flex;
  flex: 1;
  overflow: auto;
  position: relative;
  margin: 20px;
}

.groups-moderation__content {
  background: white;
  z-index: 1;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.groups-moderation__elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
  transition: 0.2s;
  &_loading {
    opacity: 0.4;
    transition: 0.3s;
  }
}

.groups-moderation__elements-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  top: 50%;
  right: 0;
  text-align: center;
}

.groups-moderation__text {
  color: $primary-black;
  font-size: 14px;
  &_light {
    color: $primary-lightgray;
  }
}

.groups-moderation__reject-reason {
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.groups-moderation__reject-reason-title {
  display: block;
  margin-bottom: 20px;
  font-size: 18px;
  color: $primary-gray;
  font-weight: 600;
}

.groups-moderation__reject-reason-button {
  margin-top: 20px;
}

</style>
