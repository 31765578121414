<template>
  <div class="group-name-editor">
    <div class="group-name-editor__header-block">
      <span class="group-name-editor__text group-name-editor__text_title">{{ $t('editing_a_channel') }}</span>
    </div>
    <div class="group-name-editor__content">
      <div class="group-name-editor__elements">
        <!-- Название -->
        <div class="group-name-editor__element">
          <span class="group-name-editor__text group-name-editor__text_desc">{{ $t('name2') }}</span>
          <DataInput
          v-model.trim="groupName"
          :error="!groupName.length"
          class="group-name-editor__input"
          :placeholder="$t('enter_new_name')" />
        </div>
        <!-- Ссылка -->
        <div class="group-name-editor__element">
          <span class="group-name-editor__text group-name-editor__text_desc">{{ $t('link') }}</span>
          <DataInput
          v-model.trim="groupLink"
          class="group-name-editor__input"
          :error="!groupLink.length"
          :placeholder="$t('specify_the_correct_link')" />
        </div>
        <!-- Категории -->
        <div class="group-name-editor__element">
          <span class="group-name-editor__text group-name-editor__text_desc">{{ $t('categories') }}</span>
          <DataSelect
          v-model="groupCategories"
          :options="getCategoriesVariants"
          :error="!groupCategories.length"
          with-search
          placeholder="Выберите категории"
          class="group-name-editor__input"
          select-type="multi" />
        </div>
      </div>
      <!-- Описание -->
      <div class="group-name-editor__elements">
        <!-- Блоггер -->
        <div class="group-name-editor__element group-name-editor__element_row">
          <DataCheckbox
          v-model="isBlogger" />
          <span class="group-name-editor__text group-name-editor__text_desc">
            Блоггер
          </span>
        </div>
        <!-- Накрутка -->
        <div class="group-name-editor__element group-name-editor__element_row">
          <DataCheckbox
          v-model="groupIsWarning" />
          <span class="group-name-editor__text group-name-editor__text_desc">
            Пометить канал как накрученный
          </span>
        </div>
        <!-- TOP  -->
        <div class="group-name-editor__element group-name-editor__element_row">
          <DataCheckbox
          v-model="addInTop" />
          <span class="group-name-editor__text group-name-editor__text_desc">
            Добавить в топ
          </span>
        </div>
        <!-- Эксклюзив  -->
        <div class="group-name-editor__element group-name-editor__element_row">
          <DataCheckbox
          v-model="exclusive" />
          <span class="group-name-editor__text group-name-editor__text_desc">
            Эксклюзив
          </span>
        </div>
        <!-- Без рекламы  -->
        <div class="group-name-editor__element group-name-editor__element_row">
          <DataCheckbox
          v-model="withoutAd" />
          <span class="group-name-editor__text group-name-editor__text_desc">
            Без рекламы
          </span>
        </div>
      </div>
    </div>
    <div
    class="group-name-editor__element">
      <span class="group-name-editor__text group-name-editor__text_desc">
        {{ $t('description') }}
      </span>
      <DataTextarea
      v-model.trim="groupDesc"
      :height="150"
      class="group-name-editor__input"
      :placeholder="$t('Enter a description')" />
    </div>
    <div class="group-name-editor__button-block">
      <DataButton
      :text="$t('save')"
      :disabled="disabledSaveButton"
      @handler="saveGroup" />
      <DataButton
      :text="$t('cancel')"
      type="outlined"
      @handler="$emit('close')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupNameEditor',
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    groupName: null,
    groupLink: null,
    groupDesc: null,
    groupCategories: null,
    groupIsWarning: null,
    isBlogger: false,
    addInTop: false,
    exclusive: false,
    withoutAd: false,
  }),
  created() {
    this.groupName = this.group.name;
    this.groupLink = this.group.url;
    this.groupDesc = this.group.description;
    this.groupCategories = this.group.categories.map((category) => ({ name: category.name, value: category.id }));
    this.groupIsWarning = this.group.inspectReport?.warnings?.some((item) => item.code === 7) || false;
    this.isBlogger = this.group.isBlogger;
    this.exclusive = this.group.isExclusive;
    this.withoutAd = this.group.withoutAd;
    this.addInTop = this.group?.contentAnalysis?.isTopGroup || false;
  },
  methods: {
    saveGroup() {
      const newData = {
        name: this.groupName,
        url: this.groupLink,
        description: this.groupDesc,
        categories: this.groupCategories.map((category) => ({ id: category.value, name: category.name })),
        categoriesId: this.groupCategories.map((category) => category.value),
        isBlogger: this.isBlogger,
        isExclusive: this.exclusive,
        withoutAd: this.withoutAd,
      };

      const saveGroupData = this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData });
      const saveGroupWarnings = this.$store.dispatch(`group/${this.groupIsWarning ? 'addWarning' : 'removeWarning'}`,
        {
          id: this.group.id,
          social: this.group.social,
          codeNumber: 7,
        });

      const topPayload = {
        groupIdentity: {
          id: this.group.id,
          social: this.group.social,
        },
      };
      if (this.addInTop) {
        this.$store.dispatch('moderate/addToTopSji', topPayload)
          .then((data) => {
            this.group.contentAnalysis = data;
          })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar', {
              text: 'Ошибка при добавлении канала в топ',
              error: true,
            });
          });
      } else {
        this.$store.dispatch('moderate/deleteFromTop', topPayload)
          .then((data) => {
            this.group.contentAnalysis = data;
          })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar', {
              text: 'Ошибка при удалении канала из топа',
              error: true,
            });
          });
      }

      Promise.all([saveGroupData, saveGroupWarnings])
        .then(() => {
          this.group.name = this.groupName;
          this.group.url = this.groupLink;
          this.group.description = this.groupDesc;
          this.group.categories = this.groupCategories.map((category) => ({ id: category.value, name: category.name }));
          this.group.isBlogger = this.isBlogger;
          this.group.isExclusive = this.exclusive;
          this.group.withoutAd = this.withoutAd;

          const arrayWarnings = this.group.inspectReport?.warnings;

          if (this.groupIsWarning) {
            this.group.inspectReport.warnings.push({
              code: 7,
              message: 'Заподозрен в накрутке',
            });
          } else if (arrayWarnings) {
            this.group.inspectReport.warnings = this.group.inspectReport.warnings.filter((item) => item.code !== 7);
          }

          this.$emit('close');
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated') });
        })
        .catch(() => {
          this.$emit('close');
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('An error occurred while updating the data'),
              error: true,
            });
        });
    },
  },
  computed: {
    disabledSaveButton() {
      return !this.groupName || !this.groupLink || !this.groupCategories.length;
    },
    getCategoriesData() {
      return this.$store.getters['groups/getCategories'];
    },
    getCategoriesVariants() {
      return this.getCategoriesData
        .map((category) => ({
          name: category.name,
          value: category.id,
        }));
    },
  },
};
</script>

<style lang="scss" scoped>
.group-name-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 700px;
}

.group-name-editor__header-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 30px;
}

.group-name-editor__text {
  color: $primary-black;
  font-size: 16px;
  &_title {
    font-size: 22px;
  }
  &_desc {
    font-size: 14px;
  }
}

.group-name-editor__content {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  @include between-elements {
    margin-right: 40px;
  }
}

.group-name-editor__elements {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-name-editor__element {
  display: flex;
  flex-direction: column;
  position: relative;
  &_row {
    flex-direction: row;
    @include between-elements {
      margin-right: 10px;
    }
  }
}

.group-name-editor__button-block {
  padding-top: 40px;
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}

.group-name-editor__input {
  margin-top: 10px;
}

</style>
