<template>
  <div
  class="header flex-center">
    <div
    class="header__container"
    :class="{ 'header__container_nosidebar': getNosidebar}">
      <div class="header__info">
        <DataBurgerButton
        v-if="mode !== 'noauth'"
        id="header-burger-button"
        :is-open="getOpenSidebar"
        class="header__sidebar-button"
        @handler="toggleSideBar" />
        <div
        v-if="showLogo"
        @click.prevent="goToMain">
          <a :href="brandingLogo ? '/' : 'https://data.socialjet.pro'">
            <img
            v-if="brandingLogo"
            class="header__logo header__logo_brand pointer"
            :src="brandingLogo">
            <img
            v-else-if="mode !== 'noauth' || (mode === 'noauth' && !isLoading)"
            class="header__logo pointer"
            :src="require('@/assets/logos/social-data-logo.svg')">
          </a>
        </div>
        <div
        v-if="showSearchBlock"
        class="header__search">
          <img
          class="header__search-icon"
          :src="require('@/assets/svg/search-icon.svg')"
          @click="showSearch = !showSearch">
          <input
          v-if="showSearch || isDesktop"
          v-model="nameOrUrl"
          class="header__search-input"
          :placeholder="$t('search_any_channels')">
          <transition name="fade">
            <DataIcon
            v-if="nameOrUrl"
            name="close"
            class="header__reset-icon"
            :size="14"
            pointer
            @click.native="setSearch(null)" />
          </transition>
        </div>
        <div
        v-if="getNewGroupsCount"
        class="header__groups-stats">
          <span class="header__text">
            {{ $t('new_channels') }}
          </span>
          <transition
          name="fade"
          :duration="100"
          mode="out-in">
            <div
            :key="switchStats"
            class="header__groups-stats header__groups-stats_count pointer"
            @click="switchStats = !switchStats">
              <span
              class="header__text header__text_gray pointer">
                {{ getNewGroupsCount }}
              </span>
              <DataIcon
              name="expand_more"
              pointer
              :size="18" />
            </div>
          </transition>
        </div>
      </div>
      <div class="header__info">
        <Notifications
        v-if="mode !== 'noauth' && showNotifications && appIsLoaded"
        @hideNotifications="showNotifications = false" />
        <div
        v-if="isDesktop && mode !== 'noauth' && showNotifications"
        class="header__line" />
        <AccountBalance v-if="getIsEmployerAgency" />
        <div
        v-if="showSendRequestButton"
        class="header__send-button"
        @click="openCreatePlacement">
          <span class="header__text header__text_stats">
            {{ `+ ${$t('send_a_request')}` }}
          </span>
        </div>
        <div
        v-if="isDesktop"
        class="header__line" />
        <LangSelector
        v-if="mode !== 'noauth'"
        class="header__lang" />
        <div
        v-if="isDesktop && mode !== 'noauth'"
        class="header__line" />
        <Profile
        v-if="mode !== 'noauth'"
        @goToMain="goToMain" />
        <span
        v-if="mode === 'noauth'"
        class="header__text header__text_noauth pointer"
        @click="$router.push({ name: 'login' })">Войти</span>
      </div>
    </div>
    <portal>
      <transition name="fade">
        <Placement v-if="getPlacementParams" />
      </transition>
    </portal>
  </div>
</template>

<script>
import pickBy from 'lodash/pickBy';
import debounce from 'lodash/debounce';
import DataBurgerButton from '@/components/UI/DataBurgerButton.vue';
import Placement from '@/components/Placement/Placement.vue';
import Profile from './components/Profile.vue';
import LangSelector from './components/LangSelector.vue';
import AccountBalance from './components/AccountBalance.vue';
import Notifications from './components/Notifications/Notifications.vue';

export default {
  name: 'MainHeader',
  components: {
    Profile, LangSelector, DataBurgerButton, Placement, AccountBalance, Notifications,
  },
  props: {
    mode: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    switchStats: false,
    showSearch: false,
    searchInput: null,
    debounceUpdate: null,
    showNotifications: true,
  }),
  created() {
    if (this.isDesktop) {
      this.showSearch = false;
    }
    this.$store.dispatch('groups/getTotalNewGroups');
    this.searchInput = this.getSearchParams.nameOrUrl;
    this.debounceUpdate = debounce((value) => {
      this.setSearch(value);
    }, 1500);
  },
  methods: {
    setSearch(value) {
      const params = pickBy({ ...this.getSearchParams, nameOrUrl: value }, (param) => param !== null);
      const query = { group: this.getSelectedTypeOfGroups, ...params };
      this.$router.push({ name: 'catalog', query });
      this.$store.commit('collections/setShowSelectedGroups', false);
      this.$store.commit('favorites/setShowFavoriteGroups', false);
      this.$store.commit('groups/setLoadPage', 1);
      this.$store.commit('groups/setGroupsSearchParams', { ...this.getSearchParams, nameOrUrl: value });
      this.$store.commit('groups/setGroupsData', []);
      this.$store.dispatch('groups/loadGroupsData', { aggregate: true });
    },
    goToMain() {
      this.$store.commit('collections/setShowSelectedGroups', false);
      this.$store.commit('favorites/setShowFavoriteGroups', false);
      this.$store.commit('groups/setInitial', true);
      this.$store.commit('groups/resetGroupsSearchParams');

      if (this.$route.name !== 'catalog') {
        this.$router.push({ name: 'catalog' });
      }
    },
    openCreatePlacement() {
      if (this.getIsDemoCatalog) {
        this.$router.push({ name: 'tariffs' });
        return;
      }
      const params = {
        selectedGroups: this.getSelectedGroups,
        viewMode: false,
        isNewRevision: false,
        currentPlacement: null,
      };
      this.$store.commit('placements/setPlacementParams', params);
    },
    closeCreatePlacement() {
      this.$store.commit('placements/setPlacementParams', null);
    },
    toggleSideBar() {
      this.$store.commit('app/setShowSidebar', !this.getOpenSidebar);
    },
  },
  computed: {
    nameOrUrl: {
      get() {
        return this.getSearchParams.nameOrUrl;
      },
      set(value) {
        this.debounceUpdate(value);
      },
    },
    appIsLoaded() {
      return this.$store.getters['app/getAppIsLoaded'];
    },
    showSearchBlock() {
      return this.$route.name === 'catalog';
    },
    getSearchParams() {
      return this.$store.getters['groups/getGroupsSearchParams'];
    },
    getSelectedTypeOfGroups() {
      return this.$store.getters['groups/getSelectedTypeOfGroups'];
    },
    getSelectedGroups() {
      if (this.isCompilation) {
        return this.$store.getters['compilations/getCompilationsSelectedList'];
      }
      return this.$store.getters['groups/getSelectedGroups'];
    },
    getNewGroupsCount() {
      if (!this.$store.getters['groups/getTotalNewGroups']) {
        return false;
      }
      return this.switchStats
        ? `+${this.setNumberSpaces(this.$store.getters['groups/getTotalNewGroups']?.lastDay.total || 0)} ${this.$t('per_day')}`
        : `+${this.setNumberSpaces(this.$store.getters['groups/getTotalNewGroups']?.lastMonth.total || 0)} ${this.$t('per_month')}`;
    },
    isDesktop() {
      return window.innerWidth > 480;
    },
    getOpenSidebar() {
      return this.$store.getters['app/getShowSidebar'];
    },
    getIsDemoCatalog() {
      return this.$store.getters['user/getIsDemoCatalog'];
    },
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
    isClientAgency() {
      return this.$store.getters['user/getUserInfo']?.asClientAgency;
    },
    isCompilation() {
      return this.$route.name === 'compilation';
    },
    compilationLogoNoauth() {
      return this.$store.getters['collections/getCurrentCollection']?.brandLogo;
    },
    brandingLogo() {
      const logo = this.getUserInfo?.asEmployerAgency?.brandLogo || this.isClientAgency?.brandLogo || this.compilationLogoNoauth || false;
      const url = 'https://data-api.socialjet.pro';
      return logo
        ? `${url}${logo}`
        : false;
    },
    isLoading() {
      return this.$store.getters['app/getAppIsLoading'];
    },
    getPlacementParams() {
      return this.$store.getters['placements/getPlacementParams'];
    },
    getIsPublisher() {
      return this.$store.getters['user/getIsPublisher'];
    },
    showSendRequestButton() {
      return this.mode !== 'noauth' && !this.getIsPublisher;
    },
    showLogo() {
      return this.getUserInfo?.email !== 'jan-clod-one-dam@yandex.ru';
    },
    getIsEmployerAgency() {
      return this.$store.getters['user/getIsEmployerAgency'];
    },
    getNosidebar() {
      return this.mode === 'noauth';
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: $primary-white;
  height: 70px;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 16;
  width: 100vw;
  box-shadow: 0px 2px 3px -2px rgba(0, 174, 232, 0.16);
  box-shadow: 0px 4px 23px 0px rgba(0, 174, 232, 0.12);
  top: 0px;
  left: 0px;
  transition: opacity 0.2s;
}

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 2560px;
  padding: 0px 30px 0px 30px;
  height: 100%;
  background: white;
  z-index: 3;
  @include desktop-screen {
    padding: 0px 30px 0px 90px;
  }
  &_nosidebar {
    padding-left: 30px;
  }
}

.header__logo {
  width: 90px;
  margin-bottom: 18px;
  &_mediagate {
    margin-bottom: 0px;
  }
  &_brand {
    margin-bottom: 0px;
    width: 120px;
  }
}

.header__sidebar-button {
  display: block;
  padding-right: 10px;
  margin-bottom: 10px;
  @include desktop-screen {
    display: none;
  }
}
.header__info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include between-elements {
    margin-right: 20px;
  }
}

.header__search {
  display: none;
  @include tablet-screen {
    display: flex;
    align-items: center;
    width: 190px;
  }
  @include between-elements {
    margin-right: 10px;
  }
}

.header__groups-stats {
  display: none;
  @include between-elements {
    margin-right: 5px;
  }
  @include max-screen {
    display: flex;
    align-items: center;
    cursor: default;
  }
  &_count {
    margin-left: 5px;
  }
}

.header__send-button {
  display: none;
  @include tablet-screen {
    background: $primary-blue;
    padding: 0px 10px 0px 10px;
    height: 24px;
    min-width: 160px;
    border-radius: 8px;
    color: $primary-white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.header__text {
  font-size: 12px;
  line-height: 12px;
  color: $primary-lightgray;
  &_stats {
    color: $primary-white;
    font-weight: 400;
  }
  &_gray {
    color: $primary-gray;
  }
  &_noauth {
    color: $primary-gray;
    font-size: 14px;
    margin-right: 20px;
  }
}

.header__search-input {
  border: none;
  outline: none;
  width: 140px;
  line-height: 14px;
  font-size: 12px;
  &::placeholder {
    color: $primary-lightgray;
  }
  @include tablet-screen {
    width: 100%;
  }
}

.header__reset-icon {
  color: $primary-lightgray;
  margin-top: 2px;
}

.header__line {
  width: 0px;
  border-right: 1px solid rgb(226, 226, 226);
  height: 30px;
}

.header__lang {
  display: none;
  @include tablet-screen {
    display: flex;
    padding-left: 8px;
  }
}

.header__search-icon {
  width: 16px;
  height: 16px;
}

.header__mini-burn {
  position: absolute;
  bottom: -37px;
  right: 20px;
}

</style>
