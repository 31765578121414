<template>
  <div
  ref="geography-filter"
  class="geography-filter pointer"
  @click="openMenu">
    <div class="geography-filter__title">
      <span class="geography-filter__text geography-filter__text_second">
        {{ $t('geography') }}
      </span>
      <transition name="fade">
        <div
        v-if="isNotEmptyValues || setWithoutCountry"
        class="geography-filter__title-active" />
      </transition>
    </div>
    <div
    v-if="selectedCountries.length === 1 && !setWithoutCountry"
    class="geography-filter__country geography-filter__country_label">
      <img
      class="geography-filter__flag"
      :src="require(`@/assets/svg/flags/${getIcon()}.svg`)"
      alt="flag">
      <span
      class="geography-filter__item"
      :class="{ 'geography-filter__item_blue' : isNotEmptyValues }">
        {{ getLabel }}
      </span>
    </div>
    <span
    v-else
    class="geography-filter__text"
    :class="{ 'geography-filter__text_blue' : isNotEmptyValues }">
      {{ getLabel }}
    </span>
    <transition name="fade">
      <div
      v-if="isOpen"
      v-click-outside="onClickOutside"
      class="geography-filter__menu"
      :style="`top: ${top}px; left: ${left}px`"
      @wheel="closeMenu">
        <div class="geography-filter__container">
          <span class="geography-filter__text">
            {{ $t('select_countries') }}
          </span>
          <div class="geography-filter__countries">
            <div
            v-for="country in getFilteredCountries"
            :key="country.value"
            :class="getSelected(country) && 'geography-filter__country_selected'"
            class="geography-filter__country"
            @click="changeValue(country)">
              <img
              v-if="country.en"
              class="geography-filter__flag"
              :src="require(`@/assets/svg/flags/${country.en}.svg`)"
              alt="flag">
              <span
              class="geography-filter__item"
              :class="!country.en && 'geography-filter__item_noflag'">
                {{ $t(country.name) }}
              </span>
            </div>
          </div>
        </div>
        <span
        class="geography-filter__text geography-filter__text_second"
        @click="resetAll">
          {{ $t('reset_all') }}
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
import groupsData from '@/assets/js/groupsData';

export default {
  name: 'GeographyFilter',
  props: {
    countries: {
      type: Array,
      default: null,
    },
    withoutCountry: {
      type: [Boolean, String],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOpen: false,
    selectedCountries: null,
    countriesData: null,
    setWithoutCountry: null,
    top: 0,
    left: 0,
  }),
  created() {
    this.countriesData = groupsData.countries;

    this.setWithoutCountry = this.withoutCountry;
    this.selectedCountries = this.countries === null
      ? []
      : [...this.countries];
  },
  methods: {
    resetAll() {
      this.selectedCountries = [];
      this.setWithoutCountry = null;
      this.$emit('update:countries', null);
      this.$emit('update:withoutCountry', null);
    },
    openMenu() {
      this.top = this.$refs['geography-filter'].getBoundingClientRect().top + 50;
      this.left = this.$refs['geography-filter'].getBoundingClientRect().left;
      if (this.isOpen) {
        return;
      }
      this.isOpen = true;
    },
    onClickOutside() {
      this.$emit('update:countries', this.selectedCountries.length ? this.selectedCountries : null);
      this.$emit('update:withoutCountry', this.setWithoutCountry);

      this.isOpen = false;
    },
    getSelected(country) {
      if (country.value === 'withoutCountry') {
        return this.setWithoutCountry;
      }
      return this.selectedCountries.includes(`${country.value}`);
    },
    changeValue(country) {
      if (country.value === 'withoutCountry') {
        this.setWithoutCountry = this.setWithoutCountry ? null : true;
        return;
      }

      if (country.value === null) {
        this.selectedCountries = [];
        this.setWithoutCountry = null;
        return;
      }

      if (this.selectedCountries.includes(country.value)) {
        this.selectedCountries = this.selectedCountries.filter((el) => el !== country.value);
      } else {
        this.selectedCountries.push(country.value);
      }
    },
    getIcon() {
      return this.countriesData.find((item) => item.value === this.selectedCountries[0])?.en;
    },
    closeMenu() {
    },
  },
  computed: {
    isNotEmptyValues() {
      return this.selectedCountries.length;
    },
    getLabel() {
      if (this.selectedCountries.length === 0 && this.setWithoutCountry) {
        return 'Без страны';
      }
      if (this.selectedCountries.length === 1 && !this.setWithoutCountry) {
        return this.selectedCountries[0];
      }
      return (this.selectedCountries === null || this.selectedCountries.length === 0) && !this.setWithoutCountry
        ? this.$t('all')
        : `${this.$t('selected')} (${this.setWithoutCountry ? this.selectedCountries.length + 1 : this.selectedCountries.length})`;
    },
    getFilteredCountries() {
      return this.countriesData.filter((item) => item.value);
    },
  },
  watch: {
    reset(newValue) {
      if (newValue === false) {
        this.selectedCountries = [];
        this.setWithoutCountry = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.geography-filter {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 60px;
}

.geography-filter__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5px;
}

.geography-filter__title-active {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: $primary-blue;
  margin-left: 10px;
  margin-top: 1px;
}

.geography-filter__text {
  color: #565656;
  font-size: 14px;
  cursor: pointer;
  &_second {
    font-weight: 400;
    color: #7a7a7a;
    font-size: 13px;
  }
  &_blue {
    color: $primary-blue;
  }
}

.geography-filter__menu {
  position: absolute;
  width: 215px;
  z-index: 10;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: auto;
  @include last-child {
    margin-top: 20px;
  }
}

.geography-filter__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.geography-filter__countries {
  background-color: $primary-white;
  max-height: 300px;
  width: 100%;
  overflow: auto;
}

.geography-filter__country {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  cursor: pointer;
  padding: 5px;
  transition: .1s;
  @include between-elements {
    margin-right: 10px;
  }
  &:hover {
    transition: .1s;
    background: rgb(241, 241, 241);
  }
  &_selected {
    color: $primary-blue;
    background: rgb(241, 241, 241);
  }
  &_label {
    padding: 0px;
    @include between-elements {
      margin-right: 5px;
    }
    &:hover {
      transition: .1s;
      background: none;
    }
  }
}

.geography-filter__flag {
  width: 14px;
  height: 14px;
}

.geography-filter__item {
  color: black;
  font-size: 14px;
  &_noflag {
    margin-left: 26px;
  }
  &_blue {
    color: $primary-blue;
  }
}
</style>
