<template>
  <div class="add-request-status">
    <DataIcon
    name="close"
    class="add-request-status__close"
    :size="24"
    pointer
    @click.native="$emit('close')" />
    <span class="add-request-status__title">
      Статус запроса
    </span>
    <div class="add-request-status__content">
      <DataSelect
      v-model="selectedStatus"
      placeholder="Выберите статус"
      :options="statusOptions" />

      <DataTextarea
      v-model="comment"
      :height="150"
      placeholder="Введите комментарий" />

      <div class="add-request-status__buttons">
        <DataButton
        text="Сохранить"
        :width="155"
        @handler="sendStatus" />
        <DataButton
        text="Отменить"
        :width="155"
        type="outlined"
        @handler="$emit('close')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddRequestStatus',
  props: {
    request: {
      type: Object,
      default: null,
    },
    statusOptions: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    selectedStatus: null,
    comment: '',
  }),
  methods: {
    sendStatus() {
      if (!this.selectedStatus) return;
      const params = { id: this.request.requestPlacement.id, status: this.selectedStatus.value };
      this.$store.dispatch('placements/setAdminStatusInRequest', params)
        .then(() => {
          this.$emit('setStatus', this.selectedStatus);
        })
        .finally(() => {
          if (this.comment.length) return;
          this.$emit('close');
        });
      if (this.comment.length) {
        const commentParams = { requestPlacementId: this.request.requestPlacement.id, content: this.comment, isOur: 'true' };
        this.$store.dispatch('placements/addCommentToRequest', commentParams)
          .finally(() => {
            this.$emit('setOurComment', this.comment);
            this.$emit('close');
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-request-status {
  border: 1px solid #CCEFFA;
  border-radius: 12px;
  padding: 32px;
  background: white;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  z-index: 2;
}

.add-request-status__close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.add-request-status__title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 24px;
}

.add-request-status__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  @include between-elements {
    margin-bottom: 20px;
  }
}

.add-request-status__buttons {
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}
</style>
