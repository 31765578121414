<template>
  <div class="sji-moderation-screen">
    <div class="sji-moderation-screen__title">
      <span class="sji-moderation-screen__text">
        Модерация SJI
      </span>
      <div
      class="sji-moderation-screen__tabs">
        <div
        v-for="tab in tabs"
        :key="tab.name"
        class="sji-moderation-screen__tab"
        @click="changeSelectedTab(tab.value)">
          <span
          class="sji-moderation-screen__tab-text">
            {{ tab.name }}
          </span>
          <div
          v-if="getSjiRequestsCount[tab.counter]"
          :class="`sji-moderation-screen__tab-counter_${tab.color}`"
          class="sji-moderation-screen__tab-counter">
            <span class="sji-moderation-screen__tab-counter-text">{{ getSjiRequestsCount[tab.counter] || null }}</span>
          </div>
        </div>
      </div>
    </div>

    <SjiModeration
    :is-loading="isLoading"
    :groups="getGroupsForModerate"
    :selected-tab="selectedTab"
    @getSjiModerateGroups="getSjiModerateGroups" />
  </div>
</template>

<script>
import SjiModeration from './components/SjiModeration.vue';

export default {
  name: 'GroupsModerationScreen',
  components: {
    SjiModeration,
  },
  data: () => ({
    isLoading: false,
    tabs: [
      {
        name: 'В работе', value: 'wait', counter: 'wait', color: 'orange',
      },
      {
        name: 'Подтверждённые', value: 'applied', counter: 'applied', color: 'green',
      },
      {
        name: 'Отклонённые', value: 'rejected', counter: 'rejected', color: 'red',
      },
    ],
    selectedTab: 'wait',
  }),
  beforeDestroy() {
    this.$store.commit('moderate/setGroupsForModerate', []);
  },
  created() {
    this.getSjiModerateGroups({ reset: true });
    this.$store.dispatch('moderate/getSjiRequestsCount');
  },
  methods: {
    getSjiModerateGroups(params) {
      if (this.isLoading) return;

      this.isLoading = true;
      this.$store.dispatch('moderate/getSjiModerateGroups', { status: this.selectedTab, reset: params.reset })
        .finally(() => {
          this.isLoading = false;
        });
    },
    changeSelectedTab(value) {
      if (this.selectedTab === value || this.isLoading) return;
      this.selectedTab = value;
      this.getSjiModerateGroups({ reset: true });
    },
  },
  computed: {
    getGroupsForModerate() {
      return this.$store.getters['moderate/getGroupsForModerate'];
    },
    getSjiRequestsCount() {
      return this.$store.getters['moderate/getSjiRequestsCount'] || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.sji-moderation-screen {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
}

.sji-moderation-screen__title {
  margin: 0px 20px 0px;
  @include between-elements {
    margin-right: 20px;
  }
}

.sji-moderation-screen__text {
  font-size: 18px;
  font-weight: 600;
  color: #575757;
}

.sji-moderation-screen__tabs {
  display: flex;
  margin-top: 10px;
  @include between-elements {
    margin-right: 15px;
  }
}

.sji-moderation-screen__tab {
  position: relative;
  display: flex;
  align-items: center;
  height: 25px;
  justify-content: flex-start;
}

.sji-moderation-screen__tab-text {
  color: rgba($primary-gray, 0.5);
  font-size: 13px;
  transition: 0.2s;
  cursor: pointer;
  display: inline-block;
  &_selected {
    color: $primary-blue;
  }
}

.sji-moderation-screen__tab-counter {
  display: flex;
  border-radius: 6px;
  background: $primary-red;
  padding: 2px 5px;
  margin-left: 5px;
  &_orange {
    background: orange;
  }
  &_green {
    background: $primary-green;
  }
  &_red {
    background: $primary-red;
  }
}

.sji-moderation-screen__tab-counter-text {
  font-size: 12px;
  color: white;
}
</style>
