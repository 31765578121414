<template>
  <img
  class="data-preloader"
  src="@/assets/svg/spinner-loader.svg">
</template>

<script>
export default {
  name: 'DataPreloader',
};
</script>

<style lang="scss" scoped>
.data-preloader {
  user-select: none;
  width: 120px;
  height: 120px;
}
</style>
