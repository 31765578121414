import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    myGroupsData: [],
    loadParams: {
      'page-size': 40,
      page: 1,
    },
    selectedTypeOfGroups: 'telegram',
    totalPageCounts: null,
    selectedGroups: [],
    loadingGroups: false,
  },
  getters: {
    getMyGroupsData: (state) => state.myGroupsData,
    getTotalPageCounts: (state) => state.totalPageCounts,
    getIsLastPage: (state) => state.loadParams.page === +state.totalPageCounts + 1,
    getSelectedTypeOfGroups: (state) => state.selectedTypeOfGroups,
    getSelectedGroups: (state) => state.selectedGroups,
    getLoadingGroups: (state) => state.loadingGroups,
  },
  mutations: {
    setMyGroupsData: (state, value) => { state.myGroupsData = value; },
    setTotalPageCounts: (state, value) => { state.totalPageCounts = value; },
    setLoadPage: (state, value) => { state.loadParams.page = value; },
    setSelectedTypeOfGroups: (state, value) => { state.selectedTypeOfGroups = value; },
    setSelectedGroups: (state, value) => { state.selectedGroups = value; },
    setLoadingGroups: (state, value) => { state.loadingGroups = value; },
  },
  actions: {
    loadMyGroups({ state, getters, commit }, params = { reset: false }) {
      commit('setLoadingGroups', true);

      if (params.reset) {
        commit('setMyGroupsData', []);
        commit('setLoadPage', 1);
        commit('setTotalPageCounts', null);
      }

      return api.get(`/group-${state.selectedTypeOfGroups}`, {
        params: {
          ...state.loadParams, sortBy: state.sortBy, sortDirection: state.sortDir, userId: params.userId,
        },
      })
        .then((response) => {
          commit('setMyGroupsData', [...getters.getMyGroupsData, ...response.data.data]);
          commit('setLoadPage', state.loadParams.page + 1);
          commit('setTotalPageCounts', response.headers['x-pagination-page-count']);
          commit('setLoadingGroups', false);
          return Promise.resolve(response.data.data);
        })
        .catch((error) => {
          commit('setMyGroupsData', []);
          commit('setLoadingGroups', false);
          console.warn('Error in action loadMyGroups; Error -', error);
        });
    },
  },
};
