<template>
  <div class="compilations-header">
    <span class="compilations-header__title">Подборки</span>

    <!-- Добавление подборки -->
    <div
    v-if="selectedTab === 'my'"
    class="compilations-header__add-folder-block">
      <div
      class="compilations-header__add-folder-button"
      @click="toggleAddFolder">
        <DataIcon
        name="add"
        :size="16" />
        <span>Добавить папку</span>
      </div>
      <div v-if="showAddFolder">
        <div
        ref="addFolder"
        v-click-outside="toggleAddFolder"
        class="compilations-header__add-folder">
          <DataInput
          ref="input"
          v-model.trim="folderName"
          submit-icon="done"
          placeholder="Введите название папки"
          theme="white"
          @submitValue="createFolder" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompilationsHeader',
  props: {
    selectedTab: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    showAddFolder: false,
    folderName: '',
    isLoading: false,
  }),
  methods: {
    createFolder() {
      if (this.isLoading) {
        this.$store.dispatch('app/setSnackBar', { text: 'Происходит добавление папки, пожалуйста подождите' });
        return;
      }
      if (!this.folderName.length) {
        this.$store.dispatch('app/setSnackBar', { text: 'Укажите название папки' });
        return;
      }
      this.isLoading = true;
      const userId = this.$store.getters['user/getUserInfo']?.id;
      const params = { userId, folderName: this.folderName };
      this.$store.dispatch('compilations/createFolderForCompilations', params)
        .then(() => {
          this.toggleAddFolder();
          this.$store.dispatch('app/setSnackBar', { text: 'Папка создана' });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    toggleAddFolder() {
      this.showAddFolder = !this.showAddFolder;
      if (this.showAddFolder) {
        this.$nextTick(() => {
          this.$store.commit('app/setShowAppOverlay', this.$refs.addFolder);
          this.$refs.input.focus();
        });
      } else {
        this.$store.commit('app/setShowAppOverlay', null);
      }
      this.folderName = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.compilations-header {
  margin: 0px 20px;
  display: flex;
  align-items: center;
  min-height: 40px;
  @include between-elements {
    margin-right: 10px;
  }

  .compilations-header__title {
    font-size: 18px;
    font-weight: 600;
  }

  .compilations-header__add-folder-block {
    margin-left: 20px;
    position: relative;
  }

  .compilations-header__add-folder {
    position: absolute;
    width: 280px;
    top: 42px;
    left: -53px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 20%);
  }

  .compilations-header__add-folder-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $primary-blue;
    color: white;
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap;
    @include between-elements {
      margin-right: 10px;
    }
    border-radius: 8px;
    padding: 7px 20px 7px 10px;
    transition: 0.1s;
    &:hover {
      background: rgba($primary-blue, 0.8);
    }
  }
}
</style>
