<template>
  <div
  class="tariffs-banner"
  :class="{ 'tariffs-banner_hide': hideBanner }">
    <div class="tariffs-banner__body">
      <span class="tariffs-banner__text tariffs-banner__text_title">
        Доступ к SocialData можно получить двумя способами:
      </span>
      <DataIcon
      name="close"
      :size="24"
      class="tariffs-banner__close-icon pointer"
      @click.native="closeTariffBanner" />
      <ul class="tariffs-banner__options">
        <li
        v-for="option in options"
        :key="option.id"
        class="tariffs-banner__text">
          {{ option.text }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    options: [
      // eslint-disable-next-line max-len
      { id: 1, text: 'Бесплатно: при условии выполнения stay tuned commit (минимального бюджета, потраченного на рекламные кампании в течение месяца через сервис SocialData в размере 100 000р' },
      { id: 2, text: 'Платно: оплатив тариф в размере 25 000р' },
    ],
    hideBanner: false,
  }),
  methods: {
    closeTariffBanner() {
      this.hideBanner = true;
      // setTimeout(() => {
      //   this.$emit('closeTariffBanner');
      // }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.tariffs-banner {
  width: 100%;
  background: $primary-blue;
  max-height: 420px;
  transition: margin-bottom 0.5s ease, max-height 0.2s ease, opacity 0.3s ease;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  opacity: 1;
  margin-bottom: 50px;
  &_hide {
    max-height: 0;
    margin-bottom: 0;
    opacity: 0;
    transition: margin-bottom 0.5s ease, max-height 0.2s ease, opacity 0.3s ease;
  }
}

.tariffs-banner__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 40px;
}

.tariffs-banner__close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
}

.tariffs-banner__text {
  color: white;
  font-weight: 400;
  font-size: 16px;
  &_title {
    font-weight: 500;
    font-size: 24px;
  }
}

.tariffs-banner__options {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  margin: 20px 0px 0px;
  @include between-elements {
    margin-bottom: 10px;
  }
}
</style>
