<template>
  <div class="group-discount">
    <span
    :class="isAdmin && 'pointer'"
    class="group-discount__text group-discount__text_light"
    @click="openEditor">
      {{ isEmptyData ? '—' : group.discount + '%' }}
    </span>

    <transition name="fade">
      <div
      v-if="showEditor"
      v-click-outside="onClickOutside"
      class="group-discount__editor">
        <div class="group-discount__editor-element">
          <input
          :value="discountValue"
          type="number"
          max="100"
          min="0"
          placeholder="Укажите скидку"
          class="group-discount__input"
          @input="handleInput">
        </div>
        <DataButton
        :width="145"
        :text="$t('save')"
        type="text"
        @handler="saveChanges" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'GroupDiscount',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    disableEditor: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showEditor: false,
    discountValue: null,
  }),
  methods: {
    openEditor() {
      if (!this.isAdmin || this.disableEditor) return;

      this.showEditor = true;
      this.discountValue = this.group.discount;
    },
    closeEditor() {
      this.showEditor = false;
      this.discountValue = null;
    },
    handleInput(event) {
      const prev = this.discountValue;
      const { value } = event.target;
      this.discountValue = value;
      if (value < 0) this.discountValue = prev;
      if (value > 100) this.discountValue = prev;
    },
    onClickOutside() {
      this.closeEditor();
    },
    saveChanges() {
      const discount = this.discountValue === '' ? null : this.discountValue;
      const newData = {
        discount: this.discountValue === '' ? null : this.discountValue,
      };
      this.showEditor = false;
      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData });

      this.$store.dispatch('groups/setDiscount', { id: this.group.id, social: this.group.social, discount })
        .then(() => {
          this.group.discount = this.discountValue === '' ? null : this.discountValue;
          this.$store.dispatch('group/getCurrentGroup', { id: this.group.id, social: this.group.social })
            .then((data) => {
              this.group.products = data.products;
              this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
            });
        });
    },
  },
  computed: {
    isEmptyData() {
      return this.group.discount === null || this.group.discount === '';
    },
  },
};
</script>

<style lang="scss" scoped>
.group-discount {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 180px;
  min-height: 50px;
  position: relative;
}

.group-discount__text {
  font-size: 14px;
  padding: 15px 10px;
  color: $primary-gray;
  text-align: center;
  word-break: break-all;
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

.group-discount__editor {
  position: absolute;
  top: 0px;
  left: 30px;
  width: 185px;
  border-radius: 12px;
  padding: 20px 20px 0px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-discount__editor-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.group-discount__input {
  padding: 5px 10px;
  border: 1px solid $primary-lightgray;
  border-radius: 8px;
  font-size: 12px;
  width: 145px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

</style>
