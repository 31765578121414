<template>
  <div class="data-snackbars">
    <transition-group name="fade">
      <div
      v-for="item in getAppSnackBars"
      :key="item.id"
      class="data-snackbar__snackbar"
      :class="item.error && 'data-snackbar__snackbar_error'">
        <div class="data-snackbar__content">
          <span>{{ item.text }}</span>
        </div>
        <DataIcon
        name="close"
        :size="20"
        class="data-snackbar__icon"
        @click.native="closeSnackBar(item)" />
      </div>
    </transition-group>
  </div>
</template>

<script>

export default {
  name: 'DataSnackbar',
  methods: {
    closeSnackBar(snackBar) {
      this.$store.dispatch('app/removeSnackBar', snackBar);
    },
  },
  computed: {
    showAppSnackBar() {
      return this.getAppSnackBars.length;
    },
    getAppSnackBars() {
      return this.$store.getters['app/getAppSnackBars'];
    },
  },
};
</script>

<style lang="scss" scoped>
.data-snackbars {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  top: 20px;
  right: 50px;
}

.data-snackbar__snackbar {
  width: 30vw;
  min-width: 300px;
  max-width: 500px;
  color: $primary-white;
  font-size: 14px;
  background: $primary-blue;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  min-height: 80px;
  border-radius: 12px;
  &_error {
    background: #eb4d4b;
  }
}

.data-snackbar__content {
  padding: 10px 40px 10px 20px;
}

.data-snackbar__icon {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.fade-move, .fade-enter-active, .fade-leave-active {
  transition: all .4s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(-60px);
}
.fade-leave-active {
  position: absolute;
  transform: translateX(50%);
}

</style>
