<template>
  <DataModal
  :show-modal="!!getCurrentModal.name"
  :modal-style="getCurrentModal.styles"
  v-bind="getCurrentModal.modalProps"
  @close="closeAppModal">
    <template #modal-content>
      <component
      :is="getCurrentModal.name"
      v-bind="getCurrentModal.props"
      @close="closeAppModal" />
    </template>
  </DataModal>
</template>

<script>
import GroupEditorModal from './modals/GroupEditorModal.vue';
import GroupApproveModal from './modals/GroupApproveModal.vue';
import ExportGroupsModal from './modals/ExportGroupsModal.vue';
import EditCollectionModal from './modals/EditCollectionModal.vue';
import SaveCollectionModal from './modals/SaveCollectionModal.vue';
import DemoBarRequestModal from './modals/DemoBarRequestModal.vue';
import PlacementResultModal from './modals/PlacementResultModal.vue';
import CompilationFolderModal from './modals/CompilationFolderModal.vue';
import SetResponsibleModal from './modals/SetResponsibleModal.vue';

export default {
  data: () => ({}),
  components: {
    GroupEditorModal,
    GroupApproveModal,
    ExportGroupsModal,
    EditCollectionModal,
    SaveCollectionModal,
    DemoBarRequestModal,
    PlacementResultModal,
    CompilationFolderModal,
    SetResponsibleModal,
  },
  methods: {
    closeAppModal() {
      this.$store.commit('app/setCurrentModal', false);
    },
  },
  computed: {
    getCurrentModal() {
      return this.$store.getters['app/getCurrentModal'] || {};
      // получаем из стейта объект модалки
      // name = название компонента, встраиваемого в модалку
      // props = объект с пропсами для компонента
      // styles = объект с стилями для самой модалки (ширина, высота)
      // modalProps - объект с пропсами для самой модалки
    },
  },
};
</script>

<style>

</style>
