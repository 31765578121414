import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    selectedDescription: 'description',
  },
  getters: {
    getSelectedDescription: (state) => state.selectedDescription,
  },
  mutations: {
    setSelectedDescription: (state, value) => { state.selectedDescription = value; },
  },
  actions: {
  },
};
