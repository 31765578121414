<template>
  <div class="compilations-list">
    <div
    v-if="compilations.length"
    class="compilations-list-wrapper">
      <div class="compilations-list__content">
        <CompilationsHeaders />
        <Draggable
        :list="compilations"
        v-bind="dragOptions"
        class="compilations-list__elements"
        @choose="chooseDragElement"
        @end="endDrag"
        @change="changeDragInList">
          <CompilationsElement
          v-for="(compilation, index) in compilations"
          :key="compilation.id"
          :last-element="compilations.length > 1 && index === compilations.length - 1"
          :compilation="compilation" />
        </Draggable>
      </div>
    </div>
    <span
    v-else
    class="compilations-list__text compilations-list__text_light">
      {{ 'Список подборок пуст' }}
    </span>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import CompilationsHeaders from './components/CompilationsHeaders.vue';
import CompilationsElement from './components/CompilationsElements.vue';

export default {
  name: 'Requests',
  components: { CompilationsHeaders, CompilationsElement, Draggable },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    compilations: {
      type: Array,
      default: null,
    },
  },
  methods: {
    chooseDragElement(event) {
      const element = this.compilations[event.oldIndex];
      this.$store.commit('compilations/setSelectedDragElement', element);
    },
    endDrag() {
      // Перенос в папку
      const isFolder = !!this.$store.getters['compilations/getDraggableFolder'];
      if (!isFolder) return;

      const currentFolder = this.$store.getters['compilations/getDraggableFolder'];
      const currentElement = this.$store.getters['compilations/getSelectedDragElement'];
      const params = { folderId: currentFolder.id, compilationId: currentElement.id };
      this.$store.dispatch('compilations/addCompilationToFolder', params);
      this.$store.commit('compilations/setDraggableFolder', null);
    },
    changeDragInList(event) {
      // Перенос внутри папки
      const isFolder = !!this.$store.getters['compilations/getDraggableFolder'];

      if (isFolder) return;

      const { element } = event.moved;
      const { newIndex } = event.moved;

      const prevElementIndex = this.compilations[newIndex - 1]?.compilationPositionId;
      const destinationIndex = prevElementIndex || this.compilations[newIndex + 1]?.compilationPositionId;
      const place = prevElementIndex ? 'after' : 'before';
      const userId = this.$store.getters['user/getUserInfo']?.id;

      const params = {
        payload: {
          positionId: element.compilationPositionId, // id подборки
          destinationPositionId: destinationIndex || null, // канал перед которым надо вставить (передается Place after или before)
          place, // after or before
          folderId: this.getSelectedFolder.id,
        },
        userId,
      };

      this.$store.dispatch('compilations/moveCompilationPositionInFolder', params)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Перемещено' });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Ошибка', error: true });
        });
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'compilations-list__drag-group',
      };
    },
    getSelectedFolder() {
      return this.$store.getters['compilations/getSelectedCompilationsFolder'];
    },
  },
};
</script>

<style lang="scss" scoped>
.compilations-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: auto;
  margin-top: -32px;
}

.compilations-list-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: auto;
}

.compilations-list__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  height: 100%;
  position: relative;
  &::-webkit-scrollbar { width: 0; }
}

.compilations-list__elements {
  min-width: 100%;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.compilations-list__text {
  font-size: 14px;
  color: #575757;
  &_light {
    color: $primary-lightgray;
  }
}

.compilations-list__drag-group {
  opacity: 0.1;
}
</style>
