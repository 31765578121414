<template>
  <div class="role-select">
    <div
    class="role-select_item"
    @click="$emit('selectRole', 'rekl')">
      <img src="../assets/rekl.png">
      <span class="role-select_text role-select_text_title">
        Рекламодатель / агентство
      </span>
      <span class="role-select_text">
        Размещайтесь на самых выгодных условиях и отслеживайте статистику по рекламным кампаниям в прямом эфире
      </span>
    </div>
    <div
    class="role-select_item"
    @click="openBot">
      <img src="../assets/publisher.png">
      <span class="role-select_text role-select_text_title">
        Я паблишер
      </span>
      <span class="role-select_text">
        Монетизируйте свои площадки на максимум с самой большой базой рекламодателей и разными форматами
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoleSelect',
  methods: {
    openBot() {
      window.open('https://t.me/SocialjetNewBot', '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.role-select {
  display: flex;
  flex-direction: column;
  margin: 10px 0px 100px;
  @include between-elements {
    margin-bottom: 30px;
  }
  @include desktop-screen {
    flex-direction: row;
    @include between-elements {
      margin-bottom: 0px;
      margin-right: 20px;
    }
  }
}

.role-select_item {
  padding: 40px;
  background: #F4FCFF;
  border-radius: 32px;
  border: 1px solid #D8F2FA;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  max-width: 520px;
  cursor: pointer;
  @include between-elements {
    margin-bottom: 24px;
  }
  @include desktop-screen {
    height: 280px;
  }
}

.role-select_text {
  font-size: 16px;
  color: #4F4F4F;
  font-weight: 400;
  &_title {
    font-size: 26px;
    font-weight: 600;
    color: #232323;
  }
}
</style>
