import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    notificationsList: [],
  },
  getters: {
    getNotificationsList: (state) => state.notificationsList,
  },
  mutations: {
    setNotificationsList: (state, value) => { state.notificationsList = value; },
  },
  actions: {
    // Получение списка всех уведомлений
    getNotificationsList({ commit }) {
      return api.get('/notification')
        .then(({ data: { data } }) => {
          commit('setNotificationsList', data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action getNotificationsList; Error -', error);
        });
    },

    // Удаление уведомления (или нескольких)
    deleteNotifications({ dispatch }, ids) {
      const params = { notificationIds: ids };
      return api.delete('/notification/delete', { params })
        .then(() => Promise.resolve())
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'deleteNotifications' }, { root: true });
          console.warn('Error in action -- deleteNotification --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Прочесть уведомления (или несколько)
    readNotifications({ dispatch }, ids) {
      const params = { notificationIds: ids };
      return api.patch('/notification/read', params)
        .then(() => Promise.resolve())
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'readNotifications' }, { root: true });
          console.warn('Error in action -- deleteNotification --; Error -', error);
          return Promise.reject(error);
        });
    },
  },
};
