<template>
  <div
  class="clients-statistic-element"
  @click="$emit('selectCurrentClient')">
    <div class="clients-statistic-element__column clients-statistic-element__column_name">
      <DataSkeleton
      v-if="isSkeleton"
      :width="250"
      :height="20" />
      <div v-else>
        {{ client.name }}
      </div>
    </div>

    <!-- Запросы-->
    <div
    class="clients-statistic-element__column"
    style="width: 200px">
      <DataSkeleton
      v-if="isSkeleton"
      :width="100"
      :height="20" />
      <span
      v-else
      class="clients-statistic-element__text">
        {{ client.requestPlacementsCount || 0 }}
      </span>
    </div>

    <!-- Всего по запросам (Руб) -->
    <div
    class="clients-statistic-element__column"
    style="width: 200px">
      <DataSkeleton
      v-if="isSkeleton"
      :width="100"
      :height="20" />
      <span
      v-else
      class="clients-statistic-element__text">
        {{ client.requestPlacementsResultPrice ? `${setNumberSpaces(Math.round(client.requestPlacementsResultPrice))} ${getCurrencySymbol}` : 0 }}
      </span>
    </div>

    <!-- Изменение % -->
    <div
    class="clients-statistic-element__column"
    style="width: 200px">
      <DataSkeleton
      v-if="isSkeleton"
      :width="100"
      :height="20" />
      <span
      v-else
      class="clients-statistic-element__text"
      :class="{'clients-statistic-element__text_red' : client.requestPlacementsVariationPercent && client.requestPlacementsVariationPercent < 0 }">
        {{ client.requestPlacementsVariationPercent || 0 }}
      </span>
    </div>

    <!-- Старты -->
    <div
    class="clients-statistic-element__column"
    style="width: 200px">
      <DataSkeleton
      v-if="isSkeleton"
      :width="100"
      :height="20" />
      <span
      v-else
      class="clients-statistic-element__text">
        {{ client.campaignsCount || 0 }}
      </span>
    </div>

    <!-- Всего по стартам (Руб) -->
    <div
    class="clients-statistic-element__column"
    style="width: 200px">
      <DataSkeleton
      v-if="isSkeleton"
      :width="100"
      :height="20" />
      <span
      v-else
      class="clients-statistic-element__text">
        {{ client.campaignsResultPrice ? `${setNumberSpaces(Math.round(client.campaignsResultPrice))} ${getCurrencySymbol}` : 0 }}
      </span>
    </div>

    <!-- Изменение % -->
    <div
    class="clients-statistic-element__column"
    style="width: 200px">
      <DataSkeleton
      v-if="isSkeleton"
      :width="100"
      :height="20" />
      <span
      v-else
      :class="{'clients-statistic-element__text_red' : client.campaignsVariationPercent && client.campaignsVariationPercent < 0 }"
      class="clients-statistic-element__text">
        {{ client.campaignsVariationPercent || 0 }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ClientsStatisticElement',
  props: {
    client: {
      type: Object,
      default: () => {},
    },
    isSkeleton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
  }),
  created() {
  },
  methods: {
  },
  computed: {
    getCurrencySymbol() {
      return this.$store.getters['app/getCurrencySymbol'];
    },
  },
};
</script>

<style lang="scss" scoped>
.clients-statistic-element {
  display: flex;
  align-items: center;
  min-width: 100%;
  background: white;
  border-bottom: 1px solid $primary-group-border;
  cursor: pointer;
  &:hover {
    background: #f4fbfe;
    .clients-statistic-element__column_name {
      background: #f4fbfe;
    }
  }
}

.clients-statistic-element__column {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  height: 40px;
  flex-shrink: 0;
  &_name {
    flex-direction: row;
    justify-content: flex-start;
    position: sticky;
    left: 0px;
    z-index: 1;
    background: white;
    padding-left: 20px;
    flex-shrink: 0;
  }
}

.clients-statistic-element__text {
  font-size: 14px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  color: rgba($primary-gray, 0.9);
  -webkit-line-clamp: 2;
  &_red {
    color: $primary-red;
  }
}
</style>
