<template>
  <div class="moderation-headers">
    <div
    v-for="header in getHeaders"
    :key="header.value"
    :class="[header.value === 'name' && 'moderation__element_name']"
    :style="`width: ${header.width}px`"
    class="moderation__element">
      <span class="moderation__text">
        {{ header.text }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModerationHeaders',
  props: {
    sourceType: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    priceHeaders: [
      { value: 'name', text: 'Название', width: 400 },
      { value: 'subscribers', text: 'Подписчики', width: 180 },
      { value: 'views', text: 'Охват', width: 180 },
      { value: 'sale', text: 'Себес', width: 180 },
      { value: 'price', text: 'Прайс', width: 180 },
      { value: 'manager', text: 'Менеджер', width: 240 },
      { value: 'actions', text: '', width: 240 },
    ],
    channelHeaders: [
      { value: 'name', text: 'Название', width: 400 },
      { value: 'admin', text: 'Админ', width: 220 },
      { value: 'subscribers', text: 'Подписчики', width: 130 },
      { value: 'views', text: 'Охват', width: 130 },
      { value: 'price', text: 'Стоимость', width: 220 },
      { value: 'contacts', text: 'Контакты', width: 180 },
      { value: 'payment', text: 'Тип оплаты', width: 130 },
      { value: 'status', text: 'Статус', width: 130 },
      { value: 'actions', text: '', width: 240 },
    ],
  }),
  computed: {
    getHeaders() {
      if (this.sourceType === 'import') return this.priceHeaders;
      return this.channelHeaders;
    },
  },
};
</script>

<style lang="scss" scoped>
.moderation-headers {
  display: flex;
  align-items: center;
  min-width: 100%;
  z-index: 1;
  position: sticky;
  background: white;
  top: 0px;
  left: 0px;
  height: 52px;
  border-bottom: 1px solid $primary-group-border;
}

.moderation__element {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &_name {
    position: sticky;
    left: 0px;
    padding-left: 20px;
    background: white;
    justify-content: flex-start;
    align-items: flex-start;
    @include tablet-screen {
      padding-left: 40px;
    }
  }
}

.moderation__text {
  font-size: 14px;
  color: $primary-gray;
}

</style>
