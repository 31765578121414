<template>
  <div class="emtpy-list">
    <img
    v-if="isLoading"
    src="@/assets/svg/spinner-loader.svg">
    <div
    v-else
    class="empty-list__container">
      <span class="empty-list__text empty-list__text_title">{{ getTitle }}</span>
      <span class="empty-list__text">{{ getDescription }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyList',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isEmptySearch: false,
  }),
  computed: {
    getIsInitial() {
      return this.$store.getters['searchPosts/getIsInitial'];
    },
    getTitle() {
      return this.getIsInitial ? 'Сервис поиска постов' : 'Посты не найдены';
    },
    getDescription() {
      return this.getIsInitial ? 'Установите фильтры и нажмите кнопку Найти' : 'Попробуйте обновить параметры поиска';
    },
  },
};
</script>

<style lang="scss" scoped>
.emtpy-list {
  width: 100%;
  display: flex;
  height: 100%;
  max-height: 650px;
  justify-content: center;
  align-items: center;

  .empty-list__container {
    width: 100%;
    max-width: 600px;
    height: 300px;
    background: white;
    border-radius: 20px;
    border: 1px solid rgba($primary-blue, 0.2);
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-40px);
    @include between-elements {
      margin-bottom: 24px;
    }

    .empty-list__text {
  font-size: 16px;
  max-width: 510px;
  text-align: center;
  line-height: 28px;
  &_title {
    max-width: 650px;
    font-size: 28px;
    color: $primary-blue;
    font-weight: 600;
  }
  &_button {
    color: white;
  }
}

.empty-list__button {
  background: $primary-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 40px;
  border-radius: 33px;
  transition: 0.2s;
  cursor: pointer;
  @include between-elements {
    margin-right: 15px;
  }
  &:hover {
    opacity: 0.8;
  }
}
  }
}
</style>
