<template>
  <div class="group-responsible">
    <DataSelect
    v-if="(isAdmin || isManager)"
    ref="select"
    v-model="selectedRealisationManager"
    :options="realisationsManagersList"
    placeholder="Ответственный"
    class="group-responsible__select"
    @input="setResponsibleUser" />
  </div>
</template>

<script>
export default {
  name: 'GroupResponsible',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isManager: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    realisationsManagers: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    selectedRealisationManager: null,
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      const responsibleName = this.group.responsibleUser?.name || null;
      if (!responsibleName) {
        this.selectedRealisationManager = null;
        return;
      }
      const currentManager = this.realisationsManagers.find((item) => item.name === responsibleName) || null;
      this.selectedRealisationManager = currentManager ? { name: currentManager.name, value: currentManager.id } : null;
    },
    setResponsibleUser(value) {
      if (!value || value.value === 'novalue') {
        this.selectedRealisationManager = null;
        const params = {
          compilationId: this.getCompilationInfo?.id,
          positionsIds: [this.group.positionId],
        };
        this.$store.dispatch('compilations/deleteResponsibleInPosition', params)
          .then(() => {
            this.$store.dispatch('app/setSnackBar', { text: 'Ответственный удален' });
          });
        return;
      }
      const params = {
        compilationId: this.getCompilationInfo?.id,
        positionsIds: [this.group.positionId],
        responsibleUserId: value ? this.selectedRealisationManager.value : null,
        responsibleUserObject: this.realisationsManagers.find((item) => item.id === this.selectedRealisationManager.value),
      };
      this.$store.dispatch('compilations/changeResponsibleInPosition', params)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Ответственный установлен' });
        });
    },
  },
  computed: {
    realisationsManagersList() {
      const managers = this.realisationsManagers || [];
      const novariant = { name: 'Не указан', value: 'novalue' };
      const list = managers.filter((item) => item.name && item.id).map((item) => ({ name: item.name, value: item.id }));
      return list.concat(novariant);
    },
    getCompilationInfo() {
      return this.$store.getters['compilations/getCurrentCompilationInfo'];
    },
  },
  watch: {
    'group.responsibleUser': {
      handler() {
        this.init();
        this.$nextTick(() => {
          this.$refs.select.init();
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.group-responsible {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 50px;
  position: relative;
  width: 260px;
}

.group-responsible__text {
  font-size: 14px;
  color: $primary-gray;
  text-align: center;
  word-break: break-all;
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

.group-responsible__select {
  max-width: 220px;
}

</style>
