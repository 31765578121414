<template>
  <div class="competitors-filters">
    <div class="competitors-filters__filters-container">
      <div class="competitors-filters__filter">
        <span class="competitors-filters__filter-title">Название или ссылка</span>
        <DataInput
        :value="nameOrUrl"
        placeholder="Название или ссылка"
        theme="white"
        clear-icon
        @input="updateName" />
      </div>
      <div class="competitors-filters__filter">
        <span class="competitors-filters__filter-title">Категории</span>
        <DataSelect
        v-model="categories"
        :options="getCategoriesVariants"
        :max-elements="8"
        select-type="multi"
        theme="white"
        with-search
        placeholder="Выберите категории"
        @input="setCategories" />
      </div>
      <div class="competitors-filters__filter">
        <span class="competitors-filters__filter-title">Мин.разница в %</span>
        <DataInput
        :value="differencePercentFrom"
        input-type="number"
        placeholder="0"
        theme="white"
        :width="150"
        clear-icon
        @input="updateDifference($event, 'from')" />
      </div>
      <div class="competitors-filters__filter">
        <span class="competitors-filters__filter-title">Макс.разница в %</span>
        <DataInput
        :value="differencePercentTo"
        input-type="number"
        placeholder="0"
        theme="white"
        clear-icon
        @input="updateDifference($event, 'to')" />
      </div>
      <div class="competitors-filters__filter">
        <span class="competitors-filters__filter-title">Наша цена</span>
        <div class="competitors-filters__our-price">
          <div
          :class="ourPrice === 'our' && 'competitors-filters__our-price-item_selected'"
          class="competitors-filters__our-price-item competitors-filters__our-price-item_left"
          @click="setOurPrice('our')">
            Ниже
          </div>
          <div
          :class="ourPrice === 'their' && 'competitors-filters__our-price-item_selected'"
          class="competitors-filters__our-price-item competitors-filters__our-price-item_right"
          @click="setOurPrice('their')">
            Выше
          </div>
        </div>
      </div>
    </div>

    <span
    v-if="isActiveFilters"
    class="competitors-filters__reset"
    @click="resetFilters">Сбросить все фильтры</span>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'RequestFilters',
  props: {
    isActiveFilters: {
      type: Boolean,
      default: false,
    },
    currentSearchParams: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    nameOrUrl: null,
    categories: [],
    categoriesVariants: null,
    differencePercentTo: null,
    differencePercentFrom: null,
    ourPrice: null,
  }),
  created() {
    this.updateName = debounce(this.updateName, 1000);
    this.updateDifference = debounce(this.updateDifference, 1000);
  },
  methods: {
    resetFilters() {
      this.reset();
      this.$emit('resetFilters');
    },
    reset() {
      this.nameOrUrl = null;
      this.categories = null;
      this.differencePercentTo = null;
      this.differencePercentFrom = null;
      this.ourPrice = null;
    },
    updateName(value) {
      this.nameOrUrl = value;
      const searchParams = { ...this.currentSearchParams };
      searchParams.groupNameOrUrl = value || null;
      this.$emit('updateFilters', searchParams);
    },
    setCategories(value) {
      const ids = value.map((item) => item.value);
      const searchParams = { ...this.currentSearchParams };
      searchParams.groupCategoriesId = ids && ids.length ? ids : null;
      this.$emit('updateFilters', searchParams);
    },
    updateDifference(value, type) {
      const searchParams = { ...this.currentSearchParams };
      if (type === 'to') {
        this.differencePercentTo = value;
        searchParams.differencePercentTo = value || null;
      } else {
        this.differencePercentFrom = value;
        searchParams.differencePercentFrom = value || null;
      }
      this.$emit('updateFilters', searchParams);
    },
    setOurPrice(type) {
      const searchParams = { ...this.currentSearchParams };
      this.ourPrice = this.ourPrice === type ? null : type;
      if (type === 'our') {
        searchParams.ourPriceGrater = this.ourPrice ? false : null;
      } else {
        searchParams.ourPriceGrater = this.ourPrice ? true : null;
      }
      this.$emit('updateFilters', searchParams);
    },
  },
  computed: {
    getCategoriesData() {
      return this.$store.getters['groups/getCategories'];
    },
    getCategoriesVariants() {
      return this.getCategoriesData
        .map((category) => ({
          name: this.$t(category.name),
          value: category.id,
        }));
    },
  },
};
</script>

<style lang="scss" scoped>
.competitors-filters__filters-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  position: relative;
  @include tablet-screen {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @include max-screen {
    grid-template-columns: 230px 280px 150px 150px 200px;
  }
}

.competitors-filters__filter {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.competitors-filters__filter-title {
  font-size: 14px;
}

.competitors-filters__reset {
  display: block;
  padding: 10px 5px 5px 5px;
  cursor: pointer;
  font-size: 14px;
}

.competitors-filters__our-price {
  display: flex;
  justify-content: center;
  align-items: center;
}

.competitors-filters__our-price-item {
  width: 100%;
  background: white;
  border: 1px solid #d8f2fa;
  height: 48px;
  outline: none;
  font-size: 16px;
  padding: 0px 20px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.1s;
  color: #7b7b7b;
  &_left {
    border-radius: 8px 0px 0px 8px;
    border-right: 0px;
  }
  &_right {
    border-radius: 0px 8px 8px 0px;
  }
  &_selected {
    color: white;
    background: $primary-blue;
  }
}
</style>
