<template>
  <div class="compilation-folders">
    <div class="compilation-folders__folders-list">
      <Draggable
      v-for="folder in getFolders"
      :key="folder.id"
      v-bind="{ disabled: true }">
        <div
        class="compilation-folders__folder"
        :class="{
          'compilation-folders__folder_selected': getSelectedFolder?.id === folder.id,
          'compilation-folders__folder_hover': getHoverFolder?.id === folder.id,
        }"
        oncontextmenu="return false;"
        @dragenter.prevent="enableDragMode && setHoverFolder(folder)"
        @dragleave.prevent="enableDragMode && setHoverFolder(null)"
        @click="selectCurrentFolder(folder)"
        @contextmenu.prevent="openMenu(folder)">
          <span
          class="compilation-folders__text compilation-folders__text_folder"
          :class="{ 'compilation-folders__text_folder-selected': getSelectedFolder?.id === folder.id }">
            {{ folder.name }}
          </span>
          <transition name="fade">
            <div
            v-if="showMenu === folder.id"
            v-click-outside="() => showMenu = false"
            class="compilation-folders__folder-menu">
              <div
              class="compilation-folders__folder-menu-item"
              @click.stop="openRenameFolderModal(folder)">
                <span class="compilation-folders__text">Переименовать</span>
              </div>
              <div
              class="compilation-folders__folder-menu-item"
              @click.stop="openDeleteFolderModal(folder)">
                <span class="compilation-folders__text">Удалить папку</span>
              </div>
            </div>
          </transition>
        </div>
      </Draggable>
    </div>
    <div
    v-if="!isNoauthMode"
    class="compilation-folders__actions">
      <div
      class="compilation-folders__action"
      @click="openCreateFolderModal">
        <img
        class="compilation-folders__icon"
        src="@/assets/svg/compilation/drag-folder.svg">
        <span class="compilation-folders__text">Добавить папку</span>
      </div>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
  name: 'CompilationFolders',
  components: { Draggable },
  props: {
    compilationInfo: {
      type: Object,
      default: null,
    },
    isNoauthMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    hoverFolder: null,
    showMenu: false,
  }),
  methods: {
    selectCurrentFolder(folder) {
      let folderId = null;
      if (!folder || this.getSelectedFolder?.id === folder.id) {
        this.$store.commit('compilations/setSelectedFolder', null);
      } else {
        this.$store.commit('compilations/setSelectedFolder', folder);
        folderId = folder.id;
      }
      this.showMenu = false;
      this.$store.commit('compilations/setIsLoadingCompilation', true);
      this.$store.dispatch('compilations/getCurrentCompilationSummary', { id: this.getCompilationInfo.id, folderId, isFolder: true });
      this.$store.dispatch('compilations/getCurrentCompilationGroups', { id: this.getCompilationInfo.id, reset: true })
        .finally(() => { this.$store.commit('compilations/setIsLoadingCompilation', false); });
    },
    setHoverFolder(value) {
      if (!this.enableDragMode) return;
      this.$store.commit('compilations/setDraggableFolder', value);
    },
    openCreateFolderModal() {
      const props = { type: 'create', compilationInfo: this.getCompilationInfo };
      const modalParams = { name: 'CompilationFolderModal', props };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    openRenameFolderModal(folder) {
      const props = { type: 'rename', compilationInfo: this.getCompilationInfo, currentFolder: folder };
      const modalParams = { name: 'CompilationFolderModal', props };
      this.$store.commit('app/setCurrentModal', modalParams);
      this.showMenu = false;
    },
    openDeleteFolderModal(folder) {
      const props = { type: 'delete', compilationInfo: this.getCompilationInfo, currentFolder: folder };
      const modalParams = { name: 'CompilationFolderModal', props };
      this.$store.commit('app/setCurrentModal', modalParams);
      this.showMenu = false;
    },
    openMenu(folder) {
      if (folder.isRoot) return;
      this.showMenu = folder.id;
    },
  },
  computed: {
    getFolders() {
      if (!this.compilationInfo.folders) return [];
      const folders = this.compilationInfo.folders.map((folder) => ({ ...folder, groups: [], name: folder.name === 'root' ? 'Каналы без папки' : folder.name }
      ));
      return folders.reverse();
    },
    getSelectedFolder() {
      return this.$store.getters['compilations/getSelectedFolder'];
    },
    getHoverFolder() {
      return this.$store.getters['compilations/getDraggableFolder'];
    },
    getCompilationInfo() {
      return this.$store.getters['compilations/getCurrentCompilationInfo'];
    },
    enableDragMode() {
      return this.$store.getters['compilations/getEnableDragMode'];
    },
  },
};
</script>

<style lang="scss" scoped>
.compilation-folders {
  margin: 20px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include tablet-screen {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .compilation-folders__folders-list {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    @include tablet-screen {
      display: flex;
      justify-content: flex-start;
      @include between-elements {
        margin-right: 10px;
      }
      margin-right: 20px;
    }
  }

  .compilation-folders__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    @include tablet-screen {
      margin-top: 0px;
    }
  }

  .compilation-folders__folder-wrapper {
    position: relative;
  }

  .compilation-folders__folder {
    border-radius: 8px;
    background: white;
    color: $primary-blue;
    text-overflow: ellipsis;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    cursor: pointer;
    position: relative;
    transition: 0.1s;
    min-width: 140px;
    max-width: 200px;
    outline: 2px solid rgba($primary-blue, 0.2);
    &:hover {
      box-shadow: 2px 0px 10px 3px rgb(34 60 80 / 10%);
    }
    &_selected {
      background: $primary-blue;
    }
    &_hover {
      transform: scale(110%);
      box-shadow: 2px 0px 10px 3px rgb(34 60 80 / 10%);
    }
  }

  .compilation-folders__folder-menu {
    position: absolute;
    top: 50px;
    left: 0px;
    width: 100%;
    border-radius: 8px;
    background: white;
    transition: 0.2s;
    box-shadow: 2px 0px 10px 1px rgb(34 60 80 / 20%);
    overflow: hidden;
    z-index: 2;
    min-height: 80px;
  }

  .compilation-folders__folder-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    height: 40px;
    &:hover {
      background: rgb(248, 248, 248);
    };
  }

  .compilation-folders__action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 10px;
    cursor: pointer;
    height: 40px;
    box-sizing: border-box;
    &_selected {
      outline: 1px solid $primary-lightgray;
      border-radius: 8px;
      background: rgb(250, 250, 250);
    }
    @include between-elements {
      margin-right: 10px;
    }
  }

  .compilation-folders__icon {
    width: 25px;
  }

  .compilation-folders__text {
    font-size: 13px;
    color: $primary-gray;
    text-align: center;
    &_folder {
      width: 100%;
      color: $primary-blue;
      pointer-events: none;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &_folder-selected {
      color: white;
      pointer-events: none;
    }
  }

}

</style>
