const getBaseUrl = () => {
  const url = window.location.href;
  if (url.includes('localhost')) {
    return 'http://localhost:8080';
  }

  if (url.includes('front.stage1')) {
    return 'https://front.stage1.socialjet.pro';
  }

  if (url.includes('front.main')) {
    return 'https://front.main.socialjet.pro';
  }

  if (url.includes('stage-datafront')) {
    return 'https://stage-datafront.socialjet.pro';
  }

  if (process.env.NODE_ENV === 'production') {
    return 'https://data.socialjet.pro';
  }

  return 'https://data.socialjet.pro';
};

export default getBaseUrl;
