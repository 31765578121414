import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store/index';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.params.savedPosition) return {}; // необходимо savedPosition: true в params, чтобы не перемещаться вверх
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.query['passthrough-token']) {
    store.dispatch('user/getToken', to.query['passthrough-token'])
      .then(() => {
        next({ name: 'catalog' });
      })
      .catch(() => {
        next({ name: 'login' });
        store.dispatch('app/setSnackBar', {
          text: 'Ошибка при авторизации. Проверьте правильность ссылки и токена',
          error: true,
        });
      });
    return;
  }

  // Вызов авторизации пользователя
  if (localStorage.getItem('userToken') && !store.state.user.userInfo) {
    // FUTURE appIsLoaded - информирование что получены данные о пользователе. переименовать и зарефакторить
    store.commit('app/setAppIsLoaded', false);
    await store.dispatch('user/loadUserInfo')
      .catch(() => {
        store.dispatch('app/setSnackBar', {
          text: 'При авторизации произошла ошибка. Попробуйте обновить страницу или обратитесь к вашему менеджеру',
          error: true,
          timeout: 10000,
        });
        next({ name: 'login' });
      })
      .finally(() => {
        store.commit('app/setAppIsLoaded', true);
        next();
      });
    return;
  }

  // Если неавторизованный переходит по ссылке с подборкой (не основная ссылка, а ссылка с groups)
  if (!localStorage.getItem('userToken') && to.name === 'catalog' && to.query.compilation && to.query.shareToken) {
    const query = { id: to.query.compilation, shareToken: to.query.shareToken };
    next({ name: 'compilation', query });
    return;
  }

  // Если нет токена и страница недоступна неавторизованным, отправляем на лендинг
  const noAuthPages = ['login', 'publishers', 'group', 'compilation'].includes(to.name);
  if (!localStorage.getItem('userToken') && !to.query['passthrough-token'] && !noAuthPages) {
    const isDev = process.env.NODE_ENV === 'development';
    if (isDev) {
      next({ name: 'login' });
    } else {
      window.open('https://socialjet.pro/data/', '_self');
    }
  } else {
    next();
  }
});

export default router;
