<template>
  <div class="data-timepicker">
    <div
    class="data-timepicker__main"
    :class="{
      'data-timepicker__main_theme-white': theme === 'white',
      'data-timepicker__main_disabled': disabled || noEdit
    }"
    :style="{ 'max-width': width + 'px' }"
    @click="openPicker">
      <span
      v-if="getLabel"
      class="data-timepicker__label">{{ getLabel }}</span>
      <span
      v-else
      class="data-timepicker__label data-timepicker__label_placeholder">{{ placeholder }}</span>
      <img
      class="data-timepicker__icon"
      src="@/assets/svg/icons/clock-picker.svg">
    </div>
    <transition name="fade">
      <div
      v-if="open"
      v-click-outside="() => open = false"
      :style="{ 'max-width': width + 'px' }"
      class="data-timepicker__container">
        <div class="data-timepicker__picker">
          <div class="data-timepicker__hours data-timepicker__hours_border">
            <div
            v-for="hour in hours"
            :key="hour"
            class="data-timepicker__hour"
            :class="selectedHour === hour && 'data-timepicker__hour_selected'"
            @click="setHour(hour)">
              {{ hour }}
            </div>
          </div>
          <div class="data-timepicker__hours">
            <div
            v-for="min in minutes"
            :key="min"
            class="data-timepicker__hour"
            :class="selectedMinutes === min && 'data-timepicker__hour_selected'"
            @click="setMinutes(min)">
              {{ min }}
            </div>
          </div>
        </div>
        <div
        class="data-timepicker__reset"
        :class="getLabel && 'data-timepicker__reset_show'"
        @click="resetData">
          Сбросить
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DataTimePicker',
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'чч:мм',
    },
    error: {
      type: Boolean,
      default: false,
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
    },
    width: {
      type: Number,
      default: 100,
    },
  },
  data: () => ({
    open: false,
    hours: null,
    minutes: null,
    selectedHour: null,
    selectedMinutes: null,
  }),
  created() {
    if (this.value !== null && this.value.includes(':')) {
      this.initData(this.value);
    }
  },
  methods: {
    initData(value) {
      this.selectedHour = value ? value.split(':')[0] : null;
      this.selectedMinutes = value ? value.split(':')[1] : null;
    },
    createData(value) {
      const res = [];
      for (let i = 0; i <= value; i++) {
        if (i === 0) {
          res.push('00');
        } else if (i < 10) {
          res.push(`0${i}`);
        } else {
          res.push(String(i));
        }
      }
      return res;
    },
    openPicker() {
      if (this.disabled || this.noEdit) {
        return;
      }
      if (this.open) {
        this.open = false;
        return;
      }
      this.minutes = this.createData(59);
      this.hours = this.createData(23);
      this.open = true;
    },
    setHour(value) {
      if (this.selectedHour === value) {
        this.selectedHour = null;
        return;
      }
      this.selectedHour = value;
      this.$emit('input', this.getLabel);
    },
    setMinutes(value) {
      if (this.selectedMinutes === value) {
        this.selectedMinutes = null;
        return;
      }
      this.selectedMinutes = value;
      this.$emit('input', this.getLabel);
    },
    resetData() {
      this.selectedHour = null;
      this.selectedMinutes = null;
      this.$emit('input', null);
    },
  },
  computed: {
    getLabel() {
      if (this.selectedHour || this.selectedMinutes) {
        const hour = this.selectedHour ? this.selectedHour : '00';
        const minutes = this.selectedMinutes ? this.selectedMinutes : '00';
        return `${hour}:${minutes}`;
      }
      return null;
    },
  },
  watch: {
    value(newValue) {
      if (newValue === null) {
        this.resetData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.data-timepicker {
  position: relative;
}

.data-timepicker__main {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafeff;
  border-radius: 8px;
  border: 1px solid #d8f2fa;
  height: 48px;
  min-width: 100px;
  cursor: pointer;
  position: relative;
  &_theme-white {
    background: white;
  }
  &_disabled {
    background: #f5f5f5;
    border: 1px solid rgba(0, 0, 0, 0.03)
  }
  &_error {
    border: 1px solid $primary-red;
  }
}

.data-timepicker__container {
  position: absolute;
  top: 50px;
  left: 0;
  overflow: hidden;
  width: 100%;
  min-width: 100px;
  background: white;
  z-index: 100;
  border-radius: 8px;
  border: 1px solid #d8f2fa;
}

.data-timepicker__picker {
  display: flex;
}

.data-timepicker__icon {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 22px;
  height: 22px;
}

.data-timepicker__hours {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 200px;
  overflow-y: scroll;
  width: 100%;
  &_border {
    border-right: 2px solid rgb(241, 241, 241);
  }
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

.data-timepicker__hour {
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background: #f1f1f1;
  }
  &_selected {
    color: white;
    background: #00AEE8;
    &:hover {
      background: #00AEE8;
    }
  }
}

.data-timepicker__label {
  font-size: 16px;
  color: $primary-gray;
  position: absolute;
  top: 14px;
  left: 14px;
  &_placeholder {
    color: #7b7b7b;
    font-weight: 400;
  }
}

.data-timepicker__reset {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: $primary-gray;
  height: 0px;
  cursor: pointer;
  overflow: hidden;
  opacity: 0;
  transition: height 0.2s, opacity 0.2s;
  &_show {
    height: 30px;
    opacity: 1;
    overflow: visible;
    border-top: 1px solid rgb(238, 238, 238);
  }
}

</style>
