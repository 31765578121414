<template>
  <div class="register-form">
    <span class="register-form__title">Создать кабинет</span>
    <transition
    name="fade"
    mode="out-in">
      <RoleSelect
      v-if="!selectedRole"
      :key="selectedRole"
      @selectRole="selectRole" />
      <div
      v-else
      :key="selectedRole"
      class="register-form__form">
        <div
        v-if="selectedRole === 'rekl'"
        class="register-form__company-block">
          <span class="register-form__text register-form__text_company">Выберите тип вашей компании</span>
          <div class="register-form__company-switcher">
            <div
            class="register-form__company register-form__company_left"
            :class="!isAgency && 'register-form__company_active'"
            @click="isAgency = false">
              Прямой рекламодатель
            </div>
            <div
            class="register-form__company register-form__company_right"
            :class="isAgency && 'register-form__company_active'"
            @click="isAgency = true">
              Рекламное агентство
            </div>
          </div>
        </div>
        <div class="register-form__form">
          <div class="register-form__input-block">
            <input
            v-model.trim="company"
            autocomplete="off"
            :placeholder="selectedRole === 'rekl'? 'Укажите название компании' : 'Название паблика или компании'"
            class="register-form__input">
            <span
            v-if="errors.company"
            class="register-form__error">{{ selectedRole === 'rekl'? 'Укажите название компании' : 'Название паблика или компании' }}</span>
          </div>
          <div class="register-form__input-block">
            <input
            v-model.trim="email"
            autocomplete="off"
            placeholder="Укажите ваш email"
            class="register-form__input">
            <span
            v-if="errors.email"
            class="register-form__error">Укажите корректный email</span>
          </div>
          <div class="register-form__input-block">
            <input
            v-model.trim="phone"
            v-mask="'+7 (###) ###-##-##'"
            autocomplete="off"
            placeholder="Укажите телефон"
            class="register-form__input">
            <span
            v-if="errors.phone"
            class="register-form__error">Укажите телефон</span>
          </div>
          <div class="register-form__input-block">
            <input
            ref="passwordInput"
            v-model.trim="password"
            autocomplete="off"
            type="password"
            placeholder="Придумайте пароль"
            class="register-form__input">
            <span
            v-if="errors.password"
            class="register-form__error">Введите корректный пароль не менее 6 символов</span>
            <DataIcon
            name="visibility"
            class="register-form__pass-icon"
            :size="20"
            pointer
            @mouseup.native="$refs.passwordInput.type = 'password'"
            @mousedown.native="$refs.passwordInput.type = 'text'" />
          </div>
          <div class="register-form__input-block">
            <input
            ref="repeatPasswordInput"
            v-model.trim="repeatPassword"
            autocomplete="off"
            type="password"
            placeholder="Наберите пароль еще раз"
            class="register-form__input">
            <span
            v-if="errors.repeatPassword"
            class="register-form__error">Пароли не совпадают</span>
            <DataIcon
            name="visibility"
            class="register-form__pass-icon"
            :size="20"
            pointer
            @mouseup.native="$refs.repeatPasswordInput.type = 'password'"
            @mousedown.native="$refs.repeatPasswordInput.type = 'text'" />
          </div>
        </div>
        <div class="register-form__rules-block">
          <DataCheckbox
          v-model="rules"
          :size="22" />
          <span
          class="register-form__text"
          :class="errors.rules && 'register-form__text_error'">
            Я прочел и соглашаюсь с <a
            target="_blank"
            class="register-form__text register-form__text_link"
            @click="showRulesModal = true">правилами</a>
          </span>
        </div>
        <DataButton
        text="Создать кабинет"
        width="full"
        :height="50"
        :font-size="16"
        @handler="submitForm" />
      </div>
    </transition>
    <div
    class="register-form__has-acc">
      <span class="register-form__text">
        У меня уже есть аккаунт. <span
        class="register-form__text register-form__text_link"
        @click="$emit('setSelectedStep', 'login')">Войти</span>
      </span>
    </div>
    <DataModal
    :show-modal="showRulesModal"
    @close="showRulesModal = false">
      <template #modal-content>
        <RulesModal @close="showRulesModal = false" />
      </template>
    </DataModal>
  </div>
</template>

<script>
import RulesModal from './RulesModal.vue';
import RoleSelect from './RoleSelect.vue';

export default {
  name: 'RegisterForm',
  components: { RulesModal, RoleSelect },
  data: () => ({
    selectedRole: null,
    company: '',
    email: '',
    phone: '',
    password: '',
    repeatPassword: '',
    errors: {
      company: false,
      email: false,
      phone: false,
      password: false,
      repeatPassword: false,
      rules: false,
    },
    isAgency: false,
    rules: false,
    showRulesModal: false,
  }),
  methods: {
    submitForm() {
      const invalidForm = this.checkForm();
      if (invalidForm) return;

      if (this.selectedRole === 'rekl') {
        const registerData = {
          email: this.email.toLowerCase(),
          password: this.password,
          companyName: this.company,
          phoneNumber: this.phone,
          isAgency: this.isAgency,
        };

        this.$store.dispatch('clients/registrateAgency', registerData)
          .then(() => {
            this.$store.dispatch('app/setSnackBar', { text: 'Успешная регистрация' });
            this.$emit('setSelectedStep', 'login');
          })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка. Попробуйте повторить позже', error: true });
          });
      }

      if (this.selectedRole === 'publisher') {
        const registerData = {
          email: this.email.toLowerCase(),
          password: this.password,
          companyName: this.company,
          phoneNumber: this.phone,
          role: 3,
        };

        this.$store.dispatch('user/registrationUser', registerData)
          .then(() => {
            this.$store.dispatch('app/setSnackBar', { text: 'Успешная регистрация' });
            this.$emit('setSelectedStep', 'login');
          })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка. Попробуйте повторить позже', error: true });
          });
      }
    },
    checkForm() {
      this.errors.company = !this.company.length;
      // eslint-disable-next-line max-len
      this.errors.email = !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(this.email.toLowerCase());
      this.errors.password = this.password.length < 6;
      this.errors.repeatPassword = this.password !== this.repeatPassword || this.errors.password;
      this.errors.phone = !this.phone.length;
      this.errors.rules = !this.rules;
      return [this.errors.email, this.errors.password, this.errors.repeatPassword, this.errors.phone].some((item) => item);
    },
    selectRole(value) {
      this.selectedRole = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.register-form {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.register-form__form {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 90vw;
  @include tablet-screen {
    max-width: 400px;
  }
  @include between-elements {
    margin-bottom: 20px;
  }
}

.register-form__title {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #0E092A;
}

.register-form__text {
  font-size: 16px;
  color: #8A8A8A;
  &_company {
    color: #8A8A8A;
    font-size: 14px;
  }
  &_link {
    text-decoration: none;
    color: $primary-blue;
    cursor: pointer;
  }
  &_error {
    border-bottom: 1px solid red;
  }
}

.register-form__company-block {
  display: flex;
  flex-direction: column;
  align-items: center;
    max-width: 100%;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.register-form__company-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
    max-width: 100%;
}

.register-form__company {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 48px;
  background: transparent;
  color: #696969;
  border: 1px solid #DBDBDB;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  transition: 0.3s;
  cursor: pointer;
  padding: 0px 10px;
  &_active {
    background: $primary-blue;
    color: white;
    border: 1px solid $primary-blue;
  }
  &_left {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
  }
  &_right {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
  }
}

.register-form__input-block {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;
}

.register-form__error {
  margin-top: 5px;
  font-size: 12px;
  color: $primary-red;
}

.register-form__input {
  padding: 14px 40px 14px 16px;
  border: 1px solid $border-color-primary;
  border-radius: 8px;
  background: $primary-white;
  box-sizing: border-box;
  font-size: 16px;
  &:focus {
    outline: none;
  }
}

.register-form__rules-block {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  text-align: left;
  @include between-elements {
    margin-right: 15px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.register-form__has-acc {
  width: 100%;
  margin-top: 30px;
  padding-top: 30px;
  &_border {
    border-top: 1px solid #CCEFFA;
  }
}

.register-form__pass-icon {
  position: absolute;
  top: 16px;
  right: 15px;
  color: $primary-lightgray;
}

</style>
