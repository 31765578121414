<template>
  <div
  class="data-button">
    <div
    class="data-button__button"
    :disabled="disabled"
    :style="{
      'width': width === 'full' ? '100%' : width + 'px',
      'height': height ? height + 'px' : '40px',
      'fontSize': fontSize + 'px',
      'borderRadius': borderRadius + 'px'
    }"
    :class="{
      'data-button__button_outlined': type === 'outlined',
      'data-button__button_danger': type === 'danger',
      'data-button__button_text': type === 'text',
      'data-button__button_disabled': disabled,
    }"
    @click="click">
      <span v-if="text && !icon">{{ text }}</span>
      <DataIcon
      v-if="icon"
      :name="icon" />
      <span
      v-if="showRipple"
      class="data-button__ripple" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'DataButton',
  props: {
    text: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'primary',
    },
    width: {
      type: [String, Number],
      default: 200,
    },
    height: {
      type: [String, Number],
      default: 40,
    },
    fontSize: {
      type: Number,
      default: 14,
    },
    icon: {
      type: String,
      default: null,
    },
    borderRadius: {
      type: Number,
      default: 40,
    },
  },
  data: () => ({
    showRipple: false,
  }),
  methods: {
    click() {
      this.setRipple();
      this.$emit('handler');
    },
    setRipple() {
      this.showRipple = true;
      setTimeout(() => {
        this.showRipple = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.data-button {
  max-width: 100%;
}

.data-button__button {
  width: 180px;
  cursor: pointer;
  padding: 0px 20px;
  outline: none;
  border: none;
  color: $primary-white;
  background: $primary-blue;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &_outlined {
    background: $primary-white;
    color: $primary-black;
    border: 1px solid $primary-lightgray;
  }
  &_danger {
    background: $primary-red;
  }
  &_text {
    padding: 12px 20px;
    background: none;
    color: $primary-blue;
    border: none;
    outline: none;
  }
  &_disabled {
    pointer-events: none;
    background: rgba($primary-lightgray, 0.5);
  }
}

.data-button__ripple {
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 38%;
  width: 30%;
  height: 100%;
  transform: scale(0);
  animation: ripple 300ms linear;
  background-color: rgba(255, 255, 255, 0.3);
}

@keyframes ripple {
  to {
    transform: scale(5);
    opacity: 0;
  }
}
</style>
