<template>
  <div
  ref="auditory-filter"
  class="auditory-filter"
  @click="openMenu">
    <div class="auditory-filter__title">
      <span class="auditory-filter__text auditory-filter__text_second">
        {{ $t('audience') }}
      </span>
      <transition name="fade">
        <div
        v-if="isNotEmptyValues"
        class="auditory-filter__title-active" />
      </transition>
    </div>
    <span
    class="auditory-filter__text"
    :class="isNotEmptyValues && 'auditory-filter__text_blue'">
      {{ getLabelForAuditory }}
    </span>

    <!-- MENU-->
    <transition name="fade">
      <div
      v-if="isOpen"
      ref="menu"
      v-click-outside="onClickOutside"
      :style="{
        top: `${top}px`,
        left: `${left}px`,
      }"
      class="auditory-filter__menu">
        <!-- Гендер -->
        <div class="auditory-filter__container">
          <span class="auditory-filter__text">
            {{ $t('preferential_sex') }}
          </span>
          <div class="auditory-filter__gender">
            <div
            v-for="gender in genders"
            :key="gender.value"
            class="auditory-filter__gender-item"
            :class="(selectedGender && selectedGender.value === gender.value) && 'auditory-filter__gender-item-active'"
            @click="changeGender(gender)">
              <span>{{ $t(gender.text) }}</span>
            </div>
          </div>
        </div>

        <!-- Возраст -->
        <div class="auditory-filter__age">
          <div class="auditory-filter__age-text-block">
            <span class="auditory-filter__text">
              {{ $t('Age') }}
            </span>
            <span class="auditory-filter__text">
              {{ getAgeLabel }}
            </span>
          </div>
          <DataRangeSlider
          :value="ageRangeValues"
          :from-max="18"
          :to-max="65"
          :disabled="disabled"
          class="auditory-filter__slider"
          @change="changeAgeValues" />
          <div class="auditory-filter__age-text-block">
            <span class="auditory-filter__text auditory-filter__text_second">
              &lt;18
            </span>
            <span class="auditory-filter__text auditory-filter__text_second">
              65+
            </span>
          </div>
        </div>

        <!-- Интересы -->
        <div class="auditory-filter__interests">
          <span class="auditory-filter__text">
            Интересы
          </span>
          <div class="auditory-filter__interests-options">
            <div
            v-for="interes in interestsOptions"
            :key="interes.id"
            class="auditory-filter__interes">
              <label class="auditory-filter__interes">
                <input
                v-model="interesValue"
                type="radio"
                :value="interes.id"
                @change="$emit(`update:interestsId`, [interes.id])">
                <span class="auditory-filter__text auditory-filter__text_second">
                  {{ interes.name }}
                </span>
              </label>
            </div>
          </div>
        </div>

        <!-- RESET -->
        <span
        class="auditory-filter__text auditory-filter__text_second"
        @click="resetAll">
          {{ $t('reset_all') }}
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AuditoryFilter',
  props: {
    maleMin: {
      type: [String, Number],
      default: null,
    },
    maleMax: {
      type: [String, Number],
      default: null,
    },
    femaleMin: {
      type: [String, Number],
      default: null,
    },
    femaleMax: {
      type: [String, Number],
      default: null,
    },
    ageMin: {
      type: [String, Number],
      default: null,
    },
    ageMax: {
      type: [String, Number],
      default: null,
    },
    reset: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    interestsId: {
      type: [Array, String],
      default: null,
    },
  },
  data: () => ({
    isOpen: false,
    interestsOptions: [
      { id: 1, name: 'Финансы' },
      { id: 2, name: 'Строительство, обустройство и ремонт' },
      { id: 3, name: 'Отдых и путешествия' },
      { id: 4, name: 'Транспорт' },
      { id: 5, name: 'Одежда, обувь и аксессуары' },
      { id: 6, name: 'Бизнес' },
      { id: 7, name: 'Электроника' },
      { id: 8, name: 'Еда и напитки' },
      { id: 9, name: 'Спорт' },
      { id: 10, name: 'Образование' },
      { id: 11, name: 'Работа' },
      { id: 12, name: 'Недвижимость' },
      { id: 13, name: 'Телеком' },
      { id: 14, name: 'Семья и дети' },
      { id: 15, name: 'Бытовая техника' },
      { id: 16, name: 'Животные' },
      { id: 17, name: 'Красота и здоровье' },
      { id: 18, name: 'Подарки и цветы' },
      { id: 19, name: 'Канцелярские товары' },
      { id: 20, name: 'Сельхоз оборудование и техника' },
    ],
    genders: [
      {
        value: null,
        text: 'all',
      },
      {
        value: 'male',
        text: 'male',
      },
      {
        value: 'female',
        text: 'female',
      },
    ],
    selectedGender: { value: null, text: 'all' },
    maxValueGender: 100,
    minValueGender: 0,
    interesValue: null,
    ageRangeValues: null,
    top: 0,
    left: 0,
  }),
  created() {
    // gender
    if (this.maleMin) {
      this.selectedGender = { value: 'male', text: 'male' };
      this.minValueGender = +this.maleMin || 0;
      this.maxValueGender = +this.maleMax || 100;
    }
    if (this.femaleMin) {
      this.selectedGender = { value: 'female', text: 'female' };
      this.minValueGender = +this.femaleMin || 0;
      this.maxValueGender = +this.femaleMax || 100;
    }
    // age
    const minValueAge = this.ageMin ? this.ageMin : 18;
    const maxValueAge = this.ageMax ? this.ageMax : 65;
    this.ageRangeValues = [minValueAge, maxValueAge];
    // interests
    this.interesValue = this.interestsId || null;
  },
  methods: {
    onClickOutside() {
      this.isOpen = false;
    },
    openMenu() {
      if (this.isOpen) {
        return;
      }
      this.top = this.$refs['auditory-filter'].getBoundingClientRect().top + 50;
      this.left = this.$refs['auditory-filter'].getBoundingClientRect().left;
      this.isOpen = true;
    },
    resetAll() {
      this.selectedGender = { value: null, text: 'all' };
      this.maxValueGender = 100;
      this.minValueGender = 0;
      this.interesValue = null;
      this.ageRangeValues = [18, 65];
      this.$emit('update:maleMin', null);
      this.$emit('update:maleMax', null);
      this.$emit('update:femaleMin', null);
      this.$emit('update:femaleMax', null);
      this.$emit('update:interests-id', null);
      this.$emit('update:ageMin', null);
      this.$emit('update:ageMax', null);
    },
    changeGender(value) {
      if (this.disabled) return;

      this.selectedGender = value;

      if (this.selectedGender.value === null) {
        this.minValueGender = 0;
        this.maxValueGender = 100;
        this.$emit('update:maleMin', null);
        this.$emit('update:maleMax', null);
        this.$emit('update:femaleMin', null);
        this.$emit('update:femaleMax', null);
        return;
      }

      this.minValueGender = 51;
      this.maxValueGender = 100;

      if (this.selectedGender.value === 'male') {
        this.$emit('update:maleMin', this.minValueGender);
        this.$emit('update:maleMax', this.maxValueGender);
        this.$emit('update:femaleMin', null);
        this.$emit('update:femaleMax', null);
        return;
      }

      this.$emit('update:femaleMin', this.minValueGender);
      this.$emit('update:femaleMax', this.maxValueGender);
      this.$emit('update:maleMin', null);
      this.$emit('update:maleMax', null);
    },
    changeAgeValues(values) {
      this.ageRangeValues = [...values];
      this.$emit('update:ageMin', this.ageRangeValues[0] === 18 ? null : this.ageRangeValues[0]);
      this.$emit('update:ageMax', this.ageRangeValues[1] === 65 ? null : this.ageRangeValues[1]);
    },
  },
  computed: {
    getLabelForAuditory() {
      const gender = this.minValueGender > 0
      || this.maxValueGender < 100 ? `${this.$t('from')} ${this.minValueGender}% ${this.$t('to')} ${this.maxValueGender}%` : '';
      return `${this.selectedGender ? this.$t(this.selectedGender.text) : this.$t('all')}\r\n ${gender}`;
    },
    isNotEmptyValues() {
      const ages = (this.ageRangeValues[0] && this.ageRangeValues[0] > 18) || (this.ageRangeValues[1] && this.ageRangeValues[1] < 65);
      return this.selectedGender.value || this.minValueGender !== 0 || this.maxValueGender !== 100 || this.interesValue || ages;
    },
    getAgeLabel() {
      // NEED TRANSLATE
      const min = this.ageRangeValues[0] === 18 ? '' : `от ${this.ageRangeValues[0]}`;
      const max = this.ageRangeValues[1] === 65 ? '' : `до ${this.ageRangeValues[1]}`;

      return `${min} ${max}`;
    },
  },
  watch: {
    reset(newValue) {
      if (newValue === false) {
        this.resetAll();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auditory-filter {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 60px;
  cursor: pointer;
}

.auditory-filter__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5px;
}

.auditory-filter__title-active {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: $primary-blue;
  margin-left: 10px;
  margin-top: 1px;
}

.auditory-filter__text {
  color: #565656;
  font-size: 14px;
  overflow: hidden;
  cursor: pointer;
  &_second {
    font-weight: 400;
    color: #7a7a7a;
    font-size: 13px;
  }
  &_bold {
    font-weight: 600;
  }
  &_blue {
    color: $primary-blue;
  }
}

.auditory-filter__menu {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 320px;
  z-index: 10;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: default;
}

.auditory-filter__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.auditory-filter__gender {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 25px;
  width: 100%;
  margin-top: 10px;
  outline: 1px solid $border-color-primary;
  @include between-elements {
    border-right: 1px solid $border-color-primary;
  }
  cursor: pointer;
  &-active {
    color: $primary-white;
    background: $primary-blue;
  };
}

.auditory-filter__gender-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 12px;
  color: $primary-black;
  padding: 3px 7px;
  &-active {
    color: $primary-white;
    background: $primary-blue;
  };
}

.auditory-filter__age {
  width: 100%;
  margin-top: 15px;
}

.auditory-filter__slider {
  margin: 10px 0px 10px;
}

.auditory-filter__age-text-block {
  display: flex;
  justify-content: space-between;
}

.auditory-filter__interests {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 20px;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.auditory-filter__interests-options {
  @include between-elements {
    margin-bottom: 3px;
  }
}

.auditory-filter__interes {
  display: flex;
  align-items: center;
  @include between-elements {
    margin-right: 5px;
  }
}
</style>
