<template>
  <portal>
    <transition name="fastfade">
      <div
      v-if="showModal"
      class="data-modal flex-center">
        <div
        class="data-modal__overlay"
        :class="iframe && 'data-modal_iframe'"
        @click="close" />
        <div
        class="data-modal__modal"
        :class="{
          'data-modal__modal_iframe' : iframe,
          'data-modal__modal_full' : full,
        }">
          <slot name="modal-content" />
          <DataIcon
          v-if="!iframe && !noClose"
          name="close"
          class="data-modal__close"
          :size="26"
          pointer
          @click.native="close" />
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>

export default {
  name: 'DataModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    // Пропс для отображения модалки на лендинге (под видео из iframe)
    iframe: {
      type: Boolean,
      default: false,
    },
    // Пропс для отображения модалки без отступов
    full: {
      type: Boolean,
      default: false,
    },
    noClose: {
      type: Boolean,
      default: false,
    },
  },
  beforeDestroy() {
    document.body.style.overflowY = 'auto';
    document.body.style.position = 'static';
  },
  methods: {
    close() {
      if (this.noClose) return;

      if (this.showModal) {
        this.$emit('close');
      }
    },
  },
  watch: {
    showModal(newValue) {
      if (newValue) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'auto';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.data-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  overflow: auto;
}

.data-modal__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(2px);
  background-color: rgba(56, 56, 56, 0.4);
  &_iframe {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.data-modal__modal {
  background: #FFFFFF;
  border-radius: 12px;
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include tablet-screen {
    padding: 40px;
  }
  &_iframe {
    background: transparent;
    box-shadow: none;
  }
  &_full {
    padding: 0px;
  }
}

.data-modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
