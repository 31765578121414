<template>
  <div class="save-collection">
    <span
    class="save-collection__header">
      {{ labelForStep }}
    </span>
    <div
    class="save-collection__content">
      <!-- STEP 1 -->
      <div
      v-if="step === 1"
      class="save-collection__step">
        <div class="save-collection__selected-groups">
          <span v-if="$route.name !== 'group'">Выбрано каналов: </span>
          <span
          v-if="$route.name !== 'group'"
          class="save-collection__selected-groups-text">
            {{ groupsForCompilation.length }}
          </span>
          <div class="save-collection__selected-groups-container">
            <div class="save-collection__selected-groups-list">
              <div
              v-for="group in groupsForCompilation"
              :key="group.id + group.social"
              class="save-collection__selected-groups-list-item">
                <span>{{ group.name }}</span>
                <DataIcon
                name="close"
                pointer
                @click.native="deleteGroupFromSelected(group)" />
              </div>
            </div>
          </div>
        </div>
        <DataButton
        :text="$t('Create a new compilation2')"
        :width="260"
        @handler="changeStep(2)" />
        <DataButton
        :text="$t('Save to existing')"
        :width="260"
        type="outlined"
        @handler="changeStep(3)" />
      </div>
      <!-- STEP 2 -->
      <div
      v-if="step === 2"
      class="save-collection__step">
        <DataInput
        v-model.trim="nameNewCompilation"
        :placeholder="$t('Come up with a name')" />
      </div>
      <!-- STEP 3 -->
      <div
      v-if="step === 3">
        <DataSelect
        v-if="getCompilationsList.length > 0"
        v-model="selectedCompilation"
        class="save-collection__input"
        placeholder="Выберите подборку"
        :options="getCompilationsList" />
        <span
        v-else
        class="save-collection__label">
          {{ isLoadingCompilations ? 'Загружаем список подборок...' : $t('The collections are empty. You need to create a new one') }}
        </span>
      </div>
      <div
      v-if="step !== 1"
      class="save-collection__save-button">
        <DataButton
        :text="$t('save')"
        :disabled="isDisabledButton"
        @handler="saveAction" />
        <span
        class="save-collection__back-text"
        @click="changeStep(1)">{{ $t('Back') }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SaveCollectionModal',
  props: {
    // добавление в подборку из мини-подменю группы (когда выбираем только 1 канал)
    addFromMenu: {
      type: Boolean,
      default: false,
    },
    singleGroup: {
      type: Object,
      default: () => {},
    },
    isCompilation: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    step: 1,
    nameNewCompilation: '',
    selectedCompilation: null,
    groupsForCompilation: null,
  }),
  created() {
    // Если список подборок пуст, перезапросить
    if (!this.getCompilationsList.length) {
      this.isLoadingCompilations = true;
      this.$store.dispatch('compilations/getUserCompilationsListOld', this.getUserInfo.id)
        .finally(() => {
          this.isLoadingCompilations = false;
        });
    }
    this.groupsForCompilation = this.singleGroup ? [this.singleGroup] : [...this.getGroupsToCompilation];
  },
  methods: {
    changeStep(value) {
      this.step = value;
    },
    saveAction() {
      // если добавление каналов
      if (this.step === 3) {
        this.$store.dispatch('compilations/addGroupsToCompilation', { id: this.selectedCompilation.id, groups: this.groupsForCompilation })
          .then(() => {
            this.$store.dispatch('app/setSnackBar', { text: this.$t('Compilation has been updated', { compilation: this.selectedCompilation.name }) });
          })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar',
              {
                text: this.$t('An error occurred while updating the collection. Reload the page and try again'),
                error: true,
              });
          })
          .finally(() => {
            this.$emit('close');
            this.reset();
          });

        return;
      }
      // если создание новой подборки
      this.$store.dispatch('compilations/createNewCompilation', { name: this.nameNewCompilation, groups: this.groupsForCompilation })
        .then(() => {
          this.$emit('close');
          this.$store.dispatch('app/setSnackBar', { text: this.$t('A compilation has been created') });
        })
        .catch(() => {
          this.$emit('close');
          this.$store.dispatch('app/setSnackBar', { text: this.$t('An error occurred while saving the selection. Reload the page and try again') });
        });
    },
    reset() {
      this.nameNewCompilation = '';
      this.selectedCompilation = null;
      this.step = 1;
    },
    selectCompilation(compilation) {
      this.selectedCompilation = compilation;
    },
    deleteGroupFromSelected(group) {
      this.groupsForCompilation = this.groupsForCompilation.filter((item) => item.id !== group.id);
      if (this.groupsForCompilation.length === 0) {
        this.$emit('close');
      }
    },
  },
  computed: {
    labelForStep() {
      if (this.step === 2) {
        return this.$t('Create a new compilation');
      }
      if (this.step === 3) {
        return this.$t('Saving to an existing collection');
      }
      return this.$t('Saving the compilation');
    },
    getCompilationsList() {
      return this.$store.getters['compilations/getSimpleCompilationsList'];
    },
    isDisabledButton() {
      if (this.step === 2) {
        return !this.nameNewCompilation.length;
      }
      return this.selectedCompilation === null;
    },
    getGroupsToCompilation() {
      if (this.addFromMenu) {
        return this.$store.getters['app/getNewGroupsForCompilation'];
      }
      if (this.isCompilation) {
        return this.$store.getters['compilations/getCompilationsSelectedList'];
      }
      return this.$store.getters['groups/getSelectedGroups'];
    },
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
  },
  watch: {
    step(newValue, prevValue) {
      if (newValue === 1 && prevValue !== 1) {
        this.reset();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.save-collection {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  width: 400px;
}

.save-collection__header {
  font-size: 22px;
  color: $primary-black;
  margin: 20px 0px 30px;
  max-width: 400px;
}

.save-collection__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.save-collection__step {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.save-collection__input {
  min-width: 350px;
}

.save-collection__selected-groups {
  padding-bottom: 20px;
  position: relative;
}

.save-collection__selected-groups-container {
  width: 300px;
  background: white;
  overflow: hidden;
  z-index: 3;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 20px 0px 0px 0px;
}

.save-collection__selected-groups-list {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-height: 256px;
  overflow: auto;
}

.save-collection__selected-groups-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid rgba($primary-blue, 0.2);
}

.save-collection__selected-groups-text {
  font-weight: bold;
  color: $primary-blue;
}

.save-collection__back-text {
  font-size: 14px;
  color: $primary-lightgray;
  margin-top: 5px;
  cursor: pointer;
}

.save-collection__label {
  font-size: 14px;
  color: $primary-black;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.save-collection__save-button {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

</style>
