<template>
  <div class="groups-moderation-screen">
    <div class="groups-moderation-screen__title">
      <!-- Основные табы -->
      <span
      v-for="tab in tabs"
      :key="tab.value"
      :class="tab.name === selectedTab.name && 'groups-moderation-screen__text_selected'"
      class="groups-moderation-screen__text"
      @click="changeTab(tab)">
        {{ tab.name }}
      </span>

      <!-- Табы статуса -->
      <div class="groups-moderation-screen__tabs">
        <div
        v-for="tab in getInnerTabs"
        :key="tab.name"
        :class="tab.name === selectedInnerTab.name && 'groups-moderation-screen__tab-element_selected'"
        class="groups-moderation-screen__tab-element">
          <span
          class="groups-moderation-screen__tab"
          @click="changeSelectedInnerTab(tab)">
            {{ tab.name }}
          </span>
          <div
          v-if="getRequestsCountValue(tab)"
          class="groups-moderation-screen__tab-counter">
            <span class="groups-moderation-screen__tab-counter-text">{{ getRequestsCountValue(tab) }}</span>
          </div>
        </div>
      </div>
    </div>

    <GroupsModeration
    :is-loading-groups="isLoadingGroups"
    :source-type="selectedTab.sourceType"
    :groups="getGroupsForModerate"
    @getModerateGroups="getModerateGroups" />
  </div>
</template>

<script>
import GroupsModeration from './components/GroupsModeration.vue';

export default {
  name: 'GroupsModerationScreen',
  components: {
    GroupsModeration,
  },
  data: () => ({
    isLoadingGroups: false,
    selectedTab: null,
    selectedInnerTab: null,
    innerTabs: [
      { name: 'В работе', value: ['in work'], sourceType: 'publisherBot' },
      { name: 'Новый', value: ['wait'], sourceType: ['publisherBot', 'import'] },
      { name: 'История', value: ['applied', 'rejected'], sourceType: ['publisherBot', 'import'] }],
    tabs: [
      { name: 'Модерация каналов', sourceType: 'publisherBot', sourceTypePayload: ['publisherBot'] },
      { name: 'Модерация цен', sourceType: 'import', sourceTypePayload: ['import', 'front'] },
    ],
  }),
  beforeDestroy() {
    this.$store.commit('moderate/setGroupsForModerate', []);
  },
  created() {
    // eslint-disable-next-line prefer-destructuring
    this.selectedInnerTab = this.innerTabs[1];
    // eslint-disable-next-line prefer-destructuring
    this.selectedTab = this.tabs[0];

    this.getModerateGroups({ reset: true });
    this.$store.dispatch('moderate/getModerateRequestsCount', this.selectedTab.sourceTypePayload);
  },
  methods: {
    getModerateGroups(params) {
      this.isLoadingGroups = true;
      const payload = { reset: params.reset, sourceType: this.selectedTab.sourceTypePayload, status: this.selectedInnerTab.value };
      this.$store.dispatch('moderate/getModerateGroups', payload)
        .finally(() => {
          this.isLoadingGroups = false;
        });
    },
    changeTab(tab) {
      if (tab.name === this.selectedTab.name || this.isLoadingGroups) return;
      this.selectedTab = tab;
      if (tab.sourceType === 'publisherBot') {
        // eslint-disable-next-line prefer-destructuring
        this.selectedInnerTab = this.getInnerTabs[1];
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.selectedInnerTab = this.getInnerTabs[0];
      }
      this.$store.dispatch('moderate/getModerateRequestsCount', this.selectedTab.sourceType);
      this.getModerateGroups({ reset: true });
    },
    changeSelectedInnerTab(tab) {
      if (tab.name === this.selectedInnerTab.name || this.isLoadingGroups) return;
      this.selectedInnerTab = tab;
      this.getModerateGroups({ reset: true });
    },
    getRequestsCountValue(tab) {
      if (!this.getModerateRequestsCount) return null;

      if (tab.name === 'История') {
        const count = this.getModerateRequestsCount.applied + this.getModerateRequestsCount.rejected;
        return count || '-';
      }

      if (tab.name === 'В работе') {
        return this.getModerateRequestsCount.inWork || '-';
      }

      return (this.getModerateRequestsCount && this.getModerateRequestsCount[tab.value]) || null;
    },
  },
  computed: {
    getGroupsForModerate() {
      if (this.selectedTab.sourceType === 'publisherBot') {
        return this.$store.getters['moderate/getGroupsForModerate'];
      }
      return this.$store.getters['moderate/getGroupsForModerate'].filter((item) => item.entity);
    },
    getModerateRequestsCount() {
      return this.$store.getters['moderate/getModerateRequestsCount'] || null;
    },
    getInnerTabs() {
      return this.innerTabs.filter((tab) => tab.sourceType.includes(this.selectedTab.sourceType));
    },
  },
};
</script>

<style lang="scss" scoped>
.groups-moderation-screen {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
}

.groups-moderation-screen__text {
  font-size: 14px;
  font-weight: 600;
  color: #575757;
  &_light {
    font-weight: 500;
  }
}

.groups-moderation-screen__title {
  margin: 0px 20px 0px;
  @include between-elements {
    margin-right: 20px;
  }
}

.groups-moderation-screen__text {
  font-size: 18px;
  font-weight: 600;
  color: #575757;
  cursor: pointer;
  &_selected {
    color: $primary-blue;
  }
}

.groups-moderation-screen__tabs {
  margin-top: 10px;
  display: flex;
  @include between-elements {
    margin-right: 10px;
  }
}

.groups-moderation-screen__tab-element {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 25px;
  box-sizing: content-box;
  &_selected {
    color: $primary-blue;
    border-bottom: 2px solid $primary-blue;
    padding-bottom: 3px;
  }
}

.groups-moderation-screen__tab {
  color: rgba($primary-gray, 0.5);
  font-size: 13px;
  transition: 0.2s;
  cursor: pointer;
  position: relative;
}

.groups-moderation-screen__tab-counter {
  display: flex;
  border-radius: 6px;
  background: $primary-blue;
  color: white;
  padding: 2px 5px;
  margin-left: 5px;
}

.groups-moderation-screen__tab-counter-text {
  font-size: 12px;
  color: white;
}
</style>
