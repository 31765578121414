<template>
  <div
  class="nav-actions"
  :class="emptyData && 'nav-actions_hide-details'">
    <div
    v-show="!emptyData"
    class="nav-actions__details">
      <span
      class="nav-actions__text"
      @click="toggleAllGroupsToSelected(!isAllSelected)">{{ textForSetAllGroupsButton }}</span>
      <span
      class="nav-actions__text"
      @click="setHideGroupDetails">{{ getHideButtonText }}</span>
      <span
      v-if="isCompilation && getSelectedFolder"
      class="nav-actions__text"
      @click="openExportModal">Экспорт папки</span>
      <transition
      :key="selectedGroups.length"
      name="fade">
        <span
        v-if="isCompilation && selectedGroups.length"
        class="nav-actions__text"
        @click="openSelectedGroupsModal">Экспорт выбранных каналов</span>
      </transition>
      <span
      v-if="isImportedGroups"
      class="nav-actions__text pointer"
      @click="resetImportGroups">{{ importTitle }}</span>
      <transition
      :key="isEmptySelected"
      name="fade">
        <ShareGroups
        v-if="mode !== 'noauth' && mode !== 'publisher' && !isEmptySelected"
        :is-admin="isAdmin"
        :is-compilation="isCompilation"
        :selected-groups="selectedGroups" />
      </transition>
    </div>
    <div
    v-if="mode !== 'noauth'"
    class="nav-actions__actions">
      <div
      v-if="!nomenu"
      class="nav-actions__collections">
        <Compilations v-if="showCompilations" />
        <Favorites
        v-if="showFavorites"
        :is-show-favorite-groups="isShowFavoriteGroups" />
      </div>
      <CompilationsDrag
      v-if="isCompilation"
      class="nav-actions__compilation-drags" />
      <AdminButtons
      v-if="(isAdmin || getIsEmployerAgency || isManager) && !nomenu && !isCompilation"
      :is-admin="isAdmin"
      :is-employer-agency="getIsEmployerAgency"
      :is-manager="isManager" />
      <NavSettings v-if="!customColumns" />
    </div>
  </div>
</template>

<script>
import CompilationsDrag from './components/CompilationsDrag.vue';
import Compilations from './components/Compilations.vue';
import Favorites from './components/Favorites.vue';
import AdminButtons from './components/AdminButtons.vue';
import NavSettings from './components/NavSettings.vue';
import ShareGroups from './components/ShareGroups.vue';

export default {
  name: 'NavActions',
  components: {
    Compilations, Favorites, AdminButtons, NavSettings, ShareGroups, CompilationsDrag,
  },
  props: {
    emptyData: {
      type: Boolean,
      default: false,
    },
    groupsData: {
      type: Array,
      default: () => [],
    },
    selectedGroups: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: null,
    },
    nomenu: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isCompilation: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    const isHideDetails = JSON.parse(localStorage.getItem('hideDetails'));
    this.$store.commit('groups/setHideGroupDetails', isHideDetails === null ? true : isHideDetails);
  },
  methods: {
    setHideGroupDetails() {
      localStorage.setItem('hideDetails', !this.getHideGroupDetails);
      this.$store.commit('groups/setHideGroupDetails', !this.getHideGroupDetails);
    },
    toggleAllGroupsToSelected(value) {
      if (this.mode === 'publisher') {
        this.$emit('selectAll');
        return;
      }
      if (this.isCompilation) {
        if (!value) {
          const groupsToUnselected = this.selectedGroups.filter((item) => !this.groupsData.some((el) => el.id === item.id && el.name === item.name));
          this.$store.commit('compilations/setSelectedCompilationList', groupsToUnselected);
        } else {
          const groupsToSelected = this.groupsData.filter((item) => !this.selectedGroups.some((el) => el.id === item.id && el.name === item.name));
          this.$store.commit('compilations/setSelectedCompilationList', [...this.selectedGroups, ...groupsToSelected]);
        }
      } else if (!value) {
        const groupsToUnselected = this.selectedGroups.filter((item) => !this.groupsData.some((el) => el.id === item.id && el.name === item.name));
        this.$store.commit('groups/setSelectedGroups', groupsToUnselected);
        if (this.isShowSelectedGroups && !groupsToUnselected.length) {
          this.$store.commit('collections/setShowSelectedGroups', false);
        }
      } else {
        const groupsToSelected = this.groupsData.filter((item) => !this.selectedGroups.some((el) => el.id === item.id && el.name === item.name));
        this.$store.commit('groups/setSelectedGroups', [...this.selectedGroups, ...groupsToSelected]);
      }
    },
    openExportModal() {
      const compilation = this.isCompilation
        ? {
          name: this.getCompilationInfo.name, id: this.getCompilationInfo.id, isFolder: true,
        } : null;
      const modalParams = { name: 'ExportGroupsModal', props: { compilation } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    openSelectedGroupsModal() {
      const compilation = this.isCompilation
        ? {
          name: this.getCompilationInfo.name, id: this.getCompilationInfo.id, isFolder: false, selectedGroups: true,
        } : null;
      const selectedGroups = [...this.selectedGroups];
      const modalParams = { name: 'ExportGroupsModal', props: { selectedGroups, compilation } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    resetImportGroups() {
      const query = { group: 'telegram' };
      this.$router.push({ path: this.$router.currentPath, query });
      this.$store.dispatch('groups/loadGroupsData', { reset: true, aggregate: true });
    },
  },
  computed: {
    customColumns() {
      return this.$store.getters['compilation/getCurrentCompilationInfo']?.sharedFields;
    },
    getHideButtonText() {
      return this.getHideGroupDetails ? this.$t('show_details') : this.$t('hide_details');
    },
    getHideGroupDetails() {
      return this.$store.getters['groups/getHideGroupDetails'];
    },
    isEmptySelected() {
      return this.selectedGroups.length === 0;
    },
    textForSetAllGroupsButton() {
      return this.isAllSelected ? this.$t('reset') : this.$t('select_all');
    },
    isAllSelected() {
      return this.groupsData.every((item) => this.selectedGroups.some((el) => el.name === item.name));
    },
    isShowFavoriteGroups() {
      return this.$store.getters['favorites/getShowFavoriteGroups'];
    },
    getIsEmployerAgency() {
      return this.$store.getters['user/getIsEmployerAgency'] && !this.getIsDemoCatalog;
    },
    isManager() {
      return this.$store.getters['user/getIsManager'];
    },
    getIsDemoCatalog() {
      return this.$store.getters['user/getIsDemoCatalog'];
    },
    getCompilationInfo() {
      return this.$store.getters['compilations/getCurrentCompilationInfo'];
    },
    isShowSelectedGroups() {
      return this.$store.getters['collections/getShowSelectedGroups'];
    },
    isImportedGroups() {
      return this.$store.getters['groups/getIsImportedGroups'];
    },
    importTitle() {
      return `Отображаются каналы из импорта ${this.isImportedGroups}. Нажмите, чтобы сбросить`;
    },
    showFavorites() {
      return this.mode !== 'my-groups' && this.mode !== 'publisher' && !this.isCompilation;
    },
    showCompilations() {
      return !this.isCompilation && this.mode !== 'publisher';
    },
    getSelectedFolder() {
      return this.$store.getters['compilations/getSelectedFolder'];
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-actions {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0px 10px;
  min-height: 50px;
  @include tablet-screen {
    padding: 10px 20px;
    justify-content: space-between;
  }
  &_hide-details {
    justify-content: flex-end;
  }
}

.nav-actions__details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include between-elements {
    margin-right: 25px;
  }
}

.nav-actions__actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include between-elements {
    margin-right: 25px;
  }
}

.nav-actions__collections {
  display: flex;
  justify-content: center;
  align-items: center;
  @include between-elements {
    margin-right: 10px;
  }
}

.nav-actions__text {
  font-size: 13px;
  cursor: pointer;
}

.nav-actions__compilation-drags {
  display: flex;
}

</style>
