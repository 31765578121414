<template>
  <div class="create-placement__wrapper">
    <!-- Основная модалка без каналов -->
    <div
    v-if="!selectedData.length && !viewMode"
    class="create-placement">
      <div class="create-placement__content">
        <div
        :key="openEditInViewMode"
        class="create-placement__form">
          <div class="create-placement__column">
            <!-- Название -->
            <div class="create-placement__form-item">
              <span
              class="create-placement__form-item-title"
              :class="{
                'create-placement__form-item-title_error': errors.rkname,
              }">
                Название РК
              </span>
              <DataInput
              v-model="rkname"
              clear-icon
              :no-edit="viewMode && !openEditInViewMode"
              :error="errors.rkname"
              :theme="isReadyRequest ? 'white' : ''"
              placeholder="Название рекламной кампании" />
            </div>

            <!-- Выбор клиента (при условии что заявка создается админом) -->
            <div
            v-if="isAdmin && !currentPlacement && !hideAdminClientsSelect"
            class="create-placement__form-item">
              <span
              class="create-placement__form-item-title"
              :class="{
                'create-placement__form-item-title_error': errors.selectedAdminClient,
              }">
                Выбор клиента
              </span>
              <DataSelect
              :value="selectedAdminClient"
              :options="adminClientsList"
              with-search
              :theme="isReadyRequest ? 'white' : ''"
              :no-edit="viewMode && !openEditInViewMode"
              :error="errors.selectedAdminClient"
              placeholder="Выберите клиента"
              @input="setSelectedAdminClient" />
            </div>

            <!-- Выбор пользователя от клиента (при условии что заявка создается админом) -->
            <div
            v-if="isAdmin && !currentPlacement && !hideAdminClientsSelect && selectedAdminClient"
            class="create-placement__form-item">
              <span
              class="create-placement__form-item-title"
              :class="{
                'create-placement__form-item-title_error': errors.selectedAdminClientManager,
              }">
                Выбор пользователя
              </span>
              <DataSelect
              v-model="selectedAdminClientManager"
              :options="adminClientsManagersList"
              with-search
              :theme="isReadyRequest ? 'white' : ''"
              :no-edit="viewMode && !openEditInViewMode"
              :error="errors.selectedAdminClientManager"
              placeholder="Выберите пользователя" />
            </div>

            <!-- Описание РК -->
            <div class="create-placement__form-item">
              <span
              class="create-placement__form-item-title"
              :class="{ 'create-placement__form-item-title_error': errors.brief }">
                Описание объекта рекламы
              </span>
              <DataTextarea
              v-model="brief"
              :error="errors.brief"
              :height="156"
              :theme="isReadyRequest ? 'white' : ''"
              :no-edit="viewMode && !openEditInViewMode"
              placeholder="Опишите что рекламируем для качественной подборки источников для рекламной кампании" />
            </div>

            <!-- Подробности -->
            <div class="create-placement__form-item">
              <span
              class="create-placement__form-item-title">
                Подробности
              </span>
              <DataTextarea
              v-model="description"
              :height="156"
              :theme="isReadyRequest ? 'white' : ''"
              :no-edit="viewMode && !openEditInViewMode"
              placeholder="Добавьте комментарий" />
            </div>

            <div v-if="showAddFile">
              <!-- Добавление файлов -->
              <div
              v-if="files.length < 3"
              class="create-placement__add-file"
              @click="$refs.fileinput.click()">
                <img
                class="create-placement__add-file-icon"
                src="@/assets/svg/icons/download-icon.svg">
                <span class="create-placement__add-file-text">Прикрепить файл</span>
                <span class="create-placement__add-file-text create-placement__add-file-text_desc">
                  {{ getAddFileText }}
                </span>
                <input
                ref="fileinput"
                :style="{'display': 'none'}"
                type="file"
                multiple
                @change="addFile">
              </div>

              <div
              v-for="file in files"
              :key="file.name + file.lastModified"
              class="create-placement__add-file">
                <img
                class="app-loader__loader"
                src="@/assets/svg/icons/download-icon.svg">
                <span class="create-placement__add-file-text create-placement__add-file-text_name">{{ file.name }}</span>
                <DataIcon
                name="close"
                class="create-placement__add-file-text create-placement__add-file-text_desc"
                @click.native="removeFile(file)" />
              </div>
            </div>

            <div v-if="viewMode && !openEditInViewMode">
              <!-- Добавленные файлы -->
              <div
              v-for="file in loadedFiles"
              :key="file.name"
              class="create-placement__add-file"
              @click="openFileLink(file)">
                <img
                class="app-loader__loader"
                src="@/assets/svg/icons/download-icon.svg">
                <span class="create-placement__add-file-text create-placement__add-file-text_name">{{ file.name }}</span>
              </div>
              <iframe
              id="my_iframe"
              style="display:none;" />
            </div>

            <div
            v-if="addNewRevision"
            class="create-placement__form-item">
              <span
              class="create-placement__form-item-title">
                Комментарий
              </span>
              <DataTextarea
              v-model.trim="newComment"
              :height="120"
              :theme="isReadyRequest ? 'white' : ''"
              :no-edit="viewMode && !openEditInViewMode"
              placeholder="Добавьте новый комментарий" />
            </div>
          </div>

          <div class="create-placement__column">
            <!-- Креатив -->
            <div class="create-placement__form-item">
              <span
              class="create-placement__form-item-title"
              :class="{
                'create-placement__form-item-title_error': errors.creative,
              }">
                Креатив
              </span>
              <DataSelect
              v-model="creative"
              :no-edit="viewMode && !openEditInViewMode"
              :options="creativeVariants"
              :theme="isReadyRequest ? 'white' : ''"
              :error="errors.creative"
              placeholder="Выберите формат" />
            </div>

            <!-- Формат размещения -->
            <div class="create-placement__form-item">
              <span
              class="create-placement__form-item-title"
              :class="{
                'create-placement__form-item-title_error': errors.format,
              }">
                Формат размещения
              </span>
              <DataSelect
              v-model="format"
              :options="formatVariants"
              select-type="multi"
              :theme="isReadyRequest ? 'white' : ''"
              :no-edit="viewMode && !openEditInViewMode"
              :error="errors.format"
              placeholder="Выберите формат" />
            </div>

            <!-- Социальные сети -->
            <div class="create-placement__form-item">
              <span
              class="create-placement__form-item-title"
              :class="{
                'create-placement__form-item-title_error': errors.socials,
              }">
                Социальные сети
              </span>
              <DataSelect
              v-model="socials"
              :options="getSocialVariants"
              select-type="multi"
              :no-edit="viewMode && !openEditInViewMode"
              :error="errors.socials"
              :theme="isReadyRequest ? 'white' : ''"
              placeholder="Выберите социальные сети" />
            </div>

            <!-- Категории -->
            <div class="create-placement__form-item">
              <span
              class="create-placement__form-item-title"
              :class="{
                'create-placement__form-item-title_error': errors.categories,
              }">
                Категории
              </span>
              <DataSelect
              v-model="categories"
              :options="getCategoriesVariants"
              select-type="multi"
              with-search
              :no-edit="viewMode && !openEditInViewMode"
              :error="errors.categories"
              :theme="isReadyRequest ? 'white' : ''"
              placeholder="Выберите категории" />
            </div>

            <div class="create-placement__deadline">
              <!-- Бюджет -->
              <div class="create-placement__form-item">
                <span
                class="create-placement__form-item-title"
                :class="{
                  'create-placement__form-item-title_error': errors.budget,
                }">
                  Бюджет
                </span>
                <DataInput
                v-model="budget"
                v-money="money"
                :error="errors.budget"
                :no-edit="viewMode && !openEditInViewMode"
                :theme="isReadyRequest ? 'white' : ''"
                placeholder="Укажите бюджет" />
              </div>
              <!-- Дедлайн -->
              <div class="create-placement__form-item">
                <span
                class="create-placement__form-item-title"
                :class="{
                  'create-placement__form-item-title_error': errors.deadline,
                }">
                  Дедлайн
                </span>
                <DataDatePicker
                v-model="deadline"
                :error="errors.deadline"
                :theme="isReadyRequest ? 'white' : ''"
                :no-edit="viewMode && !openEditInViewMode" />
              </div>

              <div class="create-placement__form-item">
                <span
                class="create-placement__form-item-title">
                  Время
                </span>
                <DataTimePicker
                v-model="time"
                :theme="isReadyRequest ? 'white' : ''"
                :no-edit="viewMode && !openEditInViewMode" />
              </div>
            </div>

            <div class="create-placement__toggles">
              <!-- Креатив -->
              <div class="create-placement__toggles-item">
                <DataToggle
                v-model="advertisingLabel"
                :disabled="viewMode && !openEditInViewMode" />
                <span class="create-placement__form-item-title">
                  Метка о рекламе
                </span>
              </div>
              <!-- Креатив -->
              <div class="create-placement__toggles-item">
                <DataToggle
                v-model="marking"
                :theme="isReadyRequest ? 'white' : ''"
                :disabled="viewMode && !openEditInViewMode" />
                <span class="create-placement__form-item-title"> Маркировка </span>
              </div>
            </div>
          </div>
        </div>

        <!-- Блок кнопок -->
        <div
        v-if="!viewMode"
        class="create-placement__buttons">
          <DataButton
          :text="$t('send_an_order')"
          :width="220"
          @handler="sendForm" />
        </div>
      </div>
    </div>

    <!-- Модалка с выбранными каналами -->
    <div
    v-else-if="selectedData.length || viewMode"
    class="create-placement__selected">
      <div class="create-placement__main-selected">
        <div class="create-placement__content">
          <div class="create-placement__column">
            <div class="create-placement__form-item-container">
              <!-- Название -->
              <div class="create-placement__form-item">
                <span
                class="create-placement__form-item-title"
                :class="{
                  'create-placement__form-item-title_error': errors.rkname,
                }">
                  Название РК
                </span>
                <DataInput
                v-model="rkname"
                clear-icon
                :no-edit="viewMode && !openEditInViewMode"
                :error="errors.rkname"
                placeholder="Название кампании" />
              </div>
              <!-- Креатив -->
              <div class="create-placement__form-item">
                <span
                class="create-placement__form-item-title"
                :class="{
                  'create-placement__form-item-title_error': errors.creative,
                }">
                  Креатив
                </span>
                <DataSelect
                v-model="creative"
                :no-edit="viewMode && !openEditInViewMode"
                :options="creativeVariants"
                :error="errors.creative"
                placeholder="Выберите формат" />
              </div>
            </div>

            <!-- Выбор клиента (при условии что заявка создается админом) -->
            <div
            v-if="isAdmin && !currentPlacement && !hideAdminClientsSelect"
            class="create-placement__form-item">
              <span
              class="create-placement__form-item-title"
              :class="{
                'create-placement__form-item-title_error': errors.selectedAdminClient,
              }">
                Выбор клиента
              </span>
              <DataSelect
              :value="selectedAdminClient"
              :options="adminClientsList"
              with-search
              :theme="isReadyRequest ? 'white' : ''"
              :no-edit="viewMode && !openEditInViewMode"
              :error="errors.selectedAdminClient"
              placeholder="Выберите клиента"
              @input="setSelectedAdminClient" />
            </div>

            <!-- Выбор пользователя от клиента (при условии что заявка создается админом) -->
            <div
            v-if="isAdmin && !currentPlacement && !hideAdminClientsSelect && selectedAdminClient"
            class="create-placement__form-item">
              <span
              class="create-placement__form-item-title"
              :class="{
                'create-placement__form-item-title_error': errors.selectedAdminClient,
              }">
                Выбор пользователя
              </span>
              <DataSelect
              v-model="selectedAdminClientManager"
              :options="adminClientsManagersList"
              with-search
              :theme="isReadyRequest ? 'white' : ''"
              :no-edit="viewMode && !openEditInViewMode"
              :error="errors.selectedAdminClient"
              placeholder="Выберите пользователя" />
            </div>

            <!-- Описание РК -->
            <div class="create-placement__form-item">
              <span
              class="create-placement__form-item-title"
              :class="{ 'create-placement__form-item-title_error': errors.brief }">
                Описание объекта рекламы
              </span>
              <DataTextarea
              v-model="brief"
              :error="errors.brief"
              :height="100"
              :no-edit="viewMode && !openEditInViewMode"
              placeholder="Опишите что рекламируем для качественной подборки источников для рекламной кампании" />
            </div>

            <!-- Подробности -->
            <div class="create-placement__form-item">
              <span
              class="create-placement__form-item-title">
                Подробности
              </span>
              <DataTextarea
              v-model="description"
              :height="100"
              :no-edit="viewMode && !openEditInViewMode"
              placeholder="Добавьте комментарий" />
            </div>

            <div class="create-placement__deadline">
              <!-- Дедлайн -->
              <div class="create-placement__form-item">
                <span
                class="create-placement__form-item-title"
                :class="{
                  'create-placement__form-item-title_error': errors.budget,
                }">
                  Дедлайн
                </span>
                <DataDatePicker
                v-model="deadline"
                :error="errors.deadline"
                :no-edit="viewMode && !openEditInViewMode" />
              </div>

              <div class="create-placement__form-item">
                <span
                class="create-placement__form-item-title">
                  Время
                </span>
                <DataTimePicker
                v-model="time"
                :no-edit="viewMode && !openEditInViewMode" />
              </div>
            </div>

            <!-- Добавление файлов -->
            <div v-if="showAddFile">
              <!-- Добавление файлов -->
              <div
              v-if="files.length < 3"
              class="create-placement__add-file"
              @click="$refs.fileinput.click()">
                <img
                class="create-placement__add-file-icon"
                src="@/assets/svg/icons/download-icon.svg">
                <span class="create-placement__add-file-text">Прикрепить файл</span>
                <span class="create-placement__add-file-text create-placement__add-file-text_desc">
                  {{ getAddFileText }}
                </span>
                <input
                ref="fileinput"
                :style="{'display': 'none'}"
                type="file"
                multiple
                @change="addFile">
              </div>

              <div
              v-for="file in files"
              :key="file.name + file.lastModified"
              class="create-placement__add-file">
                <img
                class="app-loader__loader"
                src="@/assets/svg/icons/download-icon.svg">
                <span class="create-placement__add-file-text create-placement__add-file-text_name">{{ file.name }}</span>
                <DataIcon
                name="close"
                class="create-placement__add-file-text create-placement__add-file-text_desc"
                @click.native="removeFile(file)" />
              </div>
            </div>

            <div v-if="viewMode && !openEditInViewMode">
              <!-- Добавленные файлы -->
              <div
              v-for="file in loadedFiles"
              :key="file.name"
              class="create-placement__add-file"
              @click="openFileLink(file)">
                <img
                class="app-loader__loader"
                src="@/assets/svg/icons/download-icon.svg">
                <span class="create-placement__add-file-text create-placement__add-file-text_name">{{ file.name }}</span>
              </div>
              <iframe
              id="my_iframe"
              style="display:none;" />
            </div>

            <div class="create-placement__toggles">
              <!-- Рекламная метка -->
              <div class="create-placement__toggles-item">
                <DataToggle
                v-model="advertisingLabel"
                :disabled="viewMode && !openEditInViewMode" />
                <span class="create-placement__form-item-title">
                  Метка о рекламе
                </span>
              </div>
              <!-- Маркировка -->
              <div class="create-placement__toggles-item">
                <DataToggle
                v-model="marking"
                :disabled="viewMode && !openEditInViewMode" />
                <span class="create-placement__form-item-title">Маркировка</span>
              </div>
            </div>

            <div
            v-if="addNewRevision"
            class="create-placement__form-item">
              <span
              class="create-placement__form-item-title">
                Комментарий
              </span>
              <DataTextarea
              v-model.trim="newComment"
              :height="120"
              :theme="isReadyRequest ? 'white' : ''"
              :no-edit="viewMode && !openEditInViewMode"
              placeholder="Добавьте новый комментарий" />
            </div>
          </div>
          <!-- Блок кнопок -->
          <div
          v-if="!viewMode"
          class="create-placement__buttons">
            <DataButton
            :text="$t('send_an_order')"
            :width="220"
            @handler="sendForm" />
          </div>

          <!-- Доработка заказа -->
          <div
          v-if="currentPlacement?.status"
          class="create-placement__new-revision">
            <div class="create-placement__buttons">
              <div
              v-if="!setInProgressModal"
              class="create-placement__buttons-container">
                <DataButton
                :type="currentPlacement?.status === 3 && !addNewRevision ? 'outlined' : 'primary'"
                :text="addNewRevision ? 'Отправить на доработку' : 'Редактировать заказ'"
                :width="addNewRevision ? 250 : 200"
                @handler="newRevisionHandler" />
                <DataButton
                v-if="addNewRevision"
                type="outlined"
                text="Отменить"
                @handler="cancelEdit" />
                <div
                v-if="currentPlacement?.status && !addNewRevision"
                class="create-placement__status-button"
                :class="currentPlacement?.status === 3 && 'create-placement__status-button_ready'"
                @click="openReadyCompilation">
                  <span
                  v-if="currentPlacement?.status !== 3"
                  class="create-placement__status-button-text">Статус подборки: &nbsp;</span>
                  <span class="create-placement__status-button-text create-placement__status-button-text_bold">{{ getPlacementStatus }}</span>
                </div>
                <div class="create-placement__request-links">
                  <span
                  v-if="currentPlacement?.status !== 3 && isAdmin && viewMode"
                  class="create-placement__request-link"
                  @click="openCurrentCompilation">Открыть подборку</span>
                  <span
                  v-if="isAdmin && currentPlacement?.status === 1 && showSetInProgressButton && !openEditInViewMode"
                  class="create-placement__request-link"
                  @click="setInProgressModal = true">Взять в работу</span>
                </div>
              </div>
              <div
              v-if="setInProgressModal"
              class="create-placement__buttons-container">
                <span class="create-placement__text">Вы точно хотите взять запрос в работу?</span>
                <div class="create-placement__content__set-in-progress-buttons">
                  <DataButton
                  text="Взять в работу"
                  :width="220"
                  @handler="setInProgress" />
                  <DataButton
                  type="outlined"
                  text="Отменить"
                  @handler="cancelSetInProgress" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <SelectedGroups
        v-if="!additional"
        ref="selectedGroups"
        :selected-groups="selectedData"
        :is-loading="isLoadingInitialCompilation"
        :view-mode="viewMode"
        @setSelectedData="setSelectedData"
        @openAdditional="additional = true" />

        <div
        v-if="additional"
        class="create-placement__column">
          <div class="create-placement__title">
            {{ viewMode ? 'Дополнительные данные' : 'Запрос на дополнительную подборку' }}
          </div>
          <!-- Формат размещения -->
          <div class="create-placement__form-item">
            <span
            class="create-placement__form-item-title"
            :class="{
              'create-placement__form-item-title_error': errors.format,
            }">
              Формат размещения
            </span>
            <DataSelect
            v-model="format"
            :options="formatVariants"
            select-type="multi"
            :no-edit="viewMode && !openEditInViewMode"
            :error="errors.format"
            placeholder="Выберите формат" />
          </div>

          <!-- Социальные сети -->
          <div class="create-placement__form-item">
            <span
            class="create-placement__form-item-title"
            :class="{
              'create-placement__form-item-title_error': errors.socials,
            }">
              Социальные сети
            </span>
            <DataSelect
            v-model="socials"
            :options="getSocialVariants"
            select-type="multi"
            :no-edit="viewMode && !openEditInViewMode"
            :error="errors.socials"
            placeholder="Выберите социальные сети" />
          </div>

          <!-- Категории -->
          <div class="create-placement__form-item">
            <span
            class="create-placement__form-item-title"
            :class="{
              'create-placement__form-item-title_error': errors.categories,
            }">
              Категории
            </span>
            <DataSelect
            v-model="categories"
            :options="getCategoriesVariants"
            select-type="multi"
            with-search
            :no-edit="viewMode && !openEditInViewMode"
            :error="errors.categories"
            placeholder="Выберите категории" />
          </div>

          <div class="create-placement__deadline">
            <!-- Бюджет -->
            <div class="create-placement__form-item">
              <span
              class="create-placement__form-item-title"
              :class="{
                'create-placement__form-item-title_error': errors.budget,
              }">
                Бюджет
              </span>
              <DataInput
              v-model="budget"
              v-money="money"
              :error="errors.budget"
              theme="white"
              :no-edit="viewMode && !openEditInViewMode"
              placeholder="Укажите бюджет" />
            </div>
          </div>

          <DataButton
          type="outlined"
          :width="viewMode ? 260 : 230"
          :text="viewMode ? 'Показать выбранные каналы' : 'Сохранить'"
          @handler="additional = false" />
        </div>
      </div>
      <div
      v-if="currentPlacement?.status"
      class="create-placement__new-revision-comment">
        <div
        class="create-placement__new-revision-comment-title">
          История комментариев
        </div>
        <div
        v-if="currentPlacement.comments?.length"
        :key="currentPlacement.comments?.length"
        class="create-placement__new-revision-last-comment">
          <div class="create-placement__comments">
            <div
            v-for="comment in getPlacementComments"
            :key="comment.id"
            class="create-placement__comment">
              <div class="create-placement__comment_title">
                <span class="create-placement__text_comment">
                  {{ getCommentDate(comment) }}
                </span>
                <span class="create-placement__text_comment">
                  {{ getCommentCreator(comment) }}
                </span>
              </div>
              <span class="create-placement__text">
                {{ comment.content }}
              </span>
            </div>
          </div>
        </div>
        <span
        v-else
        class="create-placement__text create-placement__text_light">Комментариев еще не было :)</span>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/axios';
import getBaseUrl from '@/assets/js/helpers';
import SelectedGroups from './SelectedGroups.vue';

export default {
  name: 'CreatePlacement',
  components: { SelectedGroups },
  props: {
    selectedGroups: {
      type: Array,
      default: () => [],
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    currentPlacement: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    rkname: '',
    brief: '',
    description: '',
    advertisingLabel: true,
    marking: true,
    creativeVariants: [
      { id: 1, name: 'Текст' },
      { id: 2, name: 'Текст + видео' },
      { id: 3, name: 'Текст + изображение' },
      { id: 4, name: 'Только изображение' },
      { id: 5, name: 'Только видео' },
      { id: 6, name: 'Другое' },
    ],
    creative: null,
    formatVariants: [
      { id: 1, name: '1/24', value: '1/24' },
      { id: 2, name: '2/48', value: '2/48' },
      { id: 3, name: 'Закреп', value: 'Закреп' },
      { id: 4, name: 'Сторис', value: 'Сторис' },
      { id: 5, name: 'Месяц без удаления', value: 'Без удаления' },
      { id: 6, name: 'Другое', value: 'Другое' },
    ],
    format: null,
    categoriesVariants: [],
    categories: [],
    socialsVariants: [
      { value: 'telegram', name: 'Телеграм', disabled: false },
      { value: 'vk', name: 'Вконтакте', disabled: false },
      { value: 'instagram', name: 'Инстаграм', disabled: false },
      { value: 'odnoklassniki', name: 'Одноклассники', disabled: false },
      { value: 'youtube', name: 'YouTube', disabled: false },
      { value: 'yandex-zen', name: 'Дзен', disabled: false },
    ],
    socials: [],
    budget: null,
    deadline: null,
    time: '09:00',
    errors: {
      rkname: false,
      brief: false,
      creative: false,
      format: false,
      categories: false,
      socials: false,
      budget: false,
      deadline: false,
      selectedAdminClient: false,
      selectedAdminClientManager: false,
    },
    openEditInViewMode: false,
    money: {
      decimal: ' ',
      thousands: ' ',
      suffix: ' ₽',
      precision: 0,
    },
    additional: false,
    selectedData: [],
    addNewRevision: false,
    newComment: '',
    showSetInProgressButton: true,
    setInProgressModal: false,
    loadedFiles: [],
    files: [],
    selectedAdminClient: null,
    selectedAdminClientManager: null,
    adminClientsManagersList: null,
    adminClientsList: [{ id: 0, name: 'Идет загрузка клиентов...' }],
    hideAdminClientsSelect: false,
    initialCompilationGroups: null,
    isLoadingInitialCompilation: false,
  }),
  created() {
    this.init();
    if (this.isAdmin && !this.currentPlacement) {
      this.getClientsForAdminSelect();
    }
  },
  methods: {
    init() {
      if (this.viewMode) {
        this.fillRequestData();
        this.getInitialCompilationGroups();
        return;
      }
      if (this.selectedGroups.length) {
        this.selectedData = [...this.selectedGroups];
        this.fillSelectedGroupsData();
      }
    },
    getInitialCompilationGroups() {
      const compilationId = this.currentPlacement.initialCompilation?.id || null;
      if (!compilationId) {
        this.additional = true;
        return;
      }
      this.isLoadingInitialCompilation = true;
      api.get(`/compilations/${compilationId}/positions`)
        .then(({ data: { data } }) => {
          const groups = data.map((item) => (item.group));
          this.selectedData = [...groups];
          this.$nextTick(() => {
            this.$refs.selectedGroups.initGroups();
            if (!this.selectedData.length) {
              this.additional = true;
            }
          });
          this.isLoadingInitialCompilation = false;
        });
    },
    buttonHandler() {
      if (this.openEditInViewMode) this.sendForm();
      else this.openEdit();
    },
    newRevisionHandler() {
      if (this.addNewRevision) {
        this.sendForm();
      } else {
        this.addNewRevision = true;
        this.openEdit();
      }
    },
    sendForm() {
      const formIsValid = this.validateForm();
      if (formIsValid) {
        this.sendRequest();
      }
    },
    addFile() {
      const newFileName = this.$refs.fileinput.files[0].name;
      const newFileDate = this.$refs.fileinput.files[0].lastModified;
      if (this.files.some((item) => item.name === newFileName && item.lastModified === newFileDate)) {
        return;
      }
      this.files.push(this.$refs.fileinput.files[0]);
    },
    removeFile(file) {
      this.files = this.files.filter((item) => item.name !== file.name && item.lastModified !== file.lastModified);
    },
    openFileLink(file) {
      const url = `https://data-api.socialjet.pro${file.link}`;
      window.open(url, '_blank');
    },
    setInProgress() {
      const params = {
        id: this.currentPlacement.id,
        responsibleOurUserId: `${this.getUserInfo.id}`,
        responsibleLink: this.getUserInfo.telegram || this.getUserInfo.email,
      };
      this.$store.dispatch('placements/setInProgress', params)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', {
            text: 'Успешно. Запрос взят в работу',
          });
          this.init();
          this.setInProgressModal = false;
          this.showSetInProgressButton = false;
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: 'Ошибка. Попробуйте позже',
              error: true,
            });
          this.setInProgressModal = false;
        });
    },
    setSelectedData(value) {
      this.selectedData = [...value];
      if (!this.selectedData.length) {
        this.additional = true;
      }
      this.fillSelectedGroupsData();
    },
    sendRequest() {
      const formData = new FormData();

      const deadline = this.time ? `${this.deadline} ${this.time}` : `${this.deadline} 00:00`;
      formData.append('campaignsName', this.rkname);
      formData.append('brief', this.brief);
      formData.append('description', this.description);
      formData.append('creative', this.creative.name);
      formData.append('advertisingLabel', this.advertisingLabel);
      formData.append('marking', this.marking);
      formData.append('budget', String(this.budget).replace(/[^\d]/g, ''));
      formData.append('deadline', deadline);

      if (this.isAdmin && !this.currentPlacement) {
        formData.append('authorId', this.selectedAdminClientManager.id);
      }

      if (this.addNewRevision && this.newComment.length) {
        formData.append('comment', this.newComment);
      }

      // соц сети
      this.socials.forEach((item) => {
        formData.append('socials[]', item.value);
      });

      // категории
      if (this.selectedData && !this.categories.length) {
        formData.append('categories[]', null);
      } else {
        this.categories.forEach((item) => {
          formData.append('categories[]', item.value);
        });
      }

      // формат размещения
      this.format.forEach((item) => {
        formData.append('placement[]', item.value);
      });

      // файлы
      this.files.forEach((item) => {
        formData.append('files[]', item);
      });

      if (this.selectedData.length) {
        this.selectedData.forEach((item) => {
          const group = { id: item.id, social: item.social };
          const object = JSON.stringify(group);
          formData.append('groupIdentities[]', object);
        });
      }

      if (this.currentPlacement?.status === 3) {
        formData.append('id', this.currentPlacement.id);
        this.$store.dispatch('placements/sendToRework', formData)
          .then(() => {
            this.currentPlacement.status = 2;
            this.$store.dispatch('app/setSnackBar', { text: this.$t('Запрос отправлен на доработку') });
            this.$emit('close');
          })
          .catch(() => {
            this.$emit('close');
            this.$store.dispatch('app/setSnackBar', {
              text: this.$t('Произошла ошибка при отправке на доработку'),
              error: true,
            });
          });
        return;
      }

      if (this.viewMode) {
        formData.append('id', this.currentPlacement.id);
        this.$store.dispatch('placements/updatePlacement', { id: this.currentPlacement.id, formData })
          .then(() => {
            this.addNewRevision = false;
            this.$store.dispatch('app/setSnackBar', { text: this.$t('Запрос отредактирован') });
            this.$emit('close');
          })
          .catch(() => {
            this.$emit('close');
            this.$store.dispatch('app/setSnackBar', {
              text: this.$t('Произошла ошибка при обновлении запроса'),
              error: true,
            });
          });
        return;
      }

      this.$store.dispatch('placements/createPlacement', formData)
        .then(() => {
          this.$emit('success');
        })
        .catch(() => {
          this.$emit('close');
          this.$store.dispatch('app/setSnackBar', {
            text: this.$t(
              'an_error_occurred_while_sending_refresh_the_page_and_try_again',
            ),
            error: true,
          });
        });
    },
    validateForm() {
      this.errors.rkname = !this.rkname.length;
      this.errors.brief = !this.brief.length;
      this.errors.creative = !this.creative;
      this.errors.format = !this.format;
      this.errors.categories = !this.categories.length;
      this.errors.socials = !this.socials.length;
      this.errors.budget = String(this.budget).replace(/[^\d]/g, '') === '0';
      this.errors.deadline = !this.deadline;
      if (this.isAdmin && !this.currentPlacement) {
        this.errors.selectedAdminClient = !this.selectedAdminClient?.id;
        this.errors.selectedAdminClientManager = !this.selectedAdminClientManager?.id;
      } else {
        this.errors.selectedAdminClient = false;
      }
      const formIsValid = !Object.values(this.errors).some((item) => item === true);

      if (!formIsValid && this.selectedData.length) {
        this.additional = true;
      }

      if (!formIsValid) {
        try {
          const invalid = [];
          Object.keys(this.errors).forEach((item) => {
            if (this.errors[item]) {
              invalid.push(item);
            }
          });
          const string = invalid.join(', ');
          console.error('Форма не валидна - ', string);
        } catch (error) {
          console.error('Форма не валидна', error);
        }
      }
      return formIsValid;
    },
    fillRequestData() {
      this.rkname = this.currentPlacement.campaignsName || '';
      this.brief = this.currentPlacement.brief || '';
      this.description = this.currentPlacement.description || '';
      this.advertisingLabel = this.currentPlacement.advertisingLabel;
      this.marking = this.currentPlacement.marking;
      this.creative = this.creativeVariants.find((item) => item.name === this.currentPlacement.creative) || null;
      this.format = this.formatVariants.filter((item) => this.currentPlacement.placement.some((format) => format === item.value)) || null;
      this.socials = this.socialsVariants.filter((item) => this.currentPlacement.socials.some((social) => social === item.value)) || null;
      this.categories = this.getCategoriesVariants.filter((item) => this.currentPlacement.categories.some((category) => +category.id === +item.value)) || null;
      this.budget = Number(this.currentPlacement.budget) || '';
      this.deadline = this.currentPlacement.deadline.date ? this.currentPlacement.deadline.date.split(' ')[0] : null;
      this.time = this.currentPlacement.deadline.date ? this.currentPlacement.deadline.date.split(' ')[1].substr(0, 5) : null;
      this.initFiles();
    },
    initFiles() {
      if (this.currentPlacement.files && this.currentPlacement.files.length && !this.loadedFiles.length) {
        this.currentPlacement.files.forEach((item, index) => {
          this.loadedFiles.push({ name: `Загруженный файл №${index + 1}`, link: item });
        });
      }
    },
    openCurrentCompilation() {
      const rkName = this.currentPlacement.campaignsName || false;
      const compilationId = this.currentPlacement.compilation?.id;
      const link = `${getBaseUrl()}/compilation?id=${compilationId}${rkName ? `&rk=${rkName}` : ''}`;
      window.open(link);
    },
    openReadyCompilation() {
      if (this.currentPlacement.status !== 3) return;
      if (this.currentPlacement.linkToCompilation && this.currentPlacement.linkToCompilation.includes('http')) {
        window.open(this.currentPlacement.linkToCompilation);
        return;
      }
      const compilationId = this.currentPlacement.compilation?.id;
      const link = `${getBaseUrl()}/compilation?id=${compilationId}`;
      window.open(link);
    },
    openEdit() {
      this.openEditInViewMode = true;
    },
    cancelEdit() {
      this.openEditInViewMode = false;
      this.addNewRevision = false;
      this.fillRequestData();
    },
    cancelSetInProgress() {
      this.init();
      this.setInProgressModal = false;
    },
    fillSelectedGroupsData() {
      // socials
      try {
        if (!this.selectedData.length) {
          this.socials = [];
          this.budget = 0;
          this.categories = [];
          this.format = null;
          this.socialsVariants.forEach((item) => {
            item.disabled = false;
          });
          return;
        }
        this.socials = this.selectedData.reduce((res, item) => {
          const includes = res.find((social) => social.value === item.social);
          if (!includes) {
            const currentSocial = this.socialsVariants.find((variant) => variant.value === item.social);
            res.push(currentSocial);
          }
          return res;
        }, []);
        this.socialsVariants.forEach((item) => {
          const includes = this.socials.find((social) => social.value === item.value);
          if (includes) {
            item.disabled = true;
          }
        });
        // budget
        this.budget = Math.round(this.selectedData.reduce((prev, cur) => {
          const currentOffer = cur.products.find((item) => item.type === 1);
          const currentPrice = currentOffer
            ? currentOffer.tradeOffersChain?.currentTradeOffer?.resultPrice || 0
            : 0;
          return prev + currentPrice;
        }, 0));
        // categories
        this.categories = this.selectedData.reduce((res, item) => {
          item.categories.forEach((category) => {
            const categoryData = this.getCategoriesVariants.find((variant) => String(variant.value) === String(category.id));
            const includes = res.find((variant) => variant.id === `${category.id}`);
            if (!includes && categoryData) {
              res.push(categoryData);
            }
          });
          return res;
        }, []);
        this.format = [this.formatVariants[0]];
      } catch {
        console.warn('Ошибка при автозаполнении из выбранных каналов');
      }
    },
    getCommentCreator(comment) {
      const creatorName = comment.creatorUser?.name;
      const creatorEmail = comment.creatorUser?.email;
      return `(${creatorName || ''} - ${creatorEmail || ''})`;
    },
    getCommentDate(comment) {
      const date = comment.createdAt?.date || null;
      return date ? this.moment(date).format('lll') : null;
    },
    getClientsForAdminSelect() {
      this.$store.dispatch('placements/getClientsList')
        .then((data) => {
          this.adminClientsList = [...data];
        })
        .catch(() => {
          this.hideAdminClientsSelect = true;
          this.$store.dispatch('app/setSnackBar',
            {
              text: 'Не удалось получить список клиентов, которых можно прикрепить к заявке',
              error: true,
            });
        });
    },
    setSelectedAdminClient(value) {
      this.selectedAdminClientManager = null;
      if (!value) {
        this.selectedAdminClient = null;
        return;
      }
      this.selectedAdminClient = value;
      this.adminClientsManagersList = this.selectedAdminClient.employees.map((item) => ({ id: item.id, name: item.name || item.email }));
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    isManager() {
      return this.$store.getters['user/getIsManager'];
    },
    isReadyRequest() {
      return this.currentPlacement?.status === 3;
    },
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
    getAddFileText() {
      return this.files.length < 3 ? ' (не обязательно)' : ' (Максимум 3 файла)';
    },
    showAddFile() {
      if (this.viewMode) {
        return this.openEditInViewMode;
      }
      return true;
    },
    getPlacementStatus() {
      if (this.currentPlacement?.status === 2) return 'В работе';
      if (this.currentPlacement?.status === 3) return 'Открыть готовую подборку';
      return 'Новый';
    },
    getCategoriesData() {
      return this.$store.getters['groups/getCategories'];
    },
    getCategoriesVariants() {
      return this.getCategoriesData
        .map((category) => ({
          name: category.name,
          value: category.id,
        }));
    },
    getSocialVariants() {
      // FUTURE для сбера выпиливаем соц сеть инстаграм
      const isSber = this.getUserInfo?.asEmployerAgency?.id === 13 || this.getUserInfo?.asClientAgency?.id === 13;
      if (isSber) {
        return this.socialsVariants.filter((social) => social.value !== 'instagram');
      }
      return this.socialsVariants;
    },
    getPlacementComments() {
      try {
        if (this.isAdmin) {
          return this.currentPlacement.comments;
        }
        return this.currentPlacement.comments.filter((item) => !item.isOur) || [];
      } catch (error) {
        console.error('Ошибка в getPlacementComments', error);
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.create-placement__wrapper {
  margin-top: 80px;
}

.create-placement {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 2000px;
  width: 100%;
  margin: 0px auto;
}

.create-placement__title {
  font-size: 24px;
  font-weight: 600;
  color: #0e092a;
}

.create-placement__main-selected {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.create-placement__content {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  min-height: 100%;
  box-sizing: border-box;
}

.create-placement__stats-block {
  width: 100%;
  min-height: 80px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primary-blue;
  border-radius: 12px;
  margin-bottom: 30px;
}

.create-placement__buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 40px;
  @include between-elements {
    margin-right: 20px;
  }
}

.create-placement__buttons-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  gap: 10px 20px;
  @include desktop-screen {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.create-placement__form {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.create-placement__selected {
  max-width: 2080px;
  width: 100%;
  margin: 0px auto;
}

.create-placement__column {
  display: flex;
  flex-direction: column;
  padding: 40px;
  @include between-elements {
    margin-bottom: 20px;
  }
}

.create-placement__form-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include between-elements {
    margin-bottom: 12px;
  }
}

.create-placement__form-item-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  @include between-elements {
    margin-bottom: 20px;
  }
  @include desktop-screen {
    flex-wrap: nowrap;
    @include between-elements {
      margin-right: 15px;
    }
  }
}

.create-placement__form-item-title {
  font-size: 16px;
  font-weight: 500;
  color: #4f4f4f;
  &_error {
    color: $primary-red;
  }
}

.create-placement__toggles {
  display: flex;
  flex-wrap: wrap;
  @include between-elements {
    margin-right: 30px;
  }
}

.create-placement__toggles-item {
  display: flex;
  align-items: center;
  height: 40px;
  @include between-elements {
    margin-right: 20px;
  }
}

// dedline block
.create-placement__deadline {
  display: flex;
  width: 100%;
  @include between-elements {
    margin-right: 20px;
  }
}

.create-placement-result {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  max-width: 500px;
}

.create-placement__text {
  color: $primary-gray;
  &_comment {
    font-size: 14px;
    font-weight: 400;
    color: $primary-lightgray;
  }
  &_light {
    color: $primary-lightgray;
  }
}

.create-placement-result__text {
  font-size: 16px;
  color: #4F4F4F;
  font-weight: 400;
}

.create-placement-result__button {
  width: 100%;
}

.create-placement__request-links {
  display: flex;
  flex-direction: column;
  @include desktop-screen {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @include between-elements {
      margin-right: 20px;
    }
  }
}

.create-placement__request-link {
  cursor: pointer;
  color: $primary-blue;
  height: 40px;
  line-height: 40px;
  @include desktop-screen {
    height: 40px;
    line-height: 40px;
  }
}

.create-placement__new-revision-comment-title {
  font-size: 24px;
  font-weight: 600;
  color: #0e092a;
  margin-bottom: 20px;
  padding-top: 40px;
}

.create-placement__content__set-in-progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-placement__content__set-in-progress-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  @include between-elements {
    margin-right: 20px;
  }
}

.create-placement__new-revision-last-comment {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.create-placement__add-file {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  height: 30px;
  @include between-elements {
    margin-right: 10px;
  }
}

.create-placement__add-file-icon {
  min-width: 24px;
  width: 24px;
}

.create-placement__add-file-text {
  font-size: 14px;
  color: $primary-blue;
  &_desc {
    color: $primary-gray;
    font-weight: 400;
  }
  &_name {
    max-width: 400px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.create-placement__comments {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 20px;
  }
}

.create-placement__comment {
  padding: 20px;
  background:  rgba(#d8f2fa, 0.2);
  border: 1px solid  #d8f2fa;
  border-radius: 6px;
}

.create-placement__comment_title {
  margin-bottom: 5px;
}

.create-placement__new-revision-comment {
  padding: 0px 40px 40px;
  box-sizing: border-box;
  border-top: 1px solid #d8f2fa;
}

.create-placement__status-button {
  border-radius: 40px;
  border: 1px solid $primary-lightgray;
  padding: 0px 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  &_ready {
    background: $primary-blue;
    border: none;
    cursor: pointer;
    span {
      color: white;
    }
  }
}

.create-placement__status-button-text {
  font-size: 14px;
  color: $primary-gray;
  &_bold {
    font-weight: 600;
  }
}
</style>
