import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    groupsForModerate: [],
    loadParams: {
      'page-size': 50,
      page: 1,
    },
    totalGroups: null,
    totalPageCounts: null,
    sjiRequestsCount: null,
    moderateRequestsCount: null,
  },
  getters: {
    getGroupsForModerate: (state) => state.groupsForModerate,
    getTotalPageCounts: (state) => state.totalPageCounts,
    getTotalGroups: (state) => state.totalGroups,
    getCurrentPage: (state) => state.loadParams.page,
    getIsLastPage: (state) => +state.loadParams.page === +state.totalPageCounts + 1,
    getSjiRequestsCount: (state) => state.sjiRequestsCount,
    getModerateRequestsCount: (state) => state.moderateRequestsCount,
  },
  mutations: {
    setGroupsForModerate: (state, value) => { state.groupsForModerate = value; },
    setLoadPage: (state, value) => { state.loadParams.page = value; },
    setTotalPageCounts: (state, value) => { state.totalPageCounts = value; },
    setTotalGroups: (state, value) => { state.totalGroups = value; },
    removeElement: (state, index) => { state.groupsForModerate.splice(index, 1); },
    setSjiRequestsCount: (state, value) => { state.sjiRequestsCount = value; },
    setModerateRequestsCount: (state, value) => { state.moderateRequestsCount = value; },
  },
  actions: {
    // Получить список групп на модерацию
    getModerateGroups({ commit, state }, params) {
      if (params.reset) {
        commit('setGroupsForModerate', []);
        commit('setLoadPage', 1);
        commit('setTotalPageCounts', null);
      }

      return api.get('/moderate', { params: { status: params.status, sourceType: params.sourceType, ...state.loadParams } })
        .then((response) => {
          const { data } = response.data;

          if (params.reset) {
            commit('setGroupsForModerate', data);
          } else {
            commit('setGroupsForModerate', [...state.groupsForModerate, ...data]);
          }

          commit('setTotalPageCounts', response.headers['x-pagination-page-count']);
          commit('setLoadPage', state.loadParams.page + 1);
          commit('setTotalGroups', response.headers['X-Pagination-Total-Count']);

          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- getModerateGroups; Error -', error);
          return Promise.reject(error);
        });
    },

    // Подтвердить/отклонить элемент на модерации
    setModerateGroupApprove({ commit }, { id, value, rejectReason }) {
      commit('app/setAppIsLoading', true, { root: true });
      return api.patch(`/moderate/${value ? 'apply' : 'reject'}/${id}`, { rejectReason: rejectReason || null })
        .then((data) => {
          commit('app/setAppIsLoading', false, { root: true });
          return Promise.resolve(data);
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- setModerateGroupApprove --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Получить список групп на модерацию SJI
    getSjiModerateGroups({ commit, state }, params) {
      if (params.reset) {
        commit('setGroupsForModerate', []);
        commit('setLoadPage', 1);
        commit('setTotalPageCounts', null);
      }

      return api.get('/content-analysis/moderation', { params: { status: params.status, ...state.loadParams } })
        .then((response) => {
          const { data } = response.data;
          if (params.reset) {
            commit('setGroupsForModerate', data);
          } else {
            commit('setGroupsForModerate', [...state.groupsForModerate, ...data]);
          }

          commit('setTotalPageCounts', response.headers['x-pagination-page-count']);
          commit('setLoadPage', state.loadParams.page + 1);
          commit('setTotalGroups', response.headers['X-Pagination-Total-Count']);

          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- getSjiModerateGroups; Error -', error);
          return Promise.reject(error);
        });
    },

    // Добавить в топ SJI канал
    addToTopSji({ commit }, params) {
      return api.patch('/content-analysis/add-to-top/', params)
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- addToTopSji --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Удалить из топа по SJI канал
    deleteFromTop({ commit }, params) {
      return api.patch('/content-analysis/delete-from-top/', params)
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- deleteFromTop --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Подтвердить новый индекс SJI
    approveSji({ commit, dispatch }, params) {
      return api.patch(`/content-analysis/${params.approve ? 'apply' : 'reject'}`, { requestId: params.requestId })
        .then(() => {
          if (!params.approve) {
            dispatch('getRejectedRequestsCount');
          }
          return Promise.resolve();
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- approveSji --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Количество каналов на модерации индекса SJI
    getSjiRequestsCount({ commit }) {
      return api.get('/content-analysis/requests-count')
        .then(({ data: { data } }) => {
          commit('setSjiRequestsCount', data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- getSjiRequestsCount --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Количество каналов на модерации
    getModerateRequestsCount({ commit }, sourceType) {
      return api.get('/moderate/requests-count', { params: { sourceType } })
        .then(({ data: { data } }) => {
          commit('setModerateRequestsCount', data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- getModerateRequestsCount --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Взять в работу модерацию канала
    setInWorkModerateChannel({ state, commit }, requestId) {
      commit('app/setAppIsLoading', true, { root: true });
      return api.patch(`/moderate/in-work/${requestId}`)
        .then(({ data: { data } }) => {
          state.groupsForModerate.forEach((item) => {
            if (item.id === requestId) {
              item.status = data.status;
            }
          });
          commit('app/setAppIsLoading', false, { root: true });
          return Promise.resolve(data);
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- setInWorkModerateChannel --; Error -', error);
          return Promise.reject(error);
        });
    },
  },
};
