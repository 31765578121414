<template>
  <div class="group-comment">
    <span
    :class="isAdmin && 'pointer'"
    class="group-comment__text group-comment__text_light"
    @click="openEditor">
      {{ getComment }}
    </span>
    <transition name="fade">
      <div
      v-if="showEditor"
      v-click-outside="onClickOutside"
      class="group-comment__editor">
        <div class="group-comment__editor-element">
          <DataTextarea
          v-model="commentValue"
          :width="300"
          :placeholder="commentType === 'note' ? 'Укажите примечание' : $t('Enter a comment')" />
        </div>
        <DataButton
        :text="$t('save')"
        type="text"
        @handler="saveChanges" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'GroupComment',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    comment: {
      type: String,
      default: null,
    },
    disableEditor: {
      type: Boolean,
      default: false,
    },
    commentType: {
      type: String,
      default: 'comment',
    },
    note: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    showEditor: false,
    commentValue: null,
  }),
  methods: {
    openEditor() {
      if (!this.isAdmin || this.disableEditor) return;

      this.commentValue = this.commentType === 'note' ? this.note : this.comment;
      this.showEditor = true;
    },
    closeEditor() {
      this.showEditor = false;
      this.commentValue = null;
    },
    onClickOutside() {
      this.closeEditor();
    },
    saveChanges() {
      if (this.commentType === 'note') {
        this.addCommentToGroupInCompilation();
        return;
      }

      const newData = {
        comment: this.commentValue === '' ? null : this.commentValue,
      };

      this.showEditor = false;

      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData })
        .then(() => {
          this.group.comment = this.commentValue === '' ? null : this.commentValue;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
        });
    },
    addCommentToGroupInCompilation() {
      const params = {
        comment: this.commentValue === '' ? null : this.commentValue,
        positionId: this.group.positionId,
      };

      this.$store.dispatch('compilations/addCommentToGroupInCompilation', params)
        .then(() => {
          this.group.note = this.commentValue === '' ? null : this.commentValue;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
        });
    },
  },
  computed: {
    isEmptyData() {
      return this.commentType === 'note'
        ? !this.note || this.note === ''
        : this.comment === null || this.comment === '';
    },
    getComment() {
      if (this.isEmptyData) return '—';
      return this.commentType === 'note'
        ? this.note
        : this.comment;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-comment {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 180px;
  min-height: 50px;
  position: relative;
}

.group-comment__text {
  font-size: 14px;
  padding: 0px 10px;
  color: $primary-gray;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

.group-comment__editor {
  position: absolute;
  top: 0px;
  left: 30px;
  width: 300px;
  border-radius: 12px;
  padding: 20px 20px 0px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.group-comment__editor-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

</style>
