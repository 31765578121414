<template>
  <div class="post-search-header">
    <span class="post-search-header__title">
      Поиск постов по ключевым словам
    </span>
  </div>
</template>

<script>
export default {
  name: 'PostSearchHeader',
};
</script>

<style lang="scss" scoped>
.post-search-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .post-search-header__title {
    font-size: 18px;
    font-weight: 600;
  }
}
</style>
