<template>
  <div class="sji-moderation-element">
    <div
    class="sji-moderation-element__column sji-moderation-element__column_name pointer"
    style="width: 400px"
    @click="routeToCurrentGroupLink">
      <img
      class="sji-moderation-element__avatar"
      :src="group.group.avatar ? group.group.avatar : require('@/assets/svg/empty-avatar.svg')">
      <div class="sji-moderation-element__name-text">
        <span
        v-tooltip="group.group.name"
        class="sji-moderation-element__text sji-moderation-element__text_name">
          {{ group.group.name }}
        </span>
        <span
        v-if="categories"
        class="sji-moderation-element__text sji-moderation-element__text_category">
          {{ categories }}
        </span>
        <DataIcon
        v-tooltip="`Редактировать канал`"
        name="edit"
        class="sji-moderation-element__social-icon"
        :size="16"
        pointer
        @click.native.stop="openEditModal" />
      </div>
    </div>

    <!-- Подписчики/Subscribers -->
    <div
    class="sji-moderation-element__column"
    style="width: 150px">
      <span class="sji-moderation-element__text">
        {{ subscribers }}
      </span>
    </div>

    <!-- Просмотры/Views -->
    <div
    class="sji-moderation-element__column"
    style="width: 150px">
      <span class="sji-moderation-element__text">
        {{ views }}
      </span>
    </div>

    <!-- Индекс/Index -->
    <div
    class="sji-moderation-element__column sji-moderation-element__column_index"
    style="width: 200px">
      <div class="sji-moderation-element__index">
        <div class="sji-moderation-element__sji-index">
          <div class="sji-moderation-element__sji-index-point" />
          <span class="sji-moderation-element__text sji-moderation-element__text_green">{{ contentScore }}</span>
        </div>
        <div class="sji-moderation-element__sji-elements">
          <span class="sji-moderation-element__text sji-moderation-element__text_light">{{ 'Эротика: ' + contentErotic }}</span>
          <span class="sji-moderation-element__text sji-moderation-element__text_light">{{ 'Жестокость: ' + contentViolence }}</span>
          <span class="sji-moderation-element__text sji-moderation-element__text_light">{{ 'Мат: ' + contentSwearing }}</span>
        </div>
      </div>
    </div>

    <!-- Действия/Actions -->
    <div
    v-if="selectedTab === 'wait'"
    class="sji-moderation-element__column">
      <div
      class="sji-moderation-element__actions"
      style="width: 250px">
        <span
        class="sji-moderation-element__text sji-moderation-element__text_blue pointer"
        @click="approveSji('approve')">
          Одобрить
        </span>
        <span
        class="sji-moderation-element__text sji-moderation-element__text_red pointer"
        @click="approveSji('reject')">
          Отклонить
        </span>
      </div>
    </div>

    <!-- Добавить в топ/Add to top -->
    <div
    v-if="selectedTab === 'wait'"
    class="sji-moderation-element__column"
    style="width: 150px">
      <DataCheckbox
      :value="inTop"
      @input="addToTop" />
    </div>

    <!-- Статус/Status -->
    <div
    class="sji-moderation-element__column"
    style="width: 150px">
      <img
      v-if="selectedTab === 'rejected'"
      class="sji-moderation-element__status-iconr"
      src="@/assets/svg/icons/ban-icon.svg">
      <img
      v-else
      class="sji-moderation-element__status-icon pointer"
      src="@/assets/svg/icons/ok-request-icon.svg"
      @click="removeElement">
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupElement',
  props: {
    group: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
    selectedTab: {
      type: String,
      default: 'wait',
    },
  },
  data: () => ({
    subscribers: null,
    views: null,
    categories: null,
    inTop: false,
    contentScore: '—',
    contentErotic: '—',
    contentViolence: '—',
    contentSwearing: '—',
    actionIsSend: false,
  }),
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.subscribers = this.getSubscribers();
      this.views = this.getViews();
      this.categories = this.getCategories();
      this.getContentData();
    },
    getCategories() {
      const categories = this.group.group.categories.map((category) => this.$t(category.name));
      return categories.length ? categories.join(', ') : false;
    },
    getSubscribers() {
      return this.group.group.subscribersStatistic.count
        ? this.setNumberSpaces(this.group.group.subscribersStatistic.count)
        : 'Нет данных';
    },
    getViews() {
      return this.group.group.viewsStatistic.countLast24h
        ? `${this.setNumberSpaces(this.group.group.viewsStatistic.countLast24h)} / день`
        : 'Нет данных';
    },
    getContentData() {
      const content = this.group?.group.contentAnalysis;
      if (content) {
        this.contentScore = content.cleanContentScore !== null ? parseFloat(content.cleanContentScore).toFixed(1) : '—';
        this.contentErotic = content.erotic !== null ? parseFloat(content.erotic).toFixed(1) : '—';
        this.contentViolence = content.violence !== null ? parseFloat(content.violence).toFixed(1) : '—';
        this.contentSwearing = content.swearing !== null ? parseFloat(content.swearing).toFixed(1) : '—';
      }
    },
    routeToCurrentGroupLink() {
      if (!this.group.group.url.match(/^https?:\/\//i)) {
        window.open(`https://${this.group.group.url}`);
      } else {
        window.open(this.group.group.url);
      }
    },
    approveSji(type) {
      const params = { requestId: this.group.id, approve: type === 'approve' };
      this.$store.dispatch('moderate/approveSji', params)
        .then(() => {
          this.actionIsSend = true;
          this.$store.dispatch('app/setSnackBar', { text: type === 'approve' ? 'Одобрено' : 'Отклонено' });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', {
            text: 'Ошибка. Попробуйте повторить позже',
            error: true,
          });
        });
    },
    addToTop() {
      if (this.inTop) return;
      const params = {
        groupIdentity: {
          id: this.group.group.id,
          social: this.group.group.social,
        },
      };
      this.$store.dispatch('moderate/addToTopSji', params)
        .then(() => {
          this.inTop = true;
          this.$store.dispatch('app/setSnackBar', { text: 'Добавлено в топ' });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', {
            text: 'Ошибка. Попробуйте повторить позже',
            error: true,
          });
        });
    },
    removeElement() {
      if (!this.actionIsSend) {
        this.$store.dispatch('app/setSnackBar', {
          text: 'Необходимо принять или отклонить индекс SJI',
          error: true,
        });
        return;
      }
      this.$store.commit('moderate/removeElement', this.index);
    },
    openEditModal() {
      const modalParams = { name: 'GroupEditorModal', props: { group: this.group.group } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
  },
};
</script>

<style lang="scss" scoped>
.sji-moderation-element {
  display: flex;
  align-items: center;
  min-width: 100%;
  position: sticky;
  background: white;
  top: 0px;
  left: 0px;
  height: 60px;
  border-bottom: 1px solid $primary-group-border;
  position: relative;
  &:hover {
    .sji-moderation-element__social-icon {
      opacity: 1;
    }
  }
}

.sji-moderation-element__column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 40px;
  border-right: 1px solid $primary-group-border;
  &_row {
    flex-direction: row;
    @include between-elements {
      margin-right: 10px;
    }
  }
  &_name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: sticky;
    left: 0px;
    padding-left: 20px;
    background: white;
    height: 59px;
    @include tablet-screen {
      padding-left: 30px;
    }
  }
  &_index {
    align-items: flex-start;
    padding-left: 30px;
  }
}

.sji-moderation-element__text {
  font-size: 14px;
  &_name {
    font-size: 16px;
    max-width: 90%;
    color: $primary-blue;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &_category {
    font-size: 12px;
    color: $primary-lightgray;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
  &_light {
    color: $primary-lightgray;
    font-size: 12px;
    text-align: center;
    font-weight: 400;
  }
  &_red {
    color: $primary-red;
  }
  &_blue {
    color: $primary-blue;
  }
  &_green {
    font-size: 16px;
    color: $primary-green;
    font-weight: 600;
  }
}

.sji-moderation-element__index {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 20px;
  }
}

.sji-moderation-element__sji-index {
  display: flex;
  justify-content: center;
  align-items: center;
  @include between-elements {
    margin-right: 5px;
  }
}

.sji-moderation-element__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  @include between-elements {
    margin-right: 30px;
  }
}

.sji-moderation-element__sji-elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 2px;
  }
}

.sji-moderation-element__sji-index-point {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background: $primary-green;
}

.sji-moderation-element__avatar {
  display: none;
  @include tablet-screen {
    display: block;
    min-width: 40px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-right: 20px;
    box-sizing: border-box;
    border-radius: 50%;
  }
}

.sji-moderation-element__name-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  padding-right: 40px;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.sji-moderation-element__status-icon {
  width: 25px;
  height: 25px;
}

.sji-moderation-element__social-icon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 4px;
  right: 5px;
  color: $primary-lightgray;
  padding: 10px;
  box-sizing: content-box;
  transition: 0.3s;
  opacity: 0;
  &_show {
    opacity: 1;
  }
}
</style>
