<template>
  <div class="group-contact">
    <div @click="openEditor">
      <span
      :class="isAdmin && 'pointer'"
      class="group-contact__text group-contact__text_light">
        {{ mainContact }}
      </span>

      <span
      v-if="getSelectedTypeOfGroups === 'mave' && telegramContact"
      :class="isAdmin && 'pointer'"
      class="group-contact__text group-contact__text_light">
        {{ telegramContact }}
      </span>
    </div>

    <transition name="fade">
      <div
      v-if="showEditor"
      v-click-outside="onClickOutside"
      class="group-contact__editor">
        <div class="group-contact__editor-element">
          <span class="group-contact__text_light">
            {{ $t('Edit a contact') }}
          </span>
          <DataInput
          v-model="contactValue"
          style="margin-top: 5px"
          :placeholder="$t('Specify the contact')" />
        </div>
        <DataButton
        :text="$t('save')"
        type="text"
        style="margin-bottom: 10px"
        @handler="saveChanges" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'GroupContact',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    contact: {
      type: String,
      default: null,
    },
    disableEditor: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showEditor: false,
    contactValue: null,
    mainContact: null,
    telegramContact: null,
  }),
  created() {
    this.initContacts();
  },
  methods: {
    initContacts() {
      if (this.getSelectedTypeOfGroups === 'mave') {
        // eslint-disable-next-line prefer-destructuring
        this.mainContact = this.contact
          ? this.contact.replace('Email: ', '').replace('Telegram: ', '').split(' ')[0]
          : '-';

        this.telegramContact = this.contact
          ? this.contact.replace('Email: ', '').replace('Telegram: ', '').split(' ')[1]
          : '-';
      } else {
        this.mainContact = this.contact;
      }
    },
    openEditor() {
      if (!this.isAdmin || this.disableEditor) return;

      this.contactValue = this.contact;
      this.showEditor = true;
    },
    closeEditor() {
      this.showEditor = false;
      this.contactValue = null;
    },
    onClickOutside() {
      this.closeEditor();
    },
    saveChanges() {
      const newData = {
        contact: this.contactValue === '' ? null : this.contactValue,
      };

      this.showEditor = false;

      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData })
        .then(() => {
          this.group.contact = this.contactValue === '' ? null : this.contactValue;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
        });
    },
  },
  computed: {
    isEmptyData() {
      return this.contact === null || this.contact === '';
    },
    getSelectedTypeOfGroups() {
      return this.$store.getters['groups/getSelectedTypeOfGroups'];
    },
  },
};
</script>

<style lang="scss" scoped>
.group-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 180px;
  min-height: 50px;
  position: relative;
}

.group-contact__text {
  font-size: 14px;
  padding: 3px 5px;
  color: $primary-gray;
  text-align: center;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

.group-contact__editor {
  position: absolute;
  top: 0px;
  left: 30px;
  border-radius: 12px;
  padding: 20px 20px 0px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 500px;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-contact__editor-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  @include between-elements {
    margin-bottom: 5px;
  }
}

</style>
