<template>
  <div class="group-name-editor">
    <div class="group-name-editor__header-block">
      <span class="group-name-editor__text group-name-editor__text_title">Добавление канала</span>
    </div>
    <div class="group-name-editor__content">
      <div class="group-name-editor__elements">
        <!-- Название -->
        <div class="group-name-editor__element">
          <span class="group-name-editor__text group-name-editor__text_desc">{{ $t('name2') }}</span>
          <DataInput
          v-model.trim="groupName"
          :error="!groupName.length"
          class="group-name-editor__input"
          :placeholder="$t('enter_new_name')" />
        </div>
        <!-- Ссылка -->
        <div class="group-name-editor__element">
          <span class="group-name-editor__text group-name-editor__text_desc">{{ $t('link') }}</span>
          <DataInput
          v-model.trim="groupLink"
          :error="!groupLink.length"
          class="group-name-editor__input"
          :placeholder="$t('specify_the_correct_link')" />
        </div>
        <!-- Категории -->
        <div class="group-name-editor__element">
          <span class="group-name-editor__text group-name-editor__text_desc">Категории</span>
          <DataSelect
          v-model="groupCategories"
          :options="getCategoriesVariants"
          :error="!groupCategories.length"
          with-search
          placeholder="Выберите категории"
          class="group-name-editor__input"
          select-type="multi" />
        </div>
      </div>
      <div class="group-name-editor__elements">
        <!-- Подписчики -->
        <div class="group-name-editor__element">
          <span class="group-name-editor__text group-name-editor__text_desc">Подписчики</span>
          <DataInput
          v-model.trim="subscribers"
          input-type="number"
          class="group-name-editor__input"
          placeholder="Укажите количество подписчиков" />
        </div>
        <!-- Просмотры -->
        <div class="group-name-editor__element">
          <span class="group-name-editor__text group-name-editor__text_desc">Просмотры за 24ч</span>
          <DataInput
          v-model.trim="views"
          input-type="number"
          class="group-name-editor__input"
          placeholder="Укажите количество просмотров" />
        </div>
        <!-- Блоггер -->
        <div class="group-name-editor__element group-name-editor__element_row">
          <DataCheckbox
          v-model="isBlogger" />
          <span class="group-name-editor__text group-name-editor__text_desc">
            Блоггер
          </span>
        </div>
        <!-- TOP  -->
        <div class="group-name-editor__element group-name-editor__element_row">
          <DataCheckbox
          v-model="addInTop" />
          <span class="group-name-editor__text group-name-editor__text_desc">
            Добавить в топ
          </span>
        </div>
        <!-- Эксклюзив  -->
        <div class="group-name-editor__element group-name-editor__element_row">
          <DataCheckbox
          v-model="exclusive" />
          <span class="group-name-editor__text group-name-editor__text_desc">
            Эксклюзив
          </span>
        </div>
      </div>
    </div>
    <div class="group-name-editor__element">
      <span class="group-name-editor__text group-name-editor__text_desc">
        {{ $t('description') }}
      </span>
      <DataTextarea
      v-model.trim="groupDesc"
      :height="150"
      class="group-name-editor__input"
      :placeholder="$t('Enter a description')" />
    </div>
    <div class="group-name-editor__button-block">
      <DataButton
      :text="$t('save')"
      :disabled="disabledSaveButton"
      @handler="saveGroup" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditGroupModal',
  props: {
    editedGroup: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    groupName: null,
    groupLink: null,
    groupDesc: null,
    groupCategories: [],
    showAddCategory: false,
    newCategory: null,
    isBlogger: false,
    addInTop: false,
    exclusive: false,
    views: null,
    subscribers: null,
  }),
  created() {
    this.groupName = this.editedGroup?.name || '';
    this.groupLink = this.editedGroup?.url || '';
    this.groupDesc = this.editedGroup?.description || '';
    this.groupCategories = this.editedGroup?.categories?.map((category) => ({ name: category.name, value: category.id })) || [];
    this.isBlogger = this.editedGroup?.isBlogger || false;
    this.exclusive = this.editedGroup?.isExclusive || false;
    this.addInTop = this.editedGroup?.contentAnalysis?.isTopGroup || false;
    this.subscribers = this.editedGroup?.subscribersStatistic?.count || 0;
    this.views = this.editedGroup?.viewsStatistic?.countLast24h || 0;
  },
  methods: {
    saveGroup() {
      const newData = {
        name: this.groupName,
        url: this.groupLink,
        description: this.groupDesc,
        categories: this.groupCategories.map((category) => ({ id: category.value, name: category.name })),
        categoriesId: this.groupCategories.map((category) => category.value),
        isBlogger: this.isBlogger,
        isExclusive: this.exclusive,
        subscribersStatistic: { count: Number(this.subscribers) || 0 },
        viewsStatistic: { countLast24h: Number(this.views) || 0 },
      };

      const saveGroupData = this.$store.dispatch('groups/groupUpdate', { id: this.editedGroup.id, social: this.editedGroup.social, newData });

      const topPayload = {
        groupIdentity: {
          id: this.editedGroup.id,
          social: this.editedGroup.social,
        },
      };
      if (this.addInTop) {
        this.$store.dispatch('moderate/addToTopSji', topPayload)
          .then((data) => {
            this.editedGroup.contentAnalysis = data;
          })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar', {
              text: 'Ошибка при добавлении канала в топ',
              error: true,
            });
          });
      } else {
        this.$store.dispatch('moderate/deleteFromTop', topPayload)
          .then((data) => {
            this.editedGroup.contentAnalysis = data;
          })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar', {
              text: 'Ошибка при удалении канала из топа',
              error: true,
            });
          });
      }

      Promise.all([saveGroupData])
        .then(() => {
          this.$emit('close');
          this.$store.dispatch('app/setSnackBar', { text: 'Данные успешно сохранены' });
        })
        .catch(() => {
          this.$emit('close');
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('An error occurred while updating the data'),
              error: true,
            });
        });
    },
  },
  computed: {
    disabledSaveButton() {
      return !this.groupName || !this.groupLink || !this.groupCategories.length;
    },
    getCategoriesData() {
      return this.$store.getters['groups/getCategories'];
    },
    getCategoriesVariants() {
      return this.getCategoriesData
        .map((category) => ({
          name: category.name,
          value: category.id,
        }));
    },
  },
};
</script>

<style lang="scss" scoped>
.group-name-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 700px;
}

.group-name-editor__header-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 30px;
}

.group-name-editor__text {
  color: $primary-black;
  font-size: 16px;
  &_title {
    font-size: 22px;
  }
  &_desc {
    font-size: 14px;
  }
  &_add {
    font-size: 14px;
    color: $primary-blue;
  }
  &_light {
    color: rgb(99, 99, 99);
    font-size: 14px;
  }
}

.group-name-editor__content {
  display: flex;
  margin-bottom: 20px;
  @include between-elements {
    margin-right: 40px;
  }
}

.group-name-editor__elements {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include between-elements {
    margin-bottom: 15px;
  }
}

.group-name-editor__element {
  display: flex;
  flex-direction: column;
  position: relative;
  &_row {
    flex-direction: row;
    @include between-elements {
      margin-right: 10px;
    }
  }
}

.group-name-editor__button-block {
  padding-top: 40px;
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}

.group-name-editor__input {
  width: 100%;
  margin-top: 5px;
}

.group-name-editor__button {
  cursor: pointer;
  padding: 10px 40px;
  font-size: 14px;
  outline: none;
  border: none;
  color: $primary-white;
  background: $primary-blue;
  border-radius: 40px;
  &_outline {
    background: $primary-white;
    color: $primary-black;
    border: 1px solid $primary-lightgray;
  }
  &_new {
    width: 180px;
    padding: 5px 0px;
  }
  &_disabled {
    pointer-events: none;
    background: $primary-lightgray;
  }
}

</style>
