<template>
  <div class="clients-statistic">
    <!-- Фильтры -->
    <ClientStatisticFilters
    :filters="filters"
    @setBaseFilters="setBaseFilters"
    @searchWithFilters="searchWithFilters" />

    <!-- Основной список -->
    <ClientsStatisticList
    :key="isResetParams"
    :list="list"
    :is-loading="isLoading"
    :filters="filters"
    :init-loading="initLoading"
    @getStatisticData="getStatisticData" />
  </div>
</template>

<script>
import { getStatisticList } from './api';
import ClientStatisticFilters from './components/ClientsStatisticFilters.vue';
import ClientsStatisticList from './components/ClientsStatisticList.vue';

export default {
  name: 'ClientsStatistic',
  components: { ClientStatisticFilters, ClientsStatisticList },
  data: () => ({
    isLoading: false,
    isError: false,
    pageSize: 100,
    page: 1,
    totalElements: 0,
    list: [],
    isResetParams: true,
    filters: {
      agencyName: null,
      startDate: null,
      endDate: null,
      withRequestPlacement: null,
      withCampaign: null,
    },
    initLoading: true,
  }),
  created() {
    this.setBaseFilters();
    this.getStatisticData();
  },
  methods: {
    getStatisticList,
    async getStatisticData() {
      if (this.isLoading || this.allIsLoaded) return;
      this.isLoading = true;
      const payload = { 'page-size': 100, page: this.isResetParams ? 1 : this.page, ...this.filters };
      await getStatisticList(payload)
        .then((result) => {
          this.totalElements = Number(result.headers['x-pagination-total-count']);
          this.list = [...this.list, ...result.data.data];
          this.isResetParams = false;
          this.isLoading = false;
          this.isError = false;
          this.page += 1;
          this.initLoading = false;
        })
        .catch((error) => {
          this.$store.dispatch('app/createApiErrorMessage', { error, action: 'getStatisticList' });
          this.isLoading = false;
          this.isError = true;
          console.warn(error);
        });
    },
    searchWithFilters() {
      if (this.isLoading) return;
      this.initLoading = true;
      this.isResetParams = true;
      this.list = [];
      this.page = 0;
      this.totalElements = 0;
      this.getStatisticData();
    },
    setBaseFilters() {
      this.filters.agencyName = null;
      this.filters.startDate = this.moment().startOf('month').format('YYYY-MM-DD');
      this.filters.endDate = this.moment().format('YYYY-MM-DD');
      this.filters.withCampaign = null;
      this.filters.withRequestPlacement = null;
    },
  },
  computed: {
    allIsLoaded() {
      return this.list.length > 0 && (this.list.length === this.totalElements);
    },
  },

};
</script>

<style lang="scss" scoped>
.clients-statistic {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow: auto;
  background: white;
  border-radius: 12px;
  margin: 20px;
  justify-content: flex-start;
}
</style>
