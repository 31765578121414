<template>
  <div>
    <div class="login-footer">
      <span
      href="https://telejet.socialjet.pro"
      class="login-footer__text">
        Служба поддержки. <a
        href="https://t.me/socialjet_bot"
        target="_blank"
        class="login-footer__text login-footer__text_link">Связаться</a>
      </span>
      <span
      href="https://telejet.socialjet.pro"
      class="login-footer__text login-footer__text_small">
        Перейти в <a
        href="https://telejet.socialjet.pro"
        target="_blank"
        class="login-footer__text login-footer__text_link login-footer__text_small">Telejet</a>
      </span>
    </div>
    <DataModal
    :show-modal="showRulesModal"
    @close="showRulesModal = false">
      <template #modal-content>
        <RulesModal />
      </template>
    </DataModal>
  </div>
</template>

<script>
import RulesModal from './RulesModal.vue';

export default {
  name: 'LoginFooter',
  components: { RulesModal },
  data: () => ({
    showRulesModal: false,
  }),
};
</script>

<style lang="scss" scoped>
.login-footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 20px;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.login-footer__text {
  font-size: 14px;
  text-decoration: none;
  &_link {
    color: $primary-blue;
  }
  &_small {
    font-size: 12px;
  }
}
</style>
