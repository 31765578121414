<template>
  <div class="admin-buttons">
    <DataBurgerButton
    v-click-outside="closeMenu"
    :is-open="isOpenMenu"
    click-outside
    @handler="toggleMenu" />

    <transition name="fade">
      <div
      v-if="isOpenMenu"
      class="admin-buttons__menu">
        <!-- Export button -->
        <span
        class="admin-buttons__text"
        @click="openExportModal">
          {{ $t('export') }}
        </span>
        <!-- Import button -->
        <span
        v-if="isAdmin"
        class="admin-buttons__text"
        @click="openImportModal">
          {{ $t('import') }}
        </span>
        <!-- Add group button -->
        <span
        v-if="isAdmin"
        class="admin-buttons__text"
        @click="openAddGroupModal">
          {{ $t('add_a_channel') }}
        </span>
      </div>
    </transition>
    <div class="admin-buttons__modals">
      <!-- Import loader -->
      <transition name="bounce">
        <ImportLoader
        v-if="showImportLoader"
        @closeLoader="showImportLoader = false" />
      </transition>
      <!-- Add group modal -->
      <DataModal
      :show-modal="showAddGroupModal"
      @close="showAddGroupModal = false">
        <template #modal-content>
          <AddGroup
          @close="showAddGroupModal = false" />
        </template>
      </DataModal>
      <!-- Import modal -->
      <DataModal
      :show-modal="showImportModal"
      @close="closeImportModal">
        <template #modal-content>
          <ImportGroups
          @closeWithSend="closeWithSend"
          @close="showImportModal = false" />
        </template>
      </DataModal>
    </div>
  </div>
</template>

<script>
import DataBurgerButton from '@/components/UI/DataBurgerButton.vue';
import AddGroup from './AddGroup.vue';
import ImportGroups from './ImportGroups.vue';
import ImportLoader from './ImportLoader.vue';

export default {
  name: 'AdminButtons',
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isEmployerAgency: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DataBurgerButton, AddGroup, ImportGroups, ImportLoader,
  },
  data: () => ({
    showAddGroupModal: false,
    showImportModal: false,
    showImportLoader: false,
    isOpenMenu: false,
  }),
  methods: {
    closeMenu($event) {
      const isMenu = $event?.target?.className.includes('admin-buttons');
      if (isMenu) return;
      this.isOpenMenu = false;
    },
    toggleMenu(value) {
      this.isOpenMenu = value;
    },
    async openExportModal() {
      const modalParams = { name: 'ExportGroupsModal' };
      await this.$store.commit('app/setCurrentModal', modalParams);
    },
    openImportModal() {
      this.showImportModal = true;
      this.showImportLoader = false;
    },
    openAddGroupModal() {
      this.showAddGroupModal = true;
    },
    closeImportModal() {
      this.showImportModal = false;
    },
    closeWithSend() {
      this.showImportModal = false;
      setTimeout(() => {
        this.showImportLoader = true;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.admin-buttons__menu {
  position: absolute;
  top: 35px;
  min-width: 170px;
  right: -10px;
  z-index: 5;
  background: $primary-white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 3px rgb(34 60 80 / 10%);
}

.admin-buttons__text {
  color: $primary-black;
  font-size: 13px;
  padding: 7px 25px;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background: $primary-blue;
    color: $primary-white;
  }
}

.admin-buttons__menu-button {
  cursor: pointer;
  margin-bottom: 3px;
}

.admin-buttons__menu-line {
  position: relative;
  width: 20px;
  height: 2px;
  border-radius: 3px;
  background-color: $primary-gray;
  margin-top: 4px;
  transition: all 0.1s ease-in-out;
  &_top_active {
    transform: rotate(-45deg);
    margin-top: 12px;
  }
  &_middle_active {
    transform: rotate(45deg);
    margin-top: -2px;
  }
  &_bottom_active {
    opacity: 0;
    transform: rotate(45deg);
  }
}

</style>
