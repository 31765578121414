<template>
  <div class="data-pagination">
    <div class="data-pagination__main">
      <!-- Пред -->
      <div
      class="data-pagination__button data-pagination__button_arrow"
      :disabled="currentPage === 1"
      @click="setCurrentPage(currentPage - 1)">
        <DataIcon
        name="chevron_left"
        :size="20" />
      </div>

      <!-- PAGES -->
      <div
      v-for="page in visiblePages"
      :key="page"
      class="data-pagination__button"
      :class="{ 'data-pagination__button_active': currentPage === page }"
      :disabled="currentPage === 1"
      @click="setCurrentPage(page)">
        {{ page }}
      </div>

      <!-- След -->
      <div
      class="data-pagination__button data-pagination__button_arrow"
      :disabled="currentPage === pages.length"
      @click="setCurrentPage(currentPage + 1)">
        <DataIcon
        name="chevron_right"
        :size="20" />
      </div>
    </div>
    <span
    v-if="showTotal && totalPagesText"
    class="data-pagination__total">{{ totalPagesText }}</span>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    // Общее количество страниц
    totalItems: {
      type: Number,
      required: true,
    },
    // Количество элементов на странице
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    // Конкретная страница
    currentPage: {
      type: [Number, String],
      default: 1,
    },
    // Показывать подсказку с количеством страниц
    showTotal: {
      type: Boolean,
      default: false,
    },
    // Максимальное количество отображемых страни
    maxVisiblePages: {
      type: Number,
      default: 10,
    },
  },
  methods: {
    setCurrentPage(page) {
      let nextPage = page;
      if (nextPage <= 0) nextPage = 1;
      if (nextPage === this.currentPage) return;

      this.$emit('setCurrentPage', nextPage);
    },
  },
  computed: {
    pages() {
      const pagesCount = Math.ceil(this.totalItems / this.itemsPerPage);
      const pagesArray = [];

      for (let i = 1; i <= pagesCount; i++) {
        pagesArray.push(i);
      }

      return pagesArray;
    },
    visiblePages() {
      const halfVisiblePages = Math.floor(this.maxVisiblePages / 2);
      const startPage = this.currentPage > halfVisiblePages
        ? Math.max(this.currentPage - halfVisiblePages, 1)
        : 1;
      const endPage = startPage + this.maxVisiblePages - 1 > this.pages.length
        ? this.pages.length
        : startPage + this.maxVisiblePages - 1;

      return this.pages.slice(startPage - 1, endPage);
    },
    totalPagesText() {
      return this.totalItems ? `Всего страниц: ${this.totalItems}` : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.data-pagination {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .data-pagination__main {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .data-pagination__button {
    width: 60px;
    height: 30px;
    background: white;
    border: 1px solid rgba($primary-blue, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: $primary-gray;
    cursor: pointer;
    transition: 0.2s;
    font-size: 14px;
    margin: 0px 5px;
    width: 40px;
    height: 40px;
    &:hover {
      color: white;
      background: rgba($primary-blue, 0.8);
    }
    &_active {
      color: white;
      background: rgba($primary-blue, 0.8);
      &:hover {
        background: rgba($primary-blue, 1);
      }
    }
    &_arrow {
      margin: 0px 20px;
      width: 50px;
      color: rgb(143, 143, 143);
      font-weight: 400;
      &:hover {
        background: rgb(243, 243, 243);
        color: rgb(143, 143, 143);
      }
    }
  }

  .data-pagination__total {
    color: #A7A7A7;
    font-size: 13px;
    padding-top: 20px;
    font-weight: 400;
  }
}
</style>
