<template>
  <div class="search-posts-list">
    <!-- Посты -->
    <div
    v-if="getSearchPosts.length"
    class="search-posts-list__posts">
      <SearchPost
      v-for="post in getSearchPosts"
      :key="post.post_id + post.url"
      :post="post" />
      <transition name="fade">
        <div
        v-if="scrollPosition > 700"
        class="search-posts-list__up-button"
        @click="goToTop">
          <DataIcon
          name="expand_less"
          :size="50"
          class="search-posts-list__up-button-icon" />
        </div>
      </transition>

      <DataPagination
      v-if="getTotalCounts > 5"
      :total-items="getTotalCounts"
      :items-per-page="5"
      :current-page="getCurrentPage"
      show-total
      class="search-posts-list__pagination"
      @setCurrentPage="setCurrentPage" />
    </div>

    <!-- Пустой список -->
    <EmptyList
    v-else
    :is-loading="isLoading" />
  </div>
</template>

<script>
import SearchPost from './SearchPost.vue';
import EmptyList from './EmptyList.vue';

export default {
  name: 'SearchPosts',
  components: { SearchPost, EmptyList },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    scrollPosition: 0,
  }),
  created() {
    window.addEventListener('scroll', this.checkScrollPosition);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkScrollPosition);
  },
  methods: {
    checkScrollPosition() {
      this.scrollPosition = window.scrollY;
    },
    goToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    setCurrentPage(page) {
      this.$store.commit('searchPosts/setLoadPage', page);
      this.$emit('goSearch', { reset: false });
    },
  },
  computed: {
    getSearchPosts() {
      return this.$store.getters['searchPosts/getSearchPosts'];
    },
    getTotalCounts() {
      return this.$store.getters['searchPosts/getTotalCounts'];
    },
    getCurrentPage() {
      return Number(this.$store.getters['searchPosts/getCurrentPage']);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-posts-list {
  width: 100%;
  margin-right: 10px;

  .search-posts-list__posts {
    @include between-elements {
      margin-bottom: 20px;
    }
  }

  .search-posts-list__empty {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 100px;
  }

  .search-posts-list__empty-text {
    color: $primary-gray;
    font-size: 14px;
  }

  .search-posts-list__up-button {
    width: 100px;
    height: 100px;
    background: $primary-blue;
    position: fixed;
    bottom: 100px;
    left: 220px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      transform: scale(105%);
      background: rgba($primary-blue, 0.8);
    }
  }

  .search-posts-list__up-button-icon {
    color: white;
  }

  .search-posts-list__pagination {
    padding: 40px 0px 20px;
  }
}
</style>
