<template>
  <div class="import-modal">
    <div class="import-modal__header-block">
      <span class="import-modal__text import-modal__text_title">{{ $t('data_import') }}</span>
    </div>
    <div class="import-modal__container">
      <input
      ref="fileinput"
      :style="{'display': 'none'}"
      type="file"
      @change="send">
      <div class="import-modal__rules">
        <span
        v-for="(rule, index) in rules"
        :key="rule"
        class="import-modal__text import-modal__text_small">
          {{ `${index + 1}. ${$t(rule)}` }}
        </span>
      </div>
      <div class="import-modal__template">
        <span class="import-modal__text import-modal__text_small">
          {{ $t('you_need_to_upload_data_using_a_template') }}
        </span>
        <DataButton
        :text="$t('open_the_import_template')"
        type="outlined"
        :width="250"
        :height="30"
        @handler="openTemplate" />
      </div>
      <transition
      name="fade"
      mode="out-in">
        <div
        v-if="!showGoogleInput"
        class="import__buttons">
          <DataButton
          :text="$t('File from the computer')"
          :width="200"
          :height="35"
          @handler="showFileInput" />
          <span class="import-modal__text import-modal__text_light">
            {{ $t('or') }}
          </span>
          <DataButton
          text="Google Docs"
          :height="35"
          @handler="openGoogleInput" />
        </div>
        <div
        v-if="showGoogleInput"
        class="import__buttons">
          <input
          ref="googleLinkInputRef"
          v-model.trim="googleLinkInput"
          :placeholder="$t('Specify the link')"
          class="import-modal__input">
          <DataButton
          :text="$t('send')"
          :disabled="!googleLinkInput || isLoading"
          :height="35"
          @handler="sendGoogle" />
          <span
          class="import-modal__text import-modal__text_light pointer"
          @click="showGoogleInput = false">
            {{ $t('Back') }}
          </span>
          <span
          v-if="errorLinkText"
          class="import-modal__text import-modal__text_link-error">
            {{ errorLinkText }}
          </span>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImportGroups',
  data: () => ({
    showGoogleInput: false,
    googleLinkInput: null,
    errorLinkText: null,
    rules: [
      'in_the_import_file_the_column_names_must_100%_match_the_template_ones',
      'required_columns_social_url_name',
      'the_columns_order_does_not_matter',
      'If the channel has several categories, specify them through; (Science; Technology; Medicine)',
    ],
  }),
  methods: {
    showFileInput() {
      this.$refs.fileinput.click();
    },
    send() {
      const formData = new FormData();
      formData.append('file', this.$refs.fileinput.files[0]);
      formData.append('importer', 'excel');
      this.sendFile(formData);
    },
    sendGoogle() {
      if (!this.googleLinkInput) {
        this.errorLinkText = this.$t('Provide a link to Google Docs');
        return;
      }

      // eslint-disable-next-line
      const reg = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
      if (!reg.test(this.googleLinkInput)) {
        this.errorLinkText = this.$t('Specify the Google Docs link in the correct format');
        return;
      }

      this.errorLinkText = null;
      const formData = new FormData();
      formData.append('source', this.googleLinkInput);
      formData.append('importer', 'google-sheets');
      this.sendFile(formData);
    },
    openTemplate() {
      window.open('https://docs.google.com/spreadsheets/d/1fcb2iCtErJ7dFpK02hwAP8r2-usC4M2Jgic663Yjn30/edit?usp=sharing');
    },
    openGoogleInput() {
      // NEED TRANSLATE
      this.showGoogleInput = true;
      this.errorLinkText = 'Обратите внимание - себестоимость НЕ может быть выше прайса!';
      this.$nextTick(() => {
        this.$refs.googleLinkInputRef.focus();
      });
    },
    sendFile(file) {
      if (this.isLoading) return;

      this.$store.commit('app/setAppIsLoading', true);

      this.$store.dispatch('imports/importGroups', file)
        .then(() => {
          this.$store.commit('app/setAppIsLoading', false);
          this.$emit('closeWithSend');
        })
        .catch((error) => {
          this.$store.commit('app/setAppIsLoading', false);
          const errors = error?.response?.data?.errors?.source;
          const isGoogleLinkError = errors?.find((err) => err === 'Неверная ссылка');
          const isAccessError = errors?.find((err) => err === 'Запрещен доступ к Google Таблицам');

          if (isGoogleLinkError) {
            this.errorLinkText = this.$t('Specify the Google Docs link in the correct format');
            return;
          }
          if (isAccessError) {
            this.errorLinkText = 'Документ закрыт для просмотра. Проверьте права доступа';
            return;
          }

          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('an_error_occurred_while_uploading_the_file_reload_the_page_and_try_again'),
              error: true,
            });
          this.$emit('close');
        });
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters['app/getAppIsLoading'];
    },
  },
};
</script>

<style lang="scss" scoped>
.import-modal {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.import-modal__header-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
}

.import-modal__container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 20px;
}

.import-modal__template {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 30px;
  margin: 30px 0px;
  min-height: 60px;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 10px;
  @include between-elements {
    margin-right: 30px;
  }
}
.import-modal__rules {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 10px;
  }
}
.import-modal__text {
  color: $primary-black;
  font-size: 16px;
  &_small {
    font-size: 14px;
  }
  &_light {
    font-size: 14px;
    color: $primary-lightgray;
  }
  &_title {
    font-size: 22px;
    margin-bottom: 10px;
  }
  &_link-error {
    position: absolute;
    color: $primary-red;
    font-size: 11px;
    top: 40px;
    left: 5px;
  }
}

.import-modal__input {
  outline: none;
  border: 1px solid $border-color-primary;
  width: 320px;
  border-radius: 12px;
  padding: 8px 20px;
  margin-right: 5px;
  background: $primary-white;
}

.import__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  @include between-elements {
    margin-right: 10px;
  }
}
</style>
