<template>
  <div
  class="data-checkbox"
  :class="[
    getChecked && 'data-checkbox_checked',
    disabled && 'disabled',
    !nochange && 'pointer',
    !nochange && 'hover'
  ]"
  :style="{
    'width': size + 'px',
    'height': size + 'px',
  }"
  @click="toggleCheck">
    <transition>
      <DataIcon
      :name="getIconName ? getIconName : ''"
      :size="size - 2"
      :class="getChecked && 'data-checkbox__icon_show'"
      class="data-checkbox__icon" />
    </transition>
  </div>
</template>

<script>

export default {
  name: 'DataCheckbox',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Boolean, String, Number, Array, Object],
      default: false,
    },
    nochange: {
      type: Boolean,
      default: false,
    },
    withNull: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 18,
    },
  },
  methods: {
    toggleCheck() {
      if (this.nochange || this.disabled) return; // чекбокс без возможности клика
      if (this.withNull && this.value === null) {
        this.$emit('input', true);
        return;
      }
      if (this.value) {
        this.$emit('input', false);
        return;
      }
      this.$emit('input', this.withNull ? null : true);
    },
  },
  computed: {
    getIconName() {
      if (this.withNull && this.value === null) return false;
      if (this.withNull) {
        return this.value === true ? 'done' : 'close';
      }
      return 'done';
    },
    getChecked() {
      if (this.withNull) {
        return this.value === true || this.value === false;
      }
      return this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.data-checkbox {
  border: 1px solid $primary-blue;
  border-radius: 5px;
  transition: 0.2s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background: white;
  flex-shrink: 0;
  &_checked {
    transition: 0.2s;
    background: $primary-blue;
  }
}

.data-checkbox__icon {
  color: $primary-white;
  &_show {
    animation: enter 0.2s forwards;
  }
}

.hover {
  &:hover {
    box-shadow: 0px 0px 1px 2px rgba(rgb(26, 144, 199), 0.2);
  }
}

.disabled {
  border: 1px solid rgb(209, 209, 209);
  &:hover {
    box-shadow: 0px 0px 1px 2px rgba(rgb(192, 192, 192), 0.2);
  }
  background: rgb(209, 209, 209);
}

@keyframes enter {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
