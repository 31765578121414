<template>
  <div class="mave-analytics">
    <MavePlatforms :group="group" />
    <MaveDevices :group="group" />
    <MaveGeo :group="group" />
  </div>
</template>

<script>
import MaveDevices from './components/MaveDevices.vue';
import MavePlatforms from './components/MavePlatforms.vue';
import MaveGeo from './components/MaveGeo.vue';

export default {
  name: 'MaveAnalytics',
  components: { MaveDevices, MavePlatforms, MaveGeo },
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>

.mave-analytics {
  margin: 40px 0px;
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr;
  @include max-screen {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
