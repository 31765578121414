<template>
  <div class="request-element">
    <!-- Название -->
    <div class="request-element__main-info">
      <div class="request-element__name-block">
        <!-- Название запроса -->
        <div class="request-element__name">
          <img src="@/assets/svg/compilation/FileText.png">
          <span
          ref="rkname"
          v-tooltip="rkName"
          class="request-element__text request-element__text_name pointer"
          @click="openBrief(false)">
            {{ rkName }}
          </span>
        </div>
        <div class="request-element__name-info">
          <!-- Клиент, автор -->
          <div class="request-element__company">
            <span
            v-if="isAdmin || isAgency"
            class="request-element__text request-element__text_company">
              {{ getCompanyName }}
            </span>
            <span class="request-element__text request-element__text_company">
              {{ isAdmin || isAgency ? `(${rkCreator})` : rkCreator }}
            </span>
          </div>
          <!-- Дата создания -->
          <span class="request-element__text request-element__text_date">
            {{ rkDate }}
          </span>
        </div>
      </div>

      <!-- Статусы -->
      <div class="request-element__status-block">
        <!-- Обычный статус -->
        <div
        class="request-element__status-wrapper"
        :class="{
          'request-element__status-wrapper_ready' : request.requestPlacement.status === 3,
          'request-element__status-wrapper_new' : request.requestPlacement.status === 1,
        }">
          {{ getStatus }}
        </div>
        <!-- Админ Статус -->
        <div
        v-if="isAdmin"
        class="request-element__admin-status">
          <span
          class="request-element__text request-element__text_status"
          @click="openAddStatus = true">
            {{ selectedOurStatus ? selectedOurStatus : 'Добавить статус' }}
          </span>
        </div>
      </div>

      <transition name="fade">
        <AddRequestStatus
        v-if="openAddStatus"
        v-click-outside="() => openAddStatus = false"
        class="request-element__add-status"
        :class="lastElement && 'request-element__add-status_last'"
        :request="request"
        :status-options="statusOptions"
        @setStatus="(status) => selectedOurStatus = status.name"
        @setOurComment="(comment) => newOurComment = comment"
        @close="openAddStatus = false" />
      </transition>
    </div>

    <div class="request-element__content">
      <!-- Менеджер -->
      <RequestManager
      :request="request" />

      <!-- Показатели -->
      <div
      class="request-element__data-column">
        <!-- 1 -->
        <div class="request-element__data-item">
          <div
          v-tooltip="request.groupsCount > 0 ? 'Нажмите, чтобы открыть подборку' : ''"
          class="request-element__data-element request-element__data-element_fix"
          :class="request.groupsCount > 0 ? 'pointer' : ''"
          @click="openCurrentCompilation">
            <div
            v-if="socials"
            class="request-element__socials">
              <img
              v-for="(social, index) in socials"
              :key="social"
              class="request-element__social"
              :style="`margin-left: -${index + 7}px`"
              :src="require(`@/assets/svg/groups-icons/${social}-icon.svg`)">
            </div>
            <span
            v-if="request.groupsCount"
            class="request-element__text">
              {{ request.groupsCount }}
            </span>
            <span class="request-element__text request-element__text_light">
              {{ request.groupsCount ? groupsCountText : 'Без каналов' }}
            </span>
          </div>
          <div class="request-element__data-line" />
          <div class="request-element__data-element">
            <img src="@/assets/svg/compilation/UsersThree.png">
            <span class="request-element__text request-element__text_light">
              {{ request.totalSubscribers ? 'Подписчиков' : 'Подписчики' }}
            </span>
            <span
            class="request-element__text">
              {{ request.totalSubscribers || '—' }}
            </span>
          </div>
        </div>
        <!-- 2 -->
        <div class="request-element__data-item">
          <div class="request-element__data-element request-element__data-element_fix">
            <img src="@/assets/svg/compilation/Eye.png">
            <span class="request-element__text request-element__text_light">
              {{ request.totalViews ? 'показов' : 'Просмотры' }}
            </span>
            <span
            class="request-element__text">
              {{ request.totalViews || '—' }}
            </span>
          </div>
          <div class="request-element__data-line" />
          <div class="request-element__data-element">
            <img src="@/assets/svg/compilation/Pulse.png">
            <span class="request-element__text request-element__text_light">
              {{ budget ? 'Бюджет' : 'Бюджет не указан' }}
            </span>
            <span
            v-if="budget"
            class="request-element__text">
              {{ budget }}
            </span>
          </div>
        </div>
      </div>

      <!-- Подборка -->
      <div class="request-element__request-column">
        <div
        v-if="isAdmin || isAgency"
        class="request-element__report-block">
          <div class="request-element__report-container">
            <span
            v-if="isReadyRequest && !showEditReportLinkInput"
            class="request-element__text request-element__text_blue pointer"
            @click="openDocLink">
              Открыть
            </span>
            <span
            v-if="!isReadyRequest && !showEditReportLinkInput"
            class="request-element__text">
              —
            </span>
            <input
            v-if="showEditReportLinkInput"
            v-model.trim="newReportLink"
            v-click-outside="() => showEditReportLinkInput = false"
            class="request-element__report-input"
            placeholder="Добавьте ссылку">
            <img
            v-show="showEditReportLinkInput"
            class="request-element__report-icon"
            src="@/assets/svg/icons/ok-request-icon.svg"
            @click="submitReportLink">
            <img
            v-show="!showEditReportLinkInput && isRequestFromClientToAgency && request?.requestPlacement?.status !== 1"
            class="request-element__report-icon"
            src="@/assets/svg/icons/edit-request-icon.svg"
            @click="openReportInput">
          </div>
          <span
          v-if="isAdmin && request.requestPlacement.status === 3"
          class="request-element__text request-element__text_rework pointer"
          @click="openBrief(true)">
            Доработать
          </span>
        </div>

        <div v-else>
          <span
          v-if="isReadyRequest"
          class="request-element__text request-element__text_name pointer"
          @click="openDocLink">
            Открыть
          </span>
          <img
          v-else
          v-tooltip="'Ссылка будет доступна позднее'"
          class="pointer"
          src="@/assets/svg/icons/SpinnerGap.svg">
        </div>
      </div>

      <!-- АдминКоммент -->
      <div
      v-if="isAdmin"
      class="request-element__manager"
      style="width: 200px">
        <span class="request-element__text request-element__text_comment">
          {{ getOurComment }}
        </span>
      </div>

      <!-- Меню -->
      <div
      v-if="isAdmin && !request.requestPlacement.isArchived"
      class="request-element__menu-container">
        <img
        src="@/assets/svg/compilation/DotsThree.png"
        class="request-element__menu-icon"
        @click="showMenu = true">
        <div
        v-if="showMenu"
        v-click-outside="() => showMenu = false"
        class="request-element__menu"
        :class="lastElement && 'request-element__menu_last'">
          <span
          v-if="isAdmin && request.requestPlacement.status === 1"
          class="request-element__menu-element"
          @click="openSetInProgressModal">Взять в работу</span>
          <span
          class="request-element__menu-element"
          @click="deleteRequest">Удалить запрос</span>
        </div>
      </div>
    </div>

    <DataModal
    :show-modal="showSetInProgressModal"
    @close="showSetInProgressModal = false">
      <template #modal-content>
        <div class="request-element__set-in-progress">
          <span class="request-element__text request-element__text_title">Вы точно хотите взять запрос в работу?</span>
          <div class="request-element__checkbox">
            <DataCheckbox
            v-model="isNeedImplementation" />
            <span class="request-element__text">
              Отправить в реализацию
            </span>
          </div>
          <DataButton
          text="Взять в работу"
          :width="220"
          @handler="setInProgress" />
        </div>
      </template>
    </DataModal>
  </div>
</template>

<script>
import getBaseUrl from '@/assets/js/helpers';
import AddRequestStatus from './components/AddRequestStatus.vue';
import RequestManager from './components/RequestManager.vue';

export default {
  name: 'RequestElement',
  components: { AddRequestStatus, RequestManager },
  props: {
    request: {
      type: Object,
      default: () => {},
    },
    lastElement: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showMenu: false,
    showEditReportLinkInput: false,
    newReportLink: '',
    rkName: null,
    rkDate: null,
    rkCreator: null,
    responsibleLink: null,
    groupsCountText: null,
    socials: null,
    budget: null,
    showSetInProgressModal: false,
    isNeedImplementation: false,
    openAddStatus: false,
    statusOptions: [
      { name: 'Готовимся к старту', value: 'our-getting-ready-to-start', id: 1 },
      { name: 'Ожидаем ответ', value: 'our-progress-response', id: 2 },
      { name: 'Отмена', value: 'our-cancel', id: 3 },
    ],
    newOurComment: null,
  }),
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.rkName = this.getCampaignName();
      this.rkDate = this.getCreatedDate();
      this.rkCreator = this.getCreatorUserName();
      this.groupsCountText = this.getGroupsCountText();
      this.socials = this.request.requestPlacement.socials ? this.request.requestPlacement.socials : false;
      this.budget = this.getBudget();
      this.selectedOurStatus = this.statusOptions.find((item) => item.id === this.request.requestPlacement.ourStatus)?.name || null;
    },
    openCurrentCompilation() {
      if (!this.request.groupsCount) return;
      const rkName = this.request.requestPlacement.campaignsName || false;
      const link = `${getBaseUrl()}/compilation?id=${this.request.requestPlacement.compilation.id}${rkName ? `&rk=${rkName}` : ''}`;
      window.open(link);
    },
    openDocLink() {
      if (this.request.requestPlacement.linkToCompilation && this.request.requestPlacement.linkToCompilation.includes('http')) {
        window.open(this.request.requestPlacement.linkToCompilation);
        return;
      }
      const compilationId = this.request.requestPlacement.compilation?.id;
      if (!compilationId) {
        this.$store.dispatch('app/setSnackBar', { text: 'Проблемы с ссылкой на подборку, обратитесь к менеджеру', error: true });
      }
      const link = `${getBaseUrl()}/compilation?id=${compilationId}`;
      window.open(link);
    },
    openBrief(isNewRevision) {
      const params = {
        selectedGroups: [],
        viewMode: true,
        isNewRevision,
        currentPlacement: this.request.requestPlacement,
      };
      this.$store.commit('placements/setPlacementParams', params);
    },
    getCreatorUserName() {
      return this.request?.requestPlacement?.user?.name || this.request?.requestPlacement?.user?.email || 'Без измени';
    },
    getGroupsCountText() {
      try {
        return this.getPluralWord(+this.request.groupsCount, ['канал', 'канала', 'каналов']);
      } catch {
        return '';
      }
    },
    getBudget() {
      if (this.request.requestPlacement?.budget) {
        const summ = this.setNumberSpaces(+this.request.requestPlacement.budget);
        return `${summ} ₽`;
      }
      return null;
    },
    getCreatedDate() {
      const date = this.request.requestPlacement?.createdAt?.date || false;
      if (date) {
        return `${this.moment(date).format('LT')}, ${this.moment(date).format('LL')}`;
      }
      return 'Дата не указана';
    },
    getCampaignName() {
      return this.request.requestPlacement.campaignsName ? this.request.requestPlacement.campaignsName : '—';
    },
    deleteRequest() {
      this.$emit('deleteRequest', this.request.requestPlacement.id);
      this.showMenu = false;
    },
    openReportInput() {
      this.showEditReportLinkInput = true;
      this.newReportLink = this.request.requestPlacement.linkToCompilation || '';
    },
    submitReportLink() {
      const params = { id: this.request.requestPlacement.id, link: this.newReportLink };
      this.$store.dispatch('placements/sendReportLink', params)
        .then((data) => {
          this.request.requestPlacement = { ...data };
          this.$store.dispatch('app/setSnackBar', { text: 'Ссылка обновлена' });
          this.showEditReportLinkInput = false;
          this.newReportLink = '';
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка. Попробуйте позже', error: true });
        });
    },
    openSetInProgressModal() {
      this.showSetInProgressModal = true;
    },
    setInProgress() {
      const params = {
        id: this.request.requestPlacement.id,
        responsibleOurUserId: `${this.getUserInfo.id}`,
        responsibleLink: this.getUserInfo.telegram || this.getUserInfo.email,
        isNeedImplementation: this.isNeedImplementation,
      };
      this.$store.dispatch('placements/setInProgress', params)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', {
            text: 'Успешно. Запрос взят в работу',
          });
          this.showSetInProgressModal = false;
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: 'Ошибка. Попробуйте позже',
              error: true,
            });
          this.showSetInProgressModal = false;
        });
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
    isRequestFromClientToAgency() {
      // Маркет кому приходят запросы этого агенства, если isSendRequestPlacementsToCrm true, Запросы клиентов агенства приходят в дату
      // если false, запросы приходят самому агенству и агенство может редачить ссылки
      return this.isAdmin || this.request.requestPlacement?.user?.asClientAgency !== null;
    },
    getCurrencySymbol() {
      return this.$store.getters['app/getCurrencySymbol'];
    },
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
    getStatus() {
      const statusId = this.request.requestPlacement.status || null;
      if (statusId) {
        return ['Новый', 'В работе', 'Готов'][statusId - 1];
      }
      return '—';
    },
    getResponsibleName() {
      const responsibleName = this.request.requestPlacement.responsibleUser?.name || this.request.requestPlacement.responsibleUser?.email;
      return responsibleName || '—';
    },
    getCompanyName() {
      if (this.isAdmin) {
        return this.request?.requestPlacement?.ownerAgency?.name || this.request?.requestPlacement?.user?.companyName || '';
      }
      return this.request?.requestPlacement?.user?.companyName || this.request?.requestPlacement?.user?.asClientAgency?.name || '';
    },
    getOurComment() {
      if (this.newOurComment) return this.newOurComment; // коммент, который добавляем сразу отображаем на странице
      const comments = this.request?.requestPlacement?.comments || [];
      return comments && comments.length ? comments.reverse().find((item) => item.isOur)?.content || '—' : '—';
    },
    isReadyRequest() {
      return this.request.requestPlacement.status === 3
      && (this.request.requestPlacement.compilation || this.request.requestPlacement.linkToCompilation);
    },
  },
};
</script>

<style lang="scss" scoped>
.request-element {
  min-width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 105px;
  background: white;
  border-radius: 12px;
  border: 1px solid #D8F2FA;
}

.request-element__main-info {
  width: 470px;
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
  height: 100%;
  padding: 15px 0px 15px 20px;
  box-shadow: 7px 0px 13px -3px rgba(0, 174, 232, 0.15);
  position: relative;

  .request-element__name-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 370px;
  }

  .request-element__status-block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    width: 175px;
    padding-right: 15px;
  }

  .request-element__name {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @include between-elements {
      margin-right: 5px;
    }
  }

  .request-element__name-info {
    display: flex;
    flex-direction: column;
    @include between-elements {
      margin-bottom: 10px;
    }
  }

  .request-element__status-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: #CCEFFA;
    width: 100%;
    max-width: 80px;
    color: #00AEE8;
    height: 24px;
    font-size: 12px;
    &_ready {
      background: #6AB16F;
      color: white;
    }
    &_new {
      background: rgb(250, 130, 33);
      color: white;
    }
  }
}

///

.request-element__content {
  display: flex;
  background: white;
  height: 100%;
}

.request-element__data-column {
  width: 450px;
  border-right: 1px solid #D8F2FA;
  height: 100%;
  padding: 0px 20px 0px 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.request-element__data-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.request-element__data-element {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 5px;
  }
  &_fix {
    width: 200px;
  }
}

.request-element__data-line {
  width: 1px;
  height: 20px;
  margin-right: 20px;
  background: #D8F2FA;
}

.request-element__socials {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  align-items: center;
  top: 0;
  left: 5px;
}

.request-element__social {
  width: 20px;
  height: 20px;
  box-sizing: content-box;
  border: 2px solid white;
  border-radius: 100%;
  position: relative;
  background: white;
}

.request-element__admin-status {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.request-element__manager {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #D8F2FA;
}

.request-element__add-status {
  position: absolute;
  top: 80px;
  right: -100px;
  &_last {
    bottom: 60px;
    top: auto;
  }
}

.request-element__social-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.request-element__request-column {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  position: relative;
  border-right: 1px solid #D8F2FA;
}

.request-element__text {
  font-size: 14px;
  max-width: 100%;
  color: #4F4F4F;
  &_name {
    font-size: 16px;
    line-height: 22px;
    color: $primary-blue;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    padding-right: 10px;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &_light {
    color: $primary-lightgray;
    font-size: 13px;
    font-weight: 400;
  }
  &_title {
    font-size: 16px;
    color: $primary-gray;
    font-weight: 600;
  }
  &_blue {
    color: $primary-blue;
  }
  &_company {
    font-size: 13px;
    color: $primary-gray;
  }
  &_date {
    font-size: 13px;
    color: $primary-gray;
    font-weight: 400;
    margin-top: 0px;
  }
  &_status {
    font-size: 13px;
    color: $primary-blue;
    cursor: pointer;
    margin-top: 10px;
  }
  &_manager {
    color: $primary-gray;
  }
  &_rework {
    font-size: 13px;
    color: $primary-lightgray;
    position: absolute;
    left: 52px;
    bottom: -10px;
  }
  &_comment {
    font-size: 13px;
    color: $primary-gray;
  }
}

.request-element__menu-icon {
  padding: 10px;
  margin-left: 0px;
  cursor: pointer;
  position: relative;
}

.request-element__menu {
  min-width: 210px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 70px;
  right: 10px;
  &_last {
    top: initial;
    bottom: 50px;
  }
}

.request-element__menu-element {
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: rgba($primary-lightgray, 0.05);
  }
}

.request-element__report-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 200px;
  height: 40px;
  @include between-elements {
    margin-bottom: 10px;
  }

  .request-element__report-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    @include between-elements {
      margin-right: 10px;
    }
  }
}

.request-element__report-input {
  background: white;
  border: 1px solid #b8b8b8;
  width: 140px;
  border-radius: 8px;
  height: 35px;
  outline: none;
  padding: 0px 5px 0px 10px;
  margin-left: 10px;
  &::placeholder {
    color: #7B7B7B;
    font-size: 12px;
  }
}

.request-element__report-icon {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.request-element__set-in-progress {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.request-element__checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 0px 20px;
  @include between-elements {
    margin-right: 10px;
  }
}

.request-element__menu-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  position: relative;
}
</style>
