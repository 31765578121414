<template>
  <div class="group-interests">
    <div
    v-if="!isEmptyData"
    class="group-interests__container"
    :class="isAdmin && 'pointer'">
      <span
      v-for="(interes, index) in getInterests"
      :key="interes.id"
      class="group-interests__text"
      :class="index !== 0 && 'group-interests__text_light'"
      @click="openEditor">
        {{ interes.name }}
      </span>
    </div>
    <span
    v-else
    class="group-interests__text group-interests__text_light"
    @click="openEditor">
      {{ $t('no_data_available') }}
    </span>

    <transition name="fade">
      <div
      v-if="false"
      v-click-outside="onClickOutside"
      class="group-interests__editor">
        <div class="group-interests__editor-element">
          <span class="group-interests__text_light">
            {{ $t('Edit a contact') }}
          </span>
          <input
          v-model="contactValue"
          type="text"
          :placeholder="$t('Specify the contact')"
          class="group-interests__input">
        </div>
        <DataButton
        :text="$t('save')"
        type="text"
        @handler="saveChanges" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'GroupTags',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    interests: {
      type: Array,
      default: () => [],
    },
    disableEditor: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showEditor: false,
  }),
  methods: {
    openEditor() {
      if (!this.isAdmin || this.disableEditor) return;

      this.showEditor = true;
    },
    closeEditor() {
      this.showEditor = false;
    },
    onClickOutside() {
      this.closeEditor();
    },
    saveChanges() {
      const newData = {
        contact: this.contactValue === '' ? null : this.contactValue,
      };

      this.showEditor = false;

      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData })
        .then(() => {
          this.group.contact = this.contactValue === '' ? null : this.contactValue;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
        });
    },
  },
  computed: {
    isEmptyData() {
      return this.interests === null || !this.interests.length;
    },
    getInterests() {
      return this.hideDetails ? this.interests.slice(0, 2) : this.interests.slice(0, 3);
    },
  },
};
</script>

<style lang="scss" scoped>
.group-interests {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  min-height: 50px;
  position: relative;
}

.group-interests__container {
  display: flex;
  flex-direction: column;
  width: 160px;
  padding-left: 30px;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.group-interests__text {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4F4F4F;
  cursor: default;
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

.group-interests__editor {
  position: absolute;
  top: 0px;
  left: 30px;
  min-width: 200px;
  border-radius: 12px;
  padding: 20px 20px 0px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-interests__editor-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.group-interests__input {
  padding: 5px 10px;
  border: 1px solid $primary-lightgray;
  border-radius: 8px;
  font-size: 12px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

</style>
