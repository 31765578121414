<!-- eslint-disable vue/no-v-html -->
<template>
  <div
  v-click-outside="closeNotificationsList"
  class="notifications-list">
    <!-- Заглушка при пустом списке -->
    <div
    v-if="!newNotifications.length && getNotificationsList.length === 0"
    class="notifications-list__empty">
      <span class="notifications-list__item-description">
        Уведомлений нет
      </span>
    </div>

    <!-- Список уведомлений -->
    <div
    v-else>
      <div class="notifications-list__header">
        <span class="notifications-list__item-description">
          {{ hasNewNotifications ? 'Новые уведомления' : 'Нет новых уведомлений' }}
        </span>
        <img
        v-tooltip="getSpeakerTooltip"
        class="notifications-list__speakers-toggle"
        :src="require(`../assets/Speaker${speakerState}.svg`)"
        @click="$emit('toggleSpeakerState')">
      </div>
      <div class="notifications-list__notifications">
        <div
        v-for="notification in getNotificationsList"
        :key="notification.id">
          <div
          class="notifications-list__notification">
            <div
            class="notifications-list__icon-block"
            :style="`background: ${notification.color}`">
              <img
              :src="require(`../assets/icons/${notification.icon || 'default'}.svg`)"
              alt="icon"
              class="notifications-list__notification-icon">
            </div>
            <div class="notifications-list__notification-content">
              <!-- Title -->
              <span
              class="notifications-list__item-title pointer"
              @click="goToAction(notification)"
              v-html="notification.title" />

              <!-- Автор -->
              <div v-if="isAdmin && notification.agencyName">
                <span
                v-if="isAdmin"
                class="notifications-list__comment-agency">
                  {{ notification.agencyName }}
                </span>
              </div>

              <!-- Comment -->
              <div
              v-if="notification.comment && notification.comment.content"
              class="notifications-list__comment-block pointer"
              @click="goToAction(notification)">
                <span class="notifications-list__comment-author">
                  {{ 'Автор: ' + (notification.comment.creatorUser?.name || notification.comment.creatorUser?.email) }}
                </span>
                <span class="notifications-list__comment">{{ notification.comment.content }}</span>
              </div>

              <!-- Date -->
              <span
              class="notifications-list__item-date">
                {{ notification.createdDate }}
              </span>
            </div>
            <div class="notifications-list__delete">
              <DataIcon
              v-tooltip="'Скрыть уведомление'"
              name="close"
              :size="18"
              class="notifications-list__delete-icon"
              @click.native="removeNotification(notification)" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div
    v-if="getNotificationsList.length"
    class="notifications-list__remove-all">
      <span
      v-if="showAllNotificationsButton"
      class="notifications-list__show-all"
      @click="showAllNotifications">
        Показать все уведомления
      </span>
      <span
      v-else
      class="notifications-list__item-description">{{ getCountText }}</span>
      <span
      class="notifications-list__item-description pointer"
      @click="removeAllNotifications">Удалить все</span>
    </div>
  </div>
</template>

<script>
import eventTypes from '../assets/assets';

export default {
  name: 'NotificationsList',
  props: {
    newNotifications: {
      type: Array,
      default: () => [],
    },
    hasNewNotifications: {
      type: Boolean,
      default: false,
    },
    speakerState: {
      type: String,
      default: 'Active',
    },
  },
  data: () => ({
    statuses: [
      { id: 1, value: 'unread', name: 'Новое' },
      { id: 2, value: 'read', name: 'Просмотрено' },
    ],
    speakerStates: [
      { value: 'Active', text: 'Звук вкл' },
      { value: 'Disabled', text: 'Звук выкл' },
    ],
  }),
  methods: {
    closeNotificationsList() {
      this.readNewNotifications();
      this.$emit('closeNotificationsList');
    },
    removeNotification(notification) {
      if (this.newNotifications.length) {
        this.newNotifications = this.newNotifications.filter((item) => item.id !== notification.id);
        this.$store.dispatch('notifications/deleteNotifications', [notification.id]);
        const list = this.getAllNotifications.filter((item) => item.id !== notification.id);
        this.$store.commit('notifications/setNotificationsList', list);
        if (!this.newNotifications.length) {
          this.closeNotificationsList();
        }
      } else {
        this.$store.dispatch('notifications/deleteNotifications', [notification.id]);
        const list = this.getAllNotifications.filter((item) => item.id !== notification.id);
        this.$store.commit('notifications/setNotificationsList', list);
        if (list.length === 0) {
          this.closeNotificationsList();
        }
      }
    },
    removeAllNotifications() {
      const ids = this.getNotificationsList.map((item) => item.id);
      this.$store.dispatch('notifications/deleteNotifications', ids);
      const list = this.getAllNotifications.filter((item) => !ids.includes(item.id));
      this.$store.commit('notifications/setNotificationsList', list);
      this.closeNotificationsList();
    },
    goToAction(notification) {
      if (notification.type === 'request') {
        if (this.$route.name !== 'requests') this.$router.push({ name: 'requests' });
        const params = {
          viewMode: true,
          currentPlacement: notification.data.requestPlacement,
        };
        this.$store.commit('placements/setPlacementParams', params);
      }

      if (notification.type === 'campaign') {
        if (this.$route.name !== 'campaigns') this.$router.push({ name: 'campaigns' });
      }

      this.closeNotificationsList();
    },
    readNewNotifications() {
      const ids = [];
      this.getNotificationsList.forEach((item) => {
        if (item.status.id === 1) {
          ids.push(item.id);
        }
        item.status = this.statuses.find((status) => status.id === 2);
      });
      if (ids.length) {
        this.$store.dispatch('notifications/readNotifications', ids);
      }
    },
    showAllNotifications() {
      this.readNewNotifications();
      this.$emit('checkNewNotifications');
    },
  },
  computed: {
    getAllNotifications() {
      return this.$store.getters['notifications/getNotificationsList'];
    },
    getNotificationsList() {
      const notificationsData = this.newNotifications && this.newNotifications.length
        ? this.newNotifications
        : this.getAllNotifications;

      const notifications = notificationsData.map((item) => {
        const typeParams = eventTypes[item.event];

        // title
        let title;
        try {
          let requestName = this.sanitizeHtml(item.data?.requestPlacement?.campaignsName || '');
          requestName = requestName.charAt(0).toUpperCase() + requestName.slice(1);
          title = typeParams.name.replace('(request)', `<span style="font-weight: 600; color: #00AEE8">${requestName}</span>`);
        } catch {
          title = typeParams.name;
        }

        // date
        const createdDate = item.createdAt?.date
          ? this.moment(item.createdAt?.date).format('LLL')
          : this.moment(new Date()).format('LLL');

        // comment
        let comment;
        try {
          if (item.event === 'requestPlacementCommented') {
            const comments = [...item.data?.requestPlacement?.comments] || [];
            comment = comments[comments.length - 1] || null;
          }
        } catch {
          comment = null;
        }

        // status
        const status = this.statuses.find((element) => element.id === item.status) || this.statuses[0];

        // agency
        const agencyName = item.data.requestPlacement?.ownerAgency?.name || '?';

        const notification = {
          ...item,
          ...typeParams,
          title,
          createdDate,
          comment,
          status,
          agencyName,
        };
        return notification;
      });

      return notifications.reverse();
    },
    getCountText() {
      const notifyText = this.getPluralWord(this.getNotificationsList.length, ['уведомление', 'уведомления', 'уведомлений']);
      return `Всего ${this.getNotificationsList.length} ${notifyText}`;
    },
    showAllNotificationsButton() {
      return this.newNotifications.length && (this.getAllNotifications.length > this.newNotifications.length);
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    getSpeakerTooltip() {
      return this.speakerStates.find((item) => item.value === this.speakerState).text;
    },
  },
};
</script>

<style lang="scss" scoped>

.notifications-list {
  background: white;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  position: absolute;
  top: 40px;
  right: -75px;
  border-radius: 8px;

  .notifications-list__notification {
    padding-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: 450px;
  }

  .notifications-list__empty {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    width: 175px;
  }

  .notifications-list__notifications {
    padding: 20px 15px 0px 20px;
    max-height: 600px;
    &::-webkit-scrollbar {
    width: 6px;
    height: 5px;
    cursor: pointer;
  }
    overflow: auto;
    scrollbar-gutter: stable;
    &_border {
      border-top: 1px solid #d8f2fa;
    }
    @include between-elements {
      margin-bottom: 15px;
      border-bottom: 1px solid #d8f2fa;
    }
  }

  .notifications-list__item-title {
    font-weight: 600;
    margin-bottom: 10px;
    color: $primary-gray;
    max-width: 90%;
    padding-right: 20px;
  }

  .notifications-list__item-title-name {
    color: $primary-blue;
    font-weight: 600;
  }

  .notifications-list__item-description {
    font-size: 14px;
    font-weight: 400;
    color: rgba($primary-gray, 0.8);
  }

  .notifications-list__item-date {
    font-size: 12px;
    color: rgba($primary-gray, 0.8);
  }

  .notifications-list__icon-block {
    width: 50px;
    height: 50px;
    background: #dfe4df;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    flex-shrink: 0;
    &_campaign {
      background: #eaf9ff;
    }
  }

  .notifications-list__notification-icon {
    width: 24px;
    height: 24px;;
  }

  .notifications-list__notification-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .notifications-list__delete {
    width: 40px;
    transition: 0.1s;
    border-radius: 8px;
    position: absolute;
    right: -10px;
    top: -3px;
    &:active {
      transform: scale(95%);
    }
  }

  .notifications-list__delete-icon {
    color: $primary-lightgray;
    cursor: pointer;
    padding: 5px;
    max-width: 50px !important;
    border-radius: 8px;
    border: 1px solid rgba(236, 236, 236, 0.5);
    &:active {
      transform: scale(81%);
    }
  }

  .notifications-list__remove-all {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 1px solid rgba($primary-gray, 0.1);
    padding: 20px 30px 20px 20px;
  }

  .notifications-list__show-all {
    color: $primary-blue;
    cursor: pointer;
    font-size: 14px;
  }

  .notifications-list__header {
    width: 100%;
    padding: 10px 20px 10px 20px;
    font-size: 13px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba($primary-gray, 0.1);
  }

  .notifications-list__speakers-toggle {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 5px;
  }

  .notifications-list__comment-block {
    margin-bottom: 10px;
    padding: 15px;
    background: rgba(black, 0.03);
    border-radius: 4px;
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .notifications-list__comment-author {
    font-size: 12px;
    color: rgba($primary-gray, 0.7);
    margin-bottom: 3px;
    font-weight: 500;
  }

  .notifications-list__comment-agency {
    font-size: 12px;
    color: $primary-gray;
    font-weight: 600;
    margin-bottom: 5px;
    display: block;
  }

  .notifications-list__comment {
    font-size: 14px;
    color: rgba($primary-gray, 0.8);
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }
}

</style>
