<template>
  <div class="clients-statistic-headers">
    <div
    v-for="header in getHeaders"
    :key="header.value"
    :style="`width: ${header.width}px`"
    :class="{ 'clients-statistic-headers__element_name': header.value === 'name' }"
    class="clients-statistic-headers__element">
      <span class="clients-statistic-headers__text_light">
        {{ header.name }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ClientsStatisticHeaders',
  data: () => ({}),
  computed: {
    getHeaders() {
      return [
        { name: 'Клиент', value: 'name', width: 300 },
        { name: 'Запросы', value: 'requests', width: 200 },
        { name: 'Всего по запросам (Руб)', value: 'requestsTotal', width: 200 },
        { name: 'Изменение %', value: 'requestChange', width: 200 },
        { name: 'Старты', value: 'starts', width: 200 },
        { name: 'Всего по стартам (Руб)', value: 'startsTotal', width: 200 },
        { name: 'Изменение %', value: 'startChange', width: 200 },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.clients-statistic-headers {
  display: flex;
  align-items: center;
  min-width: 100%;
  position: sticky;
  background: white;
  z-index: 2;
  top: 0px;
  left: 0px;
  border-bottom: 1px solid $primary-group-border;
}

.clients-statistic-headers__element {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  &_name {
    padding: 12px 0px;
    position: sticky;
    left: 0px;
    padding-left: 20px;
    background: white;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 1;
  }
}

.clients-statistic-headers__text {
  font-size: 14px;
  color: $primary-gray;
  &_name {
    color: $primary-blue;
  }
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

</style>
