import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);
const requestsSearchParamsTemplate = {
  ownerAgencyId: null,
  creatorUserId: null,
  status: null,
  campaignsName: null,
  responsibleName: null,
  createdAtFrom: null,
  createdAtTo: null,
};

export default {
  namespaced: true,
  state: {
    requestsData: [],
    loadParams: {
      'page-size': 50,
      page: 1,
    },
    totalPageCounts: null,
    requestsSearchParams: { ...requestsSearchParamsTemplate },
    requestsSearchParamsTemplate: { ...requestsSearchParamsTemplate },
    placementParams: null,
    clientsList: null,
  },
  getters: {
    getRequestsData: (state) => state.requestsData,
    getTotalPageCounts: (state) => state.totalPageCounts,
    getIsLastPage: (state) => state.loadParams.page === +state.totalPageCounts + 1,
    getRequestsSearchParams: (state) => state.requestsSearchParams,
    getRequestsSearchParamsTemplate: (state) => state.requestsSearchParamsTemplate,
    getPlacementParams: (state) => state.placementParams,
    getClientsList: (state) => state.clientsList,
  },
  mutations: {
    setRequestsData: (state, value) => { state.requestsData = value; },
    setTotalPageCounts: (state, value) => { state.totalPageCounts = value; },
    setLoadPage: (state, value) => { state.loadParams.page = value; },
    setRequestsSearchParams: (state, value) => { state.requestsSearchParams = { ...value }; },
    resetRequestsSearchParams: (state) => { state.requestsSearchParams = { ...requestsSearchParamsTemplate }; },
    setPlacementParams: (state, value) => { state.placementParams = value; },
    setClientsList: (state, value) => { state.clientsList = value; },
  },
  actions: {
    // Получение списка запросов
    loadRequestsData({ state, commit }, params = { reset: false }) {
      commit('app/setAppIsLoading', true, { root: true });
      if (params.reset) {
        commit('setRequestsData', []);
        commit('setLoadPage', 1);
        commit('setTotalPageCounts', null);
      }
      return api.get('/placement-aggregate', { params: { ...state.loadParams, ...state.requestsSearchParams, ...params.ownerParams } })
        .then((response) => {
          const requestsData = params.reset ? response.data.data : [...state.requestsData, ...response.data.data];
          commit('setRequestsData', requestsData);
          commit('setTotalPageCounts', response.headers['x-pagination-page-count']);
          commit('setLoadPage', state.loadParams.page + 1);
          commit('app/setAppIsLoading', false, { root: true });
          return Promise.resolve(requestsData);
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- loadRequestsData --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Отправка запроса
    createPlacement({ commit, dispatch }, groups) {
      const token = localStorage.getItem('userToken');
      commit('app/setAppIsLoading', true, { root: true });
      return api.post('/placement', groups, {
        headers: {
          'X-API-KEY': token,
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => Promise.resolve())
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'createPlacement' }, { root: true });
          console.warn('Error in action -- createPlacement --; Error -', error);
          return Promise.reject();
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },

    // Редактирование запроса
    updatePlacement({ state, commit, dispatch }, payload) {
      const token = localStorage.getItem('userToken');
      commit('app/setAppIsLoading', true, { root: true });
      return api.post('/placement/update', payload.formData, {
        headers: {
          'X-API-KEY': token,
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(({ data: { data } }) => {
          state.placementParams.currentPlacement = data;
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'updatePlacement' }, { root: true });
          console.warn('Error in action -- updatePlacement --; Error -', error);
          return Promise.reject();
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },

    // Отправка на доработку
    sendToRework({ commit, dispatch }, form) {
      const token = localStorage.getItem('userToken');
      commit('app/setAppIsLoading', true, { root: true });
      return api.post('/placement/send-to-rework', form, {
        headers: {
          'X-API-KEY': token,
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => Promise.resolve())
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'sendToRework' }, { root: true });
          console.warn('Error in action -- sendToRework --; Error -', error);
          return Promise.reject();
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },

    // Удаление/Архивирование запроса
    deletePlacement({ state, commit, dispatch }, id) {
      return api.patch(`/placement/${id}/archive`)
        .then(() => {
          const newData = state.requestsData.filter((item) => item.requestPlacement.id !== id);
          commit('setRequestsData', newData);
          Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'deletePlacement' }, { root: true });
          console.warn('Error in action -- deletePlacement --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Отправка ссылки на подборку в запросе
    sendReportLink({ commit, dispatch }, params) {
      commit('app/setAppIsLoading', true, { root: true });
      return api.patch(`/placement/${params.id}/complete`, { linkToCompilation: params.link })
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'sendReportLink' }, { root: true });
          console.warn('Error in action -- sendReportLink --; Error -', error);
          return Promise.reject(error);
        })
        .finally(() => {
          commit('app/setAppIsLoading', false, { root: true });
        });
    },

    // Получение списка ответственных
    getResponsibleNames({ commit }, params) {
      return api.get('/placement/responsible-users', params)
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- getResponsibleNames --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Получение списка
    getClientsNames({ commit }, payload) {
      return api.get(`/placement/${payload.isAgency ? 'creator-users' : 'owner-agencies'}`, {
        params: {
          ownerAgencyId: payload.ownerAgencyId,
        },
      })
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- getClientsNames --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Взять запрос в работу
    setInProgress({ state, commit, dispatch }, params) {
      return api.patch(`/placement/${params.id}/set-in-progress`,
        {
          responsibleOurUserId: params.responsibleOurUserId,
          responsibleLink: params.responsibleLink,
          isNeedImplementation: params.isNeedImplementation,
        })
        .then(({ data: { data } }) => {
          const newRequests = state.requestsData.map((item) => {
            if (item.requestPlacement.id === data.id) {
              item.requestPlacement = data;
              return item;
            }
            return item;
          });
          commit('setRequestsData', newRequests);
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'setInProgress' }, { root: true });
          console.warn('Error in action -- setInProgress --; Error -', error);
          return Promise.reject(error);
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },

    // Сменить ответственного в запросе
    setResponsibleUser({ state, dispatch }, params) {
      return api.patch(`/placement/${params.requestId}/set-responsible-user`, { responsibleOurUserId: params.userId })
        .then(({ data: { data } }) => {
          state.requestsData.forEach((item) => {
            if (item.requestPlacement.id === data.id) {
              item.requestPlacement = data;
            }
          });
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'setResponsibleUser' }, { root: true });
          console.warn('Error in action -- setResponsibleUser --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Добавление комментария к заявке
    addCommentToRequest({ state }, params) {
      const payload = { requestPlacementId: params.requestPlacementId, content: params.content, isOur: params.isOur || null };
      return api.post('/placement/add-comment', payload)
        .then(({ data: { data } }) => {
          state.requestsData.forEach((item) => {
            if (item.requestPlacement.id === params.requestPlacementId) {
              item.requestPlacement.comments = data;
            }
          });
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- addCommentToRequest --; Error -', error);
          return Promise.reject();
        });
    },

    // Получение списка клиентов для возможности прикрепления к запросу
    getClientsList() {
      return api.get('/agency', { params: { withAccessToCatalog: true } })
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          console.warn('Error in action -- getClientsList --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Установить внутеренний статус для запроса
    // our-cancel, our-getting-ready-to-start, our-progress-response
    setAdminStatusInRequest({ dispatch }, params) {
      return api.patch(`/placement/${params.id}/${params.status}`, params)
        .then((data) => Promise.resolve(data))
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'setAdminStatusInRequest' }, { root: true });
          console.warn('Error in action -- setAdminStatusInRequest --; Error -', error);
          return Promise.reject(error);
        });
    },
  },
};
