<template>
  <div
  ref="admin-filters"
  class="admin-filters"
  @click="openMenu">
    <div class="admin-filters__title">
      <span class="admin-filters__text admin-filters__text_second">
        {{ $t('admin_filters') }}
      </span>
      <transition name="fade">
        <div
        v-if="isNotEmptyValues"
        class="admin-filters__title-active" />
      </transition>
    </div>
    <span
    class="admin-filters__text"
    :class="isNotEmptyValues && 'admin-filters__text_blue'">
      {{ getLabel }}
    </span>
    <transition name="fade">
      <div
      v-if="isOpen"
      v-click-outside="onClickOutside"
      :style="{
        top: `${top}px`,
        left: `${left}px`,
      }"
      class="admin-filters__menu">
        <div class="admin-filters__content">
          <div class="admin-filters__column">
            <div class="admin-filters__popular">
              <span class="admin-filters__text admin-filters__text_second">Попадания в подборки</span>
              <div class="admin-filters__input-block">
                <input
                v-model="countInCompilationsValueMin"
                type="number"
                placeholder="от"
                class="admin-filters__input"
                @input="changeCountInCompilation('min')">
              </div>
              <div class="admin-filters__input-block">
                <input
                v-model="countInCompilationsValueMax"
                placeholder="до"
                type="number"
                class="admin-filters__input"
                @input="changeCountInCompilation('max')">
              </div>
            </div>
            <div class="admin-filters__popular">
              <span class="admin-filters__text admin-filters__text_second">Попадания в РК</span>
              <div class="admin-filters__input-block">
                <input
                v-model="campaignLauncherCountValueMin"
                type="number"
                placeholder="от"
                class="admin-filters__input"
                @input="changeCountInRk('min')">
              </div>
              <div class="admin-filters__input-block">
                <input
                v-model="campaignLauncherCountValueMax"
                placeholder="до"
                type="number"
                class="admin-filters__input"
                @input="changeCountInRk('max')">
              </div>
            </div>
            <div class="admin-filters__container admin-filters__container_row">
              <DataCheckbox
              v-model="getIsWrapping" />
              <span class="admin-filters__text admin-filters__text_second">
                Накрученные каналы
              </span>
            </div>
            <div class="admin-filters__container admin-filters__container_row">
              <DataCheckbox
              v-model="getIsBadUrl" />
              <span class="admin-filters__text admin-filters__text_second">
                С битой ссылкой
              </span>
            </div>
            <div class="admin-filters__container admin-filters__container_row">
              <DataCheckbox
              v-model="postSaleBiggerThanBuyValue" />
              <span class="admin-filters__text admin-filters__text_second">
                Себес выше прайса
              </span>
            </div>

            <div class="admin-filters__container">
              <span class="admin-filters__text">
                In SocialJet
              </span>
              <div class="admin-filters__switch">
                <div
                v-for="param in simpleParams"
                :key="param.value"
                class="admin-filters__switch-item"
                :class="inSocialJetValue == param.value && 'admin-filters__switch-item-active'"
                @click="changeValue('inSocialJet', 'inSocialJetValue', param.value)">
                  <span>{{ param.name }}</span>
                </div>
              </div>
            </div>
            <div class="admin-filters__container">
              <span class="admin-filters__text">
                {{ $t('in_black_list') }}
              </span>
              <div class="admin-filters__switch">
                <div
                v-for="param in simpleParams"
                :key="param.value"
                class="admin-filters__switch-item"
                :class="inBlackListValue == param.value && 'admin-filters__switch-item-active'"
                @click="changeValue('inBlackList', 'inBlackListValue', param.value)">
                  <span>{{ param.name }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="admin-filters__radios">
            <div class="admin-filters__container">
              <span class="admin-filters__text">
                {{ $t('payment_type') }}
              </span>
              <div
              v-for="payment in paymentOptions"
              :key="payment.id"
              class="admin-filters__payment"
              @click="setPaymentFilter(payment)">
                <DataRadio :checked="paymentTypesIds.includes(payment.id)" />
                <span class="admin-filters__text admin-filters__text_second">
                  {{ payment.name }}
                </span>
              </div>
            </div>
            <div class="admin-filters__container">
              <span class="admin-filters__text">
                Botname
              </span>
              <div
              v-for="bot in botnames"
              :key="bot.value"
              class="admin-filters__interval">
                <label class="admin-filters__interval">
                  <input
                  v-model="botNameValue"
                  type="radio"
                  :value="bot.value"
                  class="admin-filters__radio"
                  @change="$emit(`update:botName`, botNameValue)">
                  <span class="admin-filters__text admin-filters__text_second">
                    {{ bot.name }}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <span
        class="admin-filters__text admin-filters__text_second"
        @click="resetAll">
          {{ $t('reset_all') }}
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
import groupsData from '@/assets/js/groupsData';
import debounce from 'lodash/debounce';

export default {
  name: 'AdminFilters',
  props: {
    inSocialJet: {
      type: [String, Boolean],
      default: null,
    },
    inBlackList: {
      type: [String, Boolean],
      default: null,
    },
    paymentTypesId: {
      type: Array,
      default: null,
    },
    botName: {
      type: [String, Boolean],
      default: null,
    },
    postSaleBiggerThanBuy: {
      type: [String, Boolean],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    countInCompilationsMin: {
      type: [Number, String],
      default: null,
    },
    countInCompilationsMax: {
      type: [Number, String],
      default: null,
    },
    campaignLauncherCountMin: {
      type: [Number, String],
      default: null,
    },
    campaignLauncherCountMax: {
      type: [Number, String],
      default: null,
    },
    reset: {
      type: Boolean,
      default: false,
    },
    warnings: {
      type: [Number, String, Array, Boolean],
      default: null,
    },
  },
  data: () => ({
    top: 0,
    left: 0,
    isOpen: false,
    inSocialJetValue: null,
    inBlackListValue: null,
    countInCompilationsValueMin: '',
    countInCompilationsValueMax: '',
    campaignLauncherCountValueMin: '',
    campaignLauncherCountValueMax: '',
    paymentTypesIds: [],
    paymentOptions: [
      {
        id: 1,
        name: 'ИП',
      },
      {
        id: 2,
        name: 'СЗ',
      },
      {
        id: 3,
        name: 'КЭШ',
      },
      {
        id: 4,
        name: 'Другое',
      },
      {
        id: 5,
        name: 'OOO',
      },
      {
        id: 6,
        name: 'Sollar',
      },
    ],
    botNames: null,
    botNameValue: null,
    selectedWarnings: null,
    warningsTypes: [
      {
        value: '7',
        name: 'С накруткой',
      },
      {
        value: '2',
        name: 'Битая ссылка',
      },
    ],
  }),
  created() {
    this.botnames = groupsData.botNames;

    this.inSocialJetValue = this.inSocialJet;
    this.inBlackListValue = this.inBlackList;
    this.botNameValue = this.botName;
    this.paymentTypesIds = this.paymentTypesId ? this.paymentTypesId.map((item) => +item) : [];

    this.selectedWarnings = this.warnings === null
      ? []
      : [...this.warnings];
  },
  methods: {
    resetAll() {
      this.inSocialJetValue = null;
      this.inBlackListValue = null;
      this.botNameValue = null;
      this.selectedWarnings = [];
      this.paymentTypesIds = [];

      const values = [
        'inSocialJet',
        'inBlackList',
        'paymentTypesId',
        'botName',
        'postSaleBiggerThanBuy',
        'warnings',
        'countInCompilationsMin',
        'countInCompilationsMax',
        'campaignLauncherCountMin',
        'campaignLauncherCountMax',
      ];
      values.forEach((item) => {
        this.$emit(`update:${item}`, null);
      });
    },
    openMenu() {
      this.top = this.$refs['admin-filters'].getBoundingClientRect().top + 50;
      this.left = this.$refs['admin-filters'].getBoundingClientRect().left;
      this.isOpen = true;
    },
    onClickOutside() {
      this.isOpen = false;
    },
    changeValue(key, name, value) {
      if (this[name] === value) {
        return;
      }
      this[name] = value;
      this.$emit(`update:${key}`, value);
    },
    setPaymentFilter(payment) {
      if (this.paymentTypesIds.includes(payment.id)) {
        this.paymentTypesIds = this.paymentTypesIds.filter((item) => item !== payment.id);
      } else {
        this.paymentTypesIds.push(payment.id);
      }
      this.$emit('update:paymentTypesId', this.paymentTypesIds.length ? this.paymentTypesIds : null);
    },
    // eslint-disable-next-line func-names
    changeCountInCompilation: debounce(function (type) {
      if (type === 'min') {
        const value = !this.countInCompilationsValueMin || this.countInCompilationsValueMin === 0 ? null : this.countInCompilationsValueMin;
        this.$emit('update:countInCompilationsMin', value);
      } else {
        const value = !this.countInCompilationsValueMax || this.countInCompilationsValueMax === 0 ? null : this.countInCompilationsValueMax;
        this.$emit('update:countInCompilationsMax', value);
      }
    }, 400),
    // eslint-disable-next-line func-names
    changeCountInRk: debounce(function (type) {
      if (type === 'min') {
        const value = !this.campaignLauncherCountValueMin || this.campaignLauncherCountValueMin === 0 ? null : this.campaignLauncherCountValueMin;
        this.$emit('update:campaignLauncherCountMin', value);
      } else {
        const value = !this.campaignLauncherCountValueMax || this.campaignLauncherCountValueMax === 0 ? null : this.campaignLauncherCountValueMax;
        this.$emit('update:campaignLauncherCountMax', value);
      }
    }, 400),
  },
  computed: {
    postSaleBiggerThanBuyValue: {
      get() {
        return this.postSaleBiggerThanBuy;
      },
      set(value) {
        this.$emit('update:postSaleBiggerThanBuy', value || null);
      },
    },
    getIsWrapping: {
      get() {
        return this.selectedWarnings.includes('7');
      },
      set(value) {
        this.selectedWarnings = value ? ['7', ...this.selectedWarnings] : this.selectedWarnings.filter((item) => item !== '7');
        this.$emit('update:warnings', this.selectedWarnings.length ? this.selectedWarnings : null);
      },
    },
    getIsBadUrl: {
      get() {
        return this.selectedWarnings.includes('2');
      },
      set(value) {
        this.selectedWarnings = value ? ['2', ...this.selectedWarnings] : this.selectedWarnings.filter((item) => item !== '2');
        this.$emit('update:warnings', this.selectedWarnings.length ? this.selectedWarnings : null);
      },
    },
    isNotEmptyValues() {
      return [
        this.inSocialJetValue,
        this.inBlackListValue,
        this.paymentTypesId,
        this.botNameValue,
        this.postSaleBiggerThanBuyValue,
        this.countInCompilationsMin,
        this.countInCompilationsMax,
        this.campaignLauncherCountMin,
        this.campaignLauncherCountMax,
      ].filter((item) => item !== null).length + this.selectedWarnings.length;
    },
    getLabel() {
      return this.isNotEmptyValues ? `${this.$t('selected')} (${this.isNotEmptyValues})` : this.$t('not_selected');
    },
    simpleParams() {
      return [
        { value: null, name: this.$t('all') },
        { value: 'true', name: this.$t('yes') },
        { value: 'false', name: this.$t('no') },
      ];
    },
  },
  watch: {
    reset(newValue) {
      if (newValue === false) {
        this.resetAll();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-filters {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 60px;
}

.admin-filters__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5px;
}

.admin-filters__title-active {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: $primary-blue;
  margin-left: 10px;
  margin-top: 1px;
}

.admin-filters__text {
  color: #565656;
  font-size: 14px;
  cursor: pointer;
  &_second {
    font-weight: 400;
    color: #7a7a7a;
    font-size: 13px;
  }
  &_blue {
    color: $primary-blue;
  }
}

.admin-filters__menu {
  position: absolute;
  top: 50px;
  left: 0px;
  z-index: 10;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
}

.admin-filters__content {
  display: flex;
  max-height: 520px;
  width: 400px;
  @include between-elements {
    margin-right: 30px;
  }
  margin-bottom: 40px;
}

.admin-filters__column {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 10px;
  }
}
.admin-filters__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 5px;
  }
  &_row {
    flex-direction: row;
    align-items: center;
    @include between-elements {
      margin-right: 10px;
      margin-bottom: 0px;
    }
  }
}

.admin-filters__switch {
  display: flex;
  height: 25px;
  width: 180px;
  outline: 1px solid $border-color-primary;
  @include between-elements {
    border-right: 1px solid $border-color-primary;
  }
  cursor: pointer;
  &-active {
    color: $primary-white;
    background: $primary-blue;
  };
}

.admin-filters__switch-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 12px;
  color: $primary-black;
  &-active {
    color: $primary-white;
    background: $primary-blue;
  };
}

.admin-filters__interval {
  display: flex;
  align-items: center;
  @include between-elements {
    margin-right: 5px;
  }
}

.admin-filters__radios {
  display: flex;
  flex-wrap: wrap;
  @include between-elements {
    margin-right: 50px;
    margin-bottom: 20px;
  }
}

.admin-filters__radio {
  margin: 0px;
}

.admin-filters__slider {
  margin: 20px 0px 20px;
}

.admin-filters__popular {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 10px;
  }
  width: 200px;
}

.admin-filters__payment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 10px;
  }
}

.admin-filters__input-block {
  position: relative;
  margin-bottom: 5px;
}

.admin-filters__input {
  margin-top: 2px;
  padding: 8px 10px;
  border: 1px solid $border-color-primary;
  border-radius: 8px;
  line-height: 14px;
  background: $primary-white;
  width: 100%;
  font-size: 14px;
  -moz-appearance: textfield;
  &:focus {
    outline: none;
    border-color: $border-color-focus;
  }
  &::placeholder {
    color: rgb(161, 161, 161);
    font-size: 12px;
    font-weight: 400;
  }
}
</style>
