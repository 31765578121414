import Vue from 'vue';

// UI
import DataIcon from '../components/UI/DataIcon.vue';
import DataButton from '../components/UI/DataButton.vue';
import DataModal from '../components/UI/DataModal.vue';
import DataSnackbar from '../components/UI/DataSnackbar.vue';
import DataRangeSlider from '../components/UI/DataRangeSlider.vue';
import DataCheckbox from '../components/UI/DataCheckbox.vue';
import DataRadio from '../components/UI/DataRadio.vue';
import DataToggle from '../components/UI/DataToggle.vue';
import DataInput from '../components/UI/DataInput.vue';
import DataSelect from '../components/UI/DataSelect.vue';
import DataTextarea from '../components/UI/DataTextarea.vue';
import DataPreloader from '../components/UI/DataPreloader.vue';
import DataDatePicker from '../components/UI/DataDatePicker.vue';
import DataTimePicker from '../components/UI/DataTimePicker.vue';
import DataPagination from '../components/UI/DataPagination.vue';
import DataOverlay from '../components/UI/DataOverlay.vue';
import DataSkeleton from '../components/UI/DataSkeleton.vue';
// Components
import MainHeader from '../components/MainHeader/MainHeader.vue';
import AppLoader from '../components/AppLoader/AppLoader.vue';
// Layouts

// use
const appComponents = () => {
  Vue.component('DataIcon', DataIcon);
  Vue.component('DataButton', DataButton);
  Vue.component('DataModal', DataModal);
  Vue.component('DataSnackbar', DataSnackbar);
  Vue.component('DataRangeSlider', DataRangeSlider);
  Vue.component('DataCheckbox', DataCheckbox);
  Vue.component('DataToggle', DataToggle);
  Vue.component('DataInput', DataInput);
  Vue.component('DataSelect', DataSelect);
  Vue.component('DataTextarea', DataTextarea);
  Vue.component('DataPreloader', DataPreloader);
  Vue.component('DataRadio', DataRadio);
  Vue.component('DataDatePicker', DataDatePicker);
  Vue.component('DataTimePicker', DataTimePicker);
  Vue.component('MainHeader', MainHeader);
  Vue.component('AppLoader', AppLoader);
  Vue.component('DataPagination', DataPagination);
  Vue.component('DataOverlay', DataOverlay);
  Vue.component('DataSkeleton', DataSkeleton);
};

export default appComponents;
