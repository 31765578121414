var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"material-icons data-icon",class:{
  'pointer': _vm.pointer,
  'arrow-open': _vm.arrowOpen,
  'menu-open': _vm.menuOpen,
},style:({
  fontSize: _vm.size + 'px',
  maxWidth: _vm.size + 'px',
})},[_vm._v(" "+_vm._s(_vm.name)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }