<template>
  <div class="competitor-element">
    <div
    class="competitor-element__column competitor-element__column_name">
      <DataCheckbox
      v-model="group.checked"
      class="competitor-element__checkbox" />
      <img
      class="competitor-element__avatar"
      :src="group.group.avatar ? group.group.avatar : require('@/assets/svg/empty-avatar.svg')">
      <div class="competitor-element__name-text">
        <span
        v-tooltip="group.group.name"
        class="competitor-element__text competitor-element__text_name"
        @click="routeToCurrentGroupLink">
          {{ group.group.name }}
        </span>
        <span
        v-if="categories"
        class="competitor-element__text competitor-element__text_category">
          {{ categories }}
        </span>
      </div>
    </div>

    <!-- Подписчики/Subscribers -->
    <div
    class="competitor-element__column"
    style="width: 150px">
      <span class="competitor-element__text">
        {{ subscribers }}
      </span>
    </div>

    <!-- Просмотры/Views -->
    <div
    class="competitor-element__column"
    style="width: 150px">
      <span class="competitor-element__text">
        {{ views }}
      </span>
    </div>

    <!-- Цена в дате ПРАЙС-->
    <div
    v-click-outside="() => closeEditInput('price')"
    class="competitor-element__column competitor-element__column_price"
    style="width: 200px">
      <transition
      :key="showDataPriceInput"
      name="fade"
      mode="out-in">
        <span
        v-if="!showDataPriceInput"
        class="competitor-element__text">{{ getDataResultPriceWithCurrency }}</span>
        <input
        v-if="showDataPriceInput"
        ref="dataPriceInput"
        v-model="newDataPrice"
        class="competitor-element__price-input"
        type="number"
        @keyup.enter="saveNewDataPrice">
      </transition>
      <DataIcon
      v-if="!showDataPriceInput"
      name="edit"
      :size="16"
      class="competitor-element__text competitor-element__edit-icon"
      @click.native="openDataInput('price')" />
      <DataIcon
      v-if="showDataPriceInput"
      name="done"
      :size="16"
      class="competitor-element__text competitor-element__edit-icon competitor-element__edit-icon_visible"
      @click.native="saveNewDataPrice('price')" />
    </div>

    <!-- Цена в дате -->
    <div
    v-click-outside="() => closeEditInput('sale')"
    class="competitor-element__column competitor-element__column_price"
    style="width: 200px">
      <transition
      :key="showDataSaleInput"
      name="fade"
      mode="out-in">
        <span
        v-if="!showDataSaleInput"
        class="competitor-element__text">{{ getDataPriceWithCurrency }}</span>
        <input
        v-if="showDataSaleInput"
        ref="dataSaleInput"
        v-model="newDataSale"
        class="competitor-element__price-input"
        type="number"
        @keyup.enter="saveNewDataPrice">
      </transition>
      <DataIcon
      v-if="!showDataSaleInput"
      name="edit"
      :size="16"
      class="competitor-element__text competitor-element__edit-icon"
      @click.native="openDataInput('sale')" />
      <DataIcon
      v-if="showDataSaleInput"
      name="done"
      :size="16"
      class="competitor-element__text competitor-element__edit-icon competitor-element__edit-icon_visible"
      @click.native="saveNewDataPrice('sale')" />
    </div>

    <!-- Цена в телеге -->
    <div
    class="competitor-element__column"
    style="width: 200px">
      <span class="competitor-element__text">{{ getTelegaPrice }}</span>
    </div>

    <!-- Цена в телеге -->
    <div
    v-tooltip="differenceColor === 'red' ? `Наша цена выше на ${difference}` : `Наша цена ниже на ${difference}`"
    class="competitor-element__column"
    style="width: 200px">
      <span
      class="competitor-element__text"
      :class="differenceColor ? `competitor-element__text_${differenceColor}` : ''">{{ difference }}</span>
    </div>

    <!-- Скрыть -->
    <div
    class="competitor-element__column"
    style="width: 150px">
      <span
      class="competitor-element__text competitor-element__text_category pointer"
      @click="hideElement">Скрыть</span>
    </div>
  </div>
</template>

<script>
import getBaseUrl from '@/assets/js/helpers';

export default {
  name: 'CompetitorElement',
  props: {
    group: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
    selectedTab: {
      type: String,
      default: 'wait',
    },
    differencePercent: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    subscribers: null,
    views: null,
    categories: null,
    dataSale24h: null,
    dataPrice24h: null,
    showDataPriceInput: false,
    showDataSaleInput: false,
    oldDataPrice: null,
    newDataPrice: '',
    oldDataSale: null,
    newDataSale: '',
  }),
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.subscribers = this.getSubscribers();
      this.views = this.getViews();
      this.categories = this.getCategories();
      this.dataSale24h = this.getPostPrice('sale');
      this.dataPrice24h = this.getPostPrice('price');
      this.difference = this.getDifference();
      this.differenceColor = this.getDifferenceColor();
    },
    getDifferenceColor() {
      try {
        if (!this.differencePercent) {
          return null;
        }
        return Number(this.differencePercent) <= 0 ? 'red' : 'green';
      } catch {
        return null;
      }
    },
    getDifference() {
      if (!this.differencePercent) {
        return '—';
      }
      return `${this.differencePercent.toFixed(1)}%`;
    },
    getCategories() {
      const categories = this.group.group.categories.map((category) => this.$t(category.name));
      return categories.length ? categories.join(', ') : false;
    },
    getSubscribers() {
      return this.group.group.subscribersStatistic.count
        ? this.setNumberSpaces(this.group.group.subscribersStatistic.count)
        : 'Нет данных';
    },
    getViews() {
      return this.group.group.viewsStatistic.countLast24h
        ? `${this.setNumberSpaces(this.group.group.viewsStatistic.countLast24h)} / день`
        : 'Нет данных';
    },
    routeToCurrentGroupLink() {
      window.open(`${getBaseUrl()}/group/${this.group.group.social}/${this.group.group.id}`);
    },
    getPostPrice(type) {
      try {
        const currentProducts = this.group.group.products.filter((item) => item.type === 1); // находим объект с постом
        if (!currentProducts.length) {
          return '—';
        }
        const currentProduct = currentProducts.find((item) => item.durationInHours === '24' || item.durationInHours === null);
        if (!currentProduct) {
          return '—';
        }

        const currentTradeOffer = currentProduct?.tradeOffersChain?.tradeOffers?.find((item) => item.buyerType === 3);
        let resultPrice = null;

        if (currentTradeOffer) {
          if (type === 'sale') {
            resultPrice = currentTradeOffer.sale || null;
          }
          if (type === 'price') {
            resultPrice = currentTradeOffer.resultPrice || null;
          }
        }

        if (!resultPrice && +resultPrice !== 0) {
          return '—';
        }
        return resultPrice;
      } catch {
        return '?';
      }
    },
    hideElement() {
      this.$store.dispatch('competitors/hideElements', [this.group.id])
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Элемент скрыт' });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: 'Произошла ошибка. Попробуйте позже',
              error: true,
            });
        });
    },
    openDataInput(type) {
      if (type === 'price') {
        this.oldDataPrice = this.dataPrice24h;
        this.newDataPrice = this.dataPrice24h;
        this.showDataPriceInput = true;
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.dataPriceInput.focus();
          }, 300);
        });
      }
      if (type === 'sale') {
        this.oldDataSale = this.dataSale24h;
        this.newDataSale = this.dataSale24h;
        this.showDataSaleInput = true;
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.dataSaleInput.focus();
          }, 300);
        });
      }
    },
    closeEditInput(type) {
      if (type === 'price') {
        this.showDataPriceInput = false;
      }
      if (type === 'sale') {
        this.showDataSaleInput = false;
      }
    },
    saveNewDataPrice(type) {
      if (type === 'price') {
        if (this.newDataPrice === this.oldDataPrice || this.newDataPrice === '' || this.newDataPrice === '0') {
          this.closeEditInput('price');
          return;
        }
        this.savePrice('price');
      }

      if (type === 'sale') {
        if (this.newDataSale === this.oldDataSale || this.newDataSale === '' || this.newDataSale === '0') {
          this.closeEditInput('price');
          return;
        }
        this.savePrice('sale');
      }
    },
    savePrice(type) {
      const productType = 1; // post
      const currentTradeOfferId = this.group.group.products.find((item) => item.type === productType)?.id; // находим объекты с нужным продуктом

      if (currentTradeOfferId) {
        const params = { id: currentTradeOfferId, prices: { } };
        if (type === 'price') {
          params.prices = { price: this.newDataPrice };
        }
        if (type === 'sale') {
          params.prices = { sale: this.newDataSale };
        }
        this.$store.dispatch('group/editTradeOffer', params)
          .then((data) => {
            this.group.group.products = this.group.group.products.map((item) => {
              if (item.id === data.id) {
                return data;
              }
              return item;
            });
            this.dataPrice24h = this.getPostPrice('price');
            this.dataSale24h = this.getPostPrice('sale');
            this.$store.dispatch('app/setSnackBar', { text: 'Стоимость обновлена' });
            this.closeEditInput(type);
          })
          .catch(() => {
            this.closeEditInput(type);
          });
        return;
      }

      const newData = {
        groupIdentity: { id: this.group.group.id, social: this.group.group.social },
        productType,
        productDurationInHours: '24',
      };

      if (type === 'sale') {
        this.$store.dispatch('app/setSnackBar',
          {
            text: 'Сначала необходимо указать прайс',
            error: true,
          });
        this.closeEditInput('sale');
        return;
      }
      if (type === 'price') {
        newData.price = this.newDataPrice;
      }

      this.$store.dispatch('group/setTradeOffer', newData)
        .then((data) => {
          this.group.group.products = data.products;
          this.dataPrice24h = this.getPostPrice('price');
          this.dataSale24h = this.getPostPrice('sale');
          this.$store.dispatch('app/setSnackBar', { text: 'Стоимость обновлена' });
          this.closeEditInput('price');
          this.closeEditInput('sale');
        })
        .catch(() => {
          this.closeEditInput('price');
          this.closeEditInput('sale');
        });
    },
  },
  computed: {
    getTelegaPrice() {
      return `${this.setNumberSpaces(this.group.price)} ${this.getCurrencySymbol}`;
    },
    getCurrencySymbol() {
      return this.$store.getters['app/getCurrencySymbol'];
    },
    getDataPriceWithCurrency() {
      return this.dataSale24h === '—' || this.dataSale24h === '?'
        ? '—'
        : `${this.setNumberSpaces(Math.round(this.dataSale24h))} ${this.getCurrencySymbol}`;
    },
    getDataResultPriceWithCurrency() {
      return this.dataPrice24h === '—' || this.dataPrice24h === '?'
        ? '—'
        : `${this.setNumberSpaces(Math.round(this.dataPrice24h))} ${this.getCurrencySymbol}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.competitor-element {
  display: flex;
  align-items: center;
  min-width: 100%;
  position: sticky;
  background: white;
  top: 0px;
  left: 0px;
  height: 60px;
  border-bottom: 1px solid $primary-group-border;
  position: relative;
  &:hover {
    .competitor-element__hide-icon {
      opacity: 1;
    }
  }
}

.competitor-element__checkbox {
  margin-right: 20px;
}

.competitor-element__hide-icon {
  opacity: 0;
}

.competitor-element__edit-icon {
  position: absolute;
  right: 15px;
  top: 13px;
  opacity: 0;
  transition: 0.1s;
  &_visible {
    opacity: 1;
  }
}

.competitor-element__confirm-icon {
  position: absolute;
  right: 20px;
  top: 13px;
}

.competitor-element__column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 40px;
  border-right: 1px solid $primary-group-border;
  position: relative;
  &_row {
    flex-direction: row;
    @include between-elements {
      margin-right: 10px;
    }
  }
  &_name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: sticky;
    left: 0px;
    padding-left: 10px;
    background: white;
    height: 59px;
    width: 200px;
    z-index: 1;
    @include tablet-screen {
      padding-left: 20px;
      width: 400px;
    }
  }
  &_index {
    align-items: flex-start;
    padding-left: 30px;
  }
  &_price {
    &:hover {
      .competitor-element__edit-icon {
        opacity: 1;
        transition: 0.1s;
        cursor: pointer;
      }
    }
  }
}

.competitor-element__text {
  font-size: 14px;
  &_name {
    font-size: 16px;
    max-width: 90%;
    color: $primary-blue;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &_category {
    display: none;
    @include desktop-screen {
      font-size: 12px;
      color: $primary-lightgray;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }
  }
  &_red {
    font-size: 16px;
    color: $primary-red;
  }
  &_green {
    font-size: 16px;
    color: $primary-green;
  }
}

.competitor-element__avatar {
  display: none;
  @include tablet-screen {
    display: block;
    min-width: 40px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-right: 20px;
    box-sizing: border-box;
    border-radius: 50%;
  }
}

.competitor-element__name-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  padding-right: 40px;
  cursor: pointer;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.competitor-element__status-icon {
  width: 25px;
  height: 25px;
}

.competitor-element__price-input {
  outline: 1px solid $primary-blue;
  padding: 2px;
  border-radius: 5px;
  padding: 2px 10px;
  border: none;
  width: 120px;
  background: white;
  font-size: 14px;
  color: $primary-gray;
  appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

</style>
