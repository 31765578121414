<template>
  <div class="my-groups-screen__nav">
    <div class="my-groups-screen__tabs">
      <span
      v-for="tab in typesOfGroups"
      :key="tab.name"
      class="my-groups-screen__tab"
      :class="{ 'my-groups-screen__tab_selected': tab.value === getSelectedTab }"
      @click="changeTab(tab)">
        {{ $t(tab.name) }}
      </span>
    </div>
    <div>
      <div
      class="my-groups-screen__add-button"
      @click="showAddGroupModal = true">
        Добавить канал
      </div>
    </div>
    <DataModal
    :show-modal="showAddGroupModal"
    @close="showAddGroupModal = false">
      <template #modal-content>
        <AddGroup
        @close="showAddGroupModal = false" />
      </template>
    </DataModal>
  </div>
</template>

<script>
import AddGroup from '@/components/MainGroups/components/NavActions/components/AddGroup.vue';

export default {
  name: 'MyGroupsTabs',
  components: { AddGroup },
  data: () => ({
    typesOfGroups: [
      { value: 'telegram', name: 'Телеграм' },
      { value: 'vk', name: 'Вконтакте' },
      { value: 'instagram', name: 'Инстаграм' },
      { value: 'odnoklassniki', name: 'Одноклассники' },
      { value: 'youtube', name: 'YouTube' },
      { value: 'yandex-zen', name: 'Я.Дзен' },
    ],
    showAddGroupModal: false,
  }),
  methods: {
    changeTab(tab) {
      this.$store.commit('myGroups/setSelectedTypeOfGroups', tab.value);
      this.$emit('loadMyGroups', { reset: true });
    },
  },
  computed: {
    getSelectedTab() {
      return this.$store.getters['myGroups/getSelectedTypeOfGroups'];
    },
  },
};
</script>

<style lang="scss" scoped>
.my-groups-screen__nav {
  margin: 0px 20px;
  @include tablet-screen {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.my-groups-screen__tabs {
  margin-top: 10px;
  @include between-elements {
    margin-right: 15px;
  }
}

.my-groups-screen__tab {
  color: rgba($primary-gray, 0.5);
  font-size: 13px;
  transition: 0.2s;
  cursor: pointer;
  position: relative;
  &_selected {
    color: $primary-blue;
    &:after {
      position: absolute;
      top: 20px;
      left: 0px;
      content: '';
      width: 100%;
      height: 1px;
      background: $primary-blue;
    }
  }
}

.my-groups-screen__add-button {
  margin: 10px auto 0px;
  font-size: 14px;
  padding: 5px 15px;
  color: $primary-white;
  background: $primary-blue;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  @include tablet-screen {
    margin: 0px auto;
  }
}
</style>
