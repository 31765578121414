<template>
  <div class="app-loader">
    <!-- Catalog loader -->
    <div
    v-if="isCatalogLoader"
    class="app-loader__catalog-loader">
      <transition
      :key="currentStep"
      name="fade"
      mode="out-in">
        <div
        :key="currentStep"
        class="app-loader__catalog">
          <img
          :src="require(`./assets/${getCurrentCatalogStep.image}.png`)"
          class="app-loader__catalog-image">
          <div class="app-loader__catalog-textblock">
            <img
            class="app-loader__loader-catalog"
            src="@/assets/svg/spinner-loader.svg">
            <span class="app-loader__catalog-text">{{ getCurrentCatalogStep.text }}</span>
          </div>
        </div>
      </transition>
    </div>

    <!-- Standart loader -->
    <div
    v-else-if="appIsLoaded"
    class="app-loader__standart-loader">
      <img
      class="app-loader__loader"
      src="@/assets/svg/spinner-loader.svg">
    </div>

    <!-- Auth loader -->
    <div
    v-else-if="showBrandLogo"
    class="app-loader__auth-loader">
      <div class="app-loader__loader-block">
        <img
        v-if="brandingLogo"
        class="app-loader__loader app-loader__loader_branding"
        :src="brandingLogo">
        <img
        v-else
        class="app-loader__loader"
        src="@/assets/svg/logos/SJDLoader.svg">
        <img
        v-if="!appIsLoaded"
        class="app-loader__loader-mini"
        :class="showMiniLoader && 'app-loader__loader-mini_show'"
        src="@/assets/svg/spinner-loader.svg">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLoader',
  props: {
    isCatalogLoader: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showMiniLoader: false,
    catalogLoaderSteps: [
      { id: 1, text: 'Загружаем базу паблишеров', image: 'publishers' },
      { id: 2, text: 'Обновляем стоимость', image: 'price' },
      { id: 3, text: 'Актуализируем статистику', image: 'statistic' },
      { id: 4, text: 'Подгружаем соц. демографию', image: 'demography' },
    ],
    currentStep: 1,
    changeCatalogStep: null,
  }),
  beforeDestroy() {
    clearInterval(this.changeCatalogStep);
  },
  created() {
    if (this.catalogLoaderSteps) {
      this.changeCatalogStep = setInterval(() => {
        if (this.currentStep === 4) {
          this.currentStep = 1;
        }
        ++this.currentStep;
      }, 4000);
    }
    setTimeout(() => {
      this.showMiniLoader = true;
    }, 3000);
  },
  computed: {
    appIsLoaded() {
      return this.$store.getters['app/getAppIsLoaded'];
    },
    getCurrentCatalogStep() {
      return this.catalogLoaderSteps[this.currentStep - 1];
    },
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
    isClientAgency() {
      return this.$store.getters['user/getUserInfo']?.asClientAgency;
    },
    brandingLogo() {
      const logo = this.getUserInfo?.asEmployerAgency?.brandLogo || this.isClientAgency?.brandLogo || false;
      const url = 'https://data-api.socialjet.pro';
      return logo
        ? `${url}${logo}`
        : false;
    },
    showBrandLogo() {
      return this.getUserInfo?.email !== 'jan-clod-one-dam@yandex.ru';
    },
  },
};
</script>

<style lang="scss" scoped>
.app-loader {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  left:0;
  right: 0;
  z-index: 100;
  bottom: 0;
  user-select: none;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-loader__catalog-loader {
  background: red;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
}

.app-loader__standart-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
}

.app-loader__auth-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.app-loader__catalog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-loader__catalog-image {
  max-width: 70vw;
  @include desktop-screen {
    max-width: 100%;
  }
}

.app-loader__loader {
  min-width: 200px;
  max-width: 200px;
  height: 200px;
  animation: pulse 1.2s ease-in-out infinite;
  &_branding {
    height: auto;
    min-width: 200px;
    max-width: 300px;
  }
}

.app-loader__loader-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
  position: relative;
}

.app-loader__loader-mini {
  user-select: none;
  position: absolute;
  top: 120px;
  width: 120px;
  height: 120px;
  opacity: 0;
  transition: 1s;
  &_show {
    opacity: 0.2;
  }
}

.app-loader__catalog-textblock {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.app-loader__catalog-text {
  color: $primary-blue;
  font-size: 14px;
  @include tablet-screen {
    font-size: 24px;
  }
}

.app-loader__loader-catalog {
  width: 50vw;
  max-width: 200px;
  top: -36px;
  left: -85px;
}

@keyframes pulse {
  from {
    transform: scale(1);
    opacity: 0.3;
  }
  50% {
    transform: scale(.98);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0.3;
  }
}

// fade
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s linear;
}
</style>
