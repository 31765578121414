<template>
  <div class="groups-stats">
    <div class="group-stats__container group-stats__container__no-buttons">
      <span
      class="groups-stats__text">{{ getCountSelectedGroups }}</span>
      <div
      v-if="!isSend && !isCompilation && mode !== 'noauth'"
      class="groups-stats__text groups-stats__text_touch"
      @click="toggleShowSelectedGroups">
        {{ getTextForSelected }}
      </div>
    </div>
    <div
    class="group-stats__container"
    :class="mode === 'noauth' && 'group-stats__container_no-buttons'">
      <!-- Sell -->
      <span class="groups-stats__text">{{ getTotalSelectedPrice }}</span>
      <!-- Price -->
      <span
      v-if="isAdmin"
      class="groups-stats__text">{{ getTotalSelectedCostPrice }}</span>
      <!-- Buy -->
      <span
      v-if="isAdmin"
      class="groups-stats__text">{{ getTotalSelectedBuyPrice }}</span>
    </div>
    <div
    class="group-stats__container"
    :class="mode === 'noauth' && 'group-stats__container_no-buttons'">
      <span class="groups-stats__text">{{ getTotalSelectedSubscribers }}</span>
      <span class="groups-stats__text">{{ getTotalSelectedViews }}</span>
      <span class="groups-stats__text">{{ getTotalCpm }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GroupsStats',
  props: {
    selectedGroups: {
      type: Array,
      default: () => [],
    },
    isSend: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: null,
    },
    isCompilation: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleShowSelectedGroups() {
      // FUTURE SELECTED GROUPS
      // тут переключаем выбранные каналы на все каналы
      if (!this.isShowSelectedGroups) {
        this.$store.commit('collections/setGroupsSelectedData', this.selectedGroups);
        this.$store.commit('collections/setShowSelectedGroups', true);
        return;
      }
      this.$store.commit('collections/setShowSelectedGroups', false);
    },
  },
  computed: {
    getCountSelectedGroups() {
      if ((!this.selectedGroups.length || this.getTriggerForSelectedAllGroups) && this.getCurrentCollectionStats) {
        return `${this.$t('Channels')}: ${this.getCurrentCollectionStats.countSelectedGroups}`;
      }
      return `${this.$t('Channels selected')}: ${this.selectedGroups.length}`;
    },
    getTotalSelectedPrice() {
      // (если не выбраны группы или активирована кнопка выбрать все) и есть данные о подборке
      if ((!this.selectedGroups.length || this.getTriggerForSelectedAllGroups) && this.getCurrentCollectionStats) {
        const totalSell = Math.round(this.getCurrentCollectionStats?.sumPostPriceSell || 0);
        const sumWithNDS = totalSell > 0 ? `(${this.setNumberSpaces(totalSell * ((1 + 0.20)))} ${this.$t('nds')})` : '';
        return this.mode === 'noauth'
          ? `${this.$t('Forecast budget')}: ${this.setNumberSpaces(totalSell)} ${sumWithNDS}`
          : `${this.$t('price_sell')}: ${this.setNumberSpaces(totalSell)} ${sumWithNDS}`;
      }

      const totalSell = Math.round(this.selectedGroups.reduce((prev, cur) => {
        const currentOffer = cur.products.find((item) => item.type === 1);
        const currentPrice = currentOffer
          ? currentOffer.tradeOffersChain?.currentTradeOffer?.resultPrice || 0
          : 0;
        return prev + currentPrice;
      }, 0));

      const sumWithNDS = totalSell > 0 ? `(${this.setNumberSpaces(totalSell * ((1 + 0.20)))} ${this.$t('nds')})` : '';

      return this.mode === 'noauth'
        ? `${this.$t('Forecast budget')}: ${this.setNumberSpaces(totalSell)} ${sumWithNDS}`
        : `${this.$t('price_sell')}: ${this.setNumberSpaces(totalSell)} ${sumWithNDS}`;
    },
    getTotalSelectedCostPrice() {
      if ((!this.selectedGroups.length || this.getTriggerForSelectedAllGroups) && this.getCurrentCollectionStats) {
        return `${this.$t('price_sale')}: ${this.setNumberSpaces(Math.round(this.getCurrentCollectionStats.sumPostPriceSale))}`;
      }

      const totalSale = Math.round(this.selectedGroups.reduce((prev, cur) => {
        const currentOffer = cur.products.find((item) => item.type === 1);
        const currentPrice = currentOffer
          ? currentOffer.tradeOffersChain.tradeOffers.find((item) => item.buyerType === 3)?.sale || 0
          : 0;
        return prev + currentPrice;
      }, 0));

      return `${this.$t('price_sale')}: ${this.setNumberSpaces(totalSale)}`;
    },
    getTotalSelectedBuyPrice() {
      if ((!this.selectedGroups.length || this.getTriggerForSelectedAllGroups) && this.getCurrentCollectionStats) {
        return `${this.$t('price_buy')}: ${this.setNumberSpaces(Math.round(this.getCurrentCollectionStats.sumPostPriceBuy))}`;
      }
      const totalBuy = Math.round(this.selectedGroups.reduce((prev, cur) => {
        const currentOffer = cur.products.find((item) => item.type === 1);
        const currentPrice = currentOffer
          ? currentOffer.tradeOffersChain.tradeOffers.find((item) => item.buyerType === 3)?.resultPrice || 0
          : 0;
        return prev + currentPrice;
      }, 0));

      return `${this.$t('price_buy')}: ${this.setNumberSpaces(totalBuy)}`;
    },
    getTotalCpm() {
      if ((!this.selectedGroups.length || this.getTriggerForSelectedAllGroups) && this.getCurrentCollectionStats) {
        return `Средний CPM: ${this.setNumberSpaces(Math.round(this.getCurrentCollectionStats.averageCpm || 0))}`;
      }
      const average = this.selectedGroups.length
        ? this.selectedGroups.reduce((prev, cur) => prev + +(cur.cpm || 0), 0) / this.selectedGroups.length
        : 0;

      return `Средний CPM: ${this.setNumberSpaces(Math.round(average))}`;
    },
    getTotalSelectedSubscribers() {
      if ((!this.selectedGroups.length || this.getTriggerForSelectedAllGroups) && this.getCurrentCollectionStats) {
        return `${this.$t('subscribers')}: ${this.setNumberSpaces(Math.round(this.getCurrentCollectionStats.sumSubscribersCount))}`;
      }
      return `${this.$t('subscribers')}: ${this.setNumberSpaces(this.selectedGroups.reduce((prev, cur) => prev + +(cur.subscribersStatistic?.count || 0), 0))}`;
    },
    getTotalSelectedViews() {
      if ((!this.selectedGroups.length || this.getTriggerForSelectedAllGroups) && this.getCurrentCollectionStats) {
        return `${this.$t('views')}: ${this.setNumberSpaces(Math.round(this.getCurrentCollectionStats.sumViewsLast24hCount))}`;
      }
      return `${this.$t('views')}: ${this.setNumberSpaces(this.selectedGroups.reduce((prev, cur) => {
        if (cur.social === 'mave') {
          return prev + +(cur.listeningStatistic?.averagePerMonth || 0);
        }
        return prev + +(cur.viewsStatistic?.countLast24h || 0);
      }, 0))}`;
    },
    getTextForSelected() {
      return this.isShowSelectedGroups ? this.$t('Show all channels') : this.$t('Show selected');
    },
    isShowSelectedGroups() {
      return this.$store.getters['collections/getShowSelectedGroups'];
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    getCurrentCollectionStats() {
      return this.getSelectedFolder
        ? this.$store.getters['compilations/getFolderCompilationSummary']
        : this.$store.getters['compilations/getCurrentCompilationSummary'];
    },
    getTriggerForSelectedAllGroups() {
      return this.$store.getters['compilations/getTriggerForSelectedAllGroups'];
    },
    getSelectedFolder() {
      return this.$store.getters['compilations/getSelectedFolder'];
    },
  },
};
</script>

<style lang="scss" scoped>
.groups-stats {
  display: block;
  @include between-elements {
    margin-right: 15px;
    @include max-screen {
      margin-right: 45px;
    }
  }
  @include tablet-screen {
    display: flex;
  }
}

.group-stats__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_no-buttons {
    flex-direction: column;
    @include tablet-screen {
      flex-direction: row;
    }
    @include between-elements {
      margin-right: 25px;
    }
  }
}

.groups-stats__text {
  color: $primary-white;
  font-size: 12px;
  line-height: 20px;
  @include desktop-screen {
    font-size: 15px;
  }
  &_touch {
    cursor: pointer;
    border-bottom: 1px solid $primary-white;
  }
}
</style>
