<template>
  <div class="edit-client-modal">
    <span class="edit-client-modal__text edit-client-modal__text_title">Редактировать клиента</span>
    <div class="edit-client-modal__settings-block">
      <div class="edit-client-modal__name-inputs">
        <div class="edit-client-modal__input-container">
          <span
          class="edit-client-modal__text edit-client-modal__text_light"
          :class="companyName.error && 'edit-client-modal__text_error'">
            {{ companyName.error ? companyName.errortext : companyName.name }}
          </span>
          <DataInput
          v-model="companyName.value"
          :placeholder="companyName.placeholder"
          :error="companyName.error"
          class="edit-client-modal__input" />
        </div>
        <div class="edit-client-modal__input-container">
          <span
          class="edit-client-modal__text edit-client-modal__text_light"
          :class="baseCommissionInPercent.error && 'edit-client-modal__text_error'">
            {{ baseCommissionInPercent.error ? baseCommissionInPercent.errortext : baseCommissionInPercent.name }}
          </span>
          <DataInput
          v-model="baseCommissionInPercent.value"
          input-type="number"
          maxlength="2"
          :error="baseCommissionInPercent.error"
          :placeholder="baseCommissionInPercent.placeholder"
          class="edit-client-modal__input" />
        </div>
      </div>
      <div class="edit-client-modal__settings-inputs">
        <div
        v-for="item in profileBase"
        :key="item.name"
        class="edit-client-modal__input-container">
          <span
          class="edit-client-modal__text edit-client-modal__text_light"
          :class="item.error && 'edit-client-modal__text_error'">
            {{ item.error ? item.errortext : item.name }}
          </span>
          <DataInput
          v-model="item.value"
          :placeholder="item.placeholder"
          :error="item.error"
          class="edit-client-modal__input" />
        </div>
      </div>
      <div class="edit-client-modal__toggle">
        <DataToggle v-model.trim="access" />
        <span class="add-client__text">{{ access ? 'Открыть доступ' : 'Закрыть доступ' }}</span>
      </div>
      <div class="edit-client-modal__buttons">
        <DataButton
        text="Обновить"
        @handler="saveChanges" />
        <DataButton
        type="outlined"
        text="Отменить"
        @handler="$emit('close')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditClientModal',
  props: {
    client: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    access: null,
    companyName: {
      name: 'Название компании',
      value: '',
      placeholder: 'Введите название компании',
      errortext: 'Укажите название компании',
      error: false,
    },
    baseCommissionInPercent: {
      name: 'Комиссия',
      placeholder: '%',
      value: '',
      errortext: 'Укажите комиссию',
      error: false,
    },
    profileBase: {
      name: {
        name: 'ФИО', placeholder: 'Введите имя', field: 'name', value: '', error: false, errortext: 'Укажите ФИО',
      },
      telegram: {
        name: 'Телеграм', placeholder: 'Телеграм', field: 'telegram', value: '',
      },
      phoneNumber: {
        name: 'Телефон', placeholder: 'Телефон', field: 'phoneNumber', value: '',
      },
      email: {
        name: 'Почта', placeholder: 'Почта', field: 'email', value: '', error: false, errortext: 'Укажите корректную почту',
      },
      password: {
        name: 'Пароль', placeholder: 'Укажите пароль', field: 'password', value: '', error: false, errortext: 'Новый пароль не менее 6 символов',
      },
    },
  }),
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.companyName.value = this.client?.companyName || '';
      this.baseCommissionInPercent.value = this.client?.baseCommissionInPercent || '';

      this.profileBase.name.value = this.client.name || '';
      this.profileBase.telegram.value = this.client.telegram || '';
      this.profileBase.email.value = this.client.email || '';
      this.profileBase.phoneNumber.value = this.client.phoneNumber || '';

      this.access = this.client.isAccessToAgency;
    },
    checkIsValidForm() {
      this.companyName.error = this.companyName.value.length === 0;
      this.profileBase.name.error = this.profileBase.name.value.length === 0;
      this.profileBase.password.error = this.profileBase.password.value.length
        ? this.profileBase.password.value.length < 6
        : false;
      if (this.companyName.error || this.profileBase.name.error || this.profileBase.password.error) {
        return false;
      }
      return true;
    },
    saveChanges() {
      const formIsInvalid = !this.checkIsValidForm();
      if (formIsInvalid) return;

      const userData = {};
      Object.keys(this.profileBase).forEach((item) => {
        if (this.profileBase[item].value) {
          userData[item] = this.profileBase[item].value;
        }
      });
      userData.id = this.client.id;
      userData.companyName = this.companyName.value;
      userData.baseCommissionInPercent = this.baseCommissionInPercent.value;

      this.$store.dispatch('clients/updateClientAgency', userData)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Успешно. Данные обновлены' });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка при обновлении данных', error: true });
        })
        .finally(() => {
          this.$store.dispatch('clients/setAccessClientAgency', { id: this.client.id, isAccess: `${this.access}` });
        });
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-client-modal__settings-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 20px;
  background: white;
  margin-top: 30px;
  max-width: 400px;
  @include between-elements {
    margin-bottom: 32px;
  }
}

.edit-client-modal__settings-inputs {
  width: 100%;
  @include between-elements {
    margin-bottom: 20px;
  }
  @include desktop-screen {
    min-width: 400px;
  }
}

.edit-client-modal__text {
  font-size: 14px;
  color: #232323;
  &_title {
    font-size: 24px;
    font-weight: 600;
  }
  &_light {
    color: #4F4F4F;
    margin-bottom: 10px;
  }
  &_error {
    color: $primary-red;
  }
}

.edit-client-modal__input-container {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.edit-client-modal__buttons {
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}

.edit-client-modal__name-inputs {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
}

.edit-client-modal__toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 20px;
  }
}
</style>
