<template>
  <div class="group-redactor">
    <span class="group-redactor__text group-redactor__text_light">
      {{ getRedactor }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'GroupRedactor',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    redactor: {
      type: String,
      default: null,
    },
    disableEditor: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getRedactor() {
      const userRedactor = this.group.userUpdater?.email;
      if (!userRedactor && !this.redactor) return '—';
      return userRedactor || this.redactor;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-redactor {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 180px;
  min-height: 50px;
  position: relative;
}

.group-redactor__text {
  font-size: 14px;
  padding: 15px 10px;
  color: $primary-gray;
  text-align: center;
  word-break: break-all;
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}
</style>
