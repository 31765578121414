import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);

const campaignsSearchParamsTemplate = {
  startDateTimeFrom: null,
  endDateTimeFrom: null,
  status: null,
  requestPlacementResponsibleUserId: null,
  ownerAgencyId: null,
};

export default {
  namespaced: true,
  state: {
    campaignsData: [],
    loadParams: {
      'page-size': 40,
      page: 1,
    },
    sortBy: null,
    sortDir: null,
    totalPageCounts: null,
    campaignsOwnerParams: {},
    campaignsSearchParams: { ...campaignsSearchParamsTemplate },
    campaignsSearchParamsTemplate: { ...campaignsSearchParamsTemplate },
  },
  getters: {
    getCampaignsData: (state) => state.campaignsData,
    getTotalPageCounts: (state) => state.totalPageCounts,
    getIsLastPage: (state) => state.loadParams.page === +state.totalPageCounts + 1,
    // searchParams
    getCampaignsSearchParams: (state) => state.campaignsSearchParams,
    getCampaignsSearchParamsTemplate: (state) => state.campaignsSearchParamsTemplate,
    // ownerParams
    getCampaignsOwnerParams: (state) => state.campaignsOwnerParams,
  },
  mutations: {
    setCampaignsData: (state, value) => { state.campaignsData = value; },
    setTotalPageCounts: (state, value) => { state.totalPageCounts = value; },
    setLoadPage: (state, value) => { state.loadParams.page = value; },
    // searchParams
    setCampaignsSearchParams: (state, value) => { state.campaignsSearchParams = { ...value }; },
    resetCampaignsSearchParams: (state) => { state.campaignsSearchParams = { ...campaignsSearchParamsTemplate }; },
    // ownerParams
    setCampaignsOwnerParams: (state, value) => { state.campaignsOwnerParams = { ...value }; },
    resetCampaignsOwnerParams: (state) => { state.campaignsOwnerParams = { }; },
  },
  actions: {
    loadCampaignsData({ state, commit }, params = { reset: false }) {
      commit('app/setAppIsLoading', true, { root: true });
      if (params.reset) {
        commit('setCampaignsData', []);
        commit('setLoadPage', 1);
        commit('setTotalPageCounts', null);
      }
      return api.get('/campaign', {
        params: {
          sortBy: state.sortBy, sortDirection: state.sortDir, ...state.loadParams, ...state.campaignsSearchParams, ...state.campaignsOwnerParams,
        },
      })
        .then((response) => {
          const campaignsData = params.reset ? response.data.data : [...state.campaignsData, ...response.data.data];
          commit('setCampaignsData', campaignsData);
          commit('setTotalPageCounts', response.headers['x-pagination-page-count']);
          commit('setLoadPage', state.loadParams.page + 1);
          commit('app/setAppIsLoading', false, { root: true });
          return Promise.resolve(campaignsData);
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- loadCampaignsData --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Удаление/Архивирование кампании
    deleteCampaign({ state, commit }, id) {
      return api.patch(`/campaign/${id}/archive`)
        .then(() => {
          const newCampaignsData = state.campaignsData.filter((item) => item.id !== id);
          commit('setCampaignsData', newCampaignsData);
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- deleteCampaign --; Error -', error);
          return Promise.reject(error);
        });
    },
  },
};
