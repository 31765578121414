<template>
  <div class="mave-geo">
    <span class="mave-geo__text mave-geo__text_title">География</span>

    <!-- tabs -->
    <div class="mave-geo__tabs">
      <span
      v-for="tab in tabs"
      :key="tab.name"
      class="mave-geo__tab"
      :class="selectedTab.name === tab.name && 'mave-geo__tab_selected'"
      @click="changeSelectedTab(tab)">
        {{ tab.name }}
      </span>
    </div>

    <!-- Список -->
    <div
    v-if="selectedTab.value === 'countries' && countries.length"
    class="mave-geo__elements">
      <div
      v-for="country in countries"
      :key="country.name"
      class="mave-geo__element">
        <div class="mave-geo__element-name">
          <!-- <div
          class="mave-geo__element-indicator"
          :style="{'background-color': country.color}" /> -->

          <span class="mave-geo__text mave-geo__text_element">{{ country.name }}</span>
        </div>
        <span class="mave-geo__text mave-geo__text_blue">{{ country.label }}</span>
      </div>
    </div>

    <div
    v-else-if="selectedTab.value === 'cities' && cities.length"
    class="mave-geo__elements">
      <div
      v-for="(city, index) in cities"
      :key="city.name + index"
      class="mave-geo__element">
        <div class="mave-geo__element-name">
          <!-- <div
          class="mave-geo__element-indicator"
          :style="{'background-color': country.color}" /> -->

          <span class="mave-geo__text mave-geo__text_element">{{ city.name }}</span>
        </div>
        <span class="mave-geo__text mave-geo__text_blue">{{ city.label }}</span>
      </div>
    </div>

    <!-- Данных нет -->
    <div
    v-else
    class="mave-geo__empty">
      {{ emptyText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'MaveGeo',
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    selectedTab: null,
    tabs: [
      { name: 'Страны', value: 'countries' },
      { name: 'Города', value: 'cities' },
    ],
    countries: [],
    cities: [],
  }),
  created() {
    // eslint-disable-next-line prefer-destructuring
    this.selectedTab = this.tabs[0];
  },
  mounted() {
    this.getData();
  },
  methods: {
    changeSelectedTab(tab) {
      this.selectedTab = tab;
    },
    getData() {
      // Страны
      if (!this.group.subscribersStatistic.countries) {
        this.countries = [];
      } else {
        this.countries = this.group.subscribersStatistic.countries.map((item, index) => ({
          name: `${this.$t(item.location)}`,
          label: `${item.value ? `${Math.round(item.value)}%` : '0%'}`,
          percent: item.value,
          value: item.location,
          index,
        })).filter((item) => item.name);
      }
      // Города
      if (!this.group.subscribersStatistic.cities) {
        this.cities = [];
      } else {
        this.cities = this.group.subscribersStatistic.cities.map((item, index) => ({
          name: `${this.$t(item.location)}`,
          label: `${item.value ? `${Math.round(item.value)}%` : '0%'}`,
          percent: item.value,
          value: item.location,
          index,
        })).filter((item) => item.name);
      }
    },
  },
  computed: {
    emptyText() {
      return this.selectedTab.value === 'countries'
        ? 'Нет данных по странам'
        : 'Нет данных по городам';
    },
  },
};
</script>

<style lang="scss" scoped>
.mave-geo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  padding: 32px 32px 45px;
  border-radius: 20px;
  box-shadow: 0px 4px 23px rgba(0, 174, 232, 0.07);
  min-height: 550px;
  max-height: 550px;

  .mave-geo__text {
    font-size: 14px;
    &_element {
      color: $primary-gray;
    }
    &_title {
      font-size: 20px;
      font-weight: 700;
    }
    &_blue {
      color: $primary-blue;
      font-weight: bold;
    }
  }

  .mave-geo__tabs {
    margin: 20px 0px;
    display: flex;
    justify-content: flex-start;
    @include between-elements {
      margin-right: 20px;
    }
  }

  .mave-geo__tab {
    cursor: pointer;
    padding: 0px 5px 10px;
    &_selected {
      border-bottom: 3px solid $primary-blue;
    }
  }

  .mave-geo__empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-gray;
  }

  .mave-geo__elements {
    height: 100%;
    overflow: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-right: 10px;
    @include between-elements {
      margin-bottom: 20px;
    }
  }

  .mave-geo__element {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid $primary-group-border;
  }
}
</style>
