<template>
  <div
  class="group-price">
    <div
    class="group-price__content pointer"
    @click="openMenu">
      <div
      v-if="group.withoutAd"
      class="group-price__without-ad">
        <span class="group-price__text group-price__text_without-ad">
          {{ getMainLabel ? getMainLabel : 'Нет данных' }}
        </span>
      </div>
      <div
      v-else
      class="group-price__post-container">
        <div class="group-price__post">
          <span
          v-if="!group.withoutAd"
          class="group-price__text group-price__text_light">{{ getLabelTitle }}</span>
          <div class="group-price__post-ceil">
            <span
            :class="!getMainLabel && 'group-price__text_empty-label'"
            class="group-price__text group-price__text_price">
              {{ getMainLabel ? getMainLabel : 'Нет данных' }}
            </span>
          </div>
        </div>
        <div class="group-price__post-icon">
          <div class="group-price__post-icon-circle">
            <DataIcon
            name="expand_more"
            :size="20"
            class="group-price__chevron-price" />
          </div>
        </div>
      </div>
    </div>

    <!-- MENU -->

    <transition name="fade">
      <div
      v-if="showMenu"
      v-click-outside="onClickOutside"
      class="group-price__menu">
        <span class="group-price__text">{{ getEditorLabel }}</span>

        <!-- MAVE -->
        <div
        v-if="selectedTypeOfGroups === 'mave'"
        class="group-price__price-container group-price__price-container_mave">
          <div
          class="group-price__without-price group-price__without-price_mave"
          :style="isAdmin ? 'height: 40px' : 'height: 20px'">
            <div class="group-price__price-item">
              <span class="group-price__text">
                Цена:
              </span>
              <span
              v-if="showPriceInput !== selectedPriceType"
              class="group-price__text group-price__text_price"
              @click.stop="openPriceInput">
                {{ getPriceWithoutVariant() }}
              </span>
              <input
              v-if="showPriceInput === selectedPriceType"
              ref="priceInput"
              v-model="inputPrice"
              class="group-price__price-input"
              type="number"
              @keyup.enter="savePrice">
              <DataIcon
              v-if="isAdmin && priceType !== 'sell'"
              :name="showPriceInput === selectedPriceType ? 'done' : 'edit'"
              class="group-price__icon"
              :class="showPriceInput === selectedPriceType ? 'group-price__icon_save' : ''"
              :size="14"
              pointer
              @click.native.stop="showPriceInput === selectedPriceType ? savePrice() : openPriceInput()" />
            </div>
            <span
            v-if="isAdmin"
            class="group-price__text group-price__text_updater">{{ getPriceUpdater() }}</span>
          </div>
        </div>

        <!-- NOT MAVE -->
        <div
        v-else
        class="group-price__price-container">
          <div class="group-price__price-radios">
            <div
            v-for="type in getPriceTypes"
            :key="type.name"
            class="group-price__price-radio"
            @click="changePriceType(type)">
              <DataRadio :checked="selectedPriceType === type.name" />
              <span class="price-switcher__text">
                {{ $t(type.name) }}
              </span>
            </div>
          </div>

          <!-- Вывод цен для вариантов -->
          <div
          v-if="getPriceVariants"
          class="group-price__prices">
            <div
            v-for="variant in getPriceVariants"
            :key="variant.name"
            :class="isAdmin && priceType !== 'sell' ? 'pointer' : ''"
            class="group-price__price"
            :style="isAdmin ? 'height: 40px' : 'height: 20px'">
              <div class="group-price__price-item">
                <span class="group-price__text">{{ $t(variant.name) }}</span>
                <span
                v-if="showPriceInput !== variant.name"
                class="group-price__text group-price__text_price"
                @click="openPriceInput(variant)">
                  {{ getPriceWithVariant(variant.value) }}
                </span>
                <input
                v-if="showPriceInput === variant.name"
                ref="priceInput"
                v-model="inputPrice"
                class="group-price__price-input"
                type="number"
                @keyup.enter="saveVariantPrice(variant)">
                <DataIcon
                v-if="isAdmin && priceType !== 'sell'"
                :name="showPriceInput === variant.name ? 'done' : 'edit'"
                class="group-price__icon"
                :class="showPriceInput === variant.name ? 'group-price__icon_save' : ''"
                :size="14"
                pointer
                @click.native.stop="showPriceInput === variant.name ? saveVariantPrice(variant) : openPriceInput(variant)" />
              </div>
              <span
              v-if="isAdmin"
              class="group-price__text group-price__text_updater">{{ getPriceUpdater(variant.value) }}</span>
            </div>
          </div>

          <!-- Вывод единственной цены без варианта -->
          <div
          v-else
          class="group-price__without-price"
          :style="isAdmin ? 'height: 40px' : 'height: 20px'"
          @click.stop="showPriceInput !== selectedPriceType && openPriceInput()">
            <div class="group-price__price-item">
              <span class="group-price__text">
                Цена:
              </span>
              <span
              v-if="showPriceInput !== selectedPriceType"
              class="group-price__text group-price__text_price">
                {{ getPriceWithoutVariant() }}
              </span>
              <input
              v-if="showPriceInput === selectedPriceType"
              ref="priceInput"
              v-model="inputPrice"
              class="group-price__price-input"
              type="number"
              @keyup.enter="savePrice">
              <DataIcon
              v-if="isAdmin && priceType !== 'sell'"
              :name="showPriceInput === selectedPriceType ? 'done' : 'edit'"
              class="group-price__icon"
              :class="showPriceInput === selectedPriceType ? 'group-price__icon_save' : ''"
              :size="14"
              pointer
              @click.native.stop="showPriceInput === selectedPriceType ? savePrice() : openPriceInput()" />
            </div>
            <span
            v-if="isAdmin"
            class="group-price__text group-price__text_updater">{{ getPriceUpdater() }}</span>
          </div>
        </div>

        <GroupPriceChart
        v-if="!loadingHistory && priceData.length"
        class="group-price__chart"
        :chart-data="priceData" />

        <div
        v-if="!loadingHistory && historyData.length && !isCompilation"
        class="group-price__history">
          <span class="group-price__text">
            Журнал изменений цены
          </span>
          <div
          v-for="data in historyData"
          :key="data.id"
          class="group-price__history-element"
          :class="isAdmin && 'group-price__history-element_user'">
            <span class="group-price__text group-price__text_light">
              {{ data.fulldate }}
            </span>
            <span class="group-price__text group-price__text_small">
              {{ getHistoryValue(data.value) }}
            </span>
            <span
            v-if="isAdmin"
            style="text-align: right;"
            class="group-price__text group-price__text_light">
              {{ data.user }}
            </span>
          </div>
        </div>
        <div
        v-if="!loadingHistory && !historyData.length && !isCompilation"
        class="group-price__history group-price__history_empty">
          <span class="group-price__text group-price__text_light">
            Нет данных об изменении цены
          </span>
        </div>

        <div
        v-if="loadingHistory"
        class="group-price__loading">
          <img
          class="group-price__loading-img"
          src="@/assets/images/rocket.gif">
          <span class="group-price__text group-price__text_loading">Загрузка истории...</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import uniqBy from 'lodash/uniqBy';
import socialsPriceTypes from '@/assets/js/socialsPriceTypes';
import GroupPriceChart from './GroupPriceChart.vue';

export default {
  name: 'GroupPrice',
  components: { GroupPriceChart },
  props: {
    priceType: {
      type: String,
      default: null,
    },
    group: {
      type: Object,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    selectedTypeOfGroups: {
      type: [String, Number],
      default: null,
    },
    products: {
      type: Array,
      default: null,
    },
    isCompilation: {
      type: Boolean,
      default: false,
    },
    disableEditor: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    buyerTypes: [
      { id: 1, type: 'sale' },
      { id: 2, type: 'sell' },
      { id: 3, type: 'buy' },
    ],
    showMenu: false,
    selectedPriceType: 'postPrice',
    selectedPriceVariant: '24',
    loadingHistory: false,
    showPriceInput: false,
    inputPrice: '',
    oldPrice: null,
    historyData: [],
    priceData: [],
    productsId: {
      postPrice: 1,
      storyPrice: 2,
      reelPrice: 3,
      streamPrice: 4,
      integrationPrice: 5,
      circlePrice: 6,
      nonStandartPrice: 7,
    },
  }),
  methods: {
    getPriceWithoutVariant() {
      try {
        const currentPriceId = this.productsId[this.selectedPriceType];
        const currentProducts = this.products.filter((item) => item.type === currentPriceId); // находим объекты с нужным продуктом

        if (!currentProducts.length) {
          return '—';
        }
        const currentProduct = currentProducts[0];
        const buyerType = this.buyerTypes.find((item) => item.type === this.priceType); // какую цену ищем, sell или buy или sale
        // Для колонки цена берем цену из currentTradeoffer
        let resultPrice;

        if (buyerType.type === 'sell') { // цена
          resultPrice = currentProduct?.tradeOffersChain?.currentTradeOffer?.resultPrice || null;
        }
        if (buyerType.type === 'buy') { // прайс
          resultPrice = currentProduct?.tradeOffersChain.tradeOffers.find((item) => item.buyerType === 3)?.resultPrice;
        }
        if (buyerType.type === 'sale') { // себес
          resultPrice = currentProduct?.tradeOffersChain.tradeOffers.find((item) => item.buyerType === 3)?.sale;
        }

        if (!resultPrice && +resultPrice !== 0) {
          return '—';
        }
        return `${this.setNumberSpaces(Math.round(resultPrice))} ${this.getCurrencySymbol}`;
      } catch {
        return '?';
      }
    },
    getPriceWithVariant(variant) {
      try {
        const currentPriceId = this.productsId[this.selectedPriceType];
        const currentProducts = this.products.filter((item) => item.type === currentPriceId); // находим объекты с нужным продуктом
        if (!currentProducts.length) {
          return '—';
        }
        const currentProduct = currentProducts.find((item) => {
          if (item.isVkMarket) {
            return String(variant) === 'isVkMarket';
          }
          if (item.isFixedInTop) {
            return String(variant) === 'isFixedInTop';
          }
          if (item.durationInHours) {
            return String(item.durationInHours) === String(variant);
          }
          if (item.circleDurationInSeconds) {
            return String(item.circleDurationInSeconds) === String(variant);
          }
          return item;
        });
        const buyerType = this.buyerTypes.find((item) => item.type === this.priceType); // какую цену ищем, sell или buy или sale

        if (!currentProduct) {
          return '—';
        }

        // Для колонки цена берем цену из currentTradeoffer
        let resultPrice;

        if (buyerType.type === 'sell') { // Колонка цена
          resultPrice = currentProduct?.tradeOffersChain?.currentTradeOffer?.resultPrice || null;
        }
        if (buyerType.type === 'buy') { // Колонка прайс
          resultPrice = currentProduct?.tradeOffersChain.tradeOffers.find((item) => item.buyerType === 3)?.resultPrice;
        }
        if (buyerType.type === 'sale') { // Колонка себес
          resultPrice = currentProduct?.tradeOffersChain.tradeOffers.find((item) => item.buyerType === 3)?.sale;
        }

        if (!resultPrice && +resultPrice !== 0) {
          return '—';
        }
        return `${this.setNumberSpaces(Math.round(resultPrice))} ${this.getCurrencySymbol}`;
      } catch {
        return '?';
      }
    },
    getPriceUpdater(variant) {
      try {
        const currentPriceId = this.productsId[this.selectedPriceType];
        const currentProducts = this.products.filter((item) => item.type === currentPriceId); // находим объекты с нужным продуктом
        if (!currentProducts.length) {
          return 'Нет данных по дате обновления';
        }

        let currentProduct;
        if (variant) {
          currentProduct = currentProducts.find((item) => {
            if (item.isVkMarket) {
              return String(variant) === 'isVkMarket';
            }
            if (item.isFixedInTop) {
              return String(variant) === 'isFixedInTop';
            }
            if (item.durationInHours) {
              return String(item.durationInHours) === String(variant);
            }
            if (item.circleDurationInSeconds) {
              return String(item.circleDurationInSeconds) === String(variant);
            }
            return item;
          });
        } else {
          // eslint-disable-next-line prefer-destructuring
          currentProduct = currentProducts[0];
        }

        const buyerType = this.buyerTypes.find((item) => item.type === this.priceType); // какую цену ищем, sell или buy или sale

        if (!currentProduct) {
          return '—';
        }

        // Для колонки цена берем цену из currentTradeoffer
        if (buyerType.type === 'sell') { // Колонка цена
          const updaterName = currentProduct?.tradeOffersChain?.currentTradeOffer?.updaterUser || null;
          const updatedDate = currentProduct?.tradeOffersChain?.currentTradeOffer?.updatedAt?.date
            ? this.moment(currentProduct?.tradeOffersChain?.currentTradeOffer?.updatedAt?.date).format('LL')
            : null;
          if (!updaterName && !updatedDate) {
            return 'Нет данных по дате обновления';
          }
          return `${updatedDate ? `Обновлено: ${updatedDate}` : ''} ${updaterName ? `- ${updaterName}` : ''}`;
        }

        if (buyerType.type === 'buy') { // Колонка прайс
          const updaterName = currentProduct?.tradeOffersChain.tradeOffers.find((item) => item.buyerType === 3)?.updaterUser?.name || null;
          const updatedDate = currentProduct?.tradeOffersChain.tradeOffers.find((item) => item.buyerType === 3)?.updatedAt?.date
            ? this.moment(currentProduct?.tradeOffersChain.tradeOffers.find((item) => item.buyerType === 3)?.updatedAt?.date).format('LL')
            : null;
          if (!updaterName && !updatedDate) {
            return 'Нет данных по дате обновления';
          }
          return `${updatedDate ? `Обновлено: ${updatedDate}` : ''} ${updaterName ? `- ${updaterName}` : ''}`;
        }

        if (buyerType.type === 'sale') { // Колонка себес
          const updaterName = currentProduct?.tradeOffersChain.tradeOffers.find((item) => item.buyerType === 3)?.updaterUser?.name || null;
          const updatedDate = currentProduct?.tradeOffersChain.tradeOffers.find((item) => item.buyerType === 3)?.saleUpdatedAt?.date
            ? this.moment(currentProduct?.tradeOffersChain.tradeOffers.find((item) => item.buyerType === 3)?.saleUpdatedAt?.date).format('LL')
            : null;
          if (!updaterName && !updatedDate) {
            return 'Нет данных по дате обновления';
          }
          return `${updatedDate ? `Обновлено: ${updatedDate}` : ''} ${updaterName ? `- ${updaterName}` : ''}`;
        }
        return 'Нет данных по дате обновления';
      } catch {
        return 'Ошибка при получении данных';
      }
    },
    changePriceType(type) {
      if (type.disabled) return;
      this.showPriceInput = null;
      this.selectedPriceType = type.name;
    },
    openMenu() {
      if (this.group.withoutAd || this.disableEditor) return;

      // при открытии редактора отключаем возможность показывать мини-меню при наведении на канал
      this.$store.commit('groups/setShowGroupMenu', true);
      if (!this.isCompilation) {
        this.getPriceHistory();
      }
      this.showMenu = true;
    },
    closeEditor() {
      // при закрытии редактора включить возможность показывать мини-меню при наведении на канал
      this.$store.commit('groups/setShowGroupMenu', false);
      this.closePriceInput();
      this.selectedPriceType = 'postPrice';
      this.historyData = [];
      this.showMenu = false;
    },
    onClickOutside() {
      this.closeEditor();
    },
    getPriceHistory() {
      if (this.historyData.length) return;
      this.loadingHistory = true;

      const params = {
        params: {
          groupIdentity: { id: this.group.id, social: this.group.social },
          propertiesFilter: [`postPrice.${this.priceType}`],
        },
      };

      this.$store.dispatch('group/getPriceHistory', params)
        .then((data) => {
          const items = data.map((item, index) => {
            const price = item.changes.find((change) => change.propertyPath === `postPrice.${this.priceType}`);
            return {
              id: index,
              date: new Date(item.dateTime.date).getTime(),
              fulldate: this.moment(item.dateTime.date).format('DD MMM YYYY'),
              value: price.newValue ? Math.trunc(price.newValue) : null,
              oldValue: price.oldValue ? Math.trunc(price.oldValue) : null,
              user: item.user?.email || item.botName || null,
            };
          });
          // uniqBy - убираем дубликаты по датам (выпилить, если это будет делать сервер)
          // reverse чтобы в уникальных оставалась последняя указанная на день цена
          const filtered = uniqBy(items.reverse(), (item) => item.fulldate).reverse();
          this.priceData = [...filtered];
          this.historyData = [...this.priceData].reverse();
          this.loadingHistory = false;
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: this.$t('An error occurred while get the data'), error: true });
          this.loadingHistory = false;
        });
    },
    getHistoryValue(value) {
      // FUTURE валюта ?
      return `${this.setNumberSpaces(Math.round(value))} ₽`;
    },
    openPriceInput(variant) {
      if (!this.isAdmin || this.priceType === 'sell') return;

      const price = variant ? this.getPriceWithVariant(variant.value) : this.getPriceWithoutVariant();
      this.showPriceInput = variant ? variant.name : this.selectedPriceType;
      this.inputPrice = price ? price.replace(/[\D]+/g, '') : '';
      this.$nextTick(() => {
        if (variant) {
          this.$refs.priceInput[0].focus();
        } else {
          this.$refs.priceInput.focus();
        }
      });
      this.oldPrice = this.inputPrice;
    },
    savePrice() {
      if (this.inputPrice === this.oldPrice) {
        this.closePriceInput();
        return;
      }
      const productType = this.productsId[this.selectedPriceType];
      const currentTradeOfferId = this.products.find((item) => item.type === productType)?.id; // находим объекты с нужным продуктом

      if (currentTradeOfferId) {
        const params = { id: currentTradeOfferId };
        if (this.priceType === 'buy') {
          params.prices = { price: this.inputPrice };
        } else if (this.priceType === 'sale') {
          params.prices = { sale: this.inputPrice };
        }
        this.$store.dispatch('group/editTradeOffer', params)
          .then((data) => {
            this.group.products = this.group.products.map((item) => {
              if (item.id === data.id) {
                return data;
              }
              return item;
            });
            const message = data.needModerate ? 'Цена отправлена на модерацию' : 'Стоимость обновлена';
            this.$store.dispatch('app/setSnackBar', { text: message, error: data.needModerate });
            this.closePriceInput();
          })
          .catch(() => {
            this.closePriceInput();
          });
        return;
      }

      const newData = {
        groupIdentity: { id: this.group.id, social: this.group.social },
        productType,
      };

      if (this.priceType === 'buy') {
        newData.price = this.inputPrice;
      } else if (this.priceType === 'sale') {
        newData.sale = this.inputPrice;
      }

      this.$store.dispatch('group/setTradeOffer', newData)
        .then((data) => {
          this.group.products = data.products;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
          this.closePriceInput();
        });
    },
    saveVariantPrice(variant) {
      if (this.inputPrice === this.oldPrice) {
        this.closePriceInput();
        return;
      }

      const productType = this.productsId[this.selectedPriceType];
      const currentTradeOfferId = this.products.find((item) => {
        if (variant.field === 'isFixedInTop') {
          return item.type === productType && item.isFixedInTop;
        }
        if (variant.field === 'isVkMarket') {
          return item.type === productType && item.isVkMarket;
        }
        return item.type === productType && item[variant.field] === variant.value;
      })?.id;

      if (currentTradeOfferId) {
        const params = { id: currentTradeOfferId };
        if (this.priceType === 'buy') {
          params.prices = { price: this.inputPrice };
        } else if (this.priceType === 'sale') {
          params.prices = { sale: this.inputPrice };
        }
        this.$store.dispatch('group/editTradeOffer', params)
          .then((data) => {
            this.group.products = this.group.products.map((item) => {
              if (item.id === data.id) {
                return data;
              }
              return item;
            });
            const message = data.needModerate ? 'Цена отправлена на модерацию' : 'Стоимость обновлена';
            this.$store.dispatch('app/setSnackBar', { text: message, error: data.needModerate });
            this.closePriceInput();
          })
          .catch(() => {
            this.closePriceInput();
          });
        return;
      }

      const newData = {
        groupIdentity: { id: this.group.id, social: this.group.social },
        productType,
      };

      if (this.priceType === 'buy') {
        newData.price = this.inputPrice;
      } else if (this.priceType === 'sale') {
        newData.sale = this.inputPrice;
      }

      if (variant.field === 'isFixedInTop') {
        newData.isFixedInTop = true;
      } else if (variant.field === 'isVkMarket') {
        newData.isVkMarket = true;
      } else if (this.selectedPriceType === 'circlePrice') {
        newData.circleDurationInSeconds = variant.value;
      } else {
        newData.productDurationInHours = variant.value;
      }

      this.$store.dispatch('group/setTradeOffer', newData)
        .then((data) => {
          this.group.products = data.products;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
          this.closePriceInput();
        })
        .catch(() => {
          this.closePriceInput();
        });
    },
    changeCheckbox() {
      return false;
    },
    closePriceInput() {
      this.showPriceInput = false;
      this.inputPrice = '';
      this.oldPrice = '';
    },
  },
  computed: {
    getCurrencySymbol() {
      return this.$store.getters['app/getCurrencySymbol'];
    },
    getPriceTypes() {
      // список типов цены, доступных для конкретной соц сети
      const currentSocial = socialsPriceTypes.find((item) => item.social === this.selectedTypeOfGroups);
      return currentSocial ? currentSocial.priceTypes : socialsPriceTypes[0].priceTypes;
    },
    getSelectedPriceType() {
      // Тип цены, выбранный в фильтрах
      if (this.isCompilation) {
        return this.$store.getters['compilations/getSelectedPriceType'];
      }
      return this.$store.getters['groups/getSelectedPriceType'];
    },
    getSelectedPriceVariant() {
      // Вариант цены, выбранный в фильтрах
      if (this.isCompilation) {
        return this.$store.getters['compilations/getSelectedPriceVariant'];
      }
      return this.$store.getters['groups/getSelectedPriceVariant'];
    },
    getLabelTitle() {
      if (this.group.social === 'mave') {
        return 'Цена';
      }
      return `${this.$t(this.getSelectedPriceType)} ${this.$t(this.getSelectedPriceVariant?.name)}`;
    },
    getPriceVariants() {
      return this.getPriceTypes.find((item) => item.name === this.selectedPriceType).variants || false;
    },
    getMainLabel() {
      if (this.group.withoutAd) {
        return 'Нет рекламы';
      }
      try {
        const currentPriceId = this.productsId[this.getSelectedPriceType];
        const currentProducts = this.products.filter((item) => item.type === currentPriceId); // находим объекты с нужным продуктом
        if (!currentProducts.length) {
          return false;
        }
        const currentProduct = currentProducts.find((item) => {
          if (item.isFixedInTop) {
            return String(this.getSelectedPriceVariant?.value) === 'isFixedInTop';
          }
          if (item.durationInHours) {
            return String(item.durationInHours) === String(this.getSelectedPriceVariant?.value);
          }
          if (item.circleDurationInSeconds) {
            return String(item.circleDurationInSeconds) === String(this.getSelectedPriceVariant?.value);
          }
          return item;
        });
        const buyerType = this.buyerTypes.find((item) => item.type === this.priceType); // какую цену ищем, sell или buy или sale

        if (!currentProduct) {
          return false;
        }

        // Для колонки цена берем цену из currentTradeoffer
        let resultPrice;

        if (buyerType.type === 'sell') {
          resultPrice = currentProduct?.tradeOffersChain?.currentTradeOffer?.resultPrice || null;
        }
        if (buyerType.type === 'buy') {
          resultPrice = currentProduct?.tradeOffersChain.tradeOffers.find((item) => item.buyerType === 3)?.resultPrice;
        }
        if (buyerType.type === 'sale') {
          resultPrice = currentProduct?.tradeOffersChain.tradeOffers.find((item) => item.buyerType === 3)?.sale;
        }

        if (!resultPrice && +resultPrice !== 0) {
          return false;
        }
        return `${this.setNumberSpaces(Math.round(resultPrice))} ${this.getCurrencySymbol}`;
      } catch {
        return '?';
      }
    },
    getEditorLabel() {
      return this.selectedTypeOfGroups === 'mave'
        ? 'Стоимость публикации'
        : 'Тип публикации';
    },
  },
};
</script>

<style lang="scss" scoped>
.group-price {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 200px;
}

.group-price__post-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.group-price__post {
  width: 120px;
  padding: 5px 10px;
  background: #F0F9FC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  box-sizing: content-box;
  @include between-elements {
    padding-bottom: 5px;
  }
}

.group-price__post-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F0F9FC;
  margin-left: 3px;
  width: 30px;
  height: 52px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.group-price__post-ceil {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.group-price__post-icon-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  box-shadow: 0px 2px 1px 0px rgba(34, 60, 80, 0.05);
}

.group-price__chevron-price {
  display: block;
  color: $primary-blue;
}

.group-price__text {
  font-size: 14px;
  color: $primary-black;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &_without-ad {
    font-size: 15px;
    color: rgba($primary-blue, 0.7);
  }
  &_price {
    color: $primary-blue;
    font-size: 15px;
  }
  &_light {
    color: $primary-lightgray;
    font-weight: 400;
  }
  &_loading {
    font-size: 12px;
    color: $primary-lightgray;
  }
  &_empty-label {
    font-size: 14px;
    color: rgba($primary-blue, 0.5);
  }
  &_updater {
    color: rgb(163, 163, 163);
    font-size: 13px;
    white-space: nowrap;
  }
}

// MENU
.group-price__menu {
  position: absolute;
  top: 65px;
  left: 5px;
  background-color: white;
  min-width: 550px;
  z-index: 1;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  border-radius: 12px;
  padding: 20px;
}

// PRICE
.group-price__price-container {
  margin-top: 20px;
  display: flex;
  min-height: 90px;
  &_mave {
    min-height: 0px;
  }
}

.group-price__price-radios {
  display: flex;
  flex-direction: column;
  padding-right: 30px;
  border-right: 1px solid #CCEFFA;
}

.group-price__price-radio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  @include between-elements {
    margin-right: 10px;
  }
}

.group-price__prices {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-price__without-price {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  height: 40px;
  @include between-elements {
    margin-right: 10px;
    margin-bottom: 2px;
  }
  &:hover {
    .group-price__icon {
      opacity: 1;
    }
  }
  &_mave {
    padding-left: 0px;
  }
}

.group-price__price-item {
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 10px;
  }
}

.group-price__price {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 40px;
  @include between-elements {
    margin-right: 10px;
    margin-bottom: 2px;
  }
  &:hover {
    .group-price__icon {
      opacity: 1;
    }
  }
}

.group-price__checkboxes {
  margin: 5px 0px 20px;
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}

.group-price__history {
  display: flex;
  flex-direction: column;
  max-height: 130px;
  overflow: auto;
  &_empty {
    border-radius: 10px;
    height: 50px;
    border: 1px solid rgb(240, 240, 240);
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-price__history-element {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_user {
    display: grid;
    grid-template-columns: 25% 20% 50%;
    justify-content: flex-start;
    gap: 10px;
  }
}

.group-price__price-input {
  outline: 1px solid rgb(241, 241, 241);
  padding: 2px;
  border: none;
  width: 120px;
  background: white;
  font-size: 14px;
  color: $primary-blue;
  appearance: none;
}

.group-price__icon {
  opacity: 0;
  color: rgb(199, 199, 199);
  &_save {
    opacity: 1;
    color: $primary-lightgray;
  }
}

.group-price__chart {
  margin: 20px 0px;
  height: 250px;
  border-radius: 8px;
  border: 1px solid #D8F2FA;
  box-shadow: 0px 5px 12px -5px rgba(0, 174, 232, 0.05);
}

.group-price__loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-price__loading-img {
  width: 30px;
  height: 30px;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.group-price__without-ad {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: #F0F9FC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
  padding: 5px 15px;
  min-width: 170px;
}
</style>
