<template>
  <div class="edit-employee-modal">
    <span class="edit-employee-modal__text edit-employee-modal__text_title">Редактировать сотрудника</span>
    <div class="edit-employee-modal__settings-block">
      <div class="edit-employee-modal__settings-inputs">
        <div
        v-for="item in profileBase"
        :key="item.name"
        class="edit-employee-modal__input-container">
          <span
          class="edit-employee-modal__text edit-employee-modal__text_light"
          :class="item.error && 'edit-employee-modal__text_error'">{{ item.error ? item.errortext : item.name }}</span>
          <DataInput
          v-model="item.value"
          :placeholder="item.placeholder"
          :error="item.error"
          class="edit-employee-modal__input" />
        </div>
      </div>
      <div class="edit-employee-modal__buttons">
        <DataButton
        text="Обновить"
        @handler="saveChanges" />
        <DataButton
        type="outlined"
        text="Отменить"
        @handler="$emit('close')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditEmployeeModal',
  props: {
    client: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    profileBase: {
      name: {
        name: 'ФИО', placeholder: 'Введите имя', field: 'name', value: '', error: false, errortext: 'Укажите ФИО',
      },
      email: {
        name: 'Почта', placeholder: 'Укажите почту', field: 'email', value: '', error: false, errortext: 'Укажите корректную почту',
      },
      password: {
        name: 'Пароль', placeholder: 'Укажите новый пароль', field: 'password', value: '', error: false, errortext: 'Новый пароль не менее 6 символов',
      },
      telegram: {
        name: 'Телеграм', placeholder: 'Телеграм', field: 'telegram', value: '', error: false,
      },
      phoneNumber: {
        name: 'Телефон', placeholder: 'Укажите телефон', field: 'phoneNumber', value: '', error: false,
      },
    },
  }),
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.profileBase.name.value = this.client.name || '';
      this.profileBase.email.value = this.client.email || '';
      this.profileBase.telegram.value = this.client.telegram || '';
      this.profileBase.phoneNumber.value = this.client.phoneNumber || '';
    },
    checkIsValidForm() {
      this.profileBase.name.error = !this.profileBase.name.value.length;
      this.profileBase.password.error = this.profileBase.password.value.length
        ? this.profileBase.password.value.length < 6
        : false;
      // eslint-disable-next-line max-len
      this.profileBase.email.error = !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(this.profileBase.email.value.toLowerCase());
      return this.profileBase.name.error || this.profileBase.password.error || this.profileBase.email.error;
    },
    saveChanges() {
      const formIsInvalid = this.checkIsValidForm();
      if (formIsInvalid) return;

      const userData = {};
      Object.keys(this.profileBase).forEach((item) => {
        if (this.profileBase[item].value) {
          userData[item] = this.profileBase[item].value;
        }
      });
      userData.id = this.client.id;
      this.$store.dispatch('clients/updateEmployeeFromAgency', userData)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Успешно. Данные сотрудника обновлены' });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка при обновлении данных', error: true });
        });
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-employee-modal__settings-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 20px;
  background: white;
  margin-top: 30px;
  max-width: 400px;
  @include between-elements {
    margin-bottom: 32px;
  }
}

.edit-employee-modal__settings-inputs {
  width: 100%;
  @include between-elements {
    margin-bottom: 20px;
  }
  @include desktop-screen {
    min-width: 400px;
  }
}

.edit-employee-modal__text {
  font-size: 14px;
  color: #232323;
  &_title {
    font-size: 24px;
    font-weight: 600;
  }
  &_light {
    color: #4F4F4F;
    margin-bottom: 10px;
  }
  &_error {
    color: $primary-red;
  }
}

.edit-employee-modal__input-container {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.edit-employee-modal__buttons {
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}

</style>
