<template>
  <div class="data-datepicker">
    <div
    class="data-datepicker__main"
    :class="{
      'data-datepicker__main_theme-white': theme === 'white',
      'data-datepicker__main_disabled': disabled || noEdit,
      'data-datepicker__main_error': error,
    }"
    :style="{ 'width': width + 'px' }"
    @click="openInput">
      <span
      v-if="value"
      class="data-datepicker__label">{{ value }}</span>
      <span
      v-else
      class="data-datepicker__label data-datepicker__label_placeholder">{{ placeholder }}</span>
      <input
      ref="input"
      type="date"
      :value="value"
      :disabled="disabled || noEdit"
      @input="input"
      @change="change">
      <img
      class="data-datepicker__icon"
      src="@/assets/svg/icons/CalendarBlank.svg">
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataInput',
  props: {
    value: {
      type: [String, Number, Array, Object],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'дд.мм.гггг',
    },
    error: {
      type: Boolean,
      default: false,
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
    },
    width: {
      type: Number,
      default: null,
    },
  },
  methods: {
    input(event) {
      this.$emit('input', event.target.value ? event.target.value : null);
    },
    change(event) {
      this.$emit('change', event.target.value ? event.target.value : null);
    },
    openInput() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.data-datepicker {
  min-width: 160px;
  position: relative;
}

.data-datepicker__main {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafeff;
  border-radius: 8px;
  border: 1px solid #d8f2fa;
  height: 48px;
  min-width: 100px;
  cursor: pointer;
  position: relative;
  &_theme-white {
    background: white;
  }
  &_disabled {
    background: #f5f5f5;
    border: 1px solid rgba(0, 0, 0, 0.03)
  }
  &_error {
    border: 1px solid $primary-red;
  }
}

.data-datepicker__label {
  font-size: 16px;
  color: $primary-gray;
  position: absolute;
  top: 14px;
  left: 20px;
  &_placeholder {
    color: #7b7b7b;
    font-weight: 400;
    top: 14px;
    left: 25px;
  }
}

.data-datepicker__icon {
  position: absolute;
  top: 12px;
  right: 15px;
  z-index: 0;
}

input[type="date"] {
  position: relative;
  width: 90%;
  height: 100%;
  opacity: 0;
  z-index: 1;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
  cursor: pointer;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  z-index: -10;
}
</style>
