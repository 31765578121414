var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"competitors-headers"},[_c('div',{staticClass:"competitors-headers__checkbox"},[_c('DataCheckbox',{attrs:{"value":_vm.isAllSelected},on:{"input":_vm.setAllSelected}})],1),_vm._l((_vm.getHeaders),function(header){return _c('div',{key:header.value,staticClass:"competitors-headers__element",class:{
    'competitors-headers__element_name': header.value === 'name',
  },style:(`width: ${header.width}px`),on:{"click":function($event){return _vm.changeSort(header.sort)}}},[_c('span',{staticClass:"competitors-headers__text",class:{
      'competitors-headers__text_selected': header.sort && header.sort === _vm.getSortBy,
      'pointer' : header.sort,
    }},[_vm._v(" "+_vm._s(header.text)+" ")]),(header.sort)?_c('DataIcon',{staticClass:"groups-header__icon",class:[
      header.sort === _vm.getSortBy && 'groups-header__icon_selected',
      header.sort && 'pointer'
    ],attrs:{"size":12,"name":"south","arrow-open":_vm.getSortDir === 'asc' && header.sort === _vm.getSortBy}}):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }