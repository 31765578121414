<template>
  <div
  ref="dates-filters"
  class="dates-filter"
  @click="openMenu">
    <div class="dates-filter__title">
      <span class="dates-filter__text dates-filter__text_second">
        {{ $t('update_dates') }}
      </span>
      <transition name="fade">
        <div
        v-if="isNotEmptyValues"
        class="dates-filter__title-active" />
      </transition>
    </div>
    <span
    class="dates-filter__text"
    :class="isNotEmptyValues && 'dates-filter__text_blue'">
      {{ getLabel }}
    </span>
    <transition name="fade">
      <div
      v-if="isOpen"
      ref="menu"
      v-click-outside="onClickOutside"
      :style="{
        top: `${top}px`,
        left: `${left}px`,
      }"
      class="dates-filter__menu">
        <div class="dates-filter__container">
          <span class="dates-filter__text">
            {{ $t('update_date') }}
          </span>
          <div
          class="dates-filter__input-block">
            <div>
              <span class="dates-filter__text dates-filter__text_second">
                {{ $t('from') }}
              </span>
              <input
              v-model="updatedAtFromMin"
              type="date"
              class="dates-filter__input"
              :class="updatedAtFromMin && 'dates-filter__input_active'"
              @change="debounceUpdateMin(updatedAtFromMin, 'updatedAtMin')">
            </div>
            <div>
              <span class="dates-filter__text dates-filter__text_second">
                {{ $t('to') }}
              </span>
              <input
              v-model="updatedAtFromMax"
              type="date"
              class="dates-filter__input"
              :class="updatedAtFromMax && 'dates-filter__input_active'"
              @change="debounceUpdateMax(updatedAtFromMax, 'updatedAtMax')">
            </div>
          </div>
        </div>

        <div class="dates-filter__container">
          <span class="dates-filter__text">
            {{ $t('create_date') }}
          </span>
          <div
          class="dates-filter__input-block">
            <div>
              <span class="dates-filter__text dates-filter__text_second">
                {{ $t('from') }}
              </span>
              <input
              v-model="createdAtFromMin"
              type="date"
              class="dates-filter__input"
              :class="createdAtFromMin && 'dates-filter__input_active'"
              @change="debounceUpdateMin(createdAtFromMin, 'createdAtMin')">
            </div>
            <div>
              <span class="dates-filter__text dates-filter__text_second">
                {{ $t('to') }}
              </span>
              <input
              v-model="createdAtFromMax"
              type="date"
              class="dates-filter__input"
              :class="createdAtFromMax && 'dates-filter__input_active'"
              @change="debounceUpdateMax(createdAtFromMax, 'createdAtMax')">
            </div>
          </div>
        </div>
        <div class="dates-filter__container">
          <span class="dates-filter__text">
            {{ $t('price_update_date') }}
          </span>
          <div
          class="dates-filter__input-block">
            <div>
              <span class="dates-filter__text dates-filter__text_second">
                {{ $t('from') }}
              </span>
              <input
              v-model="ourTradeOfferUpdatedAtFromValue"
              type="date"
              class="dates-filter__input"
              :class="ourTradeOfferUpdatedAtFromValue && 'dates-filter__input_active'"
              @change="debounceUpdateMin(ourTradeOfferUpdatedAtFromValue, 'ourTradeOfferUpdatedAtFrom')">
            </div>
            <div>
              <span class="dates-filter__text dates-filter__text_second">
                {{ $t('to') }}
              </span>
              <input
              v-model="ourTradeOfferUpdatedAtToValue"
              type="date"
              class="dates-filter__input"
              :class="ourTradeOfferUpdatedAtToValue && 'dates-filter__input_active'"
              @change="debounceUpdateMax(ourTradeOfferUpdatedAtToValue, 'ourTradeOfferUpdatedAtTo')">
            </div>
          </div>
        </div>
        <span
        class="dates-filter__text dates-filter__text_second"
        @click="resetAll">
          {{ $t('reset_all') }}
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'DatesFilter',
  props: {
    updatedAtMin: {
      type: [String, Number, Object],
      default: null,
    },
    updatedAtMax: {
      type: [String, Number, Object],
      default: null,
    },
    createdAtMin: {
      type: [String, Number, Object],
      default: null,
    },
    createdAtMax: {
      type: [String, Number, Object],
      default: null,
    },
    ourTradeOfferUpdatedAtFrom: {
      type: [String, Number, Object],
      default: null,
    },
    ourTradeOfferUpdatedAtTo: {
      type: [String, Number, Object],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    updatedAtFromMin: null,
    updatedAtFromMax: null,
    createdAtFromMin: null,
    createdAtFromMax: null,
    ourTradeOfferUpdatedAtFromValue: null,
    ourTradeOfferUpdatedAtToValue: null,
    debounceUpdateMin: null,
    debounceUpdateMax: null,
    isOpen: false,
    top: 0,
    left: 0,
  }),
  created() {
    this.updatedAtFromMin = this.updatedAtMin ? this.updatedAtMin.split(' ')[0] : null;
    this.updatedAtFromMax = this.updatedAtMax ? this.updatedAtMax.split(' ')[0] : null;

    this.createdAtFromMin = this.createdAtMin ? this.createdAtMin.split(' ')[0] : null;
    this.createdAtFromMax = this.createdAtMax ? this.createdAtMax.split(' ')[0] : null;

    this.ourTradeOfferUpdatedAtFromValue = this.ourTradeOfferUpdatedAtFrom ? this.ourTradeOfferUpdatedAtFrom.split(' ')[0] : null;
    this.ourTradeOfferUpdatedAtToValue = this.ourTradeOfferUpdatedAtTo ? this.uourTradeOfferUpdatedAtTo.split(' ')[0] : null;

    this.debounceUpdateMin = debounce((value, key) => {
      const date = value ? `${value} 00:00:00` : null;
      this.$emit(`update:${key}`, date);
    }, 0);

    this.debounceUpdateMax = debounce((value, key) => {
      const date = value ? `${value} 23:59:59` : null;
      this.$emit(`update:${key}`, date);
    }, 0);
  },
  methods: {
    resetAll() {
      const dates = ['updatedAtMin', 'updatedAtMax',
        'createdAtMin', 'createdAtMax',
        'ourTradeOfferUpdatedAtFrom', 'ourTradeOfferUpdatedAtTo'];

      dates.forEach((item) => {
        this.$emit(`update:${item}`, null);
      });

      this.updatedAtFromMin = null;
      this.updatedAtFromMax = null;
      this.createdAtFromMin = null;
      this.createdAtFromMax = null;
      this.ourTradeOfferUpdatedAtFromValue = null;
      this.ourTradeOfferUpdatedAtToValue = null;
    },
    openMenu() {
      this.top = this.$refs['dates-filters'].getBoundingClientRect().top + 50;
      this.left = this.$refs['dates-filters'].getBoundingClientRect().left;
      this.isOpen = true;
    },
    onClickOutside() {
      this.isOpen = false;
    },
  },
  computed: {
    isNotEmptyValues() {
      return this.updatedAtFromMin !== null
      || this.updatedAtFromMax !== null
      || this.createdAtFromMin !== null
      || this.createdAtFromMax !== null
      || this.ourTradeOfferUpdatedAtFromValue !== null
      || this.ourTradeOfferUpdatedAtToValue !== null;
    },
    getLabel() {
      const dates = [this.updatedAtFromMin, this.updatedAtFromMax,
        this.createdAtFromMin, this.createdAtFromMax,
        this.ourTradeOfferUpdatedAtFrom, this.ourTradeOfferUpdatedAtToValue];
      const counts = dates.filter((item) => item).length;
      return counts ? `${this.$t('selected')} (${counts})` : this.$t('any_dates');
    },
  },
  watch: {
    reset(newValue) {
      if (newValue === false) {
        this.resetAll();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dates-filter {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 60px;
}

.dates-filter__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5px;
}

.dates-filter__title-active {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: $primary-blue;
  margin-left: 10px;
  margin-top: 1px;
}

.dates-filter__text {
  color: #565656;
  font-size: 14px;
  cursor: pointer;
  &_second {
    font-weight: 400;
    color: #7a7a7a;
    font-size: 13px;
  }
  &_blue {
    color: $primary-blue;
  }
}

.dates-filter__menu {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 280px;
  z-index: 10;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 20px;
  }
  @include last-child {
    margin-top: 10px;
  }
}

.dates-filter__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.dates-filter__input-block {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
  height: 30px;
  width: 100%;
  border-radius: 8px;
  justify-content: space-between;
  border: 1px solid $border-color-primary;
  @include between-elements {
    margin-right: 20px;
  }
}

.dates-filter__input {
  border: none;
  width: 80px;
  font-size: 14px;
  margin-left: 2px;
  cursor: pointer;
  &_active {
    box-shadow: 0px -1px 0px 0px $primary-blue inset;
  }
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: rgb(134, 24, 24);
    font-weight: 400;
  }
}

input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}
</style>
