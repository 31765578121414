import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    appIsLoaded: true,
    appIsLoading: false,
    isInitialCatalogLoading: false,
    newGroupsForCompilation: null,
    // AppSnackBar
    appSnackBars: [],
    // Sidebar
    showSidebar: false,
    // Modals
    currentModal: false,
    // currency
    showFullTariffRate: true,
    showAppOverlay: false,
    currencySymbols: [
      {
        name: '₽',
        value: 'rub',
      },
      {
        name: 'USD',
        value: 'usd',
      },
      {
        name: 'KZT',
        value: 'kzt',
      },
      {
        name: 'UZS',
        value: 'uzs',
      },
    ],
    selectedCurrency: localStorage.getItem('appCurrency') || 'rub',
  },
  getters: {
    getNewGroupsForCompilation: (state) => state.newGroupsForCompilation,
    // AppSnackBar
    getAppSnackBars: (state) => state.appSnackBars,
    // SideBar
    getShowSidebar: (state) => state.showSidebar,
    // Modals
    getCurrentModal: (state) => state.currentModal,
    getShowFullTariffRate: (state) => state.showFullTariffRate,
    getCurrencySymbol: (state) => state.currencySymbols.find((item) => item.value === state.selectedCurrency).name,
    getAppIsLoaded: (state) => state.appIsLoaded,
    getAppIsLoading: (state) => state.appIsLoading,
    getIsInitialCatalogLoading: (state) => state.isInitialCatalogLoading,
    getShowAppOverlay: (state) => state.showAppOverlay,
  },
  mutations: {
    setNewGroupsToCompilation: (state, value) => { state.newGroupsForCompilation = value; },
    // Sidebar
    setShowSidebar: (state, value) => { state.showSidebar = value; },
    // Modals
    setCurrentModal: (state, value) => { state.currentModal = value; },
    setShowFullTariffRate: (state, value) => { state.showFullTariffRate = value; },
    // currency
    setCurrency: (state, value) => { state.currency = value; },
    setAppIsLoaded: (state, value) => { state.appIsLoaded = value; },
    setAppIsLoading: (state, value) => { state.appIsLoading = value; },
    setInitialCatalogLoading: (state, value) => { state.isInitialCatalogLoading = value; },
    setShowAppOverlay: (state, value) => { state.showAppOverlay = value; },
  },
  actions: {
    setSnackBar({ state, dispatch }, params) {
      const newSnackBar = {
        id: new Date().getTime(),
        text: params.text,
        error: params.error,
        timeout: params.timeout,
      };
      state.appSnackBars.push(newSnackBar);
      setTimeout(() => {
        dispatch('removeSnackBar', newSnackBar);
      }, newSnackBar.timeout || 3000);
    },
    removeSnackBar({ state }, params) {
      state.appSnackBars = state.appSnackBars.filter((item) => item.id !== params.id);
    },
    createApiErrorMessage({ dispatch }, data) {
      const { error, action, defaultErrorText } = data;
      const error422 = error.response?.status === 422;
      if (error422) {
        try {
          const errorObject = error.response.data.errors || null;
          const errorMessage = Object.values(errorObject)[0][0];
          dispatch('setSnackBar', { text: errorMessage, error: true });
        } catch {
          console.warn(`Не удалось обработать ошибку в ${action}`);
        }
      } else if (defaultErrorText) {
        dispatch('setSnackBar', { text: defaultErrorText, error: true });
      } else {
        dispatch('setSnackBar', { text: 'Произошла ошибка. Повторите позже', error: true });
      }
    },
  },
};
