<template>
  <div>
    <!-- Создание -->
    <div
    v-if="type === 'create'"
    class="compilation-folder-modal">
      <span class="compilation-folder-modal__title">
        Создание папки
      </span>
      <DataInput
      v-model.trim="folderName"
      :error="folderName.length > 30"
      class="compilation-folder-modal__input"
      placeholder="Укажите название" />
      <span
      v-if="folderName.length > 30"
      class="compilation-folder-modal__error-text">
        Максимальное количество символов в названии папки - 30
      </span>
      <div class="compilation-folder-modal__buttons">
        <DataButton
        text="Создать"
        @handler="createFolder" />
        <DataButton
        text="Отменить"
        type="outlined"
        @handler="$emit('close')" />
      </div>
    </div>

    <!-- Редактирование -->
    <div
    v-if="type === 'rename'"
    class="compilation-folder-modal">
      <span class="compilation-folder-modal__title">
        Укажите название папки
      </span>
      <DataInput
      v-model="folderName"
      :error="folderNameError || folderName.length > 30"
      placeholder="Укажите название"
      class="compilation-folder-modal__input" />
      <span
      v-if="folderName.length > 30"
      class="compilation-folder-modal__error-text">
        Максимальное количество символов в названии папки - 30
      </span>
      <div class="compilation-folder-modal__buttons">
        <DataButton
        text="Сохранить"
        @handler="renameFolder" />
        <DataButton
        text="Отменить"
        type="outlined"
        @handler="$emit('close')" />
      </div>
    </div>

    <!-- Удаление -->
    <div
    v-if="type === 'delete'"
    class="compilation-folder-modal">
      <span class="compilation-folder-modal__title">
        {{ `Вы точно хотите удалить папку ${currentFolder.name}?` }}
      </span>
      <div class="compilation-folder-modal__buttons">
        <DataButton
        text="Удалить"
        @handler="deleteFolder" />
        <DataButton
        text="Отменить"
        type="outlined"
        @handler="$emit('close')" />
      </div>
    </div>

    <!-- Перемещение в папку -->
    <div
    v-if="type === 'move'"
    class="compilation-folder-modal">
      <span class="compilation-folder-modal__title">
        Выберите новую папку
      </span>
      <DataSelect
      v-model="selectedFolder"
      :options="filteredFolders"
      :error="selectedFolderError"
      placeholder="Выберите папку"
      class="compilation-folder-modal__input" />
      <div class="compilation-folder-modal__buttons">
        <DataButton
        text="Переместить"
        @handler="moveFolder" />
        <DataButton
        text="Отменить"
        type="outlined"
        @handler="$emit('close')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompilationFolderModal',
  props: {
    // Тип модалки (создание папки, удаление папки)
    type: {
      type: String,
      default: null,
      require: true,
    },
    // Объект папки для работы с ней (удаление, переименование и прочее)
    currentFolder: {
      type: Object,
      default: null,
    },
    // Массив с папками для работы с ними (удаление и тд)
    folders: {
      type: Array,
      default: null,
    },
    // Объект подборки для работы с ней (удаление, переименование и прочее)
    compilationInfo: {
      type: Object,
      default: null,
    },
    // Выбранные каналы для работы с ними (перемещение, удаление из папок и тд)
    selectedGroups: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    folderName: '',
    folderNameError: null,
    selectedFolder: null,
    selectedFolderError: null,
  }),
  created() {
    this.initData();
  },
  methods: {
    // Инициализация данных (проброс текущего названия и тд)
    initData() {
      if (this.type === 'rename') {
        this.folderName = this.currentFolder.name;
      }
    },
    // Удалить папку
    deleteFolder() {
      const folderName = this.currentFolder.name;
      const params = { folderId: this.currentFolder.id, compilationId: this.compilationInfo.id, shareToken: null };
      this.$store.dispatch('compilations/deleteFolderInCompilation', params)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: `Папка ${folderName} удалена` });
          this.$emit('close');
        })
        .catch((err) => {
          console.warn(err);
          this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка. Попробуйте повторить позже', error: true });
          this.$emit('close');
        });
    },
    // Создать папку
    createFolder() {
      this.folderNameError = !this.folderName.length || this.folderName.length > 30;
      if (this.folderNameError) return;

      const params = { folderName: this.folderName, compilationId: this.compilationInfo.id, shareToken: null };
      this.$store.dispatch('compilations/createFolderInCompilation', params)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: `Папка ${this.folderName} создана` });
          this.$emit('close');
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка. Попробуйте повторить позже', error: true });
          this.$emit('close');
        });
    },
    // Переименовать папку
    renameFolder() {
      this.folderNameError = !this.folderName.length;
      if (this.folderNameError) return;

      const params = {
        folderId: this.currentFolder.id, folderName: this.folderName, compilationId: this.compilationInfo.id, shareToken: null,
      };
      this.$store.dispatch('compilations/renameFolderInCompilation', params)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: `Папка ${this.folderName} создана` });
          this.$emit('close');
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка. Попробуйте повторить позже', error: true });
          this.$emit('close');
        });
    },
    // Перемещение в папку
    moveFolder() {
      if (!this.selectedFolder) {
        this.selectedFolderError = true;
        return;
      }
      this.selectedFolderError = false;

      const params = {
        compilationId: this.compilationInfo.id,
        positionsIds: this.selectedGroups.map((item) => item.positionId), // id каналов и их позиции
        folderId: this.selectedFolder.id, // id папки
        place: 'after', // after or before
        // destinationPositionId: params.destinationPositionId, // канал перед которым надо вставить (передается Place after или before)
      };

      this.$store.dispatch('compilations/movePositionInCompilation', params)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: `Канал перемещен в папку ${this.selectedFolder.name}` });
          this.$emit('close');
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка. Попробуйте повторить позже', error: true });
          this.$emit('close');
        });
    },
  },
  computed: {
    filteredFolders() {
      if (!this.getSelectedFolder) {
        return this.folders.filter((folder) => folder.name !== 'Каналы без папки');
      }
      return this.folders.filter((folder) => folder.name !== this.getSelectedFolder.name);
    },
    getSelectedFolder() {
      return this.$store.getters['compilations/getSelectedFolder'];
    },
  },

};
</script>

<style lang="scss" scoped>
.compilation-folder-modal {
  max-width: 450px;
  width: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.compilation-folder-modal__title {
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.compilation-folder-modal__input {
  margin-bottom: 30px;
}

.compilation-folder-modal__buttons {
  width: 100%;
  text-align: center;
  @include between-elements {
    margin-bottom: 20px;
  }
  @include tablet-screen {
    display: flex;
    justify-content: space-between;
    @include between-elements {
      margin-bottom: 0px;
    }
  }
}

.compilation-folder-modal__error-text {
  font-size: 12px;
  color: $primary-red;
  position: absolute;
  top: 115px;
  left: 0px;
}
</style>
