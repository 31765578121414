import api from '@/axios';

export const getStatisticList = (payload) => api.get('/agency/get-statistic', { params: { ...payload } })
  .then((data) => Promise.resolve(data))
  .catch((error) => Promise.reject(error));

export const getStatisticExport = (payload) => api.get('/agency/get-statistic-export', { responseType: 'arraybuffer', params: { ...payload } })
  .then((data) => Promise.resolve(data))
  .catch((error) => Promise.reject(error));

export const getClientPlacements = (payload) => api.get('/agency/get-request-placement', { params: { ...payload } })
  .then((data) => Promise.resolve(data))
  .catch((error) => Promise.reject(error));

export const getClientCampaigns = (payload) => api.get('/agency/get-campaign', { params: { ...payload } })
  .then((data) => Promise.resolve(data))
  .catch((error) => Promise.reject(error));
