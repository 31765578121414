import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';
import moment from 'moment';
import { groupsSearchParamsTemplate } from '../templates';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    initial: true,
    typesOfGroups: [
      { value: 'telegram', name: 'Телеграм' },
      { value: 'vk', name: 'Вконтакте' },
      { value: 'instagram', name: 'Инстаграм' },
      { value: 'odnoklassniki', name: 'Одноклассники' },
      { value: 'youtube', name: 'YouTube' },
      { value: 'yandex-zen', name: 'Я.Дзен' },
      { value: 'mave', name: 'Mave' },
      // { value: 'telegram-bot', name: 'Телеграм боты' },
      // { value: 'facebook', name: 'Facebook' },
    ],
    aggregateOptions: [
      'viewsLast24h',
      'viewsLast48h',
      'viewsLast72h',
      'postsLast24h',
      'postsLastMonth',
      'subscribers',
      'dayGrow',
      'monthGrow',
      'postsTotal',
      'cpm',
      'er',
      'malePercent',
      'femalePercent',
      'postPriceSell',
      'placementTypes',
      'totalCountByCategories',
    ],
    loadParams: {
      'page-size': 50,
      page: 1,
    },
    totalPageCounts: null,
    groupsSearchParams: { ...groupsSearchParamsTemplate },
    groupsSearchParamsTemplate: { ...groupsSearchParamsTemplate },
    selectedTypeOfGroups: 'telegram',
    //
    groupsData: [],
    selectedGroups: [],
    groupsAggregateValues: [],
    hideGroupDetails: null,
    totalNewGroups: null,
    sortDir: 'desc',
    sortBy: 'subscribersCount',
    showGroupMenu: false,
    selectedPriceType: 'postPrice',
    selectedPriceVariant: { name: 'per24', value: '24' },
    isImportedGroups: false,
    addToCompilationMode: false,
    categories: [],
    categoriesForFilters: null,
  },
  getters: {
    getGroupsData: (state) => state.groupsData,
    getSelectedGroups: (state) => state.selectedGroups,
    getTypesOfGroups: (state) => state.typesOfGroups,
    getAggregateIsLoading: (state) => state.aggregateIsLoading,
    getSelectedTypeOfGroups: (state) => state.selectedTypeOfGroups,
    getGroupsSearchParams: (state) => state.groupsSearchParams,
    getGroupsSearchParamsTemplate: (state) => state.groupsSearchParamsTemplate,
    getHideGroupDetails: (state) => state.hideGroupDetails,
    getOptionsForAggregate: (state) => state.aggregateOptions,
    getGroupsAggregateValues: (state) => state.groupsAggregateValues,
    getTotalNewGroups: (state) => state.totalNewGroups,
    getIsInitial: (state) => state.initial,
    getGroupsSortBy: (state) => state.sortBy,
    getGroupsSortDir: (state) => state.sortDir,
    getTotalPageCounts: (state) => state.totalPageCounts,
    getCurrentPage: (state) => state.loadParams.page,
    getShowGroupMenu: (state) => state.showGroupMenu,
    getSelectedPriceType: (state) => state.selectedPriceType,
    getSelectedPriceVariant: (state) => state.selectedPriceVariant,
    getIsImportedGroups: (state) => state.isImportedGroups,
    getAddToCompilationMode: (state) => state.addToCompilationMode,
    getCategories: (state) => state.categories,
    getCategoriesForFilters: (state) => state.categoriesForFilters,
  },
  mutations: {
    setGroupsData: (state, value) => { state.groupsData = value; },
    setSelectedGroups: (state, value) => { state.selectedGroups = value; },
    setAggregateIsLoading: (state, value) => { state.aggregateIsLoading = value; },
    setSelectedTypeOfGroups: (state, value) => { state.selectedTypeOfGroups = value; },
    setGroupsSearchParams: (state, value) => { state.groupsSearchParams = { ...value }; },
    setLoadPage: (state, value) => { state.loadParams.page = value; },
    setTotalPageCounts: (state, value) => { state.totalPageCounts = value; },
    setHideGroupDetails: (state, value) => { state.hideGroupDetails = value; },
    setGroupsAggregateValues: (state, value) => { state.groupsAggregateValues = value; },
    resetGroupsSearchParams: (state) => { state.groupsSearchParams = { ...groupsSearchParamsTemplate }; },
    setInitial: (state, value) => { state.initial = value; },
    setTotalNewGroups: (state, value) => { state.totalNewGroups = value; },
    setSortBy: (state, value) => { state.sortBy = value; },
    setSortDir: (state, value) => { state.sortDir = value; },
    setShowGroupMenu: (state, value) => { state.showGroupMenu = value; },
    setSelectedPriceType: (state, value) => { state.selectedPriceType = value; },
    setSelectedPriceVariant: (state, value) => { state.selectedPriceVariant = value; },
    setIsImportedGroups: (state, value) => { state.isImportedGroups = value; },
    setAddToCompilationMode: (state, value) => { state.addToCompilationMode = value; },
    setCategories: (state, value) => { state.categories = value; },
    setCategoriesForFilters: (state, value) => { state.categoriesForFilters = value; },
  },
  actions: {
    // Сброс данных стейта для загрузки
    resetLoadState({ commit }) {
      commit('setLoadPage', 1);
      commit('setGroupsData', []);
      commit('setGroupsAggregateValues', {});
      commit('setSortBy', 'subscribersCount');
      commit('setSortDir', 'desc');
      commit('setTotalPageCounts', null);
      commit('setInitial', true);
      commit('setIsImportedGroups', false);
    },
    // Получение групп
    loadGroupsData({
      state, commit, getters, dispatch, rootGetters,
    }, params = { reset: false, aggregate: false, userId: null }) {
      if (rootGetters['user/getIsDemoCatalog']) {
        return dispatch('getDemoGroups');
      }

      commit('app/setAppIsLoading', true, { root: true });

      if (params.reset) {
        dispatch('resetLoadState');
      }

      if (params.aggregate) {
        dispatch('getAggregateValues', { aggregateValues: ['containsPostPriceSell'], searchParams: state.groupsSearchParams });
      }

      if (params.aggregate && state.initial) {
        dispatch('getAggregateValues', { aggregateValues: [...state.aggregateOptions], searchParams: state.groupsSearchParamsTemplate }).then(() => {
          commit('setInitial', false);
        });
      }

      if (state.selectedTypeOfGroups === 'mave') {
        state.groupsSearchParams.listeningMin = state.groupsSearchParams.viewsMin;
        state.groupsSearchParams.listeningMax = state.groupsSearchParams.viewsMax;
        state.groupsSearchParams.listeningType = 'averagePerMonth';
        state.groupsSearchParams.viewsMin = null;
        state.groupsSearchParams.viewsMax = null;
      } else {
        state.groupsSearchParams.viewsMin = state.groupsSearchParams.viewsMin || state.groupsSearchParams.listeningMin || null;
        state.groupsSearchParams.viewsMax = state.groupsSearchParams.viewsMax || state.groupsSearchParams.listeningMax || null;
        state.groupsSearchParams.listeningMin = null;
        state.groupsSearchParams.listeningMax = null;
        state.groupsSearchParams.listeningType = null;
      }

      return api.get(`/group-${state.selectedTypeOfGroups}`, {
        params: {
          ...state.groupsSearchParams, ...state.loadParams, sortBy: state.sortBy, sortDirection: state.sortDir,
        },
      })
        .then((response) => {
          commit('setGroupsData', [...getters.getGroupsData, ...response.data.data]);
          commit('setLoadPage', state.loadParams.page + 1);
          commit('setTotalPageCounts', response.headers['x-pagination-page-count']);
          commit('app/setAppIsLoading', false, { root: true });
          commit('setInitial', false);
          commit('setShowGroupMenu', false);
          return Promise.resolve(response.data.data);
        })
        .catch((error) => {
          commit('setGroupsData', []);
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action loadGroupsData; Error -', error);
        });
    },

    // Получение групп из импорта
    loadImportGroups({ commit }, params) {
      commit('app/setAppIsLoading', true, { root: true });

      return api.get(`/import-groups/${params.id}/groups`)
        .then(({ data: { data } }) => {
          commit('setGroupsData', data);
          commit('app/setAppIsLoading', false, { root: true });
          return Promise.resolve(data);
        })
        .catch((error) => {
          commit('setGroupsData', []);
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action loadImportGroups; Error -', error);
        });
    },

    // Получение сводки-статистики по каналам
    getAggregateValues({ state, commit }, params) {
      return api.get(`/group-${state.selectedTypeOfGroups}/aggregate-values`, {
        params: { aggregate: params.aggregateValues, ...params.searchParams },
      })
        .then(({ data: { data } }) => {
          const newAggregates = { ...state.groupsAggregateValues, ...data };
          commit('setGroupsAggregateValues', newAggregates);
        })
        .catch((error) => {
          console.warn('Error in action -- loadGroupsData(groupsAggregateValues); Error -', error);
        });
    },

    // Получение количества новых групп
    getTotalNewGroups({ state, commit, dispatch }) {
      if (!state.totalNewGroups) {
        api.get('/statistic/total-by-period', { params: { period: ['lastMonth', 'lastDay'] } })
          .then(({ data: { data } }) => {
            commit('setTotalNewGroups', data);
          })
          .catch((error) => {
            dispatch('app/createApiErrorMessage', { error, action: 'getTotalNewGroups' }, { root: true });
            console.warn('Error in action -- getTotalNewGroups; Error -', error);
          });
      }
    },

    // Обновить канал
    groupUpdate({ commit, dispatch }, params) {
      return api.put(`/group-${params.social}/${params.id}`, { ...params.newData })
        .then(() => Promise.resolve())
        .catch((error) => {
          dispatch('app/createApiErrorMessage',
            {
              error,
              action: 'groupUpdate',
              defaultErrorText: 'Произошла ошибка при обновлении данных',
            }, { root: true });
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- groupUpdate --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Экспортировать каналы
    exportGroups({ state, commit, dispatch }, params) {
      commit('app/setAppIsLoading', true, { root: true });

      if (!params.groupIdentities) {
        // для возможности экспортировать все отфильтрованные каналы (например во вкладке телеги и тд)
        params.social = state.selectedTypeOfGroups;
      }

      return api.post('/export-groups', params, { responseType: 'arraybuffer', params: { shareToken: params.shareToken } })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          const date = moment(new Date()).format('L');
          const hours = moment(new Date()).format('HH-MM');
          const name = params.compilationName
            ? `Экспорт подборки - ${params.compilationName} - ${date}__${hours}.xlsx`
            : `Экспорт каналов - ${date}__${hours}.xlsx`;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click();
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'exportGroups' }, { root: true });
          console.warn('Error in action -- exportGroups --; Error -', error);
          return Promise.reject();
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },

    // Удалить канал
    deleteGroup({ commit, getters, dispatch }, { id, social }) {
      return api.patch(`/group-${social}/bulk-delete`, { id })
        .then(() => {
          const newGroupsData = getters.getGroupsData.filter((group) => group.id !== id[0]);
          commit('setGroupsData', newGroupsData);
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'deleteGroup' }, { root: true });
          console.warn('Error in action -- deleteGroup--; Error -', error);
          return Promise.reject(error);
        });
    },

    // Снять пометку о плохой группе
    restoreGroup({ commit, getters, dispatch }, { id, social }) {
      return api.patch(`/group-${social}/unmark-as-bad/${id}`, { id })
        .then(() => {
          const newGroupsData = getters.getGroupsData.filter((group) => group.id !== id);
          commit('setGroupsData', newGroupsData);
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'restoreGroup' }, { root: true });
          console.warn('Error in action -- restoreGroup --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Добавление в Бан
    addToBan({ commit, getters, dispatch }, params) {
      return api.patch(`/group-${params.social}/mark-as-bad/${params.id}`, { reason: params.reason })
        .then(() => {
          const newGroupsData = getters.getGroupsData.filter((group) => group.id !== params.id);
          commit('setGroupsData', newGroupsData);
          Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'addToBan' }, { root: true });
          console.warn('Error in action -- addToBan --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Добавление в ЧС
    addToBlackList({ commit, getters, dispatch }, params) {
      return api.post('blacklist', params)
        .then(() => {
          const ids = params.groupIdentities.map((item) => item.id);
          const socials = params.groupIdentities.map((item) => item.social);
          const newGroupsData = getters.getGroupsData.filter((group) => !ids.includes(group.id) && socials.includes(group.social));
          commit('setGroupsData', newGroupsData);
          Promise.resolve();
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'addToBlackList' }, { root: true });
          console.warn('Error in action -- addToBlackList --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Удаление из ЧС
    deleteFromBlackList({ dispatch }, params) {
      return api.post('/blacklist/delete-from-blacklist', params)
        .then(() => { Promise.resolve(); })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'deleteFromBlackList' }, { root: true });
          console.warn('Error in action -- deleteFromBlackList --; Error -', error);
          return Promise.reject(error);
        });
    },

    // Получение списка демо-групп
    getDemoGroups({ state, commit, dispatch }) {
      commit('app/setAppIsLoading', true, { root: true });
      return api.get(`/group-${state.selectedTypeOfGroups}/demo-list`)
        .then(({ data: { data } }) => {
          commit('setGroupsData', data);
        })
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'getDemoGroups' }, { root: true });
          console.warn('Error in action -- getDemoGroups --; Error -', error);
          Promise.reject(error);
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },

    // Установить скидку
    setDiscount({ commit, dispatch }, params) {
      const payload = {
        buyerType: 'our',
        sellerType: 'publisher',
        discountType: 'margin',
        discountValue: params.discount,
        groupIdentity: {
          id: params.id,
          social: params.social,
        },
      };
      return api.post('/trade-offer/set-discount', payload)
        .then(() => Promise.resolve())
        .catch((error) => {
          dispatch('app/createApiErrorMessage', { error, action: 'setDiscount' }, { root: true });
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- setDiscount --; Error -', error);
          return Promise.reject(error);
        });
    },
  },
};
