<template>
  <div class="demo-bar-request-modal">
    <div v-if="isLoading">
      <img src="@/assets/svg/spinner-loader.svg">
    </div>
    <div v-if="!isExists && !isLoading">
      <div
      v-if="!isSend"
      class="demo-bar-request-modal__form">
        <span class="demo-bar-request-modal__title">Заявка на доступ</span>
        <div class="demo-bar-request-modal__form-content">
          <div class="demo-bar-request-modal__form-item">
            <span
            class="demo-bar-request-modal__form-item-title"
            :class="{ 'demo-bar-request-modal__form-item-title_error': errors.name }">
              Ваше имя
            </span>
            <DataInput
            v-model.trim="name"
            clear-icon
            :error="errors.name"
            placeholder="Введите ваше имя" />
          </div>
          <div class="demo-bar-request-modal__form-item">
            <span
            class="demo-bar-request-modal__form-item-title"
            :class="{ 'demo-bar-request-modal__form-item-title_error': errors.description }">
              Что вы рекламируете
            </span>
            <DataTextarea
            v-model.trim="description"
            :error="errors.description"
            placeholder="Опишите что рекламируем для качественной подборки источников для рекламной кампании" />
          </div>
          <div class="demo-bar-request-modal__form-item">
            <span
            class="demo-bar-request-modal__form-item-title"
            :class="{ 'demo-bar-request-modal__form-item-title_error': errors.contact }">
              Контакт для связи в Телеграм
            </span>
            <DataInput
            v-model.trim="contact"
            clear-icon
            :error="errors.contact"
            placeholder="@nickname или номер телефона" />
          </div>
        </div>
        <div class="demo-bar-request-modal__buttons">
          <DataButton
          text="Отправить"
          :width="220"
          @handler="sendForm" />
          <DataButton
          text="Отменить"
          :width="220"
          type="outlined"
          @handler="$emit('close')" />
        </div>
      </div>

      <!-- Статсус отправки -->
      <div
      v-else
      class="demo-bar-request-modal demo-bar-request-modal_send">
        <span class="demo-bar-request-modal__title demo-bar-request-modal__title_center">Спасибо, менеджер свяжется с вами в течение дня!</span>
        <div class="demo-bar-request-modal__send-content">
          <img
          src="@/assets/svg/icons/check-circle.svg"
          class="demo-bar-request-modal__icon">
          <span class="demo-bar-request-modal__form-item-title">
            Заявка успешно отправлена.
          </span>
        </div>
      </div>
    </div>
    <div
    v-if="isExists && !isLoading"
    class="demo-bar-request-modal__exists">
      <span class="">
        Ранее вы уже отправляли заявку.
      </span>
      <span class="">
        Наши менеджеры свяжутся с вами в ближайшее время
      </span>
      <DataButton
      text="Закрыть"
      :width="220"
      class="demo-bar-request-modal__exists-button"
      @handler="$emit('close')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DemoBarRequestModal',
  data: () => ({
    isSend: false,
    name: '',
    companyName: '',
    description: '',
    contact: '',
    errors: {
      description: false,
      contact: false,
    },
    isExists: false,
    isLoading: false,
  }),
  async created() {
    this.isLoading = true;
    this.$store.dispatch('tariffs/getTariffRequestIsExist', this.getUserInfo.id)
      .then((data) => {
        if (data) {
          this.isExists = true;
        }
      }).finally(() => {
        this.isLoading = false;
      });
    // если у пользователя нет прикрепленного агенства, открываем поле ввода названия компании
    this.companyName = this.getUserInfo.asEmployerAgency?.name || '';
    this.contact = this.getUserInfo.phoneNumber || '';
  },
  methods: {
    sendForm() {
      const invalidForm = this.checkForm();

      if (invalidForm) return;

      const params = {
        name: this.name, companyName: this.companyName, description: this.description, contacts: this.contacts,
      };
      this.$store.dispatch('tariffs/sendTariffRequest', params)
        .then(() => {
          this.isSend = true;
        })
        .catch(() => {
          this.$emit('close');
          this.$store.dispatch('app/setSnackBar',
            {
              text: 'Произошла ошибка при отправке заявки. Попробуйте повторить позже',
              error: true,
            });
        });
    },
    checkForm() {
      this.errors.description = !this.description.length;
      this.errors.contact = !this.contact.length;
      this.errors.name = !this.name.length;
      return Object.values(this.errors).some((item) => item);
    },
  },
  computed: {
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
  },
};
</script>

<style lang="scss" scoped>

.demo-bar-request-modal {
  max-width: 600px;
  @include desktop-screen {
     max-width: 500px;
  }
  &_send {
    min-height: 400px;
    display: flex;
    flex-direction: column;
  }
}

.demo-bar-request-modal__icon {
  width: 127px;
  height: 127px;
}

.demo-bar-request-modal__title {
  font-size: 24px;
  color: #0E092A;
  font-weight: 600;
  &_center {
    margin-top: 30px;
    text-align: center;
  }
}

.demo-bar-request-modal__form-content {
  margin-top: 30px;
  @include between-elements {
    margin-bottom: 30px;
  }

}

.demo-bar-request-modal__form-item {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 12px;
  }
}

.demo-bar-request-modal__form-item-title {
  font-size: 16px;
  font-weight: 500;
  color: #4F4F4F;
  font-weight: 400;
  &_error {
    color: $primary-red;
  }
}

.demo-bar-request-modal__buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.demo-bar-request-modal__send-content {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include between-elements {
    margin-bottom: 20px;
  }
}

.demo-bar-request-modal__exists {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  text-align: center;
  max-width: 400px;
  padding-top: 30px;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.demo-bar-request-modal__exists-button {
  margin-top: 30px;
}
</style>
