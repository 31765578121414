<template>
  <div class="request-manager">
    <div class="request-manager__manager">
      <div
      class="request-manager__manager-name"
      :class="getResponsibleLink && 'pointer'"
      @click="openResponsibleLink">
        <span class="request-manager__text">
          {{ getResponsibleName }}
        </span>
        <div class="request-manager__icons">
          <img
          v-if="getResponsibleLink"
          class="request-manager__social-icon"
          :src="require('@/assets/svg/groups-icons/telegram-icon.svg')">
          <DataIcon
          v-if="isAdmin"
          name="edit"
          :size="16"
          class="request-manager__text request-manager__text_edit-icon"
          @click.native.stop="openEditManager" />
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
      v-if="showEditManager"
      v-click-outside="() => { showEditManager = false }"
      class="request-manager__edit-manager">
        <div class="request-manager__edit-manager-container">
          <DataSelect
          v-model="newManager"
          with-search
          no-clear-select
          placeholder="Выберите менеджера"
          :options="managersList" />
          <DataButton
          icon="done"
          width="40"
          height="40"
          @handler="setNewManager" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'RequestManager',
  props: {
    request: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    isLoading: false,
    showEditManager: false,
    managersList: [],
    newManager: null,
  }),
  methods: {
    openResponsibleLink() {
      if (this.getResponsibleLink) {
        window.open(this.getResponsibleLink);
      }
    },
    openEditManager() {
      if (this.isLoading || this.showEditManager) return;

      if (this.managersList.length) {
        this.showEditManager = true;
        return;
      }

      this.isLoading = true;
      this.$store.dispatch('placements/getResponsibleNames', {})
        .then((data) => {
          this.managersList = data.map((item, index) => ({ id: index, name: item.name || item.email, value: item.id }));
          this.isLoading = false;
          this.showEditManager = true;
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Не удалось загрузить список менеджеров. Повторите позже' });
          this.isLoading = false;
        });
    },
    setNewManager() {
      if (!this.newManager) return;
      const params = {
        requestId: this.request.requestPlacement.id,
        userId: String(this.newManager.value),
      };
      this.$store.dispatch('placements/setResponsibleUser', params)
        .then(() => {
          this.showEditManager = false;
          this.newManager = null;
        });
    },
  },
  computed: {
    getResponsibleName() {
      const responsibleName = this.request.requestPlacement.responsibleUser?.name || this.request.requestPlacement.responsibleUser?.email;
      return responsibleName || '—';
    },
    getResponsibleLink() {
      return this.request.requestPlacement.responsibleLink;
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
  },
};
</script>

<style lang="scss" scoped>
.request-manager {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100%;
  border-right: 1px solid #D8F2FA;
  position: relative;
  padding-left: 10px;

  .request-manager__manager {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    &:hover {
      .request-manager__icons {
        opacity: 1;
      }
    }
  }

  .request-manager__icons {
    opacity: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.3s;
    position: absolute;
    top: 10px;
    right: 10px;
    @include between-elements {
      margin-right: 10px;
    }
  }

  .request-manager__social-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .request-manager__manager-name {
    display: flex;
    justify-content: center;
    align-items: center;
    @include between-elements {
      margin-right: 10px;
    }
  }

  .request-manager__text {
    font-size: 14px;
    max-width: 100%;
    color: $primary-gray;
  }

  .request-manager__edit-manager {
    position: absolute;
    top: 15px;
    left: 160px;
    background-color: white;
    width: 350px;
    box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
    border-radius: 12px;
    padding: 10px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    z-index: 3;
  }

  .request-manager__edit-manager-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    @include between-elements {
      margin-right: 10px;
    }
  }
}
</style>
