<template>
  <transition name="fade">
    <div
    v-if="showOverlay"
    class="data-overlay" />
  </transition>
</template>

<script>
export default {
  name: 'DataOverlay',
  data: () => ({
    higligthElement: null,
  }),
  computed: {
    showOverlay() {
      return this.$store.getters['app/getShowAppOverlay'];
    },
  },
  watch: {
    showOverlay(newValue) {
      if (newValue) {
        this.higligthElement = newValue;
        if (this.higligthElement) {
          this.higligthElement.style.zIndex = 101;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.data-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(black, 0.4);
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
}
</style>
