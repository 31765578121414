<template>
  <div class="requests-screen">
    <div class="requests-screen__nav">
      <span class="requests-screen__title">Запросы</span>
    </div>
    <RequestFilters
    v-if="isAdmin || isAgency"
    v-show="showFilters"
    ref="requestFilters"
    class="requests-screen__filters"
    :is-active-filters="isActiveFilters"
    :current-search-params="getCurrentSearchParams"
    :selected-requests-tab="selectedRequestsTab"
    @changeSelectedRequestTab="changeSelectedRequestTab"
    @loadRequests="loadRequests" />
    <div
    v-if="isMobile"
    class="requests-screen__filters-toggle"
    @click="showFilters = !showFilters">
      <span class="requests-screen__title requests-screen__title_filters_toggle">{{ showFilters ? 'Скрыть фильтры' : 'Показать фильтры' }}</span>
      <DataIcon
      name="expand_more"
      :arrow-open="showFilters"
      class="requests-screen__title requests-screen__title_filters_toggle"
      :size="16"
      pointer />
    </div>
    <div class="requests-screen__tabs">
      <span
      v-for="tab in getRequestsTabs"
      :key="tab.value"
      :class="{ 'requests-screen__tab_selected' : tab.value === selectedRequestsTab }"
      class="requests-screen__tab"
      @click="changeSelectedRequestTab(tab.value)">
        {{ tab.name }}
      </span>
    </div>
    <Requests
    :is-loading="isLoading"
    :requests-data="getRequestsData"
    :is-active-filters="isActiveFilters"
    @loadRequests="loadRequests" />
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import Requests from './components/Requests/Requests.vue';
import RequestFilters from './components/Filters/RequestFilters.vue';

export default {
  name: 'RequestsScreen',
  components: { Requests, RequestFilters },
  data: () => ({
    selectedRequestsTab: 'my',
    showFilters: true,
  }),
  created() {
    this.showFilters = !this.isMobile;
    this.loadRequests({ reset: true });
  },
  methods: {
    changeSelectedRequestTab(value) {
      if (this.isLoading) return;
      this.selectedRequestsTab = value;
      this.loadRequests({ reset: true });
    },
    loadRequests(params) {
      const userId = this.$store.getters['user/getUserInfo']?.id;

      // ВКЛАДКА МОИ (Доступна всем) ++
      if (this.selectedRequestsTab === 'my') {
        if (this.isManager) {
          const ownerParams = { responsibleUserId: userId };
          this.$store.dispatch('placements/loadRequestsData', { reset: params.reset, ownerParams });
          return;
        }
        const ownerParams = { creatorUserId: userId };
        this.$store.dispatch('placements/loadRequestsData', { reset: params.reset, ownerParams });
        return;
      }

      // ВКЛАДКА НОВЫЕ (Доступна только для админов и менеджеров) ++
      if (this.selectedRequestsTab === 'new') {
        if (this.isManager) {
          const ownerParams = { status: 1, agencyResponsibleUserId: userId };
          this.$store.dispatch('placements/loadRequestsData', { reset: params.reset, ownerParams });
          return;
        }
        if (this.isAdmin) {
          const ownerParams = { status: 1 };
          this.$store.dispatch('placements/loadRequestsData', { reset: params.reset, ownerParams });
          return;
        }
      }

      // ВКЛАДКА АРХИВ (Доступна только для админов и менеджеров)
      if (this.selectedRequestsTab === 'archive') {
        if (this.isManager) {
          const ownerParams = { isArchived: true, agencyResponsibleUserId: userId };
          this.$store.dispatch('placements/loadRequestsData', { reset: params.reset, ownerParams });
          return;
        }
        if (this.isAdmin) {
          const ownerParams = { isArchived: true };
          this.$store.dispatch('placements/loadRequestsData', { reset: params.reset, ownerParams });
          return;
        }
        if (this.isClientAgency) {
          const ownerParams = { isArchived: true, creatorUserId: userId };
          this.$store.dispatch('placements/loadRequestsData', { reset: params.reset, ownerParams });
          return;
        }
        // агентство или прямой рекл (avertiser)
        const agencyId = this.$store.getters['user/getUserInfo']?.asEmployerAgency?.id;
        const ownerParams = { isArchived: true, ownerAgencyId: agencyId };
        this.$store.dispatch('placements/loadRequestsData', { reset: params.reset, ownerParams });
        return;
      }

      // ВКЛАДКА ВСЕ ЗАПРОСЫ
      if (this.selectedRequestsTab === 'all') {
        if (this.isManager) {
          const ownerParams = { agencyResponsibleUserId: userId };
          this.$store.dispatch('placements/loadRequestsData', { reset: params.reset, ownerParams });
          return;
        }
        if (this.isAdmin) {
          this.$store.dispatch('placements/loadRequestsData', { reset: params.reset });
          return;
        }
        if (this.isAgency || this.isAdvertiser) {
          const agencyId = this.$store.getters['user/getUserInfo']?.asEmployerAgency?.id;
          const ownerParams = { ownerAgencyId: agencyId };
          this.$store.dispatch('placements/loadRequestsData', { reset: params.reset, ownerParams });
          return;
        }
        const agencyId = this.$store.getters['user/getUserInfo']?.asEmployerAgency?.id;
        const ownerParams = { ownerAgencyId: agencyId };
        this.$store.dispatch('placements/loadRequestsData', { reset: params.reset, ownerParams });
      }
    },
  },
  computed: {
    getRequestsTabs() {
      return [
        { name: 'Мои запросы', value: 'my', show: true },
        { name: 'Новые запросы', value: 'new', show: this.isAdmin || this.isManager },
        { name: 'Все запросы', value: 'all', show: !this.isClientAgency },
        { name: 'Архив', value: 'archive', show: true },
      ].filter((item) => item.show);
    },
    getRequestsData() {
      return this.$store.getters['placements/getRequestsData'];
    },
    isLoading() {
      return this.$store.getters['app/getAppIsLoading'];
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
    isManager() {
      return this.$store.getters['user/getIsManager'];
    },
    isClientAgency() {
      return this.$store.getters['user/getUserInfo']?.asClientAgency;
    },
    isAdvertiser() {
      return this.$store.getters['user/getIsAdvertiser'];
    },
    getCurrentSearchParams() {
      return this.$store.getters['placements/getRequestsSearchParams'];
    },
    getCurrentSearchParamsTemplate() {
      return this.$store.getters['placements/getRequestsSearchParamsTemplate'];
    },
    isActiveFilters() {
      return !isEqual(this.getCurrentSearchParams, this.getCurrentSearchParamsTemplate);
    },
    isMobile() {
      return window.innerWidth < 768;
    },
  },
};
</script>

<style lang="scss">
.requests-screen {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
}

.requests-screen__title {
  font-size: 18px;
  font-weight: 600;
  &_filters_toggle {
    font-weight: 500;
    font-size: 16px;
    color: $primary-blue;
  }
}

.requests-screen__nav {
  margin: 0px 20px;
  display: flex;
  align-items: center;
  @include between-elements {
    margin-right: 10px;
  }
}

.requests-screen__filters {
  margin: 10px 20px 20px 20px;
  // margin: 20px 20px 0px;
}

.requests-screen__tabs {
  padding-left: 20px;
  @include between-elements {
    margin-right: 15px;
  }
}

.requests-screen__tab {
  color: rgba($primary-gray, 0.5);
  font-size: 13px;
  transition: 0.2s;
  cursor: pointer;
  position: relative;
  &_selected {
    color: $primary-blue;
    &:after {
      position: absolute;
      top: 20px;
      left: 0px;
      content: '';
      width: 100%;
      height: 1px;
      background: $primary-blue;
    }
  }
}

.requests-screen__filters-toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 20px 0px;
  cursor: pointer;
  @include between-elements {
    margin-right: 5px;
  }
}
</style>
