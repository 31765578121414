<template>
  <div
  ref="categories-filter"
  class="categories-filter"
  @click="openMenu">
    <div class="categories-filter__title">
      <span class="categories-filter__text categories-filter__text_title">
        {{ $t('categories') }}
      </span>
      <transition name="fade">
        <div
        v-if="selectValue.length || withoutCategory"
        class="categories-filter__title-active" />
      </transition>
    </div>
    <span
    v-if="!showSearchInput"
    class="categories-filter__text"
    :class="(selectValue.length || withoutCategory) && 'categories-filter__text_blue'">
      {{ getLabelForSelect }}
    </span>
    <div
    v-if="showSearchInput"
    class="categories-filter__input-block">
      <input
      id="search-input"
      ref="searchInput"
      v-model.trim="searchInputValue"
      class="categories-filter__input"
      autocomplete="off"
      :placeholder="$t('search')">
      <transition name="fade">
        <DataIcon
        v-if="searchInputValue"
        name="close"
        class="categories-filter__icon"
        :size="16"
        pointer
        @click.native="searchInputValue = ''" />
      </transition>
    </div>
    <transition name="fade">
      <div
      v-if="isOpen"
      v-click-outside="onClickOutside"
      :style="{
        top: `${top}px`,
        left: `${left}px`,
      }"
      class="categories-filter__menu">
        <DataIcon
        v-if="selectValue.length || setWithoutCategory"
        v-tooltip="`${$t('reset_all')}`"
        name="close"
        class="categories-filter__all-reset-icon"
        pointer
        :size="16"
        @click.native="resetAll" />
        <div
        class="categories-filter__menu-content">
          <div
          v-for="group in getFilteredCategories"
          :key="group.id"
          class="categories-filter__category-block">
            <span
            class="categories-filter__text_category-title">
              {{ group.name }}
            </span>
            <div
            v-for="category in group.categories"
            :key="category.id"
            class="categories-filter__category-item">
              <span
              :class="getIsSelected(category) && 'categories-filter__text_category_active'"
              class="categories-filter__text categories-filter__text_category"
              @click.stop="changeValue(category)">
                {{ category.name }}
              </span>
              <span class="categories-filter__text_light">{{ getCategoryCount(category.id) }}</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import api from '@/axios';

export default {
  name: 'CategoriesFilter',
  props: {
    reset: {
      type: Boolean,
      default: false,
    },
    categoriesCount: {
      type: Array,
      default: null,
    },
    withoutCategory: {
      type: [Boolean, String],
      default: null,
    },
    selectedCategories: {
      type: [Number, String, Array, Boolean],
      default: null,
    },
  },
  data: () => ({
    isOpen: false,
    selectValue: [],
    searchInputValue: '',
    showSearchInput: false,
    setWithoutCategory: null,
    top: 0,
    left: 0,
  }),
  created() {
    if (!this.getCategoriesData) {
      api.get('/categories/with-groups')
        .then(({ data: { data } }) => {
          const categories = [...data];
          categories.forEach((item) => {
            if (item.name === 'Другое') {
              item.categories.push({ id: 'no-category', name: 'Без категории' });
            }
          });
          this.$store.commit('groups/setCategoriesForFilters', categories);
          this.initCategories();
        })
        .catch((error) => {
          console.warn('Ошибка загрузки категорий', error);
        });
    } else {
      this.initCategories();
    }
  },
  methods: {
    resetAll() {
      this.searchInputValue = '';
      this.setWithoutCategory = null;
      this.selectValue = [];
    },
    onClickOutside(event) {
      if (this.preventClickOutside) {
        this.preventClickOutside = false;
        return;
      }
      if (event.target.className === 'categories-filter__input' || event.target.className.includes('categories-filter__icon')) return;

      window.removeEventListener('mousedown', this.onMouseDown);

      this.showSearchInput = false;
      this.showSearchInput = false;
      this.searchInputValue = '';
      this.isOpen = false;

      if (isEqual(this.selectedCategories, this.selectValue)) {
        return;
      }
      this.$emit('update:withoutCategory', this.setWithoutCategory);
      this.$emit('update:selectedCategories', this.selectValue.length ? this.selectValue : null);
    },
    initCategories() {
      this.setWithoutCategory = this.withoutCategory;
      this.selectValue = this.selectedCategories === null
        ? []
        : [...this.selectedCategories];
    },
    openMenu() {
      if (this.isOpen) {
        return;
      }
      this.top = this.$refs['categories-filter'].getBoundingClientRect().top + 50;
      this.left = this.$refs['categories-filter'].getBoundingClientRect().left - 180;
      // слушаем событие клика на стороннем элементе
      // чтобы v-click-outside не срабатывал при выделении инпута
      window.addEventListener('mousedown', this.onMouseDown);

      this.initCategories();
      this.showSearchInput = true;
      this.isOpen = true;
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },
    changeValue(category) {
      const categoryId = String(category.id);
      if (categoryId === 'no-category') {
        this.setWithoutCategory = this.setWithoutCategory ? null : true;
        this.$emit('update:withoutCategory', this.setWithoutCategory);
        return;
      }

      if (this.selectValue.includes(categoryId)) {
        this.selectValue = this.selectValue.filter((el) => el !== categoryId);
      } else {
        this.selectValue.push(categoryId);
      }
      this.$emit('update:selectedCategories', this.selectValue.length ? this.selectValue : null);
    },
    getSelected(category) {
      return this.selectValue.includes(category.id);
    },
    onMouseDown(mouseEvent) {
      const inputs = ['search-input'];
      this.preventClickOutside = inputs.includes(mouseEvent.target.id);
    },
    getCategoryCount(value) {
      if (value === 'no-category') {
        const category = this.categoriesCount ? this.categoriesCount.find((item) => item.categoryId === null) : null;
        return category ? category.totalCount : '—';
      }
      const category = this.categoriesCount ? this.categoriesCount.find((item) => +item.categoryId === +value) : null;
      return category ? category.totalCount : '—';
    },
    getIsSelected(category) {
      if (category.id === 'no-category') {
        return this.setWithoutCategory;
      }
      return this.selectValue.includes(String(category.id));
    },
  },
  computed: {
    getLabelForSelect() {
      return (this.selectValue === null || this.selectValue.length === 0) && !this.setWithoutCategory
        ? this.$t('all')
        : `${this.$t('selected')} (${this.setWithoutCategory ? this.selectValue.length + 1 : this.selectValue.length})`;
    },
    getFilteredCategories() {
      return this.getCategoriesData
        .filter((item) => item.categories.some((category) => category.name.toUpperCase().includes(this.searchInputValue.toUpperCase())));
    },
    getCategoriesData() {
      return this.$store.getters['groups/getCategoriesForFilters'];
    },
  },
  watch: {
    reset(newValue) {
      if (newValue === false) {
        this.initCategories();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.categories-filter {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 60px;
}

.categories-filter__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.categories-filter__category-block {
  width: 230px;
  margin-bottom: 20px;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.categories-filter__category-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0px;
  width: 100%;
}

.categories-filter__title-active {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: $primary-blue;
  margin-left: 10px;
  margin-top: 1px;
}

.categories-filter__input {
  outline: none;
  border: none;
  width: 120px;
  margin-right: 5px;
  background: $primary-white;
  padding: 0px;
  &::placeholder {
    color: rgb(161, 161, 161);
    font-size: 14px;
  }
}

.categories-filter__input-block {
  display: flex;
  align-items: center;
  width: 150px;
}

.categories-filter__text {
  color: #565656;
  font-size: 14px;
  cursor: pointer;
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
    font-weight: 400;
  }
  &_title {
    font-weight: 400;
    color: #7a7a7a;
    font-size: 13px;
    max-width: 150px;
  }
  &_category {
    font-size: 12px;
    padding-right: 10px;
    &_active {
      color: $primary-blue;
    }
  }
  &_category-title {
    margin-bottom: 5px;
  }
  &_blue {
    color: $primary-blue;
  }
}

.categories-filter__menu {
  position: absolute;
  z-index: 10;
  background-color: $primary-white;
  top: 80px;
  left: 0;
  border-radius: 12px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  max-width: 1310px;
  width: calc(100vw - 40px);
  @include desktop-screen {
    width: calc(100vw - 120px);
  }
}

.categories-filter__menu-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  padding: 20px 0px 10px 40px;
  overflow: auto;
  height: calc(100vh - 300px);
  max-height: 1000px;
}

.categories-filter__all-reset-icon {
  position: absolute;
  left: 13px;
  top: 13px;
  color: $primary-lightgray;
}

.categories-filter__icon {
  color: #9B9B9B;
}
</style>
