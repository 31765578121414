<template>
  <div class="group-robot">
    <span
    class="group-robot__text"
    :class="isAdmin && 'pointer'"
    @click="openEditor">
      {{ getRobotValue }}
    </span>

    <transition name="fade">
      <div
      v-if="showEditor"
      v-click-outside="onClickOutside"
      class="group-robot__editor">
        <div class="group-robot__editor-element">
          <span class="group-robot__text_light">
            {{ $t('Edit a robot') }}
          </span>
          <input
          v-model="robotValue"
          type="text"
          :placeholder="$t('New value')"
          class="group-robot__input">
        </div>
        <DataButton
        :text="$t('save')"
        type="text"
        @handler="saveChanges" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'GroupRobot',
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    robot: {
      type: [String, Number],
      default: null,
    },
    disableEditor: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showEditor: false,
    robotValue: null,
  }),
  methods: {
    openEditor() {
      if (!this.isAdmin || this.disableEditor) return;

      this.robotValue = this.robot;
      this.showEditor = true;
    },
    closeEditor() {
      this.showEditor = false;
      this.robotValue = null;
    },
    onClickOutside() {
      this.closeEditor();
    },
    saveChanges() {
      const newData = {
        roboticity: this.robotValue === '' ? null : this.robotValue,
      };

      this.showEditor = false;

      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData })
        .then(() => {
          this.group.roboticity = this.robotValue === '' ? null : this.robotValue;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
        });
    },
  },
  computed: {
    getRobotValue() {
      return this.robot ? `${this.robot}%` : '—';
    },
  },
};
</script>

<style lang="scss" scoped>
.group-robot {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 180px;
  min-height: 50px;
  position: relative;
}

.group-robot__text {
  font-size: 12px;
  color: $primary-gray;
  text-align: center;
  word-break: break-all;
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

.group-robot__editor {
  position: absolute;
  top: 0px;
  left: 30px;
  min-width: 200px;
  border-radius: 12px;
  padding: 20px 20px 0px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-robot__editor-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.group-robot__input {
  padding: 5px 10px;
  border: 1px solid $primary-lightgray;
  border-radius: 8px;
  font-size: 12px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

</style>
