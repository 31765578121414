<template>
  <div class="client-export-statistic-modal">
    <span class="client-export-statistic-modal__title">Экспорт статистики по клиентам</span>

    <div class="client-export-statistic-modal__checkboxes">
      <div class="client-export-statistic-modal__checkbox">
        <DataCheckbox v-model="withAccessToCatalog" />
        <span>Только клиенты с доступом</span>
      </div>
      <div class="client-export-statistic-modal__checkbox">
        <DataCheckbox v-model="withCampaign" />
        <span>Есть РК</span>
      </div>
      <div class="client-export-statistic-modal__checkbox">
        <DataCheckbox v-model="withRequestPlacement" />
        <span>Есть запросы</span>
      </div>
    </div>

    <DataButton
    :text="statisticIsLoading ? 'Загрузка...' : 'Скачать'"
    @handler="downloadStatistic" />
  </div>
</template>

<script>
import { getStatisticExport } from '../api';

export default {
  name: 'ClientExportStatisticModal',
  props: {
    filters: {
      type: Object,
      default: null,
    },
    statisticIsLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    withAccessToCatalog: true,
    withCampaign: true,
    withRequestPlacement: true,
  }),
  methods: {
    getStatisticExport,
    async downloadStatistic() {
      if (this.statisticIsLoading) return;

      this.$emit('setStatisticIsLoading', true);

      const params = {
        agencyName: this.filters.agencyName,
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
        withAccessToCatalog: this.withAccessToCatalog ? 1 : null,
        withCampaign: this.withCampaign ? 1 : null,
        withRequestPlacement: this.withRequestPlacement ? 1 : null,
      };

      getStatisticExport(params)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Статистика по клиентам.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка. Попробуйте повторить позже', error: true });
          console.warn(error);
        })
        .finally(() => {
          this.$emit('setStatisticIsLoading', false);
          this.$emit('close');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.client-export-statistic-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .client-export-statistic-modal__title {
    color: $primary-gray;
    font-size: 20px;
    margin-bottom: 40px;
  }

  .client-export-statistic-modal__checkboxes {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
    min-width: 400px;
  };

  .client-export-statistic-modal__checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include between-elements {
      margin-right: 10px;
    }
  }
}
</style>
