<template>
  <div
  class="group-name">
    <a
    :href="getGroupLink"
    target="_blank"
    class="group-name__avatar-block pointer">
      <img
      class="group-name__avatar"
      :class="[
        !hideDetails && 'group-name__avatar_full',
        isBannedGroup || getIsWarning ? 'group-name__avatar_banned': '',
      ]"
      :src="avatarSrc ? avatarSrc : require('@/assets/svg/empty-avatar.svg')">
      <img
      class="group-name__social-icon"
      :class="!hideDetails && 'group-name__social-icon_full'"
      :src="require(`@/assets/svg/groups-icons/${group.social}-icon.svg`)">
      <div
      v-if="isAdmin && group.fromPublisherBot"
      v-tooltip="'Канал добавлен через бота'"
      class="group-name__bot-icon">
        <span class="group-name__bot-icon-text">A</span>
      </div>
    </a>
    <div
    class="group-name__block"
    :class="!hideDetails && 'group-name__block_full'">
      <a
      :href="getGroupLink"
      target="_blank"
      :title="group.name"
      class="group-name__text group-name__text_name"
      :class="!hideDetails && 'group-name__text_full'">
        {{ group.name }}
      </a>
      <span
      v-if="group.categories"
      :title="getCategories"
      class="group-name__text group-name__text_category"
      :class="!hideDetails && 'group-name__text_full'">
        {{ getCategories }}
      </span>
      <div
      class="group-name__icons-block"
      :class="!hideDetails && 'group-name__icons-block_full'">
        <img
        v-if="isBannedGroup"
        v-tooltip="getBannedTitle"
        class="group-name__icon"
        :src="require(`@/assets/svg/icons/ban-icon.svg`)"
        alt="ban">

        <img
        v-if="getIsWarning"
        v-tooltip="getIsWarning"
        class="group-name__icon"
        :src="require(`@/assets/svg/icons/warn-icon-red.svg`)"
        alt="ban">

        <img
        v-if="getAllWarnings"
        v-tooltip="getAllWarnings"
        class="group-name__icon"
        :src="require(`@/assets/svg/icons/warn-icon.svg`)"
        alt="warnings">
      </div>
      <img
      class="group-name__link-icon pointer"
      :class="hover && 'group-name__link-icon_show'"
      src="@/assets/svg/icons/link-icon.svg"
      @click="routeToCurrentGroupLink">
      <div
      v-tooltip="getPriceIndicatorText"
      class="group-name__price-indicator">
        <div
        class="group-name__price-indicator-circle"
        :class="`group-name__price-indicator-circle_${getPriceColor}`" />
      </div>
    </div>
  </div>
</template>

<script>
import getBaseUrl from '@/assets/js/helpers';

export default {
  name: 'GroupName',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    disableEditor: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentPriceColor: null,
    priceIdicatorText: null,
    productsId: {
      postPrice: 1,
      storyPrice: 2,
      reelPrice: 3,
      streamPrice: 4,
      integrationPrice: 5,
      circlePrice: 6,
      nonStandartPrice: 7,
    },
  }),
  methods: {
    routeToCurrentGroupLink() {
      if (!this.group.url.match(/^https?:\/\//i)) {
        window.open(`https://${this.group.url}`);
      } else {
        window.open(this.group.url);
      }
    },
  },
  computed: {
    getPriceColor() {
      if (!this.getLastUpdateDate && this.getLastUpdateDate !== 0) {
        return 'default';
      }
      if (this.getLastUpdateDate < 8) {
        return 'green';
      }
      if (this.getLastUpdateDate >= 8 && this.getLastUpdateDate <= 14) {
        return 'lightgreen';
      }
      if (this.getLastUpdateDate > 14 && this.getLastUpdateDate <= 30) {
        return 'orange';
      }
      if (this.getLastUpdateDate > 30) {
        return 'red';
      }
      return 'default';
    },
    getPriceIndicatorText() {
      if (!this.getLastUpdateDate && this.getLastUpdateDate !== 0) {
        return 'Цены не обновлялись';
      }
      if (this.getLastUpdateDate === 0) {
        return 'Цена обновлена сегодня';
      }
      const days = this.getPluralWord(this.getLastUpdateDate, ['день', 'дня', 'дней']);
      return `Цена обновлена ${this.getLastUpdateDate} ${days} назад`;
    },
    getGroupLink() {
      return `${getBaseUrl()}/group/${this.group.social}/${this.group.id}`;
    },
    isBannedGroup() {
      return this.group.status === 3;
    },
    getIsWarning() {
      // проверка на наличие в warnings элементов связанных с накруткой тгстат, телеметр
      const isWarning = this.group.inspectReport?.warnings?.find((item) => item.code === 7);
      return isWarning ? 'Канал заподозрен в накрутке' : false;
    },
    getCategories() {
      return this.group.categories.map((category) => this.$t(category.name)).join(', ');
    },
    getBannedTitle() {
      return this.getBanReasons
        ? `${this.$t('The channel is banned')}. ${this.$t('Cause')}: ${this.getBanReasons}`
        : this.$t('The channel is banned');
    },
    getAllWarnings() {
      // Ворнинги строкой через запятую
      if (!this.group?.inspectReport?.warnings.length) {
        return false;
      }
      // NEED TRANSLATE
      // необходимо удалить из ворнингов ER и накрутку, так как их выводим в других иконках
      const warnings = this.group.inspectReport?.warnings.filter((item) => item.code !== 5 && item.code !== 7).map((item) => item.message).join(', ');
      return warnings;
    },
    getBanReasons() {
      // Cheatings + причины бана строкой через запятую
      if (!this.group?.inspectReport?.cheatings.length && !this.group.badReason) {
        return false;
      }
      const cheatings = this.group.inspectReport?.cheatings.map((item) => item.message).slice(0, 2) || [];
      if (this.group.badReason && this.group.badReason.length) {
        const badreason = this.group.badReason.split(', ');
        cheatings.unshift(...badreason);
      }
      const reasons = new Set(cheatings);
      return Array.from(reasons).join(',');
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    getLastUpdateDate() {
      try {
        const currentPriceId = this.productsId[this.getSelectedPriceType];
        const currentProducts = this.group.products.filter((item) => item.type === currentPriceId); // находим объекты с нужным продуктом
        if (!currentProducts) return null;
        const currentProduct = currentProducts.find((item) => {
          if (item.durationInHours) {
            return String(item.durationInHours) === String(this.getSelectedPriceVariant?.value);
          }
          if (item.circleDurationInSeconds) {
            return String(item.circleDurationInSeconds) === String(this.getSelectedPriceVariant?.value);
          }
          return item;
        });
        const lastUpdatedDate = currentProduct?.tradeOffersChain.tradeOffers[0]?.updatedAt?.date || null;
        return lastUpdatedDate ? this.moment(new Date()).diff(lastUpdatedDate, 'days') : null;
      } catch (error) {
        console.error('Ошибка в getLastUpdateDate', error);
        return null;
      }
    },
    getSelectedPriceType() {
      // Тип цены, выбранный в фильтрах
      if (this.isCompilation) {
        return this.$store.getters['compilations/getSelectedPriceType'];
      }
      return this.$store.getters['groups/getSelectedPriceType'];
    },
    getSelectedPriceVariant() {
      // Вариант цены, выбранный в фильтрах
      if (this.isCompilation) {
        return this.$store.getters['compilations/getSelectedPriceVariant'];
      }
      return this.$store.getters['groups/getSelectedPriceVariant'];
    },
    avatarSrc() {
      if (this.group.social === 'mave') {
        return this.group.coverImage ? this.group.coverImage : null;
      }
      return this.group.avatar ? this.group.avatar : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-name {
  display: flex;
  align-items: center;
  width: 150px;
  height: 69px;
  z-index: 10;
  @include tablet-screen {
    margin-left: 0px;
    width: 422px;
  }
  &_full {
    height: 124px;
  }
  &:hover {
    .group-name__link-icon {
      opacity: 1;
    }
  }
}

.group-name__avatar-block {
  position: relative;
}

.group-name__block {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  align-items: flex-start;
  overflow: hidden;
  text-decoration: none;
  @include between-elements {
    margin-bottom: 2px;
  }
  &_full {
    @include between-elements {
      margin-bottom: 5px;
    }
  }
  @include tablet-screen {
    width: 100%;
    margin-left: 0px;
  }
}

.group-name__avatar {
  display: none;
  @include tablet-screen {
    display: block;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 50%;
    transition: 0.2s;
  }
  &_full {
    margin: 0px 17px;
    width: 50px;
    height: 50px;
    transition: 0.2s;
  }
  &_banned {
    border: 2px solid $primary-red;
  }
}

.group-name__social-icon {
  display: none;
  @include tablet-screen {
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: -4px;
    left: 30px;
    border-radius: 50%;
    box-shadow: -3px 3px 7px -1px rgb(46 50 50 / 50%);
    transition: 0.2s;
  }
  &_full {
    width: 20px;
    height: 20px;
    top: -5px;
    left: 50px;
    transition: 0.2s;
  }
}

.group-name__bot-icon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: -4px;
  left: -5px;
  border-radius: 50%;
  box-shadow: -1px 3px 7px -1px rgba(46, 50, 50, 0.5);
  background: $primary-green;
  display: flex;
  justify-content: center;
  align-items: center;
}

.group-name__bot-icon {
  font-size: 11px;
  font-weight: 700;
  color: white;
}

.group-name__text {
  font-size: 14px;
  color: $primary-gray;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 300px;
  padding-right: 30px;
  &_full {
    -webkit-line-clamp: 2;
  }
  &_name {
    color: $primary-blue;
    text-decoration: none;
  }
  &_desc {
    font-size: 12px;
    color: $primary-gray;
  }
  &_category {
    font-size: 12px;
    color: $primary-lightgray;
  }
  &_er {
    font-size: 9px;
    color: $primary-white;
  }
}

.group-name__icons-block {
  display: none;
  @include tablet-screen {
    display: flex;
    align-items: center;
    z-index: 2;
    @include between-elements {
      margin-right: 8px;
    }
  }
}

.group-name__icon-block {
  width: 20px;
  height: 15px;
  border-radius: 5px;
  background: $primary-lightred;
  &_warning {
    border-radius: 3px;
    width: 25px;
    height: 18px;
    background: $primary-yellow;
  }
}

.group-name__icon {
  width: 18px;
  height: 18px;
}

.group-name__link-icon {
  display: none;
  @include tablet-screen {
    display: block;
    position: absolute;
    right: 10px;
    top: 25px;
    width: 20px;
    height: 20px;
    opacity: 0;
    transition: 0.3s;
  }
}

.group-name__price-indicator {
  width: 35px;
  height: 16px;
  position: absolute;
  top: 6px;
  right: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.group-name__price-indicator-circle {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: rgba($primary-lightgray, 0.5);
  &_default {
    background: rgba($primary-lightgray, 0.5);
  }
  &_green {
    background: #1ec52a;
  }
  &_lightgreen {
    background: #79e781;
  }
  &_orange {
    background: #ffa654;
  }
  &_red {
    background: #ff451c
  }
}
</style>
