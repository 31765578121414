<template>
  <div class="request-filters">
    <div class="request-filters__filters-container">
      <div class="request-filters__filter">
        <span class="request-filters__filter-title">Название кампании</span>
        <DataInput
        :value="campaignName"
        placeholder="Название кампании"
        theme="white"
        clear-icon
        @input="updateCampaignName" />
      </div>
      <div
      v-if="!isAgency"
      class="request-filters__filter">
        <span class="request-filters__filter-title">Клиент</span>
        <DataSelect
        :options="clientNames"
        :value="clientName"
        placeholder="Клиент"
        no-option-label="Клиенты не найдены"
        theme="white"
        @input="setClientNameFilter" />
      </div>
      <div class="request-filters__filter">
        <span class="request-filters__filter-title">Статус</span>
        <DataSelect
        :options="statuses"
        :value="selectedStatus"
        :disabled="selectedRequestsTab === 'new'"
        placeholder="Статус"
        theme="white"
        @input="setStatusFilter" />
      </div>
      <div class="request-filters__filter">
        <span class="request-filters__filter-title">Ответственный в SJ</span>
        <DataSelect
        :options="responsibleNames"
        :value="responsibleName"
        placeholder="Ответственный"
        theme="white"
        @input="setResponsibleName" />
      </div>
      <div class="request-filters__filter">
        <span class="request-filters__filter-title">Дата запроса</span>
        <DataDatePicker
        v-model="currentDate"
        theme="white"
        @input="setCurrentDate" />
      </div>
    </div>

    <span
    v-if="isActiveFilters"
    class="request-filters__reset"
    @click="resetFilters">Сбросить все фильтры</span>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'RequestFilters',
  props: {
    isActiveFilters: {
      type: Boolean,
      default: false,
    },
    currentSearchParams: {
      type: Object,
      default: () => {},
    },
    selectedRequestsTab: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    statuses: [
      { id: 1, name: 'Новый' },
      { id: 2, name: 'В работе' },
      { id: 3, name: 'Готов' },
    ],
    selectedStatus: null,
    clientNames: [],
    clientName: null,
    responsibleNames: [],
    responsibleName: null,
    currentDate: null,
    campaignName: null,
    debounceCampaignsName: null,
  }),
  async created() {
    const agencyId = this.isAgency ? this.$store.getters['user/getUserInfo']?.asEmployerAgency?.id : null;
    const ownerParams = { ownerAgencyId: agencyId, isAgency: this.isAgency };
    const responsibleNamesData = await this.$store.dispatch('placements/getResponsibleNames', ownerParams);
    this.responsibleNames = responsibleNamesData
      .map((item, index) => ({ id: index, name: item.name || item.email, value: item.id }));

    const clientNamesData = this.isAgency ? null : await this.$store.dispatch('placements/getClientsNames', ownerParams);
    this.clientNames = clientNamesData ? clientNamesData.map((item, index) => ({ id: index, name: item.name, value: item.id })) : [];

    this.debounceCampaignsName = debounce((value) => {
      this.setCampaignName(value);
    }, 600);
  },
  methods: {
    resetFilters() {
      this.reset();
      this.$store.commit('placements/resetRequestsSearchParams');
      this.$emit('changeSelectedRequestTab', 'my');
    },
    reset() {
      this.clientName = null;
      this.responsibleName = null;
      this.selectedStatus = null;
      this.currentDate = null;
      this.campaignName = null;
    },
    updateCampaignName(value) {
      if (value) {
        this.debounceCampaignsName(value);
        return;
      }
      this.setCampaignName();
    },
    setCampaignName(value) {
      this.campaignName = value || null;
      const params = { ...this.currentSearchParams, campaignsName: this.campaignName };
      this.$store.commit('placements/setRequestsSearchParams', params);
      this.$emit('loadRequests', { reset: true });
    },
    setStatusFilter(status) {
      this.selectedStatus = status;
      const params = { ...this.currentSearchParams, status: status ? status.id : null };
      this.$store.commit('placements/setRequestsSearchParams', params);
      this.$emit('loadRequests', { reset: true });
    },
    setClientNameFilter(value) {
      this.clientName = value || null;
      const params = { ...this.currentSearchParams, ownerAgencyId: this.clientName ? this.clientName.value : null };
      this.$store.commit('placements/setRequestsSearchParams', params);
      this.$emit('loadRequests', { reset: true });
    },
    setResponsibleName(value) {
      this.responsibleName = value || null;
      const params = { ...this.currentSearchParams, responsibleUserId: this.responsibleName ? this.responsibleName.value : null };
      this.$store.commit('placements/setRequestsSearchParams', params);
      this.$emit('loadRequests', { reset: true });
    },
    setCurrentDate() {
      const fromDate = this.currentDate ? `${this.currentDate} 00:00` : null;
      const toDate = this.currentDate ? `${this.currentDate} 23:59` : null;
      const params = {
        ...this.currentSearchParams,
        createdAtFrom: fromDate,
        createdAtTo: toDate,
      };
      this.$store.commit('placements/setRequestsSearchParams', params);
      this.$emit('loadRequests', { reset: true });
    },
  },
  computed: {
    getCurrentSearchParams() {
      return this.$store.getters['placements/getRequestsSearchParams'];
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
  },
};
</script>

<style lang="scss" scoped>
.request-filters__filters-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
  position: relative;
  @include desktop-screen {
    max-width: 1200px;
  }
}

.request-filters__filter {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.request-filters__filter-title {
  font-size: 14px;
}

.request-filters__reset {
  display: block;
  padding: 10px 5px 5px 5px;
  cursor: pointer;
  font-size: 14px;
}
</style>
