<template>
  <div class="import-loader">
    <img src="@/assets/images/rocket-blue.gif">
    <div class="import-loader__content flex-center">
      <span class="import-loader__text">
        {{ $t('The file has been sent for import') }}
      </span>
      <span
      class="import-loader__text import-loader__text_upper pointer"
      @click="$router.push({ name: 'imports-screen' })">
        {{ $t('Go to the import results page') }}
      </span>
    </div>
    <DataIcon
    name="close"
    class="import-loader__text"
    :size="24"
    pointer
    @click.native="closeLoader" />
  </div>
</template>

<script>
export default {
  name: 'ImportLoader',
  data: () => ({
  }),
  methods: {
    closeLoader() {
      this.$emit('closeLoader');
    },
  },
};
</script>

<style lang="scss" scoped>
.import-loader {
  position: fixed;
  left: 50%;
  margin-left: -300px;
  top: 40px;
  background: $primary-blue;
  height: 120px;
  border-radius: 12px;
  z-index: 25;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  padding: 40px;
  @include between-elements {
    margin-right: 40px;
  }
}

.import-loader__text {
  font-size: 16x;
  color: $primary-white;
  &_upper {
    font-size: 12px;
    padding-bottom: 2px;
    border-bottom: 1px solid white;
  }
}

.import-loader__content {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 10px;
  }
}
</style>
