<template>
  <div>
    <div class="clients-list">
      <ClientHeaders />
      <ClientElement
      v-for="(client, index) in clientsData"
      ref="clientElement"
      :key="client.name + client.id"
      :client="client"
      :last-element="clientsData.length > 1 && index === clientsData.length - 1"
      :sales-managers="salesManagers"
      :account-managers="accountManagers"
      @openDeleteClient="openDeleteClient"
      @openEditAgency="openEditAgency"
      @openEditClientAgency="openEditClientAgency"
      @openEditEmployeeAgency="openEditEmployeeAgency"
      @closeAllClients="closeAllClients" />
    </div>

    <!-- Модалка удаления агенства -->
    <DataModal
    :show-modal="showDeleteClientModal"
    @close="showDeleteClientModal = false">
      <template #modal-content>
        <div class="clients-list__confirm-delete-modal">
          <span> {{ getLabelForDelete }}
            <br><span class="clients-list__text_bold">
              {{ currentClient.name }}?
            </span>
          </span>
          <div class="clients-list__buttons">
            <DataButton
            text="Удалить"
            @handler="confirmDeleteClient" />
            <DataButton
            text="Отменить"
            type="outlined"
            @handler="showDeleteClientModal = false" />
          </div>
        </div>
      </template>
    </DataModal>

    <!-- Модалка редактирования клиента -->
    <DataModal
    :show-modal="showEditAgencyModal"
    @close="closeModals">
      <template #modal-content>
        <AddClientModal
        :is-admin="isAdmin"
        is-edit-company
        :sales-managers="salesManagers"
        :account-managers="accountManagers"
        modal-type="addCompany"
        :current-client="currentClient"
        @close="closeModals" />
      </template>
    </DataModal>

    <!-- Модалка редактирования клиента агенства-->
    <DataModal
    :show-modal="showEditClientAgencyModal"
    @close="closeModals">
      <template #modal-content>
        <EditClientAgencyModal
        :client="currentClient"
        @close="closeModals" />
      </template>
    </DataModal>

    <!-- Модалка редактирования сотрудника агенства-->
    <DataModal
    :show-modal="showEditEmployeeAgencyModal"
    @close="closeModals">
      <template #modal-content>
        <EditEmployeeModal
        :client="currentClient"
        @close="closeModals" />
      </template>
    </DataModal>
  </div>
</template>

<script>
import ClientElement from './ClientElement.vue';
import ClientHeaders from './ClientHeaders.vue';
import AddClientModal from '../../../Modals/AddClientModal.vue';
import EditClientAgencyModal from '../../../Modals/EditClientAgencyModal.vue';
import EditEmployeeModal from '../../../Modals/EditEmployeeModal.vue';

// Копонент обёртка для списка клиентов (агентств)
export default {
  name: 'ClientsList',
  components: {
    ClientElement, ClientHeaders, AddClientModal, EditClientAgencyModal, EditEmployeeModal,
  },
  props: {
    clientsData: {
      type: Array,
      default: null,
    },
    accountManagers: {
      type: Array,
      default: null,
    },
    salesManagers: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    showDeleteClientModal: false,
    showEditAgencyModal: false,
    showEditClientAgencyModal: false,
    showEditEmployeeAgencyModal: false,
    currentClient: null,
    currentEmployee: null,
  }),
  methods: {
    closeAllClients() {
      this.$refs.clientElement.forEach((item) => { item.closeUsers(); });
    },
    closeModals() {
      this.showDeleteClientModal = false;
      this.showEditAgencyModal = false;
      this.showEditClientAgencyModal = false;
      this.showEditEmployeeAgencyModal = false;
      this.currentClient = null;
      this.currentEmployee = null;
    },
    openDeleteClient(params) {
      if (this.isAgency && this.getIsDemoCatalog) {
        this.$router.push({ name: 'tariffs' });
        return;
      }
      this.showDeleteClientModal = true;
      this.currentClient = params.client;
      this.currentClient.name = params.name;
    },
    openEditAgency(client) {
      this.showEditAgencyModal = true;
      this.currentClient = client;
    },
    openEditClientAgency(client) {
      if (this.getIsDemoCatalog) {
        this.$router.push({ name: 'tariffs' });
        return;
      }
      this.showEditClientAgencyModal = true;
      this.currentClient = client;
    },
    openEditEmployeeAgency(employee) {
      this.showEditEmployeeAgencyModal = true;
      this.currentClient = employee;
    },
    confirmDeleteClient() {
      if (this.isAdmin) {
        this.$store.dispatch('clients/deleteClientFromAdmin', this.currentClient.id)
          .then(() => {
            this.$store.dispatch('app/setSnackBar', { text: `Успешно. Клиент «${this.currentClient.name}» удалён` });
          })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar', { text: this.$t('Произошла ошибка при удалении клиента. Повторите позже'), error: true });
          })
          .finally(() => {
            this.closeModals();
          });
      }
      if (this.isAgency) {
        const params = { agencyId: this.$store.getters['user/getUserInfo']?.asEmployerAgency?.id, userId: this.currentClient.id };
        this.$store.dispatch('clients/deleteClientFromAgency', params)
          .then(() => {
            this.$store.dispatch('app/setSnackBar', { text: `Успешно. Клиент «${this.currentClient.name}» удалён` });
          })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar', { text: this.$t('Произошла ошибка при удалении клиента. Повторите позже'), error: true });
          })
          .finally(() => {
            this.closeModals();
          });
      }
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
    getLabelForDelete() {
      return this.isAdmin ? 'Вы точно хотите удалить агентство' : 'Вы точно хотите удалить клиента';
    },
    getIsDemoCatalog() {
      return this.$store.getters['user/getIsDemoCatalog'];
    },
  },
};
</script>

<style lang="scss" scoped>
.clients-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.clients-list__confirm-delete-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  @include between-elements {
    margin-bottom: 40px;
  }
}

.clients-list__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include between-elements {
    margin-right: 20px;
  }
}

.clients-list__text {
  font-size: 14px;
  &_bold {
    font-weight: bold;
  }
}
</style>
