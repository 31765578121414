<template>
  <div class="group-admins">
    <div
    class="group-admins__content"
    :class="isAdmin && 'pointer'"
    @click="openEditor">
      <div class="group-admins__element">
        <span
        class="group-admins__text">
          {{ $t('payment_type') + ':' }}
        </span>
        <span class="group-admins__text group-admins__text_light">
          {{ getPaymentTypesLabel() }}
        </span>
      </div>
      <div class="group-admins__element">
        <span
        class="group-admins__text">
          {{ $t('in_sj') + ':' }}
        </span>
        <span class="group-admins__text group-admins__text_light">
          {{ inSocialJet ? $t('yes') : $t('no') }}
        </span>
      </div>
      <div
      v-if="!hideDetails"
      class="group-admins__element">
        <span
        class="group-admins__text">
          {{ $t('blogger') + ':' }}
        </span>
        <span class="group-admins__text_light">
          {{ isBlogger ? $t('yes') : $t('no') }}
        </span>
      </div>
      <div
      v-if="!hideDetails"
      class="group-admins__element">
        <span
        class="group-admins__text">
          {{ $t('blacklist') + ':' }}
        </span>
        <span class="group-admins__text_light">
          {{ inBlackList ? $t('yes') : $t('no') }}
        </span>
      </div>
    </div>

    <transition name="fade">
      <div
      v-if="showEditor"
      v-click-outside="onClickOutside"
      class="group-admins__editor">
        <span class="group-admins__text group-admins__text_light">
          {{ $t('Parameters') }}
        </span>
        <div class="group-admins__editor-element group-admins__editor-element_row">
          <DataCheckbox
          v-model="inSocialJetValue" />
          <span class="group-admins__text group-admins__text_small">
            {{ $t('In SocialJet') }}
          </span>
        </div>
        <div class="group-admins__editor-element group-admins__editor-element_row">
          <DataCheckbox
          v-model="isBloggerValue" />
          <span class="group-admins__text group-admins__text_small">
            {{ $t('Is blogger') }}
          </span>
        </div>
        <div class="group-admins__editor-element group-admins__editor-element_row">
          <DataCheckbox
          v-model="inBlackListValue" />
          <span class="group-admins__text group-admins__text_small">
            {{ $t('In black list') }}
          </span>
        </div>
        <div class="group-admins__editor-element">
          <span class="group-admins__text group-admins__text_light">
            {{ $t('payment_type') }}
          </span>
          <div
          v-for="payment in paymentOptions"
          :key="payment.id"
          class="group-admins__payment"
          @click="setPaymentType(payment)">
            <DataRadio :checked="paymentTypesIds.includes(payment.id)" />
            <span class="group-admins__text">
              {{ payment.name }}
            </span>
          </div>
        </div>
        <DataButton
        :text="$t('save')"
        type="text"
        @handler="saveChanges" />
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'GroupAdmins',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    paymentTypes: {
      type: Array,
      default: null,
    },
    isBlogger: {
      type: [String, Boolean],
      default: null,
    },
    inBlackList: {
      type: [String, Boolean],
      default: null,
    },
    inSocialJet: {
      type: [String, Boolean],
      default: null,
    },
    disableEditor: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showEditor: false,
    inSocialJetValue: null,
    inBlackListValue: null,
    isBloggerValue: null,
    paymentTypesIds: [],
    paymentOptions: [
      {
        id: null,
        name: 'Не указан',
      },
      {
        id: 1,
        name: 'ИП',
      },
      {
        id: 2,
        name: 'СЗ',
      },
      {
        id: 3,
        name: 'КЭШ',
      },
      {
        id: 4,
        name: 'Другое',
      },
      {
        id: 5,
        name: 'OOO',
      },
      {
        id: 6,
        name: 'Sollar',
      },
    ],
  }),
  methods: {
    openEditor() {
      if (!this.isAdmin || this.disableEditor) return;

      this.inSocialJetValue = this.inSocialJet;
      this.inBlackListValue = this.inBlackList;
      this.isBloggerValue = this.isBlogger;
      this.showEditor = true;
      this.initPaymentData();
    },
    closeEditor() {
      this.showEditor = false;
    },
    initPaymentData() {
      try {
        if (!this.paymentTypes || !this.paymentTypes.length) {
          this.paymentTypesIds = [null];
          return;
        }
        this.paymentTypesIds = this.paymentTypes.map((item) => item.id);
      } catch {
        this.paymentTypesIds = [null];
      }
    },
    onClickOutside() {
      this.closeEditor();
    },
    saveChanges() {
      const newData = {
        inSocialjet: this.inSocialJetValue,
        inBlackList: this.inBlackListValue,
        isBlogger: this.isBloggerValue,
        paymentTypesId: this.paymentTypesIds.includes(null) ? [] : this.paymentTypesIds,
      };

      this.showEditor = false;

      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData })
        .then(() => {
          this.group.inSocialjet = this.inSocialJetValue;
          this.group.inBlackList = this.inBlackListValue;
          this.group.isBlogger = this.isBloggerValue;
          this.group.paymentTypes = this.paymentTypesIds.map((item) => this.paymentOptions.find((option) => option.id === item));
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
        });
    },
    setPaymentType(payment) {
      if (payment.id === null) {
        this.paymentTypesIds = [null];
        return;
      }

      if (this.paymentTypesIds.includes(payment.id)) {
        this.paymentTypesIds = this.paymentTypesIds.filter((item) => item !== payment.id && item !== null);
      } else {
        this.paymentTypesIds.push(payment.id);
      }

      if (this.paymentTypesIds.includes(null)) {
        this.paymentTypesIds = this.paymentTypesIds.filter((item) => item !== null);
        return;
      }

      if (!this.paymentTypesIds.length) {
        this.paymentTypesIds = [null];
      }
    },
    getPaymentTypesLabel() {
      try {
        if (!this.paymentTypes || !this.paymentTypes.length) return '—';
        if (this.paymentTypes.some((item) => item.id === null)) return '—';
        return this.paymentTypes.map((item) => this.paymentOptions.find((option) => option.id === item.id).name).join(', ');
      } catch {
        return '?';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.group-admins {
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 50px;
  padding-left: 35px;
  position: relative;
}

.group-admins__content {
    @include between-elements {
    margin-bottom: 3px;
  }
}

.group-admins__text {
  font-size: 12px;
  margin-right: 2px;
  color: $primary-black;
  &_small {
    font-size: 12px;
  }
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

.group-admins__editor {
  position: absolute;
  top: 0px;
  left: 25px;
  min-width: 200px;
  border-radius: 12px;
  padding: 20px 20px 0px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-admins__editor-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 5px;
  }
  &_row {
    flex-direction: row;
    align-items: center;
    @include between-elements {
      margin-right: 5px;
      margin-bottom: 0px;
    }
  }
}

.group-admins__payment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 10px;
  }
}
</style>
