<template>
  <div class="export-groups">
    <div class="export-groups__header-block">
      <span class="export-groups__text export-groups__text_title">{{ getExportTitle }}</span>
      <span class="export-groups__text export-groups__text_subtitle">{{ $t('select_the_required_columns_to_export') }}</span>
    </div>
    <div class="export-groups__items">
      <div
      v-for="column in selectedColumns"
      :key="column.value"
      class="export-groups__item"
      :class="{ 'export-groups__item_margin-bottom': column.margin }">
        <DataCheckbox
        v-model="column.set" />
        <span class="export-groups__text export-groups__text_subtitle">{{ $t(column.name) }}</span>
      </div>
    </div>
    <div class="export-groups__line" />
    <div class="export-groups__additional">
      <div class="export-groups__select">
        <span
        class="export-groups__text export-groups__text_touch"
        @click="setAll(true)">{{ $t('select_all') }}</span>
        <span
        class="export-groups__text export-groups__text_touch"
        @click="setAll(false)">{{ $t('reset_all') }}</span>
      </div>
      <div
      v-if="isAdmin && !exportCollection"
      class="export-groups__select">
        <span
        v-tooltip="`${$t('All channels of the social network you are on will be exported')}`"
        class="export-groups__text export-groups__text_touch export-groups__text_small"
        @click="exportAllGroups">{{ $t('export_all_filtered_channels') }}</span>
        <DataIcon
        v-tooltip="`${$t('All channels of the social network you are on will be exported')}`"
        name="help_outline"
        class="export-groups__text_small"
        :size="16"
        pointer />
      </div>
    </div>
    <span
    v-show="lengthFieldsError || lenghtSelectedError"
    class="export-groups__text export-groups__text_error">
      {{ getErrorText }}
    </span>
    <div class="export-groups__button-block">
      <DataButton
      :text="$t('export2')"
      @handler="exportSelectedGroups" />
      <DataButton
      :text="$t('cancel')"
      type="outlined"
      @handler="close" />
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'ExportGroupsModal',
  props: {
    mode: {
      type: String,
      default: null,
    },
    compilation: {
      type: Object,
      default: null,
    },
    selectedGroups: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    selectedColumns: null,
    loading: false,
    lengthFieldsError: false,
    lenghtSelectedError: false,
  }),
  created() {
    // FUTURE старые копии колонок удалить
    localStorage.removeItem('savedExportColumns230623');
    localStorage.removeItem('savedExportColumns050723');
    localStorage.removeItem('savedExportColumns060723');
    //
    const savedColumns = JSON.parse(localStorage.getItem('savedExportColumns011223'));

    if (this.isAdmin) {
      this.selectedColumns = savedColumns || cloneDeep(this.getColumns);
    } else {
      const notAdminColumns = this.getColumns.filter((item) => item.show);
      this.selectedColumns = savedColumns || notAdminColumns;
    }
  },
  methods: {
    close() {
      if (this.loading) return;
      this.$emit('close');
    },
    setAll(value) {
      this.selectedColumns.forEach((item) => {
        item.set = value;
      });
    },
    resetErrors() {
      this.lengthFieldsError = false;
      this.lenghtSelectedError = false;
    },
    saveColumns() {
      localStorage.setItem('savedExportColumns011223', JSON.stringify(this.selectedColumns));
    },
    exportSelectedGroups() {
      this.resetErrors();
      this.saveColumns();

      const groupIdentities = this.getSelectedGroups.map((group) => ({ id: group.id, social: group.social }));

      if (!groupIdentities.length && !this.compilation) {
        this.lenghtSelectedError = true;
        return;
      }

      const fields = this.selectedColumns.filter((item) => item.set).map((item) => item.value);
      if (!fields.length) {
        this.lengthFieldsError = true;
        return;
      }
      this.loading = true;
      const params = {};
      const folder = this.compilation && this.compilation.isFolder && this.$store.getters['compilations/getSelectedFolder'];
      if (this.mode === 'noauth' || this.compilation) {
        params.shareToken = this.$route.query.shareToken;
        params.compilationId = this.compilation.id;
        params.compilationName = this.compilation.name;
        params.fields = fields;
        params.groupIdentities = this.compilation.selectedGroups ? groupIdentities : null;
        params.folderId = folder?.id || null;
      } else {
        params.fields = fields;
        params.groupIdentities = groupIdentities;
      }

      this.$store.dispatch('groups/exportGroups', params)
        .then(() => {
          this.loading = false;
          this.$store.commit('compilations/setTriggerForSelectedAllGroups', false);
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Successfully. Open the downloaded file') });
          this.$emit('close');
        })
        .catch(() => {
          this.loading = false;
          this.$emit('close');
        });
    },
    exportAllGroups() {
      this.resetErrors();
      this.saveColumns();
      const fields = this.selectedColumns.filter((item) => item.set).map((item) => item.value);
      if (!fields.length) {
        this.lengthFieldsError = true;
        return;
      }

      const params = {
        fields,
        ...this.getSearchParams,
      };

      this.loading = true;
      this.$store.dispatch('groups/exportGroups', params)
        .then(() => {
          this.loading = false;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Successfully. Open the downloaded file'), error: false });
          this.$emit('close');
        })
        .catch(() => {
          this.loading = false;
          this.$emit('close');
        });
    },
  },
  computed: {
    getSelectedGroups() {
      if (this.selectedGroups) {
        return this.selectedGroups;
      }
      if (this.compilation) {
        return this.$store.getters['compilations/getCompilationsSelectedList'];
      }
      return this.$store.getters['groups/getSelectedGroups'];
    },
    getSelectedTypeOfGroups() {
      return this.$store.getters['groups/getSelectedTypeOfGroups'];
    },
    getSearchParams() {
      return this.$store.getters['groups/getGroupsSearchParams'];
    },
    getErrorText() {
      if (this.lengthFieldsError) {
        return this.$t('to_export_you_must_select_at_least_1_column');
      }
      return this.$t('to_export_you_must_select_at_least_1_channel');
    },
    getExportTitle() {
      if (this.exportCollection) {
        const folder = this.exportCollection.isFolder && this.$store.getters['compilations/getSelectedFolder'];
        return folder?.name
          ? `Экспорт папки ${folder.name} из подборки ${this.exportCollection.name}`
          : `${this.$t('export_compilation')} ${this.exportCollection.name}`;
      }
      return this.$t('export_channels');
    },
    exportCollection() {
      if (this.compilation) {
        return this.compilation;
      }
      return null;
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
    getColumns() {
      return [
        {
          name: 'name2',
          value: 'name',
          set: true,
          show: true,
        },
        {
          name: 'link',
          value: 'url',
          set: true,
          show: true,
        },
        {
          name: 'avatar',
          value: 'avatar',
          set: true,
          show: this.isAdmin,
        },
        {
          name: 'description',
          value: 'description',
          set: true,
          show: true,
        },
        {
          name: 'categories',
          value: 'categories',
          set: true,
          show: true,
        },
        {
          name: 'subscribers_count',
          value: 'subscribersCount',
          set: true,
          show: true,
        },

        {
          name: 'cpm',
          value: 'cpm',
          set: true,
          show: true,
        },
        {
          name: 'posts_day',
          value: 'postsCountLast24h',
          set: true,
          show: true,
        },
        {
          name: 'posts_week',
          value: 'postsCountLastWeek',
          set: true,
          show: true,
        },
        {
          name: 'posts_month',
          value: 'postsCountLastMonth',
          set: true,
          show: true,
        },
        {
          name: 'er',
          value: 'er',
          set: true,
          show: true,
        },
        {
          name: 'male_percent',
          value: 'subscribersMalePercent',
          set: true,
          show: true,
        },
        {
          name: 'female_percent',
          value: 'subscribersFemalePercent',
          set: true,
          show: true,
        },
        {
          name: 'contacts',
          value: 'contact',
          set: true,
          show: this.isAdmin,
        },
        {
          name: 'comment',
          value: 'comment',
          set: true,
          show: this.isAdmin,
        },
        {
          name: 'commentByCompilation',
          value: 'commentByCompilation',
          set: true,
          show: true,
        },
        {
          name: 'monthly_subscriber_growth',
          value: 'subscribersMonthGrow',
          set: true,
          show: true,
        },
        {
          name: 'payment_type',
          value: 'paymentTypes',
          set: true,
          show: this.isAdmin,
        },
        {
          name: 'status',
          value: 'status',
          set: true,
          show: this.isAdmin,
        },
        {
          name: 'in_socialjet',
          value: 'inSocialjet',
          set: true,
          show: this.isAdmin,
        },
        {
          name: 'in_archived',
          value: 'isArchived',
          set: true,
          show: this.isAdmin,
        },
        {
          name: 'blogger',
          value: 'isBlogger',
          set: true,
          show: this.isAdmin,
        },
        {
          name: 'tags',
          value: 'tags',
          set: true,
          show: this.isAdmin,
        },
        {
          name: 'bot_name',
          value: 'botName',
          set: true,
          show: this.isAdmin,
        },
        {
          name: 'update',
          value: 'updatedAt',
          set: true,
          show: true,
        },
        {
          name: 'advertising_label',
          value: 'advertisingLabel',
          set: true,
          show: true,
        },
        {
          name: 'location',
          value: 'subscribersCountries',
          set: true,
          show: true,
        },
        {
          name: 'city',
          value: 'subscribersCities',
          set: true,
          show: true,
        },
        {
          name: 'cpc',
          value: 'cpc',
          set: true,
          show: true,
        },
        {
          name: 'robot',
          value: 'roboticity',
          set: true,
          show: true,
        },
        {
          name: 'Age',
          value: 'subscribersAge',
          set: true,
          show: true,
        },
        {
          name: 'interests',
          value: 'interests',
          set: true,
          show: true,
        },
        // views
        {
          name: 'views_24h',
          value: 'viewsCountLast24h',
          set: true,
          show: true,
        },
        {
          name: 'views_48h',
          value: 'viewsCountLast48h',
          set: true,
          show: true,
        },
        {
          name: 'views_72h',
          value: 'viewsCountLast72h',
          set: true,
          show: true,
          margin: true,
        },
        {
          name: 'isTopGroup',
          value: 'isTopGroup',
          set: true,
          show: this.isAdmin,
        },
        // Without AD
        {
          name: 'withoutAd',
          value: 'withoutAd',
          set: true,
          show: true,
        },
        // Without AD
        {
          name: 'discount',
          value: 'discount',
          set: true,
          show: this.isAdmin,
        },
        // Price without Delete
        {
          name: 'postPriceSellWithoutDelete',
          value: 'postPriceSell',
          set: true,
          show: true,
        },
        {
          name: 'postPriceBuyWithoutDelete',
          value: 'postPrice',
          set: true,
          show: this.isAdmin || this.isAgency,
        },
        {
          name: 'postPriceSaleWithoutDelete',
          value: 'postPriceSale',
          set: true,
          show: this.isAdmin,
        },
        // POST FIXED
        {
          name: 'fixedInTopPriceSell',
          value: 'fixedInTopPriceSell',
          set: true,
          show: true,
        },
        {
          name: 'fixedInTopPriceBuy',
          value: 'fixedInTopPrice',
          set: true,
          show: this.isAdmin || this.isAgency,
        },
        {
          name: 'fixedInTopPriceSale',
          value: 'fixedInTopPriceSale',
          set: true,
          show: this.isAdmin,
        },
        // PRICE 24
        {
          name: 'postPrice24hSell',
          value: 'postPrice24hSell',
          set: true,
          show: true,
        },
        {
          name: 'postPrice24hBuy',
          value: 'postPrice24h',
          set: true,
          show: this.isAdmin || this.isAgency,
        },
        {
          name: 'postPrice24hSale',
          value: 'postPrice24hSale',
          set: true,
          show: this.isAdmin,
        },
        // PRICE 48
        {
          name: 'postPrice48hSell',
          value: 'postPrice48hSell',
          set: true,
          show: true,
        },
        {
          name: 'postPrice48hBuy',
          value: 'postPrice48h',
          set: true,
          show: this.isAdmin || this.isAgency,
        },
        {
          name: 'postPrice48hSale',
          value: 'postPrice48hSale',
          set: true,
          show: this.isAdmin,
        },
        // PRICE 72
        {
          name: 'postPrice72hSell',
          value: 'postPrice72hSell',
          set: true,
          show: true,
        },
        {
          name: 'postPrice72hBuy',
          value: 'postPrice72h',
          set: true,
          show: this.isAdmin || this.isAgency,
        },
        {
          name: 'postPrice72hSale',
          value: 'postPrice72hSale',
          set: true,
          show: this.isAdmin,
        },

        // Story price SELL
        {
          name: 'storyPriceSell',
          value: 'storyPriceSell',
          set: true,
          show: true,
        },
        // Story price BUY
        {
          name: 'storyPriceBuy',
          value: 'storyPrice',
          set: true,
          show: this.isAdmin || this.isAgency,
        },
        // Story price SALE
        {
          name: 'storyPriceSale',
          value: 'storyPriceSale',
          set: true,
          show: this.isAdmin,
        },

        // Stream price SELL
        {
          name: 'streamPriceSell',
          value: 'streamPriceSell',
          set: true,
          show: true,
        },
        // Stream price BUY
        {
          name: 'streamPriceBuy',
          value: 'streamPrice',
          set: true,
          show: this.isAdmin || this.isAgency,
        },
        // Stream price SALE
        {
          name: 'streamPriceSale',
          value: 'streamPriceSale',
          set: true,
          show: this.isAdmin,
        },

        // Integration price SELL
        {
          name: 'integrationPriceSell',
          value: 'integrationPriceSell',
          set: true,
          show: true,
        },
        // Integration price BUY
        {
          name: 'integrationPriceBuy',
          value: 'integrationPrice',
          set: true,
          show: this.isAdmin || this.isAgency,
        },
        // Integration price SALE
        {
          name: 'integrationPriceSale',
          value: 'integrationPriceSale',
          set: true,
          show: this.isAdmin,
        },
        // Circle 15s
        {
          name: 'circlePrice15sSell',
          value: 'circlePrice15sSell',
          set: true,
          show: true,
        },
        {
          name: 'circlePrice15sBuy',
          value: 'circlePrice15s',
          set: true,
          show: this.isAdmin || this.isAgency,
        },
        {
          name: 'circlePrice15sSale',
          value: 'circlePrice15sSale',
          set: true,
          show: this.isAdmin,
        },
        // CIRCLE 30
        {
          name: 'circlePrice30sSell',
          value: 'circlePrice30sSell',
          set: true,
          show: true,
        },
        {
          name: 'circlePrice30sBuy',
          value: 'circlePrice30s',
          set: true,
          show: this.isAdmin || this.isAgency,
        },
        {
          name: 'circlePrice30sSale',
          value: 'circlePrice30sSale',
          set: true,
          show: this.isAdmin,
        },
        // CIRCLE 60
        {
          name: 'circlePrice60sSell',
          value: 'circlePrice60sSell',
          set: true,
          show: true,
        },
        {
          name: 'circlePrice60sBuy',
          value: 'circlePrice60s',
          set: true,
          show: this.isAdmin || this.isAgency,
        },
        {
          name: 'circlePrice60sSale',
          value: 'circlePrice60sSale',
          set: true,
          show: this.isAdmin,
        },
        // nonstandard
        {
          name: 'nonstandardPriceSell',
          value: 'nonstandardPriceSell',
          set: true,
          show: true,
        },
        {
          name: 'nonstandardPrice',
          value: 'nonstandardPrice',
          set: true,
          show: this.isAdmin || this.isAgency,
        },
        {
          name: 'nonstandardPriceSale',
          value: 'nonstandardPriceSale',
          set: true,
          show: this.isAdmin,
        },
        // VK MARKET
        {
          name: 'vkMarketPriceSell',
          value: 'vkMarketPriceSell',
          set: true,
          show: true,
        },
        {
          name: 'vkMarketPrice',
          value: 'vkMarketPrice',
          set: true,
          show: this.isAdmin || this.isAgency,
        },
        {
          name: 'vkMarketPriceSale',
          value: 'vkMarketPriceSale',
          set: true,
          show: this.isAdmin,
        },
        //
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.export-groups {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @include desktop-screen {
    width: 85vw;
    max-width: 1500px;
  }
}

.export-groups__header-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
}

.export-groups__text {
  color: $primary-black;
  font-size: 14px;
  &_title {
    font-size: 22px;
    margin-bottom: 10px;
  }
  &_touch {
    cursor: pointer;
  }
  &_error {
    color: $primary-red;
  }
  &_small {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

.export-groups__items {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: scroll;
  padding-bottom: 10px;
  max-height: 450px;
  @include between-elements {
    margin-bottom: 5px;
  }
  @include desktop-screen {
    flex-direction: column;
      flex-wrap: wrap;
  }
}

.export-groups__item {
  display: flex;
  width: 270px;
  align-items: center;
  margin-right: 10px;
  @include between-elements {
    margin-right: 10px;
  }
  &_margin-bottom {
    padding-bottom: 40px
  }
}

.export-groups__button-block {
  padding-top: 20px;
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}

.export-groups__line {
  height: 0;
  border: none;
  border-top: 1px solid rgb(212, 212, 212);
}

.export-groups__additional {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.export-groups__select {
  display: flex;
  align-items: center;
  @include between-elements {
    margin-right: 10px;
  }
}

.export-group__loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  border-radius: 12px;
}
</style>
