import { render, staticRenderFns } from "./GroupAdmins.vue?vue&type=template&id=9b67aff4&scoped=true"
import script from "./GroupAdmins.vue?vue&type=script&lang=js"
export * from "./GroupAdmins.vue?vue&type=script&lang=js"
import style0 from "./GroupAdmins.vue?vue&type=style&index=0&id=9b67aff4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b67aff4",
  null
  
)

export default component.exports