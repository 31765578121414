<template>
  <div class="client-balance">
    <div class="client-balance__inputs">
      <DataSelect
      v-model="selectedBalanceOption"
      no-clear-select
      :options="balanceOptions" />
      <DataInput
      v-model="balanceValue"
      input-type="number"
      :error="balanceValueError && !balanceValue.length"
      placeholder="Введите сумму" />
    </div>
    <DataButton
    icon="done"
    width="40"
    height="40"
    @handler="setBalance" />
  </div>
</template>

<script>
export default {
  name: 'ClientBalance',
  props: {
    client: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    selectedBalanceOption: { name: 'Зачислить', value: 'add' },
    balanceOptions: [{ name: 'Зачислить', value: 'add' }, { name: 'Вычесть', value: 'deduct' }],
    balanceValue: '',
    balanceValueError: false,
    isLoading: false,
  }),
  methods: {
    setBalance() {
      const currentBalance = Number(this.client.account?.balance) || 0;
      const isNegative = this.selectedBalanceOption.value === 'deduct' && (currentBalance - Number(this.balanceValue) < 0);
      if (!this.balanceValue.length || isNegative) {
        this.balanceValue = '';
        this.balanceValueError = true;
        return;
      }

      if (this.isLoading) return;

      this.isLoading = true;
      this.balanceValueError = false;
      const params = {
        amount: this.selectedBalanceOption.value === 'deduct' ? `-${this.balanceValue}` : this.balanceValue,
        agencyId: this.client.id,
      };
      this.$store.dispatch('clients/setIncomeClientBalance', params)
        .then((data) => {
          this.balanceValue = '';
          this.client.account = data;
          this.$store.dispatch('app/setSnackBar', { text: 'Баланс обновлен' });
        })
        .finally(() => {
          this.isLoading = false;
          this.$emit('close');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.client-balance {
  width: 470px;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 0px 20px 0px rgb(34 60 80 / 20%);
  background: white;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &::after {
    content: "";
    border: solid transparent;
    position: absolute;
    left: 230px;
    bottom: 100%;
    border-bottom-color: white;
    border-width: 12px;
  }

  .client-balance__inputs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 30px;
    width: 100%;
    position: relative;
    @include between-elements {
      margin-right: 10px;
    }
  }
}

</style>
