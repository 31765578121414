import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    tariffsRequestsData: [],
  },
  getters: {
    getTariffsRequestsData: (state) => state.tariffsRequestsData,
  },
  mutations: {
    setTariffsRequestsData: (state, value) => { state.tariffsRequestsData = value; },
  },
  actions: {
    // Отправляет заявку на доступ
    sendTariffRequest({ commit }, params) {
      commit('app/setAppIsLoading', true, { root: true });
      return api.post('/tariff-requests', params)
        .then(() => Promise.resolve())
        .catch((error) => {
          console.warn('Error in action -- sendTariffRequest --; Error -', error);
          return Promise.reject(error);
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },

    // Получает информацию, есть ли существующая заявка
    getTariffRequestIsExist({ commit }, userId) {
      return api.get(`/tariff-requests/is-exists/${userId}`)
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          console.warn('Error in action -- getTariffRequestIsExist --; Error -', error);
          return Promise.reject(error);
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },

    // Получение списка заявок
    getTariffRequestsData({ commit }, params = { reset: false }) {
      if (params.reset) {
        commit('setTariffsRequestsData', []);
      }
      return api.get('/tariff-requests')
        .then(({ data: { data } }) => {
          commit('setTariffsRequestsData', data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          console.warn('Error in action -- getTariffRequestsData --; Error -', error);
          return Promise.reject(error);
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },
    setDemoTariff({ commit }, params) {
      commit('app/setAppIsLoading', true, { root: true });
      return api.post('/tariff/set-demo-tariff', params)
        .then(() => Promise.resolve())
        .catch((error) => {
          console.warn('Error in action -- setDemoTariff --; Error -', error);
          return Promise.reject(error);
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },
    approveDemoTariffRequest({ state, commit }, params) {
      commit('app/setAppIsLoading', true, { root: true });
      return api.patch(`/tariff-requests/approve/${params.requestId}`)
        .then(() => {
          const newTariffsRequestsData = state.tariffsRequestsData.filter((item) => item.id !== params.requestId);
          commit('setTariffsRequestsData', newTariffsRequestsData);
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- rejectDemoTariffRequest --; Error -', error);
          return Promise.reject(error);
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },
    rejectDemoTariffRequest({ state, commit }, requestId) {
      commit('app/setAppIsLoading', true, { root: true });
      return api.patch(`/tariff-requests/reject/${requestId}`)
        .then(() => {
          const newTariffsRequestsData = state.tariffsRequestsData.filter((item) => item.id !== requestId);
          commit('setTariffsRequestsData', newTariffsRequestsData);
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- rejectDemoTariffRequest --; Error -', error);
          return Promise.reject(error);
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },
    createPayment({ commit }, params) {
      commit('app/setAppIsLoading', true, { root: true });
      return api.post('/payment/create', params)
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          console.warn('Error in action -- createPayment --; Error -', error);
          return Promise.reject(error);
        })
        .finally(() => commit('app/setAppIsLoading', false, { root: true }));
    },
  },
};
