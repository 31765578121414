<template>
  <div>
    <!-- Удаление канала -->
    <div
    v-if="action === 'delete'"
    class="group-approve">
      <span class="group-approve__title">{{ `${$t('delete_channel')} ${getGroupsNames}?` }}</span>
      <div class="group-approve__button-block">
        <DataButton
        :text="$t('delete')"
        @handler="deleteGroup" />
        <DataButton
        :text="$t('cancel')"
        type="outlined"
        @handler="closeApprove" />
      </div>
    </div>

    <!-- Добавление в бан -->
    <div
    v-if="action === 'addToBan'"
    class="group-approve">
      <span class="group-approve__title">{{ `Укажите причину бана для канала ${getGroupsNames}` }}</span>
      <input
      v-model="banReason"
      placeholder="Причина бана"
      class="group-approve__input">
      <div class="group-approve__button-block">
        <DataButton
        text="Забанить"
        @handler="addToBan" />
        <DataButton
        :text="$t('cancel')"
        type="outlined"
        @handler="closeApprove" />
      </div>
    </div>

    <!-- Добавление в ЧС -->
    <div
    v-if="action === 'addToBlackList'"
    class="group-approve">
      <span class="group-approve__title">{{ `Вы точно хотите отправить в чёрный список ${groups.length > 1 ? 'каналы' : 'канал'} ${getGroupsNames}? ` }}</span>
      <span class="group-approve__title">{{ groups.length > 1 ? 'Каналы станут недоступны в каталоге' : 'Канал станет недоступен в каталоге' }}</span>
      <div class="group-approve__button-block">
        <DataButton
        text="Добавить в ЧС"
        @handler="addToBlackList" />
        <DataButton
        :text="$t('cancel')"
        type="outlined"
        @handler="closeApprove" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupApproveModal',
  // Компонент для подтверждения действий с группой: Удаление, Добавление в бан и тд
  props: {
    group: {
      type: Object,
      default: null,
    },
    action: {
      type: String,
      default: null,
    },
    groups: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    banReason: '',
  }),
  methods: {
    closeApprove() {
      this.banReason = '';
      this.$emit('close');
    },
    deleteGroup() {
      const currentId = [this.group.id];
      this.$store.dispatch('groups/deleteGroup', { id: currentId, social: this.group.social })
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: this.$t('successfully_the_channel_was_removed_from_the_database') });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('an_error_occurred_when_deleting_the_channel_refresh_the_page_and_try_again'),
              error: true,
            });
        })
        .finally(() => this.closeApprove());
    },
    addToBan() {
      const params = {
        id: this.groups[0].id,
        social: this.groups[0].social,
        reason: this.banReason,
      };
      this.$store.dispatch('groups/addToBan', params)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Successfully. The channel will be banned') });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('An error occurred while updating the data'),
              error: true,
            });
        })
        .finally(() => this.closeApprove());
    },
    addToBlackList() {
      const params = {};
      if (this.groups.length > 1) {
        params.groupIdentities = this.groups.map((item) => ({ id: item.id, social: item.social }));
      } else {
        params.groupIdentities = [
          {
            id: this.groups[0].id,
            social: this.groups[0].social,
          },
        ];
      }

      this.$store.dispatch('groups/addToBlackList', params)
        .then(() => {
          // FUTURE compilations
          this.$store.commit('collections/setSelectedGroups', []);
          this.$store.dispatch('app/setSnackBar', { text: `Успешно. ${this.groups.length > 1 ? 'Каналы отправлены' : 'Канал отправлен'} в ЧС` });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: 'При отправке в черный список произошла ошибка. Попробуйте позже',
              error: true,
            });
        })
        .finally(() => this.closeApprove());
    },
  },
  computed: {
    getGroupsNames() {
      return this.groups.length > 1 ? this.groups.map((item) => item.name).join(', ') : this.groups[0].name;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-approve {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 500px;
  @include between-elements {
    margin-bottom: 20px;
  }
}

.group-approve__button-block {
  padding-top: 20px;
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}

.group-approve__title {
  max-width: 80%;
  text-align: center;
}

.group-approve__input {
  padding: 0px 10px;
  border: 1px solid $border-color-primary;
  border-radius: 8px;
  background: $primary-white;
  box-sizing: border-box;
  width: 30vw;
  min-width: 200px;
  max-width: 300px;
  font-size: 14px;
  margin-top: 5px;
  height: 40px;
  &:focus {
    outline: none;
    border-color: $border-color-focus;
  }
  &:hover {
    outline: none;
  }
}
</style>
