<template>
  <div class="add-group">
    <div class="add-group__header-block">
      <span class="add-group__text add-group__text_title">{{ $t('creating a channel') }}</span>
      <span class="add-group__text add-group__text_subtitle">{{ $t('It is necessary to fill in all required fields') }}</span>
    </div>
    <div class="add-group__elements">
      <!-- Название -->
      <div
      class="add-group__element">
        <span class="add-group__text add-group__text_require">{{ $t('name2') }}</span>
        <DataInput
        v-model.trim="groupTemplate.name"
        :placeholder="$t('name2')"
        theme="white"
        clear-icon
        class="add-group__input" />
      </div>
      <!-- Название -->
      <div
      class="add-group__element">
        <span class="add-group__text add-group__text_require">{{ $t('link') }}</span>
        <DataInput
        v-model.trim="groupTemplate.url"
        :placeholder="$t('Link to the channel')"
        theme="white"
        clear-icon
        class="add-group__input" />
      </div>

      <!-- Соц сеть -->
      <div
      class="add-group__element">
        <span class="add-group__text add-group__text_require">{{ $t('Social network') }}</span>
        <DataSelect
        v-model="currentSocial"
        :options="groupsData.socials"
        theme="white"
        :placeholder="$t('Choose a social network')" />
      </div>

      <!-- Количество подписчиков -->
      <div
      class="add-group__element">
        <span class="add-group__text">{{ $t('Subscribers count') }}</span>
        <DataInput
        v-model="groupTemplate.subscribersStatistic.count"
        input-type="number"
        placeholder="100000"
        theme="white"
        clear-icon />
      </div>

      <!-- Контакты -->
      <div
      class="add-group__element">
        <span class="add-group__text">{{ $t('contacts') }}</span>
        <DataInput
        v-model.trim="groupTemplate.contact"
        placeholder="client@gmail.com"
        theme="white"
        clear-icon />
      </div>

      <!-- Стоимость поста -->
      <div
      class="add-group__element">
        <span class="add-group__text">{{ $t('Сost of the post') }}</span>
        <DataInput
        v-model="groupTemplate.postPrice.sell"
        input-type="number"
        placeholder="100000"
        theme="white"
        clear-icon />
      </div>

      <!-- Прайс -->
      <div
      class="add-group__element">
        <span class="add-group__text">{{ $t('price_sale') }}</span>
        <DataInput
        v-model="groupTemplate.postPrice.buy"
        input-type="number"
        placeholder="100000"
        theme="white"
        clear-icon />
      </div>

      <!-- Тип оплаты -->
      <div
      class="add-group__element">
        <span class="add-group__text">{{ $t('payment_type') }}</span>
        <DataSelect
        v-model="paymentTypes"
        :options="paymentOptions"
        select-type="multi"
        theme="white"
        :placeholder="$t('Select the payment type')" />
      </div>

      <!-- Категории -->
      <div class="add-group__element add-group__element_categories">
        <span class="add-group__text">{{ $t('categories') }}</span>
        <DataSelect
        v-if="showCategorySelect"
        v-model="selectedCategories"
        select-type="multi"
        :max-elements="4"
        :options="getCategoriesVariants"
        with-search
        theme="white"
        :placeholder="$t('Select a new category')" />
      </div>
    </div>
    <span
    v-show="showError"
    class="add-group__text add-group__text_error">{{ $t('Not all required fields are filled in') }}</span>
    <div class="add-group__button-block">
      <DataButton
      :text="$t('save')"
      @handler="sendGroup" />
      <DataButton
      :text="$t('cancel')"
      type="outlined"
      @handler="close" />
    </div>
  </div>
</template>

<script>
import groupsData from '@/assets/js/groupsData';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'AddGroup',
  data: () => ({
    groupTemplate: null,
    currentSocial: null,
    showError: false,
    selectedCategories: [],
    showCategorySelect: true,
    paymentTypes: [],
    paymentOptions: [
      {
        id: 1,
        name: 'ИП',
      },
      {
        id: 2,
        name: 'СЗ',
      },
      {
        id: 3,
        name: 'КЭШ',
      },
      {
        id: 4,
        name: 'Другое',
      },
      {
        id: 5,
        name: 'OOO',
      },
      {
        id: 6,
        name: 'Sollar',
      },
    ],
  }),
  created() {
    this.groupsData = cloneDeep(groupsData);
    this.groupTemplate = cloneDeep(groupsData.groupTemplate);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    sendGroup() {
      if (this.isError) {
        this.showError = true;
        return;
      }
      this.showError = false;
      this.groupTemplate.categoriesId = this.selectedCategories.length ? this.selectedCategories.map((item) => item.value) : null;
      this.groupTemplate.paymentTypesId = this.paymentTypes.length ? this.paymentTypes.map((item) => item.id) : null;

      this.$store.dispatch('group/createGroup', { group: this.groupTemplate, social: this.currentSocial.value })
        .then(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('Successfully. Group added', { group: this.groupTemplate.name }),
              error: false,
            });
          this.close();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$store.dispatch('app/setSnackBar',
              {
                text: this.$t('Mistake! Such group already exists'),
                error: true,
              });
            this.close();
            return;
          }
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('an_error_occurred_when_creating_the_group_reload_the_page_and_try_again'),
              error: true,
            });
          this.close();
        });
    },
  },
  computed: {
    isError() {
      return !this.groupTemplate.name || !this.groupTemplate.url || !this.selectedCategories.length;
    },
    getCategoriesData() {
      return this.$store.getters['groups/getCategories'];
    },
    getCategoriesVariants() {
      return this.getCategoriesData
        .map((category) => ({
          name: this.$t(category.name),
          value: category.id,
        }));
    },
  },
};
</script>

<style lang="scss" scoped>
.add-group {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.add-group__header-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 30px;
}

.add-group__text {
  font-size: 14px;
  &_title {
    font-size: 24px;
    line-height: 44px;
  }
  &_light {
    color: $primary-lightgray;
  }
  &_error {
    color: $primary-red;
    margin-top: 10px;
  }
  &_require::after {
    color: red;
    content: " *";
    font-size: 18px;
  }
}

.add-group__elements {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  @include tablet-screen {
    grid-template-columns: 1fr 1fr;
  }
}

.add-group__element {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 300px;
  &_categories {
    min-height: 60px;
  }
}

.add-group__button-block {
  padding-top: 30px;
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}
</style>
