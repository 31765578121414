import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    currentGroup: null,
    currentGroupLogs: null,
  },
  getters: {
    getGroupsForModerate: (state) => state.groupsForModerate,
    getCurrentGroup: (state) => state.currentGroup,
    getCurrentGroupLogs: (state) => state.currentGroupLogs,
  },
  mutations: {
    setCurrentGroup: (state, value) => { state.currentGroup = value; },
    setCurrentGroupLogs: (state, value) => { state.currentGroupLogs = value; },
  },
  actions: {
    getCurrentGroup({ commit }, { id, social, shareToken }) {
      return api.get(`/group-${social}/${id}`, {
        params: {
          shareToken,
        },
      })
        .then(({ data: { data } }) => {
          commit('setCurrentGroup', data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          console.warn('Error in action getCurrentGroup; Error -', error);
          return Promise.reject(error);
        });
    },
    getPriceHistory({ commit }, params) {
      return api.post('/change-log/find-change', params.params, { params: { shareToken: params.shareToken } })
        .then(({ data: { data } }) => {
          commit('app/setAppIsLoading', false, { root: true });
          return Promise.resolve(data);
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- getPriceHistory --; Error -', error);
          return Promise.reject();
        });
    },
    getGroupStatistic({ commit }, params) {
      return api.get('/statistic/group-statistic-report', {
        params: { groupId: params.groupId, social: params.social, shareToken: params.shareToken },
      })
        .then(({ data: { data } }) => {
          commit('app/setAppIsLoading', false, { root: true });
          return Promise.resolve(data);
        })
        .catch((error) => {
          console.warn('Error in action -- getGroupStatistic; Error -', error);
        });
    },
    createGroup({ commit }, { group, social }) {
      commit('app/setAppIsLoading', true, { root: true });
      return api.post(`/group-${social}`, group)
        .then(() => {
          commit('app/setAppIsLoading', false, { root: true });
          return Promise.resolve();
        })
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- createGroup--; Error -', error);
          return Promise.reject(error);
        });
    },
    createCurrentGroupLink({ commit }, params) {
      return api.post(`/group-${params.social}/share`, { id: params.id })
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- createCurrentGroupLink --; Error -', error);
          return Promise.reject();
        });
    },

    // Установка трейдоффера
    setTradeOffer({ commit, dispatch }, params) {
      return api.post('/product/create-for-group', params)
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- setTradeOffer --; Error -', error);
          dispatch('app/createApiErrorMessage',
            {
              error,
              action: 'setTradeOffer',
              defaultErrorText: 'Произошла ошибка при обновлении данных',
            }, { root: true });
        });
    },

    // Редактирование трейдоффера
    editTradeOffer({ dispatch, commit }, { id, prices }) {
      return api.patch(`/product/${id}/update-for-group`, prices)
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          dispatch('app/createApiErrorMessage',
            { error, action: 'editTradeOffer', defaultErrorText: 'Произошла ошибка при обновлении данных' }, { root: true });
          console.warn('Error in action -- editTradeOffer --; Error -', error);
          return Promise.reject(error);
        });
    },

    addWarning({ commit }, { id, social, codeNumber }) {
      return api.patch(`/group-${social}/add-warning/${id}`, { code: codeNumber })
        .then(() => Promise.resolve())
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- addWarning --; Error -', error);
          return Promise.reject(error);
        });
    },
    removeWarning({ commit }, { id, social, codeNumber }) {
      return api.patch(`/group-${social}/remove-warning/${id}`, { code: codeNumber })
        .then(() => Promise.resolve())
        .catch((error) => {
          commit('app/setAppIsLoading', false, { root: true });
          console.warn('Error in action -- removeWarning --; Error -', error);
          return Promise.reject(error);
        });
    },
  },
};
