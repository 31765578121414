<template>
  <div class="campaigns-headers">
    <div
    v-for="header in getHeaders"
    :key="header.value"
    :style="`width: ${header.width}px`"
    :class="{
      'campaigns-headers__element_name': header.value === 'name',
      'campaigns-headers__element_id': header.value === 'id'
    }"
    class="campaigns-headers__element">
      <span class="campaigns-headers__text_light">
        {{ header.name }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CampaignsHeaders',
  props: {
  },
  data: () => ({
  }),
  computed: {
    getHeaders() {
      const headers = [
        {
          name: 'ID', value: 'id', width: 60, show: true,
        },
        { name: 'Название', value: 'name', show: true },
        {
          name: 'Подборка', value: 'compilation', width: 150, show: true,
        },
        {
          name: 'Бюджет', value: 'budget', width: 200, show: true,
        },
        {
          name: 'Ответственный в SJ', value: 'manager', width: 250, show: true,
        },
        {
          name: 'Статус', value: 'status', width: 150, show: true,
        },
        {
          name: 'Отчет', value: 'report', width: 150, show: true,
        },
        {
          name: 'Дата старта', value: 'start-date', width: 140, show: true,
        },
        {
          name: 'Дата окончания', value: 'end-date', width: 140, show: true,
        },
        {
          name: '', value: 'actions', width: 100, show: true,
        },
      ];
      return headers.filter((item) => item.show);
    },
  },
};
</script>

<style lang="scss" scoped>
.campaigns-headers {
  display: flex;
  align-items: center;
  min-width: 100%;
  position: sticky;
  background: white;
  z-index: 2;
  top: 0px;
  left: 0px;
  border-bottom: 1px solid $primary-group-border;
}

.campaigns-headers__element {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 5px;
  }
  &_id {
    position: sticky;
    left: 0px;
    background: white;
    padding: 12px 0px;
  }
  &_name {
    padding: 12px 0px;
    position: sticky;
    left: 60px;
    padding-left: 20px;
    background: white;
    justify-content: flex-start;
    align-items: flex-start;
    width: 240px;
    @include tablet-screen {
      width: 360px;
    }
  }
}

.campaigns-headers__text {
  font-size: 14px;
  color: $primary-gray;
  &_name {
    color: $primary-blue;
  }
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

</style>
