import { render, staticRenderFns } from "./ImportElement.vue?vue&type=template&id=f3d47002&scoped=true"
import script from "./ImportElement.vue?vue&type=script&lang=js"
export * from "./ImportElement.vue?vue&type=script&lang=js"
import style0 from "./ImportElement.vue?vue&type=style&index=0&id=f3d47002&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3d47002",
  null
  
)

export default component.exports