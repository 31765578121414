<template>
  <div
  class="group"
  :class="[
    !hideGroupDetails && 'group_show-all',
    showMenu && 'group_hover',
    group.isExclusive && 'group_exclusive',
    dragMode && 'group_drag'
  ]"
  @mouseenter="setShowMenu($event, true)"
  @mouseleave="setShowMenu($event, false)">
    <div
    class="group__sticky-elements"
    :class="[
      showMenu && 'group__sticky-elements_hover',
      group.isExclusive && 'group__sticky-elements_exclusive'
    ]">
      <!-- Эксклюзив -->
      <div
      v-if="group.isExclusive"
      class="group__exclusive"
      :class="group.isBlogger && 'group__exclusive_double'">
        <span class="group__exclusive-text">{{ group.isBlogger ? 'Экскл.' : 'Эксклюзив!' }}</span>
      </div>
      <!-- Эксклюзив -->
      <div
      v-if="group.isBlogger"
      :class="group.isExclusive && 'group__exclusive_bloger-double'"
      class="group__exclusive group__exclusive_bloger">
        <span class="group__exclusive-text">{{ group.isExclusive ? 'Блог.' : 'Блогер' }}</span>
      </div>
      <div class="group__checkbox">
        <DataCheckbox
        v-model="groupChecked" />
      </div>
      <GroupName
      :group="group"
      :hover="showMenu"
      :hide-details="hideGroupDetails"
      @openEditNameModal="openEditNameModal" />
    </div>

    <div class="group__elements">
      <!-- Ответ от бота -->
      <GroupBotNotify
      v-if="isAdmin && isCompilation && inShowList('bot-notify')"
      :style="{ order: getOrder('bot-notify') }"
      :group="group" />

      <!-- Ответственный -->
      <GroupResponsible
      v-if="(isAdmin || isManager) && isCompilation && inShowList('responsible')"
      :style="{ order: getOrder('responsible') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :realisations-managers="realisationsManagers"
      :is-manager="isManager" />

      <!-- Описание -->
      <GroupDescription
      v-if="inShowList('description')"
      :style="{ order: getOrder('description') }"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :group="group"
      :disable-editor="dragMode" />

      <!-- Подсписчики -->
      <GroupSubscribers
      v-if="inShowList('subscribers')"
      :style="{ order: getOrder('subscribers') }"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :group="group"
      :disable-editor="dragMode"
      :subscribers="group.subscribersStatistic" />

      <!-- Просмоторы -->
      <GroupViews
      v-if="inShowList('views-er')"
      :style="{ order: getOrder('views-er') }"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :group="group"
      :disable-editor="dragMode" />

      <!-- География -->
      <GroupGeo
      v-if="inShowList('geo')"
      :style="{ order: getOrder('geo') }"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :countries="group.subscribersStatistic?.countries"
      :cities="group.subscribersStatistic?.cities"
      :disable-editor="dragMode"
      :group="group" />

      <!-- Гендер -->
      <GroupGender
      v-if="inShowList('gender')"
      :style="{ order: getOrder('gender') }"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :male-percent="group.subscribersStatistic.malePercent"
      :female-percent="group.subscribersStatistic.femalePercent"
      :ages="group.subscribersStatistic.age"
      :disable-editor="dragMode"
      :group="group" />

      <!-- Интересы -->
      <GroupInterests
      v-if="inShowList('interests')"
      :style="{ order: getOrder('interests') }"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :interests="group.interests"
      :disable-editor="dragMode"
      :group="group" />

      <!-- Количество постов -->
      <GroupPostCount
      v-if="inShowList('posts_count')"
      :style="{ order: getOrder('posts_count') }"
      :group="group"
      :is-admin="isAdmin"
      :hide-details="hideGroupDetails"
      :disable-editor="dragMode"
      :posts="group.postsStatistic" />

      <!-- Наценка -->
      <GroupMargin
      v-if="isAdmin && isCompilation && inShowList('margin')"
      :group="group"
      class="group__element"
      :style="{ order: getOrder('margin') }" />

      <!-- Цена -->
      <GroupPrice
      v-if="inShowList('price_sell')"
      :style="{ order: getOrder('price_sell') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :selected-type-of-groups="selectedTypeOfGroups"
      :is-compilation="isCompilation"
      :disable-editor="dragMode"
      :products="group.products"
      price-type="sell" />

      <!-- ПРАЙС -->
      <GroupPrice
      v-if="(isAdmin || isAgency) && inShowList('price_buy')"
      :style="{ order: getOrder('price_buy') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :selected-type-of-groups="selectedTypeOfGroups"
      :is-compilation="isCompilation"
      :products="group.products"
      :disable-editor="dragMode"
      :price-type="'buy'" />

      <!-- СЕБЕС -->
      <GroupPrice
      v-if="isAdmin && inShowList('price_sale')"
      :style="{ order: getOrder('price_sale') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :selected-type-of-groups="selectedTypeOfGroups"
      :is-compilation="isCompilation"
      :products="group.products"
      :disable-editor="dragMode"
      :price-type="'sale'" />

      <!-- Скидка -->
      <GroupDiscount
      v-if="isAdmin && inShowList('discount')"
      :style="{ order: getOrder('discount') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :disable-editor="dragMode"
      :is-admin="isAdmin" />

      <!-- Параметр CPM -->
      <GroupCpm
      v-if="inShowList('cpm')"
      :style="{ order: getOrder('cpm') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :cpm="group.cpm"
      :disable-editor="dragMode"
      :cpm-from-buy="group.cpmFromBuy" />

      <!-- Параметр CPC -->
      <GroupCpc
      v-if="inShowList('cpc')"
      :style="{ order: getOrder('cpc') }"
      :group="group"
      :is-admin="isAdmin"
      :disable-editor="dragMode"
      :cpc="group.cpc" />

      <!-- Индекс SJI -->
      <GroupContentAnalysis
      v-if="inShowList('rating_sji')"
      :style="{ order: getOrder('rating_sji') }"
      :data="group.contentAnalysis"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :processed-posts-count="group.processedPostsCount"
      :disable-editor="dragMode"
      :group="group" />

      <!-- Параметры популярности -->
      <GroupPopular
      v-if="isAdmin && inShowList('popular')"
      :style="{ order: getOrder('popular') }"
      :disable-editor="dragMode"
      :group="group" />

      <!-- Параметр роботности -->
      <GroupRobot
      v-if="inShowList('robot')"
      :style="{ order: getOrder('robot') }"
      :is-admin="isAdmin"
      :group="group"
      :disable-editor="dragMode"
      :robot="group.roboticity" />

      <!-- Примечание -->
      <GroupComment
      v-if="isCompilation && inShowList('note')"
      :style="{ order: getOrder('note') }"
      comment-type="note"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :comment="group.comment"
      :note="group.note"
      :disable-editor="dragMode" />

      <!-- Данные для админов -->
      <GroupAdmins
      v-if="isAdmin && inShowList('Other')"
      :style="{ order: getOrder('Other') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :in-social-jet="group.inSocialjet"
      :payment-types="group.paymentTypes"
      :is-blogger="group.isBlogger"
      :in-black-list="group.inBlackList"
      :disable-editor="dragMode" />

      <!-- Контакты -->
      <GroupContact
      v-if="isAdmin && inShowList('contacts')"
      :style="{ order: getOrder('contacts') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :contact="group.contact"
      :disable-editor="dragMode" />

      <!-- Комментарий -->
      <GroupComment
      v-if="isAdmin && inShowList('comment')"
      :style="{ order: getOrder('comment') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :comment="group.comment"
      :disable-editor="dragMode" />

      <!-- Редактор - бот и тд -->
      <GroupRedactor
      v-if="isAdmin && inShowList('redactor')"
      :style="{ order: getOrder('redactor') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :redactor="group.botName"
      :disable-editor="dragMode" />

      <!-- Дата создания -->
      <div
      v-if="isAdmin && inShowList('create_date')"
      :style="{ order: getOrder('create_date') }"
      class="group__element">
        <span
        v-if="group.createdAt"
        class="group__text_small">{{ getDate(group.createdAt.date) }}</span>
        <span
        v-else
        class="group__text_light">—</span>
      </div>

      <!-- Дата обновления данных -->
      <div
      v-if="inShowList('update')"
      :style="{ order: getOrder('update') }"
      class="group__element">
        <span
        v-if="group.dataUpdatedAt"
        class="group__text_small">{{ getDate(group.dataUpdatedAt.date) }}</span>
        <span
        v-else
        class="group__text_light">—</span>
      </div>

      <!-- Обновление цены -->
      <GroupPriceUpdate
      v-if="isAdmin && inShowList('update_price')"
      :style="{ order: getOrder('update_price') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :disable-editor="dragMode" />

      <!-- Причина бана -->
      <div
      v-if="isAdmin && isBannedGroup && isHiddenMode && inShowList('bad_reason')"
      :style="{ order: getOrder('bad_reason') }"
      class="group__element">
        <span
        v-for="reason in getBanReasons"
        :key="reason"
        class="group__text group__text_small group__text_no-wrap">{{ reason }}</span>
        <span
        class="group__text group__text_light pointer"
        @click="restoreGroup">{{ $t('Restore channel') }}</span>
      </div>
    </div>

    <div class="group__sticky-menu">
      <GroupMenu
      v-if="showMenu && !hideGroupMenu"
      ref="groupmenu"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :mode="mode"
      :is-compilation="isCompilation"
      class="group__menu"
      :checked.sync="groupChecked"
      @openDeleteModal="openApproveModal('delete')"
      @openEditNameModal="openEditNameModal"
      @restoreGroup="restoreGroup"
      @addToBanned="openApproveModal('addToBan')"
      @addToBlackList="openApproveModal('addToBlackList')" />
    </div>

    <div
    v-if="dragMode"
    class="group__drag-wrapper" />
  </div>
</template>

<script>
import GroupName from './components/GroupName.vue';
import GroupSubscribers from './components/GroupSubscribers.vue';
import GroupViews from './components/GroupViews.vue';
import GroupPostCount from './components/GroupPostCount.vue';
import GroupPrice from './components/GroupPrice.vue';
import GroupGeo from './components/GroupGeo.vue';
import GroupContentAnalysis from './components/GroupContentAnalysis.vue';
import GroupAdmins from './components/GroupAdmins.vue';
import GroupContact from './components/GroupContact.vue';
import GroupMenu from './components/GroupMenu.vue';
import GroupCpm from './components/GroupCpm.vue';
import GroupCpc from './components/GroupCpc.vue';
import GroupRedactor from './components/GroupRedactor.vue';
import GroupComment from './components/GroupComment.vue';
import GroupInterests from './components/GroupInterests.vue';
import GroupRobot from './components/GroupRobot.vue';
import GroupGender from './components/GroupGender.vue';
import GroupPriceUpdate from './components/GroupPriceUpdate.vue';
import GroupDiscount from './components/GroupDiscount.vue';
import GroupPopular from './components/GroupPopular.vue';
import GroupResponsible from './components/GroupResponsible.vue';
import GroupDescription from './components/GroupDescription.vue';
import GroupMargin from './components/GroupMargin.vue';
import GroupBotNotify from './components/GroupBotNotify.vue';

export default {
  name: 'Group',
  components: {
    GroupName,
    GroupSubscribers,
    GroupViews,
    GroupPostCount,
    GroupPrice,
    GroupGeo,
    GroupRedactor,
    GroupContentAnalysis,
    GroupAdmins,
    GroupContact,
    GroupCpm,
    GroupCpc,
    GroupMenu,
    GroupComment,
    GroupInterests,
    GroupRobot,
    GroupGender,
    GroupPriceUpdate,
    GroupDiscount,
    GroupPopular,
    GroupResponsible,
    GroupDescription,
    GroupMargin,
    GroupBotNotify,
  },
  props: {
    // Объект группы
    group: {
      type: Object,
      default: null,
    },
    // Режим (например noauth, режим для просмотра для неавторизованных)
    mode: {
      type: String,
      default: null,
    },
    // Параметр админа у пользователя
    isAdmin: {
      type: Boolean,
      default: false,
    },
    // Параметр агентства у пользователя
    isAgency: {
      type: Boolean,
      default: false,
    },
    // Параметр менеджера у пользователя
    isManager: {
      type: Boolean,
      default: false,
    },
    // Список выбранных каналов
    selectedGroups: {
      type: Array,
      default: null,
    },
    // Выбранный тип каталога (telegram, vk и тд)
    selectedTypeOfGroups: {
      type: [String, Number],
      default: null,
    },
    // Режим переключения показа колонок (мало/много данных)
    hideGroupDetails: {
      type: Boolean,
      default: false,
    },
    // Показ колонки причина бана (для режима показа скрытых каналов)
    isHiddenMode: {
      type: [Boolean, String],
      default: false,
    },
    // Список колонок
    columns: {
      type: Array,
      default: null,
    },
    // Отключает показ менюшки при наведении
    hideGroupMenu: {
      type: Boolean,
      default: false,
    },
    // Режим подборки
    isCompilation: {
      type: Boolean,
      default: false,
    },
    // Находится ли группа в списке выбранных
    groupIsChecked: {
      type: Boolean,
      default: false,
    },
    // Отключение возможности редактирования колонок админом
    dragMode: {
      type: Boolean,
      default: false,
    },
    // Список менеджеров отдела реализации
    realisationsManagers: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    showMenu: false,
    priceUpdate: null,
    priceUpdateUser: null,
  }),
  methods: {
    setShowMenu(event, value) {
      if (this.hideGroupMenu) {
        this.showMenu = false;
        return;
      }
      // обработка исключения для элемента v-tooltip (ложное срабатывание при наведении)
      if (event.relatedTarget && event.relatedTarget.className.includes('v-tooltip-block')) return;
      this.showMenu = value;
    },
    getOrder(element) {
      return this.columns.findIndex((item) => item.value === element || item.value2 === element);
    },
    getDate(date) {
      return this.moment(date).format('LLL');
    },
    inShowList(value) {
      try {
        return this.columns.find((column) => column.value === value || column.value2 === value).show;
      } catch {
        return false;
      }
    },
    openEditNameModal() {
      const modalParams = { name: 'GroupEditorModal', props: { group: this.group } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    openApproveModal(action) {
      const modalParams = { name: 'GroupApproveModal', props: { groups: [this.group], action } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    restoreGroup() {
      this.$store.dispatch('groups/restoreGroup', { id: this.group.id, social: this.group.social })
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: this.$t('successfully_the_channel_will_be_restored') });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('An error occurred while restoring the channel. Please refresh the page and try again'),
              error: true,
            });
        });
    },
  },
  computed: {
    groupChecked: {
      get() {
        return this.groupIsChecked;
      },
      set(value) {
        this.$emit('clickCheckbox', value);
      },
    },
    isBannedGroup() {
      return this.group.status === 3;
    },
    getBanReasons() {
      if (!this.group.inspectReport?.cheatings.length && !this.group.badReason) {
        return ['—'];
      }

      const cheatings = this.group.inspectReport?.cheatings.map((item) => item.message).slice(0, 2) || [];

      if (this.group.badReason && this.group.badReason.length) {
        const badreason = this.group.badReason.split(', ');
        cheatings.unshift(...badreason);
      }

      return new Set(cheatings);
    },
  },
};
</script>

<style lang="scss" scoped>
.group {
  display: flex;
  align-items: center;
  height: 70px;
  transition: 0.2s;
  border-bottom: 1px solid #CCEFFA;
  min-width: 100%;
  position: relative;
  &_show-all {
    transition: 0.2s;
    height: 125px;
  }
  &_hover {
    background: #fcfcfc;
  }
  &_exclusive {
    background: #fdfaff;
  }
  &_drag {
    cursor: move !important;
  }
}

.group__sticky-elements {
  position: sticky;
  left: 0px;
  top: 15px;
  background: white;
  padding-left: 30px;
  display: flex;
  align-items: center;
  z-index: 3;
  transition: 0.2s;
  height: 100%;
  @include tablet-screen {
    width: 422px;
  }
  &_hover {
    background: #fcfcfc;
  }
  &:after {
    content: '';
    width: 1px;
    height: 50px;
    background: $primary-group-border;
  }
  &_exclusive {
    background: #fdfaff;
  }
}

.group__checkbox {
  width: 18px;
  margin-right: 20px;
}

.group__elements {
  display: flex;
  align-items: center;
  width: 100%;
}

.group__sticky-menu {
  position: sticky;
  top: 0px;
  right: 0px;
  z-index: 4;
}

.group__menu {
  display: none;
  @include tablet-screen {
    display: flex;
    position: absolute;
    z-index: 1;
    transition: .2s;
    top: -70px;
    right: 30px;
  }
}

.group__element {
  width: 180px;
  min-height: 50px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
}

.group__text {
  font-size: 14px;
  color: $primary-gray;
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
    line-height: 12px;
    padding-right: 0px;
    overflow-wrap: break-word;
  }
  &_small {
    font-size: 12px;
    color: $primary-black;
    padding-right: 0px;
  }
  &_no-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.group__exclusive {
  position: absolute;
  top: 24px;
  left: -26px;
  width: 66px;
  background: #9000e8;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transform: rotate(-90deg);
  &_bloger {
    background: #00c5e8;
  }
  &_double {
    width: 33px;
    top: 41px;
    left: -10px;
    border-bottom-right-radius: 0px;
  }
  &_bloger-double {
    top: 8px;
    left: -10px;
    width: 33px;
    border-bottom-left-radius: 0px;
  }
}

.group__exclusive-text {
  font-size: 9px;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.group__drag-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: rgba($primary-blue, 0.03);
  z-index: 3;
}
</style>
