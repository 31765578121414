<template>
  <div class="post-dates">
    <div
    class="post-dates__header"
    @click="isOpen = !isOpen">
      <div class="post-dates__header-name">
        <span class="post-dates__title">Период публикации</span>
        <transition name="fastfade">
          <div
          v-if="isActiveFilter"
          v-tooltip="datesTooltip"
          class="post-dates__active-highlight" />
        </transition>
      </div>
      <DataIcon
      name="expand_more"
      class="post-dates__title pointer"
      :arrow-open="isOpen" />
    </div>
    <div
    class="post-dates__content"
    :class="isOpen && 'post-dates__content_show'">
      <DataDatePicker
      v-model="fromDate"
      class="post-dates__picker"
      @input="changeFilters" />
      <DataDatePicker
      v-model="toDate"
      class="post-dates__picker"
      @input="changeFilters" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostDates',
  props: {
    open: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isOpen: false,
    fromDate: null,
    toDate: null,
  }),
  created() {
    this.isOpen = this.open;
    this.initData();
  },
  methods: {
    initData() {
      const { query } = this.$route;
      this.fromDate = query.fromDate ? query.fromDate.split(' ')[0] : null;
      this.toDate = query.toDate ? query.toDate.split(' ')[0] : null;
    },
    changeFilters() {
      const query = { ...this.$route.query };
      if (this.fromDate) {
        query.fromDate = `${this.fromDate} 00:00:00`;
      } else {
        delete query.fromDate;
      }
      if (this.toDate) {
        query.toDate = `${this.toDate} 23:59:59`;
      } else {
        delete query.toDate;
      }

      this.$router.push({ name: 'post-search', query, params: { savedPosition: true } });
    },
    resetFilters() {
      this.fromDate = null;
      this.toDate = null;
    },
  },
  computed: {
    isActiveFilter() {
      return this.fromDate || this.toDate;
    },
    datesTooltip() {
      if (this.fromDate && this.toDate) {
        return `с ${this.fromDate} до ${this.toDate}`;
      }
      if (this.fromDate) {
        return `с ${this.fromDate}`;
      }
      return `до ${this.toDate}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.post-dates {
  background: white;
  border-radius: 10px;
  padding: 0px 15px;

  .post-dates__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 15px 0px;
  }

  .post-dates__header-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .post-dates__active-highlight {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: $primary-blue;
  }

  .post-dates__title {
    font-size: 14px;
    color: $primary-gray;
    font-weight: 500;
    margin-right: 10px;
  }

  .post-dates__content {
    height: 0px;
    opacity: 0;
    transition: 0.2s;
    pointer-events: none;
    display: flex;
    width: 100%;
    @include between-elements {
      margin-right: 15px;
    }
    &_show {
      pointer-events: auto;
      opacity: 1;
      height: 65px;
    }
  }

  .post-dates__picker {
    width: 100%;
  }
}
</style>
