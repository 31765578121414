import { render, staticRenderFns } from "./CampaignHeaders.vue?vue&type=template&id=066cc49f&scoped=true"
import script from "./CampaignHeaders.vue?vue&type=script&lang=js"
export * from "./CampaignHeaders.vue?vue&type=script&lang=js"
import style0 from "./CampaignHeaders.vue?vue&type=style&index=0&id=066cc49f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "066cc49f",
  null
  
)

export default component.exports