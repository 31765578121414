<template>
  <div class="group-info">
    <div class="group-info__elements group-info__elements_subpost">
      <div class="group-info__subpost">
        <!-- NEED TRANSLATE -->
        <span class="group-info__text group-info__text_title">{{ group.social === 'mave' ? 'Аудитория' : 'Подписчиков' }}</span>
        <div class="group-info__subpost-element">
          <img
          class="group-info__subpost-icon"
          :src="require('@/assets/svg/group/group-user.svg')">
          <span class="group-info__text group-info__text_big">{{ getSubscribers() }}</span>
        </div>
      </div>
      <div class="group-info__subpost">
        <!-- NEED TRANSLATE -->
        <span class="group-info__text group-info__text_title">{{ group.social === 'mave' ? 'Прослушивания' : 'Охват поста' }}</span>
        <div class="group-info__subpost-element">
          <img
          class="group-info__subpost-icon"
          :src="require('@/assets/svg/group/group-note.svg')">
          <span class="group-info__text group-info__text_big">{{ getViews() }}</span>
          <span
          v-if="group.social === 'mave'"
          class="group-info__text group-info__text_light">(Месяц)</span>
        </div>
      </div>
    </div>

    <div class="group-info__elements">
      <div class="group-info__element">
        <!-- NEED TRANSLATE -->
        <span class="group-info__text group-info__text_title">Категория</span>
        <div
        v-if="getCategories().length"
        class="group-info__info-elements">
          <span
          v-for="category in getCategories()"
          :key="category"
          class="group-info__text group-info__text_info-blue">{{ category }}
          </span>
        </div>
        <!-- NEED TRANSLATE -->
        <span
        v-else
        class="group-info__text group-info__text_info">Нет данных</span>
      </div>
      <div class="group-info__element group-info__element_border">
        <!-- NEED TRANSLATE -->
        <span class="group-info__text group-info__text_title">{{ group.social === 'mave' ? 'Гендер' : 'Аудитория' }}</span>
        <span class="group-info__text group-info__text_info">{{ getLabelForGender() }}</span>
      </div>
      <div class="group-info__element">
        <!-- NEED TRANSLATE -->
        <span class="group-info__text group-info__text_title">Гео</span>
        <div
        v-if="getCountriesData().length"
        class="group-info__info-elements">
          <span
          v-for="country in getCountriesData()"
          :key="country.name"
          class="group-info__text group-info__text_info">{{ country.name }}
          </span>
        </div>
        <span
        v-else
        class="group-info__text group-info__text_info">Нет данных</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupInfo',
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  methods: {
    getCurrentCountryIcon(country) {
      const countryIcon = this.countriesFlags.find((item) => item.name === country);
      return countryIcon ? countryIcon.value : false;
    },
    getSubscribers() {
      const count = this.group.subscribersStatistic?.count;
      return count ? this.setNumberSpaces(+count) : '—';
    },
    getViews() {
      if (this.group.social === 'mave') {
        return this.group.listeningStatistic?.averagePerMonth
          ? this.setNumberSpaces(this.group.listeningStatistic.averagePerMonth)
          : '—';
      }
      return this.group.viewsStatistic?.countLast24h
        ? this.setNumberSpaces(this.group.viewsStatistic.countLast24h)
        : '—';
    },
    getLabelForGender() {
      const { malePercent, femalePercent } = this.group.subscribersStatistic;

      if (malePercent === null && femalePercent === null) return 'Нет данных';

      if (malePercent >= femalePercent) {
        return `${this.$t('M')} ${malePercent || 0}% / ${this.$t('F')} ${femalePercent || 0}%`;
      }
      return `${this.$t('F')} ${femalePercent || 0}% / ${this.$t('M')} ${malePercent || 0}%`;
    },
    getCountriesData() {
      const { countries } = this.group.subscribersStatistic;

      if (!countries) return [];

      return countries.filter((country) => country.location !== null).map((item, index) => ({
        name: item.location,
        value: item.location,
        index,
      })).slice(0, 3);
    },
    getCategories() {
      return this.group.categories.map((category) => this.$t(category.name));
    },
  },
};
</script>

<style lang="scss" scoped>
.group-info {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
  @include tablet-screen {
    margin-left: 0px;
  }
  @include desktop-screen {
    margin-left: 20px;
    align-items: flex-start;
    @include between-elements {
      margin-bottom: 20px;
      margin-right: 10px;
  }
  }
  @include max-screen {
    margin-left: 60px;
    margin-top: 0px;
    max-width: 560px;
    flex-direction: column;
    @include between-elements {
      margin-right: 0px;
    }
  }
}

.group-info__elements {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @include tablet-screen {
    flex-direction: row;
  }
  @include between-elements {
    margin-bottom: 15px;
  }
  @include max-screen {
    display: grid;
    justify-content: flex-start;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr 1fr;
  }
  &_subpost {
    display: flex;
    flex-wrap: wrap;
    @include tablet-screen {
      @include between-elements {
        margin-right: 15px;
      }
    }
    @include max-screen {
      justify-content: flex-start;
      align-items: flex-start;
      display: grid;
      gap: 12px;
      grid-template-columns: 1fr 1fr;
    }

  }
}

.group-info__subpost {
  padding: 20px;
  background: white;
  min-width: 240px;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #F1F9FB;
  box-shadow: 0px 12px 23px -7px rgba(0, 174, 232, 0.12);
  border-radius: 12px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  @include between-elements {
    margin-bottom: 12px;
  }
}

.group-info__subpost-element {
  display: flex;
  justify-content: center;
  align-items: center;
  @include between-elements {
    margin-right: 12px;
  }
}

.group-info__element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 160px;
  margin-left: 10px;
  margin-right: 32px;
  @include between-elements {
    margin-bottom: 12px;
  }
  &_border {
    @include max-screen {
      border-left: 1px solid #D8F2FA;
      border-right: 1px solid #D8F2FA;
      padding: 0px 32px;
      margin-right: 22px;
      min-width: 220px;
      margin-left: 0px;
    }
  }
}

.group-info__text {
  white-space: nowrap;
  font-size: 14px;
  &_big {
    font-size: 28px;
    color: #232323;
    font-weight: 600;
  }
  &_light {
    font-size: 14px;
    color: $primary-gray;
    font-weight: 400;
  }
  &_title {
    color: #7B7B7B;
  }
  &_info {
    font-weight: 600;
    font-size: 18px;
  }
  &_info-blue {
    font-weight: 600;
    font-size: 18px;
    color: $primary-blue;
    white-space: pre-wrap;
  }
}

.group-info__info-elements {
  display: flex;
  flex-direction: column;
}
</style>
