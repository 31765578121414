<template>
  <div
  v-click-outside="closeSidebar"
  class="sidebar"
  :class="getOpenSidebar && 'sidebar_open'">
    <div class="sidebar__menu">
      <div class="sidebar__pages">
        <div
        class="sidebar__page sidebar__page_toggler"
        :class="[getOpenSidebar && 'sidebar__page_open', getOpenSidebar && 'sidebar__page_active']"
        @click="toggleSidebar">
          <div class="sidebar__mini-page sidebar__mini-page_toggler">
            <img
            class="sidebar__icon"
            :src="require(`./assets/${getOpenSidebar? `arrow-left-active` : 'menu'}.svg`)">
          </div>
          <span
          v-if="getOpenSidebar"
          class="sidebar__page-title">
            Свернуть
          </span>
        </div>

        <a
        v-for="page in getLinksData"
        :key="page.id"
        class="sidebar__page-wrap"
        :href="page.href">
          <div
          v-tooltip.right="!page.link && 'Скоро'"
          class="sidebar__page"
          :class="{'sidebar__page_disabled': !page.link, 'sidebar__page_open': getOpenSidebar }"
          @click.prevent="goTo(page)">
            <div class="sidebar__mini-page">
              <img
              class="sidebar__icon"
              :class="!page.link && 'sidebar__icon_disabled'"
              :src="require(`./assets/${page.link.includes(currentPage) ? `${page.icon}-active` : page.icon}.svg`)">
              <span
              class="sidebar__page-minititle"
              :class="{
                'sidebar__page-minititle_active': page.link.includes(currentPage),
                'sidebar__page-minititle_hide': getOpenSidebar,
              }">
                {{ page.name }}
              </span>
            </div>
            <span
            v-if="getOpenSidebar"
            class="sidebar__page-title"
            :class="{
              'sidebar__page-title_active': page.link.includes(currentPage),
              'sidebar__page-title_disabled': !page.link,
            }">
              {{ page.name }}
            </span>
          </div>
        </a>
      </div>
    </div>

    <div class="sidebar__helps">
      <a
      v-for="page in getHelpsData"
      :key="page.id"
      v-tooltip="!page.link && 'Скоро'"
      class="sidebar__page"
      :class="{'sidebar__page_disabled': !page.link, 'sidebar__page_open': getOpenSidebar }"
      @click.prevent="goTo(page)">
        <div class="sidebar__mini-page">
          <img
          class="sidebar__icon"
          :class="!page.link && 'sidebar__icon_disabled'"
          :src="require(`./assets/${page.link.includes(currentPage) ? `${page.icon}-active` : page.icon}.svg`)">
          <span
          class="sidebar__page-minititle"
          :class="{
            'sidebar__page-minititle_active': page.link.includes(currentPage),
            'sidebar__page-minititle_hide': getOpenSidebar,
          }">
            {{ page.name }}
          </span>
        </div>
        <span
        v-if="getOpenSidebar"
        class="sidebar__page-title"
        :class="{
          'sidebar__page-title_active': page.link.includes(currentPage),
          'sidebar__page-title_disabled': !page.link,
        }">
          {{ page.name }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  data: () => ({
  }),
  methods: {
    goTo(page) {
      if (page.name === 'Помощь') {
        window.open('https://t.me/socialjet_bot', '_blank');
        return;
      }
      if (this.$route.name === page.link || !page.link) return;
      this.closeSidebar();
      if (page.link === 'catalog') {
        this.goToMain();
        return;
      }
      this.$router.push({ name: page.link });
    },
    goToMain() {
      this.$store.commit('collections/setShowSelectedGroups', false);
      this.$store.commit('favorites/setShowFavoriteGroups', false);
      this.$store.commit('groups/setInitial', true);
      this.$store.commit('groups/resetGroupsSearchParams');
      this.$router.push({ name: 'catalog' });
    },
    toggleSidebar() {
      this.$store.commit('app/setShowSidebar', !this.getOpenSidebar);
    },
    closeSidebar(event) {
      const isBurger = event?.target?.className.includes('data-burger');
      if (isBurger) return;
      this.$store.commit('app/setShowSidebar', false);
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
    checkAccessToPage() {
      if (this.isAdmin) return true;
      return !this.getIsDemoCatalog;
    },
    getIsDemoCatalog() {
      return this.$store.getters['user/getIsDemoCatalog'];
    },
    isClientAgency() {
      return this.$store.getters['user/getUserInfo']?.asClientAgency;
    },
    getLinksData() {
      if (this.getIsPublisher) return this.getPublishersPages;
      return this.getPages.filter((item) => item.show);
    },
    getHelpsData() {
      return this.isAdmin ? this.getHelpsPages : this.getHelpsPages.filter((item) => item.show);
    },
    getOpenSidebar() {
      return this.$store.getters['app/getShowSidebar'];
    },
    currentPage() {
      return this.$route.name;
    },
    getIsPublisher() {
      return this.$store.getters['user/getIsPublisher'];
    },
    getIsRealisator() {
      return this.$store.getters['user/getIsRealisator'];
    },
    getIsRealisatorHead() {
      return this.$store.getters['user/getIsRealisatorHead'];
    },
    getPublishersPages() {
      return [{
        id: 1, name: 'Мои каналы', icon: 'mygroups', link: 'my-groups', show: this.getIsPublisher,
      }];
    },
    getPages() {
      return [
        {
          id: 1, name: 'Каталог', icon: 'catalog', href: 'catalog', link: 'catalog', show: !this.getIsPublisher,
        },
        {
          id: 2, name: 'Подборки', icon: 'compilations', href: 'compilations', link: 'compilations', show: !this.getIsPublisher,
        },
        {
          id: 3, name: 'Импорты', icon: 'import', href: 'imports', link: 'imports-screen', show: this.isAdmin,
        },
        {
          id: 4, name: 'Запросы', icon: 'request', href: 'requests', link: 'requests', show: !this.getIsPublisher && !this.getIsRealisator,
        },
        {
          id: 5, name: 'Кампании', icon: 'favorites', href: 'campaigns', link: 'campaigns', show: !this.getIsPublisher && !this.getIsRealisator,
        },
        {
          id: 6, name: 'Клиенты', icon: 'clients', href: 'clients', link: 'clients', show: (this.isAdmin || this.isAgency) && !this.getIsRealisator,
        },
        {
          id: 7, name: 'Поиск постов', icon: 'post-search', href: 'post-search', link: 'post-search', show: !this.getIsPublisher,
        },
      ];
    },
    getHelpsPages() {
      return [
        {
          id: 1, name: 'Тариф', icon: 'rate', link: 'tariffs', show: !this.isClientAgency && !this.getIsPublisher,
        },
        {
          id: 2, name: 'Помощь', icon: 'help', link: 'telegram', show: true,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

.sidebar {
  opacity: 0;
  position: fixed;
  left: 0px;
  width: 0px;
  top: 0px;
  height: 100vh;
  transition: 0.2s;
  background: white;
  border-top: 1px solid #eaf8fc;;
  display: flex;
  flex-direction: column;
  z-index: 17;
  overflow: hidden;
  @include desktop-screen {
    opacity: 1;
    height: 100vh;
    min-width: 70px;
  }
  &_open {
    opacity: 1;
    box-shadow: 0px 0px 10px 3px rgb(34 60 80 / 0.1);
    min-width: 250px;
    .sidebar__tarif,
    .sidebar__tarif-title {
      opacity: 1;
    }
  }
}

.sidebar__menu {
  transition: 0.2s;
}

.sidebar__mini-page {
  width: 70px;
  height: 55px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  top: 0px;
  &_toggler {
    height: 70px;
  }
}

.sidebar__page-wrap {
  text-decoration: none;
}

.sidebar__page {
  width: 70px;
  height: 55px;
  cursor: pointer;
  transition: 0.2s;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 70px;
  text-decoration: none;
  color: $primary-gray;
  &_open {
    width: 250px
  }
  &_active {
    background: rgba($primary-blue, 0.1);
  }
  &_disabled {
    cursor: default;
  }
  &:hover {
    background: rgba($primary-blue, 0.1);
  }
  &_toggler {
    height: 70px;
  }
}

.sidebar__pages {
  padding-bottom: 10px;
  border-bottom: 1px solid #D8F2FA;
}

.sidebar__icon {
  width: 25px;
  &_disabled {
    opacity: 0.5;
  }
}

.sidebar__page-title {
  font-size: 14px;
  min-width: 150px;
  &_disabled {
    color: rgb(211, 211, 211);
  }
  &_active {
    color: $primary-blue;
  };
}

.sidebar__page-minititle {
  font-size: 9px;
  color: #7a7a7a;
  opacity: 1;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 40px;
  text-align: center;
  &_hide {
    opacity: 0;
  }
  &_active {
    color: $primary-blue;
  };
}

.sidebar__text {
  transition: opacity 0.1s;
  font-size: 14px;
  color: $primary-gray;
  white-space: nowrap;
  &_white {
    color: white;
  }
  &_bold {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
    white-space: pre-wrap;
  }
  &_small {
    font-size: 12px;
    white-space: pre-wrap;
  }
}

.sidebar__tarifs {
  position: fixed;
  bottom: 50px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.sidebar__tarif-title {
  display: flex;
}

.sidebar__tarif {
  padding: 20px;
  margin-top: 20px;
  background: $primary-blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  transition: 0.2s;
  width: 210px;
  border-radius: 16px;
  transform-origin:left center;
}

.sidebar__tarif-button {
  width: 100%;
  padding: 12px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: rgba(255, 255, 255, 0.31);
  border-radius: 33px;
  margin-top: 14px;
  position: relative;
  cursor: pointer;
  &:hover {
    .sidebar__update-info {
      opacity: 1;
    }
  }
}

.sidebar__update-info {
  opacity: 0;
  transition: 0.2s;
  pointer-events: none;
  position: absolute;
  background: white;
  border-radius: 20px;
  right: -410px;
  top: -310px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  width: 450px;
}

.sidebar__update-info-check {
  width: 84px;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F1F8F1;
  border-radius: 8px;
  margin-bottom: 28px;
}

.sidebar__update-info-block {
  text-align: left;
  width: 100%;
  @include between-elements {
    margin-bottom: 5px;
  }
}
</style>
