<template>
  <div>
    <TariffMiniBurn
    :show-full="showFull"
    :user-burn-rate="getUserBurnRate"
    :expired-date="expiredDate"
    :access-to-catalog="getAccessToCatalog"
    :comission="getComission"
    :is-admin="isAdmin" />

    <TariffFullBurn
    :show-full="showFull"
    :user-burn-rate="getUserBurnRate"
    :expired-date="expiredDate"
    :access-to-catalog="getAccessToCatalog"
    :comission="getComission"
    :is-admin="isAdmin" />
  </div>
</template>

<script>
import TariffMiniBurn from './components/TariffMiniBurn.vue';
import TariffFullBurn from './components/TariffFullBurn.vue';

export default {
  name: 'TariffsRate',
  components: { TariffMiniBurn, TariffFullBurn },
  props: {
    userInfo: {
      type: Object,
      default: null,
    },
  },
  computed: {
    showFull() {
      return this.$store.getters['app/getShowFullTariffRate'];
    },
    getAccessToCatalog() {
      return this.userInfo?.asEmployerAgency?.currentTariff?.tariff?.isAccessToCatalog || false;
    },
    getUserBurnRate() {
      return this.userInfo?.asEmployerAgency?.burnMarketRateAccount?.burnMarketRate || null;
    },
    expiredDate() {
      const date = this.userInfo?.asEmployerAgency?.currentTariff?.expiredDateTime?.date || null;
      if (date) {
        return this.moment(date).format('ll');
      }
      return null;
    },
    getComission() {
      try {
        const checkpoints = this.userInfo?.asEmployerAgency?.burnMarketRateAccount?.checkpoints;
        let comission = null;
        if (checkpoints) {
          comission = checkpoints.find((item) => item.isCurrent)?.resultCommission;
        }
        return comission || this.userInfo?.asEmployerAgency?.baseCommissionInPercent || '?';
      } catch (error) {
        console.warn('error in getComission', error);
        return '?';
      }
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
