<template>
  <div class="clients-statistic-filters">
    <div class="clients-statistic-filters__items">
      <DataInput
      v-model.trim="filters.agencyName"
      clear-icon
      style="width: 300px"
      class="compilation-folder-modal__input"
      placeholder="Укажите название клиента"
      @submitValue="searchFilters" />
      <DataDatePicker
      v-model="filters.startDate" />
      <DataDatePicker
      v-model="filters.endDate" />
      <div class="compilation-folder-modal__checkboxes">
        <div class="compilation-folder-modal__checkbox">
          <DataCheckbox
          :value="filters.withCampaign"
          @input="(value) => filters.withCampaign = value ? 1 : null" />
          <span>Есть РК</span>
        </div>
        <div class="compilation-folder-modal__checkbox">
          <DataCheckbox
          :value="filters.withRequestPlacement"
          @input="(value) => filters.withRequestPlacement = value ? 1 : null" />
          <span>Есть запросы</span>
        </div>
      </div>
    </div>

    <div class="clients-statistic-filters__buttons">
      <DataButton
      text="Найти"
      :border-radius="0"
      :width="150"
      @handler="searchFilters" />
      <DataButton
      v-tooltip="'Сбросить фильтры'"
      icon="close"
      type="outlined"
      :border-radius="0"
      :width="50"
      @handler="resetFilters" />
      <DataButton
      v-tooltip.left="'Экспорт статистики'"
      icon="download"
      type="outlined"
      :border-radius="0"
      :width="50"
      @handler="exportStatistic" />
    </div>

    <!-- Экспорт статистики -->
    <DataModal
    :show-modal="showExportStatisticModal"
    @close="closeExportStatisticModal">
      <template #modal-content>
        <ExportStatisticModal
        :filters="filters"
        :statistic-is-loading="statisticIsLoading"
        @setStatisticIsLoading="setStatisticIsLoading"
        @close="closeExportStatisticModal" />
      </template>
    </DataModal>
  </div>
</template>

<script>
import ExportStatisticModal from './ExportStatisticModal.vue';

export default {
  name: 'ClientsStatisticFilters',
  components: { ExportStatisticModal },
  props: {
    filters: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    showExportStatisticModal: false,
    statisticIsLoading: false,
  }),
  methods: {
    searchFilters() {
      this.$emit('searchWithFilters');
    },
    resetFilters() {
      this.$emit('setBaseFilters');
      this.searchFilters();
    },
    exportStatistic() {
      if (this.showExportStatisticModal) return;
      this.showExportStatisticModal = true;
    },
    closeExportStatisticModal() {
      if (this.statisticIsLoading) return;
      this.showExportStatisticModal = false;
    },
    setStatisticIsLoading(value) {
      this.statisticIsLoading = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.clients-statistic-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  width: 100%;

  .clients-statistic-filters__items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-right: 20px;
  }

  .clients-statistic-filters__buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .compilation-folder-modal__checkboxes {
    display: flex;
    @include between-elements {
      margin-right: 20px;
    }
  }

  .compilation-folder-modal__checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include between-elements {
      margin-right: 10px;
    }

    span {
      font-size: 14px;
      color: $primary-gray;
    }
  }
}
</style>
