<template>
  <div class="header-selector">
    <div
    v-click-outside="close"
    class="lang-selector">
      <div
      class="lang-selector__title"
      @click.stop="openMenu('lang')">
        <img
        class="lang-selector__flag"
        :src="require(`@/assets/svg/flags/${getCurrentFlag}.svg`)"
        alt="flag">
        <span
        class="lang-selector__text lang-selector__text_touch">
          {{ selectedLang }}
        </span>
        <DataIcon
        name="expand_more"
        :size="18"
        class="lang-selector__text lang-selector__text_touch lang-selector__text_gray"
        :arrow-open="showMenu" />
      </div>
      <div
      class="lang-selector__menu"
      :class="showMenu && 'lang-selector__menu_open'">
        <div
        v-for="lang in langs"
        :key="lang.value"
        class="lang-selector__country"
        :class="lang.disable && 'lang-selector__country_disable'"
        @click="changeLang(lang)">
          <img
          class="lang-selector__flag"
          :src="require(`@/assets/svg/flags/${lang.flag}.svg`)"
          alt="flag">
          <span class="lang-selector__text">
            {{ lang.name }}
          </span>
        </div>
      </div>
    </div>

    <!-- <div
    v-click-outside="close"
    class="lang-selector">
      <div
      class="lang-selector__title"
      @click.stop="openMenu('currency')">
        <span
        class="lang-selector__text lang-selector__text_touch">
          {{ selectedCurrency.toUpperCase() }}
        </span>
        <DataIcon
        name="expand_more"
        :size="18"
        class="lang-selector__text lang-selector__text_touch lang-selector__text_gray"
        :arrow-open="showСurrency" />
      </div>
      <div
      class="lang-selector__menu lang-selector__menu_cur"
      :class="showСurrency && 'lang-selector__menu_open-currency'">
        <div
        v-for="currency in currencies"
        :key="currency.value"
        class="lang-selector__country lang-selector__country_cur"
        @click="changeCurrency(currency)">
          <span class="lang-selector__text">
            {{ currency.name }}
          </span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'LangSelector',
  data: () => ({
    selectedLang: null,
    selectedCurrency: null,
    showMenu: false,
    showСurrency: false,
    langs: [
      {
        name: 'Русский',
        value: 'Ru',
        flag: 'russia',
      },
      {
        name: 'English',
        value: 'En',
        flag: 'england',
      },
      {
        name: 'Uzbekistan',
        value: 'Uz',
        flag: 'uzbekistan',
      },
    ],
    currencies: [
      {
        name: 'RUB',
        value: 'rub',
        flag: 'russia',
      },
      {
        name: 'USD',
        value: 'usd',
        flag: 'england',
      },
      {
        name: 'KZT',
        value: 'kzt',
        flag: 'kazakhstan',
      },
      {
        name: 'UZS',
        value: 'uzs',
        flag: 'uzbekistan',
      },
    ],
  }),
  created() {
    this.selectedLang = localStorage.getItem('appLang') || 'Ru';
    this.$i18n.locale = this.selectedLang.toLowerCase();
    this.selectedCurrency = localStorage.getItem('appCurrency') || 'rub';
  },
  methods: {
    close() {
      this.showMenu = false;
      this.showСurrency = false;
    },
    changeLang(lang) {
      this.close();
      if (lang.value === this.selectedLang) return;

      this.selectedLang = lang.value;
      localStorage.setItem('appLang', lang.value);
      this.$i18n.locale = lang.value.toLowerCase();
    },
    changeCurrency(currency) {
      this.close();
      if (currency.value === this.selectedCurrency) return;

      this.$store.commit('app/setAppIsLoading', true);
      this.selectedCurrency = currency.value;
      localStorage.setItem('appCurrency', currency.value);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    },
    openMenu(value) {
      if (value === 'lang') {
        this.showMenu = !this.showMenu;
        this.showСurrency = false;
        return;
      }
      this.showMenu = false;
      this.showСurrency = !this.showСurrency;
    },
  },
  computed: {
    getCurrentFlag() {
      return this.langs.find((item) => item.value === this.selectedLang).flag;
    },
  },
};
</script>

<style lang="scss">
.header-selector {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lang-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 60px;
}

.lang-selector__title {
  display: flex;
  align-items: center;
}

.lang-selector__text {
  font-size: 12px;
  line-height: 16px;
  &_gray {
    color: #6e6e6e;
  }
  &_touch {
    cursor: pointer;
  };
}

.lang-selector__menu {
  height: 0px;
  position: absolute;
  top: 30px;
  left: -10px;
  background: white;
  overflow: hidden;
  transition: 0.1s;
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 4;
  &_open {
    height: 78px;
  }
  &_open-currency {
    height: 104px;
  }
  &_cur {
    left: -2px;
  }
}

.lang-selector__country {
  width: 120px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  cursor: pointer;
  padding: 5px 10px;
  transition: .1s;
  &_cur {
    width: 80px;
  }
  &:hover {
    transition: .1s;
    color: $primary-blue;
    background: rgb(241, 241, 241);
  }
  &_selected {
    color: $primary-blue;
    background: rgb(241, 241, 241);
  }
  &_disable {
    color: rgb(214, 214, 214);
    background: rgb(255, 255, 255);
    &:hover {
      color: rgb(214, 214, 214);
      background: rgb(255, 255, 255);
  }
  }
}

.lang-selector__flag {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
</style>
