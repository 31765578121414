<template>
  <div class="group-bot-notify">
    <div
    class="group-bot-notify__status"
    :class="`group-bot-notify__status_${selectedStatus.color}`">
      <span
      class="group-bot-notify__text"
      :class="`group-bot-notify__text_${selectedStatus.color}`">
        {{ selectedStatus.name }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupBotNotify',
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    statusTypes: [
      { value: 0, name: 'Нет ответа', color: 'gray' },
      { value: 1, name: 'Да', color: 'green' },
      { value: 2, name: 'Нет', color: 'red' },
    ],
    selectedStatus: null,
  }),
  created() {
    this.initData();
  },
  methods: {
    initData() {
      const status = this.group.answerFromPublisherBot?.status || 0;
      this.selectedStatus = this.statusTypes.find((item) => item.value === status);
    },
  },
};
</script>

<style lang="scss" scoped>
.group-bot-notify {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .group-bot-notify__status {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    min-height: 20px;
    padding: 4px 10px;
    &_red {
      background: rgba($primary-red, 0.3);
    }
    &_gray {
      background: rgba($primary-gray, 0.2);
    }
    &_green {
      background: rgba($primary-green, 0.3);
    }
  }

  .group-bot-notify__text {
    font-size: 14px;
    &_red {
      color: rgba($primary-red, 0.8);
    }
    &_gray {
      color: rgba($primary-gray, 0.8);
    }
    &_green {
      color: rgba($primary-green, 0.8);
    }
  }
}
</style>
