<template>
  <div class="user-headers">
    <div
    v-for="header in getHeaders"
    :key="header.value"
    :style="`width: ${header.width}px`"
    :class="{ 'user-headers__element_name': header.value === 'name' }"
    class="user-headers__element">
      <span class="user-headers__text_light">
        {{ header.name }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserHeaders',
  props: {
  },
  data: () => ({
  }),
  computed: {
    getHeaders() {
      const headers = [
        { name: 'Название', value: 'name', show: true },
        {
          name: 'Запросы', value: 'requests', width: 150, show: true,
        },
        {
          name: 'Кампании', value: 'source', width: 150, show: true,
        },
        {
          name: 'Потрачено за мес/всего', value: 'status', width: 250, show: true,
        },
        {
          name: 'Комиссия', value: 'total', width: 100, show: true,
        },
      ];
      return headers.filter((item) => item.show);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-headers {
  display: flex;
  align-items: center;
  min-width: 100%;
  position: sticky;
  background: white;
  z-index: 2;
  top: 0px;
  left: 0px;
  border-top: 1px solid $primary-group-border;
  border-bottom: 1px solid $primary-group-border;
}

.user-headers__element {
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 5px;
  }
  &_name {
    padding: 12px 0px;
    position: sticky;
    left: 0px;
    padding-left: 20px;
    background: white;
    justify-content: flex-start;
    align-items: flex-start;
    @include tablet-screen {
      width: 400px;
    }
  }
}

.user-headers__text {
  font-size: 14px;
  color: $primary-gray;
  &_name {
    color: $primary-blue;
  }
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

</style>
