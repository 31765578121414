<template>
  <div
  class="user-element">
    <div
    class="user-element__column user-element__column_name">
      <DataIcon
      name="person"
      class="user-element__person" />
      <div class="user-element__name-text">
        <span
        class="user-element__text">
          {{ getName() }}
        </span>
        <span
        class="user-element__text user-element__text_small-light">
          {{ user.email }}
        </span>
      </div>
    </div>

    <!-- Запросы -->
    <div
    class="user-element__column"
    style="width: 150px">
      <span class="user-element__text">
        —
      </span>
    </div>

    <!-- Клиенты -->
    <div
    v-if="inClient"
    class="user-element__column"
    style="width: 150px" />

    <!-- Кампании -->
    <div
    class="user-element__column"
    style="width: 150px">
      <span class="user-element__text">
        —
      </span>
    </div>

    <!-- Потрачено за мес/всего -->
    <div
    class="user-element__column"
    style="width: 250px">
      <span class="user-element__text">
        —
      </span>
    </div>

    <!-- Комиссия -->
    <div
    class="user-element__column"
    style="width: 100px">
      <span class="user-element__text user-element__text_light">
        {{ getRate }}
      </span>
      <img
      v-if="inClient"
      src="@/assets/svg/icons/menu.svg"
      class="user-element__menu-icon"
      @click="showMenu = true">
      <div
      v-if="showMenu"
      v-click-outside="closeMenu"
      class="user-element__menu">
        <span
        v-if="isAdmin && inClient"
        class="user-element__menu-element"
        @click="$emit('openEditEmployeeAgency')">Редактировать сотрудника</span>
        <span
        class="user-element__menu-element"
        @click="deleteUser">Удалить</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserElement',
  props: {
    // Объект с пользователем
    user: {
      type: Object,
      default: () => {},
    },
    // Объект с владельцем пользователя, т.к пользователь может быть сотрудником клиента
    currentClient: {
      type: Object,
      default: null,
    },
    // Пропс, указывающий, что пользователь отображается в клиенте
    inClient: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showMenu: false,
  }),
  methods: {
    closeMenu() {
      this.showMenu = false;
    },
    deleteUser() {
      if (this.inClient) {
        // удаление из клиента
        this.$emit('deleteEmployeeFromClient', this.user, this.currentClient);
      } else {
        // просто удаление юзера
        this.$emit('openDeleteUser', this.user);
      }
      this.closeMenu();
    },
    getName() {
      return this.user.name || this.user.email || '—';
    },
  },
  computed: {
    getRate() {
      if (this.currentClient) {
        return this.currentClient.baseCommissionInPercent ? `${this.currentClient.baseCommissionInPercent}%` : '—';
      }
      return '—';
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
  },
};
</script>

<style lang="scss" scoped>
.user-element {
  display: flex;
  align-items: center;
  min-width: 100%;
  background: white;
  padding: 10px 0px;
  &:hover {
    background: #f4fbfe;
    .user-element__column_name {
      background: #f4fbfe;
    }
  }
}

.user-element__person {
  margin-right: 20px;
  color: rgba($primary-lightgray, 0.5)
}

.user-element__column {
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  height: 40px;
  &_name {
    flex-direction: row;
    justify-content: flex-start;
    position: sticky;
    left: 0px;
    z-index: 1;
    background: white;
    padding-left: 20px;
    @include tablet-screen {
      width: 400px;
    }
  }
}

.user-element__name-text {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.user-element__text {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &_light {
    color: $primary-lightgray;
  }
  &_small-light {
    color: $primary-lightgray;
    font-size: 12px;
  }
  &_name {
    font-size: 16px;
    color: $primary-blue;
  }
}

.user-element__info-icon {
  margin-left: 10px;
  cursor: pointer;
}

.user-element__menu-icon {
  margin-left: 30px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  padding: 10px;
}

.user-element__info-icon {
  margin-left: 10px;
  cursor: pointer;
}

.user-element__menu {
  min-width: 210px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40px;
  left: -40px;
  z-index: 2;
}

.user-element__menu-element {
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: rgba($primary-lightgray, 0.05);
  }
}
</style>
