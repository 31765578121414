<template>
  <div
  class="data-toggle"
  :class="{'data-toggle_active': value, 'data-toggle_disabled': disabled }"
  @click="toggleCheck">
    <DataIcon
    name="check"
    :size="14"
    :class="value && 'data-toggle__icon_active'"
    class="data-toggle__icon" />
    <div
    class="data-toggle__round"
    :class="value && 'data-toggle__round_active'" />
  </div>
</template>

<script>
export default {
  name: 'DataToggle',
  props: {
    value: {
      type: [Boolean, String, Number],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleCheck() {
      if (this.disabled) return;
      this.$emit('input', !this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.data-toggle {
  width: 45px;
  height: 26px;
  border-radius: 12px;
  transition: 0.4s;
  background: linear-gradient(90deg, #f1f1f1, #f1f1f1);
  position: relative;
  cursor: pointer;
  &:hover {
    background: linear-gradient(90deg, #ececec, #ececec);
  }
  &_active {
    background: linear-gradient(90deg, #5ACBF0, #00AEE8);
    &:hover {
      background: linear-gradient(90deg, #5ACBF0, #00AEE8);
    }
  }
  &_disabled {
      background: linear-gradient(90deg, #c4c4c4, #c4c4c4);
      &:hover {
        background: linear-gradient(90deg, #c4c4c4, #c4c4c4);
      }
  }
}

.data-toggle__round {
  width: 18px;
  height: 18px;
  background: white;
  border-radius: 100%;
  position: absolute;
  top: 4px;
  left: 4px;
  transform: translateX(0);
  transition: transform 0.2s;
  &_active {
    width: 18px;
    height: 18px;
    background: white;
    transform: translateX(19px);
  }
}

.data-toggle__icon {
  opacity: 0;
  transform: scale(0);
  transition: 0.2s;
  position: absolute;
  top: 5px;
  left: 6px;
  color: white;
  &_active {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
